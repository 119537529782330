import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import FormErrorMessage from '../../../../../_shared/FormErrorMessage';
import { TooltipInfo } from '../../../../../_shared/TooltipInfo';
import {
  fetchDictionaries,
  getArraysCommonElements,
} from '../../../../../../utils/additionalFieldsUtilsForWeighingDialog';
import { DictionaryAdditionalFieldAutocomplete } from '../../../../../_shared/DictionaryAdditionalFieldAutocomplete';
import { useTranslation } from 'react-i18next';
import { Weighing } from '../../../../../../types/weighing';
import { Dictionary } from '../../../../../../types/dictionary';
import { ensureDictionaryValueIncluded } from '../../../../../../utils/additionalFieldsUtils';
import CustomDivider from '../../../../../_shared/CustomDivider';

const INIT_SHOW_DATA_STATE = {
  divider: false,
  fieldsForWeighings: false,
};

interface AdditionalFieldsPluginProps {
  weighing: Weighing;
  formikInstance: any;
}

const AdditionalFieldsPlugin = ({ weighing, formikInstance }: AdditionalFieldsPluginProps) => {
  const { t } = useTranslation('weighingWeighingList');
  const [dictionariesData, setDictionariesData] = useState<Dictionary[]>([]);
  const [localTouched, setLocalTouched] = useState({});
  const [currentProductIds, setCurrentProductIds] = useState([]);
  const [showData, setShowData] = useState(INIT_SHOW_DATA_STATE);

  useEffect(() => {
    setShowData({
      divider: !!formikInstance.values.additionalFields?.length,
      fieldsForWeighings: !!formikInstance.values.additionalFields?.length,
    });
  }, [formikInstance.values.additionalFields, weighing.weighingMode, weighing.id]);

  useEffect(() => {
    void fetchDictionaries().then((result) => setDictionariesData(result));
  }, []);

  useEffect(() => {
    const productIds = formikInstance.values.measurements?.map((m: any) => m.product?.id).filter((p: string) => p);
    if (JSON.stringify(productIds) !== JSON.stringify(currentProductIds)) {
      setCurrentProductIds(productIds);
    }
  }, [JSON.stringify(formikInstance)]);

  useEffect(() => {
    setLocalTouched({});
  }, [JSON.stringify(currentProductIds)]);

  const renderAdditionalField = (field: any, index: any) => {
    const identifier = `additionalFields-${index}`;

    const getValueParent = () => formikInstance.values.additionalFields || [];

    const handleChange = (value: any) => {
      const newValue = getValueParent();
      newValue[index] = { ...newValue[index], value };
      formikInstance.setFieldValue('additionalFields', newValue, true);
      const newLocalTouched = localTouched as any;
      newLocalTouched[identifier] = true;
      setLocalTouched(newLocalTouched);
    };

    const renderDictionaryType = () => {
      const sourceDictionary = dictionariesData.find((d: any) => d.id === field.dictionaryId) as Dictionary;

      let source = [];

      if (sourceDictionary) {
        source = sourceDictionary.values;
      } else if (getValueParent()[index]?.value) {
        source.push(getValueParent()[index]?.value);
      }

      source = ensureDictionaryValueIncluded(source, field.value);

      return (
        <DictionaryAdditionalFieldAutocomplete
          id={identifier}
          value={source?.find((el: string) => el === getValueParent()[index]?.value) || null}
          onChange={handleChange}
          source={source}
        />
      );
    };

    const renderNumberType = () => (
      <InputText
        id={identifier}
        type="number"
        value={getValueParent()[index]?.value || ''}
        onChange={(e) => handleChange(e.target.value)}
      />
    );

    const renderTextType = () => (
      <InputText
        id={identifier}
        value={getValueParent()[index]?.value || ''}
        onChange={(e) => handleChange(e.target.value)}
      />
    );

    const renderFormulaType = () => <InputText id={identifier} value={getValueParent()[index]?.value || ''} disabled />;

    const renderAutoIdType = () => <InputText id={identifier} value={getValueParent()[index]?.value || ''} disabled />;

    const METHODS = {
      DICTIONARY: renderDictionaryType,
      NUMBER: renderNumberType,
      TEXT: renderTextType,
      FORMULA: renderFormulaType,
      AUTO_ID: renderAutoIdType,
    };

    if (!!METHODS[field.type as keyof typeof METHODS]) {
      const showUnit = !!field.unit;
      const isFormulaType = field.type === 'FORMULA';
      const showRequiredStar = !field.isOptional;

      return (
        <>
          <label htmlFor={field.name}>
            {field.name}
            {showUnit && (
              <>
                &nbsp;<small>{`[${field.unit}]`}</small>
              </>
            )}
            {isFormulaType && (
              <>
                &nbsp;
                <TooltipInfo _key={identifier} text={t('weighingDialogAfPluginEvaluationInfo')} className={undefined} />
              </>
            )}
            {showRequiredStar && (
              <span className="text-red-500">
                <small>*</small>
              </span>
            )}
          </label>
          {METHODS[field.type as keyof typeof METHODS]()}
          {!isFormulaType && (
            <FormErrorMessage
              fieldName={identifier}
              formikInstance={formikInstance}
              withTouchedTrue={!!formikInstance.submitCount || !!localTouched[identifier as keyof typeof localTouched]}
            />
          )}
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      {showData.divider && <CustomDivider />}

      {showData.fieldsForWeighings &&
        formikInstance.values.additionalFields.map(
          (field: any, index: number) =>
            !(!weighing.closed && field.type === 'FORMULA') &&
            (field.productIds?.length === 0 ||
              getArraysCommonElements(field.productIds, currentProductIds).length > 0) &&
            field.isActive && (
              <div key={field.name} className="field col-12 md:col-4">
                {renderAdditionalField(field, index)}
              </div>
            ),
        )}
    </>
  );
};

export default AdditionalFieldsPlugin;
