import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AutoComplete, AutoCompleteProps } from 'primereact/autocomplete';
import { Chip } from 'primereact/chip';
import { Tooltip } from 'primereact/tooltip';
import { default as ViewCardDialogKpo } from '../Bdo/Kpo/Sender/components/ViewCardDialog';
import { default as ViewCardDialogKpok } from '../Bdo/Kpok/Sender/components/ViewCardDialog';
import { Button } from 'primereact/button';
import BdoApiService from '../../services/BdoApiService';
import { productBodyTemplate, productMeasurementsBodyTemplate } from '../Weighing/components/Templates';
import { formatDecimal, formatDateTime, formatDate } from '../../utils/formatUtils';
import useTransactionType from '../../utils/useTransactionType';
import { productsActiveTypeLabels, tareTypeLabels, getTranslatedLabel } from '../../utils/labels';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../types/reducer-state';

export const dictionarySearchBranch = (event: any, items: any[], setFilteredItems: (a: any[]) => void) => {
  const searchString = event?.query?.trim()?.toLowerCase();
  if (!searchString?.length) {
    setFilteredItems([...items]);
  } else {
    setFilteredItems(items.filter((item) => item['name'].toLowerCase().includes(searchString)));
  }
};

interface CustomAutoCompleteProps {
  lookupField?: string;
  source?: any[];
  id?: string;
  value?: any;
  handler?: (a: any[]) => void;
  inputStyle?: string;
  customSearchBranch?: (e: any, items: any[], setFilteredItems: (a: any[]) => void, fields: string) => void;
  customItemTemplate?: (a: any) => void;
  placeholder?: string;
  scrollHeight?: string;
  tooltip?: string;
  disabled?: boolean;
  forceSelection?: boolean;
  onBlur?: (a: any[]) => void;
}

export const CustomAutoComplete = ({
  lookupField,
  source,
  id,
  value,
  handler,
  inputStyle,
  customSearchBranch,
  customItemTemplate,
  placeholder,
  scrollHeight,
  tooltip,
  disabled,
  forceSelection,
  onBlur,
  ...rest
}: CustomAutoCompleteProps & AutoCompleteProps) => {
  const [items, setItems] = useState<any[] | null>(null);
  const [filteredItems, setFilteredItems] = useState<any[] | null>(null);
  const [searchText, setSearchText] = useState('');
  const field = lookupField !== undefined ? lookupField : 'name';
  const placeholderField = placeholder !== undefined ? placeholder : '';
  const forceSelectionField = forceSelection !== undefined ? forceSelection : true;

  useEffect(() => {
    setItems(source!);
  }, [source]);

  useEffect(() => {
    if (!value) {
      setSearchText('');
    } else if (value !== searchText && value[field]) {
      if (value[field].replace(/[^a-z0-9]/gi, '') === value[field]) {
        setSearchText('');
      }
    }
  }, [searchText, value, field]);

  const searchBranch = (event: any) => {
    setTimeout(() => {
      if (customSearchBranch) {
        customSearchBranch(event, items!, setFilteredItems, field);
      } else {
        if (!event.query.trim().length) {
          setFilteredItems([...items!]);
        } else {
          setFilteredItems(
            items!.filter((item) => {
              return item[field].toLowerCase().includes(event.query.toLowerCase());
            }),
          );
        }
      }
    }, 250);
  };

  const onSelect = () => {
    setSearchText('');
  };

  const itemTemplate = (item: any) => {
    if (typeof customItemTemplate === 'function') {
      return customItemTemplate(item);
    } else {
      return (
        <div className="item">
          <div>{item[field]}</div>
        </div>
      );
    }
  };

  const valueTemplate = (_value: any) => {
    if (!!searchText) {
      return searchText;
    }

    return _value;
  };

  const onChange = (event: any) => {
    if (typeof event.value === 'string') {
      const newValue = event.value;

      event.originalEvent.target.value = newValue;
      setSearchText(newValue);
    }

    if (typeof handler === 'function') {
      handler(event);
    }
  };

  const AnyAutoComplete = AutoComplete as any;

  return (
    <div className="custom-autocomplete">
      <AnyAutoComplete
        id={id}
        value={valueTemplate(value)}
        suggestions={filteredItems}
        completeMethod={searchBranch}
        field={field}
        dropdown
        forceSelection={forceSelectionField}
        onBlur={onBlur}
        itemTemplate={itemTemplate}
        onChange={onChange}
        onSelect={onSelect}
        inputStyle={inputStyle}
        placeholder={placeholderField}
        scrollHeight={scrollHeight}
        tooltip={tooltip}
        disabled={disabled}
        {...rest}
      />
    </div>
  );
};

interface CustomTableFieldProps {
  col: any;
  row: any;
  withoutHeaders?: boolean;
}

export const CustomTableField = ({ col, row, withoutHeaders }: CustomTableFieldProps) => {
  const { t } = useTranslation('sharedComponents');
  const { t: t1 } = useTranslation('utils');
  const { fieldsType, header, field, emptyValue } = col?.column?.props;
  const unit = useSelector((state: ReducerState) => state.appConfig.unit);
  const [bdoCardItem, setBdoCardItem] = useState(null);
  const [bdoModuleVisible, setBdoModuleVisible] = useState(false);
  const { getTransactionTypeLabel } = useTransactionType();

  if (fieldsType) {
    switch (fieldsType) {
      case 'text':
      case 'noFilter':
      case 'dropdown':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? header : null}</span> : ''}
            {row[field] ? row[field] : emptyValue || null}
          </>
        );
      case 'product':
        return productBodyTemplate(row, emptyValue);
      case 'productMeasurement':
        return productMeasurementsBodyTemplate(row, emptyValue);

      case 'scalesNames':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? col.header : null}</span> : ''}
            {row[col.field]?.filter(Boolean)?.join(', ')}
          </>
        );
      case 'weighingNumber':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? header : null}</span> : ''}
            {row[field] ? <>{row[field]}</> : emptyValue || null}
          </>
        );
      case 'number':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? col.header : null}</span> : ''}
            {row[col.field]
              ? formatDecimal(row[col.field])
              : row[col.field] === 0
              ? row[col.field]
              : emptyValue || null}
          </>
        );
      case 'kg':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? col.header : null}</span> : ''}
            {row[col.field]
              ? `${formatDecimal(row[col.field])} ${unit}`
              : row[col.field] === 0
              ? `${row[col.field]} ${unit}`
              : emptyValue || null}
          </>
        );
      case 'date':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? header : null}</span> : ''}
            {row[field] ? formatDate(row[field]) : emptyValue || null}
          </>
        );
      case 'datetime':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? header : null}</span> : ''}
            {row[field] ? formatDateTime(row[field]) : emptyValue || null}
          </>
        );
      case 'multiple':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? header : null}</span> : ''}
            {row[field] instanceof Array
              ? row[field].map((item: any) => {
                  return item.name ? <Chip key={item.id} label={t(item.name)} className="mr-2 mb-2" /> : null;
                })
              : null}
          </>
        );
      case 'subfield':
        const _field = field.split('.')[0];
        const _subfield = field.split('.')[1];
        return (
          <>
            <span className="p-column-title">{col ? header : null}</span>
            {row[_field] ? row[_field][_subfield] : emptyValue || null}
          </>
        );
      case 'transactionType':
        return (
          <>
            <span className="p-column-title">{col ? header : null}</span>
            {row[col.field] ? getTransactionTypeLabel(row[col.field]) : emptyValue || null}
          </>
        );
      case 'tareType':
        return (
          <>
            <span className="p-column-title">{col ? header : null}</span>
            {row[col.field] ? getTranslatedLabel(tareTypeLabels, row[col.field], t1) : emptyValue || null}
          </>
        );
      case 'netto':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? col.header : null}</span> : ''}
            {`${formatDecimal(row[col.field] ?? 0)} ${unit}`}
          </>
        );
      case 'sum':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? col.header : null}</span> : ''}
            {row.weighingMode === 'WEIGHING_SERIES' || row.weighingMode === 'DOUBLE_WEIGHING_SERIES'
              ? `${formatDecimal(row[col.field])} ${unit}`
              : null}
          </>
        );
      case 'isConfirmed':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? col.header : null}</span> : ''}
            {<i className={row.isConfirmed ? 'pi pi-check' : 'pi pi-times'} />}
          </>
        );
      case 'containsAnyContainers':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? col.header : null}</span> : ''}
            {<i className={row.containers?.length > 0 ? 'pi pi-check' : 'pi pi-times'} />}
          </>
        );
      case 'productActive':
        return (
          <>
            <span className="p-column-title">{col ? header : null}</span>
            {getTranslatedLabel(productsActiveTypeLabels, row[col.field] === true ? 'ACTIVE' : 'INACTIVE', t1) ||
              emptyValue ||
              null}
          </>
        );
      case 'bdoCard':
        if (!row[field]) {
          return null;
        }

        const getItemBody = async (cardStatus: string) => {
          const result = {} as any;

          if (row.cardType === 'kpok-receive') {
            result.kpokId = row[field];
            result.cardSubtype = 'RECEIVE';
          } else if (row.cardType === 'kpok-transfer') {
            result.kpokId = row[field];
            result.cardSubtype = 'TRANSFER';
          } else {
            result.kpoId = row[field];
          }

          const cardStatuses = (await BdoApiService.getStatuses({ show_loader: false })).data;
          cardStatuses.find((status: any) => {
            if (status.Name === cardStatus) {
              result.cardStatusCodeName = status.CodeName;
              return true;
            }
            return false;
          });

          return result;
        };

        const cardStatusMethods = {
          kpo: async () =>
            (await BdoApiService.KPO_getPrintingPageData(row[field], { show_loader: false })).data?.cardStatus,
          'kpok-receive': async () =>
            (await BdoApiService.KPOK_getPrintingPageDataReceive(row[field], { show_loader: false })).data?.cardStatus,
          'kpok-transfer': async () =>
            (await BdoApiService.KPOK_getPrintingPageDataTransfer(row[field], { show_loader: false })).data?.cardStatus,
        };

        const handleClick = async () => {
          const cardStatus = await cardStatusMethods[row.cardType as keyof typeof cardStatusMethods]();
          const itemBody = await getItemBody(cardStatus);
          setBdoCardItem(itemBody);
          setBdoModuleVisible(true);
        };

        const handleClose = () => {
          setBdoModuleVisible(false);
          setBdoCardItem(null);
        };

        const types = {
          kpo: (
            <>
              <ViewCardDialogKpo visible={bdoModuleVisible} closeDialog={handleClose} item={bdoCardItem} />
              <Button type="button" label={t('kpo')} className="p-button-link" onClick={handleClick} />
            </>
          ),
          'kpok-receive': (
            <>
              <ViewCardDialogKpok visible={bdoModuleVisible} closeDialog={handleClose} item={bdoCardItem} />
              <Button type="button" label={t('kpokReceive')} className="p-button-link" onClick={handleClick} />
            </>
          ),
          'kpok-transfer': (
            <>
              <ViewCardDialogKpok visible={bdoModuleVisible} closeDialog={handleClose} item={bdoCardItem} />
              <Button type="button" label={t('kpokTransfer')} className="p-button-link" onClick={handleClick} />
            </>
          ),
        };
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? header : null}</span> : ''}
            {row.cardType ? types[row.cardType as keyof typeof types] : emptyValue || null}
          </>
        );
      default:
        return <></>;
    }
  }
};

interface CustomButtonProps {
  label?: string;
  icon?: string;
  name?: string;
  className?: string;
  dataFlag?: string;
  tooltip?: string;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'button' | 'reset' | 'submit';
  severity?: 'secondary' | 'help' | 'danger' | 'success' | 'info' | 'warning' | undefined;
}

export const CustomButton = ({
  label,
  icon,
  name,
  className,
  dataFlag,
  tooltip,
  disabled,
  onClick,
  type,
  severity,
}: CustomButtonProps) => {
  return (
    <>
      <Tooltip target={`.${name}-tooltip`} content={disabled ? tooltip : ''} position="bottom" />
      <div className={`${name}-tooltip`}>
        <Button
          type={type || 'submit'}
          className={className}
          label={label}
          icon={icon}
          name={name}
          data-flag={dataFlag}
          disabled={disabled}
          onClick={onClick}
          severity={severity}
        />
      </div>
    </>
  );
};
