import axios from 'axios';

export interface OnlineApiKeyCredentails {
  name: string;
  ClientId: string;
  ClientSecret: string;
  scopes: string[];
  contractorIds: string[];
  lastUsedAt: Date | null;
}

export interface OfflineApiKeyCredentials {
  name: string;
  id: string;
  contractorIds: string[];
  lastUsedAt: Date | null;
  scopes: string[];
}

export type ApiKeyCredentials = OnlineApiKeyCredentails | OfflineApiKeyCredentials;

class ApiKeysService {
  async getApiKeys(): Promise<{ data: ApiKeyCredentials[] }> {
    return axios.get('/api-keys');
  }
  async generateApiKey(name: string, scopes: string[], contractorIds: string[]) {
    return axios.post(`/api-keys/generate`, {
      name,
      scopes,
      contractorIds,
    });
  }
  async updateApiKey(identifier: string, name: string, scopes: string[], contractorIds: string[]) {
    return axios.put(`/api-keys`, {
      name,
      identifier,
      scopes,
      contractorIds,
    });
  }
  async deleteApiKey(identifier: string) {
    return axios.delete(`/api-keys`, {
      data: {
        identifier,
      },
    });
  }
}
const apiKeysService = new ApiKeysService();
export default apiKeysService;
