import { Panel } from 'primereact/panel';
import { useTranslation } from 'react-i18next';
import { useWebhooksData } from '../../../hooks/Webhooks/useWebhooksData';
import { WebhooksTable } from './components/WebhooksTable';
import { useCallback, useState } from 'react';
import WebhookDialog from './components/WebhookDialog';
import { Webhook } from '../../../types/webhook';

const Webhooks = () => {
  const { t } = useTranslation('managementWebhooks');
  const { fetchAPI, webhooks, contractors } = useWebhooksData();
  const [webhookDialogOpen, setWebhookDialogOpen] = useState(false);
  const [webhook, setWebhook] = useState<Webhook | null>(null);

  const handleCloseApiKeyDialog = useCallback(() => {
    setWebhookDialogOpen(false);
    setWebhook(null);
  }, [setWebhook, setWebhookDialogOpen]);

  return (
    <div className="card pb-8">
      <Panel header={t('header')} toggleable>
        <WebhooksTable
          fetchAPI={fetchAPI}
          webhooks={webhooks!}
          setWebhook={setWebhook}
          setWebhookDialogOpen={setWebhookDialogOpen}
        />
      </Panel>
      {webhookDialogOpen && (
        <WebhookDialog
          visible={webhookDialogOpen}
          closeDialog={handleCloseApiKeyDialog}
          item={webhook}
          fetchData={fetchAPI}
          contractors={contractors}
        />
      )}
    </div>
  );
};

export default Webhooks;
