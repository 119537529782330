import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Password } from 'primereact/password';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosConfigContainer } from '../components/AxiosConfig';
import FormErrorMessage from '../components/_shared/FormErrorMessage';
import LanguageSelectorUnauthorized from '../components/_shared/LanguageSelectorUnauthorized';
import usersService from '../services/ScaleoApiServices/UsersService';
import { uiActions } from '../store/ui-slice';
import { useTranslation } from 'react-i18next';

export const CreateAccount = () => {
  const { t } = useTranslation('createAccountPage');

  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();

  const formik = useFormik({
    initialValues: {
      password: '',
      consent: false,
      repeatPassword: '',
    },
    validate: (data) => {
      const errors = {};

      if (!data.password) {
        errors.password = t('noPasswordError');
      }
      if (!data.consent) {
        errors.consent = t('consent');
      }
      if (!data.repeatPassword) {
        errors.repeatPassword = t('noRepeatPasswordError');
      } else if (data.password !== data.repeatPassword) {
        errors.repeatPassword = t('repeatPasswordError');
      }

      return errors;
    },
    onSubmit: (formData, helpers) => {
      dispatch(uiActions.toggleIsLoading({ isLoading: true }));

      usersService
        .createAccount({
          invitationId: query.get('invitationId'),
          password: formData.password,
        })
        .then(() => {
          history.push(`/create-successfull`);
          scheduleHideLoader();
        })
        .catch((error) => {
          scheduleHideLoader();
          const errorCode = error.response.data.error;
          // TODO: i18n
          errorCode === 'INVALID_PASSWORD' && helpers.setFieldError('password', t('invalidPasswordError'));
          errorCode === 'WEAK_PASSWORD' && helpers.setFieldError('password', t('weakPasswordError'));
          errorCode === 'INVITATION_EXPIRED' && helpers.setFieldError('password', t('invitationExpiredError'));
        });
    },
  });

  const scheduleHideLoader = () => {
    setTimeout(() => {
      dispatch(uiActions.toggleIsLoading({ isLoading: false }));
    }, 400);
  };

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  return (
    <>
      <AxiosConfigContainer />
      <div className="login-body">
        <div className="card login-panel p-fluid">
          <LanguageSelectorUnauthorized visible={false} />
          <div className="login-panel-content">
            <form>
              <div className="grid">
                <div className="col-12 logo-container">
                  <img src="assets/layout/images/logo_scaleo.png" alt={t('logoAlt')} className="w-full" />
                </div>
                <div className="col-12 sm:col-6 md:col-6">
                  <span className="guest-sign-in">{t('header')}</span>
                </div>
                <div className="col-12 password-container">
                  <label>{t('password')}</label>
                  <Password
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    feedback={false}
                    toggleMask
                  />
                  <FormErrorMessage fieldName="password" formikInstance={formik} />
                </div>
                <div className="col-12 password-container">
                  <label>{t('repeatPassword')}</label>
                  <Password
                    id="repeatPassword"
                    name="repeatPassword"
                    value={formik.values.repeatPassword}
                    onChange={formik.handleChange}
                    feedback={false}
                    toggleMask
                  />
                  <FormErrorMessage fieldName="repeatPassword" formikInstance={formik} />
                </div>
                <div className="col-12">
                  <label>
                    {t('acceptRules1')}
                    <a href="https://scaleo.eu/files/regulamin.pdf" target="_blank" rel="noreferrer">
                      {t('rules')}
                    </a>{' '}
                    {t('acceptRules2')}
                    <a href="https://scaleo.eu/files/polityka_prywatnosci.pdf" target="_blank" rel="noreferrer">
                      {t('privatePolicy')}
                    </a>
                    .
                  </label>
                  <InputSwitch id="consent" checked={formik.values.consent} onChange={formik.handleChange} />
                  <FormErrorMessage fieldName="consent" formikInstance={formik} />
                </div>
              </div>
            </form>
            <div className="grid">
              <div className="col-6">
                <Button type="submit" label={t('submitButton')} icon="pi pi-check" onClick={formik.submitForm} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
