import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import edgeDevicesService from '../../../services/ScaleoApiServices/EdgeDevicesService';
import { Dictionary } from '../../../types/dictionary';
import { ValueDynamicSetting } from '../../../types/edge-device';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import FormErrorMessageScroller from '../../_shared/FormErrorMessageScroller';
import DynamicSettings from './DynamicSettings';

interface DynamicSettingsDialogProps {
  edgeDevice: any;
  dynamicSettings: ValueDynamicSetting[] | null;
  onCancel: () => void;
  visible: boolean;
  onConfigSaved: () => void;
  dictionaries: Dictionary[] | null;
}

const DynamicSettingsDialog = ({
  edgeDevice,
  dynamicSettings,
  onCancel,
  visible,
  onConfigSaved,
  dictionaries,
}: DynamicSettingsDialogProps) => {
  const { t } = useTranslation('managementDynamicSettings');

  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const formik = useFormik({
    initialValues: {} as { dynamicSettings: ValueDynamicSetting[] },
    validate: (data: any) => {
      const errors: any = {};

      (data?.dynamicSettings as ValueDynamicSetting[])?.map((dynamicSetting) => {
        return dynamicSetting.type !== 'boolean' && dynamicSetting?.required && !dynamicSetting.value
          ? (errors[dynamicSetting.name] = t('valueRequiredError'))
          : undefined;
      });

      return errors;
    },
    onSubmit: async () => {
      edgeDevicesService.saveDynamicSettings(edgeDevice.id, formik.values.dynamicSettings).then(() => {
        onConfigSaved();
      });
    },
  });

  useEffect(() => {
    dynamicSettings &&
      formik.resetForm({
        values: {
          dynamicSettings,
        },
      });
  }, [dynamicSettings]);

  const dialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={onCancel} />
      <Button
        type="submit"
        label={t('saveButton')}
        icon="pi pi-check"
        text
        onClick={async () => {
          setIsSubmitClicked(true);
          await formik.submitForm();
        }}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={visible}
        header={t('header')}
        modal
        className="p-fluid w-40vw"
        footer={dialogFooter}
        onHide={onCancel}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      >
        <form>
          <div className="col-8">
            <div className="field">
              <div className="formgrid grid">
                <DynamicSettings formik={formik} dictionaries={dictionaries} isSubmitClicked={isSubmitClicked} />
              </div>
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};

export default DynamicSettingsDialog;
