import axios from 'axios';

class LocationsService {
  async getLocationsSearch(filters: any, paginationParameters: any, globalFilter: any) {
    return axios.post('/locations/search', { filters, paginationParameters, globalFilter });
  }
  async getAllLocations() {
    return axios.get('/locations');
  }
  async postLocation(data: any) {
    return axios.post(`/locations`, data);
  }
  async editLocation(data: any, id: string) {
    return axios.put(`/locations/${id}`, data);
  }
  async removeLocation(id: string) {
    return axios.delete(`/locations/${id}`);
  }
}
const locationsService = new LocationsService();
export default locationsService;
