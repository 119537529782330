import { createSlice } from '@reduxjs/toolkit';
import { AppConfigSlice } from './types/appConfig-slice';

const initialState: AppConfigSlice = {
  unit: null,
  confirmationOfWindowChange: {
    required: false,
    trigger: null,
    save: false,
    saveMethod: null,
    approved: false,
  },
};

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState: { ...initialState },
  reducers: {
    // @ts-ignore
    resetState(_state: AppConfigSlice) {
      _state = { ...initialState };
    },
    setUnit(state: AppConfigSlice, value) {
      state.unit = value.payload;
    },
    setConfirmationOfWindowChange(state: AppConfigSlice, value) {
      state.confirmationOfWindowChange = value.payload;
    },
  },
});

export const appConfigActions = appConfigSlice.actions;

export default appConfigSlice;
