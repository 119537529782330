import axios from 'axios';

import { DateFilters, Filters } from '../../components/AccessControlEventLog/types';
import { PaginationParams } from '../../types/general-types';
import { dateParser } from '../../utils/dateParser';
import moment from 'moment';

class AccessControlEventLogService {
  async getLogs(filters: Filters, paginationParameters: PaginationParams, dateFilters: DateFilters) {
    const internalFilters = { ...filters, date: ['', ''] };

    if (dateFilters.startDate) {
      const endDate = moment(dateFilters.endDate || dateFilters.startDate)
        .add(23, 'hours')
        .add(59, 'minutes')
        .toDate()
        .toString();

      const parsedDates = dateParser(dateFilters.startDate, endDate);
      internalFilters.date = [parsedDates.startDate, parsedDates.endDate];
    }

    return axios.post('/access-control/event-log/search', { filters: internalFilters, paginationParameters });
  }
  async getAllLogs() {
    return axios.get('/access-control/event-log');
  }

  async getImageUrls(eventLogId: string) {
    return axios.get(`/access-control/event-log/${eventLogId}/image-urls`);
  }

  async getDownloadableImages(eventLogId: string) {
    return axios.get(`/access-control/event-log/${eventLogId}/download-images`);
  }
}

const accessControlEventLogService = new AccessControlEventLogService();
export default accessControlEventLogService;
