import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReducerState } from '../../../../../../types/reducer-state';

const INIT_STANDARD_COLUMNS = [
  'number',
  'transactionType',
  'indDates',
  'vehicle',
  'trailer',
  'contractor',
  'products',
  'indication1',
  'indication2',
  'netto',
  'sum',
  'driver',
  'scaleName',
];

interface ReportColumnsProps {
  isWeighingSeriesEnabled: boolean;
  checkedStandardColumns: any[];
  initAdditionalColumns: any[];
  checkedAdditionalColumns: any[];
  onStandardColumnChange: (e: any) => void;
  onAdditionalColumnChange: (e: any) => void;
}

const ReportColumns = ({
  isWeighingSeriesEnabled,
  checkedStandardColumns,
  initAdditionalColumns,
  checkedAdditionalColumns,
  onStandardColumnChange,
  onAdditionalColumnChange,
}: ReportColumnsProps) => {
  const { t } = useTranslation('weighingWeighingList');
  const { weighingConfiguration } = useSelector((state: ReducerState) => state.weighing);

  const columnTranslation = {
    number: t('reportColumnsNumber'),
    transactionType: t('reportColumnsTransactionType'),
    indDates: t('reportColumnsIndDates'),
    vehicle: t('reportColumnsVehicle'),
    trailer: t('reportColumnsTrailer'),
    contractor: t('reportColumnsContractor'),
    products: t('reportColumnsProducts'),
    indication1: t('reportColumnsInd1'),
    indication2: t('reportColumnsInd2'),
    netto: t('reportColumnsNetto'),
    sum: t('reportColumnsSum'),
    driver: t('reportColumnsDriver'),
    scaleName: t('reportColumnsScaleName'),
  };

  const getAllStandardColumns = () => {
    const columns = [...INIT_STANDARD_COLUMNS];
    if (!isWeighingSeriesEnabled) {
      columns.splice(columns.indexOf('sum'), 1);
    }

    if (!weighingConfiguration.addTrailerRegistrationNumbersSupport) {
      columns.splice(columns.indexOf('trailer'), 1);
    }

    return columns;
  };

  const standardColumns = getAllStandardColumns().map((column) => {
    return (
      <div className="field-checkbox w-50p">
        <Checkbox
          inputId={column}
          name="reportCol"
          value={column}
          onChange={onStandardColumnChange}
          checked={checkedStandardColumns.indexOf(column) !== -1}
        />
        <label htmlFor={column}>{columnTranslation[column as keyof typeof columnTranslation]}</label>
      </div>
    );
  });

  const additionalColumns = initAdditionalColumns.map((column) => {
    return (
      <div className="field-checkbox w-50p">
        <Checkbox
          inputId={column.name}
          name="reportCol"
          value={column.name}
          onChange={onAdditionalColumnChange}
          checked={checkedAdditionalColumns.indexOf(column.name) !== -1}
        />
        <label htmlFor={column.name}>{column.name}</label>
      </div>
    );
  });

  return (
    <>
      <Fieldset legend={t('reportColumnsPrimaryFields')} toggleable>
        <div className="flex flex-wrap">{standardColumns}</div>
      </Fieldset>
      <br />
      <Fieldset legend={t('reportColumnsAdditionalFields')} toggleable collapsed>
        <div className="flex flex-wrap">{additionalColumns}</div>
      </Fieldset>
    </>
  );
};
export default ReportColumns;
