import { PaginationParams } from '../../types/general-types';
import { Filters } from './types';
import React from 'react';

export const handleSort = (
  e: any,
  paginationParameters: PaginationParams,
  setPaginationParameters: React.Dispatch<React.SetStateAction<PaginationParams>>,
  fetchData: any,
) => {
  const newPaginationParameters = {
    ...paginationParameters,
    order: {
      isAscending: e.sortOrder === 1 ? true : false,
      orderColumn: e.sortField,
    },
  };

  setPaginationParameters(newPaginationParameters);

  void fetchData(newPaginationParameters);
};

export const changeFilter = (
  value: any,
  name: string,
  filters: Filters,
  setFilters: React.Dispatch<React.SetStateAction<Filters>>,
) => {
  const newFilters: any = { ...filters };

  name === 'date'
    ? value
      ? (newFilters[name] = [value[0].toString(), value[1] ? value[1].toString() : null])
      : (newFilters[name] = null)
    : (newFilters[name] = value);

  setFilters(newFilters);
};

export const handleClickClearFilters = (setFilters: React.Dispatch<React.SetStateAction<Filters>>) => {
  setFilters({
    validFrom: null,
    validTo: null,
    registrationNumber: null,
    trailerRegistrationNumber: null,
    driver: null,
    contractor: null,
    product: null,
    number: null,
    global: null,
  });
};
