import { useFormik } from 'formik';
import { NodeType } from '../../types/node';
import { TFunction } from 'i18next';
import nodesService from '../../services/ScaleoApiServices/NodesService';
import validator from 'validator';
import { ConnectionType } from '../../enums/connection-type.enum';

const weightIndicatorErrors = (data: any, errors: any, t: TFunction) => {
  if (!data.weighingUnit) {
    errors.weighingUnit = t('noWeighingUnitError');
  }

  if (!data.division) {
    errors.division = t('noDivisionError');
  }

  if (!data.maxWeight) {
    errors.maxWeight = t('noMaxWeightError');
  }

  if (data.maxWeight && !validator.isNumeric(data.maxWeight)) {
    errors.maxWeight = t('incorrectMaxWeightError');
  }

  if (!data.connectionType) {
    errors.connectionType = t('noConnectionType');
  }

  if (data?.connectionType === ConnectionType.SERIAL_PORT && !data.baudrate) {
    errors.baudrate = t('noBaudrateError');
  }

  if (data?.connectionType === ConnectionType.SERIAL_PORT && data.baudrate && !validator.isNumeric(data.baudrate)) {
    errors.baudrate = t('incorrectBaudrateError');
  }

  if (
    data.playSoundSpecifiedWeight === 0 ||
    data.playSoundSpecifiedWeight === '0' ||
    data.playSoundSpecifiedWeight === '' ||
    (data.playSoundSpecifiedWeight && !validator.isNumeric(data.playSoundSpecifiedWeight?.toString()))
  ) {
    errors.playSoundSpecifiedWeight = t('playSoundSpecifiedWeightError');
  }
};

const displayErrors = (data: any, errors: any, t: TFunction) => {
  if (!data.sourceScale) {
    errors.sourceScale = t('noSourceScaleError');
  }
};

const useManagementNodesForm = (
  managementNodesData: any,
  handleOnSubmit: () => void,
  initialNodeValues: any,
  t: TFunction,
) => {
  const formik = useFormik({
    initialValues: managementNodesData,
    validate: (data) => {
      const errors = {} as any;

      if (!data.type) {
        errors.type = t('noTypeError');
      } else {
        if (!data.name) {
          errors.name = t('noNameError');
        }

        if (!data.name) {
          errors.name = t('incorrectNameError');
        }

        if (!data.station) {
          errors.station = t('noStationError');
        }

        if (!data.nodeModel) {
          errors.nodeModel = t('noNodeModelError');
        }

        if (data?.type === NodeType.WEIGHT_INDICATOR && data.testScale) return errors;

        if (data?.type === NodeType.WEIGHT_INDICATOR) weightIndicatorErrors(data, errors, t);

        if ((data?.connectionType === ConnectionType.TCP || data?.type !== NodeType.WEIGHT_INDICATOR) && !data.host) {
          errors.host = t('noHostError');
        }

        if ((data?.connectionType === ConnectionType.TCP || data?.type !== NodeType.WEIGHT_INDICATOR) && !data.host) {
          errors.host = t('incorrectHostError');
        }

        // TODO: hotfix to be able to provide serial port
        // if (data.host && !validator.isURL(data.host) && !validator.isUIP(data.host)) {
        //   errors.host = t('incorrectHostError');
        // }

        if (!data.port) {
          errors.port = t('noPortError');
        }

        if (data.type === NodeType.DISPLAY) displayErrors(data, errors, t);
      }

      return errors;
    },
    onSubmit: async (formData, helpers) => {
      if (!formData.testScale) formData.testScale = false;

      try {
        if (!formData.id) {
          await nodesService.createNode(formData);
        } else {
          await nodesService.updateNode(formData, formData.id);
        }
        handleOnSubmit();
      } catch (err: any) {
        if (err.response && err.response.status === 409) {
          helpers.setFieldError('name', t('nodeExistError'));
        }
      } finally {
        helpers.setSubmitting(false);
      }
    },
    enableReinitialize: true,
  });

  const resetForm = (values: any = initialNodeValues) => {
    formik.resetForm({ values });
  };

  return { formik, resetForm };
};

export default useManagementNodesForm;
