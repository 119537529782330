import axios from 'axios';

class PermissionsService {
  async getPermissions() {
    return axios.get('/permissions');
  }
  async searchPermissions(filter) {
    return axios.post('/permissions/search', { filter });
  }
}
const permissionsService = new PermissionsService();
export default permissionsService;
