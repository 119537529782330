const emptyWeighing = {
  id: null,
  netto: '',
  alibi: null,
  location: null,
  registrationNumber: '',
  contractor: '',
  productsSummary: '',
  driver: '',
  number: null,
  tare: {
    timestamp: '',
  },
  indication: '',
  weighingMode: 'MANUAL',
  scale: '',
};

const emptyFilters = {
  global: null,
  'vehicles.registration_number': null,
  netto: null,
  transaction_type: null,
  'contractors.name': null,
  'products.name': [],
  date: null,
  additional_field: {},
  scale_name: null,
};

const emptyPaginationParameters = {
  order: {
    isAscending: false,
    orderColumn: 'closedAt',
    orderByAdditionalFields: false,
  },
  page: {
    index: 1,
    size: 10,
  },
};

const emptyPaginatorState = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

const getWeighingMainColumnsConfig = (t: any) => {
  return [
    {
      id: '590ccdf8-9a6b-47c1-9db8-a2ccb72aa4e6',
      standardCol: true,
      field: 'scalesNames',
      name: t('columnNamesScale'),
      type: 'scalesNames',
      filterField: 'scale_name',
      sortable: false,
      dropdown: true,
      source: 'inputScales',
    },
    {
      id: '5b4ba6bf-7f36-45a0-9522-1e95146b69ad',
      standardCol: true,
      field: 'number',
      name: t('columnNamesNumber'),
      type: 'text',
      filterField: 'number',
      sortable: true,
    },
    {
      id: 'bf47548c-ab34-49db-9e19-58bf1e26537f',
      standardCol: true,
      field: 'transactionType',
      name: t('columnNamesWeighingType'),
      type: 'transactionType',
      filterField: 'transaction_type',
      dropdown: true,
      source: 'transactionTypes',
      sortable: true,
    },
    {
      id: 'b35ddc3f-9efd-45a3-9598-94be46af5edb',
      standardCol: true,
      field: 'location.name',
      name: t('columnNamesLocation'),
      type: 'subfield',
      filterField: 'locations.name',
      dropdown: true,
      source: 'locations',
      sortable: true,
    },
    {
      id: '19e9dda7-3f4a-4cd6-9aad-5d45b2a32739',
      standardCol: true,
      field: 'vehicle.registrationNumber',
      name: t('columnNamesRegistrationNumber'),
      type: 'subfield',
      filterField: 'vehicles.registration_number',
      autocomplete: true,
      source: 'inputVehicles',
      sortable: true,
    },
    {
      id: '3f0cdda5-f000-426b-82c2-2ba7b7fb0fa0',
      standardCol: true,
      field: 'trailer.registrationNumber',
      name: t('columnNamesTrailerRegistrationNumber'),
      type: 'subfield',
      filterField: 'trailers.registration_number',
      autocomplete: true,
      source: 'inputTrailers',
      sortable: false,
    },
    {
      id: 'e81d837c-ab79-47b1-91db-2ada0e02453b',
      standardCol: true,
      field: 'contractor.name',
      name: t('columnNamesContractor'),
      type: 'subfield',
      filterField: 'contractors.name',
      autocomplete: true,
      source: 'inputContractors',
      sortable: true,
    },
    {
      id: '1556711d-e6e2-4e22-8cc8-fa89b691a3a2',
      standardCol: true,
      field: 'driver.name',
      name: t('columnNamesDriver'),
      type: 'subfield',
      filterField: 'driver',
      autocomplete: true,
      source: 'inputDrivers',
      sortable: true,
    },
    {
      id: 'cb193576-0c32-4955-89dc-d72256641ef7',
      standardCol: true,
      field: 'product.name',
      name: t('columnNamesProduct'),
      type: 'product',
      filterField: 'products.name',
      multiselect: true,
      source: 'inputProducts',
      sortable: false,
    },
    {
      id: '48dfd467-753e-471d-9085-6b3a7bb0542b',
      standardCol: true,
      field: 'netto',
      name: t('columnNamesNetto'),
      type: 'netto',
      filterField: 'netto',
    },
    {
      id: '47aaeb9c-b15a-4216-9862-ab53a7911df7',
      standardCol: true,
      field: 'sum',
      name: t('reportColumnsSum'),
      type: 'sum',
      filterField: 'sum',
    },
    {
      id: 'a761eeec-d85b-4313-990d-1c17e54569f1',
      standardCol: true,
      field: 'isConfirmed',
      name: t('reportColumnsIsConfirmed'),
      type: 'isConfirmed',
      dropdown: true,
      source: 'trueFalseStatuses',
      filterField: 'isConfirmed',
    },
    {
      id: 'cd4b9e86-b43e-435f-a3e1-94d3bd26cba7',
      standardCol: true,
      field: 'containsAnyContainers',
      name: t('reportColumnsContainsAnyContainers'),
      type: 'containsAnyContainers',
      dropdown: true,
      source: 'trueFalseStatuses',
      filterField: 'containsAnyContainers',
    },
    {
      id: '70a5021e-aa9c-4f12-956c-b5ede517f3ad',
      standardCol: true,
      field: 'cardId',
      name: t('columnNamesCardId'),
      type: 'bdoCard',
      filterField: 'card_type',
      dropdown: true,
      source: [
        { value: 'kpo', label: t('columnNamesCardIdKpo') },
        { value: 'kpok-receive', label: t('columnNamesCardIdKpokReceive') },
        { value: 'kpok-transfer', label: t('columnNamesCardIdKpokTransfer') },
      ],
      requiredModuleName: 'modulesConfiguration.bdo',
    },
  ];
};

const translateWeighingListColSource = (t: any, source: any) => {
  if (!!source && Array.isArray(source)) {
    return source.map((el) => {
      return { ...el, label: t(el.label) };
    });
  }

  return source;
};

export {
  emptyWeighing,
  emptyFilters,
  emptyPaginationParameters,
  emptyPaginatorState,
  getWeighingMainColumnsConfig,
  translateWeighingListColSource,
};
