import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import weighingConfigurationService from '../../services/ScaleoApiServices/WeighingConfigurationService';
import weighingsService from '../../services/ScaleoApiServices/WeighingsService';
import { weighingActions } from '../../store/weighing-slice';
import { Weighing } from '../../types/weighing';
import { reduceAdditionalFieldsArrayToObject } from '../../utils/additionalFieldsUtils';
import {
  synchronizeAdditionalFieldsWithConfiguration,
  mergeAdditionalFields,
} from '../../utils/additionalFieldsUtilsForNewWeighingForm';
import { ReducerState } from '../../types/reducer-state';

export const useNewWeighingFormData = (
  fetchComponentData: () => Promise<void>,
  transactionTypes: any,
  setContinueWeighingDialogData: Function,
  setWeighingInformationFieldsValues: Function,
) => {
  const dispatch = useDispatch();
  const [initFormValues, setInitFormValues] = useState({});
  const { currentLocationId, currentCustomer } = useSelector((state: ReducerState) => state.user.context);
  const { weighingConfiguration, shouldReloadWeighingForm, formState, weighingState, allOpenWeighings } = useSelector(
    (state: ReducerState) => state.weighing,
  );

  const updateFormState = (state: any) => dispatch(weighingActions.updateFormState(state));

  useEffect(() => {
    setInitFormValues({
      ...formState,
    });
  }, [JSON.stringify(formState.additionalFields), JSON.stringify(formState.measurementAdditionalFields)]);

  useEffect(() => {
    setInitFormValues({
      ...formState,
    });
  }, [formState.saveVehicle, formState.updateTare, formState.updateData, formState.printPosReceipt]);

  const fetchInitValues = useCallback(async () => {
    const state = {
      vehicle: formState.selectedVehicle,
      transactionType:
        formState.selectedTransactionType === null && weighingConfiguration.transactionTypeDefaultId
          ? transactionTypes?.find(
              (transactionType: any) => transactionType.id === weighingConfiguration.transactionTypeDefaultId,
            )
          : formState.selectedTransactionType,
      contractor: formState.selectedContractor,
      product: formState.selectedProduct,
      driver: formState.selectedDriver,
      container: formState.selectedContainer,
      saveVehicle:
        formState.saveVehicle != null ? formState.saveVehicle : weighingConfiguration.saveVehicleDefaultValue,
      updateTare:
        formState.updateTare != null ? formState.updateTare : weighingConfiguration.updateVehicleTareDefaultValue,
      updateData:
        formState.updateData != null ? formState.updateData : weighingConfiguration.updateVehicleDataDefaultValue,
      printPosReceipt:
        formState.printPosReceipt != null
          ? formState.printPosReceipt
          : weighingConfiguration.printPosReceiptDefaultValue,
      additionalFields: await synchronizeAdditionalFieldsWithConfiguration(
        reduceAdditionalFieldsArrayToObject(formState.additionalFields) || {},
        'WEIGHING',
        formState.selectedProduct?.id,
        formState.selectedWeighingModeId,
        !!weighingState?.openWeighing?.id,
      ),
      measurementAdditionalFields: await synchronizeAdditionalFieldsWithConfiguration(
        reduceAdditionalFieldsArrayToObject(formState.measurementAdditionalFields) || {},
        'MEASUREMENT',
        formState.selectedProduct?.id,
        formState.selectedWeighingModeId,
        !!weighingState?.openWeighing?.id,
      ),
      containers: formState.containers,
      trailer:
        typeof formState.selectedTrailer === 'object' && !formState.selectedTrailer?.registrationNumber
          ? null
          : formState.selectedTrailer,
    };
    setInitFormValues(state);
    updateFormState(state);
  }, [
    JSON.stringify({
      ...formState,
      saveVehicle: null,
      updateTare: null,
      updateData: null,
      printPosReceipt: null,
      additionalFields: null,
      measurementAdditionalFields: null,
      containers: [],
    }),
    weighingState?.openWeighing?.id,
  ]);

  useEffect(() => {
    fetchInitValues();
  }, [fetchInitValues]);

  useEffect(() => {
    fetchComponentData();
  }, [fetchComponentData, currentLocationId, currentCustomer?.id]);

  useEffect(() => {
    shouldReloadWeighingForm && fetchComponentData();
    dispatch(weighingActions.weighingFormReloaded());
  }, [fetchComponentData, shouldReloadWeighingForm]);

  const fetchVehicleAndTrailerWeighingState = useCallback(
    async (vehicle, trailer) => {
      const response = await weighingsService.getVehicleWeighingState(vehicle.id, trailer?.id);
      if (
        response.data.allOpenWeighings.length &&
        (!!weighingConfiguration.multipleOpenWeighingsForTheSameVehicle || response.data.allOpenWeighings.length > 1) &&
        !formState.continueWeighing
      ) {
        setContinueWeighingDialogData({ visible: true, weighingState: response.data });
      } else {
        dispatch(
          weighingActions.weighingStateFetched({
            ...response.data,
            openWeighing: formState.continueWeighing || response.data.openWeighing,
          }),
        );
        await setWeighingInformationFieldsValues(formState.continueWeighing || response.data.openWeighing, vehicle);
        if (formState.continueWeighing) {
          dispatch(weighingActions.updateFormState({ continueWeighing: false }));
        }
      }
    },
    [
      dispatch,
      weighingConfiguration.multipleOpenWeighingsForTheSameVehicle,
      formState.continueWeighing,
      JSON.stringify(allOpenWeighings),
    ],
  );

  const fetchAdditionalFields = useCallback(
    async (productId: string, openWeighing: Weighing | null, clearState?: boolean, disableDefaultValues?: boolean) => {
      let responseWeighing = [] as any;
      let responseMeasurement = [] as any;
      let additionalFields = [];
      let measurementAdditionalFields = [];

      const _weighingMode = openWeighing ? openWeighing.weighingMode : formState.selectedWeighingModeId;

      const getAdditionalFieldsFromState = () => {
        if (clearState) {
          return null;
        }
        return {
          weighing: reduceAdditionalFieldsArrayToObject(formState.additionalFields),
          measurement: reduceAdditionalFieldsArrayToObject(formState.measurementAdditionalFields),
        };
      };

      responseWeighing = await weighingConfigurationService.searchAdditionalFields(
        {
          productIds: [productId],
          destination: 'WEIGHING',
          weighingMode: _weighingMode,
          isActive: true,
        },
        { show_loader: false },
      );
      responseMeasurement = await weighingConfigurationService.searchAdditionalFields(
        {
          productIds: [productId],
          destination: 'MEASUREMENT',
          weighingMode: _weighingMode,
          isActive: true,
        },
        { show_loader: false },
      );
      const additionalFieldsFromState = getAdditionalFieldsFromState();

      additionalFields = mergeAdditionalFields(
        additionalFieldsFromState?.weighing || openWeighing?.additionalFields || {},
        responseWeighing.data,
        [productId],
        !!disableDefaultValues,
      );

      measurementAdditionalFields = mergeAdditionalFields(
        additionalFieldsFromState?.measurement || openWeighing?.measurements[0].additionalFields || {},
        responseMeasurement.data,
        [productId],
        !!disableDefaultValues,
      );

      additionalFields = additionalFields.filter((field) => !!field.isActive);
      measurementAdditionalFields = measurementAdditionalFields.filter(
        (field) => !!field.isActive && ['DOUBLE', 'MULTIPLE'].includes(_weighingMode!),
      );

      return {
        additionalFields,
        measurementAdditionalFields,
      };
    },
    [formState.selectedWeighingModeId, dispatch],
  );

  return { initFormValues, fetchAdditionalFields, fetchVehicleAndTrailerWeighingState };
};
