import { NodeState } from '../../../../../../store/types/node-slice';
import ControlButton from './ControlButton';
import { NodeControl } from '../../../../../../types/controls';

type ControlProps = NodeControl & {
  nodeStatus?: NodeState;
};

const NodeControlElement = ({ node, label, modes, nodeId, nodeStatus }: ControlProps) => {
  const currentMode =
    nodeStatus && modes.find((el) => el.signal === nodeStatus.signalName && el.signalValue === nodeStatus.value);

  return (
    <div className={`weighing-sidebar-node ${!currentMode && 'weighing-sidebar-node--disabled'}`} key={node}>
      <i
        className={`weighing-sidebar-node-icon pi ${currentMode?.icon || 'pi-times'}`}
        style={{ color: currentMode?.color || '#808080' }}
      />
      <span className="weighing-sidebar-node-label">
        <b>{label}</b>
      </span>
      <span className="weighing-sidebar-node-button w-8vw">
        <ControlButton currentMode={currentMode} nodeId={nodeId} node={node} />
      </span>
    </div>
  );
};

export default NodeControlElement;
