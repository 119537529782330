import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import useReceiptFieldsOrderData from '../../../../../hooks/_shared/useReceiptFieldsOrderData';
import { InputNumber } from 'primereact/inputnumber';

interface ReceiptFieldsOrderSectionProps {
  formikInstance: any;
}

const ReceiptFieldsOrderSection = ({ formikInstance }: ReceiptFieldsOrderSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationPrints');
  const [isEditDialogVisible, setEditDialogVisible] = useState(false);

  const { data, setData } = useReceiptFieldsOrderData(
    formikInstance.values.additionalFields,
    formikInstance.values.receiptFieldsOrder,
  );

  const handleOpenDialog = () => {
    setEditDialogVisible(true);
  };

  const handleSave = () => {
    formikInstance.setFieldValue(
      'receiptFieldsOrder',
      data.map((singleData: any) => ({
        id: singleData.id,
        value: singleData.value,
      })),
      false,
    );
    setEditDialogVisible(false);
  };

  const dialogFooter = (
    <>
      <Button type="button" onClick={() => setEditDialogVisible(false)} label={t('cancelButton')} />
      <Button type="button" onClick={handleSave} label={t('saveButton')} />
    </>
  );

  const renderField = (field: any, index: number) => {
    const handleChange = (v: any) => {
      const newValue = [...data];
      newValue.find((el) => el.id === field.id).value = v;
      setData(newValue);
    };

    return (
      <div className="p-fluid formgrid grid">
        <label htmlFor={`field-${index}`} className="col-3">
          {field.name}
        </label>
        <div className="field col-2">
          <InputNumber
            id={`field-${index}`}
            mode="decimal"
            value={field?.value}
            min={1}
            onChange={(e: any) => handleChange(e.value)}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Fieldset legend={t('fieldsOrderHeader')} className="mt-1vh">
        <Button
          type="button"
          label={t('editButton')}
          icon="pi pi-plus"
          severity="success"
          className="mt-4"
          onClick={handleOpenDialog}
        />
      </Fieldset>

      {isEditDialogVisible && (
        <Dialog
          visible={true}
          header={t('fieldsOrderHeader')}
          modal
          className="p-fluid w-40vw"
          breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
          footer={dialogFooter}
          onHide={() => setEditDialogVisible(false)}
        >
          <form>{data.map((element: any, index: number) => renderField(element, index))}</form>
        </Dialog>
      )}
    </>
  );
};

export default ReceiptFieldsOrderSection;
