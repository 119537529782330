import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import React, { useEffect, useState } from 'react';
import locationsService from '../../../../services/ScaleoApiServices/LocationsService';
import productsService from '../../../../services/ScaleoApiServices/ProductsService';
import { CustomAutoComplete } from '../../../_shared/CustomComponents';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import contractorsService from '../../../../services/ScaleoApiServices/ContractorsService';
import { useSelector } from 'react-redux';
import useTransactionType from '../../../../utils/useTransactionType';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../types/reducer-state';
import { Product } from '../../../../types/weighing';
import { ProductLocation } from '../../../../types/product-location';
import { Product as ProductProps } from '../../../../types/product';

interface NewOrEditProductDialogProps {
  productDialogVisible: boolean;
  setProductDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
  items: any;
  onProductAdded?: (selectedProduct: Product) => void;
}

const INIT_FORM_STATE = {
  id: null,
  code: '',
  name: '',
  locations: '',
  contractors: '',
  transactionType: {
    id: '',
    name: '',
  },
  active: true,
};

export const NewOrEditProductDialog = ({
  productDialogVisible,
  setProductDialogVisible,
  items,
  onProductAdded,
}: NewOrEditProductDialogProps) => {
  const { t } = useTranslation('datasetsProducts');

  const { currentLocationId } = useSelector((state: ReducerState) => state.user.context);

  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const [inputLocations, setInputLocations] = useState([]);
  const [inputContractors, setInputContractors] = useState([]);

  const { ALL_TRANSACTION_TYPES, getTransactionTypeLabel } = useTransactionType();

  const hideDialog = () => {
    setProductDialogVisible(false);
    formik.resetForm({ values: INIT_FORM_STATE });
  };

  useEffect(() => {
    Promise.all([locationsService.getAllLocations(), contractorsService.getContractors()]).then((response) => {
      if (currentLocationId) {
        const currentLocations = response[0].data.filter(
          (location: ProductLocation) => location.id === currentLocationId,
        );
        currentLocations.unshift({ name: t('allLocations'), id: null });
        setInputLocations(currentLocations);
      } else {
        response[0].data.unshift({ name: t('allLocations'), id: null });
        setInputLocations(response[0].data);
      }
      response[1].data.unshift({ name: t('allLocations'), id: null });
      setInputContractors(response[1].data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocationId]);

  useEffect(() => {
    if (productDialogVisible && items?.id) {
      setInitFormValues({
        ...items,
        contractors: items.contractors?.name ? items.contractors : { id: null, name: t('allContractors') },
        transactionType: items.transactionType
          ? { id: items.transactionType, name: getTransactionTypeLabel(items.transactionType) }
          : { id: null, name: t('allTransactionType') },
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDialogVisible, items]);

  const checkIfEntityExistsError = (error: any, helpers: any) => {
    if (error.response.status === 409) {
      if (error.response.data.error.includes('code')) {
        helpers.setFieldError('code', t('codeExistError'));
      }

      if (error.response.data.error.includes('name')) {
        helpers.setFieldError('name', t('nameExistError'));
      }
    }
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: ProductProps) => {
      const errors: any = {};

      if (!data.code) {
        errors.code = t('noCodeError');
      }
      if (!data.name) {
        errors.name = t('noNameError');
      }
      if (!data.locations) {
        errors.locations = t('noLocationsError');
      }

      return errors;
    },
    onSubmit: async (formData: ProductProps, helpers) => {
      if (!formData.id) {
        productsService
          .createProduct(formData)
          .then((res) => {
            hideDialog();
            onProductAdded && onProductAdded(productsService.mapProduct(res.data));
          })
          .catch((error) => {
            checkIfEntityExistsError(error, helpers);
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        productsService
          .editProduct(formData, formData.id)
          .then(() => {
            hideDialog();
          })
          .catch((error) => {
            checkIfEntityExistsError(error, helpers);
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
    enableReinitialize: true,
  });

  const productDialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={hideDialog} />
      <Button type="submit" label={t('saveButton')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={productDialogVisible}
        header={t('details')}
        modal
        className="p-fluid w-40vw"
        footer={productDialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      >
        <form>
          <div className="col-12">
            <div className="field">
              <label htmlFor="code">{t('code')}</label>
              <InputText id="code" value={formik.values.code} onChange={formik.handleChange} autoFocus />
              <FormErrorMessage fieldName="code" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="name">{t('name')}</label>
              <InputText id="name" value={formik.values.name} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="locations">{t('locations')}</label>
              <CustomAutoComplete
                id="locations"
                value={formik.values.locations}
                handler={formik.handleChange}
                source={inputLocations}
              />
              <FormErrorMessage fieldName="locations" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="contractors">{t('contractors')}</label>
              <CustomAutoComplete
                id="contractors"
                value={formik.values.contractors}
                handler={formik.handleChange}
                source={inputContractors}
              />
            </div>
            <div className="field">
              <label htmlFor="transactionType">{t('transactionType')}</label>
              <CustomAutoComplete
                id="transactionType"
                value={formik.values.transactionType}
                handler={formik.handleChange}
                source={[...ALL_TRANSACTION_TYPES, { id: null, name: t('allTransactionType') }]}
              />
            </div>
            <div className="field mt-4 flex justify-content-between">
              <label htmlFor="active">{t('activeProduct')}</label>
              <InputSwitch id="active" checked={formik.values.active} onChange={formik.handleChange} className="" />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};
