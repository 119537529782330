import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { SplitButton } from 'primereact/splitbutton';
import { Message } from 'primereact/message';
import { useTranslation } from 'react-i18next';

interface EditFormulaDialogProps {
  visible: any;
  closeDialog: any;
  onChangeValue: any;
  initValue: any;
  allAdditionalFields: any;
  parentDialogRef: any;
}

const EditFormulaDialog = ({
  visible,
  closeDialog,
  onChangeValue,
  initValue,
  allAdditionalFields,
  parentDialogRef,
}: EditFormulaDialogProps) => {
  const { t } = useTranslation('managementSystemConfigurationWeighing');

  const [value, setValue] = useState(initValue || '');
  const additionalFieldsItems = allAdditionalFields
    .filter((field: any) => field.type === 'NUMBER')
    .map((field: any) => {
      const identifier =
        field.destination === 'WEIGHING' ? `{AF.${field.name}}` : `{AF.${field.name}_MeasurementIndex}`;
      return { label: field.name, command: () => insertIntoFormula(identifier) };
    });

  const handleSubmit = () => {
    const formattedValue = value.replace(/\./g, ',').replace(/AF./g, 'AF.');
    onChangeValue(formattedValue);
    closeDialog(true, formattedValue);
  };

  const dialogFooter = (
    <div className="grid justify-content-center">
      <Button type="button" onClick={closeDialog} label={t('cancelButton')} />
      <Button type="button" onClick={handleSubmit} label={t('confirmButton')} />
    </div>
  );

  const handleChange = (formula: any) => setValue(formula);

  const insertIntoFormula = (formulaPart: any) => setValue(`${value}${formulaPart} `);

  return (
    <Dialog
      visible={visible}
      header={t('formulaDialogHeader')}
      modal
      className="p-fluid w-40vw"
      breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      footer={dialogFooter}
      onHide={closeDialog}
      appendTo={parentDialogRef.current}
    >
      <Message severity="info" text={t('formulaDialogInfo')} />
      <div className="p-fluid formgrid grid mt-5">
        <div className="col-3">
          <Button
            label={t('formulaDialogButtonsBrutto')}
            className="button-info mb-2"
            onClick={() => insertIntoFormula('{brutto}')}
          />
          <Button
            label={t('formulaDialogButtonsNetto')}
            className="button-info mb-2"
            onClick={() => insertIntoFormula('{netto}')}
          />
          <Button
            label={t('formulaDialogButtonsTare')}
            className="button-info mb-2"
            onClick={() => insertIntoFormula('{tare}')}
          />
          <SplitButton
            label={t('formulaDialogButtonsAf')}
            className="button-info mb-2"
            model={additionalFieldsItems}
            disabled={!additionalFieldsItems.length}
          />
        </div>
        <div className="col-5"></div>
        <div className="col-2">
          <Button label="*" severity="secondary" className="mb-2" onClick={() => insertIntoFormula('*')} />
          <Button label="/" severity="secondary" className=" mb-2" onClick={() => insertIntoFormula('/')} />
          <Button label="+" severity="secondary" className=" mb-2" onClick={() => insertIntoFormula('+')} />
          <Button label="-" severity="secondary" className=" mb-2" onClick={() => insertIntoFormula('-')} />
        </div>
        <div className="col-2">
          <Button label="(" severity="secondary" className="mb-2" onClick={() => insertIntoFormula('(')} />
          <Button label=")" severity="secondary" className="mb-2" onClick={() => insertIntoFormula(')')} />
        </div>
        <div className="col-12 flex align-items-center mt-4">
          <label htmlFor="formula" className="white-space-nowrap">
            {t('formulaDialogFormula')}&nbsp;
          </label>
          <InputText id={'formula'} value={value} onChange={(e) => handleChange(e.target.value)} />
        </div>
      </div>
    </Dialog>
  );
};

export default EditFormulaDialog;
