import axios from 'axios';

class WeighingUnitsService {
  async getWeighingUnits() {
    return axios.get(`/weighing-units`);
  }
}

const weighingUnitsService = new WeighingUnitsService();
export default weighingUnitsService;
