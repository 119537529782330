import { useState, useEffect } from 'react';
import { PaginationParams, PaginatorState } from '../../types/general-types';

const INITIAL_PAGINATOR_STATE: PaginatorState = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

const usePagination = (fetchData: any, orderColumn: string, isAscending = false) => {
  const INITIAL_PAGINATION_PARAMETERS: PaginationParams = {
    order: {
      isAscending,
      orderColumn: orderColumn,
    },
    page: {
      index: 1,
      size: 10,
    },
  };

  const [paginationParameters, setPaginationParameters] = useState(INITIAL_PAGINATION_PARAMETERS);
  const [paginatorState, setPaginatorState] = useState(INITIAL_PAGINATOR_STATE);

  useEffect(() => {
    const params = {
      ...paginationParameters,
      page: {
        index: paginatorState.currentPage,
        size: paginatorState.rows,
      },
    };
    setPaginationParameters(params);
    fetchData(params);
  }, [paginatorState]);

  return { paginationParameters, setPaginationParameters, paginatorState, setPaginatorState };
};

export default usePagination;
