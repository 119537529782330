import axios from 'axios';
import moment from 'moment';
import { formatStringToDate } from '../../utils/formatUtils';
import { Trailer } from '../../types/trailer';

class TrailersService {
  formatSearchFilters(filters: any) {
    const startOfDay = (day: number) => moment(day).startOf('day').toDate().toString();
    const endOfDay = (day: number) => moment(day).endOf('day').toDate().toString();

    const prepareDateFilter = (_filters: any) => {
      if (_filters?.tareTimestamp) {
        if (_filters.tareTimestamp[1]) {
          return {
            ..._filters,
            tareTimestamp: [startOfDay(_filters.tareTimestamp[0]), endOfDay(_filters.tareTimestamp[1])],
          };
        } else {
          return {
            ..._filters,
            tareTimestamp: [startOfDay(_filters.tareTimestamp[0]), endOfDay(_filters.tareTimestamp[0])],
          };
        }
      } else {
        return _filters;
      }
    };

    return {
      ...filters,
      tareTimestamp: prepareDateFilter(filters).tareTimestamp?.map((stringDate: string) =>
        formatStringToDate(stringDate),
      ),
    };
  }
  async searchTrailers(filters: any, paginatorParam: any) {
    const trailers = await axios.post(`/trailers/search`, {
      filters: this.formatSearchFilters(filters),
      paginatorParam,
    });

    return { data: trailers.data.data, totalPages: trailers.data.totalPages };
  }

  async getTrailers() {
    return axios.get(`/trailers`);
  }

  async createTrailer(trailer: Trailer) {
    return axios.post(`/trailers`, {
      data: trailer,
    });
  }

  async editTrailer(trailer: Trailer, id: string) {
    return axios.put(`/trailers/${id}`, {
      data: trailer,
    });
  }

  async removeManyTrailers(trailers: any[]) {
    return axios.post(`/trailers/remove-many`, {
      data: trailers.map((v) => v.id),
    });
  }
}

const trailersService = new TrailersService();
export default trailersService;
