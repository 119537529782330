import { downloadImages, serviceToCall } from '../../components/AccessControlEventLog/helpers';
import SnapshotsDialog from '../../components/SnapshotDialog/SnapshotDialog';
import { SNAPSHOT_DIALOG_TYPE } from '../../enums/snapshot-dialog.enum';
import AccessControlEventLog from '../../components/AccessControlEventLog/AccessControlEventLog';

const AccessControlEventLogPage = () => {
  const { listOfLogs, isOpenSnapshotsDialog, setIsOpenSnapshotsDialog, clickedRowId, DataTableComponent } =
    AccessControlEventLog();

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          {isOpenSnapshotsDialog && (
            <SnapshotsDialog
              data={listOfLogs}
              currentDataId={clickedRowId!}
              visible={isOpenSnapshotsDialog}
              closeDialog={() => setIsOpenSnapshotsDialog(false)}
              visibleText={false}
              type={SNAPSHOT_DIALOG_TYPE.ACCESS_CONTROL_EVENT_LOG}
              serviceToCall={serviceToCall}
              downloadImagesCallback={downloadImages}
            />
          )}

          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTableComponent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessControlEventLogPage;
