import axios from 'axios';
import { ConnectionType } from '../../enums/connection-type.enum';
import { Node } from '../../types/node';

class NodesService {
  async sendControlSignal(nodeId: string, commandName: string) {
    return axios.post('/nodes/commands', { nodeId, commandName });
  }
  async searchNodes(query?: any) {
    const nodes = await axios.post('/nodes/search', query);

    return nodes.data.map(mapFromResponseBody);
  }
  async createNode(node: Node) {
    return axios.post('/nodes', mapToRequestBody(node));
  }
  async updateNode(node: Node, id: string) {
    return axios.put(`/nodes/${id}`, mapToRequestBody(node));
  }
  async deleteNode(id: string) {
    return axios.delete(`/nodes/${id}`);
  }
  async getDivisionsScale() {
    return axios.get(`/nodes/divisions`);
  }
  async updateAutoWeighingStatus(isAutoWeighing: boolean, locationId: string, scaleId: string) {
    return axios.put(`/nodes/auto-weighing`, {
      isAutoWeighing,
      locationId,
      scaleId,
    });
  }
}

const mapToRequestBody = (node: any) => {
  return {
    id: node.id,
    type: node.type.value,
    name: node.name,
    host: node?.connectionType === 'TCP' ? node.host : null,
    port: node.port,
    nodeModel: node.nodeModel,
    location: node.location,
    station: node.station,
    edgeDevice: node.edgeDevice,
    weighingUnit: node?.weighingUnit?.name,
    division: node?.division?.name,
    maxWeight: parseInt(node?.maxWeight),
    sourceScale: node?.sourceScale,
    openPin: node?.openPin,
    closePin: node?.closePin,
    statusPin: node?.statusPin,
    addImageData: node.addImageData,
    playSoundSpecifiedWeight: node.playSoundSpecifiedWeight?.toString() ?? null,
    selectedCameras: node?.selectedCameras?.length ? node?.selectedCameras : null,
    baudrate: node?.connectionType === 'SERIAL_PORT' ? node?.baudrate : null,
    testScale: node?.testScale,
  };
};

const mapFromResponseBody = (node: Node) => {
  return {
    id: node.id,
    type: node.type,
    name: node.name,
    host: node.host,
    port: node.port.toString(),
    baudrate: node.baudrate?.toString(),
    nodeModelId: node.nodeModelId,
    location: node.location,
    station: node.station,
    edgeDevice: node.edgeDevice,
    weighingUnit: node.weighingUnit,
    division: node.division,
    maxWeight: node.maxWeight?.toString(),
    isUsed: node.isUsed,
    isAutoWeighing: node.isAutoWeighing,
    sourceScaleId: node.sourceScaleId,
    openPin: node.openPin,
    closePin: node.closePin,
    statusPin: node.statusPin,
    addImageData: node.addImageData,
    playSoundSpecifiedWeight: node.playSoundSpecifiedWeight ? Number(node.playSoundSpecifiedWeight) : null,
    selectedCameras: node?.selectedCameras,
    connectionType: node?.baudrate ? ConnectionType.SERIAL_PORT : ConnectionType.TCP,
    testScale: node?.testScale,
  };
};

const nodesService = new NodesService();
export default nodesService;
