import axios from 'axios';
import { trimString } from '../../utils/stringUtils';

class ProductsService {
  async searchProducts(filters, paginatorParam) {
    const products = await axios.post(`/products/search`, { filters, paginatorParam });
    return { data: products.data.data.map(mapFromResponse), total_pages: products.data.total_pages };
  }
  async exportData() {
    return axios.get(`/products/csv`);
  }
  async importData(postData) {
    return axios.post(`/products/csv`, {
      data: postData,
    });
  }
  async importBdo(locationId) {
    return axios.post(`/products/import-bdo`, { data: { locationId } });
  }
  async checkIfImportedBdo() {
    return axios.get(`products/bdo-import-status`);
  }
  async getProducts(params, requestConfig) {
    const productsResponse = await axios.get(`/products`, { ...requestConfig, params });

    return {
      data: productsResponse.data.map(this.mapProduct),
    };
  }
  async getProductById(id) {
    if (id) {
      const response = await axios.get('/products/byid', { params: { id } });
      const product = response.data;
      return {
        data: {
          ...product,
          fulltext: `${product.locationShortName ? `[${product.locationShortName}]` : ''}[${
            product['code']
          }] ${trimString(product['name'], 50, true)}`,
        },
      };
    } else {
      return { data: null };
    }
  }
  async searchProductByIds(ids) {
    if (ids) {
      const response = await axios.post('/products/byids', {
        query: { ids },
      });
      const products = response.data;
      return {
        data: products.map((product) => {
          return {
            ...product,
            fulltext: `${product.locationShortName ? `[${product.locationShortName}]` : ''}[${
              product['code']
            }] ${trimString(product['name'], 50, true)}`,
          };
        }),
      };
    } else {
      return { data: [] };
    }
  }
  async editProductsActivity(postData) {
    return axios.put(`/products/activity`, {
      data: postData,
    });
  }
  async createProduct(postData) {
    return axios.post(`/products`, {
      data: mapToRequestBody(postData),
    });
  }
  async editProduct(postData, id) {
    return axios.put(`/products/${id}`, {
      data: mapToRequestBody(postData),
    });
  }
  async removeProduct(id) {
    return axios.delete(`/products/${id}`);
  }
  async removeManyProducts(products) {
    return axios.post(`/products/remove-many`, {
      data: products.map((p) => p.id),
    });
  }
  mapProduct(product) {
    if (product)
      return {
        ...product,
        fulltext: `${product.locationShortName ? `[${product.locationShortName}]` : ''}[${
          product['code']
        }] ${trimString(product['name'], 50, true)}`,
      };
  }
}

const mapToRequestBody = (product) => {
  return {
    id: product.id,
    code: product.code,
    name: product.name,
    active: product.active,
    locationId: product.locations ? product.locations.id : null,
    contractorId: product.contractors ? product.contractors.id : null,
    transactionType: product.transactionType ? product.transactionType.id : null,
  };
};

const mapFromResponse = (product) => {
  return {
    ...product,
    name: product['name'].length > 100 ? product['name'].substring(0, 100) + '...' : product['name'],
    fulltext: `${product.locationShortName ? `[${product.locationShortName}]` : ''}[${product['code']}] ${trimString(
      product['name'],
      50,
      true,
    )}`,
  };
};

const productsService = new ProductsService();
export default productsService;
