import { Fieldset } from 'primereact/fieldset';
import { SelectButton } from 'primereact/selectbutton';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import edgeDevicesService from '../../../../../../services/ScaleoApiServices/EdgeDevicesService';
import nodeModelsService from '../../../../../../services/ScaleoApiServices/NodeModelsService';
import nodesService from '../../../../../../services/ScaleoApiServices/NodesService';
import systemConfigurationService from '../../../../../../services/ScaleoApiServices/SystemConfigurationService';
import usersService from '../../../../../../services/ScaleoApiServices/UsersService';
import weighingUnitsService from '../../../../../../services/ScaleoApiServices/WeighingUnitsService';
import { indicationActions } from '../../../../../../store/indication-slice';
import { uiActions } from '../../../../../../store/ui-slice';
import { userActions } from '../../../../../../store/user-slice';
import { weighingActions } from '../../../../../../store/weighing-slice';
import { ReducerState } from '../../../../../../types/reducer-state';
import { getTranslatedLabel, weighingModeExtendedLabels } from '../../../../../../utils/labels';
import useCommandTopics from '../../../../../../utils/useCommandTopics';
import { isModulesEnabled } from '../../../../../../utils/modulesUtils';
import NotificationPanel from './NotificationPanel';
import { Vehicle } from '../../../../../../types/weighing';
import { useIsWeighingStation } from '../../../../../../hooks/_shared/useIsWeighingStation';

interface ScaleIndicationProps {
  publishScaleCommandHandler: (s: string, a: any) => void;
  isIndicationOutOfRange: any;
  setIsIndicationOutOfRange: (a: any) => void;
  vehicleData: Vehicle;
  edgeDeviceMaxWeightBrutto: number;
}

const ScaleIndication = ({
  publishScaleCommandHandler,
  isIndicationOutOfRange,
  setIsIndicationOutOfRange,
  vehicleData,
  edgeDeviceMaxWeightBrutto,
}: ScaleIndicationProps) => {
  const { t } = useTranslation('weighingHandlingWeighings');
  const { t: t1 } = useTranslation('utils');

  const CONTAINER_WEIGHING = { id: 'CONTAINER', name: t1('weighingModeExtendedLabelsContainersTare') };

  const { SCALE_COMMAND_ZERO_TOPIC } = useCommandTopics();
  const dispatch = useDispatch();

  const weighingModes = useSelector((state: ReducerState) =>
    state.weighing.weighingConfiguration.weighingMode.map((mode: any) => {
      return { id: mode, name: getTranslatedLabel(weighingModeExtendedLabels, [mode], t1) };
    }),
  );
  const { currentIndication, scaleZeroingStatus } = useSelector((state: ReducerState) => state.indication);
  const { enableAutomaticWeighings, selectedScale, weighingState, formState, automationCurrentMode } = useSelector(
    (state: ReducerState) => state.weighing,
  );

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const loggedUser = useSelector((state: ReducerState) => state.user.loggedUser);

  const isWeighingStation = useIsWeighingStation();

  // TODO: cleanup when we remove isAutoWeighing as scale attribute
  const isManualWeighingForm =
    (!selectedScale?.edgeDevice?.automationStates && !selectedScale?.isAutoWeighing) ||
    automationCurrentMode?.weighingFormMode === 'FULL';

  const [componentData, setComponentData] = useState<any | null>(null);

  const [convertedIndication, setConvertedIndication] = useState<number | null>(null);
  const [zeroButtonDisabled, setZeroButtonDisabled] = useState(true);
  const [zeroButtonVisible, setZeroButtonVisible] = useState(false);

  const onScaleSelected = useCallback(
    (scale) => {
      usersService.setCurrentScaleAndLocation(scale.location?.id, loggedUser!.id, scale?.id).then(() => {
        dispatch(weighingActions.scaleSelected(scale));
        dispatch(indicationActions.scaleIndicationReceived(null));
        dispatch(userActions.setCurrentLocationId(scale.location.id));
        scale.edgeDevice?.automationCurrentMode &&
          dispatch(weighingActions.changeAutomationMode(scale.edgeDevice?.automationCurrentMode));
      });
    },
    [dispatch, loggedUser?.id],
  );

  const fetchComponentData = useCallback(async () => {
    await Promise.all([
      usersService.getCurrentUserScales(),
      weighingUnitsService.getWeighingUnits(),
      systemConfigurationService.getSelectedUnit(),
    ]).then((values) => {
      const scales = values[0];
      setComponentData({
        scales,
        units: values[1].data,
        unitMultiplicationFactor: values[2].data.multiplicationFactor,
      });
      if (!selectedScale) {
        const lastUsedScale = scales.find((scale: any) => scale.id === loggedUserContext.lastUsedScaleId);
        if (scales?.length > 0) {
          onScaleSelected(lastUsedScale || scales[0]);
        }
      } else if (!scales?.some((scale: any) => scale.id === selectedScale.id)) {
        dispatch(weighingActions.clearSelectedScale());
      }
    });
  }, [loggedUserContext.lastUsedScaleId, onScaleSelected, selectedScale, dispatch]);

  useEffect(() => {
    if (selectedScale) {
      nodeModelsService.getNodeModelDetails(selectedScale.nodeModelId).then((response) => {
        setZeroButtonVisible(response.data.enabledZeroing);
      });
    }
  }, [selectedScale]);

  useEffect(() => {
    fetchComponentData();
  }, [fetchComponentData, loggedUserContext.currentCustomer?.id]);

  useEffect(() => {
    if (componentData && selectedScale) {
      if (currentIndication != null) {
        const selectedScaleUnit = componentData.units.find((unit: any) => unit.name === selectedScale?.weighingUnit);
        const indication =
          (currentIndication * selectedScaleUnit.multiplicationFactor) / componentData.unitMultiplicationFactor;
        if (indication === 0) {
          dispatch(indicationActions.scaleZeroReceived({ scaleId: selectedScale.id }));
          setZeroButtonDisabled(true);
        } else {
          setZeroButtonDisabled(false);
        }
        setConvertedIndication(indication);
      } else {
        setConvertedIndication(null);
      }
    }
  }, [currentIndication, selectedScale, componentData, dispatch]);

  useEffect(() => {
    if (scaleZeroingStatus === 'SUCCEEDED') {
      dispatch(uiActions.toggleIsLoading({ isLoading: false }));
    }
  }, [scaleZeroingStatus, dispatch]);

  const onScaleZero = () => {
    dispatch(uiActions.toggleIsLoading({ isLoading: true }));
    dispatch(indicationActions.scaleZeoingRequested({ scaleId: selectedScale.id }));
    publishScaleCommandHandler(SCALE_COMMAND_ZERO_TOPIC, {
      scaleId: selectedScale.id,
    });
  };

  const onWeighingModeChange = (value: string) => {
    dispatch(weighingActions.updateFormState({ selectedWeighingModeId: value }));
  };

  const onAutoWeighingChange = (e: any) => {
    nodesService.updateAutoWeighingStatus(e.value, loggedUserContext.currentLocationId!, selectedScale.id).then(() => {
      dispatch(weighingActions.changeScaleAutoWeighing(e.value));
    });
  };

  const onAutomationModeChange = (e: any) => {
    edgeDevicesService.changeAutomationCurrentMode(e.value, selectedScale.edgeDevice.id).then(() => {
      dispatch(weighingActions.changeAutomationMode(e.value));
    });
  };

  return (
    <Fieldset legend={t('scaleIndicationTitle')}>
      <div className="grid p-0">
        <div className="col-12 lg:col-12 px-0">
          <div className="field mt-2">
            <NotificationPanel
              isIndicationOutOfRange={isIndicationOutOfRange}
              setIsIndicationOutOfRange={setIsIndicationOutOfRange}
              zeroButtonVisible={zeroButtonVisible}
              zeroButtonDisabled={zeroButtonDisabled}
              convertedIndication={convertedIndication}
              onScaleZero={onScaleZero}
              componentData={componentData}
              onScaleSelected={onScaleSelected}
              automationCurrentMode={automationCurrentMode}
              onAutomationModeChange={onAutomationModeChange}
              onAutoWeighingChange={onAutoWeighingChange}
              enableAutomaticWeighings={enableAutomaticWeighings}
              vehicleData={vehicleData}
              edgeDeviceMaxWeightBrutto={edgeDeviceMaxWeightBrutto}
            />
          </div>
        </div>
        {isManualWeighingForm && isWeighingStation && (
          <div className="col-12 lg:col-12">
            <div className="flex justify-content-center align-items-center mt-10px mb-0 lg:mr-20p lg:ml-20p">
              <div className="p-fluid mode-selection">
                <SelectButton
                  value={formState.selectedWeighingModeId}
                  onChange={(e) => {
                    if (e.value) {
                      onWeighingModeChange(e.value);
                    }
                  }}
                  options={[
                    ...weighingModes,
                    isModulesEnabled(['CONTAINERS'], loggedUserContext.currentCustomer?.subscriptionModules)
                      ? CONTAINER_WEIGHING
                      : null,
                  ].filter((o) => !!o)}
                  optionLabel="name"
                  optionValue="id"
                  disabled={weighingState?.openWeighing}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Fieldset>
  );
};

export default ScaleIndication;
