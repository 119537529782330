export enum WeighingNumerationLocationOption {
  NONE = 'none',
  SHORT = 'short',
}

export enum WeighingNumerationPeriodOption {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  SHORT_YEARLY = 'shortYearly',
  MONTHLY_SHORT_YEARLY = 'monthlyShortYearly',
}

export enum WeighingNumerationSeparatorOption {
  SLASH = '/',
  DASH = '-',
  UNDERSCORE = '_',
}

export enum WeighingNumerationGeneratedByField {
  NUMBER_LOCATION = 'numberLocation',
  WEIGHING_TYPE = 'weighingType',
  ADDITIONAL_FIELD = 'additionalField',
}
