import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import '../../../DataTable.css';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import { ApiKeyCredentials } from '../../../../services/ScaleoApiServices/ApiKeyService';
import { useApiKeyForm } from '../../../../hooks/ApiKeys/useApiKeyForm';
import { MultiSelect } from 'primereact/multiselect';
import multiSelectPanelHeader from '../../../_shared/multiSelectPanelHeader';
import { Contractor } from '../../../../types/weighing';

interface ApiKeyDialogProps {
  visible: boolean;
  closeDialog: () => void;
  fetchData: () => Promise<void>;
  item: ApiKeyCredentials | null;
  contractors: Contractor[];
}

const ApiKeyDialog = ({ visible, closeDialog, item, fetchData, contractors }: ApiKeyDialogProps) => {
  const { t } = useTranslation('managementApiKey');

  const { formik, selectScopesHandler, selectContractorsHandler } = useApiKeyForm(item, fetchData, closeDialog);

  const dialogFooter = (
    <>
      <Button type="reset" label={t('cancel')} icon="pi pi-times" text onClick={closeDialog} />
      <Button type="submit" label={t('save')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <form onSubmit={formik.handleSubmit}>
        <div className="location-edit-dialog">
          <Dialog
            visible={visible}
            header={t('details')}
            modal
            className="p-fluid w-40vw"
            breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
            footer={dialogFooter}
            onHide={closeDialog}
          >
            {item?.lastUsedAt && (
              <div className="col-12">
                <div className="field">
                  <label htmlFor="lastUsedAt">{t('lastUsedAt')}</label>
                  <InputText id="lastUsedAt" value={new Date(item?.lastUsedAt).toLocaleString()} disabled />
                  <FormErrorMessage fieldName="lastUsedAt" formikInstance={formik} />
                </div>
              </div>
            )}
            <div className="col-10">
              <div className="field">
                <label htmlFor="name">{t('clientName')}</label>
                <InputText id="name" value={formik.values.name!} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="name" formikInstance={formik} />
              </div>

              <div className="field">
                <label htmlFor="name">{t('scopes')}</label>
                <MultiSelect
                  value={formik.values.scopes}
                  name="scopes"
                  options={SCOPES}
                  onChange={selectScopesHandler}
                  placeholder={t('multiSelectPlaceholder')}
                  panelHeaderTemplate={multiSelectPanelHeader}
                  maxSelectedLabels={0}
                  selectedItemsLabel={`${t('multiSelectSelected')}: ${formik.values.scopes?.length ?? 0}`}
                  showClear
                  tooltip={t('multiSelectTooltip')}
                />
                <FormErrorMessage fieldName="scopes" formikInstance={formik} />
              </div>

              {formik.values.scopes?.some((scope) => scope === 'weighings/read') && (
                <div className="field">
                  <label htmlFor="contractorc">{t('contractors')}</label>
                  <MultiSelect
                    value={formik.values.contractorIds}
                    name="scopes"
                    optionLabel="name"
                    optionValue="id"
                    options={contractors}
                    onChange={selectContractorsHandler}
                    placeholder={t('multiSelectPlaceholder')}
                    panelHeaderTemplate={multiSelectPanelHeader}
                    maxSelectedLabels={0}
                    selectedItemsLabel={`${t('multiSelectSelected')}: ${formik.values.contractorIds?.length ?? 0}`}
                    showClear
                    tooltip={t('multiSelectContractorsTooltip')}
                  />
                </div>
              )}
            </div>
          </Dialog>
        </div>
      </form>
    </FormErrorMessageScroller>
  );
};

export default ApiKeyDialog;

const SCOPES = [
  'weighings/read',
  'drivers/read',
  'drivers/write',
  'arrival-notifications/write',
  'contractors/read',
  'contractors/write',
  'vehicles/read',
  'vehicles/write',
  'weighings/write',
  'nodes/read',
  'products/read',
  'products/write',
  'trailers/read',
  'trailers/write',
];
