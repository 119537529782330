import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';

export const VehicleAutoComplete = ({ items, id, value, onChange, onBlurFuntion }) => {
  const [source, setSource] = useState([]);
  const [filteredItems, setFilteredItems] = useState(null);
  const [subValue, setSubValue] = useState(null); //"subValue" is required for a valid value change process. This is a way to update the state of the form only when the value is actually changed.
  const field = 'registrationNumber';

  useEffect(() => {
    setSource(items);
  }, [items]);

  const searchBranch = (event) => {
    setTimeout(() => {
      if (!event.query.trim().length) {
        setFilteredItems([...source]);
      } else {
        setFilteredItems(
          source.filter((item) => {
            return item[field].toLowerCase().includes(event.query.toLowerCase());
          }),
        );
      }
    }, 250);
  };

  const itemTemplate = (item) => (
    <div className="item">
      <div>{item[field]}</div>
    </div>
  );

  const getValue = () => (subValue !== null ? subValue : value);

  const handleSelect = (e) => {
    const _value = e.value;
    setSubValue(null);
    onChange(_value);
  };

  const handleChange = (e) => {
    if (typeof e.value === 'string') {
      setSubValue(e.value);
    }
  };

  const handleHide = () => {
    if (value) {
      setSubValue(null);
    }
  };

  const handleBlur = () => {
    onBlurFuntion && onBlurFuntion();
    if (!!subValue || subValue === '') {
      const _value = subValue;
      onChange(_value);
      setSubValue(null);
    }
  };

  return (
    <>
      <div className="custom-autocomplete">
        <AutoComplete
          id={id}
          value={getValue()}
          suggestions={filteredItems}
          completeMethod={searchBranch}
          field={field}
          dropdown
          forceSelection={false}
          itemTemplate={itemTemplate}
          inputStyle={{ fontSize: '1.5em', height: '100%' }}
          placeholder={''}
          onChange={handleChange}
          onSelect={handleSelect}
          onHide={handleHide}
          onBlur={handleBlur}
        />
      </div>
    </>
  );
};
