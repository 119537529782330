import { useState, useEffect, useCallback } from 'react';
import contractorsService from '../../services/ScaleoApiServices/ContractorsService';
import driversService from '../../services/ScaleoApiServices/DriversService';
import productsService from '../../services/ScaleoApiServices/ProductsService';
import { Contractor, Driver, Product, Vehicle } from '../../types/weighing';
import vehicleService from '../../services/ScaleoApiServices/VehiclesService';
import { Trailer } from '../../types/trailer';
import trailersService from '../../services/ScaleoApiServices/TrailersService';

const useAccessControlArrivalNotificationData = () => {
  const [componentData, setComponentData] = useState<{
    contractorsData: Contractor[];
    driversData: Driver[];
    productsData: Product[];
    vehiclesData: Vehicle[];
    trailersData: Trailer[];
  }>({
    contractorsData: [],
    driversData: [],
    productsData: [],
    vehiclesData: [],
    trailersData: [],
  });

  const fetchRelatedData = useCallback(async () => {
    Promise.all([
      contractorsService.getContractors(),
      driversService.getDrivers(),
      productsService.getProducts({ searchText: null, searchForCurrentLocation: false, searchForActive: false }),
      vehicleService.getVehicles(),
      trailersService.getTrailers(),
    ]).then((response) => {
      setComponentData({
        contractorsData: response[0].data ?? [],
        driversData: response[1].data ?? [],
        productsData: response[2].data ?? [],
        vehiclesData: response[3].data ?? [],
        trailersData: response[4].data ?? [],
      });
    });
  }, []);

  useEffect(() => {
    fetchRelatedData();
  }, [fetchRelatedData]);

  return componentData;
};

export default useAccessControlArrivalNotificationData;
