import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import '../../../DataTable.css';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../../store/user-slice';
import { useTranslation, Trans } from 'react-i18next';
import accessCodesService from '../../../../services/ScaleoApiServices/AccessCodesService';

interface DeleteAccessCodeDialogProps {
  visible: boolean;
  closeDialog: any;
  selectedRows: any;
}

const DeleteAccessCodeDialog = ({ visible, closeDialog, selectedRows }: DeleteAccessCodeDialogProps) => {
  const { t } = useTranslation('datasetsAccessCodes');

  const dispatch = useDispatch();
  const handleCloseDialog = (changes: boolean) => {
    closeDialog(changes === true);
  };

  const handleConfirm = async () => {
    const itemsToDelete = Array.isArray(selectedRows) ? selectedRows : [selectedRows];

    await Promise.all(itemsToDelete.map((item) => accessCodesService.removeAccessCode(item.id))).then(() => {
      dispatch(userActions.shouldRefreshLoggedUserContext(true));
      handleCloseDialog(true);
    });
  };

  const dialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={() => handleCloseDialog(true)} />
      <Button type="button" label={t('deleteButton')} icon="pi pi-check" text onClick={handleConfirm} />
    </>
  );

  return (
    <Dialog
      visible={visible}
      breakpoints={{ '896px': '90vw' }}
      header={
        <span className="flex align-items-center">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
          {t('confirm')}
        </span>
      }
      modal
      className="p-fluid min-width-450px"
      footer={dialogFooter}
      onHide={() => handleCloseDialog(true)}
    >
      {selectedRows ? (
        <div className="confirmation-content">
          {Array.isArray(selectedRows) && selectedRows.length > 1 ? (
            <span>{t('content')}</span>
          ) : (
            <span>
              <Trans
                t={t}
                i18nKey="singleDeleteDetail"
                values={{ accessCode: selectedRows.name || selectedRows[0].name }}
              />
            </span>
          )}
        </div>
      ) : (
        ''
      )}
    </Dialog>
  );
};

export default DeleteAccessCodeDialog;
