import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';

interface ReceiptContentSectionProps {
  formikInstance: any;
  handleChangeField: Function;
}

const ReceiptsSettingsSection = ({ formikInstance, handleChangeField }: ReceiptContentSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationReceipts');

  return (
    <Fieldset legend={t('posReceiptSettings')} className="mt-1vh">
      <div className="flex pt-1vh">
        <label htmlFor="posReceiptNumberOfCopies" className="mr-6 mt-2">
          {t('posReceiptNumberOfCopies')}
        </label>
        <div className="field">
          <Dropdown
            id={`posReceiptNumberOfCopies`}
            options={[1, 2, 3]}
            value={formikInstance.values.posReceiptNumberOfCopies}
            onChange={(e) => handleChangeField(e, 'dropdown')}
            placeholder={t('posReceiptNumberOfCopiesPlaceholder')}
          />
        </div>
      </div>
    </Fieldset>
  );
};

export default ReceiptsSettingsSection;
