import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import webhooksService from '../../services/ScaleoApiServices/WebhooksService';
import { Webhook } from '../../types/webhook';

export const useWebhookForm = (fetchAPI: () => Promise<void>, item: Webhook | null, closeDialog: () => void) => {
  const { t } = useTranslation('managementWebhooks');

  const validate = (data: Webhook) => {
    const errors = {} as any;

    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    const isValidUrl = urlRegex.test(data.destinationUrl ?? '');

    if (!data.name) {
      errors.name = t('invalidName');
    }

    if (data.destinationUrl && !isValidUrl) {
      errors.destinationUrl = t('invalidDestinationUrl');
    }

    if (data.destinationPassword !== null && !data.destinationId) {
      errors.destinationId = t('invalidDestinationId');
    }

    if (data.destinationPassword !== null && data.destinationPassword === '') {
      errors.destinationPassword = t('invalidDestinationPassword');
    }

    return errors;
  };

  const onSubmit = async (formData: Webhook) => {
    if (formData.id) {
      await webhooksService.updateWebhook(formData);
    } else {
      await webhooksService.createWebhook(formData);
    }
    closeDialog();
    await fetchAPI();
  };

  const formik = useFormik({
    initialValues: item ?? INIT_WEBHOOKS_STATE,
    validate,
    onSubmit,
    enableReinitialize: true,
  });

  const selectContractorsHandler = (e: any) => {
    formik.setFieldValue('contractorIds', e.value ?? e.target.value ?? []);
  };

  return {
    formik,
    selectContractorsHandler,
  };
};

const INIT_WEBHOOKS_STATE = {
  name: null as string | null,
  destinationUrl: null as string | null,
  contractorIds: [] as string[],
  destinationId: null as string | null,
  destinationPassword: null as string | null,
} as Webhook;
