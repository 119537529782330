import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { trimString } from '../../utils/stringUtils';

const ProductItemTemplate = (item) => (
  <div className="item" key={item['id']}>
    <Tooltip className="max-width-unset" target={`.pr-item-tooltip-${item['id']}`} mouseTrack mouseTrackLeft={10}>
      {item['name']}
    </Tooltip>
    <div className={`pr-item-tooltip-${item['id']}`}>{`${item.locationShortName ? `[${item.locationShortName}]` : ''}[${
      item['code']
    }] ${trimString(item['name'], 50, true)}`}</div>
  </div>
);

const ContractorItemTemplate = (item) => (
  <div className="item" key={item['id']}>
    <Tooltip className="max-width-unset" target={`.pr-item-tooltip-${item['id']}`} mouseTrack mouseTrackLeft={10}>
      {item['name']}
    </Tooltip>
    <div className={`pr-item-tooltip-${item['id']}`}>{`[${item['code']}] ${trimString(item['name'], 50, true)}`}</div>
  </div>
);

const DictionaryItemTemplate = (item) => {
  return (
    <div className="item">
      <div>{item['name']}</div>
    </div>
  );
};

const ContainerItemTemplate = (item) => {
  return (
    <div className="item" key={item['id']}>
      <div className={`pr-item-tooltip-${item['id']}`}>{`${item.name}(${item.code})`}</div>
    </div>
  );
};

const ComponentItemTemplates = {
  Product: ProductItemTemplate,
  Contractor: ContractorItemTemplate,
  Dictionary: DictionaryItemTemplate,
  Container: ContainerItemTemplate,
};

export default ComponentItemTemplates;
