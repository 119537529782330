import { createSlice } from '@reduxjs/toolkit';
import { StateSlice } from './types/state-slice';

const initialState: StateSlice = {
  vehiclePosition: {},
};

const stateSlice = createSlice({
  name: 'state',
  initialState: { ...initialState },
  reducers: {
    resetState(_state: StateSlice) {
      _state = { ...initialState };
    },

    vehiclePosition(state: StateSlice, value) {
      if (!value?.payload) {
        return;
      }

      const { edgeDeviceId, vehiclePosition } = value.payload;
      state.vehiclePosition[edgeDeviceId] = vehiclePosition;
    },
  },
});

export const stateActions = stateSlice.actions;

export default stateSlice;
