import { useState, useEffect } from 'react';
import { Toolbar } from 'primereact/toolbar';
import AccessControlArrivalNotificationDialog from '../../components/AccessControlArrivalNotification/AccessControlArrivalNotificationDialog';
import { Button } from 'primereact/button';
import useAccessControlArrivalNotificationData from '../../hooks/AccessControlArrivalNotification/useAccessControlArrivalNotificationData';
import useAccessControlArrivalNotificationRelatedData from '../../hooks/AccessControlArrivalNotification/useAccessControlArrivalNotificationRelatedData';
import { useSelector } from 'react-redux';
import { ReducerState } from '../../types/reducer-state';
import AccessControlArrivalNotification from '../../components/AccessControlArrivalNotification/AccessControlArrivalNotification';
import { ArrivalNotification, Filters } from '../../components/AccessControlArrivalNotification/types';
import { useTranslation } from 'react-i18next';
import AccessControlArrivalNotificationDeleteDialog from '../../components/AccessControlArrivalNotification/AccessControlArrivalNotificationDeleteDialog';
import accessControlArrivalNotificationService from '../../services/ScaleoApiServices/AccessControlArrivalNotificaitonService';
import useArrivalNotificationForm from '../../hooks/AccessControlArrivalNotification/useAccessControlArrivalNotificationForm';

const INIT_FILTERS: Filters = {
  validFrom: null,
  validTo: null,
  registrationNumber: null,
  trailerRegistrationNumber: null,
  driver: null,
  contractor: null,
  product: null,
  number: null,
  global: null,
};

const AccessControlArrivalNotificationPage = () => {
  const { t } = useTranslation('accessControlArrivalNotification');

  const [filters, setFilters] = useState<Filters>(INIT_FILTERS);

  const {
    data: arrivalNotificationData,
    paginatorState,
    setPaginatorState,
    paginationParameters,
    setPaginationParameters,
    fetchData,
  } = useAccessControlArrivalNotificationData(filters);

  const { contractorsData, driversData, productsData, vehiclesData, trailersData } =
    useAccessControlArrivalNotificationRelatedData();

  const [dialogVisible, setDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [selectedArrivalNotifications, setSelectedArrivalNotifications] = useState<ArrivalNotification[]>([]);
  const [arrivalNotificationDataRow, setArrivalNotificationDataRow] = useState<ArrivalNotification | null>(null);

  const closeDialogFormHandler = () => setDialogVisible(false);
  const { formik, updateForm } = useArrivalNotificationForm(fetchData, paginationParameters, closeDialogFormHandler, t);

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  useEffect(() => {
    void fetchData(paginationParameters);
  }, [loggedUserContext.currentCustomer?.id, filters]);

  const openDialog = (arrivalNotificationData: any = null) => {
    setArrivalNotificationDataRow(arrivalNotificationData);
    setDialogVisible(true);
  };

  const openSingleDeleteDialog = (arrivalNotificationData: ArrivalNotification) => {
    setArrivalNotificationDataRow(arrivalNotificationData);
    setDeleteDialogVisible(true);
  };

  const openMultipleDeleteDialog = () => {
    setArrivalNotificationDataRow(null);
    setDeleteDialogVisible(true);
  };

  const closeDialog = () => {
    updateForm();
    setDialogVisible(false);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogVisible(false);
  };

  const deleteHandler = () => {
    if (arrivalNotificationDataRow) {
      void accessControlArrivalNotificationService
        .deleteArrivalNotification(arrivalNotificationDataRow?.id)
        .then(() => fetchData(paginationParameters));
    } else if (selectedArrivalNotifications.length) {
      const selectedRolesIds = selectedArrivalNotifications.map((notification: ArrivalNotification) => notification.id);

      void accessControlArrivalNotificationService
        .deleteMultipleArrivalNotifications(selectedRolesIds)
        .then(() => fetchData(paginationParameters));
      setSelectedArrivalNotifications([]);
    }
    setDeleteDialogVisible(false);
  };

  const leftToolbarTemplate = () => {
    return (
      <>
        <Button label={t('addButton')} icon="pi pi-plus" severity="success" className="mr-2" onClick={openDialog} />
        <Button
          label={t('deleteButton')}
          icon="pi pi-trash"
          severity="danger"
          onClick={openMultipleDeleteDialog}
          disabled={!selectedArrivalNotifications || !selectedArrivalNotifications.length}
        />
      </>
    );
  };

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <AccessControlArrivalNotification
                arrivalNotificationData={arrivalNotificationData.arrivalNotifications}
                paginatorState={paginatorState}
                setPaginatorState={setPaginatorState}
                paginationParameters={paginationParameters}
                setPaginationParameters={setPaginationParameters}
                filters={filters}
                setFilters={setFilters}
                contractorsData={contractorsData}
                driversData={driversData}
                productsData={productsData}
                vehiclesData={vehiclesData}
                trailersData={trailersData}
                openDialog={openDialog}
                openDeleteDialog={openSingleDeleteDialog}
                selectedArrivalNotifications={selectedArrivalNotifications}
                setSelectedArrivalNotifications={setSelectedArrivalNotifications}
                fetchData={fetchData}
              />
            </div>
          </div>
        </div>
      </div>
      <AccessControlArrivalNotificationDialog
        isVisible={dialogVisible}
        arrivalNotificationData={arrivalNotificationDataRow}
        contractors={contractorsData}
        drivers={driversData}
        products={productsData}
        vehicles={vehiclesData}
        closeDialog={closeDialog}
        formikInstance={formik}
        updateForm={updateForm}
        dictionaries={arrivalNotificationData.dictionaries}
        trailers={trailersData}
      />
      {deleteDialogVisible && (
        <AccessControlArrivalNotificationDeleteDialog
          arrivalNotification={arrivalNotificationDataRow}
          selectedArrivalNotifications={selectedArrivalNotifications}
          deleteArrivalNotificationHandler={deleteHandler}
          closeModalHandler={closeDeleteDialog}
        />
      )}
    </div>
  );
};

export default AccessControlArrivalNotificationPage;
