import classNames from 'classnames';
import { Avatar } from 'primereact/avatar';
import { Dropdown } from 'primereact/dropdown';
import React, { SyntheticEvent, useContext, useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import usersService from './services/ScaleoApiServices/UsersService';
import { appConfigActions } from './store/appConfig-slice';
import AuthContext from './store/auth-context';
import LogoScaleo from './logo_scaleo_white.png';
import TeamViewerLogo from './team_viewer_logo.png';
import { userActions } from './store/user-slice';
import { weighingActions } from './store/weighing-slice';
import LanguageSelector from './components/_shared/LanguageSelector';
import { hasOneOfPermissions } from './utils/permissionsUtils';
import { useTranslation } from 'react-i18next';
import { ReducerState } from './types/reducer-state';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';

interface AppTopbarProps {
  onTopbarItemClick: any;
  onMenuButtonClick: any;
  inlineUser: boolean;
  activeTopbarItem: string;
}

const TEAM_VIEWER_LINK = 'https://get.teamviewer.com/6954v9e';

const AppTopbar = ({
  onMenuButtonClick,
  inlineUser,
  activeTopbarItem,
  onTopbarItemClick: propsOnTopbarItemClick,
}: AppTopbarProps) => {
  const { t } = useTranslation('appTopbar');

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const loggedUser = useSelector((state: ReducerState) => state.user.loggedUser);
  const dispatch = useDispatch();
  const currentCustomer = useSelector((state: ReducerState) => state.user.context.currentCustomer);
  const customers = useSelector((state: ReducerState) => state.user.context.customers);
  const confirmationOfWindowChange = useSelector((state: ReducerState) => state.appConfig.confirmationOfWindowChange);
  const { limits, limitsState } = loggedUserContext;

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth >= 900);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onChangeWindowEvent = (event: SyntheticEvent) => {
    if (confirmationOfWindowChange.required && !confirmationOfWindowChange.approved) {
      event.preventDefault();
      dispatch(
        appConfigActions.setConfirmationOfWindowChange({ ...confirmationOfWindowChange, trigger: event.target }),
      );
      return false;
    } else {
      dispatch(
        appConfigActions.setConfirmationOfWindowChange({
          ...confirmationOfWindowChange,
          approved: false,
          required: false,
        }),
      );
      return true;
    }
  };

  const onTopbarItemClick = (event: SyntheticEvent, item: any) => {
    if (propsOnTopbarItemClick) {
      propsOnTopbarItemClick({
        originalEvent: event,
        item: item,
      });
    }
  };

  const onLogoutClicked = (e: SyntheticEvent) => {
    if (onChangeWindowEvent(e)) {
      authCtx.logout();
    }
  };

  const onLinkClicked = (e: SyntheticEvent, path: string) => {
    if (onChangeWindowEvent(e)) {
      history.push(path);
    }
  };

  const handleChangeCustomer = (e: any) => {
    usersService.setCurrentCustomerId(e.value, loggedUser!.id).then(() => {
      dispatch(weighingActions.clearSelectedScale());
      dispatch(userActions.shouldRefreshLoggedUserContext(true));
    });
  };

  const redirectFunction = () => {
    if (!currentCustomer?.companyName || !currentCustomer.taxId) {
      !window.location.href.includes('billing-data') && history.push('/billing-data');
      return;
    }

    if (!loggedUserContext?.firstName || !loggedUserContext.lastName) {
      !window.location.href.includes('user-profile') && history.push('/user-profile');
      return;
    }
  };

  redirectFunction();

  const ScaleoLogo = () => {
    return (
      <div className="topbar-project-logo">
        <img src={LogoScaleo} alt="scaleo_logo" />
      </div>
    );
  };

  const LeftSideMenu = () => {
    return (
      <>
        <button type="button" className="p-link layout-menu-button layout-topbar-icon mr-4" onClick={onMenuButtonClick}>
          <i className="pi pi-bars"></i>
        </button>
      </>
    );
  };

  const MobileTopbarMenu = () => {
    const menu = useRef(null);
    const items = [
      {
        label: '',
        icon: 'pi pi-users',
        className: 'mt-1 pb-2',
        template: (_item: any, options: any) => (
          <div className={options.className} style={options.style}>
            {customers && customers.length > 1 && (
              <Dropdown
                value={currentCustomer?.id}
                options={customers!}
                optionValue="id"
                optionLabel="companyName"
                placeholder={t('companyName')}
                onChange={handleChangeCustomer}
                className="layout-topbar-names"
                style={{ width: '100%' }}
              />
            )}
          </div>
        ),
      },
      {
        label: t('userProfile'),
        icon: 'pi pi-user',
        className: 'pb-2',
        command: (e: any) => {
          onLinkClicked(e, '/user-profile');
        },
      },
      {
        label: t('billingData'),
        icon: 'pi pi-credit-card',
        className: 'pb-2',
        command: (e: any) => {
          onLinkClicked(e, '/billing-data');
        },
      },
      {
        label: t('contact'),
        icon: 'pi pi-question-circle',
        className: 'pb-2',
        command: (e: any) => {
          onLinkClicked(e, '/contact');
        },
      },
      {
        label: t('version'),
        icon: 'pi pi-ticket',
        className: 'pb-2',
        command: (e: any) => {
          onLinkClicked(e, '/version');
        },
      },
      {
        label: 'Team Viewer',
        icon: 'pi pi-external-link',
        className: 'pb-2',
        command: () => {
          window.open(TEAM_VIEWER_LINK, '_blank');
        },
      },
      {
        label: t('logout'),
        icon: 'pi pi-sign-out',
        command: () => {
          authCtx.logout();
        },
      },
    ];

    return (
      <div className="flex justify-content-between align-items-center w-full">
        <LeftSideMenu />
        <ScaleoLogo />
        <div className="flex align-items-center">
          <div className="mr-3">
            <LanguageSelector visible={true} />
          </div>

          <div>
            <Menu model={items} popup ref={menu} id="popup_menu" className="w-90p mt-4" />
            <Button
              label=""
              icon="pi pi-bars"
              rounded
              className="p-hidden-gt-sm p-4"
              onClick={(event: any) => (menu as any)?.current?.toggle(event)}
              aria-controls="popup_menu"
              aria-haspopup
            />
          </div>
        </div>
      </div>
    );
  };

  const FullTopbarMenu = () => {
    return (
      <>
        <div className="flex layout-topbar-side">
          <LeftSideMenu />
          <div className="flex mt-2 mb-2  align-items-center layout-topbar-client">
            {customers?.length === 1 && (
              <span className="layout-topbar-name text-center">{currentCustomer?.companyName}</span>
            )}
            {customers && customers.length > 1 && (
              <Dropdown
                value={currentCustomer?.id}
                options={customers!}
                optionValue="id"
                optionLabel="companyName"
                placeholder={t('companyName')}
                onChange={handleChangeCustomer}
                className="layout-topbar-names"
              />
            )}
          </div>
        </div>
        <div>
          <ScaleoLogo />
        </div>
        <div className="layout-topbar-side">
          <ul
            className={`topbar-menu inline-flex align-items-center h-45px ${
              limits && limitsState && limits.maximumMeasurementsNumber ? 'w-35vw' : ''
            }`}
          >
            {limits && limitsState && limits.maximumMeasurementsNumber && (
              <li>
                <div className="ml-2">
                  <p className="m-auto text-white">{t('currentMonthMeasurements')}:</p>
                  <div className="inline-flex">
                    <h5 className="m-auto mr-1vw text-white">{`${limitsState.currentMonthMeasurementsNumber ?? 0}/${
                      limits.maximumMeasurementsNumber
                    }`}</h5>
                    <div className="inline-flex w-15vw ml-3">
                      <ProgressBar
                        className="w-180px h-30px m-auto"
                        value={(
                          ((limitsState.currentMonthMeasurementsNumber ?? 0) / limits.maximumMeasurementsNumber) *
                          100
                        ).toFixed(2)}
                      />

                      <Button
                        className="w-100px h-30px flex justify-content-center align-items-center"
                        onClick={() => history.push('/contact')}
                      >
                        <span className="text-center">{t('increase')}</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </li>
            )}
            <LanguageSelector visible={true} />
            <li
              className={classNames('user-profile', {
                'active-topmenuitem fadeInDown': activeTopbarItem === 'profile',
              })}
            >
              {!inlineUser && (
                <button type="button" className="p-link" onClick={(e) => onTopbarItemClick(e, 'profile')}>
                  <Avatar
                    label={`${loggedUserContext?.firstName?.charAt(0) || ''}${
                      loggedUserContext?.lastName?.charAt(0) || ''
                    }`}
                    className=""
                    size="large"
                    shape="circle"
                  ></Avatar>
                  <div className="layout-profile-userinfo">
                    <span className="layout-profile-name">
                      {loggedUserContext.firstName} {loggedUserContext.lastName}
                    </span>
                    <span className="layout-profile-role">{loggedUserContext.role}</span>
                  </div>
                </button>
              )}

              <ul className="fadeInDown">
                <li role="menuitem">
                  <button type="button" className="p-link" onClick={(e) => onLinkClicked(e, '/user-profile')}>
                    <i className="pi pi-fw pi-user"></i>
                    <span>{t('userProfile')}</span>
                  </button>
                  {hasOneOfPermissions(['BILLING_DATA_EDIT'], loggedUserContext.permissions) && (
                    <button type="button" className="p-link" onClick={(e) => onLinkClicked(e, '/billing-data')}>
                      <i className="pi pi-fw pi-credit-card"></i>
                      <span>{t('billingData')}</span>
                    </button>
                  )}
                  <button type="button" className="p-link" onClick={(e) => onLinkClicked(e, '/contact')}>
                    <i className="pi pi-fw pi-question-circle"></i>
                    <span>{t('contact')}</span>
                  </button>
                  <button type="button" className="p-link" onClick={(e) => onLinkClicked(e, '/version')}>
                    <i className="pi pi-fw pi-ticket"></i>
                    <span>{t('version')}</span>
                  </button>
                  <button
                    type="button"
                    className="p-link"
                    onClick={() => {
                      window.open(TEAM_VIEWER_LINK, '_blank');
                    }}
                  >
                    <i>
                      <img src={TeamViewerLogo} style={{ height: '15px', width: '15px' }} alt="team_viewer_logo" />
                    </i>
                    <span>Team Viewer</span>
                  </button>
                  <button type="button" className="p-link" onClick={(e) => onLogoutClicked(e)}>
                    <i className="pi pi-fw pi-sign-out"></i>
                    <span>{t('logout')}</span>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </>
    );
  };

  return (
    <div className="layout-topbar">
      <div className="flex justify-content-between"> {isWideScreen ? <FullTopbarMenu /> : <MobileTopbarMenu />}</div>
    </div>
  );
};

export default AppTopbar;
