import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appConfigActions } from '../../../../store/appConfig-slice';
import { userActions } from '../../../../store/user-slice';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import weighingConfigurationService from '../../../../services/ScaleoApiServices/WeighingConfigurationService';
import formChangeFieldMethod from '../../../../utils/formChangeFieldMethod';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../types/reducer-state';
import ConfirmationsContent from './components/ConfirmationsContent';
import { Dialog } from 'primereact/dialog';
import weighingService from '../../../../services/ScaleoApiServices/WeighingsService';

const INIT_FORM_STATE = {
  weighingConfirmation: null,
  confirmationComment: null,
  requireConfirmationComment: null,
  confirmationRequiredCondition: null,
  enableConfirmedWeighingsEdit: null,
};

const ConfirmationsConfiguration = () => {
  const { t } = useTranslation('managementSystemConfigurationConfirmations');
  const [isConfirmLegacyWeighingsDialogVisible, setIsConfirmLegacyWeighingsDialogVisible] = useState(false);

  const dispatch = useDispatch();
  const [stringifiedInitFormValues, setStringifiedInitFormValues] = useState('[]');
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const confirmationOfWindowChange = useSelector((state: ReducerState) => state.appConfig.confirmationOfWindowChange);
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const fetchAPI = useCallback(async () => {
    const response = await weighingConfigurationService.getConfigurationConfirmations();
    if (response.data) {
      const responseDataValues = {
        weighingConfirmation: response.data.weighingConfirmation,
        confirmationComment: response.data.confirmationComment,
        requireConfirmationComment: response.data.requireConfirmationComment,
        confirmationRequiredCondition: response.data.confirmationRequiredCondition,
        enableConfirmedWeighingsEdit: response.data.enableConfirmedWeighingsEdit,
      };

      setInitFormValues({ ...initFormValues, ...responseDataValues });
      setStringifiedInitFormValues(JSON.stringify({ ...initFormValues, ...responseDataValues }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initFormValues]);

  useEffect(() => {
    fetchAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUserContext.currentCustomer?.id]);

  const handleSubmit = async (data: any) => {
    //SUBMITTING
    const postData = {
      weighingConfirmation: data.weighingConfirmation,
      confirmationComment: data.confirmationComment,
      requireConfirmationComment: data.requireConfirmationComment,
      confirmationRequiredCondition: data.confirmationRequiredCondition,
      enableConfirmedWeighingsEdit: data.enableConfirmedWeighingsEdit,
    };
    await weighingConfigurationService.updateConfigurationConfirmations(postData);
    dispatch(userActions.shouldRefreshSystemConfiguration(true));
  };

  const formik = useFormik({
    initialValues: initFormValues,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const changes = JSON.stringify(formik.values) !== stringifiedInitFormValues;
    if (confirmationOfWindowChange.required !== changes) {
      dispatch(
        appConfigActions.setConfirmationOfWindowChange({
          ...confirmationOfWindowChange,
          required: changes,
          saveMethod: formik.submitForm,
        }),
      );
    }
  }, [JSON.stringify(formik.values)]);

  const handleClickCancel = () => {
    formik.resetForm();
  };

  const handleClickSubmit = async () => {
    await formik.submitForm().then(() => {
      dispatch(appConfigActions.setConfirmationOfWindowChange({ ...confirmationOfWindowChange, required: false }));
    });
  };

  const handleChangeField = (e: any, type: string) => {
    if (e.target.id === 'weighingConfirmation' && e.target.checked) {
      setIsConfirmLegacyWeighingsDialogVisible(true);
    }
    formChangeFieldMethod(e, type, formik);
  };

  const dialogFooter = (
    <>
      <Button
        type="reset"
        label={t('no')}
        icon="pi pi-times"
        text
        onClick={() => setIsConfirmLegacyWeighingsDialogVisible(false)}
      />
      <Button
        type="submit"
        label={t('yes')}
        icon="pi pi-check"
        text
        onClick={() => {
          void weighingService.confirmLegacyWeighings();
          setIsConfirmLegacyWeighingsDialogVisible(false);
        }}
      />
    </>
  );

  return (
    <div className="card pb-70px">
      <Dialog
        visible={isConfirmLegacyWeighingsDialogVisible}
        header={t('confirmAllWeighings')}
        modal
        className="p-fluid w-40vw"
        footer={dialogFooter}
        onHide={() => setIsConfirmLegacyWeighingsDialogVisible(false)}
      ></Dialog>
      <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
        <form onSubmit={formik.handleSubmit}>
          <ConfirmationsContent formikInstance={formik} handleChangeField={handleChangeField} />
          <div>
            <div
              className="flex justify-content-end w-full p-ai-end bg-white fixed bottom-0px right-0px pt-10px pr-40px pb-10px pl-0"
              style={{
                boxShadow: '0px 10px 15px black',
              }}
            >
              <Button type="reset" label={t('reset')} icon="pi pi-times" text onClick={handleClickCancel} />
              <Button type="button" label={t('save')} icon="pi pi-check" text onClick={handleClickSubmit} />
            </div>
          </div>
        </form>
      </FormErrorMessageScroller>
    </div>
  );
};

export default ConfirmationsConfiguration;
