import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { AutoComplete } from 'primereact/autocomplete';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import FormErrorMessage from '../../../../_shared/FormErrorMessage';
import formChangeFieldMethod from '../../../../../utils/formChangeFieldMethod';
import statusUtils from '../statusUtils';
import {
  DEFAULT_DIALOG_BREAKPOINTS,
  DEFAULT_DIALOG_STYLE,
  CancelButton,
  searchWasteCodeId,
  SubmitButton,
  wasteCodeIdItemTemplate,
  limitOfDigitsAfterDecimalsFilter,
  disableDotInTextInput,
} from '../../../bdoUtils';
import FormErrorMessageScroller from '../../../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { CardSubtype } from '../../../../../enums/card-subtype.enum';

const INIT_FORM_STATE = { remarks: '' };

const CorrectSender = ({ visible, closeDialog, item }) => {
  const { t } = useTranslation('kpokComponents');

  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const mode = item?.cardSubtype;

  const getInitialValues = useCallback(async () => {
    const {
      wasteCodeId,
      wasteCodeExtended,
      wasteCodeExtendedDescription,
      wasteMass,
      hazardousWasteReclassification,
      hazardousWasteReclassificationDescription,
    } = item;

    const initValuesObj = {
      ...INIT_FORM_STATE,
      wasteCodeId,
      wasteCodeExtended,
      hazardousWasteReclassification,
      wasteMass,
    };
    if (wasteCodeExtended) {
      initValuesObj.wasteCodeExtendedDescription = wasteCodeExtendedDescription;
    }
    if (hazardousWasteReclassification) {
      initValuesObj.hazardousWasteReclassificationDescription = hazardousWasteReclassificationDescription;
    }

    setInitFormValues(initValuesObj);
    formik.setTouched('plannedTransportTime');
    formik.setTouched('plannedTransportTimeDate');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [INIT_FORM_STATE, item]);

  useEffect(() => {
    if (visible) {
      getInitialValues();
    }
  }, [getInitialValues, visible]);

  const validateMethod = (data) => {
    const errors = {};

    if (!data.wasteCodeId) {
      errors.wasteCodeId = t('wasteCodeIdNotProvided');
    }

    if (mode === CardSubtype.TRANSFER) {
      if (!data.wasteMass) {
        errors.wasteMass = t('wasteMassNotProvided');
      }
    }

    if (data.wasteCodeExtended && !data.wasteCodeExtendedDescription) {
      errors.wasteCodeExtendedDescription = t('wasteCodeExtendedDescNotProvided');
    }

    return errors;
  };

  const handleCloseDialog = (changes, statusFilter) => {
    formik.resetForm({
      values: INIT_FORM_STATE,
    });
    closeDialog(changes === true, statusFilter);
  };

  const handleSubmit = async (data) => {
    const resultBody = {
      kpokId: item.kpokId,
      wasteCodeId: data.wasteCodeId?.wasteCodeId || data.wasteCodeId,
      wasteCodeExtended: data.wasteCodeExtended,
      wasteCodeExtendedDescription: data.wasteCodeExtendedDescription || '',
      wasteMass: parseFloat(data.wasteMass) || 0,
      hazardousWasteReclassification: data.hazardousWasteReclassification,
      hazardousWasteReclassificationDescription: data.hazardousWasteReclassificationDescription || '',
    };

    await statusUtils.changeStatusMethods.sender.CORRECT(resultBody);

    handleCloseDialog(true, 'CONFIRMATION_GENERATED');
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const dialogFooter = (
    <div className="grid justify-content-center">
      <CancelButton onClick={handleCloseDialog} />
      <SubmitButton onClick={formik.submitForm} label="Koryguj" />
    </div>
  );

  const handleChangeField = (e, type) => {
    formChangeFieldMethod(e, type, formik);

    if (!formik.values.wasteCodeExtended) {
      formik.setFieldValue('wasteCodeExtendedDescription', INIT_FORM_STATE.wasteCodeExtendedDescription, false);
    }
    if (!formik.values.hazardousWasteReclassification) {
      formik.setFieldValue(
        'hazardousWasteReclassificationDescription',
        INIT_FORM_STATE.hazardousWasteReclassificationDescription,
        false,
      );
    }
  };

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={visible}
        header={t('correctSenderHeader')}
        modal
        className="p-fluid"
        maskClassName="higher-rank"
        breakpoints={DEFAULT_DIALOG_BREAKPOINTS}
        style={DEFAULT_DIALOG_STYLE}
        footer={dialogFooter}
        onHide={handleCloseDialog}
      >
        <form>
          <div className="field col-12">
            <label htmlFor="wasteCodeId">
              {t('wasteCodeId')}
              <span className="text-red-500">&nbsp;*</span>
            </label>
            <AutoComplete
              id="wasteCodeId"
              field="fulltext"
              value={formik.values.wasteCodeId}
              suggestions={autocompleteOptions}
              completeMethod={(event) => searchWasteCodeId(event, setAutocompleteOptions)}
              onChange={(e) => handleChangeField(e, 'autocomplete')}
              forceSelection
              itemTemplate={wasteCodeIdItemTemplate}
              placeholder={t('autoCompletePlaceholder')}
            />
            <FormErrorMessage fieldName="wasteCodeId" formikInstance={formik} />
            <br />
            <br />
            <div className="field-checkbox">
              <Checkbox
                id="wasteCodeExtended"
                checked={formik.values.wasteCodeExtended}
                onChange={(e) => handleChangeField(e, 'checkbox')}
              ></Checkbox>
              <label htmlFor="wasteCodeExtended" className="checkbox-label">
                {t('wasteCodeExtended')}
              </label>
              <FormErrorMessage fieldName="wasteCodeExtended" formikInstance={formik} />
            </div>
            <label htmlFor="wasteCodeExtendedDescription">{t('wasteCodeExtendedDesc')}</label>
            <InputText
              id="wasteCodeExtendedDescription"
              value={formik.values.wasteCodeExtendedDescription || ''}
              onChange={(e) => handleChangeField(e, 'text')}
              disabled={!formik.values.wasteCodeExtended}
            />
            <FormErrorMessage fieldName="wasteCodeExtendedDescription" formikInstance={formik} />
            <br />
            <br />
            <div className="field-checkbox">
              <Checkbox
                id="hazardousWasteReclassification"
                checked={formik.values.hazardousWasteReclassification}
                onChange={(e) => handleChangeField(e, 'checkbox')}
              ></Checkbox>
              <label htmlFor="hazardousWasteReclassification" className="checkbox-label">
                {t('hazardousWasteReclassification')}
              </label>
              <FormErrorMessage fieldName="hazardousWasteReclassification" formikInstance={formik} />
            </div>
            <label htmlFor="hazardousWasteReclassificationDescription">{t('hazardousWasteReclassificationDesc')}</label>
            <InputText
              id="hazardousWasteReclassificationDescription"
              value={formik.values.hazardousWasteReclassificationDescription || ''}
              onChange={(e) => handleChangeField(e, 'text')}
              disabled={!formik.values.hazardousWasteReclassification}
            />
            <FormErrorMessage fieldName="hazardousWasteReclassificationDescription" formikInstance={formik} />
            <br />
            <br />
            {mode === CardSubtype.TRANSFER ? (
              <>
                <label htmlFor="wasteMass">
                  {`Masa odpadów w tonach [Mg]`}
                  <span className="text-red-500">&nbsp;*</span>
                </label>
                <InputText
                  id="wasteMass"
                  value={formik.values.wasteMass || ''}
                  onKeyDown={(e) => disableDotInTextInput(e)}
                  onInput={(e) => limitOfDigitsAfterDecimalsFilter(e, 4)}
                  onChange={(e) => handleChangeField(e, 'text')}
                  type="number"
                />
                <FormErrorMessage fieldName="wasteMass" formikInstance={formik} />
                <br />
                <br />
              </>
            ) : (
              <></>
            )}
            <br />
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};

export default CorrectSender;
