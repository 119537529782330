import React, { useState, useEffect } from 'react';
import { AutoComplete } from 'primereact/autocomplete';

export const DictionaryAdditionalFieldAutocomplete = ({ source, id, value, onChange }) => {
  const [items, setItems] = useState(null);
  const [filteredItems, setFilteredItems] = useState(null);
  const [searchText, setSearchText] = useState(null);

  useEffect(() => {
    setItems(
      source.map((_value) => {
        return { value: _value };
      }),
    );
  }, [source]);

  const searchBranch = (event) => {
    setTimeout(() => {
      if (!event.query.trim().length) {
        setFilteredItems([...items]);
      } else {
        setFilteredItems(
          items.filter((item) => {
            return item.value.toLowerCase().includes(event.query.toLowerCase());
          }),
        );
      }
    }, 250);
  };

  const getValue = () => (searchText !== null ? searchText : { value: value || '' });

  const handleSelect = (e) => {
    setSearchText(null);
    onChange(e.value?.value);
  };

  const handleChange = (e) => {
    if (typeof e.value === 'string') {
      if (e.value === '') {
        onChange('');
      }
      setSearchText(e.value);
    }
  };

  const handleHide = () => {
    if (value) {
      setSearchText(null);
    }
  };

  const handleBlur = () => {
    if (searchText === '') {
      onChange('');
      setSearchText('');
    } else {
      setSearchText(null);
    }
  };

  return (
    <AutoComplete
      id={id}
      value={getValue()}
      suggestions={filteredItems}
      completeMethod={searchBranch}
      dropdown
      placeholder={''}
      onChange={handleChange}
      onSelect={handleSelect}
      onHide={handleHide}
      onBlur={handleBlur}
      field="value"
    />
  );
};
