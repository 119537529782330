import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { useTranslation } from 'react-i18next';
import { ApiKeyCredentials } from '../../../../services/ScaleoApiServices/ApiKeyService';
import { ApiKeysTableHeaders } from './ApiKeysTableHeaders';
import { ApiKeyRow } from './ApiKeyRow';

interface ApiKeysTableProps {
  fetchAPI: () => Promise<void>;
  apiKeys: ApiKeyCredentials[];
  setApiKey: (a: ApiKeyCredentials) => void;
  setApiKeyDialogOpen: (b: boolean) => void;
}

export const ApiKeysTable = ({ fetchAPI, apiKeys, setApiKey, setApiKeyDialogOpen }: ApiKeysTableProps) => {
  const { t } = useTranslation('managementApiKey');

  return (
    <Fieldset legend={t('apiKeysHeader')} className="mt-1vh">
      {apiKeys.length > 0 && <ApiKeysTableHeaders />}
      {apiKeys?.map((field: any, i: number) => (
        <ApiKeyRow
          field={field}
          index={i}
          fetchAPI={fetchAPI}
          setApiKey={setApiKey}
          setApiKeyDialogOpen={setApiKeyDialogOpen}
        />
      ))}
      <div className="reportTooltip">
        <Button
          type="button"
          label={t('add')}
          icon="pi pi-plus"
          severity="success"
          className="mt-4"
          onClick={() => setApiKeyDialogOpen(true)}
        />
      </div>
    </Fieldset>
  );
};
