export const blockBodyScroll = () => {
  if (document.body.classList) {
    document.body.classList.add('blocked-scroll');
  } else {
    document.body.className += ' blocked-scroll';
  }
};

export const unblockBodyScroll = () => {
  if (document.body.classList) {
    document.body.classList.remove('blocked-scroll');
  } else {
    document.body.className = document.body.className.replace(
      new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
      ' ',
    );
  }
};

export const isDesktop = () => {
  return window.innerWidth > 896;
};

export const isMobile = () => {
  return window.innerWidth <= 1025;
};
