import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputNumber } from 'primereact/inputnumber';
import { Tree } from 'primereact/tree';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import edgeDevicesService from '../../../services/ScaleoApiServices/EdgeDevicesService';
import { ReducerState } from '../../../types/reducer-state';
import { isModulesEnabled } from '../../../utils/modulesUtils';
import { jsonToTreeData } from '../../../utils/treeUtils';
import FormErrorMessage from '../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../_shared/FormErrorMessageScroller';
import { Calendar } from 'primereact/calendar';
import { RadioButton } from 'primereact/radiobutton';
import { Dictionary } from '../../../types/dictionary';
import { AutomationProcessConfig, ValueDynamicSetting } from '../../../types/edge-device';
import DynamicSettings from '../DynamicSettings/DynamicSettings';
import { MultiSelect } from 'primereact/multiselect';
import multiSelectPanelHeader from '../../_shared/multiSelectPanelHeader';
import { CustomAutoComplete } from '../../_shared/CustomComponents';
import { AutomaticWeighingMode } from '../../../enums/automatic-weighing-mode.enum';

const MILISECONDS_IN_MINUTE = 60 * 1000;

interface AutomationProcessConfigDialogProps {
  edgeDevice: any;
  automationProcessConfig: any;
  onCancel: () => void;
  visible: boolean;
  onConfigSaved: () => void;
  dictionaries: Dictionary[] | null;
}

export const AutomationProcessConfigDialog = ({
  edgeDevice,
  automationProcessConfig,
  onCancel,
  visible,
  onConfigSaved,
  dictionaries,
}: AutomationProcessConfigDialogProps) => {
  const { t } = useTranslation('managementEdgeDevices');

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const automationEnabled = isModulesEnabled(['AUTOMATION'], loggedUserContext.currentCustomer?.subscriptionModules);

  const recognitionAndAutomationEnabled =
    isModulesEnabled(['REGISTRATION_NUMBER_RECOGNITION'], loggedUserContext.currentCustomer?.subscriptionModules) &&
    automationEnabled;

  const ALL_DATASETS = [
    {
      name: 'PRODUCTS',
      translation: t(`products`),
    },
    {
      name: 'VEHICLES',
      translation: t(`vehicles`),
    },
  ];

  function getTranslationKey(weighingMode: string) {
    switch (weighingMode) {
      case 'SINGLE':
        return 'singleWeighing';
      case 'DOUBLE':
        return 'doubleWeighing';
      default:
        return '';
    }
  }

  const automaticWeighingModes = [
    {
      name: t('singleWeighing'),
      value: AutomaticWeighingMode.SINGLE,
    },
    {
      name: t('doubleWeighing'),
      value: AutomaticWeighingMode.DOUBLE,
    },
  ];

  const onChangeAutomaticWeighingMode = (e: any) => {
    formik.setFieldValue('automaticWeighingMode', e?.value?.value);
  };

  const formik = useFormik({
    initialValues: {} as AutomationProcessConfig,
    validate: (data: any) => {
      const errors: any = {};

      if (!data.automationStates) {
        errors.automationStates = t('noAutomationStatesError');
      }

      if (!data.automaticWeighingsThreshold) {
        errors.automaticWeighingsThreshold = t('noAutomaticWeighingsThresholdError');
      }

      if (data.enableAutomaticTare && !data.automaticTareThreshold) {
        errors.automaticTareThreshold = t('noAutomaticTareThresholdError');
      }

      if (
        data.enableAutomaticClosing &&
        data.automaticClosingWeighingAfterMinutes !== 0 &&
        !data.automaticClosingWeighingAfterMinutes
      ) {
        errors.automaticClosingWeighingAfterMinutes = t('closingWeighingAfterMinutesError');
      }

      (data?.dynamicSettings as ValueDynamicSetting[])?.map((dynamicSetting) => {
        return dynamicSetting.type !== 'boolean' && dynamicSetting?.required && !dynamicSetting.value
          ? (errors[dynamicSetting.name] = t('valueRequiredError'))
          : undefined;
      });

      return errors;
    },
    onSubmit: async () => {
      edgeDevicesService
        .saveAutomationConfig(edgeDevice.id, {
          saveRecognizedVehicles: formik.values.saveRecognizedVehicles,
          enableAutomaticCombine: formik.values.enableAutomaticCombine,
          enableAutomaticTare: formik.values.enableAutomaticTare,
          automaticWeighingsThreshold: formik.values.automaticWeighingsThreshold,
          automaticTareThreshold: formik.values.automaticTareThreshold,
          automationStates: formik.values.automationStates,
          automaticClosingWeighingAfterMinutes: formik.values.enableAutomaticClosingAfterTime
            ? formik.values.automaticClosingWeighingAfterMinutes
            : null,
          automaticClosingWeighingAtMinutes: formik.values.enableAutomaticClosingAtTime
            ? formik.values.automaticClosingWeighingAtMinutes
            : null,
          useLastMeasurementDateAsClosingDate: formik.values.useLastMeasurementDateAsClosingDate,
          useAutomaticClosingDateAsClosingDate: formik.values.useAutomaticClosingDateAsClosingDate,
          dynamicSettings: formik.values.dynamicSettings,
          datasetsSyncConfig: JSON.stringify(formik.values.datasetsSyncConfig),
          automaticWeighingMode: formik.values.automaticWeighingMode,
          maxWeightBrutto: formik.values.maxWeightBrutto,
          allowManualWeighingIfVehicleOverloaded: formik.values.allowManualWeighingIfVehicleOverloaded,
          allowManualWeighingIfVehicleIncorrectPosition: formik.values.allowManualWeighingIfVehicleIncorrectPosition,
        })
        .then(() => {
          onConfigSaved();
        });
    },
  });

  useEffect(() => {
    automationProcessConfig &&
      formik.resetForm({
        values: {
          saveRecognizedVehicles: automationProcessConfig.saveRecognizedVehicles,
          enableAutomaticClosingAfterTime: automationProcessConfig.automaticClosingWeighingAfterMinutes !== null,
          enableAutomaticClosingAtTime: automationProcessConfig.automaticClosingWeighingAtMinutes !== null,
          enableAutomaticCombine: automationProcessConfig.enableAutomaticCombine,
          enableAutomaticTare: automationProcessConfig.enableAutomaticTare,
          automaticWeighingsThreshold: automationProcessConfig.automaticWeighingsThreshold,
          automaticTareThreshold: automationProcessConfig.automaticTareThreshold,
          automationStates: automationProcessConfig.automationStates,
          automaticClosingWeighingAfterMinutes: automationProcessConfig.automaticClosingWeighingAfterMinutes,
          automaticClosingWeighingAtMinutes: automationProcessConfig.automaticClosingWeighingAtMinutes,
          useLastMeasurementDateAsClosingDate: automationProcessConfig.useLastMeasurementDateAsClosingDate,
          useAutomaticClosingDateAsClosingDate: automationProcessConfig.useAutomaticClosingDateAsClosingDate,
          dynamicSettings: automationProcessConfig.dynamicSettings,
          datasetsSyncConfig: automationProcessConfig.datasetsSyncConfig ?? [],
          automaticWeighingMode: automationProcessConfig.automaticWeighingMode,
          maxWeightBrutto: automationProcessConfig.maxWeightBrutto,
          allowManualWeighingIfVehicleOverloaded: automationProcessConfig.allowManualWeighingIfVehicleOverloaded,
          allowManualWeighingIfVehicleIncorrectPosition:
            automationProcessConfig.allowManualWeighingIfVehicleIncorrectPosition,
        },
      });
  }, [automationProcessConfig]);

  const dialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={onCancel} />
      <Button
        type="submit"
        label={t('saveButton')}
        icon="pi pi-check"
        text
        onClick={async () => {
          setIsSubmitClicked(true);
          await formik.submitForm();
        }}
      />
    </>
  );

  const maxWeightBruttoHandler = (e: any) => {
    formik.setFieldValue('maxWeightBrutto', e?.value);
  };

  const importData = (event: any) => {
    const [f] = event.files;
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const jsonData = JSON.parse(e.target.result as string);

      formik.setFieldValue(
        'dynamicSettings',
        jsonData[0].dynamicSettings?.map((p: any) => ({ ...p, value: p.defaultValue })),
      );
      formik.setFieldValue('automationStates', jsonData, false);
    };
    reader.readAsText(f);
  };

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={visible}
        header={t('automationConfigHeader')}
        modal
        className="p-fluid w-40vw"
        footer={dialogFooter}
        onHide={onCancel}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      >
        <form>
          <div className="col-12">
            <div className="field">
              <label className="mb-3">Parametry</label>
              <div className="formgrid grid">
                <div className="field col-12">
                  <label htmlFor="automaticWeighingsThreshold">{t('automaticWeighingsThreshold')}</label>
                  <InputNumber
                    id="automaticWeighingsThreshold"
                    value={formik.values.automaticWeighingsThreshold}
                    onValueChange={formik.handleChange}
                    min={1}
                  />
                  <FormErrorMessage
                    withTouchedTrue={isSubmitClicked}
                    fieldName="automaticWeighingsThreshold"
                    formikInstance={formik}
                  />
                </div>

                {isModulesEnabled(
                  ['VEHICLE_OVERLOAD_CONTROL'],
                  loggedUserContext.currentCustomer?.subscriptionModules,
                ) && (
                  <div className="field col-12">
                    <label htmlFor="maxWeightBrutto">{`${t('maxWeightBrutto')}`}</label>
                    <InputNumber
                      id="maxWeightBrutto"
                      value={formik.values.maxWeightBrutto}
                      onChange={maxWeightBruttoHandler}
                    />

                    <FormErrorMessage
                      withTouchedTrue={isSubmitClicked}
                      fieldName="maxWeightBrutto"
                      formikInstance={formik}
                    />
                  </div>
                )}

                <DynamicSettings formik={formik} dictionaries={dictionaries} isSubmitClicked={isSubmitClicked} />
                {formik.values.enableAutomaticTare && (
                  <div className="field col-12">
                    <label htmlFor="automaticTareThreshold">{t('automaticTareThreshold')}</label>
                    <InputNumber
                      id="automaticTareThreshold"
                      value={formik.values.automaticTareThreshold}
                      onValueChange={formik.handleChange}
                      min={1}
                    />
                    <FormErrorMessage
                      withTouchedTrue={isSubmitClicked}
                      fieldName="automaticTareThreshold"
                      formikInstance={formik}
                    />
                  </div>
                )}
                <div className="field-checkbox col-12">
                  <Checkbox
                    id="allowManualWeighingIfVehicleOverloaded"
                    checked={formik.values.allowManualWeighingIfVehicleOverloaded}
                    onChange={(e) => formik.setFieldValue('allowManualWeighingIfVehicleOverloaded', e.checked)}
                  ></Checkbox>
                  <label htmlFor="allowManualWeighingIfVehicleOverloaded" className="checkbox-label">
                    {t('allowManualWeighingIfVehicleOverloaded')}
                  </label>
                </div>
                <div className="field-checkbox col-12">
                  <Checkbox
                    id="allowManualWeighingIfVehicleIncorrectPosition"
                    checked={formik.values.allowManualWeighingIfVehicleIncorrectPosition}
                    onChange={(e) => formik.setFieldValue('allowManualWeighingIfVehicleIncorrectPosition', e.checked)}
                  ></Checkbox>
                  <label htmlFor="allowManualWeighingIfVehicleIncorrectPosition" className="checkbox-label">
                    {t('allowManualWeighingIfVehicleIncorrectPosition')}
                  </label>
                </div>
                {recognitionAndAutomationEnabled && (
                  <div className="field-checkbox col-12">
                    <Checkbox
                      id="saveRecognizedVehicles"
                      checked={formik.values.saveRecognizedVehicles}
                      onChange={(e) => formik.setFieldValue('saveRecognizedVehicles', e.checked)}
                    ></Checkbox>
                    <label htmlFor="saveRecognizedVehicles" className="checkbox-label">
                      {t('saveRecognizedVehicles')}
                    </label>
                  </div>
                )}
                <div className="field-checkbox col-12">
                  <Checkbox
                    id="enableAutomaticClosingAfterTime"
                    checked={formik.values.enableAutomaticClosingAfterTime}
                    onChange={(e) => {
                      void formik.setFieldValue('enableAutomaticClosingAfterTime', e.checked);
                      void formik.setFieldValue('automaticClosingWeighingAfterMinutes', 0);
                      e.checked && void formik.setFieldValue('enableAutomaticCombine', false);
                      e.checked && void formik.setFieldValue('enableAutomaticClosingAtTime', false);
                      e.checked && void formik.setFieldValue('enableAutomaticClosingAtMinutes', null);
                      e.checked && void formik.setFieldValue('useAutomaticClosingDateAsClosingDate', true);
                      void formik.setFieldValue('useLastMeasurementDateAsClosingDate', false);
                      !e.checked && void formik.setFieldValue('useAutomaticClosingDateAsClosingDate', false);
                    }}
                  ></Checkbox>
                  <label htmlFor="enableAutomaticClosingAfterTime" className="p-checkbox-label">
                    {t('enableAutomaticClosingAfterTime')}
                  </label>
                </div>
                {formik.values.enableAutomaticClosingAfterTime && (
                  <div className="field col-12">
                    <label htmlFor="automaticClosingWeighingAfterMinutes">{''}</label>
                    <InputNumber
                      id="automaticClosingWeighingAfterMinutes"
                      value={formik.values.automaticClosingWeighingAfterMinutes as number}
                      onValueChange={formik.handleChange}
                      min={0}
                    />
                    <FormErrorMessage
                      withTouchedTrue={isSubmitClicked}
                      fieldName="automaticClosingWeighingAfterMinutes"
                      formikInstance={formik}
                    />
                  </div>
                )}
                <div className="field-checkbox col-12">
                  <Checkbox
                    id="enableAutomaticClosingAtTime"
                    checked={formik.values.enableAutomaticClosingAtTime}
                    onChange={(e) => {
                      void formik.setFieldValue('enableAutomaticClosingAtTime', e.checked);
                      void formik.setFieldValue('automaticClosingWeighingAtMinutes', new Date(0));
                      e.checked && void formik.setFieldValue('enableAutomaticCombine', false);
                      e.checked && void formik.setFieldValue('enableAutomaticClosingAfterTime', false);
                      e.checked && void formik.setFieldValue('enableAutomaticClosingAfterMinutes', null);
                      e.checked && void formik.setFieldValue('useAutomaticClosingDateAsClosingDate', true);
                      void formik.setFieldValue('useLastMeasurementDateAsClosingDate', false);
                      !e.checked && void formik.setFieldValue('useAutomaticClosingDateAsClosingDate', false);
                    }}
                  ></Checkbox>
                  <label htmlFor="enableAutomaticClosingAtTime" className="checkbox-label">
                    {t('enableAutomaticClosingAtTime')}
                  </label>
                </div>
                {formik.values.enableAutomaticClosingAtTime && (
                  <div className="col-2 lg:col-7 p-fluid mt-2 mb-3">
                    {t('automaticClosingWeighingAtMinutes')}
                    <Calendar
                      className="w-7rem mt-2"
                      showTime
                      timeOnly
                      hourFormat="24"
                      value={
                        formik.values.automaticClosingWeighingAtMinutes &&
                        (new Date(formik.values.automaticClosingWeighingAtMinutes * MILISECONDS_IN_MINUTE) as any)
                      }
                      onChange={(e: any) =>
                        formik.setFieldValue(
                          'automaticClosingWeighingAtMinutes',
                          e?.value?.getUTCHours() * 60 + e?.value?.getUTCMinutes(),
                        )
                      }
                    />
                    <FormErrorMessage fieldName="automaticClosingWeighingAtMinutes" formikInstance={formik} />
                  </div>
                )}
                {(formik.values.enableAutomaticClosingAtTime || formik.values.enableAutomaticClosingAfterTime) && (
                  <div className="col-12 lg:col-7 p-fluid mt-2 mb-3">
                    <label htmlFor="closingWeighingDate" className="checkbox-label">
                      {t('closingWeighingDate')}
                    </label>
                  </div>
                )}
                {(formik.values.enableAutomaticClosingAtTime || formik.values.enableAutomaticClosingAfterTime) && (
                  <div className="field-checkbox col-12 ml-2">
                    <RadioButton
                      id="useAutomaticClosingDateAsClosingDate"
                      checked={formik.values.useAutomaticClosingDateAsClosingDate}
                      onChange={() => {
                        void formik.setFieldValue('useAutomaticClosingDateAsClosingDate', true);
                        void formik.setFieldValue('useLastMeasurementDateAsClosingDate', false);
                      }}
                    ></RadioButton>
                    <label htmlFor="useAutomaticClosingDateAsClosingDate" className="checkbox-label">
                      {t('useAutomaticClosingDateAsClosingDate')}
                    </label>
                  </div>
                )}
                {(formik.values.enableAutomaticClosingAtTime || formik.values.enableAutomaticClosingAfterTime) && (
                  <div className="field-checkbox col-12 ml-2">
                    <RadioButton
                      id="useLastMeasurementDateAsClosingDate"
                      checked={formik.values.useLastMeasurementDateAsClosingDate}
                      onChange={() => {
                        void formik.setFieldValue('useLastMeasurementDateAsClosingDate', true);
                        void formik.setFieldValue('useAutomaticClosingDateAsClosingDate', false);
                      }}
                    ></RadioButton>
                    <label htmlFor="useLastMeasurementDateAsClosingDate" className="checkbox-label">
                      {t('useLastMeasurementDateAsClosingDate')}
                    </label>
                  </div>
                )}

                {automationEnabled && (
                  <div className="field-checkbox col-12">
                    <Checkbox
                      id="enableAutomaticCombine"
                      checked={formik.values.enableAutomaticCombine}
                      onChange={(e) => formik.setFieldValue('enableAutomaticCombine', e.checked)}
                      disabled={
                        formik.values.enableAutomaticClosingAfterTime &&
                        formik.values.automaticClosingWeighingAfterMinutes === 0
                      }
                    ></Checkbox>
                    <label htmlFor="enableAutomaticCombine" className="checkbox-label">
                      {t('enableAutomaticCombine')}
                    </label>
                  </div>
                )}
                {automationEnabled && (
                  <div className="field-checkbox col-12">
                    <Checkbox
                      id="enableAutomaticTare"
                      checked={formik.values.enableAutomaticTare}
                      onChange={(e) => formik.setFieldValue('enableAutomaticTare', e.checked)}
                    ></Checkbox>
                    <label htmlFor="enableAutomaticTare" className="checkbox-label">
                      {t('enableAutomaticTare')}
                    </label>
                  </div>
                )}
              </div>
            </div>
            <div className="font-bold my-4">{t('automaticWeighingMode')}</div>
            <div className="field">
              <CustomAutoComplete
                id="automaticWeighingMode"
                value={t(getTranslationKey(formik.values.automaticWeighingMode))}
                handler={onChangeAutomaticWeighingMode}
                source={automaticWeighingModes}
              />
            </div>
            <div className="field">
              <label className="mb-3">{t('config')}</label>
              <div className="formgrid grid">
                <div className="field-checkbox col-12">
                  <FileUpload
                    id="states"
                    mode="basic"
                    accept=".json"
                    maxFileSize={1000000}
                    chooseLabel={t('importButton')}
                    className="mr-2 mt-2"
                    customUpload
                    auto
                    uploadHandler={importData}
                  />
                  <FormErrorMessage
                    withTouchedTrue={isSubmitClicked}
                    fieldName="automationStates"
                    formikInstance={formik}
                  />
                </div>
                <div className="field col-12">
                  {!!formik.values.automationStates && (
                    <Tree
                      value={jsonToTreeData(formik.values.automationStates)}
                      nodeTemplate={(node, options) => {
                        let label = node.label;

                        if (!node.children) {
                          label = (
                            <>
                              {node.label}:&nbsp;
                              <b>{node.data}</b>
                            </>
                          ) as any;
                        }

                        return <span className={options.className}>{label}</span>;
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="field">
              <label htmlFor="locationIds">{t('title')}</label>
              <MultiSelect
                value={formik.values.datasetsSyncConfig}
                onChange={(e) => {
                  void formik.setFieldValue('datasetsSyncConfig', e.value);
                }}
                options={ALL_DATASETS}
                optionLabel="translation"
                optionValue="name"
                placeholder={t('placeholder')}
                className="multiselect-custom"
                maxSelectedLabels={0}
                selectedItemsLabel={t('selectedLabel')}
                showClear={true}
                panelHeaderTemplate={multiSelectPanelHeader}
              />
              <FormErrorMessage fieldName="locationIds" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};
