import useModulesConfiguration from '../../../../utils/useModulesConfiguration';
import { useTranslation } from 'react-i18next';

const ListLegend = () => {
  const { t } = useTranslation('weighingWeighingList');
  const modulesConfiguration = useModulesConfiguration();

  return (
    <div className="flex flex-wrap">
      <div className="flex align-items-center m-2">
        <div className="legend-qube" style={{ backgroundColor: 'rgba(255, 160, 160, 0.75)' }}></div>
        <div className="ml-2">{t('legendDeleted')}</div>
      </div>
      <div className="flex align-items-center m-2">
        <div className="legend-qube" style={{ backgroundColor: 'rgba(253, 219, 154, 0.75)' }} />
        <div className="ml-2">{t('legendManual')}</div>
      </div>
      <div className="flex align-items-center m-2">
        <div className="legend-qube" style={{ backgroundColor: 'rgba(251, 255, 143, 0.75)' }} />
        <div className="ml-2">{t('legendEdited')}</div>
      </div>
      <div className="flex align-items-center m-2">
        <div className="legend-qube" style={{ backgroundColor: 'rgba(207, 207, 207, 0.75)' }} />
        <div className="ml-2">{t('legendTest')}</div>
      </div>
      {modulesConfiguration.bdo === 'enabled' && (
        <div className="flex align-items-center m-2">
          <div className="legend-qube" style={{ backgroundColor: 'rgba(180, 255, 180, 0.75)' }} />
          <div className="ml-2">{t('legendKpo')}</div>
        </div>
      )}
    </div>
  );
};
export default ListLegend;
