import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { FileUpload } from 'primereact/fileupload';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import logosService from '../../../../services/ScaleoApiServices/LogosService';
import { useTranslation } from 'react-i18next';

interface FilesForm {
  files: any;
}

interface GraphicElementsProps {
  formik: any;
  fetchAPI: () => Promise<void>;
  setFileBuffer: (b: Buffer) => void;
  logoUrl: string | null;
}

const GraphicElements = ({ formik, fetchAPI, setFileBuffer, logoUrl }: GraphicElementsProps) => {
  const { t } = useTranslation('managementSystemConfigurationGraphicElements');

  function bufferToArrayBuffer(buf: Buffer) {
    const byteBuffer = new Uint8Array(new ArrayBuffer(buf.length));
    return byteBuffer.map((_, index) => buf[index]) as Buffer;
  }

  const onUploadFile = (data: FilesForm) => {
    const [f] = data.files;
    const reader = new FileReader();
    reader.readAsArrayBuffer(f);
    reader.onload = async (e: any) => {
      const file = e.target.result;
      setFileBuffer(bufferToArrayBuffer(Buffer.from(file)));
      formik.setFieldValue('files', [file], false);
    };
  };

  const headerTemplate = (options: any) => {
    const { className, chooseButton } = options;
    return (
      <div className={`${className} grid p-0 m-0`}>
        <b className="col-4 p-3 ">{t('logo')}</b>
        <div className="col-8 p-2 text-right">{chooseButton}</div>
      </div>
    );
  };

  const emptyTemplate = () => {
    const deleteLogo = async () => {
      await logosService.deleteLogo();
      await fetchAPI();
    };

    return logoUrl ? (
      <>
        <img className="pr-300px" src={logoUrl} width="400px" />
        <Button onClick={deleteLogo}>{t('delete')}</Button>
      </>
    ) : (
      <p className="m-0">{t('dropFile')}</p>
    );
  };

  return (
    <div className="mt-2vh">
      <Fieldset legend={t('title')}>
        <div className="grid pt-1vh">
          <FileUpload
            headerTemplate={headerTemplate}
            className="col-7"
            name="files"
            customUpload
            multiple={false}
            uploadHandler={onUploadFile}
            accept="image/*"
            auto
            maxFileSize={1000000}
            emptyTemplate={emptyTemplate}
            chooseLabel={formik.values.files.length > 0 ? t('chosen') : t('choose')}
          />
          <FormErrorMessage className="col-7" fieldName="files" formikInstance={formik} />
        </div>
      </Fieldset>
    </div>
  );
};

export default GraphicElements;
