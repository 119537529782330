import { formatDecimal } from '../../../utils/formatUtils';

interface WeighingSummaryTextFieldTemplateProps {
  label: string;
  value: string;
}

const WeighingSummaryTextFieldTemplate = ({ label, value }: WeighingSummaryTextFieldTemplateProps) =>
  value ? (
    <>
      <div className="col-6">
        <strong>{label}</strong>
      </div>
      <div className="field col-6">{`${value}`}</div>
    </>
  ) : (
    <></>
  );

interface WeighingSummaryDecimalFieldTemplateProps {
  label: string;
  value: number | string;
  unit: string | null;
}

const WeighingSummaryDecimalFieldTemplate = ({ label, value, unit }: WeighingSummaryDecimalFieldTemplateProps) => {
  let _value = parseFloat(`${value}`.replace(/,/g, '.').replace(/\s/g, '')) as number | string;
  let _unit = unit;

  if (isNaN(_value as number)) {
    _value = value;
    _unit = '';
  } else {
    _value = formatDecimal(_value);
  }

  return _value ? (
    <>
      <div className="col-6">
        <strong>{label}</strong>
      </div>
      <div className="field col-6">{`${_value} ${_unit}`}</div>
    </>
  ) : (
    <></>
  );
};

export { WeighingSummaryDecimalFieldTemplate, WeighingSummaryTextFieldTemplate };
