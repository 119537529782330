import { useState } from 'react';
import { Weighing } from '../../types/weighing';

export const useWeighingsListSelection = () => {
  const [selectedWeighings, setSelectedWeighings] = useState<Weighing[] | null>(null);

  const handleSelectionChange = (e: any) => {
    const selected = [] as Weighing[];
    e.value.forEach((item: any) => {
      if (item.deleted === false) {
        selected.push(item);
      }
    });
    setSelectedWeighings(selected);
  };

  return {
    selectedWeighings,
    setSelectedWeighings,
    handleSelectionChange,
  };
};
