import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';

interface DeleteTrailersDialogProps {
  deleteTrailersDialogVisible: boolean;
  hideDeleteTrailersDialog: () => void;
  deleteSelectedTrailers: () => void;
}

export const DeleteTrailersDialog = ({
  deleteTrailersDialogVisible,
  hideDeleteTrailersDialog,
  deleteSelectedTrailers,
}: DeleteTrailersDialogProps) => {
  const { t } = useTranslation('datasetsTrailers');

  const deleteTrailersDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteTrailersDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteSelectedTrailers} />
    </>
  );

  return (
    <Dialog
      visible={deleteTrailersDialogVisible}
      header={
        <span className="flex align-items-center">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
          {t('confirm')}
        </span>
      }
      modal
      className="p-fluid min-width-450px"
      footer={deleteTrailersDialogFooter}
      onHide={hideDeleteTrailersDialog}
      breakpoints={{ '896px': '90vw' }}
    >
      <div className="confirmation-content">{<span>{t('deleteTrailersContent')}</span>}</div>
    </Dialog>
  );
};
