import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import edgeDevicesService from '../../../services/ScaleoApiServices/EdgeDevicesService';
import { userActions } from '../../../store/user-slice';
import { isModulesEnabled } from '../../../utils/modulesUtils';
import '../../DataTable.css';
import DataTableFilterElements from '../../_shared/DataTableFilterElements';
import { ActivationDialog } from './ActivationDialog';
import { AutomationProcessConfigDialog } from './AutomationConfigDialog';
import { DeleteEdgeDeviceConfirmationDialog } from './DeleteEdgeDeviceConfirmationDialog';
import { NewOrEditEdgeDeviceDialog } from './NewOrEditEdgeDeviceDialog';
import { ReducerState } from '../../../types/reducer-state';
import { EdgeDevice } from '../../../types/edge-device';
import dictionariesService from '../../../services/ScaleoApiServices/DictionariesService';
import stationsService from '../../../services/ScaleoApiServices/StationsService';
import { Station } from '../../../types/station';
import { Dictionary } from '../../../types/dictionary';

const EdgeDevices = () => {
  const { t } = useTranslation('managementEdgeDevices');

  const emptyPaginationParameters = {
    order: {
      isAscending: true,
      orderColumn: '',
    },
  };

  const emptyEdgeDevice = {
    name: '',
  };

  const activationStatusOptions = [
    { label: t('active'), value: 'ACTIVE' },
    { label: t('inactive'), value: 'INACTIVE' },
  ];

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const [componentData, setComponentData] = useState({
    edgeDevices: null,
    stations: null as Station[] | null,
    dictionaries: null as Dictionary[] | null,
  });

  const [edgeDevice, setEdgeDevice] = useState<Partial<EdgeDevice>>(emptyEdgeDevice);
  const filters = useSelector((state: ReducerState) => state.user.filtersState.edgeDevices);
  const [paginationParameters, setPaginationParameters] = useState(emptyPaginationParameters);

  const [edgeDeviceDialogVisible, setEdgeDeviceDialogVisible] = useState(false);
  const [deleteEdgeDeviceDialogVisible, setDeleteEdgeDeviceDialogVisible] = useState(false);
  const [activationCodeDialogVisible, setActivationCodeDialogVisible] = useState(false);
  const [automationConfigDialogVisible, setAutomationConfigDialogVisible] = useState(false);

  const [activationOtp, setActivationOtp] = useState();
  const [automationProcessConfig, setAutomationProcessConfig] = useState();

  const dispatch = useDispatch();
  const dt = useRef(null);

  const fetchComponentData = useCallback(async () => {
    void Promise.all([
      edgeDevicesService.searchEdgeDevices({ filters, paginationParameters }),
      stationsService.getStations(),
      dictionariesService.getDictionaries(),
    ]).then((response) => {
      setComponentData({
        edgeDevices: response[0],
        stations: response[1].data,
        dictionaries: response[2].data,
      });
    });
  }, [filters, paginationParameters]);

  useEffect(() => {
    fetchComponentData();
  }, [fetchComponentData, loggedUserContext.currentCustomer?.id]);

  const onEdgeDeviceCreated = () => {
    fetchComponentData();
    setEdgeDeviceDialogVisible(false);
    setEdgeDevice(emptyEdgeDevice);
  };

  const onEdgeDeviceUpdated = () => {
    fetchComponentData();
    setEdgeDeviceDialogVisible(false);
  };

  const hideDialog = () => setEdgeDeviceDialogVisible(false);

  const confirmDeleteEdgeDevice = (_edgeDevice: EdgeDevice) => {
    setEdgeDevice(_edgeDevice);
    setDeleteEdgeDeviceDialogVisible(true);
  };
  const hideDeleteEdgeDeviceDialog = () => {
    setDeleteEdgeDeviceDialogVisible(false);
  };
  const showActivationCodeDialog = (_edgeDevice: EdgeDevice) => {
    edgeDevicesService.getActivationOtp(_edgeDevice).then((otp) => {
      setActivationOtp(otp);
      setActivationCodeDialogVisible(true);
    });
  };
  const hideActivationCodeDialog = () => {
    setActivationCodeDialogVisible(false);
    fetchComponentData();
  };

  const hideAutomationConfigDialog = () => {
    setAutomationConfigDialogVisible(false);
  };

  const deleteEdgeDevice = () => {
    edgeDevicesService.deleteEdgeDevice(edgeDevice.id!).then(() => {
      fetchComponentData();
    });
    setDeleteEdgeDeviceDialogVisible(false);
    setEdgeDevice(emptyEdgeDevice);
  };

  const openNew = () => {
    setEdgeDevice(emptyEdgeDevice);
    setEdgeDeviceDialogVisible(true);
  };

  const editEdgeDevice = (_edgeDevice: EdgeDevice) => {
    setEdgeDevice({ ..._edgeDevice });
    setEdgeDeviceDialogVisible(true);
  };

  const generateActivationCode = (_edgeDevice: EdgeDevice) => {
    setEdgeDevice(_edgeDevice);
    showActivationCodeDialog(_edgeDevice);
  };

  const showAutomationConfigDialog = (edgeDevice: EdgeDevice) => {
    setEdgeDevice(edgeDevice);
    void edgeDevicesService.getAutomationConfig(edgeDevice.id!).then((automationProcessConfigResponse) => {
      setAutomationProcessConfig(automationProcessConfigResponse.data);
      setAutomationConfigDialogVisible(true);
    });
  };

  const syncEdgeDeviceData = (edgeDevice: EdgeDevice) => {
    edgeDevicesService.syncEdgeDeviceData(edgeDevice.id!);
  };

  const clearFilters = () => {
    dispatch(userActions.setEdgeDevicesFilters({}));
  };

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const changeFilter = (value: EdgeDevice, name: string) => {
    const newFilters = { ...filters };
    newFilters[name] = value;

    dispatch(userActions.setEdgeDevicesFilters(newFilters));
  };

  const header = (
    <div className="table-header text-2xl p-2">
      {t('header')} &#160;&#160;
      <div className="flex">
        <Button label={t('clearFilters')} icon="pi pi-filter-slash" outlined className="mr-2" onClick={clearFilters} />
      </div>
    </div>
  );

  const leftToolbarTemplate = () => (
    <Button label={t('addButton')} icon="pi pi-plus" severity="success" className="mr-2" onClick={openNew} />
  );

  const nameBodyTemplate = (rowData: EdgeDevice) => (
    <>
      <span className="p-column-title">{t('name')}</span>
      {rowData.name}
    </>
  );

  const stationBodyTemplate = (rowData: EdgeDevice) => (
    <>
      <span className="p-column-title">{t('station')}</span>
      {rowData.station?.name}
    </>
  );

  const statusBodyTemplate = (rowData: EdgeDevice) => (
    <>
      {rowData.activationStatus === 'ACTIVE' && (
        <Tag className="mr-2" icon="pi pi-check" severity="success" value={t('active')}></Tag>
      )}
      {rowData.activationStatus === 'INACTIVE' && (
        <Tag className="mr-2" icon="pi pi-info-circle" severity="info" value={t('inactive')}></Tag>
      )}
    </>
  );

  const dropdownOptionTemplate = (option: any) => (
    <div className="item">
      <i className={option.icon}></i>&#160;
      {option.label}
    </div>
  );

  const actionBodyTemplate = (rowData: EdgeDevice) => {
    const options = [
      { label: t('delete'), value: 'delete', icon: 'pi pi-trash' },
      { label: t('edit'), value: 'edit', icon: 'pi pi-pencil', disabled: rowData.activationStatus === 'ACTIVE' },
      {
        label: t('activationCode'),
        value: 'activation-code',
        icon: 'pi pi-key',
        disabled: rowData.activationStatus === 'ACTIVE',
      },
      loggedUserContext.limits?.allowEdgeDeviceConfigurationUpdate && {
        label: t('update'),
        value: 'configuration-update',
        icon: 'pi pi-refresh',
        disabled: rowData.activationStatus === 'INACTIVE',
      },
    ].filter((o) => o);

    isModulesEnabled(['AUTOMATION'], loggedUserContext.currentCustomer?.subscriptionModules) &&
      options.push({
        label: t('automationConfig'),
        value: 'automation-config',
        icon: 'pi pi-cog',
      });

    const handleChangeOption = (e: any) => {
      switch (e.value) {
        case 'edit':
          editEdgeDevice(rowData);
          break;
        case 'delete':
          confirmDeleteEdgeDevice(rowData);
          break;
        case 'activation-code':
          generateActivationCode(rowData);
          break;
        case 'automation-config':
          showAutomationConfigDialog(rowData);
          break;
        case 'configuration-update':
          syncEdgeDeviceData(rowData);
          break;

        default:
          break;
      }
    };

    return (
      <div className="actions">
        <Dropdown
          options={options}
          onChange={handleChangeOption}
          placeholder={t('actionsPlaceholder')}
          itemTemplate={dropdownOptionTemplate}
        />
      </div>
    );
  };

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                responsiveLayout="scroll"
                ref={dt}
                value={componentData.edgeDevices as any}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                rowHover
                emptyMessage={t('noData')}
                removableSort
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                stripedRows
                filterDisplay="row"
              >
                <Column
                  field="name"
                  header={t('name')}
                  sortable
                  body={nameBodyTemplate}
                  filter
                  showFilterMenu={false}
                  filterPlaceholder={t('searchByName')}
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters['name']}
                      onChangeFilter={changeFilter}
                      name="name"
                      placeholder={t('filter')}
                    />
                  }
                ></Column>
                <Column
                  field="activationStatus"
                  header={t('status')}
                  sortable
                  body={statusBodyTemplate}
                  filter
                  showFilterMenu={false}
                  filterElement={
                    <DataTableFilterElements.Dropdown
                      initialValue={filters['activationStatus']}
                      options={activationStatusOptions}
                      onChangeFilter={changeFilter}
                      name="activationStatus"
                      placeholder={t('filter')}
                    />
                  }
                  filterPlaceholder={t('searchByStatus')}
                ></Column>
                <Column
                  field="stationId"
                  header={t('station')}
                  sortable
                  body={stationBodyTemplate}
                  filter
                  showFilterMenu={false}
                  filterElement={
                    <DataTableFilterElements.Dropdown
                      initialValue={filters['stationId']}
                      options={componentData.stations?.map((s) => ({ label: s.name, value: s.id })) || []}
                      onChangeFilter={changeFilter}
                      name="stationId"
                      placeholder={t('filter')}
                    />
                  }
                  filterPlaceholder={t('searchByStation')}
                ></Column>
                <Column body={actionBodyTemplate} className="w-150px text-center"></Column>
              </DataTable>
              <NewOrEditEdgeDeviceDialog
                visible={edgeDeviceDialogVisible}
                edgeDevice={edgeDevice as any}
                stations={componentData.stations!}
                onCancel={hideDialog}
                onEdgeDeviceCreated={onEdgeDeviceCreated}
                onEdgeDeviceUpdated={onEdgeDeviceUpdated}
              />
              <DeleteEdgeDeviceConfirmationDialog
                visible={deleteEdgeDeviceDialogVisible}
                edgeDevice={edgeDevice}
                onConfirm={deleteEdgeDevice}
                onCancel={hideDeleteEdgeDeviceDialog}
              />
              <ActivationDialog
                edgeDevice={edgeDevice}
                activationOtp={activationOtp}
                onClose={hideActivationCodeDialog}
                visible={activationCodeDialogVisible}
              />
              <AutomationProcessConfigDialog
                edgeDevice={edgeDevice}
                automationProcessConfig={automationProcessConfig}
                onCancel={hideAutomationConfigDialog}
                visible={automationConfigDialogVisible}
                onConfigSaved={hideAutomationConfigDialog}
                dictionaries={componentData.dictionaries}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EdgeDevices;
