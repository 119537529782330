import React from 'react';
import { useSelector } from 'react-redux';
import { formatDateTime, formatDecimal } from '../../../../../../utils/formatUtils';
import { getTranslatedLabel, tareTypeLabels } from '../../../../../../utils/labels';
import { useTranslation } from 'react-i18next';

const VehiclePersitedTareInfo = () => {
  const { t } = useTranslation('weighingHandlingWeighings');
  const { t: t1 } = useTranslation('utils');
  const unit = useSelector((state) => state.appConfig.unit);
  const { weighingState } = useSelector((state) => state.weighing);
  const tare = weighingState?.tare;

  return (
    <>
      <h6>{t('vehiclePersistedTareTitle')}</h6>
      <div className="field grid">
        <label htmlFor="name3" className="col-5">
          {t('vehiclePersistedTareValue')}
        </label>
        <div className="col-7">
          <span>{tare?.value ? `${formatDecimal(tare?.value)} ${unit}` : '-'} </span>
        </div>
      </div>
      <div className="field grid">
        <label htmlFor="name3" className="col-5">
          {t('vehiclePersistedTareDate')}
        </label>
        <div className="col-7">
          <span>{tare?.timestamp ? formatDateTime(tare.timestamp) : '-'}</span>
        </div>
      </div>
      <div className="field grid">
        <label htmlFor="name3" className="col-5">
          {t('vehiclePersistedTareSource')}
        </label>
        <div className="col-7">
          <span>{getTranslatedLabel(tareTypeLabels, tare?.type, t1) || '-'}</span>
        </div>
      </div>
    </>
  );
};

export default VehiclePersitedTareInfo;
