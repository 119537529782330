import { useEffect } from 'react';

const scrollToErrorMessage = (errors) => {
  const errorsNames = Object.keys(errors);
  const node = document.getElementById(`error-msg-${errorsNames[0]}`);

  if (node) {
    node.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  }
};

const FormErrorMessageScroller = ({ children, formikInstance, beforeScroll }) => {
  useEffect(() => {
    if (formikInstance?.isSubmitting || formikInstance?.isValidating) {
      if (typeof beforeScroll === 'function') {
        beforeScroll();
      }
      scrollToErrorMessage(formikInstance.errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikInstance]);

  return children;
};

export default FormErrorMessageScroller;
