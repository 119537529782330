import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import logosService from '../../services/ScaleoApiServices/LogosService';
import weighingConfigurationService from '../../services/ScaleoApiServices/WeighingConfigurationService';
import { appConfigActions } from '../../store/appConfig-slice';
import { userActions } from '../../store/user-slice';
import formChangeFieldMethod from '../../utils/formChangeFieldMethod';
import { ReducerState } from '../../types/reducer-state';

export const usePrintsConfigurationForm = (
  fileBuffer: Buffer | null,
  initValues: any,
  stringifiedInitFormValues: string,
) => {
  const { t } = useTranslation('managementSystemConfigurationPrints');

  const dispatch = useDispatch();
  const confirmationOfWindowChange = useSelector((state: ReducerState) => state.appConfig.confirmationOfWindowChange);

  const handleSubmit = async (data: any) => {
    const postData = {
      receiptOrientation: data.receiptOrientation,
      receiptFormat: data.receiptFormat,
      receiptFont: data.receiptFont,
      receiptMargin: data.receiptMargin,
      receiptAutoSend: data.receiptAutoSend,
      receiptSendToDrivers: data.receiptSendToDrivers,
      receiptSendToContractors: data.receiptSendToContractors,
      receiptAddPhotos: data.receiptAddPhotos,
      receiptEmails: data.receiptEmails,
      receiptCustomFooter: data.receiptCustomFooter,
      receiptFieldsOrder: data.receiptFieldsOrder,
    };
    if (fileBuffer) {
      await logosService.saveLogo(fileBuffer);
    }
    await weighingConfigurationService.updateConfigurationReceipts(postData);
    dispatch(userActions.shouldRefreshSystemConfiguration(true));
  };

  const validateMethod = (data: any) => {
    const errors: any = {};
    if (!data.receiptFont) {
      errors.receiptFont = t('fontSizeValidationError');
    }
    if (!data.receiptMargin) {
      errors.receiptMargin = t('marginValidationError');
    }

    if (
      data.receiptAutoSend &&
      !(data.receiptSendToDrivers || data.receiptSendToContractors || !(data.receiptEmails.length < 1))
    ) {
      errors.receiptEmails = t('emptyMailValidationError');
    }
    if (
      !data.receiptAutoSend &&
      (data.receiptSendToDrivers || data.receiptSendToContractors || !(data.receiptEmails.length < 1))
    ) {
      errors.receiptAutoSend = t('autoSendValidationError');
    }
    if (data.receiptEmails) {
      const re = /\S+@\S+\.\S+/;
      data.receiptEmails.map((email: string) => {
        if (!re.test(email)) return (errors.receiptEmails = t('mailValidationError'));
        else return null;
      });
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: initValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const changes = JSON.stringify(formik.values) !== stringifiedInitFormValues;
    if (confirmationOfWindowChange.required !== changes) {
      dispatch(
        appConfigActions.setConfirmationOfWindowChange({
          ...confirmationOfWindowChange,
          required: changes,
          saveMethod: formik.submitForm,
        }),
      );
    }
  }, [JSON.stringify(formik.values)]);

  const handleClickCancel = () => {
    formik.resetForm();
  };

  const handleClickSubmit = () => {
    formik.submitForm().then(() => {
      dispatch(appConfigActions.setConfirmationOfWindowChange({ ...confirmationOfWindowChange, required: false }));
    });
  };

  const handleChangeField = (e: any, type: string) => {
    formChangeFieldMethod(e, type, formik);
  };

  return {
    handleChangeField,
    handleClickSubmit,
    handleClickCancel,
    formik,
  };
};
