import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import '../.././DataTable.css';
import getPaginatorTemplate from '../../../utils/getPaginatorTemplate';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { useRef } from 'react';
import { CustomButton, CustomTableField } from '../../_shared/CustomComponents';
import DataTableFilterElements from '../../_shared/DataTableFilterElements';
import { Station } from '../../../types/station';
import { DeleteStationDialog } from './components/DeleteStationDialog';
import { DeleteStationsDialog } from './components/DeleteStationsDialog';
import { StationDialog } from './components/StationDialog';
import { useStationsData } from '../../../hooks/Stations/useStationsData';
import { useStationDialogs } from '../../../hooks/Stations/useStationDialogs';

const AnyColumn = Column as any;

export const Stations = () => {
  const { t } = useTranslation('managementStations');

  const dt = useRef(null);

  const {
    fetchStations,
    stations,
    handleSort,
    paginationParameters,
    paginatorState,
    setPaginatorState,
    internalFilters,
    changeFilter,
    clearFilters,
    locations,
    filters,
  } = useStationsData();

  const {
    setSelectedStations,
    openNew,
    confirmDeleteSelected,
    hideDeleteStationDialog,
    deleteStation,
    deleteSelectedStations,
    editStation,
    hideDeleteStationsDialog,
    setStation,
    setDeleteStationDialogVisible,
    setStationDialogVisible,
    stationDialogVisible,
    deleteStationDialogVisible,
    deleteStationsDialogVisible,
    station,
    selectedStations,
  } = useStationDialogs(fetchStations);

  const header = (
    <div className="table-header text-2xl p-2">
      {t('header')} &#160;&#160;
      <div className="flex">
        <Button label={t('clearFilters')} icon="pi pi-filter-slash" outlined className="mr-2" onClick={clearFilters} />
        <span>
          <DataTableFilterElements.Text
            initialValue={internalFilters['global']}
            onChangeFilter={changeFilter}
            name={'global'}
            placeholder={t('searchPlaceholder')}
            isSearchIcon
          />
        </span>
      </div>
    </div>
  );

  const leftToolbarTemplate = () => {
    return (
      <>
        <Button label={t('addButton')} icon="pi pi-plus" severity="success" className="mr-2" onClick={openNew} />
        <CustomButton
          label={t('deleteButton')}
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedStations || !selectedStations.length}
          tooltip={!selectedStations.length ? t('selectStation') : t('cannotDelete')}
          name={undefined}
          dataFlag={undefined}
          type={undefined}
        />
      </>
    );
  };

  const actionBodyTemplate = (rowData: Station) => {
    return (
      <div className="actions">
        <Button icon="pi pi-pencil" raised severity="success" className="mr-2" onClick={() => editStation(rowData)} />
        <Button
          icon="pi pi-trash"
          raised
          severity="secondary"
          onClick={() => {
            setStation(rowData);
            setDeleteStationDialogVisible(true);
          }}
        />
      </div>
    );
  };

  const columnBodyTemplate = (rowData: Station, column: any) => {
    return <CustomTableField row={rowData} col={column} withoutHeaders={undefined} />;
  };

  const locationBodyTemplate = (rowData: Station) => (
    <>
      <span className="column-title">{t('location')}</span>
      {locations?.find((l) => l.id === rowData.locationId)?.name}
    </>
  );

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                responsiveLayout="scroll"
                ref={dt}
                value={stations ?? []}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                rowHover
                selection={selectedStations}
                selectionMode="checkbox"
                onSelectionChange={(e) => setSelectedStations(e.value)}
                emptyMessage={t('noData')}
                removableSort
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                paginator
                rows={paginatorState.rows}
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState) as any}
                stripedRows
                filterDisplay="row"
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <AnyColumn
                  key={'name'}
                  field={'name'}
                  header={t('name')}
                  body={columnBodyTemplate}
                  fieldsType={'text'}
                  sortable
                  filter
                  showFilterMenu={false}
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={internalFilters['name']}
                      onChangeFilter={changeFilter}
                      name={'name'}
                      placeholder={t('filter')}
                    />
                  }
                />
                <AnyColumn
                  key={'installationId'}
                  field={'installationId'}
                  header={t('installationId')}
                  body={columnBodyTemplate}
                  fieldsType={'text'}
                  sortable
                  filter
                  showFilterMenu={false}
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={internalFilters['installationId']}
                      onChangeFilter={changeFilter}
                      name={'installationId'}
                      placeholder={t('filter')}
                    />
                  }
                />
                <Column
                  field="locationId"
                  header={t('location')}
                  sortable
                  body={locationBodyTemplate}
                  filter
                  showFilterMenu={false}
                  filterElement={
                    <DataTableFilterElements.Dropdown
                      initialValue={filters['locationId']}
                      options={locations?.map((location) => {
                        return { label: location.name, value: location.id };
                      })}
                      onChangeFilter={changeFilter}
                      name="locationId"
                      placeholder={t('filter')}
                    />
                  }
                  filterPlaceholder={t('searchByLocation')}
                />
                <Column body={actionBodyTemplate} style={{ width: '100px', textAlign: 'center' }}></Column>
              </DataTable>
              <StationDialog
                visible={stationDialogVisible}
                onStationAdded={() => fetchStations()}
                setStationDialogVisible={setStationDialogVisible}
                station={station}
                locations={locations}
              />
              <DeleteStationDialog
                deleteStationDialogVisible={deleteStationDialogVisible}
                hideDeleteStationDialog={hideDeleteStationDialog}
                station={station}
                deleteStation={deleteStation}
              />
              <DeleteStationsDialog
                deleteStationsDialogVisible={deleteStationsDialogVisible}
                hideDeleteStationsDialog={hideDeleteStationsDialog}
                deleteSelectedStations={deleteSelectedStations}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stations;
