import * as moment from 'moment';

export const dateParser = (firstDate: Date | string, secondDate: Date | string) => {
  let parsedFirstDate = '';
  let parsedSecondDate = '';

  if (firstDate) {
    parsedFirstDate = moment.parseZone(new Date(firstDate)).format('YYYY-MM-DD HH:mm:ss');
  }

  if (firstDate.toString() !== secondDate.toString() && secondDate) {
    parsedSecondDate = moment.parseZone(new Date(secondDate)).format('YYYY-MM-DD HH:mm:ss');
  }

  if (firstDate.toString() === secondDate.toString()) {
    const shiftedDate = new Date(secondDate);
    shiftedDate.setDate(shiftedDate.getDate() + 1);

    parsedSecondDate = moment.parseZone(shiftedDate).format('YYYY-MM-DD HH:mm:ss');
  }

  return { startDate: parsedFirstDate, endDate: parsedSecondDate };
};
