import { useCallback, useState } from 'react';
import { PaginationParams } from '../../types/general-types';
import { NodeType, Node } from '../../types/node';
import nodesService from '../../services/ScaleoApiServices/NodesService';
import weighingUnitsService from '../../services/ScaleoApiServices/WeighingUnitsService';
import edgeDevicesService from '../../services/ScaleoApiServices/EdgeDevicesService';
import nodeModelsService from '../../services/ScaleoApiServices/NodeModelsService';
import { parseNodeModels } from '../../components/Management/Nodes/helpers';
import { ManagementNode, PartialNode } from '../../components/Management/Nodes/types';
import { ConnectionType } from '../../enums/connection-type.enum';
import stationsService from '../../services/ScaleoApiServices/StationsService';

const initialNodeValues = {
  id: null,
  type: null,
  name: null,
  host: null,
  port: null,
  nodeModel: null,
  location: null,
  edgeDevice: null,
  station: null,
  weighingUnit: null,
  division: null,
  maxWeight: null,
  sourceScale: null,
  openPin: null,
  closePin: null,
  statusPin: null,
  addImageData: null,
  playSoundSpecifiedWeight: null,
  selectedCameras: null,
  connectionType: ConnectionType.TCP,
  baudrate: null,
  testScale: null,
} as PartialNode;

const initialValues: ManagementNode = {
  nodes: [],
  nodeModels: [],
  nodeModelsByNodeType: null,
  weighingUnits: [],
  divisionsScale: [],
  edgeDevices: [],
  stations: [],
  cameras: [],
};

const useManagementNodesData = (filters: any) => {
  const [nodeData, setNodeData] = useState(initialNodeValues);
  const [data, setData] = useState(initialValues);

  const changeNodeData = (node?: Node) => setNodeData(node ?? initialNodeValues);

  const fetchData = useCallback(
    async (paginationParameters: PaginationParams) => {
      Promise.all([
        nodesService.searchNodes({ filters, paginationParameters }),
        nodeModelsService.getNodeModels(),
        weighingUnitsService.getWeighingUnits(),
        nodesService.getDivisionsScale(),
        edgeDevicesService.searchEdgeDevices(),
        stationsService.getStations(),
      ]).then((response) => {
        const cameras = response[0]
          .filter((node: any) => node.type === NodeType.CAMERA)
          .map((cameraNode: any) => ({
            id: cameraNode.id,
            name: cameraNode.name,
            stationId: cameraNode.station?.id,
          }));

        setData({
          nodes: response[0].map((element: any) => ({
            ...element,
            type: element.type === null && element.testScale ? NodeType.WEIGHT_INDICATOR : element.type,
          })),
          nodeModels: response[1].data,
          nodeModelsByNodeType: parseNodeModels(response[1].data) as any,
          weighingUnits: response[2].data,
          divisionsScale: response[3].data.map((item: any) => ({ name: item })),
          edgeDevices: response[4],
          stations: response[5].data,
          cameras: cameras,
        });
      });
    },
    [filters],
  );

  return { data, fetchData, nodeData, changeNodeData, initialNodeValues };
};

export default useManagementNodesData;
