import { useSelector } from 'react-redux';
import { transactionTypeLabels, getTranslatedLabel } from './labels';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../types/reducer-state';
import { CustomTransactionType } from '../types/transaction-types';

const useTransactionType = () => {
  const { t: t1 } = useTranslation('utils');
  const customTransactionTypes: CustomTransactionType[] = useSelector(
    (state: ReducerState) => state.weighing.weighingConfiguration?.customTransactionTypes,
  );
  const enableCustomTransactionTypes = customTransactionTypes?.filter(
    (customTransactionType) => !customTransactionType.deleted,
  );

  const ALL_TRANSACTION_TYPES = [
    { id: 'DELIVERY', name: getTranslatedLabel(transactionTypeLabels, 'DELIVERY', t1) },
    { id: 'RECEIVE', name: getTranslatedLabel(transactionTypeLabels, 'RECEIVE', t1) },
    ...(enableCustomTransactionTypes ? enableCustomTransactionTypes : []),
  ];

  const _allTransactionTypes = {
    DELIVERY: getTranslatedLabel(transactionTypeLabels, 'DELIVERY', t1),
    RECEIVE: getTranslatedLabel(transactionTypeLabels, 'RECEIVE', t1),
    ...(customTransactionTypes &&
      Object.fromEntries(
        customTransactionTypes?.map((transactionsType: CustomTransactionType) => [
          transactionsType.id,
          transactionsType.name,
        ]),
      )),
  };
  const getTransactionTypeLabel = (transactionTypeId: string) =>
    _allTransactionTypes[transactionTypeId as keyof typeof _allTransactionTypes];
  return { ALL_TRANSACTION_TYPES, getTransactionTypeLabel };
};

export default useTransactionType;
