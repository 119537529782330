import React from 'react';

interface ConfirmationRowProps {
  title: string;
  value: string | null;
}

export const ConfirmationRow = ({ title, value }: ConfirmationRowProps) =>
  value ? (
    <>
      <div className="col-6">
        <strong>{title}</strong>
      </div>
      <div className="field col-6">{value}</div>
    </>
  ) : (
    <></>
  );
