import axios from 'axios';

class SystemConfigurationService {
  async getSelectedUnit() {
    return axios.get(`/system-configuration/unit`);
  }
  async saveSMTPConfig(data: any) {
    return axios.put(`/system-configuration/smtp`, data);
  }
  async getSMTPConfig() {
    return axios.get(`/system-configuration/smtp`);
  }
  async existsAnySmtpConfig() {
    return axios.get(`/system-configuration/smtp-exists`);
  }
}

const systemConfigurationService = new SystemConfigurationService();
export default systemConfigurationService;
