import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import filesService from '../services/ScaleoApiServices/FilesService';
import { indicationActions } from '../store/indication-slice';
import { ReducerState } from '../types/reducer-state';

export const SoundContainer = () => {
  const [audio, setAudio] = useState<HTMLAudioElement | undefined>(undefined);
  const dispatch = useDispatch();
  const shouldPlaySound = useSelector((state: ReducerState) => state.indication.displaySound);

  useEffect(() => {
    if (shouldPlaySound) {
      playSound();
    }
  }, [shouldPlaySound]);

  const playSound = async () => {
    if (shouldPlaySound) {
      audio?.play();
      setTimeout(() => {
        audio?.pause();
        dispatch(indicationActions.specifiedWeightSoundPlayed());
      }, 2000);
    }
  };

  const fetchSound = useCallback(async () => {
    const response = await filesService.getSoundAlarmUrl();
    setAudio(new Audio(response.data));
  }, []);

  useEffect(() => {
    void fetchSound();
  }, []);

  return <></>;
};
