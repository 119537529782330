import axios from 'axios';
import moment from 'moment';
import { formatStringToDate } from '../../utils/formatUtils';
import { Direction } from '../../components/AccessControl/ConfirmationsPanelDialog/types/Direction';

class VehiclesService {
  formatSearchFilters(filters: any) {
    const startOfDay = (day: number) => moment(day).startOf('day').toDate().toString();
    const endOfDay = (day: number) => moment(day).endOf('day').toDate().toString();

    const prepareDateFilter = (_filters: any) => {
      if (_filters?.tareTimestamp) {
        if (_filters.tareTimestamp[1]) {
          return {
            ..._filters,
            tareTimestamp: [startOfDay(_filters.tareTimestamp[0]), endOfDay(_filters.tareTimestamp[1])],
          };
        } else {
          return {
            ..._filters,
            tareTimestamp: [startOfDay(_filters.tareTimestamp[0]), endOfDay(_filters.tareTimestamp[0])],
          };
        }
      } else {
        return _filters;
      }
    };

    return {
      ...filters,
      tareTimestamp: prepareDateFilter(filters).tareTimestamp?.map((stringDate: string) =>
        formatStringToDate(stringDate),
      ),
    };
  }
  async searchVehicles(filters: any, paginatorParam: any) {
    const vehicles = await axios.post(`/vehicles/search`, {
      filters: this.formatSearchFilters(filters),
      paginatorParam,
    });

    return { data: vehicles.data.data.map(mapVehiclesFromResponse), totalPages: vehicles.data.totalPages };
  }
  async exportData() {
    return axios.get(`/vehicles/csv`);
  }
  async importData(postData: any) {
    return axios.post(`/vehicles/csv`, {
      data: postData,
    });
  }
  async getVehicles() {
    return axios.get(`/vehicles`);
  }
  async createVehicle(postData: any) {
    return axios.post(`/vehicles`, {
      data: mapToRequestBody(postData),
    });
  }
  async editVehicle(postData: any, id: string) {
    return axios.put(`/vehicles/${id}`, {
      data: mapToRequestBody(postData),
    });
  }
  async removeVehicle(id: string) {
    return axios.delete(`/vehicles/${id}`);
  }
  async removeManyVehicles(vehicles: any[]) {
    return axios.post(`/vehicles/remove-many`, {
      data: vehicles.map((v) => v.id),
    });
  }
  async getVehicleHistory(vehicle_id: string) {
    const tares = await axios.post(`/tares`, {
      data: vehicle_id,
    });
    return { data: tares.data.map(mapTaresFromResponse) };
  }
  async getVehicleByRegistrationNumber(registrationNumber: string) {
    return axios.get(`/vehicles/${registrationNumber}`);
  }
  async confirmVehicleAccess(
    registrationNumber: string,
    direction: Direction,
    locationId: string,
    targetEdgeDeviceId: string,
  ) {
    return axios.post(`/vehicles/confirm-access`, {
      direction,
      registrationNumber,
      locationId,
      targetEdgeDeviceId,
    });
  }
}
const mapToRequestBody = (vehicle: any) => {
  return {
    id: vehicle.id,
    registrationNumber: vehicle.registrationNumber,
    weighingMode: JSON.stringify(vehicle.weighingMode),
    defaultDriverId: vehicle.driver ? vehicle.driver.id : null,
    defaultContractorId: vehicle.contractor ? vehicle.contractor.id : null,
    defaultProductId: vehicle.product ? vehicle.product.id : null,
    defaultTrailerId: vehicle.trailer ? vehicle.trailer.id : null,
    visible: true,
    deleted: false,
    tareValue: vehicle.tareChanged ? vehicle.tareValue : null,
    requireArrivalNotification: vehicle.requireArrivalNotification,
    allowEntry: vehicle.allowEntry,
    allowExit: vehicle.allowExit,
    entryWeighingRequired: vehicle.entryWeighingRequired,
    entryConfirmationRequired: vehicle.entryConfirmationRequired,
    exitWeighingRequired: vehicle.exitWeighingRequired,
    exitConfirmationRequired: vehicle.exitConfirmationRequired,
    automaticWeighingMode: vehicle.automaticWeighingMode,
    maxWeightBrutto: vehicle.maxWeightBrutto,
  };
};

const mapTaresFromResponse = (tare: any) => {
  return {
    ...tare,
    type: tare.type === 'DECLARED' ? 'Deklaracja użytkownika' : tare.type === 'MEASURED' ? 'Pomiar' : '',
  };
};
const mapVehiclesFromResponse = (vehicle: any) => {
  return {
    ...vehicle,
    'lt.type':
      vehicle['lt.type'] === 'DECLARED' ? 'Deklaracja użytkownika' : vehicle['lt.type'] === 'MEASURED' ? 'Pomiar' : '',
  };
};

const vehicleService = new VehiclesService();
export default vehicleService;
