import React from 'react';
import { Panel } from 'primereact/panel';
import { Confirmation as ConfirmationType } from '../../../../store/types/confirmations-slice';
import { Confirmation } from './Confirmation';
import { useTranslation } from 'react-i18next';

interface ConfirmationsPanelProps {
  confirmations: ConfirmationType[];
}

export const ConfirmationsPanel = ({ confirmations }: ConfirmationsPanelProps) => {
  const { t } = useTranslation('accessControl');

  return (
    <div className="grid nested-grid">
      <div className="grid lg:col-12 md:col-12 sm:col-12">
        <div className="col-12">
          <Panel header={t('subtitle')}>
            {confirmations.map((c, i) => (
              <Confirmation
                key={i}
                registrationNumber={c.registrationNumber}
                direction={c.direction}
                locationId={c.locationId}
                edgeDeviceId={c.edgeDeviceId}
              />
            ))}
          </Panel>
        </div>
      </div>
    </div>
  );
};
