import { Dialog } from 'primereact/dialog';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Trailer } from '../../../../types/trailer';

interface DeleteTrailerDialogProps {
  deleteTrailerDialogVisible: boolean;
  hideDeleteTrailerDialog: () => void;
  trailer: Trailer;
  deleteTrailer: () => void;
}

export const DeleteTrailerDialog = ({
  deleteTrailerDialogVisible,
  hideDeleteTrailerDialog,
  trailer,
  deleteTrailer,
}: DeleteTrailerDialogProps) => {
  const { t } = useTranslation('datasetsTrailers');

  const deleteTrailerDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteTrailerDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteTrailer} />
    </>
  );

  return (
    <Dialog
      visible={deleteTrailerDialogVisible}
      header={
        <span className="flex align-items-center">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
          {t('confirm')}
        </span>
      }
      modal
      className="p-fluid min-width-450px"
      footer={deleteTrailerDialogFooter}
      onHide={hideDeleteTrailerDialog}
      breakpoints={{ '896px': '90vw' }}
    >
      <div className="confirmation-content">
        {trailer && (
          <span>
            <Trans t={t} i18nKey="deleteTrailerContent" values={{ trailer: trailer.registrationNumber }} />
          </span>
        )}
      </div>
    </Dialog>
  );
};
