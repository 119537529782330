const isModulesEnabled = (requiredModules, customerSubscriptionModules) => {
  return requiredModules
    .map((requiredModule) => {
      return customerSubscriptionModules?.includes(requiredModule);
    })
    .reduce((a, b) => {
      return a && b;
    });
};

const isOneOfModulesEnabled = (requiredModules, customerSubscriptionModules) => {
  return requiredModules
    .map((requiredModule) => {
      return customerSubscriptionModules?.includes(requiredModule);
    })
    .reduce((a, b) => {
      return a || b;
    });
};

export { isModulesEnabled, isOneOfModulesEnabled };
