import { Button } from 'primereact/button';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import apiKeysService, { ApiKeyCredentials } from '../../../../services/ScaleoApiServices/ApiKeyService';

interface ApiKeyRowProps {
  field: ApiKeyCredentials;
  index: number;
  fetchAPI: () => Promise<void>;
  setApiKey: (a: ApiKeyCredentials) => void;
  setApiKeyDialogOpen: (b: boolean) => void;
}

export const ApiKeyRow = ({ field, index, fetchAPI, setApiKey, setApiKeyDialogOpen }: ApiKeyRowProps) => {
  const { t } = useTranslation('managementApiKey');

  const [isHidden, setIsHidden] = useState(true);

  const handleClickDelete = useCallback(
    async (clientId: string) => {
      await apiKeysService.deleteApiKey(clientId);
      await fetchAPI();
    },
    [fetchAPI, apiKeysService.deleteApiKey],
  );

  const OnlineRow = ({ clientId, clientSecret }: { clientId: string; clientSecret: string }) => (
    <>
      <div className="col-3">
        <span>{clientId}</span>
      </div>
      <div className="col-6">
        <span>{isHidden ? '**********' : clientSecret}</span>
        <i
          onClick={() => setIsHidden((prev) => !prev)}
          className={`${isHidden ? 'pi pi-eye' : 'pi pi-eye-slash'} cursor-pointer ml-1vw`}
        />
      </div>
    </>
  );

  const OfflineRow = ({ id }: { id: string }) => (
    <div className="col-6">
      <span>{isHidden ? '**********' : id}</span>
      <i
        onClick={() => setIsHidden((prev) => !prev)}
        className={`${isHidden ? 'pi pi-eye' : 'pi pi-eye-slash'} cursor-pointer ml-1vw`}
      />
    </div>
  );

  return (
    <div key={`apiKey--${index}`}>
      <div
        className="p-fluid formgrid grid align-items-center pl-0 pr-0 pt-2px pb-2px"
        style={{ backgroundColor: index % 2 === 0 ? 'whitesmoke' : '' }}
      >
        <div className="col-2">
          <span>{field.name}</span>
        </div>
        {'ClientId' in field ? (
          <OnlineRow clientId={field.ClientId} clientSecret={field.ClientSecret} />
        ) : (
          <OfflineRow id={field.id} />
        )}
        <div className="col-1 text-center flex flex-nowrap">
          <Button
            icon="pi pi-pencil"
            type="button"
            text
            className="p-button-sm"
            onClick={() => {
              setApiKey(field);
              setApiKeyDialogOpen(true);
            }}
            tooltip={t('update')}
          />
          <Button
            icon="pi pi-trash"
            type="button"
            severity="danger"
            text
            className="p-button-sm ml-2"
            onClick={async () => handleClickDelete('ClientId' in field ? field.ClientId : field.id)}
            tooltip={t('delete')}
          />
        </div>
      </div>
    </div>
  );
};
