import axios from 'axios';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { uiActions } from '../store/ui-slice';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';

export const AxiosConfigContainer = () => {
  const { t } = useTranslation('axiosConfig');

  const dispatch = useDispatch();
  const loggedUserToken = useSelector((state) => state.user?.loggedUser?.idToken);
  const loggedUserContext = useSelector((state) => state.user.context);
  const history = useHistory();
  const toast = useRef();
  let inFlightRequests = [];

  const showLoader = (config) => {
    const request = config.method + ' ' + config.url;
    dispatch(uiActions.toggleIsLoading({ isLoading: true }));
    inFlightRequests.push(request);
  };

  const handleLoaderTimeout = (loaderTimeoutState) => {
    dispatch(uiActions.setHasLoaderTimeout({ hasLoaderTimeout: loaderTimeoutState }));
  };

  const hideLoader = (config) => {
    if (config) {
      inFlightRequests = inFlightRequests.filter((item) => item !== config.method + ' ' + config.url);
      if (inFlightRequests.length === 0) {
        setTimeout(() => {
          dispatch(uiActions.toggleIsLoading({ isLoading: false }));
        }, 300);
      }
    } else {
      dispatch(uiActions.toggleIsLoading({ isLoading: false }));
    }
  };

  const setUpInterceptors = useCallback(async (token, locationId, customerId) => {
    axios.interceptors.request.handlers = [];
    axios.interceptors.response.handlers = [];

    axios.interceptors.request.use(
      function (config) {
        if (process.env.REACT_APP_SCALEO_BACKEND_URL) {
          config.baseURL = process.env.REACT_APP_SCALEO_BACKEND_URL;
        } else if (process.env.REACT_APP_SCALEO_MODE === 'OFFLINE') {
          config.baseURL = `http://${window.location.hostname}/api`;
        } else {
          config.baseURL = `https://${window.location.hostname}/api`;
        }
        if (token) {
          config.headers.common.Authorization = `Bearer ${token}`;
        }
        if (locationId) {
          config.headers.common.location_id = locationId;
        }
        if (customerId) {
          config.headers.common.customer_id = customerId;
        }
        if (config.show_loader !== false) {
          showLoader(config);
        }

        if (config.loader_timeout === false) {
          handleLoaderTimeout(false);
        }

        config.withCredentials = true;

        return config;
      },
      function (error) {
        return Promise.reject(error);
      },
    );

    axios.interceptors.response.use(
      function (response) {
        hideLoader(response.config);
        handleLoaderTimeout(true);

        return response;
      },
      function (error) {
        console.error(error);
        error.response && error.response.status === 400 && history.push('/error');
        error.response && error.response.status === 401 && history.push('/login');
        error.response &&
          error.response.status === 403 &&
          history.push('/login') &&
          toast.current.show({
            severity: 'error',
            summary: t('permissionsError'),
            detail: t('permissionsErrorMessage'),
            life: 4000,
          });
        error.response && error.response.status.toString().charAt(0) === '5' && history.replace('/error'); // HTTP 5XX
        !error.response && history.replace('/error');

        hideLoader(error.response?.config);
        handleLoaderTimeout(true);

        return Promise.reject(error);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUpInterceptors(loggedUserToken, loggedUserContext.currentLocationId, loggedUserContext.currentCustomer?.id);
  }, [setUpInterceptors, loggedUserContext.currentLocationId, loggedUserContext.currentCustomer?.id, loggedUserToken]);

  return <Toast ref={toast} />;
};
