import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { AccessControlArrivalNotificationDeleteDialogProps } from './types';

const AccessControlArrivalNotificationDeleteDialog = ({
  arrivalNotification,
  selectedArrivalNotifications,
  deleteArrivalNotificationHandler,
  closeModalHandler,
}: AccessControlArrivalNotificationDeleteDialogProps) => {
  const { t } = useTranslation('accessControlArrivalNotification');

  const dialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={() => closeModalHandler()} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={() => deleteArrivalNotificationHandler()} />
    </>
  );

  return (
    <>
      <Dialog
        visible={true}
        className="p-fluid min-width-450px"
        header={
          <span className="flex align-items-center">
            <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
            {t('confirmDelete')}
          </span>
        }
        modal
        footer={dialogFooter}
        onHide={() => closeModalHandler()}
        breakpoints={{ '896px': '90vw' }}
      >
        <div className="confirmation-content">
          {arrivalNotification && (
            <span>
              <strong>{t('deleteSingle')}</strong>
            </span>
          )}
          {!arrivalNotification && selectedArrivalNotifications && (
            <span>
              <strong>{t('deleteMultiple')}</strong>
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default AccessControlArrivalNotificationDeleteDialog;
