import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../store/user-slice';
import { ReducerState } from '../../types/reducer-state';
import { useState } from 'react';

export const useWeighingsListFilters = () => {
  const dispatch = useDispatch();
  const filters = useSelector((state: ReducerState) => state.user.filtersState.weighings);

  const [clearFlag, setClearFlag] = useState(false);

  const changeFilter = (value: any, name: string, singleDateMode?: boolean, shouldClearDate?: boolean) => {
    const newFilters = { ...filters };

    if (name === 'date') {
      if (value) {
        newFilters[name] = [value[0].toString(), value[1] ? value[1].toString() : null];
      }

      if (shouldClearDate) {
        newFilters[name] = null;
      }
    } else {
      newFilters[name] = value;
    }

    if (singleDateMode !== null || singleDateMode !== undefined) {
      newFilters.singleDateMode = singleDateMode;
    }

    dispatch(userActions.setWeighingsFilters(newFilters));
  };

  const additionalFieldChangeFilter = (value: any, name: string) => {
    dispatch(userActions.setWeighingsAdditionalFieldsFilters({ name: name, value: value }));
  };

  const autocompleteChangeFilter = (event: any) => {
    const _item = event.target;
    if (_item.value) {
      if (_item.value.registrationNumber && _item.value.registrationNumber !== filters[_item.id]) {
        dispatch(userActions.setWeighingsFilters({ ...filters, [_item.id]: _item.value.registrationNumber }));
      } else if (!_item.value.registrationNumber && !_item.value.name && _item.value !== filters[_item.id]) {
        dispatch(userActions.setWeighingsFilters({ ...filters, [_item.id]: _item.value }));
      } else if (_item.value.name && _item.value.name !== filters[_item.id]) {
        dispatch(userActions.setWeighingsFilters({ ...filters, [_item.id]: _item.value.name }));
      }
    } else {
      if (_item.value === '') dispatch(userActions.setWeighingsFilters({ ...filters, [_item.id]: _item.value }));
    }
  };

  const multiSelectChangeFilter = (event: any) => {
    const _item = event.target;
    dispatch(userActions.setWeighingsFilters({ ...filters, [_item.name]: _item.value }));
  };

  const clearFilters = () => {
    dispatch(userActions.setWeighingsFilters({}));
    setClearFlag((prev) => !prev);
  };

  return {
    clearFlag,
    setClearFlag,
    changeFilter,
    additionalFieldChangeFilter,
    autocompleteChangeFilter,
    multiSelectChangeFilter,
    clearFilters,
  };
};
