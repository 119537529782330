import { Tooltip } from 'primereact/tooltip';

export const TooltipInfo = ({ _key, text, className }) => (
  <span className={className || ''}>
    <Tooltip target={`.pr-item-tooltip-${_key}`} mouseTrack mouseTrackLeft={10}>
      {text}
    </Tooltip>
    <i className={`pi pi-info-circle pr-item-tooltip-${_key}`}></i>
  </span>
);
