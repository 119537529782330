import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import driversService from '../../../../services/ScaleoApiServices/DriversService';
import '../../../DataTable.css';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { Driver } from '../../../../types/weighing';

interface NewOrEditDriverDialogProps {
  driverDialogVisible: boolean;
  setDriverDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
  items: any;
  onDriverAdded?: (selectedDriver: Driver) => void;
}

interface DriverDataValidationProps {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const INIT_FORM_STATE = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  personalId: '',
  idCard: '',
  street: '',
  zip: '',
  city: '',
  taxId: '',
};

export const NewOrEditDriverDialog = ({
  driverDialogVisible,
  setDriverDialogVisible,
  items,
  onDriverAdded,
}: NewOrEditDriverDialogProps) => {
  const { t } = useTranslation('datasetsDrivers');

  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const hideDialog = () => {
    setDriverDialogVisible(false);
    formik.resetForm({ values: INIT_FORM_STATE });
  };

  useEffect(() => {
    if (driverDialogVisible && items?.id) {
      setInitFormValues(items);
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
  }, [driverDialogVisible, items]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: DriverDataValidationProps) => {
      const errors: any = {};

      if (!data.firstName) {
        errors.firstName = t('noNameError');
      }
      if (!data.lastName) {
        errors.lastName = t('noLastNameError');
      }
      if (data.email) {
        const re = /\S+@\S+\.\S+/;
        if (!re.test(data.email)) errors.email = t('incorrectEmailError');
      }
      if (data.phone) {
        const re = /^\+[1-9]{1}[0-9]{3,14}$/;
        if (re.test(data.phone)) errors.phone = t('incorrectPhoneError');
      }
      return errors;
    },
    onSubmit: async (formData, helpers) => {
      if (!formData.id) {
        driversService
          .createDrivers(formData)
          .then((res) => {
            hideDialog();
            onDriverAdded && onDriverAdded({ id: res.data.id, name: `${res.data.firstName} ${res.data.lastName}` });
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('driverExistError'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        driversService
          .editDriver(formData, formData.id)
          .then(() => {
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('driverExistError'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
    enableReinitialize: true,
  });

  const driverDialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={hideDialog} />
      <Button type="submit" label={t('saveButton')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );

  const taxIdHandler = (event: any) => {
    const reg = /^[a-zA-Z0-9]/;
    if (reg.test(event.nativeEvent.data)) formik.setFieldValue('taxId', event.target.value);
  };

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={driverDialogVisible}
        header={t('driverDetails')}
        modal
        className="p-fluid w-40vw"
        footer={driverDialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      >
        <form>
          <div className="col-12">
            <div className="field">
              <label htmlFor="firstName">{t('firstName')}</label>
              <InputText id="firstName" value={formik.values.firstName} onChange={formik.handleChange} autoFocus />
              <FormErrorMessage fieldName="firstName" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="lastName">{t('lastName')}</label>
              <InputText id="lastName" value={formik.values.lastName} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="lastName" formikInstance={formik} />
            </div>
            <div className="grid">
              <div className="col-6">
                <label htmlFor="personalId">{t('personalId')}</label>
                <InputText id="personalId" value={formik.values.personalId} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="personalId" formikInstance={formik} />
              </div>
              <div className="col-6">
                <label htmlFor="idCard">{t('idCard')}</label>
                <InputText id="idCard" value={formik.values.idCard} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="idCard" formikInstance={formik} />
              </div>
            </div>
            <div className="field">
              <label htmlFor="street">{t('street')}</label>
              <InputText id="street" value={formik.values.street} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="street" formikInstance={formik} />
            </div>
            <div className="grid">
              <div className="col-4">
                <label htmlFor="zip">{t('zip')}</label>
                <InputMask mask="99-999" id="zip" value={formik.values.zip} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="zip" formikInstance={formik} />
              </div>
              <div className="col-8">
                <label htmlFor="city">{t('city')}</label>
                <InputText id="city" value={formik.values.city} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="city" formikInstance={formik} />
              </div>
            </div>
            <div className="field">
              <label htmlFor="taxId">{t('taxId')}</label>
              <div className="vat">
                <InputText id="taxId" value={formik.values.taxId} onChange={taxIdHandler} />
              </div>
            </div>
            <div className="field">
              <label htmlFor="email">{t('email')}</label>
              <InputText id="email" value={formik.values.email} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="email" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="phone">{t('phone')}</label>
              <InputText id="phone" value={formik.values.phone} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="phone" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};
