import { createSlice } from '@reduxjs/toolkit';
import { UiSlice } from './types/ui-slice';

const initialState: UiSlice = {
  isLoading: false,
  mqttConnected: true,
  isMenuOpen: true,
  isSidebarOpen: false,
  hasLoaderTimeout: true,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState: { ...initialState },
  reducers: {
    toggleIsLoading(state: UiSlice, value) {
      state.isLoading = value.payload.isLoading;
    },
    toggleMqttConnected(state: UiSlice, value) {
      state.mqttConnected = value.payload.mqttConnected;
    },
    setIsMenuOpen(state: UiSlice, value) {
      state.isMenuOpen = value.payload;
      if (state.isMenuOpen) {
        state.isSidebarOpen = false;
      }
    },
    setIsSidebarOpen(state: UiSlice, value) {
      state.isSidebarOpen = value.payload;
      if (state.isSidebarOpen) {
        state.isMenuOpen = false;
      }
    },
    setHasLoaderTimeout(state: UiSlice, value) {
      state.hasLoaderTimeout = value.payload.hasLoaderTimeout;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
