import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React from 'react';
import { useSelector } from 'react-redux';
import { formatDecimal } from '../../../../../../utils/formatUtils';
import { columnBodyTemplate } from '../../../../components/Templates';
import { useTranslation } from 'react-i18next';

const ContinueWeighingDialog = ({ visible, onHide }) => {
  const { t } = useTranslation('weighingHandlingWeighings');

  const { weighingConfiguration, allOpenWeighings, formState } = useSelector((state) => state.weighing);
  const unit = useSelector((state) => state.appConfig.unit);
  const filteredOpenWeighings = allOpenWeighings.filter((el) => el.vehicle?.id === formState.selectedVehicle?.id);

  const footer = !!weighingConfiguration.multipleOpenWeighingsForTheSameVehicle ? (
    <div className="grid justify-content-center">
      <Button type="button" label={t('newWeighing')} className="mt-3" onClick={() => onHide(null)} />
    </div>
  ) : (
    <></>
  );

  const actionBodyTemplate = (rowData) => {
    const handleClick = () => {
      onHide(rowData);
    };

    return (
      <div className="actions">
        <Button type="button" label={t('continue')} icon="pi pi-caret-right" onClick={handleClick} />
      </div>
    );
  };

  const columnBody = (rowData, column) => columnBodyTemplate(rowData, { ...column, emptyValue: '--' });

  const firstMeasurementIndicationColumnBody = (rowData) => {
    if (rowData.firstMeasurementIndication) {
      return `${formatDecimal(rowData.firstMeasurementIndication)} ${unit}`;
    }
    return '';
  };

  return (
    <Dialog
      visible={visible}
      breakpoints={{ '960px': '90vw', '640px': '100vw' }}
      className="p-fluid w-80vw"
      header={t('selectWeighing')}
      modal
      footer={footer}
      closeOnEscape={false}
      closable={false}
    >
      <div className="datatable-responsive">
        <div className="card datatable-card">
          <DataTable
            responsiveLayout="scroll"
            value={filteredOpenWeighings}
            sortField="createdAt"
            sortOrder={-1}
            className="p-datatable-responsive p-datatable-measurements weighing-list"
          >
            <Column
              field="number"
              header={t('weighingNumber')}
              body={columnBody}
              fieldsType={'weighingNumber'}
              sortable
              canContinue
            />
            <Column
              field="firstMeasurementIndication"
              header={t('mass')}
              body={firstMeasurementIndicationColumnBody}
              sortable
              canContinue
            />
            <Column
              field="contractor.name"
              header={t('contractor')}
              body={columnBody}
              fieldsType={'subfield'}
              sortable
              canContinue
            />
            <Column
              field="product.name"
              header={t('product')}
              body={columnBody}
              fieldsType={'product'}
              sortable
              canContinue
            />
            <Column
              key={'createdAt'}
              field={'createdAt'}
              header={t('createdAt')}
              body={columnBody}
              fieldsType={'datetime'}
              sortable
              canContinue
            />
            <Column className="text-left w-150px" body={actionBodyTemplate}></Column>
          </DataTable>
        </div>
      </div>
    </Dialog>
  );
};

export default ContinueWeighingDialog;
