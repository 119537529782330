import { Dialog } from 'primereact/dialog';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Container } from '../../../../types/container';

interface DeleteContainerDialogProps {
  deleteContainerDialogVisible: boolean;
  hideDeleteContainerDialog: () => void;
  container: Container;
  deleteContainer: () => void;
}

export const DeleteContainerDialog = ({
  deleteContainerDialogVisible,
  hideDeleteContainerDialog,
  container,
  deleteContainer,
}: DeleteContainerDialogProps) => {
  const { t } = useTranslation('datasetsContainers');

  const deleteContainerDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteContainerDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteContainer} />
    </>
  );

  return (
    <Dialog
      visible={deleteContainerDialogVisible}
      header={
        <span className="align-items-center flex">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
          {t('confirm')}
        </span>
      }
      modal
      className="p-fluid min-width-450px"
      footer={deleteContainerDialogFooter}
      onHide={hideDeleteContainerDialog}
      breakpoints={{ '896px': '90vw' }}
    >
      <div className="confirmation-content">
        {container && (
          <span>
            <Trans t={t} i18nKey="deleteVehicleContent" values={{ vehicle: container.name }} />
          </span>
        )}
      </div>
    </Dialog>
  );
};
