export interface ControlMode {
  name: string;
  icon: string;
  signal: string;
  signalValue: number;
  color: string;
  actions: any[];
}

export enum ControlType {
  NODE = 'node',
  DYNAMIC_SETTINGS = 'dynamic-settings',
}

export interface NodeControl {
  node: string;
  label: string;
  nodeId: string;
  type?: ControlType.NODE;
  modes: ControlMode[];
}

export interface DynamicSettingsControl {
  node: string;
  label: string;
  nodeId: string;
  type: ControlType.DYNAMIC_SETTINGS;
  edgeDeviceId: string;
}

export type Control = DynamicSettingsControl | NodeControl;
