import React, { useCallback, useEffect, useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { Tooltip } from 'primereact/tooltip';
import { trimString } from '../../utils/stringUtils';
import productsService from '../../services/ScaleoApiServices/ProductsService';

export const ProductAutoComplete = ({ id, value, onChange, conditionalFields, searchForCurrentLocation }) => {
  const [source, setSource] = useState([]);
  const [subValue, setSubValue] = useState({ value: null, timestamp: null }); //"subValue" is required for a valid value change process. This is a way to update the state of the form only when the value is actually changed.

  const fetchProducts = async (_searchText) => {
    const _params = {
      searchText: _searchText || '',
      searchForCurrentLocation: searchForCurrentLocation === undefined ? true : searchForCurrentLocation,
      searchForActive: true,
      searchConditionalFields: conditionalFields,
    };

    return (await productsService.getProducts(_params, { show_loader: false })).data;
  };

  const checkCorrectnessOfValueAfterChangeConditionalFields = useCallback(async () => {
    if (value?.id && conditionalFields) {
      const productsList = await fetchProducts(value.name);

      if (!productsList.some((product) => product?.id === value?.id)) {
        onChange('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(conditionalFields)]);

  useEffect(() => {
    void checkCorrectnessOfValueAfterChangeConditionalFields();
  }, [checkCorrectnessOfValueAfterChangeConditionalFields]);

  const completeMethod = (e) => {
    const searchText = e.query.trim();
    void fetchProducts(searchText).then((productsList) => {
      setSource(productsList);
    });
  };

  const itemTemplate = (item) => {
    const content = `${item.locationShortName ? `[${item.locationShortName}]` : ''}[${item['code']}] ${trimString(
      item['name'],
      50,
      true,
    )}`;
    const tooltipContent = item['name'];

    return (
      <div className="item" key={item['id']}>
        <Tooltip className="max-width-unset" target={`.pr-item-tooltip-${item['id']}`} mouseTrack mouseTrackLeft={10}>
          {tooltipContent}
        </Tooltip>
        <div className={`pr-item-tooltip-${item['id']}`}>{content}</div>
      </div>
    );
  };

  const getValue = () => (subValue?.value !== null ? subValue.value : value);

  const handleSelect = (e) => {
    setSubValue({ value: null, timestamp: null });
    onChange(e.value);
  };

  const handleChange = (e) => {
    if (typeof e.value === 'string') {
      setSubValue((prevState) => {
        if (!prevState.timestamp || prevState.timestamp < e?.originalEvent?.timeStamp) {
          return { value: e.value, timestamp: e.originalEvent.timeStamp };
        }
        return prevState;
      });
    }
  };

  const handleHide = () => {
    if (value) {
      setSubValue({ value: null, timestamp: null });
    }
  };

  const handleBlur = () => {
    if (subValue.value === '') {
      onChange('');
    }
    setSubValue({ value: null, timestamp: null });
    if (!!value && source.findIndex((el) => el.id === value.id) === -1) {
      setSource([...source, value]);
    }
  };

  return (
    <AutoComplete
      id={id}
      value={getValue()}
      suggestions={source}
      forceSelection={true}
      field="fulltext"
      placeholder=""
      dropdown
      completeMethod={completeMethod}
      itemTemplate={itemTemplate}
      onChange={handleChange}
      onSelect={handleSelect}
      onHide={handleHide}
      onBlur={handleBlur}
    />
  );
};
