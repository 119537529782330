import React from 'react';
import { useSelector } from 'react-redux';
import { getTranslatedLabel, weighingModeLabels } from '../../../../../../utils/labels';
import useTransactionType from '../../../../../../utils/useTransactionType';
import ProductsSummary from '../../../../components/ProductsSummary';
import {
  WeighingSummaryTextFieldTemplate,
  WeighingSummaryDecimalFieldTemplate,
} from '../../../../components/WeighingSummaryFieldTemplates';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../../../types/reducer-state';
import { Weighing } from '../../../../../../types/weighing';
import CustomDivider from '../../../../../_shared/CustomDivider';

interface DefaultWeighingSummaryDataProps {
  weighing: Weighing;
}

const DefaultWeighingSummaryData = ({ weighing }: DefaultWeighingSummaryDataProps) => {
  const { t } = useTranslation('weighingHandlingWeighings');
  const { t: t1 } = useTranslation('utils');

  const unit = useSelector((state: ReducerState) => state.appConfig.unit);
  const { getTransactionTypeLabel } = useTransactionType();

  const renderAdditionalField = (field: any) =>
    ['DICTIONARY', 'TEXT', 'AUTO_ID'].includes(field.type) ? (
      <WeighingSummaryTextFieldTemplate label={field.name} value={field.value} />
    ) : (
      <WeighingSummaryDecimalFieldTemplate label={field.name} value={field.value} unit={field.unit} />
    );

  return (
    <>
      <div className="lg:col-6 md:col-12">
        <div className="formgrid grid">
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleWeighingNumber')}
            value={weighing?.number as string}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleWeighingMode')}
            value={getTranslatedLabel(weighingModeLabels, weighing?.weighingMode, t1)}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleWeighingType')}
            value={getTransactionTypeLabel(weighing?.transactionType as string)}
          />
          {weighing?.netto !== 0 ? (
            <WeighingSummaryDecimalFieldTemplate
              label={t('openWeighingsListSummaryMultipleNetto')}
              value={weighing?.netto as any}
              unit={unit}
            />
          ) : (
            <WeighingSummaryDecimalFieldTemplate
              label={t('openWeighingsListSummaryRegularIndication')}
              value={weighing.measurements[0].indication}
              unit={unit}
            />
          )}
        </div>
      </div>
      <div className="lg:col-6 md:col-12">
        <div className="formgrid grid">
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleRegistrationNumber')}
            value={weighing?.vehicle?.registrationNumber as string}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleProduct')}
            value={weighing?.product?.name as string}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleDriver')}
            value={weighing?.driver?.name as string}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleContractor')}
            value={weighing?.contractor?.name}
          />
          {weighing?.additionalFields?.map(renderAdditionalField)}
        </div>
      </div>
      <div className="lg:col-12 md:col-12">
        {weighing.weighingMode !== 'WEIGHING_SERIES' && weighing.weighingMode !== 'DOUBLE_WEIGHING_SERIES' && (
          <>
            <CustomDivider>
              <span className="tag">{t('openWeighingsListSummaryMultipleProductsSummary')}</span>
            </CustomDivider>
            <ProductsSummary productsSummary={weighing?.productsSummary as any} />
          </>
        )}
      </div>
    </>
  );
};

export default DefaultWeighingSummaryData;
