import { useTranslation } from 'react-i18next';

export const WebhooksTableHeaders = () => {
  const { t } = useTranslation('managementWebhooks');

  return (
    <div className="p-fluid formgrid grid pb-10px">
      <div className="col-2">
        <label htmlFor="name">
          <b>{t('name')}</b>
        </label>
      </div>
      <div className="col-5">
        <label htmlFor="destinationUrl">
          <b>{t('destinationUrl')}</b>
        </label>
      </div>
      <div className="col-3">
        <label htmlFor="event">
          <b>{t('event')}</b>
        </label>
      </div>
      <div className="col-1 text-center">
        <label htmlFor="actions">
          <b>{t('actions')}</b>
        </label>
      </div>
    </div>
  );
};
