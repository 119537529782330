import { useFormik } from 'formik';
import { Button } from 'primereact/button';
// import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import validator from 'validator';
import FormErrorMessage from '../components/_shared/FormErrorMessage';
import LanguageSelectorUnauthorized from '../components/_shared/LanguageSelectorUnauthorized';
import usersService from '../services/ScaleoApiServices/UsersService';
import { uiActions } from '../store/ui-slice';
import { useTranslation } from 'react-i18next';

export const ForgotPassword = () => {
  const { t } = useTranslation('forgotPasswordPage');

  const history = useHistory();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate: (data) => {
      const errors = {};

      if (!data.email) {
        errors.email = t('noEmailError');
      } else {
        if (!validator.isEmail(data.email)) {
          errors.email = t('incorrectEmailError');
        }
      }

      return errors;
    },
    onSubmit: (formData, helpers) => {
      dispatch(uiActions.toggleIsLoading({ isLoading: true }));

      usersService
        .forgotPassword({
          email: formData.email,
        })
        .then(() => {
          history.push(`/confirm-forgot-password?email=${formData.email}`);
          scheduleHideLoader();
        })
        .catch((error) => {
          scheduleHideLoader();

          const errorCode = error.response.data.error;
          // TODO: i18n
          errorCode === 'LIMIT_EXCEEDED' && helpers.setFieldError('email', t('limitExceededError'));
          errorCode === 'USER_NOT_FOUND' && helpers.setFieldError('email', t('userNotFoundError'));
        });
    },
  });

  const scheduleHideLoader = () => {
    setTimeout(() => {
      dispatch(uiActions.toggleIsLoading({ isLoading: false }));
    }, 400);
  };

  return (
    <div className="login-body">
      <div className="card login-panel p-fluid">
        <LanguageSelectorUnauthorized visible={false} />
        <div className="login-panel-content">
          <form>
            <div className="grid">
              <div className="col-12 logo-container">
                <img src="assets/layout/images/logo_scaleo.png" alt={t('logoAlt')} className="w-full" />
              </div>
              <div className="col-12 sm:col-6 md:col-6">
                <span className="guest-sign-in">{t('header')}</span>
              </div>
              <div className="col-12">
                <label htmlFor="email">{t('email')}</label>
                <InputText id="email" value={formik.values.email} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="email" formikInstance={formik} />
              </div>
            </div>
          </form>
          <div className="grid">
            <div className="col-12 sm:col-6 md:col-6">
              <Button type="submit" label={t('submitButton')} icon="pi pi-check" onClick={formik.submitForm} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
