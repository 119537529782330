import React from 'react';
import { useSelector } from 'react-redux';
import { DataScroller } from 'primereact/datascroller';
import '.././DataTable.css';
import { formatDateTime, formatDecimal } from '../../utils/formatUtils';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../types/reducer-state';

interface TaresProps {
  tares: any[];
}

export const Tares = ({ tares }: TaresProps) => {
  const { t } = useTranslation('datasetsTares');

  const unit = useSelector((state: ReducerState) => state.appConfig.unit);

  const itemTemplate = (rowData: any) => {
    return (
      <div className="p-1">
        <div className="grid m-0">
          <div className="col-6 py-0">
            <span>{t('value')} </span>
            {rowData.value || rowData.value === 0 ? formatDecimal(rowData.value) : null} {unit}
          </div>
          <div className="col-6 py-0 text-right"> {rowData.timestamp ? formatDateTime(rowData.timestamp) : null}</div>
        </div>
        <div className="grid m-0 ">
          <p className="col-6 py-1 m-0"> {rowData.type ? rowData.type : null} </p>
          <p className="col-6 py-1 text-right"> {rowData.user ? rowData.user : null}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="datascroller-demo">
      <div className="card">
        <DataScroller
          value={tares}
          itemTemplate={itemTemplate}
          rows={200}
          inline
          scrollHeight="250px"
          header={t('history')}
        />
      </div>
    </div>
  );
};
