import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import locationsService from '../../../services/ScaleoApiServices/LocationsService';
import productsService from '../../../services/ScaleoApiServices/ProductsService';
import '../../DataTable.css';
import { CustomTableField, CustomButton } from '../../_shared/CustomComponents';
import DataTableFilterElements from '../../_shared/DataTableFilterElements';
import ImportInformationDialog from '../../_shared/ImportInformationDialog';
import { NewOrEditProductDialog } from './components/NewOrEditProductDialog';
import { userActions } from '../../../store/user-slice';
import { useTranslation, Trans } from 'react-i18next';
import useModulesConfiguration from '../../../utils/useModulesConfiguration';
import { getFileFromText } from '../../../utils/getFile';
import getPaginatorTemplate from '../../../utils/getPaginatorTemplate';
import contractorsService from '../../../services/ScaleoApiServices/ContractorsService';
import useTransactionType from '../../../utils/useTransactionType';
import { getTranslatedLabel, productsActiveTypeLabels } from '../../../utils/labels';
import { ReducerState } from '../../../types/reducer-state';
import { ProductLocation } from '../../../types/product-location';

interface ProductsFilterTransactionTypeProps {
  label: string;
  value: string;
}

const EMPTY_PRODUCT = {
  id: null,
  code: '',
  name: '',
  locations: '',
  active: '',
};

const EMPTY_PAGINATION_PARAMETERS = {
  order: {
    isAscending: true,
    orderColumn: 'id',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const EMPTY_PAGINATOR_STATE = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

export const Products = () => {
  const { t } = useTranslation('datasetsProducts');
  const { t: t1 } = useTranslation('utils');

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [product, setProduct] = useState(EMPTY_PRODUCT);
  const [productDialogVisible, setProductDialogVisible] = useState(false);
  const [deleteProductDialogVisible, setDeleteProductDialogVisible] = useState(false);
  const [selectedRowToEdit, setSelectedRowToEdit] = useState(null);
  const [location, setLocation] = useState<ProductLocation | null>(null);

  const [isImportedBdo, setIsImportedBdo] = useState([]);
  const [isEnableImportBdo, setIsEnableImportBdo] = useState(null);

  const dispatch = useDispatch();

  const [deleteProductsDialogVisible, setDeleteProductsDialogVisible] = useState(false);
  const dt = useRef(null);
  const fileUploader = useRef<FileUpload>(null);
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
  const [importInformationDialogData, setImportInformationDialogData] = useState(null);
  const { ALL_TRANSACTION_TYPES } = useTransactionType();

  const [filterLocations, setFilterLocations] = useState(null);
  const [filterContractors, setFilterContractors] = useState(null);
  const [filterTransactionType, setFilterTransactionType] = useState<ProductsFilterTransactionTypeProps[]>([]);
  const filterActivity = [true, false].map((el) => ({
    label: getTranslatedLabel(productsActiveTypeLabels, el ? 'ACTIVE' : 'INACTIVE', t1),
    value: el,
  }));

  const filters = useSelector((state: ReducerState) => state.user.filtersState.products);
  const [paginationParameters, setPaginationParameters] = useState(EMPTY_PAGINATION_PARAMETERS);
  const [paginatorState, setPaginatorState] = useState(EMPTY_PAGINATOR_STATE);

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const modulesConfiguration = useModulesConfiguration();

  const fetchProducts = useCallback(
    async (_filters, _paginatorParam) => {
      Promise.all([
        productsService.searchProducts(_filters, _paginatorParam),
        productsService.checkIfImportedBdo(),
      ]).then((response) => {
        response[0].data.map((item: any) => (item.locations.name ??= t('nullLocations')));
        setProducts(response[0].data);
        response[0].total_pages
          ? setPaginatorState({
              ...paginatorState,
              totalPages: response[0].total_pages,
              // actionBodyTemplate,
            })
          : setPaginatorState({
              ...paginatorState,
              totalPages: 1,
              currentPage: 1,
            });
        setIsImportedBdo(response[1].data.isImported);
        setIsEnableImportBdo(response[1].data.isEnableImport);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginatorState],
  );

  useEffect(() => {
    fetchProducts(filters, paginationParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, paginationParameters, loggedUserContext?.currentCustomer?.id, productDialogVisible]);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  useEffect(() => {
    locationsService.getAllLocations().then((response) => {
      const parsedList = response.data?.map((el: any) => {
        return {
          label: el.name,
          value: el.id,
        };
      });
      setFilterLocations(parsedList);
    });
    contractorsService.getContractors().then((response) => {
      const parsedList = response.data?.map((el: any) => {
        return {
          label: el.name,
          value: el.id,
        };
      });
      setFilterContractors(parsedList);
    });

    setFilterTransactionType(
      ALL_TRANSACTION_TYPES?.map((el) => {
        return {
          label: el.name,
          value: el.id,
        };
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loggedUserContext.locations) {
      setLocation(
        loggedUserContext.locations[
          loggedUserContext.locations.map((_location: any) => _location.id).indexOf(loggedUserContext.currentLocationId)
        ],
      );
    }
  }, [loggedUserContext.locations, loggedUserContext.currentLocationId]);

  const openNew = () => {
    setProduct(EMPTY_PRODUCT);
    setSelectedRowToEdit(null);
    setProductDialogVisible(true);
  };
  const editProduct = (_product: any) => {
    setProduct(_product);
    setSelectedRowToEdit(_product);
    setProductDialogVisible(true);
  };
  const confirmDeleteProduct = (_product: any) => {
    setProduct(_product);
    setDeleteProductDialogVisible(true);
  };
  const hideDeleteProductDialog = () => {
    setDeleteProductDialogVisible(false);
  };
  const confirmDeleteSelected = () => {
    setDeleteProductsDialogVisible(true);
  };
  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialogVisible(false);
  };

  const deleteProduct = () => {
    productsService.removeManyProducts([product]).then(() => {
      fetchProducts(filters, paginationParameters);
    });

    setDeleteProductDialogVisible(false);
    setProduct(EMPTY_PRODUCT);
  };
  const deleteSelectedProducts = () => {
    productsService.removeManyProducts(selectedProducts).then(() => {
      fetchProducts(filters, paginationParameters);
    });
    setDeleteProductsDialogVisible(false);
    setSelectedProducts([]);
  };

  const changeActivityForAllProducts = async (activity: any) => {
    await productsService.editProductsActivity({ activity: activity });
    fetchProducts(filters, paginationParameters);
  };

  const changeActivityForSelectedProducts = async (activity: any) => {
    await productsService.editProductsActivity({
      activity: activity,
      ids: selectedProducts.map((_product: any) => _product.id),
    });
    fetchProducts(filters, paginationParameters);
  };

  const changeActivityForCurrentLocationProducts = async (activity: any) => {
    await productsService.editProductsActivity({ activity: activity, locationId: loggedUserContext.currentLocationId });
    fetchProducts(filters, paginationParameters);
  };

  const importBdo = async (locationId: string) => {
    await productsService.importBdo(locationId);
    fetchProducts(filters, paginationParameters);
  };

  const changeFilter = (value: any, name: any) => {
    const newFilters = { ...filters };
    newFilters[name] = value;
    dispatch(userActions.setProductsFilters(newFilters));
  };

  const clearFilters = () => {
    dispatch(userActions.setProductsFilters({}));
  };

  useEffect(() => {
    setPaginationParameters((prevPaginationParameters) => ({
      ...prevPaginationParameters,
      page: {
        ...prevPaginationParameters.page,
        index: 1,
      },
    }));
  }, [filters]);

  useEffect(() => {
    if (paginatorState.currentPage > paginatorState.totalPages)
      setPaginatorState((prevPaginatorState) => ({
        ...prevPaginatorState,
        currentPage: 1,
      }));
  }, [paginatorState]);

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const deleteProductDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteProductDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteProduct} />
    </>
  );
  const deleteProductsDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteProductsDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteSelectedProducts} />
    </>
  );

  const header = (
    <div className="table-header text-2xl p-2">
      {t('header')} &#160;&#160;
      <div className="flex">
        <Button label={t('clearFilters')} icon="pi pi-filter-slash" outlined className="mr-2" onClick={clearFilters} />
        <span>
          <DataTableFilterElements.Text
            initialValue={filters['global']}
            onChangeFilter={changeFilter}
            name={'global'}
            placeholder={t('searchPlaceholder')}
            isSearchIcon
          />
        </span>
      </div>
    </div>
  );

  const leftToolbarTemplate = () => {
    const activeItems = [
      {
        label: t('all'),
        command: () => changeActivityForAllProducts(true),
      },
      {
        label: t('selected'),
        command: () => changeActivityForSelectedProducts(true),
        disabled: !selectedProducts || !selectedProducts.length,
      },
      {
        label: t('allFor', { name: location?.name }),
        command: () => changeActivityForCurrentLocationProducts(true),
      },
    ];
    const inactiveItems = [
      {
        label: t('all'),
        command: () => changeActivityForAllProducts(false),
      },
      {
        label: t('selected'),
        command: () => changeActivityForSelectedProducts(false),
        disabled: !selectedProducts || !selectedProducts.length,
      },
      {
        label: t('allFor', { name: location?.name }),
        command: () => changeActivityForCurrentLocationProducts(false),
      },
    ];
    return (
      <>
        <Button label={t('addButton')} icon="pi pi-plus" severity="success" className="mr-2 my-1" onClick={openNew} />
        <CustomButton
          label={t('deleteButton')}
          icon="pi pi-trash"
          name="button-trash"
          severity="danger"
          className="mr-2 my-1"
          onClick={confirmDeleteSelected}
          disabled={
            !selectedProducts ||
            !selectedProducts.length ||
            selectedProducts.map((product: any) => product?.isBdoWasteCode || product?.isUsed).includes(true)
          }
          tooltip={!selectedProducts.length ? t('selectProduct') : t('cannotDelete')}
        />
        <SplitButton label={t('activate')} className="mr-2 my-1" model={activeItems} />
        <SplitButton label={t('deactivate')} className="mr-2 my-1" model={inactiveItems} />
      </>
    );
  };

  const exportData = async () => {
    const csvResponse = await productsService.exportData();
    const csv = csvResponse.data;
    getFileFromText(csv, 'text/csv', `products-${Date.now().toString()}.csv`);
  };

  const importData = (event: any) => {
    let csvData = '';
    const [f] = event.files;
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      csvData = Buffer.from(e.target.result).toString('base64');
      const insertResponse = await productsService.importData(csvData);

      fileUploader.current?.clear();
      await fetchProducts(filters, paginationParameters);
      setImportInformationDialogData(insertResponse.data);
      setIsOpenImportDialog(true);
    };
    reader.readAsText(f);
  };

  const rightToolbarTemplate = () => {
    const importBdoModel = isImportedBdo.map((location: any) => {
      return {
        label: t('importToLocation', { location: location.name }),
        command: () => importBdo(location.id),
        disabled: location.isImported,
      };
    });
    return (
      <>
        {modulesConfiguration.bdo === 'enabled' && isEnableImportBdo ? (
          <SplitButton label={t('importFromBdo')} className="mr-2 my-1" model={importBdoModel} />
        ) : (
          ''
        )}
        <FileUpload
          ref={fileUploader}
          mode="basic"
          accept=".csv"
          maxFileSize={1000000}
          name={t('import')}
          chooseLabel={t('import')}
          className="mr-2 my-1"
          customUpload
          auto
          uploadHandler={importData}
        />
        <Button label={t('export')} icon="pi pi-upload" severity="help" className="my-1" onClick={exportData} />
      </>
    );
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        {rowData.isBdoWasteCode === false ? (
          <Button icon="pi pi-pencil" raised severity="success" className="mr-2" onClick={() => editProduct(rowData)} />
        ) : (
          <Button
            icon="pi pi-pencil"
            raised
            severity="secondary"
            className="mr-2"
            tooltip={t('tooltip')}
            tooltipOptions={{ position: 'bottom' }}
          />
        )}
        {rowData.isUsed === true ? (
          <Button
            icon="pi pi-trash"
            raised
            severity="secondary"
            tooltip={t('isUsed')}
            tooltipOptions={{ position: 'bottom' }}
          />
        ) : rowData.isBdoWasteCode === true ? (
          <Button
            icon="pi pi-trash"
            raised
            severity="secondary"
            tooltip={t('isWasteCode')}
            tooltipOptions={{ position: 'bottom' }}
          />
        ) : (
          <Button icon="pi pi-trash" raised severity="warning" onClick={() => confirmDeleteProduct(rowData)} />
        )}
      </div>
    );
  };

  const columnBodyTemplate = (rowData: any, column: any) => {
    return <CustomTableField row={rowData} col={column} />;
  };

  const columns = [
    { field: 'code', header: t('code'), type: 'text' },
    { field: 'name', header: t('name'), type: 'text' },
    {
      field: 'locations.name',
      filterField: 'locations.id',
      header: t('location'),
      type: 'subfield',
      dropdown: true,
      source: filterLocations,
    },
    {
      field: 'contractors.name',
      filterField: 'contractors.id',
      header: t('contractor'),
      type: 'subfield',
      dropdown: true,
      source: filterContractors,
    },
    {
      field: 'transactionType',
      header: t('transactionType'),
      type: 'transactionType',
      dropdown: true,
      source: filterTransactionType,
    },
    { field: 'active', header: t('status'), type: 'productActive', dropdown: true, source: filterActivity },
  ];

  const dynamicColumns = columns.map((col: any) => (
    <Column
      key={col.field}
      field={col.field}
      header={col.header}
      body={columnBodyTemplate}
      fieldsType={col.type}
      sortable
      filter
      showFilterMenu={false}
      filterElement={
        col.dropdown ? (
          <DataTableFilterElements.Dropdown
            initialValue={filters[col.filterField] || filters[col.field]}
            options={col.source}
            onChangeFilter={changeFilter}
            name={col.filterField || col.field}
            placeholder={t('filters')}
          />
        ) : (
          <DataTableFilterElements.Text
            initialValue={filters[col.filterField] || filters[col.field]}
            onChangeFilter={changeFilter}
            name={col.filterField || col.field}
            placeholder={t('filters')}
          />
        )
      }
    ></Column>
  ));

  return (
    <div className="grid">
      <ImportInformationDialog
        data={importInformationDialogData}
        visible={isOpenImportDialog}
        closeDialog={() => setIsOpenImportDialog(false)}
      />
      <div className="col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                responsiveLayout="scroll"
                ref={dt}
                value={products}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                rowHover
                selectionMode="checkbox"
                selection={selectedProducts}
                onSelectionChange={(e: any) => setSelectedProducts(e.value)}
                emptyMessage={t('noData')}
                removableSort
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                paginator
                rows={paginatorState.rows}
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState)}
                stripedRows
                filterDisplay="row"
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                {dynamicColumns}
                <Column body={actionBodyTemplate} className="actionBody"></Column>
              </DataTable>
              <NewOrEditProductDialog
                productDialogVisible={productDialogVisible}
                setProductDialogVisible={setProductDialogVisible}
                items={selectedRowToEdit}
              />
              <Dialog
                visible={deleteProductDialogVisible}
                header={
                  <span className="flex align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
                    {t('confirm')}
                  </span>
                }
                modal
                className="p-fluid min-w-min"
                footer={deleteProductDialogFooter}
                onHide={hideDeleteProductDialog}
                breakpoints={{ '896px': '90vw' }}
              >
                <div className="confirmation-content">
                  {product && (
                    <span>
                      <Trans t={t} i18nKey="singleContent" values={{ product: product.name }} />
                    </span>
                  )}
                </div>
              </Dialog>
              <Dialog
                visible={deleteProductsDialogVisible}
                header={
                  <span className="flex align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
                    {t('confirm')}
                  </span>
                }
                modal
                className="p-fluid min-w-min"
                footer={deleteProductsDialogFooter}
                onHide={hideDeleteProductsDialog}
                breakpoints={{ '896px': '90vw' }}
              >
                <div className="confirmation-content">{product && <span>{t('multipleContent')}</span>}</div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
