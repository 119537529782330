import weighingConfigurationService from '../services/ScaleoApiServices/WeighingConfigurationService';

const getDefaultValue = (fieldConfig, currentProductId) => {
  if (fieldConfig && fieldConfig.type !== 'FORMULA') {
    const specificDefaultValue = fieldConfig.specificDefaultValues?.find(
      (el) => el.product.id === currentProductId,
    )?.value;
    const baseDefaultValue = fieldConfig.baseDefaultValue;
    if (specificDefaultValue || specificDefaultValue === '') return specificDefaultValue;
    if (baseDefaultValue || baseDefaultValue === '') return baseDefaultValue;
  }
  return null;
};

export const mergeAdditionalFields = (
  actualAdditionalFields,
  configAdditionalFields,
  productIds,
  disableDefaultValues,
) => {
  const actualAF = actualAdditionalFields || {};
  const configAF = configAdditionalFields || [];

  const filteredConfigFields = configAF.filter(
    (configField) => !Object.keys(actualAF).includes(configField.id) && configField.isActive,
  );
  const filteredWeighingFields = configAF.filter((configField) => {
    const actualAFKeys = Object.keys(actualAF);
    if (actualAFKeys.includes(configField.id)) {
      configField.value = actualAF[configField.id];
      return (
        actualAFKeys.includes(configField.id) &&
        (configField.productIds.length === 0 ||
          configField.productIds.findIndex((product_id) => productIds.includes(product_id)) > -1)
      );
    }
    return false;
  });

  const sortedData = [...filteredConfigFields, ...filteredWeighingFields].sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return sortedData.map((el) => {
    if (el.value || el.value === '' || !!disableDefaultValues) {
      return el;
    } else if (typeof el.value === 'undefined' && Object.keys(el).includes('value') && el.type === 'DICTIONARY') {
      return { ...el, value: '' };
    } else {
      const defaultValue = getDefaultValue(
        configAF.find((field) => field.name === el.name),
        productIds[0],
      );
      return { ...el, value: defaultValue };
    }
  });
};

export const synchronizeAdditionalFieldsWithConfiguration = async (
  additionalFields,
  destination,
  productId,
  weighingMode,
  isOpenWeighing,
) => {
  let response = [];

  response = await weighingConfigurationService.searchAdditionalFields(
    {
      productIds: [productId],
      destination,
      weighingMode,
      isActive: true,
    },
    { show_loader: false },
  );

  return mergeAdditionalFields(additionalFields, response.data, [productId], isOpenWeighing);
};
