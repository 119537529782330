import { Panel } from 'primereact/panel';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApiKeyDialog from './components/ApiKeyDialog';
import { useApiKeysData } from '../../../hooks/ApiKeys/useApiKeysData';
import { CustomerIdSection } from './components/CustomerIdSection';
import { ApiKeysTable } from './components/ApiKeysTable';

const ApiKeys = () => {
  const { t } = useTranslation('managementApiKey');

  const [apiKeyDialogOpen, setApiKeyDialogOpen] = useState(false);
  const [apiKey, setApiKey] = useState<any | null>(null);

  const handleCloseApiKeyDialog = useCallback(() => {
    setApiKey(null);
    setApiKeyDialogOpen(false);
  }, [setApiKey, setApiKeyDialogOpen]);

  const { apiKeys, fetchAPI, contractors } = useApiKeysData();

  return (
    <div className="card pb-7">
      <Panel header={t('header')} toggleable>
        <CustomerIdSection />
        <ApiKeysTable
          fetchAPI={fetchAPI}
          apiKeys={apiKeys}
          setApiKey={setApiKey}
          setApiKeyDialogOpen={setApiKeyDialogOpen}
        />
      </Panel>
      {apiKeyDialogOpen && (
        <ApiKeyDialog
          visible={apiKeyDialogOpen}
          closeDialog={handleCloseApiKeyDialog}
          item={apiKey}
          fetchData={fetchAPI}
          contractors={contractors}
        />
      )}
    </div>
  );
};

export default ApiKeys;
