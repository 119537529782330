import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import stationsService from '../../services/ScaleoApiServices/StationsService';
import { Station } from '../../types/station';
import { ReducerState } from '../../types/reducer-state';
import { userActions } from '../../store/user-slice';
import edgeDevicesService from '../../services/ScaleoApiServices/EdgeDevicesService';

export const useStationsData = () => {
  const dispatch = useDispatch();

  const [stations, setStations] = useState<Station[] | null>(null);

  const filters = useSelector((state: ReducerState) => state.user.filtersState.stations);
  const [internalFilters, setInternalFilters] = useState(filters);
  const [paginationParameters, setPaginationParameters] = useState(emptyPaginationParameters);
  const [paginatorState, setPaginatorState] = useState(emptyPaginatorState);

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const locations = useSelector((state: ReducerState) => state.user.context.locations);

  const fetchStations = useCallback(
    async (_filters, _paginationParameters) => {
      void Promise.all([
        stationsService.searchStations(_filters, _paginationParameters),
        edgeDevicesService.searchEdgeDevices(),
      ]).then((response) => {
        setStations(response[0].data);

        response[0].total_pages
          ? setPaginatorState({
              ...paginatorState,
              totalPages: response[0].total_pages,
            })
          : setPaginatorState({
              ...paginatorState,
              totalPages: 1,
              currentPage: 1,
            });
      });
    },
    [stationsService.searchStations, setStations, setPaginatorState, paginatorState],
  );

  useEffect(() => {
    void fetchStations(internalFilters, paginationParameters);
  }, [internalFilters, paginationParameters, loggedUserContext.currentCustomer?.id]);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const changeFilter = (value: any, name: string) => {
    const newFilters = { ...internalFilters };
    newFilters[name] = value;

    dispatch(userActions.setStationsFilters(newFilters));
    setInternalFilters(newFilters);
  };

  const clearFilters = () => {
    dispatch(userActions.setStationsFilters({}));
    setInternalFilters({});
  };

  const fetchStationsData = useCallback(
    async () => fetchStations(filters, paginationParameters),
    [filters, paginationParameters],
  );

  return {
    fetchStations: fetchStationsData,
    stations,
    handleSort,
    paginationParameters,
    paginatorState,
    setPaginatorState,
    internalFilters,
    changeFilter,
    clearFilters,
    locations,
    filters,
  };
};

const emptyPaginationParameters = {
  order: {
    isAscending: true,
    orderColumn: 'id',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const emptyPaginatorState = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};
