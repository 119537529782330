import React from 'react';
import { Dialog } from 'primereact/dialog';
import { useFormik } from 'formik';
import statusUtils from '../statusUtils';
import FormErrorMessage from '../../../../_shared/FormErrorMessage';
import formChangeFieldMethod from '../../../../../utils/formChangeFieldMethod';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { DEFAULT_DIALOG_BREAKPOINTS, DEFAULT_DIALOG_STYLE, CancelButton, SubmitButton } from '../../../bdoUtils';
import * as moment from 'moment';
import FormErrorMessageScroller from '../../../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';

const ReceiveConfirmationReceiver = ({ visible, closeDialog, item, wasteMass }) => {
  const { t } = useTranslation('kpoChangeStatusDialogs');

  const INIT_FORM_STATE = { correctedWasteMass: wasteMass || '', remarks: '' };

  const validateMethod = (data) => {
    const errors = {};

    if (!data.correctedWasteMass) {
      errors.correctedWasteMass = t('receiverCorrectedWasteMassNotProvided');
    }

    const transportTime = new Date(item.realTransportTime);

    if (moment(transportTime).isAfter(new Date(Date.now()))) {
      errors.realTransportTime = t('receiverRealTransportTimeError');
    }

    return errors;
  };

  const handleCloseDialog = (changes, statusFilter) => {
    formik.resetForm({
      values: INIT_FORM_STATE,
    });
    closeDialog(changes === true, statusFilter);
  };

  const handleSubmit = async (data) => {
    await statusUtils.changeStatusMethods.receiver.RECEIVE_CONFIRMATION({ kpoId: item.kpoId, ...data });

    handleCloseDialog(true, 'RECEIVE_CONFIRMATION');
  };

  const formik = useFormik({
    initialValues: INIT_FORM_STATE,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const dialogFooter = (
    <div className="grid justify-content-center">
      <CancelButton onClick={handleCloseDialog} />
      <SubmitButton onClick={formik.submitForm} />
    </div>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={visible}
        header={t('receiveConfirmationReceiverHeader')}
        modal
        className="p-fluid"
        maskClassName="higher-rank"
        breakpoints={DEFAULT_DIALOG_BREAKPOINTS}
        style={DEFAULT_DIALOG_STYLE}
        footer={dialogFooter}
        onHide={handleCloseDialog}
      >
        <form>
          <div className="field col-12">
            <FormErrorMessage fieldName="realTransportTime" formikInstance={formik} withTouchedTrue={true} />
            <br />
            <label htmlFor="correctedWasteMass">
              {t('receiverCorrectedWasteMass')}
              <span className="text-red-500">&nbsp;*</span>
            </label>
            <InputText
              type="number"
              id="correctedWasteMass"
              value={formik.values.correctedWasteMass}
              onChange={(e) => formChangeFieldMethod(e, 'text', formik)}
            />
            <FormErrorMessage fieldName="correctedWasteMass" formikInstance={formik} />
            <br />
            <br />
            <label htmlFor="remarks">{t('remarks')}</label>
            <InputTextarea
              id="remarks"
              value={formik.values.remarks}
              onChange={(e) => formChangeFieldMethod(e, 'text', formik)}
              rows={5}
              autoResize
            />
            <FormErrorMessage fieldName="remarks" formikInstance={formik} />
            <br />
            <br />
            <br />
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};

export default ReceiveConfirmationReceiver;
