import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import nodesService from '../../../../../../services/ScaleoApiServices/NodesService';
import { ControlMode } from '../../../../../../types/controls';

interface ControlButtonProps {
  currentMode?: ControlMode;
  nodeId: string;
  node: string;
}

const ControlButton = ({ currentMode, nodeId, node }: ControlButtonProps) => {
  const isOneAction = currentMode?.actions && currentMode.actions.length === 1;
  const isMultipleAction = currentMode?.actions && currentMode.actions.length > 1;

  const handleAction = (nodeId: string, commandName: string) => {
    nodesService.sendControlSignal(nodeId, commandName);
  };

  const dropdownOptionTemplate = (option: any) => <div className="item">{option.label}</div>;

  const handleChangeOption = (e: any) => {
    handleAction(node, e.value.action);
  };

  if (isOneAction) {
    return (
      <Button
        label={currentMode?.actions[0].label}
        text
        onClick={() => handleAction(nodeId, currentMode?.actions[0].commandName)}
      />
    );
  }

  if (isMultipleAction) {
    const options = currentMode?.actions;

    return (
      <div className="actions">
        <Dropdown
          options={options}
          onChange={handleChangeOption}
          placeholder="Akcje"
          itemTemplate={dropdownOptionTemplate}
        />
      </div>
    );
  }

  return <></>;
};

export default ControlButton;
