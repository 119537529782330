import React from 'react';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { CustomAutoComplete } from '../../../../../_shared/CustomComponents';
import FormErrorMessage from '../../../../../_shared/FormErrorMessage';
import BdoApiService from '../../../../../../services/BdoApiService';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import CustomDivider from '../../../../../../components/_shared/CustomDivider';

const BdoPlugin = ({ weighing, formikInstance, canEnable, kpoWeighings }) => {
  const { t } = useTranslation('weighingWeighingList');

  const onChangeCardType = (e) => {
    formikInstance.setFieldValue('cardType', e.value);
    formikInstance.setFieldValue('card', null);
  };

  const onChangeCardId = (e) => {
    if (e.value) {
      formikInstance.setFieldValue('card', e.value);
    } else {
      formikInstance.setFieldValue('card', formikInstance.values.card);
    }
  };

  const filterUsedCards = (cards) =>
    cards?.filter((card) => {
      const kpoWeighingId = kpoWeighings?.find((w) => w.cardId === card.kpoId)?.id;
      return kpoWeighingId === weighing.id || !kpoWeighingId;
    });

  const cardSearchBranch = (event, items, setFilteredItems) => {
    const searchText = event.query.trim();

    const paginationParameters = {
      order: {
        isAscending: false,
        orderColumn: null,
      },
      page: {
        index: 1,
        size: 100,
      },
    };

    const config = {
      show_loader: false,
    };

    const filters = {};
    if (searchText) {
      filters['cardNumber'] = searchText;
    }
    if (!formikInstance.values.filteredCardsForVehicle && formikInstance.values.vehicle.registrationNumber) {
      filters['vehicleRegNumber'] = formikInstance.values.vehicle.registrationNumber;
    }

    const searchMethods = {
      kpo: () => BdoApiService.KPO_getSenderSearch({ ...filters }, paginationParameters, config),
      'kpok-receive': () =>
        BdoApiService.KPOK_getSenderSearch({ ...filters, cardSubtype: 'RECEIVE' }, paginationParameters, config),
      'kpok-transfer': () =>
        BdoApiService.KPOK_getSenderSearch({ ...filters, cardSubtype: 'TRANSFER' }, paginationParameters, config),
    };

    searchMethods[formikInstance.values.cardType]().then((response) => {
      if (
        response.data.items.find((item) =>
          formikInstance.values.cardType === 'kpo'
            ? item?.kpoId === formikInstance.values.card?.kpoId
            : item?.kpokId === formikInstance.values.card?.kpokId,
        ) ||
        !formikInstance.values.card
      ) {
        setFilteredItems(filterUsedCards(response.data.items));
      } else {
        setFilteredItems(filterUsedCards([...response.data.items, formikInstance.values.card]));
      }
    });
  };

  const cardItemTemplate = (item) => (
    <div className="item" key={item.cardNumber}>
      <div>
        {item.cardNumber ||
          t('weighingDialogBdoPluginCardTemplate', {
            time: moment(item.plannedTransportTime).format('YYYY-MM-DD HH:mm'),
          })}
      </div>
    </div>
  );

  if (canEnable && (['SINGLE', 'DOUBLE', 'MANUAL'].includes(weighing.weighingMode) || !weighing.id)) {
    return (
      <>
        <CustomDivider />
        <div className="field col-12 md:col-12">
          <label htmlFor="cardType">{t('weighingDialogBdoPluginCardType')}</label>
          <div className="flex">
            <div className="field-radiobutton mr-3">
              <RadioButton
                inputId="cardType0"
                name="cardType"
                value=""
                onChange={onChangeCardType}
                checked={!formikInstance.values.cardType}
              />
              <label htmlFor="cardType3">{t('weighingDialogBdoPluginNoExist')}</label>
            </div>
            <div className="field-radiobutton mr-3">
              <RadioButton
                inputId="cardType1"
                name="cardType"
                value="kpo"
                onChange={onChangeCardType}
                checked={formikInstance.values.cardType === 'kpo'}
              />
              <label htmlFor="cardType1">{t('weighingDialogBdoPluginKpo')}</label>
            </div>
            <div className="field-radiobutton mr-3">
              <RadioButton
                inputId="cardType2"
                name="cardType"
                value="kpok-receive"
                onChange={onChangeCardType}
                checked={formikInstance.values.cardType === 'kpok-receive'}
              />
              <label htmlFor="cardType2">{t('weighingDialogBdoPluginKpokReceive')}</label>
            </div>
            <div className="field-radiobutton">
              <RadioButton
                inputId="cardType3"
                name="cardType"
                value="kpok-transfer"
                onChange={onChangeCardType}
                checked={formikInstance.values.cardType === 'kpok-transfer'}
              />
              <label htmlFor="cardType3">{t('weighingDialogBdoPluginKpokTransfer')}</label>
            </div>
            <div className="field-checkbox col-6">
              <Checkbox
                id="filteredCardsForVehicle"
                checked={
                  formikInstance.values.vehicle?.registrationNumber && !formikInstance.values.filteredCardsForVehicle
                }
                onChange={(e) => formikInstance.setFieldValue('filteredCardsForVehicle', !e.checked)}
              ></Checkbox>
              <label htmlFor="filteredCardsForVehicle">{t('weighingDialogBdoPluginFilterCheckbox')}</label>
            </div>
          </div>
          {!!formikInstance.values.cardType && (
            <>
              <CustomAutoComplete
                id="card"
                lookupField="cardNumber"
                value={
                  formikInstance.values.card
                    ? {
                        ...formikInstance.values.card,
                        cardNumber:
                          formikInstance.values.card.cardNumber ||
                          (formikInstance.values.card.plannedTransportDate
                            ? t('weighingDialogBdoPluginPlannedCardInfo1', {
                                plannedTransportDate: formikInstance.values.card.plannedTransportDate,
                                plannedTransportTime: formikInstance.values.card.plannedTransportTime,
                              })
                            : t('weighingDialogBdoPluginPlannedCardInfo2', {
                                plannedTransportTime: moment(formikInstance.values.card.plannedTransportTime).format(
                                  'YYYY-MM-DD HH:mm',
                                ),
                              })),
                      }
                    : null
                }
                handler={onChangeCardId}
                source={[]}
                customSearchBranch={cardSearchBranch}
                customItemTemplate={cardItemTemplate}
                disabled={!formikInstance.values.cardType}
              />
              <FormErrorMessage fieldName="card" formikInstance={formikInstance} />
            </>
          )}
        </div>
      </>
    );
  }

  return <></>;
};
export default BdoPlugin;
