import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SNAPSHOT_DIALOG_TYPE } from '../../enums/snapshot-dialog.enum';
import { weighingDialogHeaderCallback } from '../Weighing/WeighingsList/components/helpers';
import { accessEventLogDialogHeaderCallback } from '../AccessControlEventLog/helpers';
import Gallery from '../Gallery/Gallery';

interface SnapshotsDialogProps {
  data: any[];
  currentDataId: number;
  visible: boolean;
  closeDialog: () => void;
  visibleText: boolean;
  type: SNAPSHOT_DIALOG_TYPE;
  serviceToCall: any;
  downloadImagesCallback: any;
}

const SnapshotsDialog = ({
  data,
  currentDataId,
  visible,
  closeDialog,
  visibleText,
  type,
  serviceToCall,
  downloadImagesCallback,
}: SnapshotsDialogProps) => {
  const { t } = useTranslation('snapshotDialog');

  const [currentData, setCurrentData] = useState<any>(null);
  const [snapshots, setSnapshots] = useState<any[]>([]);

  const dialog =
    type === SNAPSHOT_DIALOG_TYPE.WEIGHINGS
      ? weighingDialogHeaderCallback(currentData)
      : accessEventLogDialogHeaderCallback(currentData);

  const fetchData = useCallback(async () => {
    if (currentData) {
      const result = await serviceToCall(currentData);

      setSnapshots(result);
    }
  }, [currentData]);

  useEffect(() => {
    void fetchData();
  }, [fetchData, currentDataId]);

  useEffect(() => {
    const foundData = data.find((item) => item.id === currentDataId);

    setCurrentData(foundData);
  }, [data, currentDataId]);

  const countSnapshots = () => {
    return snapshots.reduce((acc: number, curr: any) => acc + curr.length, 0);
  };

  const dialogFooter = (
    <>
      <Button
        type="button"
        label={t('downloadButton')}
        icon="pi pi-download"
        text
        onClick={() => downloadImagesCallback(currentData)}
        disabled={countSnapshots() === 0}
      />
      <Button type="reset" label={t('closeButton')} icon="pi pi-times" text onClick={closeDialog} />
    </>
  );

  const DialogData = () => {
    if (type === SNAPSHOT_DIALOG_TYPE.ACCESS_CONTROL_EVENT_LOG && currentData.id) {
      return (
        <div key={currentData.id}>
          {snapshots?.length > 0 ? (
            <Gallery snapshots={snapshots} timestamp={currentData.date} visibleText={visibleText} />
          ) : (
            <div className="h-3vh mt-1vw">
              <p>{t('noSnapshots')}</p>
            </div>
          )}
        </div>
      );
    }

    if (type === SNAPSHOT_DIALOG_TYPE.WEIGHINGS && currentData.measurements) {
      return currentData.measurements?.map((measurement: any, index: number) => (
        <div key={index}>
          <h6 className="pb-10px m-0" style={{ borderBottom: '1px solid rgba(0,0,0,0.2)' }}>
            {' '}
            {t('measurement', { index: index + 1 })}
          </h6>
          {snapshots[index]?.length > 0 ? (
            <Gallery
              snapshots={snapshots[index]}
              timestamp={measurement.timestamp}
              weight={measurement.indication}
              visibleText={visibleText}
            />
          ) : (
            <div className="h-3vh mt-1vw">
              <p>{t('noSnapshots')}</p>
            </div>
          )}
        </div>
      ));
    }
  };

  return (
    <Dialog
      visible={visible}
      header={dialog}
      modal
      className="p-fluid w-40vw"
      footer={dialogFooter}
      onHide={closeDialog}
    >
      <DialogData />
    </Dialog>
  );
};

export default SnapshotsDialog;
