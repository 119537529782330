import { Dialog } from 'primereact/dialog';
import HistoryPlugin from './components/HistoryPlugin';
import { useTranslation } from 'react-i18next';

interface WeighingHistoryDialogProps {
  visible: boolean;
  onClose: () => void;
  weighing: any;
  setWeighing?: (arg: any) => void;
  isCombine: boolean;
}

const WeighingHistoryDialog = ({ visible, onClose, weighing }: WeighingHistoryDialogProps) => {
  const { t } = useTranslation('weighingWeighingList');

  return (
    <Dialog
      visible={visible}
      header={t('weighingDialogHistoryLabelTitle')}
      modal
      className="p-fluid w-60vw"
      breakpoints={{ '1400px': '75vw', '896px': '90vw' }}
      onHide={onClose}
    >
      <HistoryPlugin weighingId={weighing.id} alwaysVisible={true} noInnerTitle={true} />
    </Dialog>
  );
};

export default WeighingHistoryDialog;
