import axios from 'axios';

class RolesService {
  async searchRoles(filters, paginatorParam) {
    return axios.post('/roles/search', this.convertFilters(filters, paginatorParam));
  }
  async getRoles() {
    return axios.get('/roles');
  }
  async removeRole(id) {
    return axios.delete(`/roles/${id}`);
  }
  async editRole(data, id) {
    return axios.put(`/roles/${id}`, {
      data,
    });
  }
  async createRole(data) {
    return axios.post(`/roles`, {
      data,
    });
  }
  convertFilters(filters, paginatorParam) {
    const newFilters = { 'roles.name': filters.name };
    return { filters: newFilters, paginatorParam };
  }
}
const rolesService = new RolesService();
export default rolesService;
