import '../../DataTable.css';
import useModulesConfiguration from '../../../utils/useModulesConfiguration';
import { useWeighingsListData } from '../../../hooks/WeighingsList/useWeighingsListData';
import { useWeighingsListDialogs } from '../../../hooks/WeighingsList/useWeighingsListDialogs';
import { WeighingsListTable } from './components/WeighingsListTable';
import { useWeighingsListSelection } from '../../../hooks/WeighingsList/useWeighingListSelection';
import { WeighingDialogs } from './components/WeighingDialogs';
import { WeighingListToolbar } from './components/WeighingListToolbar';

const WeighingsList = () => {
  const modulesConfiguration = useModulesConfiguration();

  const {
    componentData,
    paginatorState,
    handleFetchComponentData,
    productsMultiselectSearchMethod,
    setPaginatorState,
    isWeighingSeriesEnabled,
    locations,
    paginationParameters,
    showDeletedWeighings,
    setShowDeletedWeighings,
    setPaginationParameters,
  } = useWeighingsListData();

  const { selectedWeighings, setSelectedWeighings, handleSelectionChange } = useWeighingsListSelection();

  const {
    setClickedRowId,
    setIsOpenSnapshotsDialog,
    setBdoDialogData,
    setDialogKpokMode,
    setIsOpenDialogKpo,
    setIsOpenDialogKpok,
    setWeighing,
    setWeighingDialogVisible,
    showSendEmailDialog,
    handleOpenNew,
    handleClickDeleteSelectedWeighings,
    setReportFormat,
    setReportDialogVisible,
    setWeighingHistoryVisible,
    setDeleteWeighingDialogVisible,
    setRestoreWeighingDialogVisible,
    weighing,
    weighingDialogVisible,
    weighingHistoryVisible,
    deleteSelectedWeighingsDialogVisible,
    deleteWeighingDialogVisible,
    restoreWeighingDialogVisible,
    reportDialogVisible,
    sendEmailDialogVisible,
    reportFormat,
    isOpenDialogKpo,
    isOpenDialogKpok,
    dialogKpokMode,
    bdoDialogData,
    isOpenSnapshotsDialog,
    clickedRowId,
    handleHideDialog,
    handleDeleteWeighing,
    handleDeleteSelectedWeighings,
    handleCloseDeleteWeighingDialog,
    handleCloseRestoreWeighingDialog,
    handleCloseDeleteSelectedWeighingsDialog,
    handleHideReportDialog,
    restoreWeighingDialogFooter,
    getReportDialogFilters,
    hideSendEmailDialog,
    loadAfterChange,
    handleCloseDialog,
    weighingsReceiptsToSend,
  } = useWeighingsListDialogs(handleFetchComponentData, selectedWeighings, setSelectedWeighings, locations);

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <WeighingListToolbar
            selectedWeighings={selectedWeighings!}
            handleOpenNew={handleOpenNew}
            handleClickDeleteSelectedWeighings={handleClickDeleteSelectedWeighings}
            showSendEmailDialog={showSendEmailDialog}
            setReportFormat={setReportFormat}
            setReportDialogVisible={setReportDialogVisible}
          />
          <WeighingsListTable
            handleFetchComponentData={handleFetchComponentData}
            componentData={componentData}
            modulesConfiguration={modulesConfiguration}
            showSendEmailDialog={showSendEmailDialog}
            selectedWeighings={selectedWeighings}
            setWeighing={setWeighing}
            setWeighingDialogVisible={setWeighingDialogVisible}
            setWeighingHistoryVisible={setWeighingHistoryVisible}
            setBdoDialogData={setBdoDialogData}
            setDeleteWeighingDialogVisible={setDeleteWeighingDialogVisible}
            setRestoreWeighingDialogVisible={setRestoreWeighingDialogVisible}
            setIsOpenDialogKpo={setIsOpenDialogKpo}
            setIsOpenDialogKpok={setIsOpenDialogKpok}
            setDialogKpokMode={setDialogKpokMode}
            setClickedRowId={setClickedRowId}
            setIsOpenSnapshotsDialog={setIsOpenSnapshotsDialog}
            setPaginationParameters={setPaginationParameters}
            paginationParameters={paginationParameters}
            paginatorState={paginatorState}
            setPaginatorState={setPaginatorState}
            locations={locations}
            isWeighingSeriesEnabled={isWeighingSeriesEnabled}
            handleSelectionChange={handleSelectionChange}
            showDeletedWeighings={showDeletedWeighings}
            setShowDeletedWeighings={setShowDeletedWeighings}
            productsMultiselectSearchMethod={productsMultiselectSearchMethod}
            weighingConfiguration={componentData.configuration}
          />
          <WeighingDialogs
            sendEmailDialogVisible={sendEmailDialogVisible}
            isOpenSnapshotsDialog={isOpenSnapshotsDialog}
            isOpenDialogKpo={isOpenDialogKpo}
            isOpenDialogKpok={isOpenDialogKpok}
            weighingDialogVisible={weighingDialogVisible}
            weighingHistoryVisible={weighingHistoryVisible}
            deleteWeighingDialogVisible={deleteWeighingDialogVisible}
            deleteSelectedWeighingsDialogVisible={deleteSelectedWeighingsDialogVisible}
            restoreWeighingDialogVisible={restoreWeighingDialogVisible}
            reportDialogVisible={reportDialogVisible}
            componentData={componentData}
            hideSendEmailDialog={hideSendEmailDialog}
            setSelectedWeighings={setSelectedWeighings}
            clickedRowId={clickedRowId}
            setIsOpenSnapshotsDialog={setIsOpenSnapshotsDialog}
            handleCloseDialog={handleCloseDialog}
            bdoDialogData={bdoDialogData}
            dialogKpokMode={dialogKpokMode}
            handleHideDialog={handleHideDialog}
            loadAfterChange={loadAfterChange}
            weighing={weighing}
            modulesConfiguration={modulesConfiguration}
            setWeighingHistoryVisible={setWeighingHistoryVisible}
            paginationParameters={paginationParameters}
            handleCloseDeleteWeighingDialog={handleCloseDeleteWeighingDialog}
            handleDeleteWeighing={handleDeleteWeighing}
            handleCloseDeleteSelectedWeighingsDialog={handleCloseDeleteSelectedWeighingsDialog}
            handleDeleteSelectedWeighings={handleDeleteSelectedWeighings}
            restoreWeighingDialogFooter={restoreWeighingDialogFooter}
            handleCloseRestoreWeighingDialog={handleCloseRestoreWeighingDialog}
            handleHideReportDialog={handleHideReportDialog}
            getReportDialogFilters={getReportDialogFilters}
            reportFormat={reportFormat}
            isWeighingSeriesEnabled={isWeighingSeriesEnabled}
            weighingsReceiptsToSend={weighingsReceiptsToSend}
          />
        </div>
      </div>
    </div>
  );
};

export default WeighingsList;
