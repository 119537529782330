import { useCallback, useState } from 'react';
import { BdoApiType } from '../../enums/bdo-api-type.enum';
import bdoConfigurationService from '../../services/ScaleoApiServices/BdoConfigurationService';

const initialValues = {
  moduleEnabled: false,
  isChanged: false,
  clientId: '',
  clientSecret: '',
  isLogged: null,
  receiverKpoModuleEnabled: false,
  receiverKpoUpdatedCardStatus: null,
  receiverKpoUpdateMass: null,
  receiverKpoAddMassToCardRemarks: null,
  receiverKpokReceiveModuleEnabled: false,
  receiverKpokReceiveUpdatedCardStatus: null,
  receiverKpokReceiveUpdateMass: null,
  receiverKpokReceiveAddMassToCardRemarks: null,
  receiverKpokTransferModuleEnabled: false,
  receiverKpokTransferUpdatedCardStatus: null,
  receiverKpokTransferUpdateMass: null,
  receiverKpokTransferAddMassToCardRemarks: null,
  apiType: BdoApiType.PRODUCTION,
};

const isModuleEnabled = (updatedCardStatus: string) => {
  if (!updatedCardStatus || !updatedCardStatus.length) return false;

  return true;
};

const useBdoConfigurationData = () => {
  const [data, setData] = useState(initialValues);

  const fetchData = useCallback(async () => {
    const response = await bdoConfigurationService.getConfiguration();

    if (response.data) {
      const responseData = {
        id: response.data.id,
        customerId: response.data.customerId,
        moduleEnabled: response.data.moduleEnabled,
        isChanged: !response.data.isLogged,
        clientId: response.data.clientId,
        clientSecret: response.data.clientSecret,
        isLogged: response.data.isLogged,
        receiverKpoModuleEnabled: isModuleEnabled(response.data.receiverKpoUpdatedCardStatus),
        receiverKpoUpdatedCardStatus: response.data.receiverKpoUpdatedCardStatus,
        receiverKpoUpdateMass: response.data.receiverKpoUpdateMass,
        receiverKpoAddMassToCardRemarks: response.data.receiverKpoAddMassToCardRemarks,
        receiverKpokReceiveModuleEnabled: isModuleEnabled(response.data.receiverKpokReceiveUpdatedCardStatus),
        receiverKpokReceiveUpdatedCardStatus: response.data.receiverKpokReceiveUpdatedCardStatus,
        receiverKpokReceiveUpdateMass: response.data.receiverKpokReceiveUpdateMass,
        receiverKpokReceiveAddMassToCardRemarks: response.data.receiverKpokReceiveAddMassToCardRemarks,
        receiverKpokTransferModuleEnabled: isModuleEnabled(response.data.receiverKpokTransferUpdatedCardStatus),
        receiverKpokTransferUpdatedCardStatus: response.data.receiverKpokTransferUpdatedCardStatus,
        receiverKpokTransferUpdateMass: response.data.receiverKpokTransferUpdateMass,
        receiverKpokTransferAddMassToCardRemarks: response.data.receiverKpokTransferAddMassToCardRemarks,
        apiType: response.data.apiType,
      };

      setData(responseData);
    }
  }, []);

  return { data, fetchData };
};

export default useBdoConfigurationData;
