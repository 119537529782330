import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Fieldset } from 'primereact/fieldset';
import React, { useRef } from 'react';
import importExportService from '../../../../services/ScaleoApiServices/ImportExportService';
import { getFileFromText } from '../../../../utils/getFile';
import { useTranslation } from 'react-i18next';

interface SectionDatabaseProps {
  onImport: any;
}

const SectionDatabase = ({ onImport }: SectionDatabaseProps) => {
  const { t } = useTranslation('managementImportExport');

  const fileUploader = useRef<FileUpload>(null);

  const exportData = async () => {
    const csvResponse = await importExportService.getDatabaseCSV();
    const csv = csvResponse.data;
    getFileFromText(csv, 'text/csv', `database-${Date.now().toString()}.csv`);
  };

  const importData = (event: any) => {
    let csvData = '';
    const [f] = event.files;
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      csvData = Buffer.from(e.target.result).toString('base64');
      const insertResponse = await importExportService.insertDatabaseCSV(csvData);

      fileUploader.current?.clear();
      onImport(insertResponse.data);
    };
    reader.readAsText(f);
  };

  return (
    <Fieldset legend={t('databaseHeader')} style={undefined}>
      <div className="formgrid grid">
        <FileUpload
          ref={fileUploader}
          mode="basic"
          accept=".csv"
          maxFileSize={1000000}
          name={t('importButton')}
          chooseLabel={t('importButton')}
          className="mr-2"
          customUpload
          auto
          uploadHandler={importData}
        />
        <Button label={t('exportButton')} icon="pi pi-upload" severity="help" onClick={exportData} />
      </div>
    </Fieldset>
  );
};

export default SectionDatabase;
