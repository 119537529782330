import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../types/reducer-state';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export const CustomerIdSection = () => {
  const { t } = useTranslation('managementApiKey');

  const [isCopiedCustomerId, setIsCopiedCustomerId] = useState(false);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const customerId = loggedUserContext.currentCustomer?.id;

  const handleClickCopy = async (text: string, setIsCopied: (b: boolean) => void) => {
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <div className="field grid mt-6 ml-5 mb-8">
      <label htmlFor="customerId" className="col-12 md:col-1">
        {t('customerId')}
      </label>
      <div className="col-12 md:col-11 inline-block">
        <InputText className="w-30rem ml-7" id="customerId" value={customerId} />
        <Button
          className="w-12rem ml-4"
          onClick={() => handleClickCopy(customerId!, setIsCopiedCustomerId)}
          disabled={isCopiedCustomerId}
        >
          {t('copy')}
        </Button>
      </div>
      {isCopiedCustomerId && (
        <Message
          style={{ marginLeft: '9.7vw', marginTop: '0.1vw', marginBottom: '-2.2vw', height: '2vw' }}
          text={t('copied')}
        />
      )}
    </div>
  );
};
