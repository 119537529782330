const trimString = (text, count, dots) => {
  if (count < text?.length) {
    let result = text.substring(0, count - 1);
    if (dots) {
      result += '...';
    }
    return result;
  } else {
    return text;
  }
};

export { trimString };
