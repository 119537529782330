import * as mqtt from 'mqtt';
import { Toast } from 'primereact/toast';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import AppWrapper from '../../AppWrapper';
import { scaleoModeContext } from '../../services/ScaleoModeContext';
import { indicationActions } from '../../store/indication-slice';
import { nodeActions } from '../../store/node-slice';
import { uiActions } from '../../store/ui-slice';
import { weighingActions } from '../../store/weighing-slice';
import { confirmationsActions } from '../../store/confirmations-slice';
import { ReducerState } from '../../types/reducer-state';
import { userActions } from '../../store/user-slice';
import { stateActions } from '../../store/state-slice';

const AnyAppWrapper = AppWrapper as any;

export const MqttMessagingContainer = () => {
  const dispatch = useDispatch();
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const loggedUserToken = useSelector((state: ReducerState) => state.user.loggedUser?.idToken);
  const selectedScale = useSelector((state: ReducerState) => state.weighing.selectedScale);
  const toast = useRef(null);
  const credentialsRef = useRef();
  const clientRef = useRef<any | null>();
  // const [disconnected, setDisconnected] = useState(false);

  const publishWeighCommand = (topic: string, message: any) => {
    dispatch(weighingActions.weighingMeasurementRequested(message.sessionId));
    dispatch(uiActions.toggleIsLoading({ isLoading: true }));
    publicMqttMessage(topic, message);
  };

  const publishScaleCommand = (topic: string, message: any) => {
    publicMqttMessage(topic, message);
  };

  const publicMqttMessage = (topic: string, message: any) => {
    const payload = JSON.stringify({ ...message, token: loggedUserToken });
    clientRef.current.publish(
      topic,
      payload,
      { qos: 1, clientId: clientRef.current.options.clientId },
      (error: any) => {
        error && console.error(`MQTT Publish error ${error}`);
      },
    );
  };

  const messageHandler = useCallback(
    async (message, topic, locationId, scaleId) => {
      const weighingFinishedEventTopic = process.env.REACT_APP_MQTT_WEIGHING_FINISHED_EVENT_TOPIC?.replace(
        '<location>',
        locationId,
      );
      const nodeSignalsDataTopic = process.env.REACT_APP_MQTT_NODE_SIGNALS_DATA_TOPIC?.replace(
        '<location>',
        locationId,
      );
      const stateVehiclePositionTopic = process.env.REACT_APP_MQTT_STATE_VEHICLE_POSITION_TOPIC?.replace(
        '<location>',
        locationId,
      );
      const scaleIndicationDataTopic = process.env.REACT_APP_MQTT_SCALE_INDICATION_DATA_TOPIC?.replace(
        '<location>',
        locationId,
      ).replace('<scale>', scaleId);
      const scaleZeroFinishedEventTopic = process.env.REACT_APP_MQTT_SCALE_EVENT_ZERO_FINISHED_TOPIC?.replace(
        '<location>',
        locationId,
      );
      const requestVehicleAccessConfirmationTopic =
        process.env.REACT_APP_MQTT_REQUEST_VEHICLE_ACCESS_CONFIRMATION_TOPIC?.replace('<location>', locationId);
      switch (topic) {
        case weighingFinishedEventTopic:
          dispatch(weighingActions.weighingMeasurementFinished(JSON.parse(message)));
          dispatch(userActions.shouldRefreshLoggedUserContext(true));
          break;
        case nodeSignalsDataTopic:
          dispatch(nodeActions.updateNodes(JSON.parse(message)));
          break;
        case scaleIndicationDataTopic:
          dispatch(
            indicationActions.scaleIndicationReceived({
              message,
              playSoundSpecifiedWeight: selectedScale?.playSoundSpecifiedWeight,
            }),
          );
          break;
        case scaleZeroFinishedEventTopic:
          // TODO: scale zero finished is done by detecting 0 in ScaleIndication.js so far
          // we will have dedicated message fro edge device in the future
          break;
        case requestVehicleAccessConfirmationTopic:
          dispatch(confirmationsActions.updateConfirmations({ ...JSON.parse(message), locationId }));
          break;
        case stateVehiclePositionTopic:
          dispatch(stateActions.vehiclePosition(JSON.parse(message)));
          break;
        default:
      }
    },
    [dispatch, selectedScale],
  );

  const initMqtt = useCallback(
    async (locationId, scaleId) => {
      const weighingFinishedTopic = process.env.REACT_APP_MQTT_WEIGHING_FINISHED_EVENT_TOPIC?.replace(
        '<location>',
        locationId,
      );
      const nodeSignalsDataTopic = process.env.REACT_APP_MQTT_NODE_SIGNALS_DATA_TOPIC?.replace(
        '<location>',
        locationId,
      );
      const stateVehiclePositionTopic = process.env.REACT_APP_MQTT_STATE_VEHICLE_POSITION_TOPIC?.replace(
        '<location>',
        locationId,
      );
      const scaleIndicationDataTopic = process.env.REACT_APP_MQTT_SCALE_INDICATION_DATA_TOPIC?.replace(
        '<location>',
        locationId,
      ).replace('<scale>', scaleId);
      const scaleIndicationRequestTopic = process.env.REACT_APP_MQTT_SCALE_INDICATION_REQUEST_TOPIC?.replace(
        '<location>',
        locationId,
      );
      const getSignalValuesRequestTopic = process.env.REACT_APP_MQTT_GET_SIGNAL_VALUES_REQUEST_TOPIC?.replace(
        '<location>',
        locationId,
      );
      const scaleZeroFinishedEventTopic = process.env.REACT_APP_MQTT_SCALE_EVENT_ZERO_FINISHED_TOPIC?.replace(
        '<location>',
        locationId,
      );
      const getVehiclePositionTopic = process.env.REACT_APP_MQTT_COMMAND_GET_STATE_VEHICLE_POSITION?.replace(
        '<location>',
        locationId,
      );
      const requestVehicleAccessConfirmationTopic =
        process.env.REACT_APP_MQTT_REQUEST_VEHICLE_ACCESS_CONFIRMATION_TOPIC?.replace('<location>', locationId);

      if (clientRef.current) {
        const currentClientId = clientRef.current.options.clientId;
        clientRef.current.end(true, () => {
          console.log(`Closed client ${currentClientId}`);
        });
      }

      const wsUrlTransformer = scaleoModeContext().wsUrlTransformer;
      const host = process.env.REACT_APP_MQTT_HOST ?? `${window.location.hostname}:1884`;
      const clientId = `scaleo-ui/${uuidv4()}`;

      console.log(`Creating client ${clientId}`);
      const client = mqtt.connect(wsUrlTransformer(host, credentialsRef), {
        transformWsUrl: () => {
          return wsUrlTransformer(host, credentialsRef); //transformWsUrl as when trying to re-connect it will fetch updated creds.
        },
        keepalive: 5,
        clientId: clientId,
        username: process.env.REACT_APP_MQTT_USERNAME,
        password: process.env.REACT_APP_MQTT_PASSWORD,
      });

      client.on('connect', () => {
        clientRef.current = client;
        const topics = [
          scaleIndicationDataTopic,
          weighingFinishedTopic,
          scaleZeroFinishedEventTopic,
          nodeSignalsDataTopic,
          stateVehiclePositionTopic,
          requestVehicleAccessConfirmationTopic,
        ] as string[];
        console.log(`Trying to subscribe to topics: ${topics}`);
        client.subscribe(topics, (err: any) => {
          if (err) {
            // setDisconnected(true);
            dispatch(uiActions.toggleMqttConnected({ mqttConnected: false }));
            console.log('Subscribe failed!');
          } else {
            dispatch(uiActions.toggleMqttConnected({ mqttConnected: true }));

            client.publish(
              scaleIndicationRequestTopic as string,
              JSON.stringify({ scaleId: scaleId }),
              { qos: 1, clientId: client.options.clientId, retain: false } as any,
              (error) => {
                error && console.error(`MQTT Publish error ${error}`);
              },
            );

            client.publish(
              getSignalValuesRequestTopic as string,
              JSON.stringify({}),
              { qos: 1, clientId: client.options.clientId, retain: false } as any,
              (error) => {
                error && console.error(`MQTT Publish error ${error}`);
              },
            );

            client.publish(
              getVehiclePositionTopic as string,
              JSON.stringify({}),
              { qos: 1, clientId: client.options.clientId, retain: false } as any,
              (error) => {
                error && console.error(`MQTT Publish error ${error}`);
              },
            );
            // disconnected && toast.current.show({ severity: 'success', summary: '', detail: 'Przywrócono połączenie z serwerem.', life: 5000 });
            // setDisconnected(false);
          }
        });
      });

      client.on('offline', () => {
        // setDisconnected(true);
        dispatch(uiActions.toggleMqttConnected({ mqttConnected: false }));
      });

      client.on('message', (topic, message) => {
        messageHandler(message.toString(), topic, locationId, scaleId);
      });
    },
    [dispatch, messageHandler],
  );

  useEffect(() => {
    setInterval(() => {
      scaleoModeContext().sessionCredentialsHandler(credentialsRef);
    }, 10 * 60000);
  }, []);

  useEffect(() => {
    loggedUserContext.currentLocationId &&
      scaleoModeContext()
        .sessionCredentialsHandler(credentialsRef)
        .then(() => {
          selectedScale?.location.id === loggedUserContext.currentLocationId &&
            initMqtt(loggedUserContext.currentLocationId, selectedScale?.id);
        });
  }, [initMqtt, loggedUserContext.currentLocationId, selectedScale?.id]);

  return (
    <>
      <AnyAppWrapper
        publishWeighCommandHandler={publishWeighCommand}
        publishScaleCommandHandler={publishScaleCommand}
      />
      <Toast ref={toast} />
    </>
  );
};
