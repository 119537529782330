import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import BdoApiService from '../../../../../services/BdoApiService';
import ChangeStatusDialogs from '../../components/ChangeStatusDialogs';
import {
  DEFAULT_DIALOG_BREAKPOINTS,
  DEFAULT_DIALOG_STYLE,
  CancelButton,
  transformCommuneId,
  transformWasteCodeId,
  ChangeStatusDropdown,
  PrintButton,
  checkCommaSeparator,
} from '../../../bdoUtils';
import documentUtils from '../../components/documentUtils';
import { useTranslation } from 'react-i18next';
import { CardSubtype } from '../../../../../enums/card-subtype.enum';

const EditGeneratedConfirmationCardDialog = ({ visible, closeDialog, item }) => {
  const { t } = useTranslation('kpokSender');

  const CHANGE_STATUS_OPTIONS = [{ label: t('withdrawn'), value: 'WITHDRAWN' }];

  const mode = item?.cardSubtype;
  const [currentCardInfo, setCurrentCardInfo] = useState(null);
  const [currentSenderEupInfo, setCurrentSenderEupInfo] = useState(null);
  const [currentSenderCompanyInfo, setCurrentSenderCompanyInfo] = useState(null);
  const [currentCarrierCompanyInfo, setCurrentCarrierCompanyInfo] = useState(null);
  const [isOpenChangeStatusDialog, setIsOpenChangeStatusDialog] = useState(false);
  const [currentReceiverCompanyInfo, setCurrentReceiverCompanyInfo] = useState(null);
  const [currentReceiverEupInfo, setCurrentReceiverEupInfo] = useState(null);

  const getInitialValues = useCallback(async () => {
    const response = await BdoApiService.KPOK_getGeneratedConfirmationCard(item.kpokId, 'Sender');

    BdoApiService.getCompany(response.data.senderCompanyId).then((res) => {
      setCurrentSenderCompanyInfo(res.data);
    });
    BdoApiService.getEup(response.data.senderEupId).then((res) => {
      setCurrentSenderEupInfo(res.data);
    });
    BdoApiService.getCompany(response.data.carrierCompanyId).then((res) => {
      setCurrentCarrierCompanyInfo(res.data);
    });
    BdoApiService.getCompany(response.data.receiverCompanyId).then((res) => {
      setCurrentReceiverCompanyInfo(res.data);
    });
    BdoApiService.getEup(response.data.receiverEupId).then((res) => {
      setCurrentReceiverEupInfo(res.data);
    });

    const {
      wasteCodeId,
      wasteMass,
      vehicleRegNumber,
      plannedTransportTime,
      realTransportTime,
      additionalInfo,
      wasteCodeExtended,
      wasteCodeExtendedDescription,
      hazardousWasteReclassification,
      hazardousWasteReclassificationDescription,
      approvalUser,
      cardApprovalTime,
      year,
      cardNumber,
      cardStatus,
      isRevised,
      revisedAt,
      revisedBy,
      revisedWasteCodeId,
      revisedWasteMass,
      generatingConfirmationTime,
      generatingConfirmationUser,
      communeId,
      communeArea,
    } = response.data;

    const cardInfo = {
      vehicleRegNumber,
      wasteMass,
      plannedTransportTime: new Date(plannedTransportTime),
      plannedTransportTimeDate: new Date(plannedTransportTime),
      additionalInfo,
      wasteCodeExtended,
      hazardousWasteReclassification,
      realTransportTime: new Date(realTransportTime),
      approvalUser,
      cardApprovalTime: new Date(cardApprovalTime),
      year,
      cardNumber,
      cardStatus,
      isRevised,
      revisedAt: new Date(revisedAt),
      revisedBy,
      revisedWasteCodeId,
      revisedWasteMass,
      generatingConfirmationTime: new Date(generatingConfirmationTime),
      generatingConfirmationUser,
      communeArea,
    };
    if (wasteCodeExtended) {
      cardInfo.wasteCodeExtendedDescription = wasteCodeExtendedDescription;
    }
    if (hazardousWasteReclassification) {
      cardInfo.hazardousWasteReclassificationDescription = hazardousWasteReclassificationDescription;
    }

    const wasteCodeIdResponse = await BdoApiService.getWasteCodeByWasteCodeId(wasteCodeId);
    cardInfo.wasteCodeId = transformWasteCodeId(wasteCodeIdResponse.data);

    const revisedWasteCodeIdResponse = await BdoApiService.getWasteCodeByWasteCodeId(revisedWasteCodeId);
    cardInfo.revisedWasteCodeId = transformWasteCodeId(revisedWasteCodeIdResponse.data);

    const communeIdResponse = await BdoApiService.getCommune(communeId);
    cardInfo.communeId = transformCommuneId(communeIdResponse.data);

    setCurrentCardInfo(cardInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    if (visible) {
      getInitialValues();
    }
  }, [getInitialValues, visible]);

  const handleCloseDialog = (changes, statusFilter) => {
    closeDialog(changes === true, statusFilter);
  };

  const handleCloseChangeStatusDialog = (changes, statusFilter) => {
    setIsOpenChangeStatusDialog(false);
    if (changes) {
      handleCloseDialog(true, statusFilter);
    }
  };

  const handleChangeStatus = (e) => {
    const METHODS = {
      WITHDRAWN: () => setIsOpenChangeStatusDialog(true),
    };

    METHODS[e.value] && METHODS[e.value]();
  };

  const dialogFooter = (
    <div className="grid justify-content-center">
      <CancelButton onClick={handleCloseDialog} />
      <ChangeStatusDropdown options={CHANGE_STATUS_OPTIONS} onChange={handleChangeStatus} />
      <PrintButton onClick={() => documentUtils.KPOK_downloadDocument(item.kpokId, mode)} label={t('print')} />
    </div>
  );

  return (
    <>
      <Dialog
        visible={visible}
        header={t('editGeneratedConfirmationCardDialog')}
        modal
        className="p-fluid"
        breakpoints={DEFAULT_DIALOG_BREAKPOINTS}
        style={DEFAULT_DIALOG_STYLE}
        footer={dialogFooter}
        onHide={handleCloseDialog}
      >
        <Panel header=" " className="pt-1vh">
          <div className="p-fluid formgrid grid">
            <div className="field col-4">
              <label>{t('calendarYear')}</label>
              <p>
                <b>{currentCardInfo?.year || '-'}</b>
              </p>
            </div>
            <div className="field col-4">
              <label>{t('fullCardNumber')}</label>
              <p>
                <b>{currentCardInfo?.cardNumber || '-'}</b>
              </p>
            </div>
            <div className="field col-4">
              <label>{t('cardStatus')}</label>
              <p>
                <b>{currentCardInfo?.cardStatus || '-'}</b>
              </p>
            </div>
          </div>
        </Panel>
        <Panel
          header={mode === CardSubtype.RECEIVE ? t('receiverData') : t('senderData')}
          toggleable
          className="pt-1vh"
        >
          <div className="p-fluid formgrid grid pt-1vh">
            <div className="field col-4">
              <label>{t('senderRegistrationNumber')}</label>
              <p>
                <b>{currentSenderCompanyInfo?.registrationNumber || '-'}</b>
              </p>
            </div>
            <div className="field col-4">
              <label>{t('senderIdentificationNumber')}</label>
              <p>
                <b>{currentSenderCompanyInfo?.nip || '-'}</b>
              </p>
            </div>
            <div className="field col-4">
              <label>{t('identificationNumberEu')}</label>
              <p>
                <b>{currentSenderCompanyInfo?.nipEu || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('fullSenderName')}</label>
              <p>
                <b>{currentSenderCompanyInfo?.name || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('senderAddress')}</label>
              <p>
                {currentSenderCompanyInfo?.address
                  ? currentSenderCompanyInfo?.address
                      .replaceAll(': ', ':<b> ')
                      .split(', ')
                      .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                  : '-'}
              </p>
            </div>
            {mode === CardSubtype.TRANSFER ? (
              <>
                <div className="field col-4">
                  <label>{t('senderPlaceNumber')}</label>
                  <p>
                    <b>{currentSenderEupInfo?.registrationNumber || '-'}</b>
                  </p>
                </div>
                <div className="field col-8">
                  <label>{t('senderPlaceName')}</label>
                  <p>
                    <b>{currentSenderEupInfo?.name || '-'}</b>
                  </p>
                </div>
                <div className="field col-12">
                  <label>{t('senderPlaceAddress')}</label>
                  <p>
                    {currentSenderEupInfo?.address
                      ? currentSenderEupInfo?.address
                          .replaceAll(': ', ':<b> ')
                          .split(', ')
                          .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                      : '-'}
                  </p>
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </Panel>
        <Panel header={t('transporterData')} toggleable className="pt-1vh">
          <div className="p-fluid formgrid grid">
            <div className="field col-4">
              <label>{t('transporterRegistrationNumber')}</label>
              <p>
                <b>{currentCarrierCompanyInfo?.registrationNumber || '-'}</b>
              </p>
            </div>
            <div className="field col-4">
              <label>{t('transporterIdentificationNumber')}</label>
              <p>
                <b>{currentCarrierCompanyInfo?.nip || '-'}</b>
              </p>
            </div>
            <div className="field col-4">
              <label>{t('identificationNumberEu')}</label>
              <p>
                <b>{currentCarrierCompanyInfo?.nipEu || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('transporterName')}</label>
              <p>
                <b>{currentCarrierCompanyInfo?.name || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('transporterAddress')}</label>
              <p>
                {currentCarrierCompanyInfo?.address
                  ? currentCarrierCompanyInfo?.address
                      .replaceAll(': ', ':<b> ')
                      .split(', ')
                      .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                  : '-'}
              </p>
            </div>
          </div>
        </Panel>
        <Panel header={t('receiverData')} toggleable className="pt-1vh">
          <div className="p-fluid formgrid grid">
            <div className="field col-4">
              <label>{t('receiverRegistrationNumber')}</label>
              <p>
                <b>{currentReceiverCompanyInfo?.registrationNumber || '-'}</b>
              </p>
            </div>
            <div className="field col-4">
              <label>{t('receiverIdentificationNumber')}</label>
              <p>
                <b>{currentReceiverCompanyInfo?.nip || '-'}</b>
              </p>
            </div>
            <div className="field col-4">
              <label>{t('identificationNumberEu')}</label>
              <p>
                <b>{currentReceiverCompanyInfo?.nipEu || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('fullReceiverName')}</label>
              <p>
                <b>{currentReceiverCompanyInfo?.name || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('receiverAddress')}</label>
              <p>
                {currentReceiverCompanyInfo?.address
                  ? currentReceiverCompanyInfo?.address
                      .replaceAll(': ', ':<b> ')
                      .split(', ')
                      .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                  : '-'}
              </p>
            </div>
            <div className="field col-4">
              <label>{t('receiverPlaceNumber')}</label>
              <p>
                <b>{currentReceiverEupInfo?.registrationNumber || '-'}</b>
              </p>
            </div>
            <div className="field col-8">
              <label>{t('receiverPlaceName')}</label>
              <p>
                <b>{currentReceiverEupInfo?.name || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('receiverPlaceAddress')}</label>
              <p>
                {currentReceiverEupInfo?.address
                  ? currentReceiverEupInfo?.address
                      .replaceAll(': ', ':<b> ')
                      .split(', ')
                      .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                  : '-'}
              </p>
            </div>
          </div>
        </Panel>
        <Panel
          header={mode === CardSubtype.RECEIVE ? t('receiverWasteData') : t('senderWasteData')}
          toggleable
          className="pt-1vh"
        >
          <div className="p-fluid formgrid grid">
            <div className="field col-12">
              <label>{t('communeName')}</label>
              <p>
                <b>{currentCardInfo?.communeId?.fulltext || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('communeArea')}</label>
              <p>
                <b>{currentCardInfo?.communeArea || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('wasteCodeAndType')}</label>
              <p>
                <b>{currentCardInfo?.wasteCodeId?.fulltext || '-'}</b>
              </p>
            </div>
            <div className="field col-12 md:col-6 lg:col-4">
              <label>{t('wasteCodeExtended')}</label>
              <p>
                <b>{currentCardInfo?.wasteCodeExtended ? 'TAK' : 'NIE'}</b>
              </p>
            </div>
            <div className="field col-12 md:col-6 lg:col-8">
              <label>{t('wasteTypeExtended')}</label>
              <p>
                <b>{currentCardInfo?.wasteCodeExtendedDescription || '-'}</b>
              </p>
            </div>
            <div className="field col-12 md:col-6 lg:col-4">
              <label>{t('hazardousWasteReclassificationFull')}</label>
              <p>
                <b>{currentCardInfo?.hazardousWasteReclassification ? 'TAK' : 'NIE'}</b>
              </p>
            </div>
            <div className="field col-12 md:col-6 lg:col-8">
              <label>{t('hazardousWasteReclassificationType')}</label>
              <p>
                <b>{currentCardInfo?.hazardousWasteReclassificationDescription || '-'}</b>
              </p>
            </div>

            {mode === CardSubtype.TRANSFER ? (
              <div className="field col-12">
                <label>{t('wasteMass')}</label>
                <p>
                  <b>{checkCommaSeparator(currentCardInfo?.wasteMass) || '-'}</b>
                </p>
              </div>
            ) : (
              ''
            )}

            <div className="field col-12">
              <label>{t('vehicleRegistrationNumber')}</label>
              <p>
                <b>{currentCardInfo?.vehicleRegNumber || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{mode === CardSubtype.RECEIVE ? t('plannedReceiveTime') : t('plannedTransportTime')}</label>
              <p>
                <b>{currentCardInfo?.plannedTransportTimeDate?.toLocaleString() || '-'}</b>
              </p>
            </div>
          </div>
        </Panel>
        {currentCardInfo?.approvalUser ? (
          <Panel header={t('cardData')} toggleable className="pt-1vh">
            <div className="p-fluid formgrid grid">
              <div className="field col-12 md:col-6 lg:col-8">
                <label>{t('approvalUser')}</label>
                <p>
                  <b>{currentCardInfo?.approvalUser || '-'}</b>
                </p>
              </div>
              <div className="field col-12 md:col-6 lg:col-4">
                <label>{t('cardApprovalTime')}</label>
                <p>
                  <b>{currentCardInfo?.cardApprovalTime?.toLocaleString() || '-'}</b>
                </p>
              </div>
            </div>
          </Panel>
        ) : (
          ''
        )}
        {currentCardInfo?.generatingConfirmationUser ? (
          <Panel
            header={mode === CardSubtype.RECEIVE ? t('realReceiveHour') : t('realTransportHour')}
            toggleable
            className="pt-1vh"
          >
            <div className="p-fluid formgrid grid">
              <div className="field col-8">
                <label>{mode === CardSubtype.RECEIVE ? t('realReceiveHour') : t('realTransportHour')}</label>
                <p>
                  <b>{currentCardInfo?.realTransportTime?.toLocaleString() || '-'}</b>
                </p>
              </div>
              <div className="field col-12 md:col-6 lg:col-8">
                <label>{t('generatingConfirmationUser')}</label>
                <p>
                  <b>{currentCardInfo?.generatingConfirmationUser || '-'}</b>
                </p>
              </div>
              <div className="field col-12 md:col-6 lg:col-4">
                <label>{t('generatingConfirmationTime')}</label>
                <p>
                  <b>{currentCardInfo?.generatingConfirmationTime?.toLocaleString() || '-'}</b>
                </p>
              </div>
            </div>
          </Panel>
        ) : (
          ''
        )}
        {currentCardInfo?.isRevised ? (
          <Panel header={t('adjustmentData')} toggleable className="pt-1vh">
            <div className="p-fluid formgrid grid">
              <div className="field col-12">
                <label>{t('revisedWastemass')}</label>
                <p>
                  <b>{checkCommaSeparator(currentCardInfo?.revisedWasteMass) || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('revisedWasteCodeAndType')}</label>
                <p>
                  <b>{currentCardInfo?.revisedWasteCodeId?.fulltext || '-'}</b>
                </p>
              </div>
              <div className="field col-12 md:col-6 lg:col-8">
                <label>{t('revisedBy')}</label>
                <p>
                  <b>{currentCardInfo?.revisedBy || '-'}</b>
                </p>
              </div>
              <div className="field col-12 md:col-6 lg:col-4">
                <label>{t('revisedAt')}</label>
                <p>
                  <b>{currentCardInfo?.revisedAt?.toLocaleString() || '-'}</b>
                </p>
              </div>
            </div>
          </Panel>
        ) : (
          ''
        )}
        <Panel header={t('additionalInfo')} toggleable className="pt-1vh">
          <div className="p-fluid formgrid grid">
            <div className="field col-12">
              <label>{t('additionalInfo')}</label>
              <p>
                <b>{currentCardInfo?.additionalInfo || '-'}</b>
              </p>
            </div>
          </div>
        </Panel>
        <br />
        <br />
        <br />
      </Dialog>
      <ChangeStatusDialogs.Sender.WITHDRAWN
        visible={isOpenChangeStatusDialog}
        closeDialog={handleCloseChangeStatusDialog}
        item={item}
      />
    </>
  );
};

export default EditGeneratedConfirmationCardDialog;
