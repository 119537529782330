const weighingReportLabels = {
  global: 'global',
  netto: 'netto',
  transaction_type: 'weighingType',
  'contractors.name': 'contractor',
  'vehicles.registration_number': 'registrationNumber',
  number: 'weighingNumber',
  'locations.name': 'location',
  'products.name': 'products',
  driver: 'driver',
  date: 'date',
};

const getTranslatedWeighingReportLabel = (key, t) => {
  return t(weighingReportLabels[key]);
};

const weighingOrderLabels = {
  'weighings.id': 'id',
  transactionType: 'transaction_type',
  location: 'locations.name',
  registrationNumber: 'vehicles.registration_number',
  'contractor.name': 'contractors.name',
  'driver.name': 'drivers.last_name',
  'product.name': 'products.name',
  number: 'number',
};

export { getTranslatedWeighingReportLabel, weighingOrderLabels };
