import React from 'react';
import { Dialog } from 'primereact/dialog';
import { CancelButton } from '../Bdo/bdoUtils';
import { useTranslation } from 'react-i18next';

const UserLimitDialog = ({ isDialogVisible, handleDialogVisible }: any) => {
  const { t } = useTranslation('loginPage');

  const dialogFooter = (
    <div className="grid justify-content-center">
      <CancelButton label={t('close')} onClick={handleDialogVisible} />
    </div>
  );

  return (
    <Dialog
      visible={isDialogVisible}
      breakpoints={{ '896px': '90vw' }}
      header={<i className="pi pi-exclamation-triangle mr-3 text-4xl" />}
      modal
      className="p-fluid min-width-450px"
      footer={dialogFooter}
      onHide={handleDialogVisible}
    >
      <div className="confirmation-content">
        <span>{t('userLoginLimitExceeded')}</span>
      </div>
    </Dialog>
  );
};

export default UserLimitDialog;
