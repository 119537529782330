import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { uiActions } from '../../store/ui-slice';
import { useTranslation } from 'react-i18next';

export const Loader = () => {
  const { t } = useTranslation('sharedComponents');
  const dispatch = useDispatch();
  const { isLoading: isLoaderVisible, hasLoaderTimeout } = useSelector((state) => state.ui);
  const isWeighingRequested = useSelector((state) => state.weighing.weighingMeasurementStatus) === 'REQUESTED';
  const toast = useRef();

  useEffect(() => {
    let loaderTimer;
    if (hasLoaderTimeout && isLoaderVisible) {
      loaderTimer = setTimeout(() => {
        toast.current.show({
          severity: 'error',
          summary: t('loaderError'),
          detail: t('loaderErrorMessage'),
          life: 4000,
        });
        dispatch(uiActions.toggleIsLoading({ isLoading: false }));
      }, 20000);
    } else {
      clearTimeout(loaderTimer);
    }
    return () => clearTimeout(loaderTimer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaderVisible, hasLoaderTimeout, dispatch]);

  return (
    <>
      {isLoaderVisible && (
        <div
          className="flex justify-content-center align-items-center w-full h-full top-0 left-0 fixed"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
          }}
        >
          <Sidebar visible={isLoaderVisible} baseZIndex={1000} showCloseIcon={false} unstyled={true}>
            <ul className="loader">
              <li className="dot-a"></li>
              <li className="dot-b"></li>
              <li className="dot-c"></li>
              <li className="dot-d"></li>
              <li className="dot-e"></li>
              <li className="dot-f"></li>
              <li className="dot-g"></li>
              <li className="dot-h"></li>
              <li className="dot-i"></li>
            </ul>
            <div className="loader-text">{isWeighingRequested ? t('loaderWaiting') : ''}</div>
          </Sidebar>
          <Toast ref={toast} />
        </div>
      )}
    </>
  );
};

export default Loader;
