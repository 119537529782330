import { EdgeDevice } from './edge-device';
import { ConnectionType } from '../enums/connection-type.enum';
import { Station } from './station';

export enum NodeType {
  POS_PRINTER = 'POS_PRINTER',
  WEIGHT_INDICATOR = 'WEIGHT_INDICATOR',
  DISPLAY = 'DISPLAY',
  BARRIER = 'BARRIER',
  CAMERA = 'CAMERA',
  IO = 'IO',
  RFID_READER = 'RFID_READER',
  BARCODE_SCANNER = 'BARCODE_SCANNER',
}

export interface Node {
  nodeModelId: any;
  sourceScaleId: any;
  isUsed: any;
  id: string;
  type: NodeType;
  name: string;
  host?: string;
  port: string;
  nodeModel: any;
  station: Station;
  location: any;
  edgeDevice: EdgeDevice;
  weighingUnit: any;
  division: any;
  maxWeight: string;
  sourceScale: any;
  openPin: string;
  closePin: string;
  statusPin: string;
  addImageData: boolean;
  playSoundSpecifiedWeight: number | string;
  alias?: string;
  selectedCameras?: string[];
  connectionType?: ConnectionType.TCP;
  baudrate?: string;
  testScale?: boolean;
  isAutoWeighing?: boolean;
}

export type UnpreparedNode = Node & { type: NodeType };
