import ProductsSummary from './ProductsSummary';
import { CustomTableField } from '../../_shared/CustomComponents';
import WeighingSeriesProductsSummary from './WeighingSeriesProductsSummary';
import DoubleWeighingSeriesProductsSummary from './DoubleWeighingSeriesProductsSummary';
import MeasurementsList from './MeasurementsList';
import { Weighing } from '../../../types/weighing';

const columnBodyTemplate = (rowData: Weighing, column: any) => {
  return <CustomTableField row={rowData} col={column} />;
};

const productBodyTemplate = (weighing: Weighing, emptyValue: any) => {
  let products = '';
  let measurementsProducts = weighing.measurements.map((measurement) => {
    return measurement.product?.name;
  });
  measurementsProducts = [...new Set(measurementsProducts)];
  measurementsProducts = measurementsProducts.filter((el) => !!el);
  products = measurementsProducts.join(', ');

  if (!products.length && emptyValue) {
    return emptyValue;
  }
  return <>{products}</>;
};

const productMeasurementsBodyTemplate = (measurement: any, emptyValue: any) => {
  if (measurement.product?.name && !measurement.isTare) {
    return measurement.product?.name;
  }

  return emptyValue || null;
};

const additionalFieldsBodyTemplate = (weighing: Weighing, column: any) => {
  const additionalFieldValue = weighing.additionalFields && weighing.additionalFields[column.field];
  const unit = column?.unit ? ` ${column.unit}` : '';

  if (['NUMBER', 'FORMULA'].includes(column.type)) {
    const value = parseFloat(`${additionalFieldValue}`.replace(/,/g, '.').replace(/\s/g, ''));
    if (isNaN(value)) {
      return additionalFieldValue;
    } else {
      return <>{`${additionalFieldValue.replace(/\./g, ',')}${unit}`}</>;
    }
  }
  return <>{additionalFieldValue ? `${additionalFieldValue}${unit}` : ''} </>;
};

const dropdownOptionTemplate = (option: any) => {
  return (
    <div className="item">
      <i className={`mr-6px ${option.icon}`}></i>&#160;
      {option.label}
    </div>
  );
};

const weighingRowExpansionTemplate = (weighing: Weighing, t: (s: string) => string) => {
  return (
    <>
      <strong>{t('rowExpansionTitleMeasurements')}</strong>
      <MeasurementsList measurements={weighing.measurements} columnEmptyValue="--" showAdditionalFields={false} />
      <strong>{t('rowExpansionTitleProducts')}</strong>
      {weighing.weighingMode === 'WEIGHING_SERIES' ? (
        <WeighingSeriesProductsSummary measurements={weighing.measurements} />
      ) : weighing.weighingMode === 'DOUBLE_WEIGHING_SERIES' ? (
        <DoubleWeighingSeriesProductsSummary measurements={weighing.measurements} />
      ) : (
        <ProductsSummary productsSummary={weighing.productsSummary} />
      )}
    </>
  );
};

export {
  productBodyTemplate,
  productMeasurementsBodyTemplate,
  additionalFieldsBodyTemplate,
  weighingRowExpansionTemplate,
  columnBodyTemplate,
  dropdownOptionTemplate,
};
