import axios from 'axios';

import { Filters } from '../../components/AccessControlArrivalNotification/types';
import { PaginationParams } from '../../types/general-types';

class AccessControlArrivalNotificationService {
  async searchArrivalNotifications(filters: Filters, paginationParameters: PaginationParams) {
    const internalFilters = { ...filters, date: ['', ''] };

    const validFromStart = (filters as any)?.validFrom?.[0];
    const validToStart = (filters as any)?.validTo?.[0];

    internalFilters.date = [validFromStart || null, validToStart || null];

    return axios.post('/arrival-notifications/search', { filters: internalFilters, paginationParameters });
  }

  async createArrivalNotification(postData: any) {
    return axios.post(`/arrival-notifications`, postData);
  }
  async updateArrivalNotification(postData: any, id: string) {
    return axios.put(`/arrival-notifications/${id}`, postData);
  }
  async deleteArrivalNotification(id: string) {
    return axios.delete(`/arrival-notifications/${id}`);
  }

  async deleteMultipleArrivalNotifications(ids: string[]) {
    return axios.post(`/arrival-notifications/delete-multiple`, ids);
  }
}

const accessControlArrivalNotificationService = new AccessControlArrivalNotificationService();
export default accessControlArrivalNotificationService;
