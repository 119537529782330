import BdoApiService from '../../../../services/BdoApiService';
import { prepareCorrectDateFormat } from '../../bdoUtils';

const possibleStatuses = {
  sender: {
    PLANNED: ['APPROVED'],
    APPROVED: ['CONFIRMATION_GENERATED', 'WITHDRAWN'],
    CONFIRMATION_GENERATED: ['WITHDRAWN'],
    WITHDRAWN: [],
    REJECTED: ['CORRECT'],
    RECEIVE_CONFIRMATION: [],
    TRANSPORT_CONFIRMATION: [],
  },
  carrier: {
    PLANNED: [],
    APPROVED: ['CONFIRMATION_GENERATED'],
    CONFIRMATION_GENERATED: [],
    WITHDRAWN: [],
    REJECTED: [],
    RECEIVE_CONFIRMATION: ['TRANSPORT_CONFIRMATION'],
    TRANSPORT_CONFIRMATION: [],
  },
  receiver: {
    PLANNED: [],
    APPROVED: [],
    CONFIRMATION_GENERATED: ['REJECTED', 'RECEIVE_CONFIRMATION'],
    WITHDRAWN: [],
    REJECTED: [],
    RECEIVE_CONFIRMATION: ['TRANSPORT_CONFIRMATION'],
    TRANSPORT_CONFIRMATION: [],
  },
};

const changeStatusMethods = {
  sender: {
    APPROVED: ({ kpoId }) => BdoApiService.KPO_setStatusApproved({ kpoId }),
    CONFIRMATION_GENERATED: ({ kpoId }) => BdoApiService.KPO_setStatusGeneratedConfirmationSender({ kpoId }),
    WITHDRAWN: ({ kpoId, remarks }) => BdoApiService.KPO_setStatusWithDrawn({ kpoId, remarks }),
    CORRECT: ({
      kpoId,
      wasteCodeId,
      wasteMass,
      wasteCodeExtended,
      wasteCodeExtendedDescription,
      hazardousWasteReclassification,
      hazardousWasteReclassificationDescription,
    }) =>
      BdoApiService.KPO_reviseCard({
        kpoId,
        wasteCodeId,
        wasteMass,
        wasteCodeExtended,
        wasteCodeExtendedDescription,
        hazardousWasteReclassification,
        hazardousWasteReclassificationDescription,
      }),
  },
  carrier: {
    CONFIRMATION_GENERATED: ({ kpoId, vehicleRegNumber, realTransportDate, realTransportTime }) =>
      BdoApiService.KPO_setStatusGeneratedConfirmationCarrier({
        kpoId,
        vehicleRegNumber,
        realTransportDate: prepareCorrectDateFormat(realTransportDate, realTransportTime),
        realTransportTime:
          `0${realTransportTime.getHours()}`.slice(-2) + ':' + `0${realTransportTime.getMinutes()}`.slice(-2),
      }),
    TRANSPORT_CONFIRMATION: ({ kpoId }) => BdoApiService.KPO_setStatusTransportConfirmation({ kpoId }),
  },
  receiver: {
    REJECTED: ({ kpoId, remarks }) => BdoApiService.KPO_setStatusRejected({ kpoId, remarks }),
    RECEIVE_CONFIRMATION: ({ kpoId, correctedWasteMass, remarks }) =>
      BdoApiService.KPO_setStatusReceiveConfirmation({ kpoId, correctedWasteMass, remarks }),
  },
};

const statusesFilterOptions = (t) => {
  return [
    {
      label: t('planned'),
      value: 'PLANNED',
    },
    {
      label: t('approved'),
      value: 'APPROVED',
    },
    {
      label: t('confirmationGenerated'),
      value: 'CONFIRMATION_GENERATED',
    },
    {
      label: t('receiveConfirmation'),
      value: 'RECEIVE_CONFIRMATION',
    },
    {
      label: t('transportConfirmation'),
      value: 'TRANSPORT_CONFIRMATION',
    },
    {
      label: t('withdrawn'),
      value: 'WITHDRAWN',
    },
    {
      label: t('rejected'),
      value: 'REJECTED',
    },
  ];
};

const getStatuses = async () => {
  return BdoApiService.getStatuses().then((response) => {
    return response.data?.map((el) => {
      return {
        label: el.Name,
        value: el.CodeName,
      };
    });
  });
};

const statusUtils = {
  possibleStatuses,
  changeStatusMethods,
  statusesFilterOptions,
  getStatuses,
};

export default statusUtils;
