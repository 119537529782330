import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import useModulesConfiguration from '../../../../../utils/useModulesConfiguration';
import { useTranslation } from 'react-i18next';

interface BdoCardsSectionProps {
  formikInstance: any;
  handleChangeField: any;
}

const BdoCardsSection = ({ formikInstance, handleChangeField }: BdoCardsSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationWeighing');
  const modulesConfiguration = useModulesConfiguration();

  if (modulesConfiguration.bdo === 'enabled') {
    return (
      <Fieldset legend={t('bdoCardsSectionHeader')} className="mt-1vh">
        <div className="p-fluid formgrid grid">
          <div className="field-checkbox col-12">
            <Checkbox
              id="weighingCarrierAutocomplete"
              checked={formikInstance.values.weighingCarrierAutocomplete}
              onChange={(e) => handleChangeField(e, 'checkbox')}
            ></Checkbox>
            <label htmlFor="weighingCarrierAutocomplete" className="checkbox-label">
              {t('weighingCarrierAutocomplete')}
            </label>
          </div>
          <div className="field-checkbox col-12">
            <Checkbox
              id="weighingReceiverAutocomplete"
              checked={formikInstance.values.weighingReceiverAutocomplete}
              onChange={(e) => handleChangeField(e, 'checkbox')}
            ></Checkbox>
            <label htmlFor="weighingReceiverAutocomplete" className="checkbox-label">
              {t('weighingReceiverAutocomplete')}
            </label>
          </div>
        </div>
      </Fieldset>
    );
  }

  return <></>;
};

export default BdoCardsSection;
