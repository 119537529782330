import { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import deploymentsService from '../../../../services/ScaleoApiServices/DeploymentsService';
import { useTranslation } from 'react-i18next';
import CustomDivider from '../../CustomDivider';

interface DowntimeInfoDialogProps {
  item: {
    id: string;
    version?: string;
    startDate: Date;
    endDate: Date;
    description?: string;
  };
}

const DowntimeInfoDialog = ({ item }: DowntimeInfoDialogProps) => {
  const [dialogVisible, setDialogVisible] = useState(false);

  const { t } = useTranslation('sharedComponents');

  useEffect(() => {
    if (item) setDialogVisible(true);
  }, [item]);

  const handleClickSubmit = () => {
    void deploymentsService.confirmDeployment(item?.id).then(() => {
      setDialogVisible(false);
    });
  };

  const dialogFooter = (
    <Button type="submit" label={t('understand')} icon="pi pi-check" text onClick={handleClickSubmit} />
  );

  return (
    <Dialog
      visible={dialogVisible}
      header={t('dialogHeader')}
      modal
      className="p-fluid w-40vw"
      footer={dialogFooter}
      closable={false}
      breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      onHide={() => undefined}
    >
      <div className="col-12 pb-0">
        <div className="field word-break-all">
          {!!item?.version && (
            <>
              {t('dialogVersion')}: <b>{item.version}</b>
            </>
          )}
          {!!item?.description && (
            <>
              <CustomDivider />
              {item.description.split('\n').map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};
export default DowntimeInfoDialog;
