import { useState, useEffect } from 'react';

const useReceiptFieldsOrderData = (additionalFields: any, fieldsOrder: any) => {
  const getInitialState = () => {
    const includeValues = (items: any) =>
      items?.map((item: any) => {
        const value = fieldsOrder?.find((el: any) => el.id === item.id)?.value || null;
        return { ...item, value };
      });

    const _additionalFields = additionalFields?.map((f: any) => {
      return { id: f.id, name: f.name, value: null };
    });
    return includeValues([..._additionalFields]);
  };

  const [data, setData] = useState(getInitialState);

  useEffect(() => {
    setData(getInitialState());
  }, [additionalFields]);

  return { data, setData };
};

export default useReceiptFieldsOrderData;
