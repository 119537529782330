import { useTranslation } from 'react-i18next';
import '../../../DataTable.css';

import { ConnectionType } from '../../../../enums/connection-type.enum';
import { InputText } from 'primereact/inputtext';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import { CustomAutoComplete } from '../../../_shared/CustomComponents';
import { ManagementNode } from '../types';

interface RfidReaderProps {
  formik: any;
  data: ManagementNode;
}

const RfidReaderFields = ({ formik, data }: RfidReaderProps) => {
  const { t } = useTranslation('managementNodes');

  return (
    <>
      <div className="field">
        <label htmlFor="name">{t('name')}</label>
        <InputText id="name" value={formik.values.name as any} onChange={formik.handleChange} autoFocus />
        <FormErrorMessage fieldName="name" formikInstance={formik} />
      </div>

      <div className="field">
        <label htmlFor="station">{t('station')}</label>
        <CustomAutoComplete
          id="station"
          value={formik.values.station}
          handler={formik.handleChange}
          source={data.stations}
        />
        <FormErrorMessage fieldName="station" formikInstance={formik} />
      </div>

      <div className="field">
        <label htmlFor="nodeModel">{t('nodeModel')}</label>
        <CustomAutoComplete
          id="nodeModel"
          value={formik.values.nodeModel}
          handler={formik.handleChange}
          source={data.nodeModelsByNodeType[formik.values.type]}
          lookupField="name"
        />
        <FormErrorMessage fieldName="nodeModel" formikInstance={formik} />
      </div>

      {formik.values.connectionType === ConnectionType.TCP && (
        <div className="field">
          <label htmlFor="host">{t('host')}</label>
          <InputText id="host" value={formik.values.host!} onChange={formik.handleChange} />
          <FormErrorMessage fieldName="host" formikInstance={formik} />
        </div>
      )}

      <div className="field">
        <label htmlFor="port">{t('port')}</label>
        <InputText id="port" value={formik.values.port!} onChange={formik.handleChange} />
        <FormErrorMessage fieldName="port" formikInstance={formik} />
      </div>
    </>
  );
};

export default RfidReaderFields;
