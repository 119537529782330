import React from 'react';
import { useSelector } from 'react-redux';
import useTransactionType from '../../../../../../utils/useTransactionType';
import { formatDateTime } from '../../../../../../utils/formatUtils';
import {
  WeighingSummaryDecimalFieldTemplate,
  WeighingSummaryTextFieldTemplate,
} from '../../../../components/WeighingSummaryFieldTemplates';
import { getTranslatedLabel, weighingModeLabels } from '../../../../../../utils/labels';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../../../types/reducer-state';
import { Weighing } from '../../../../../../types/weighing';

interface ControlWeighingSummaryDataProps {
  weighing: Weighing;
}

const ControlWeighingSummaryData = ({ weighing }: ControlWeighingSummaryDataProps) => {
  const { t } = useTranslation('weighingHandlingWeighings');
  const { t: t1 } = useTranslation('utils');

  const unit = useSelector((state: ReducerState) => state.appConfig.unit);
  const { getTransactionTypeLabel } = useTransactionType();

  return (
    <>
      <div className="lg:col-6 md:col-12">
        <div className="formgrid grid">
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryControlWeighingNumber')}
            value={weighing?.number as string}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryControlDate')}
            value={formatDateTime(weighing?.measurements[0].timestamp)}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('hw-open_weighings_list-summary-control-user')}
            value={weighing.measurements[0].user.name}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryControlWeighingMode')}
            value={getTranslatedLabel(weighingModeLabels, weighing?.weighingMode, t1)}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryControlWeighingType')}
            value={getTransactionTypeLabel(weighing?.transactionType)}
          />
          <WeighingSummaryDecimalFieldTemplate
            label={t('openWeighingsListSummaryControlIndication')}
            value={weighing.measurements[0].indication}
            unit={unit}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryControlAlibi')}
            value={weighing?.measurements[0].alibi}
          />
        </div>
      </div>
      <div className="lg:col-6 md:col-12">
        <div className="formgrid grid">
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryControlRegistrationNumber')}
            value={weighing?.vehicle.registrationNumber}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryControlProduct')}
            value={weighing?.product?.name}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryControlDriver')}
            value={weighing?.driver?.name}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryControlContractor')}
            value={weighing?.contractor?.name}
          />
        </div>
      </div>
    </>
  );
};

export default ControlWeighingSummaryData;
