import { Message } from 'primereact/message';
import { Trailer } from '../../../../../types/trailer';
import { Vehicle } from '../../../../../types/weighing';
import { Node, NodeType } from '../../../../../types/node';
import { getArraysCommonElements } from '../../../../../utils/additionalFieldsUtilsForWeighingDialog';

export const renderNewVehicleInfoMsg = (vehicle: Vehicle) => {
  return !!vehicle?.isNew && <Message severity="info" text="Nowy pojazd" />;
};

export const getVehicle = (registrationNumber: string, vehicles: Vehicle[]) => {
  const existingVehicleIndex = vehicles
    .map((vehicle) => vehicle.registrationNumber)
    .indexOf(registrationNumber.replace(/\s/g, ''));

  if (existingVehicleIndex > -1) {
    return vehicles[existingVehicleIndex];
  }
  return null;
};

export const getTrailer = (registrationNumber: string, trailers: Trailer[]) => {
  const existingTrailerIndex = trailers
    .map((trailer) => trailer.registrationNumber)
    .indexOf(registrationNumber.replace(/\s/g, ''));

  if (existingTrailerIndex > -1) {
    return trailers[existingTrailerIndex];
  }
  return null;
};

export const renderNewTrailerInfoMsg = (trailer: { isNew: boolean }) => {
  return !!trailer?.isNew && <Message severity="info" text="Nowa naczepa" />;
};

export const posPrinterNode = (nodes: Node[]) => {
  return nodes?.find((node) => {
    return node.type === NodeType.POS_PRINTER;
  });
};

export const checkIfIndicationOutOfRange = (
  selectedScale: Node,
  currentIndication: number,
  manualIndication?: number,
) => {
  const MINIMAL_START_WEIGHT_VALUE = 0.001;

  const value = Number(manualIndication ? manualIndication : currentIndication);

  const minWeightThreshold = Number(selectedScale?.division) * 20;
  const maxWeighingThreshold = Number(selectedScale?.maxWeight) + 9 * Number(selectedScale?.division);

  const minWeight = minWeightThreshold > value && value >= MINIMAL_START_WEIGHT_VALUE;
  const maxWeight = maxWeighingThreshold < value;

  return { minWeight, maxWeight };
};

export const getRestAdditionalFieldsValues = (productId: string | null, additionalFields: any) => {
  const restAdditionalFields = additionalFields?.filter(
    (a: any) => productId && getArraysCommonElements(a.productIds, [productId]).length === 0,
  );
  const reducedWeighingRestAdditionalFields = restAdditionalFields
    .filter((a: any) => a.destination === 'WEIGHING' && a.baseDefaultValue !== undefined && a.baseDefaultValue !== null)
    .reduce((acc: any, curr: any) => (acc = { ...acc, [curr.id]: curr.baseDefaultValue }), {});
  const reducedMeasurementRestAdditionalFields = restAdditionalFields
    .filter(
      (a: any) => a.destination === 'MEASUREMENT' && a.baseDefaultValue !== undefined && a.baseDefaultValue !== null,
    )
    .reduce((acc: any, curr: any) => (acc = { ...acc, [curr.id]: curr.baseDefaultValue }), {});

  return {
    reducedMeasurementRestAdditionalFields,
    reducedWeighingRestAdditionalFields,
  };
};
