import { Fieldset } from 'primereact/fieldset';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import { TFunction } from 'i18next';
import { Message } from 'primereact/message';
import { InputText } from 'primereact/inputtext';

interface BdoConfigurationGeneralSectionProps {
  formik: any;
  handleChangeField: (e: any, type: string) => void;
  isCorrectConfig: boolean | null;
  t: TFunction;
}

const BdoConfigurationAuthorizationSection = ({
  formik,
  handleChangeField,
  isCorrectConfig,
  t,
}: BdoConfigurationGeneralSectionProps) => {
  return (
    <>
      <Fieldset legend={t('headerAuthorization')} className="w-full">
        <div className="grid p-fluid pt-1vh">
          <div className="col-12">
            <label htmlFor="clientId" className="col-12 md-1">
              {t('clientId')}
            </label>
            <div className="col-12 md:col-11">
              <InputText
                id="clientId"
                type="password"
                value={formik.values.clientId}
                onChange={(e) => handleChangeField(e, 'text')}
                autoFocus
              />
              <FormErrorMessage fieldName="clientId" formikInstance={formik} />
            </div>
          </div>

          <div className="col-12">
            <label htmlFor="clientSecret" className="col-12 md:col-1">
              {t('clientSecret')}
            </label>
            <div className="col-12 md:col-11">
              <InputText
                id="clientSecret"
                type="password"
                value={formik.values.clientSecret}
                onChange={(e) => handleChangeField(e, 'text')}
                autoFocus
              />
              <FormErrorMessage fieldName="clientSecret" formikInstance={formik} />
            </div>
          </div>

          {(formik.values.isChanged || !formik.values.isLogged) && isCorrectConfig === false && (
            <div className="field grid">
              <span className="col-12 md:col-12">
                <Message severity="error" text={t('errorMessage')} />
              </span>
            </div>
          )}
        </div>
      </Fieldset>
    </>
  );
};

export default BdoConfigurationAuthorizationSection;
