import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import DataTableFilterElements from '../_shared/DataTableFilterElements';
import getPaginatorTemplate from '../../utils/getPaginatorTemplate';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../types/reducer-state';
import { columnBodyTemplate } from './Templates';
import { formatStringToDate } from '../../utils/formatUtils';
import { PaginationParams, PaginatorState } from '../../types/general-types';
import { AccessControlEventLogType, Filters } from './types';
import { changeFilter, changeRegistrationNumber, fetchData, handleClickClearFilters, handleSort } from './helpers';
import { isModulesEnabled } from '../../utils/modulesUtils';

const INIT_FILTERS: Filters = {
  date: null,
  registrationNumber: null,
  global: null,
};

const INIT_PAGINATION_PARAMETERS: PaginationParams = {
  order: {
    isAscending: false,
    orderColumn: 'date',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const INIT_PAGINATOR_STATE: PaginatorState = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

const ColumnAny = Column as any;

const AccessControlEventLog = () => {
  const { t } = useTranslation('accessControlEventLog');

  const dt = useRef(null);
  const [filters, setFilters] = useState<Filters>(INIT_FILTERS);
  const [paginationParameters, setPaginationParameters] = useState<PaginationParams>(INIT_PAGINATION_PARAMETERS);
  const [paginatorState, setPaginatorState] = useState<PaginatorState>(INIT_PAGINATOR_STATE);
  const [listOfLogs, setListOfLogs] = useState<AccessControlEventLogType[]>([]);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const [registrationNumbersList, setRegistrationNumberList] = useState<string[]>([]);
  const [isOpenSnapshotsDialog, setIsOpenSnapshotsDialog] = useState(false);
  const [clickedRowId, setClickedRowId] = useState<number | null>(null);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  useEffect(() => {
    fetchData(
      filters,
      paginationParameters,
      paginatorState,
      setRegistrationNumberList,
      setListOfLogs,
      setPaginatorState,
    );
  }, [loggedUserContext.currentCustomer?.id, fetchData, filters, paginationParameters]);

  const handleOpenSnapshots = (rowDataId: number) => {
    setClickedRowId(rowDataId);
    setIsOpenSnapshotsDialog(true);
  };

  const header = (
    <div className="table-header text-2xl p-2">
      {t('eventsLogHeader')} &#160;&#160;
      <div className="flex">
        <Button
          label={t('filter')}
          icon="pi pi-filter-slash"
          outlined
          className="mr-2"
          onClick={() => handleClickClearFilters(setFilters)}
        />
        <span>
          <DataTableFilterElements.Text
            initialValue={filters['global']}
            onChangeFilter={(value: string) => setFilters({ ...filters, global: value })}
            name={'global'}
            placeholder={t('searchPlaceholder')}
            isClearButtonVisible={filters['global'] !== '' ? true : false}
            isSearchIcon
          />
        </span>
      </div>
    </div>
  );

  const actionBodyTemplate = (rowData: AccessControlEventLogType, { rowIndex }: any) => (
    <ImagePreview rowData={rowData} rowIndex={rowIndex} />
  );

  const ImagePreview = (props: any) => {
    const { rowData } = props;

    return (
      <div className="actions flex justify-content-end">
        {isModulesEnabled(['CAMERAS'], loggedUserContext.currentCustomer?.subscriptionModules) && (
          <button type="button" className="p-link mr-5" onClick={() => handleOpenSnapshots(rowData?.id)}>
            <i className="pi pi-fw pi-images"></i>
          </button>
        )}
      </div>
    );
  };

  const DataTableComponent = () => {
    return (
      <>
        <DataTable
          responsiveLayout="scroll"
          ref={dt}
          value={listOfLogs}
          header={header}
          className="p-datatable-responsive"
          dataKey="id"
          sortOrder={paginationParameters.order.isAscending ? 1 : -1}
          sortField={paginationParameters.order.orderColumn}
          removableSort
          rowHover
          rows={paginatorState.rows}
          paginator
          paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState) as any}
          emptyMessage={t('noData')}
          onSort={(e: any) => handleSort(e, paginationParameters, setPaginationParameters)}
          stripedRows
          filterDisplay="row"
        >
          <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
          <ColumnAny
            field="date"
            header={t('eventsLogDate')}
            fieldsType={'datetime'}
            body={columnBodyTemplate}
            sortable
            filter
            showFilterMenu={false}
            filterElement={
              <DataTableFilterElements.Date
                initialValue={filters.date
                  ?.filter((f) => f)
                  .map((f: any) => {
                    return formatStringToDate(f);
                  })}
                onChangeFilter={(value: any, name: string) => changeFilter(value, name, filters, setFilters)}
                name={'date'}
                placeholder={t('filterPlaceholder')}
              />
            }
          />
          <Column
            field="registrationNumber"
            header={t('registrationNumber')}
            filter
            showFilterMenu={false}
            filterElement={
              <DataTableFilterElements.Dropdown
                initialValue={(filters as Filters)?.registrationNumber}
                options={registrationNumbersList}
                placeholder={t('registrationNumberPlaceholder')}
                onChangeFilter={(e: any) => changeRegistrationNumber(e, filters, setFilters)}
                name={filters.registrationNumber}
              />
            }
          />
          <Column field="event" header={t('event')} />
          <Column field="userName" header={t('userName')} />
          <Column body={actionBodyTemplate} className="w-150px text-right" />
        </DataTable>
      </>
    );
  };

  return {
    listOfLogs,
    isOpenSnapshotsDialog,
    setIsOpenSnapshotsDialog,
    clickedRowId,
    DataTableComponent,
  };
};

export default AccessControlEventLog;
