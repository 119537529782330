import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useTranslation, Trans } from 'react-i18next';

interface DeleteWeighingDialogProps {
  visible: boolean;
  onClose: () => void;
  onDelete: () => void;
  weighingNumber: any;
}

const DeleteWeighingDialog = ({ visible, onClose, onDelete, weighingNumber }: DeleteWeighingDialogProps) => {
  const { t } = useTranslation('weighingComponents');

  const deleteWeighingDialogFooter = (
    <>
      <Button type="button" label={t('no')} icon="pi pi-times" text onClick={onClose} />
      <Button type="button" label={t('yes')} icon="pi pi-check" text onClick={onDelete} />
    </>
  );

  return (
    <Dialog
      visible={visible}
      className="dialog-md"
      header={t('confirm')}
      modal
      footer={deleteWeighingDialogFooter}
      onHide={onClose}
    >
      <div className="confirmation-content">
        <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
        {weighingNumber ? (
          <span>
            <Trans t={t} i18nKey="question" values={{ weighingNumber }} />
          </span>
        ) : (
          <span>{t('questionMultiple')}</span>
        )}
      </div>
    </Dialog>
  );
};

export default DeleteWeighingDialog;
