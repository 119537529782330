import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { TFunction } from 'i18next';
import { ReceiverUpdatedStatus } from '../../../../enums/receiver-updated-status.enum';

interface BdoConfigurationGeneralSectionProps {
  formik: any;
  handleChangeField: (e: any, type: string) => void;

  t: TFunction;
}

const BdoConfigurationKpokReceiverSettingsSection = ({
  formik,
  handleChangeField,
  t,
}: BdoConfigurationGeneralSectionProps) => {
  return (
    <>
      <Fieldset
        legend={t('receiverSettingsSectionHeader') + ' (KPOK - ODBIÓR ODPADÓW OD WŁAŚCICIELI NIERUCHOMOŚCI)'}
        className="mt-1vh"
      >
        <div className="grid pt-1vh">
          <>
            <div className="grid pt-1vh">
              <div className="col-6 lg:col-12">
                <span className="field-checkbox md:col-12">
                  <Checkbox
                    id="receiverKpokReceiveModuleEnabled"
                    checked={formik.values.receiverKpokReceiveModuleEnabled}
                    onChange={(e) => {
                      handleChangeField(e, 'checkbox');
                      if (!e.target.value) {
                        formik.setFieldValue('receiverKpokReceiveUpdatedCardStatus', null);
                        formik.setFieldValue('receiverKpokReceiveAddMassToCardRemarks', null);
                      }
                    }}
                  ></Checkbox>
                  <label htmlFor="receiverKpokReceiveModuleEnabled" className="checkbox-label">
                    {t('updateKpokReceiveStatusForWeighing')}
                  </label>
                </span>
                {formik.values.receiverKpokReceiveModuleEnabled && (
                  <>
                    <div className="field col-6 md:col-4">
                      <label htmlFor="receiverKpokReceiveUpdatedCardStatus">{t('status')}</label>
                      <br />
                      <Dropdown
                        id="receiverKpokReceiveUpdatedCardStatus"
                        value={formik.values.receiverKpokReceiveUpdatedCardStatus}
                        onChange={(e) => formik.setFieldValue('receiverKpokReceiveUpdatedCardStatus', e.value)}
                        options={[
                          { value: ReceiverUpdatedStatus.RECEIVE_CONFIRMATION, name: t('receiveConfirmation') },
                          { value: ReceiverUpdatedStatus.REJECT, name: t('reject') },
                        ]}
                        optionValue="value"
                        optionLabel="name"
                      />
                    </div>
                    {(formik.values.receiverKpokReceiveUpdatedCardStatus ===
                      ReceiverUpdatedStatus.RECEIVE_CONFIRMATION ||
                      formik.values.receiverKpokReceiveUpdatedCardStatus === ReceiverUpdatedStatus.REJECT) && (
                      <span className="field-checkbox md:col-12">
                        <Checkbox
                          id="receiverKpokReceiveAddMassToCardRemarks"
                          checked={formik.values.receiverKpokReceiveAddMassToCardRemarks}
                          onChange={(e) => {
                            handleChangeField(e, 'checkbox');
                          }}
                        />
                        <label htmlFor="receiverKpokReceiveAddMassToCardRemarks" className="checkbox-label">
                          {t('receiverAddMassToCardRemarks')}
                        </label>
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        </div>
      </Fieldset>
    </>
  );
};

export default BdoConfigurationKpokReceiverSettingsSection;
