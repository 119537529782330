import React from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import LanguageSelectorUnauthorized from '../components/_shared/LanguageSelectorUnauthorized';
import { useTranslation } from 'react-i18next';

export const CreateSuccessful = () => {
  const { t } = useTranslation('createSuccessfulPage');

  const history = useHistory();

  return (
    <div className="login-body">
      <div className="card login-panel p-fluid">
        <LanguageSelectorUnauthorized visible={false} />
        <div className="login-panel-content">
          <div className="grid">
            <div className="col-12 logo-container">
              <img src="assets/layout/images/logo_scaleo.png" alt={t('logoAlt')} className="w-full" />
            </div>
            <div className="col-12">
              <span className="guest-sign-in">{t('header')}</span>
            </div>
          </div>
          <div className="grid rememberme-container">
            <div className="col-8">
              <Button
                label={t('submitButton')}
                icon="pi pi-arrow-left"
                onClick={() => {
                  history.push('/login');
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
