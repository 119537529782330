import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface AutocompleteDataButtonProps {
  isCombine: boolean;
  onClick: (e: any) => void;
}

const AutocompleteDataButton = ({ isCombine, onClick }: AutocompleteDataButtonProps) => {
  const { t } = useTranslation('weighingWeighingList');

  const AUTOCOMPLETE_DATA_BUTTONS = [
    {
      label: t('weighingDialogAutocompleteDataButton1'),
      value: 0,
    },
    {
      label: t('weighingDialogAutocompleteDataButton2'),
      value: 1,
    },
  ];
  const renderButton = (button: any) => (
    <Button
      label={button.label}
      className="mr-4 col p-2"
      onClick={() => onClick(button.value)}
      key={button.label}
      type="button"
    />
  );

  if (!!isCombine) {
    return (
      <div className="field col-12">
        <label htmlFor="vehicle" className="p-2">
          {t('weighingDialogUseWeighingData')}
        </label>
        <div className="col-5 grid my-0">{AUTOCOMPLETE_DATA_BUTTONS.map(renderButton)}</div>
      </div>
    );
  }

  return <></>;
};

export default AutocompleteDataButton;
