import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { appConfigActions } from '../../../../store/appConfig-slice';
import { ReducerState } from '../../../../types/reducer-state';
import formChangeFieldMethod from '../../../../utils/formChangeFieldMethod';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import useBdoConfigurationData from '../../../../hooks/BdoConfiguration/useBdoConfigurationData';
import useBdoConfigurationForm from '../../../../hooks/BdoConfiguration/useBdoConfigurationForm';
import BdoConfigurationGeneralSection from './BdoConfigurationGeneralSection';
import BdoConfigurationKpoReceiverSettingsSection from './BdoConfigurationKpoReceiverSettingsSection';
import BdoConfigurationKpokReceiverSettingsSection from './BdoConfigurationKpokReceiverSettingsSection';
import BdoConfigurationKpokTransferSettingsSection from './BdoConfigurationKpokTransferSettingsSection';

const BdoConfiguration = () => {
  const { t } = useTranslation('managementSystemConfigurationBdo');

  const dispatch = useDispatch();
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const confirmationOfWindowChange = useSelector((state: ReducerState) => state.appConfig.confirmationOfWindowChange);

  const [isCorrectConfig, setIsCorrectConfig] = useState<boolean | null>(null);

  const { data: bdoConfigurationData, fetchData } = useBdoConfigurationData();

  const { formik } = useBdoConfigurationForm(bdoConfigurationData, fetchData, dispatch, setIsCorrectConfig, t);

  useEffect(() => {
    fetchData();
  }, [loggedUserContext.currentCustomer?.id]);

  useEffect(() => {
    const changes = JSON.stringify(formik.values) !== JSON.stringify(bdoConfigurationData);
    if (confirmationOfWindowChange.required !== changes) {
      dispatch(
        appConfigActions.setConfirmationOfWindowChange({
          ...confirmationOfWindowChange,
          required: changes,
          saveMethod: formik.submitForm,
        }),
      );
    }
  }, [formik.dirty]);

  useEffect(() => {
    if (!formik.values.moduleEnabled) formik.values.isChanged = false;
    if (formik.values.moduleEnabled && !formik.values.isLogged) formik.values.isChanged = true;
  }, [formik.values]);

  const handleChangeField = (e: any, type: string) => {
    formChangeFieldMethod(e, type, formik);
  };

  const handleClickCancel = () => {
    formik.resetForm();
  };

  const handleClickSubmit = () => {
    formik.submitForm().then(() => {
      dispatch(appConfigActions.setConfirmationOfWindowChange({ ...confirmationOfWindowChange, required: false }));
    });
  };

  return (
    <div className="card pb-70px">
      <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
        <form onSubmit={formik.handleSubmit}>
          <BdoConfigurationGeneralSection
            formik={formik}
            handleChangeField={handleChangeField}
            isCorrectConfig={isCorrectConfig}
            t={t}
          />
          {formik.values.moduleEnabled && (
            <>
              <BdoConfigurationKpoReceiverSettingsSection formik={formik} handleChangeField={handleChangeField} t={t} />

              <BdoConfigurationKpokReceiverSettingsSection
                formik={formik}
                handleChangeField={handleChangeField}
                t={t}
              />

              <BdoConfigurationKpokTransferSettingsSection
                formik={formik}
                handleChangeField={handleChangeField}
                t={t}
              />
            </>
          )}
          <div>
            <div
              className="flex justify-content-end w-full p-ai-end fixed bg-white bottom-0px right-0px pt-10px pr-40px pb-10px pl-0px"
              style={{
                boxShadow: '0px 10px 15px black',
              }}
            >
              <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={handleClickCancel} />
              <Button type="button" label={t('saveButton')} icon="pi pi-check" text onClick={handleClickSubmit} />
            </div>
          </div>
        </form>
      </FormErrorMessageScroller>
    </div>
  );
};

export default BdoConfiguration;
