import { createSlice } from '@reduxjs/toolkit';
import { UserContext, UserSlice } from './types/user-slice';

const initialUserContext: UserContext = {
  firstName: null,
  lastName: null,
  role: null,
  currentCustomer: null,
  currentLocationId: null,
  customers: null,
  scales: null,
  nodes: null,
  permissions: null,
  lastUsedScaleId: null,
  appLanguage: 'pl-PL',
  locations: null,
  weighingConfiguration: {
    weighingMode: [],
    addTrailerRegistrationNumbersSupport: false,
  },
  weighingListColumns: [],
};

const initialState: UserSlice = {
  context: { ...initialUserContext },
  loggedUserCustomers: null,
  loggedUser: null,
  filtersState: {
    weighings: {},
    vehicles: {},
    contractors: {},
    products: {},
    drivers: {},
    edgeDevices: {},
    nodes: {},
    containers: {},
    trailers: {},
    stations: {},
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState: { ...initialState },
  reducers: {
    resetState(_state: UserSlice) {
      _state = { ...initialState };
    },
    setLoggedUser(state: UserSlice, value) {
      state.loggedUser = value.payload;
    },
    setLoggedUserCustomers(state: UserSlice, value) {
      state.loggedUserCustomers = value.payload;
    },
    setLoggedUserContext(state: UserSlice, value) {
      state.context = { ...initialUserContext, ...value.payload };
    },
    setCurrentLocationId(state: UserSlice, value) {
      state.context.currentLocationId = value.payload;
    },
    setLastUsedScaleId(state: UserSlice, value) {
      state.context.lastUsedScaleId = value.payload;
    },
    shouldRefreshLoggedUserContext(state: UserSlice, value) {
      state.shouldRefreshLoggedUserContext = value.payload;
    },
    shouldRefreshSystemConfiguration(state: UserSlice, value) {
      state.shouldRefreshSystemConfiguration = value.payload;
    },
    setVehiclesFilters(state: UserSlice, value) {
      state.filtersState.vehicles = value.payload;
    },
    setEdgeDevicesFilters(state: UserSlice, value) {
      state.filtersState.edgeDevices = value.payload;
    },
    setNodesFilters(state: UserSlice, value) {
      state.filtersState.nodes = value.payload;
    },
    setContractorsFilters(state: UserSlice, value) {
      state.filtersState.contractors = value.payload;
    },
    setProductsFilters(state: UserSlice, value) {
      state.filtersState.products = value.payload;
    },
    setDriversFilters(state: UserSlice, value) {
      state.filtersState.drivers = value.payload;
    },
    setWeighingsFilters(state: UserSlice, value) {
      state.filtersState.weighings = value.payload;
    },
    setWeighingsAdditionalFieldsFilters(state: UserSlice, value) {
      state.filtersState.weighings.additional_field = {
        ...state.filtersState.weighings.additional_field,
        [value.payload.name]: value.payload.value,
      };
    },
    setContainersFilters(state: UserSlice, value) {
      state.filtersState.containers = value.payload;
    },
    setTrailersFilters(state: UserSlice, value) {
      state.filtersState.trailers = value.payload;
    },
    setStationsFilters(state: UserSlice, value) {
      state.filtersState.stations = value.payload;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
