const isIn = (currentMode, acceptableValues) => {
  if (acceptableValues) {
    if (Array.isArray(acceptableValues)) {
      return !!acceptableValues.includes(currentMode);
    } else if (typeof acceptableValues === 'string') {
      return acceptableValues === currentMode;
    }
  }
  return false;
};

export default isIn;
