import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import logosService from '../../services/ScaleoApiServices/LogosService';
import weighingConfigurationService from '../../services/ScaleoApiServices/WeighingConfigurationService';
import { ReducerState } from '../../types/reducer-state';

const INIT_FORM_STATE = {
  receiptOrientation: null,
  receiptFormat: null,
  receiptFont: null,
  receiptMargin: null,
  receiptAutoSend: null,
  receiptSendToDrivers: null,
  receiptSendToContractors: null,
  receiptAddPhotos: null,
  receiptEmails: [],
  shouldIncludeReceiptCustomFooter: false,
  receiptCustomFooter: '',
  receiptFieldsOrder: [],
  additionalFields: [],
  files: [],
};

export const usePrintsConfigurationData = () => {
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const [stringifiedInitFormValues, setStringifiedInitFormValues] = useState('[]');

  const [fileBuffer, setFileBuffer] = useState<Buffer | null>(null);
  const [logo, setLogo] = useState<string | null>(null);

  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const fetchAPI = useCallback(async () => {
    void Promise.all([logosService.getLogoUrl(), weighingConfigurationService.getConfigurationReceipts()]).then(
      (response) => {
        setLogo(response[0].data);

        if (response[1].data) {
          const isReceiptCustomFooter =
            response[1].data.receiptCustomFooter && response[1].data.receiptCustomFooter.trim() !== '';

          const responseDataValues = {
            receiptOrientation: response[1].data.receiptOrientation,
            receiptFormat: response[1].data.receiptFormat,
            receiptFont: response[1].data.receiptFont,
            receiptMargin: response[1].data.receiptMargin,
            receiptAutoSend: response[1].data.receiptAutoSend,
            receiptSendToDrivers: response[1].data.receiptSendToDrivers,
            receiptSendToContractors: response[1].data.receiptSendToContractors,
            receiptAddPhotos: response[1].data.receiptAddPhotos,
            receiptEmails: response[1].data.receiptEmails,
            shouldIncludeReceiptCustomFooter: isReceiptCustomFooter,
            receiptCustomFooter: response[1].data.receiptCustomFooter,
            receiptFieldsOrder: response[1].data.receiptFieldsOrder,
            additionalFields: response[1].data.additionalFields,
          };

          setInitFormValues({ ...initFormValues, ...responseDataValues });
          setStringifiedInitFormValues(JSON.stringify({ ...initFormValues, ...responseDataValues }));
        }
      },
    );
  }, [initFormValues]);

  useEffect(() => {
    fetchAPI();
  }, [loggedUserContext.currentCustomer?.id]);

  return {
    fetchAPI,
    stringifiedInitFormValues,
    fileBuffer,
    setFileBuffer,
    logo,
    initFormValues,
  };
};
