import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { weighingActions } from '../../../store/weighing-slice';
import contractorsService from '../../../services/ScaleoApiServices/ContractorsService';
import driversService from '../../../services/ScaleoApiServices/DriversService';
import vehiclesService from '../../../services/ScaleoApiServices/VehiclesService';
import productsService from '../../../services/ScaleoApiServices/ProductsService';
import weighingsService from '../../../services/ScaleoApiServices/WeighingsService';
import weighingConfigurationService from '../../../services/ScaleoApiServices/WeighingConfigurationService';
import '../.././DataTable.css';
import { CustomTableField, CustomButton } from '../../_shared/CustomComponents';
import DataTableFilterElements from '../../_shared/DataTableFilterElements';
import ImportInformationDialog from '../../_shared/ImportInformationDialog';
import getPaginatorTemplate from '../../../utils/getPaginatorTemplate';
import moment from 'moment';
import { userActions } from '../../../store/user-slice';
import { formatStringToDate } from '../../../utils/formatUtils';
import { getFileFromText } from '../../../utils/getFile';
import { getTranslatedLabel, tareTypeLabels } from '../../../utils/labels';
import { useTranslation, Trans } from 'react-i18next';
import { ReducerState } from '../../../types/reducer-state';
import { Contractor, Driver, Product, Vehicle } from '../../../types/weighing';
import { NewOrEditVehicleDialog } from './components/NewOrEditVehicleDialog';
import { MultiSelect } from 'primereact/multiselect';
import multiSelectPanelHeader from '../../_shared/multiSelectPanelHeader';
import { AutomaticWeighingMode } from '../../../enums/automatic-weighing-mode.enum';
import { Trailer } from '../../../types/trailer';
import trailersService from '../../../services/ScaleoApiServices/TrailersService';

export const Vehicles = () => {
  const { t } = useTranslation('datasetsVehicles');
  const { t: t1 } = useTranslation('utils');

  const emptyVehicle = {
    id: null,
    registrationNumber: '',
    weighingMode: [],
    driver: '',
    contractor: '',
    product: '',
    trailer: '',
    tareValue: '',
    tareChanged: false,
    requireArrivalNotification: false,
    allowEntry: true,
    allowExit: true,
    entryWeighingRequired: false,
    entryConfirmationRequired: false,
    exitWeighingRequired: false,
    exitConfirmationRequired: false,
    products: [],
    automaticWeighingMode: AutomaticWeighingMode.DEFAULT,
    maxWeightBrutto: null,
  };

  const emptyPaginationParameters = {
    order: {
      isAscending: true,
      orderColumn: 'id',
    },
    page: {
      index: 1,
      size: 10,
    },
  };

  const emptyPaginatorState = {
    currentPage: 1,
    totalPages: 1,
    rows: 10,
  };

  const [vehicles, setVehicles] = useState<Vehicle[] | null>(null);
  const [selectedVehicles, setSelectedVehicles] = useState<Vehicle[]>([]);
  const [vehicle, setVehicle] = useState<Vehicle>(emptyVehicle);
  const [vehicleDialogVisible, setVehicleDialogVisible] = useState(false);
  const [deleteVehicleDialogVisible, setDeleteVehicleDialogVisible] = useState(false);

  const [deleteVehiclesDialogVisible, setDeleteVehiclesDialogVisible] = useState(false);
  const dt = useRef(null);
  const fileUploader = useRef<FileUpload | null>(null);
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
  const [importInformationDialogData, setImportInformationDialogData] = useState(null);

  const [inputContractors, setInputContractors] = useState<Contractor[] | null>(null);
  const [inputDrivers, setInputDrivers] = useState<Driver[] | null>(null);
  const [inputProducts, setInputProducts] = useState<Product[]>([]);
  const [inputTrailers, setInputTrailers] = useState<Trailer[]>([]);

  const [tares, setTares] = useState<any | null>(null);
  const [configurationWeighingModes, setConfigurationWeighingModes] = useState<string[]>([]);
  const [initFormValues, setInitFormValues] = useState<Vehicle>(emptyVehicle);

  const filters = useSelector((state: ReducerState) => state.user.filtersState.vehicles);
  const [internalFilters, setInternalFilters] = useState(filters);
  const [paginationParameters, setPaginationParameters] = useState(emptyPaginationParameters);
  const [paginatorState, setPaginatorState] = useState(emptyPaginatorState);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const weighingAppState = useSelector((state: ReducerState) => state.weighing);
  const { weighingConfiguration } = useSelector((state: ReducerState) => state.weighing);

  const dispatch = useDispatch();

  const fetchVehicles = useCallback(
    async (_filters, _paginationParameters) => {
      Promise.all([
        vehiclesService.searchVehicles(formatSearchFilters(_filters), _paginationParameters),
        contractorsService.getContractors(),
        driversService.getDrivers(),
        weighingConfigurationService.getConfiguration(),
        productsService.getProducts({ searchText: null, searchForCurrentLocation: false, searchForActive: false }),
        trailersService.getTrailers(),
      ]).then((response) => {
        setVehicles(response[0].data);
        setInputContractors(response[1].data);
        setInputDrivers(response[2].data);

        setInputProducts(response[4].data);
        response[0].totalPages
          ? setPaginatorState({
              ...paginatorState,
              totalPages: response[0].totalPages,
            })
          : setPaginatorState({
              ...paginatorState,
              totalPages: 1,
              currentPage: 1,
            });
        setConfigurationWeighingModes(response[3].data.weighingMode);
        setInputTrailers(response[5].data);
        dispatch(weighingActions.weighingConfigurationFetched(response[3].data));
      });
    },
    [paginatorState],
  );

  const formatSearchFilters = (internalFilters: any) => {
    return {
      ...internalFilters,
      tareTimestamp: internalFilters.tareTimestamp?.map((stringDate: string) => formatStringToDate(stringDate)),
    };
  };

  useEffect(() => {
    fetchVehicles(internalFilters, paginationParameters);
  }, [internalFilters, paginationParameters, loggedUserContext.currentCustomer?.id]);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  useEffect(() => {
    setInitFormValues((prevInitFormValues) => ({ ...prevInitFormValues, weighingMode: configurationWeighingModes }));
  }, [configurationWeighingModes]);

  const openNew = () => {
    setVehicle(emptyVehicle);
    setVehicleDialogVisible(true);
  };

  const editVehicle = async (_vehicle: Vehicle) => {
    setTares(null);
    formik.resetForm({
      values: {
        id: _vehicle.id,
        registrationNumber: _vehicle.registrationNumber,
        weighingMode: _vehicle.weighingMode,
        driver: _vehicle.defaultDriver as any,
        contractor: _vehicle.defaultContractor!,
        trailer: _vehicle.defaultTrailer!,
        product: _vehicle.defaultProduct && productsService.mapProduct(_vehicle.defaultProduct),
        tareValue: (+_vehicle.tareValue !== 0 ? +_vehicle.tareValue : '') as string,
        tareChanged: false,
        requireArrivalNotification: !!_vehicle.requireArrivalNotification,
        allowEntry: !!_vehicle.allowEntry,
        allowExit: !!_vehicle.allowExit,
        entryWeighingRequired: _vehicle.entryWeighingRequired,
        entryConfirmationRequired: _vehicle.entryConfirmationRequired,
        exitWeighingRequired: _vehicle.exitWeighingRequired,
        exitConfirmationRequired: _vehicle.exitConfirmationRequired,
        products: [],
        automaticWeighingMode: _vehicle.automaticWeighingMode || AutomaticWeighingMode.DEFAULT,
        maxWeightBrutto: _vehicle.maxWeightBrutto,
      },
    });
    setVehicle({ ..._vehicle });
    setVehicleDialogVisible(true);
  };

  const hideDialog = () => {
    setVehicleDialogVisible(false);
    setTares(null);
    formik.resetForm({ values: initFormValues });
  };

  const confirmDeleteVehicle = (_vehicle: Vehicle) => {
    setVehicle(_vehicle);
    setDeleteVehicleDialogVisible(true);
  };

  const hideDeleteVehicleDialog = () => {
    setDeleteVehicleDialogVisible(false);
  };

  const confirmDeleteSelected = () => {
    setDeleteVehiclesDialogVisible(true);
  };

  const hideDeleteVehiclesDialog = () => {
    setDeleteVehiclesDialogVisible(false);
  };

  const fetchVehicleWeighingState = useCallback(
    async (vehicleId) => {
      if (weighingAppState.formState.selectedVehicle && weighingAppState.formState.selectedVehicle.id === vehicleId) {
        const response = await weighingsService.getVehicleWeighingState(vehicleId, null);
        dispatch(weighingActions.weighingStateFetched(response.data));
      }
    },
    [dispatch],
  );

  const deleteVehicle = () => {
    vehiclesService.removeManyVehicles([vehicle as any]).then(() => {
      fetchVehicles(internalFilters, paginationParameters);
      fetchVehicleWeighingState(vehicle.id);
    });
    setDeleteVehicleDialogVisible(false);
    setVehicle(emptyVehicle);
  };

  const deleteSelectedVehicles = () => {
    vehiclesService.removeManyVehicles(selectedVehicles).then(() => {
      fetchVehicles(internalFilters, paginationParameters);

      for (const _vehicle of selectedVehicles) {
        fetchVehicleWeighingState(_vehicle.id as any);
      }
    });

    setDeleteVehiclesDialogVisible(false);
    setSelectedVehicles([]);
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data) => {
      const errors = {} as any;

      if (!data.registrationNumber) {
        errors.registrationNumber = t('noRegistrationNumberError');
      }
      return errors;
    },
    onSubmit: async (formData, helpers) => {
      if (!formData.id) {
        await vehiclesService
          .createVehicle({ ...formData })
          .then(() => {
            hideDialog();
            fetchVehicles(internalFilters, paginationParameters);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('registrationNumber', t('vehicleExistsError'));
            }
          });
      } else {
        await vehiclesService
          .editVehicle({ ...formData }, formData.id)
          .then(() => {
            hideDialog();
            fetchVehicles(internalFilters, paginationParameters);
            fetchVehicleWeighingState(formData.id);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('registrationNumber', t('vehicleExistsError'));
            }
          });
      }
    },
    enableReinitialize: true,
  });

  const changeFilter = (value: any, name: string) => {
    const newFilters = { ...internalFilters };
    name === 'tareTimestamp'
      ? value
        ? (newFilters[name] = [
            value[0].toString(),
            value[1] ? moment(value[1]).add(23, 'hours').add(59, 'minutes').toDate().toString() : null,
          ])
        : (newFilters[name] = null)
      : (newFilters[name] = value);

    dispatch(userActions.setVehiclesFilters(newFilters));
    setInternalFilters(newFilters);
  };

  const clearFilters = () => {
    dispatch(userActions.setVehiclesFilters({}));
    setInternalFilters({});
  };

  useEffect(() => {
    setPaginationParameters((prevPaginationParameters) => ({
      ...prevPaginationParameters,
      page: {
        ...prevPaginationParameters.page,
        index: 1,
      },
    }));
  }, [internalFilters]);

  useEffect(() => {
    if (paginatorState.currentPage > paginatorState.totalPages)
      setPaginatorState((prevPaginatorState) => ({
        ...prevPaginatorState,
        currentPage: 1,
      }));
  }, [paginatorState]);

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const deleteVehicleDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteVehicleDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteVehicle} />
    </>
  );
  const deleteVehiclesDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteVehiclesDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteSelectedVehicles} />
    </>
  );

  const header = (
    <div className="table-header text-2xl p-2">
      {t('header')} &#160;&#160;
      <div className="flex">
        <Button label={t('clearFilters')} icon="pi pi-filter-slash" outlined className="mr-2" onClick={clearFilters} />
        <span>
          <DataTableFilterElements.Text
            initialValue={internalFilters['global']}
            onChangeFilter={changeFilter}
            name={'global'}
            placeholder={t('searchPlaceholder')}
            isSearchIcon
          />
        </span>
      </div>
    </div>
  );

  const leftToolbarTemplate = () => {
    return (
      <>
        <Button label={t('addButton')} icon="pi pi-plus" severity="success" className="mr-2" onClick={openNew} />
        <CustomButton
          label={t('deleteButton')}
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={
            !selectedVehicles ||
            !selectedVehicles.length ||
            selectedVehicles.map((vehicle) => vehicle?.isUsed).includes(true)
          }
          tooltip={!selectedVehicles.length ? t('selectVehicle') : t('cannotDelete')}
          name={undefined}
          dataFlag={undefined}
          type={undefined}
        />
      </>
    );
  };

  const exportData = async () => {
    const csvResponse = await vehiclesService.exportData();
    const csv = csvResponse.data;
    getFileFromText(csv, 'text/csv', `vehicles-${Date.now().toString()}.csv`);
  };

  const importData = (event: any) => {
    let csvData = '';
    const [f] = event.files;
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      csvData = Buffer.from(e.target.result).toString('base64');
      const insertResponse = await vehiclesService.importData(csvData);
      fileUploader.current?.clear();
      await fetchVehicles(internalFilters, paginationParameters);
      setImportInformationDialogData(insertResponse.data);
      setIsOpenImportDialog(true);
    };
    reader.readAsText(f);
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <FileUpload
          ref={fileUploader}
          mode="basic"
          accept=".csv"
          maxFileSize={1000000}
          chooseLabel={t('import')}
          className="mr-2"
          customUpload
          auto
          uploadHandler={importData}
        />
        <Button label={t('export')} icon="pi pi-upload" severity="help" className="mr-2" onClick={exportData} />
      </>
    );
  };

  const actionBodyTemplate = (rowData: Vehicle) => {
    return (
      <div className="actions">
        <Button icon="pi pi-pencil" raised severity="success" className="mr-2" onClick={() => editVehicle(rowData)} />
        {rowData.isUsed === false ? (
          <Button icon="pi pi-trash" raised severity="warning" onClick={() => confirmDeleteVehicle(rowData)} />
        ) : (
          <Button
            icon="pi pi-trash"
            raised
            severity="secondary"
            tooltip={t('tooltip')}
            tooltipOptions={{ position: 'bottom' }}
          />
        )}
      </div>
    );
  };

  const columnBodyTemplate = (rowData: Vehicle, column: any) => {
    return <CustomTableField row={rowData} col={column} withoutHeaders={undefined} />;
  };

  const trailerSource = inputTrailers?.map((trailer: Trailer) => ({
    label: trailer.registrationNumber,
    value: trailer.registrationNumber,
  }));

  const tareTypeSource = Object.entries(tareTypeLabels).map(([key]) => {
    return {
      label: getTranslatedLabel(tareTypeLabels, key, t1),
      value: key,
    };
  });

  const driverSource = inputDrivers?.map((driver: Driver) => {
    return {
      label: driver.name,
      value: driver.name,
    };
  });

  const contractorSource = inputContractors?.map((contractor: Contractor) => {
    return {
      label: contractor.name,
      value: contractor.name,
    };
  });

  const columns = [
    { field: 'registrationNumber', header: t('registrationNumber'), type: 'text' },
    weighingConfiguration?.addTrailerRegistrationNumbersSupport && {
      field: 'trailer',
      header: t('trailer'),
      type: 'text',
      source: trailerSource,
      dropdown: true,
    },
    { field: 'driver', header: t('driver'), type: 'text', dropdown: true, source: driverSource },
    { field: 'contractor', header: t('contractor'), type: 'text', dropdown: true, source: contractorSource },
    { field: 'product', header: t('product'), type: 'text', multiselectProducts: true },
    { field: 'tareValue', header: t('tareValue'), type: 'kg' },
    { field: 'tareTimestamp', header: t('tareTimestamp'), type: 'date' },
    {
      field: 'tareType',
      header: t('tareType'),
      type: 'tareType',
      dropdown: true,
      source: tareTypeSource,
    },
  ].filter((c) => c);

  const onProductChanged = (event: any) => {
    const newProducts = event.value;
    const newFilters = { ...internalFilters, products: newProducts };

    setInternalFilters(newFilters);
    formik.setFieldValue('products', newProducts);

    dispatch(userActions.setVehiclesFilters(newFilters));
  };

  const dynamicColumns = columns.map((col) => {
    const AnyColumn = Column as any;

    const selectedItemsLabel = `${internalFilters?.products?.length} ${
      internalFilters?.products?.length > 4
        ? 'produktów'
        : internalFilters?.products?.length === 1
        ? 'produkt'
        : 'produkty'
    }`;

    return (
      <AnyColumn
        key={col.field}
        field={col.field}
        header={col.header}
        body={columnBodyTemplate}
        fieldsType={col.type as any}
        sortable
        showFilterMenu={false}
        filter={col.type === 'noFilter' ? false : true}
        filterElement={
          col.dropdown ? (
            <DataTableFilterElements.Dropdown
              initialValue={internalFilters[col.field]}
              options={col.source}
              onChangeFilter={changeFilter}
              name={col.field}
              placeholder={t('filters')}
            />
          ) : col.multiselectProducts ? (
            <MultiSelect
              id="multiselect"
              value={internalFilters?.products}
              onChange={onProductChanged}
              options={inputProducts!}
              optionLabel="name"
              optionValue="name"
              placeholder={'Wybierz'}
              className="multiselect-custom"
              maxSelectedLabels={0}
              selectedItemsLabel={selectedItemsLabel}
              showClear={true}
              panelHeaderTemplate={multiSelectPanelHeader}
              filter
            />
          ) : col.type === 'date' ? (
            <DataTableFilterElements.Date
              initialValue={internalFilters[col.field]?.map((f: any) => {
                return formatStringToDate(f);
              })}
              onChangeFilter={changeFilter}
              name={col.field}
              placeholder={t('filters')}
            />
          ) : (
            <DataTableFilterElements.Text
              initialValue={internalFilters[col.field]}
              onChangeFilter={changeFilter}
              name={col.field}
              placeholder={t('filters')}
            />
          )
        }
      />
    );
  });

  return (
    <div className="grid">
      <ImportInformationDialog
        data={importInformationDialogData}
        visible={isOpenImportDialog}
        closeDialog={() => setIsOpenImportDialog(false)}
      />
      <div className="col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                responsiveLayout="scroll"
                ref={dt}
                value={vehicles as any}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                rowHover
                selection={selectedVehicles}
                selectionMode="checkbox"
                onSelectionChange={(e) => setSelectedVehicles(e.value)}
                emptyMessage={t('noData')}
                removableSort
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                paginator
                rows={paginatorState.rows}
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState) as any}
                stripedRows
                filterDisplay="row"
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                {dynamicColumns}
                <Column body={actionBodyTemplate} className="actionBody"></Column>
              </DataTable>
              <NewOrEditVehicleDialog
                formik={formik}
                visible={vehicleDialogVisible}
                hideDialog={hideDialog}
                configurationWeighingModes={configurationWeighingModes}
                inputDrivers={inputDrivers}
                inputContractors={inputContractors}
                inputTrailers={inputTrailers.map((t) => ({ ...t, name: t.registrationNumber }))}
                tares={tares}
                vehicle={vehicle}
                setTares={setTares}
              />
              <Dialog
                visible={deleteVehicleDialogVisible}
                header={
                  <span className="flex align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
                    {t('confirm')}
                  </span>
                }
                modal
                className="p-fluid min-width-450px"
                footer={deleteVehicleDialogFooter}
                onHide={hideDeleteVehicleDialog}
                breakpoints={{ '896px': '90vw' }}
              >
                <div className="confirmation-content">
                  {vehicle && (
                    <span>
                      <Trans t={t} i18nKey="deleteVehicleContent" values={{ vehicle: vehicle.registrationNumber }} />
                    </span>
                  )}
                </div>
              </Dialog>
              <Dialog
                visible={deleteVehiclesDialogVisible}
                header={
                  <span className="flex align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
                    {t('confirm')}
                  </span>
                }
                modal
                className="p-fluid min-width-450px"
                footer={deleteVehiclesDialogFooter}
                onHide={hideDeleteVehiclesDialog}
                breakpoints={{ '896px': '90vw' }}
              >
                <div className="confirmation-content">{vehicle && <span>{t('deleteVehiclesContent')}</span>}</div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
