import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Panel } from 'primereact/panel';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import dictionariesService from '../../../../../../../services/ScaleoApiServices/DictionariesService';
import productsService from '../../../../../../../services/ScaleoApiServices/ProductsService';
import ComponentItemTemplates from '../../../../../../_shared/ComponentItemTemplates';
import { CustomAutoComplete, dictionarySearchBranch } from '../../../../../../_shared/CustomComponents';
import FormErrorMessage from '../../../../../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../../../../../_shared/FormErrorMessageScroller';
import multiSelectPanelHeader from '../../../../../../_shared/multiSelectPanelHeader';
import EditFormulaDialog from './EditFormulaDialog';
import { useTranslation } from 'react-i18next';

interface EditDialogProps {
  visible: any;
  closeDialog: any;
  field: any;
  allAdditionalFields: any;
}

const EMPTY_SPECIFIC_VALUE_OBJECT = { product: null, value: '' };

const INIT_EDIT_FORMULA_DIALOG_DATA = { isOpen: false, initValue: null, onChangeValue: () => undefined };

const CLEAR_ADDITIONAL_FIELD = {
  id: null,
  name: '',
  isActive: true,
  showOnWeighingForm: true,
  isOptional: true,
  isVisibleOnReceipt: true,
  destination: null,
  mode: [],
  productIds: [],
  type: null,
  unit: '',
  unitMode: null,
  max: 0,
  min: 0,
  dictionaryId: null,
  baseDefaultValue: null,
  specificDefaultValues: [],
  isUsed: false,
  precision: null,
};

const EditDialog = ({ visible, closeDialog, field, allAdditionalFields }: EditDialogProps) => {
  const { t } = useTranslation('managementSystemConfigurationWeighing');

  const PRECISION_OPTIONS = [
    ...[100, 10, 1, 0.1, 0.01, 0.001, 0.0001, 0.00001].map((el) => {
      return { label: `${el}`, value: el };
    }),
    { label: t('optionsNull'), value: null },
  ];

  const MODE_OPTIONS = [
    { label: t('modeOptionsSingle'), value: 'SINGLE' },
    { label: t('modeOptionsDouble'), value: 'DOUBLE' },
    { label: t('modeOptionsMultiple'), value: 'MULTIPLE' },
    { label: t('modeOptionsWeighingSeries'), value: 'WEIGHING_SERIES' },
    { label: t('modeOptionsDoubleWeighingSeries'), value: 'DOUBLE_WEIGHING_SERIES' },
    { label: t('modeOptionsManual'), value: 'MANUAL' },
  ];

  const TYPE_OPTIONS = [
    { label: t('typeOptionsNumber'), value: 'NUMBER' },
    { label: t('typeOptionsText'), value: 'TEXT' },
    { label: t('typeOptionsDictionary'), value: 'DICTIONARY' },
    { label: t('formula'), value: 'FORMULA' },
    { label: t('autoId'), value: 'AUTO_ID' },
  ];

  const DESTINATION_OPTIONS = [
    { label: t('weighing'), value: 'WEIGHING' },
    { label: t('measurement'), value: 'MEASUREMENT' },
  ];

  const UNIT_OPTIONS = [
    { label: t('unitOptionsKg'), value: 'kg' },
    { label: t('unitOptionsT'), value: 't' },
    { label: t('unitOptionsMg'), value: 'Mg' },
    { label: t('unitOptionsOther'), value: 'other' },
    { label: t('unitOptionsNone'), value: 'none' },
  ];

  const [dictionariesOptions, setDictionariesOptions] = useState<any>([]);
  const [productsOptions, setProductsOptions] = useState<any>([]);
  const [editFormulaDialogData, setEditFormulaDialogData] = useState(INIT_EDIT_FORMULA_DIALOG_DATA);
  const ref = useRef(null);

  const fetchAPI = useCallback(async () => {
    const responseDictionariesOptions = await dictionariesService.getDictionaries();
    setDictionariesOptions(responseDictionariesOptions.data);
  }, []);

  useEffect(() => {
    fetchAPI();
  }, [fetchAPI]);

  const productsMultiselectOnClearFilter = (currentValue: any) => {
    if (currentValue && Array.isArray(currentValue) && currentValue?.length > 0) {
      setProductsOptions(currentValue);
    } else {
      const params = {
        searchText: null,
        searchForCurrentLocation: false,
        searchForActive: false,
      };
      productsService.getProducts(params, { show_loader: false }).then((response) => {
        setProductsOptions(response.data);
      });
    }
  };

  const productsMultiselectSearchMethod = (filter: any, currentValue: any) => {
    const searchText = filter.trim();
    if (searchText?.length > 0) {
      const params = {
        searchText: searchText,
        searchForCurrentLocation: false,
        searchForActive: false,
      };
      productsService.getProducts(params, { show_loader: false }).then((response) => {
        const resultOptionsIds = response.data.map((el: any) => el.id);
        const optionsSelected = currentValue.filter((el: any) => resultOptionsIds.includes(el.id));
        const selectedOptionsIds = optionsSelected.map((el: any) => el.id);
        const optionsUnselected = response.data.filter((el: any) => !selectedOptionsIds.includes(el.id));
        const options = [...optionsSelected, ...optionsUnselected];
        setProductsOptions(options);
      });
    } else {
      productsMultiselectOnClearFilter(currentValue);
    }
  };

  const handleSubmit = () => {
    closeDialog(true, formik.values);
  };

  const handleClose = () => {
    closeDialog(false, null);
  };

  const validateMethod = (data: any) => {
    const errors: any = {};

    if (!data.name) {
      errors.name = t('noNameError');
    }

    if (!data.destination) {
      errors.destination = t('noDestinationError');
    }

    if (!data.mode?.length) {
      errors.mode = t('noModeError');
    }

    if (!data.type) {
      errors.type = t('notTypeError');
    }

    if (data.type === 'DICTIONARY' && !data.dictionaryId) {
      errors.dictionaryId = t('noDictionaryIdError');
    }

    if ((data.type === 'NUMBER' || data.type === 'FORMULA') && !data.unitMode) {
      errors.unitMode = t('noUnitModeError');
    }

    if (
      data.type === 'NUMBER' &&
      data.min !== '' &&
      data.min !== null &&
      data.max !== '' &&
      data.max !== null &&
      +data.min > +data.max
    ) {
      errors.min = t('minError');
    }

    if (data.type === 'FORMULA' && !data.baseDefaultValue) {
      errors.baseDefaultValue = t('noBaseDefaultValueError');
    } else if (data.type === 'NUMBER' && data.min !== '' && data.min !== null && +data.baseDefaultValue < +data.min) {
      errors.baseDefaultValue = t('minBaseDefaultValueError');
    } else if (data.type === 'NUMBER' && data.max !== '' && data.max !== null && +data.baseDefaultValue > +data.max) {
      errors.baseDefaultValue = t('maxBaseDefaultValueError');
    }

    if (data.specificDefaultValues && Array.isArray(data.specificDefaultValues)) {
      data.specificDefaultValues.forEach(({ value, product }: any, i: number) => {
        if (!product) {
          errors[`specificDefaultValue-${i}-product`] = t('noSpecificDefaultValuesError');
        }

        if (data.type === 'NUMBER' && value === '') {
          errors[`specificDefaultValue-${i}-value`] = t('noSpecificDefaultValuesError');
        } else if (data.type === 'NUMBER' && data.min !== '' && data.min !== null && +value < +data.min) {
          errors[`specificDefaultValue-${i}-value`] = t('minSpecificDefaultValuesError');
        } else if (data.type === 'NUMBER' && data.max !== '' && data.max !== null && +value > +data.max) {
          errors[`specificDefaultValue-${i}-value`] = t('maxSpecificDefaultValuesError');
        }
      });
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: field ? { ...CLEAR_ADDITIONAL_FIELD, ...field } : CLEAR_ADDITIONAL_FIELD,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const newSpecificDefaultValues = formik.values.specificDefaultValues?.filter((specificDefaultValue: any) =>
      formik.values.productIds.map((el: any) => el?.id).includes(specificDefaultValue.product?.id),
    );
    handleChange('specificDefaultValues', newSpecificDefaultValues);
  }, [formik.values.productIds]);

  const dialogFooter = (
    <>
      <Button type="button" onClick={handleClose} label={t('cancelButton')} />
      <Button type="button" onClick={formik.submitForm} label={t('confirmButton')} />
    </>
  );

  const handleChange = (fieldName: any, value: any) => {
    formik.setFieldValue(fieldName, value, true);
  };

  const handleClickNewSpecificValue = () => {
    formik.setFieldValue(
      'specificDefaultValues',
      [...(formik.values.specificDefaultValues || []), JSON.parse(JSON.stringify(EMPTY_SPECIFIC_VALUE_OBJECT))],
      false,
    );
  };

  const handleRemoveNewSpecificValue = (i: any) => {
    const newSpecificDefaultValues = formik.values.specificDefaultValues.filter(
      (_value: any, index: any) => index !== i,
    );
    handleChange('specificDefaultValues', newSpecificDefaultValues);
  };

  const handleClickOpenEditFormulaDialog = (initValue: any, onChangeValue: any) => {
    setEditFormulaDialogData({ isOpen: true, initValue: initValue || null, onChangeValue });
  };

  const handleClickCloseEditFormulaDialog = () => {
    setEditFormulaDialogData(INIT_EDIT_FORMULA_DIALOG_DATA);
  };

  const renderCorrectInput = (value: any, id: any, onValueChange: any) => {
    const renderDictionaryType = () => {
      const source = dictionariesOptions
        .find((dictionary: any) => dictionary.id === formik.values.dictionaryId)
        ?.values.map((_value: any) => {
          return { value: _value };
        });

      return (
        <CustomAutoComplete
          id={id}
          value={source?.find((el: any) => el.value === value) || null}
          handler={(e: any) => onValueChange(e.value?.value)}
          source={source}
          lookupField={'value'}
          disabled={!formik.values.dictionaryId}
        />
      );
    };

    const renderNumberType = () => (
      <InputText id={id} type="number" value={value || ''} onChange={(e) => onValueChange(e.target.value)} />
    );

    const renderTextType = () => (
      <InputText id={id} value={value || ''} onChange={(e) => onValueChange(e.target.value)} />
    );

    const renderFormulaType = () => (
      <div className="flex">
        <InputText id={id} value={value || ''} disabled className="border-noround-right" />
        <Button
          icon="pi pi-pencil"
          type="button"
          text
          className="p-button ml--1px border-noround-left border-left-0"
          style={{
            border: '1px solid #ced4da99',
          }}
          onClick={() => handleClickOpenEditFormulaDialog(value, (_value: any) => onValueChange(_value))}
        />
      </div>
    );

    const METHODS = {
      DICTIONARY: renderDictionaryType,
      NUMBER: renderNumberType,
      TEXT: renderTextType,
      FORMULA: renderFormulaType,
    };

    if (!!(METHODS as any)[formik.values.type]) {
      return (METHODS as any)[formik.values.type]();
    }

    return '';
  };

  const renderSpecificValueRow = (rowData: any, i: any) => (
    <React.Fragment key={`specificDefaultValue-${i}`}>
      {i === 0 ? (
        <div className="p-fluid formgrid grid pb-10px">
          <div className="col-5">
            <label htmlFor="product">{t('product')}</label>
          </div>
          <div className="col-5">
            <label htmlFor="value">{formik.values.type === 'FORMULA' ? t('formula') : t('value')}</label>
          </div>
          <div className="col-2 text-center">
            <label htmlFor="actions">{t('actions')}</label>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="p-fluid formgrid grid">
        <div className="field col-5">
          <CustomAutoComplete
            id="product"
            field="fulltext"
            value={rowData.product}
            handler={(e: any) => {
              const newSpecificDefaultValues = [...formik.values.specificDefaultValues];

              newSpecificDefaultValues[i] = {
                ...newSpecificDefaultValues[i],
                product: e.target.value,
              };

              handleChange('specificDefaultValues', newSpecificDefaultValues);
            }}
            source={formik.values.productIds.filter(
              (productId: any) =>
                formik.values.specificDefaultValues.findIndex(
                  (specificDefaultValue: any) => specificDefaultValue.product?.id === productId?.id,
                ) === -1,
            )}
            customItemTemplate={ComponentItemTemplates.Product}
          />
          <FormErrorMessage fieldName={`specificDefaultValue-${i}-product`} formikInstance={formik} withTouchedTrue />
        </div>
        <div className="field col-5">
          {renderCorrectInput(rowData.value, `specificDefaultValue-${i}`, (value: any) => {
            const newSpecificDefaultValues = [...formik.values.specificDefaultValues];

            newSpecificDefaultValues[i] = {
              ...newSpecificDefaultValues[i],
              value: value,
            };

            handleChange('specificDefaultValues', newSpecificDefaultValues);
          })}
          <FormErrorMessage fieldName={`specificDefaultValue-${i}-value`} formikInstance={formik} withTouchedTrue />
        </div>
        <div className="field col-2 text-center">
          <Button
            icon="pi pi-trash"
            type="button"
            severity="danger"
            text
            className="p-button-sm"
            onClick={() => handleRemoveNewSpecificValue(i)}
            tooltip={t('deleteButton')}
          />
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={visible}
        header={field ? t('editAdditionalField') : t('addAdditionalField')}
        modal
        className="p-fluid w-40vw"
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        footer={dialogFooter}
        onHide={handleClose}
      >
        <form>
          <div className="col-12" ref={ref}>
            <div className="field">
              <label htmlFor="name">{t('name')}</label>
              <InputText id="name" value={formik.values.name} onChange={(e) => handleChange('name', e.target.value)} />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="isActive">{t('isActive')}</label>
              <InputSwitch
                id="isActive"
                className="float-right"
                checked={formik.values.isActive}
                onChange={(e) => handleChange('isActive', e.value)}
              />
            </div>
            {!['AUTO_ID', 'FORMULA'].includes(formik.values.type) && (
              <>
                <div className="field">
                  <label htmlFor="isOptional">{t('isOptional')}</label>
                  <InputSwitch
                    id="isOptional"
                    className="float-right"
                    checked={formik.values.isOptional}
                    onChange={(e) => handleChange('isOptional', e.value)}
                  />
                </div>
                <div className="field">
                  <label htmlFor="showOnWeighingForm">{t('showOnWeighingForm')}</label>
                  <InputSwitch
                    id="showOnWeighingForm"
                    className="float-right"
                    checked={formik.values.showOnWeighingForm}
                    onChange={(e) => handleChange('showOnWeighingForm', e.value)}
                  />
                </div>
              </>
            )}
            <div className="field">
              <label htmlFor="isVisibleOnReceipt">{t('isVisibleOnReceipt')}</label>
              <InputSwitch
                id="isVisibleOnReceipt"
                className="float-right"
                checked={formik.values.isVisibleOnReceipt}
                onChange={(e) => handleChange('isVisibleOnReceipt', e.value)}
              />
            </div>
            <div className="field">
              <label htmlFor="destination">{t('destination')}</label>
              <Dropdown
                id="destination"
                options={DESTINATION_OPTIONS}
                value={formik.values.destination}
                disabled={formik.values.isUsed}
                onChange={(e) => {
                  handleChange('destination', e.value);
                  if (e.value === 'MEASUREMENT') {
                    handleChange(
                      'mode',
                      formik.values.mode.filter((value: any) => value !== 'SINGLE'),
                    );
                  }
                }}
                placeholder={t('destinationPlaceholder')}
              />
              <FormErrorMessage fieldName="destination" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="mode">{t('mode')}</label>
              <MultiSelect
                id="mode"
                value={formik.values.mode}
                options={
                  formik.values.destination === 'MEASUREMENT'
                    ? MODE_OPTIONS.filter((el) => el.value !== 'SINGLE')
                    : MODE_OPTIONS
                }
                onChange={(e) => handleChange('mode', e.value)}
                placeholder={t('modePlaceholder')}
                panelHeaderTemplate={multiSelectPanelHeader}
              />
              <FormErrorMessage fieldName="mode" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="productIds">{t('productIds')}</label>
              <MultiSelect
                id="productIds"
                optionLabel="name"
                value={formik.values.productIds}
                options={productsOptions}
                onChange={(e) => handleChange('productIds', e.value)}
                filter
                resetFilterOnHide
                emptyFilterMessage={t('productIdsEmptyFilterMsg')}
                maxSelectedLabels={0}
                selectedItemsLabel={t('productIdsSelectedItemsLabel')}
                itemTemplate={ComponentItemTemplates.Product}
                onFilter={({ filter }) => productsMultiselectSearchMethod(filter, formik.values.productIds)}
                onShow={() => productsMultiselectOnClearFilter(formik.values.productIds)}
                placeholder={t('productIdsPlaceholder')}
                panelHeaderTemplate={multiSelectPanelHeader}
              />
            </div>
            <div className="field">
              <label htmlFor="type">{t('type')}</label>
              <Dropdown
                id="type"
                options={TYPE_OPTIONS}
                value={formik.values.type}
                disabled={formik.values.isUsed}
                onChange={(e) => {
                  if (e.value !== 'NUMBER') {
                    handleChange('unit', '');
                  }
                  if (e.value === 'FORMULA') {
                    handleChange('isOptional', true);
                  }
                  handleChange('baseDefaultValue', null);
                  handleChange('specificDefaultValues', []);
                  handleChange('min', null);
                  handleChange('max', null);
                  handleChange('type', e.value);
                }}
                placeholder="wybierz"
              />
              <FormErrorMessage fieldName="type" formikInstance={formik} />
            </div>
            {!!(formik.values.type === 'NUMBER') && (
              <>
                <div className="field">
                  <label htmlFor="min">{t('min')}</label>
                  <InputText
                    id="min"
                    type="number"
                    value={formik.values.min || ''}
                    onChange={(e) => handleChange('min', e.target.value)}
                  />
                  <FormErrorMessage fieldName="min" formikInstance={formik} />
                </div>
                <div className="field">
                  <label htmlFor="max">{t('max')}</label>
                  <InputText
                    id="max"
                    type="number"
                    value={formik.values.max || ''}
                    onChange={(e) => handleChange('max', e.target.value)}
                  />
                  <FormErrorMessage fieldName="max" formikInstance={formik} />
                </div>
              </>
            )}
            {!!(formik.values.type === 'DICTIONARY') && (
              <div className="field">
                <label htmlFor="dictionaryId">{t('dictionaryId')}</label>
                <CustomAutoComplete
                  id="dictionaryId"
                  value={dictionariesOptions?.find((el: any) => el.id === formik.values.dictionaryId)}
                  handler={(e: any) => handleChange('dictionaryId', e.value?.id)}
                  source={dictionariesOptions}
                  customSearchBranch={dictionarySearchBranch}
                  customItemTemplate={ComponentItemTemplates.Dictionary}
                  placeholder={t('dictionaryIdPlaceholder')}
                  tooltip={dictionariesOptions.length === 0 ? t('dictionaryIdTooltip') : undefined}
                  disabled={formik.values.isUsed || dictionariesOptions.length === 0}
                />
                <FormErrorMessage fieldName="dictionaryId" formikInstance={formik} />
              </div>
            )}
            {!!(formik.values.type && formik.values.type !== 'AUTO_ID') && (
              <div className="field">
                <label htmlFor="baseDefaultValue">
                  {formik.values.type === 'FORMULA' ? t('baseDefaultValueFormula') : t('baseDefaultValueValue')}
                </label>
                {renderCorrectInput(formik.values.baseDefaultValue, 'baseDefaultValue', (value: any) =>
                  handleChange('baseDefaultValue', value),
                )}
                <FormErrorMessage fieldName="baseDefaultValue" formikInstance={formik} />
              </div>
            )}
            {!!(formik.values.type === 'FORMULA') && (
              <div className="field">
                <label htmlFor="precision">{t('precision')}</label>
                <Dropdown
                  id="precision"
                  options={PRECISION_OPTIONS}
                  value={formik.values.precision}
                  onChange={(e) => handleChange('precision', e.value)}
                  placeholder={t('precisionPlaceholder')}
                />
              </div>
            )}
            {!!['NUMBER', 'FORMULA'].includes(formik.values.type) && (
              <>
                <div className="field">
                  <label htmlFor="unitMode">{t('unitMode')}</label>
                  <Dropdown
                    id="unitMode"
                    options={UNIT_OPTIONS}
                    value={formik.values.unitMode}
                    onChange={(e) => {
                      handleChange('unitMode', e.value);
                      if (['other', 'none'].includes(e.value)) {
                        handleChange('unit', '');
                      } else {
                        handleChange('unit', e.value);
                      }
                    }}
                    placeholder={t('unitModePlaceholder')}
                    disabled={formik.values.isUsed}
                  />
                  <FormErrorMessage fieldName="unitMode" formikInstance={formik} />
                  {!!(formik.values.unitMode === 'other') && (
                    <InputText
                      id="unit"
                      value={formik.values.unit}
                      onChange={(e) => handleChange('unit', e.target.value)}
                      className="mt-1"
                      placeholder={t('unitPlaceholder')}
                    />
                  )}
                  <FormErrorMessage fieldName="unit" formikInstance={formik} />
                </div>
              </>
            )}
            {!!(formik.values.type && formik.values.type !== 'AUTO_ID') && (
              <div className="field">
                <Panel
                  header={
                    formik.values.type === 'FORMULA'
                      ? t('specificDefaultValuesFormula')
                      : t('specificDefaultValuesValue')
                  }
                  toggleable
                >
                  {formik.values.specificDefaultValues?.map(renderSpecificValueRow)}
                  <div className="p-fluid formgrid grid">
                    <div>
                      <Button
                        type="button"
                        label={t('addButton')}
                        icon="pi pi-plus"
                        severity="success"
                        className="ml-3"
                        onClick={handleClickNewSpecificValue}
                      />
                    </div>
                  </div>
                </Panel>
              </div>
            )}
          </div>
        </form>
      </Dialog>
      {editFormulaDialogData.isOpen ? (
        <EditFormulaDialog
          visible={editFormulaDialogData.isOpen}
          closeDialog={handleClickCloseEditFormulaDialog}
          onChangeValue={editFormulaDialogData.onChangeValue}
          initValue={editFormulaDialogData.initValue}
          allAdditionalFields={allAdditionalFields}
          parentDialogRef={ref}
        />
      ) : (
        ''
      )}
    </FormErrorMessageScroller>
  );
};

export default EditDialog;
