import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import trailersService from '../../services/ScaleoApiServices/TrailersService';
import { Trailer } from '../../types/trailer';

export const useTrailerForm = (
  trailer: Trailer | null,
  onTrailerAdded: ((t: Trailer) => void) | undefined,
  setTrailerDialogVisible: (b: boolean) => void,
) => {
  const { t } = useTranslation('datasetsTrailers');

  const formik = useFormik({
    initialValues: trailer ?? emptyTrailer,
    validate: (data) => {
      const errors = {} as any;

      if (!data.registrationNumber) {
        errors.name = t('noRegistrationNumberError');
      }
      if (!data.registrationNumber) {
        errors.code = t('noRegistrationNumberError');
      }
      return errors;
    },
    onSubmit: async (formData, helpers) => {
      if (!formData.id) {
        await trailersService
          .createTrailer({ ...formData })
          .then((res) => {
            hideDialog();
            onTrailerAdded && onTrailerAdded({ ...res.data });
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('registrationNumber', t('trailerExistsError'));
            }
          });
      } else {
        await trailersService
          .editTrailer({ ...formData }, formData.id)
          .then((res) => {
            hideDialog();
            onTrailerAdded && onTrailerAdded({ ...res.data });
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('registrationNumber', t('trailerExistError'));
            }
          });
      }
    },
    enableReinitialize: true,
  });

  const hideDialog = () => {
    setTrailerDialogVisible(false);
    formik.resetForm({ values: emptyTrailer });
  };

  return {
    formik,
    hideDialog,
  };
};

const emptyTrailer = {
  id: null,
  registrationNumber: '',
} as Trailer;
