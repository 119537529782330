import { PaginationParams, PaginatorState } from '../../types/general-types';
import { Filters, AccessControlEventLogType } from './types';
import React from 'react';
import accessControlEventLogService from '../../services/ScaleoApiServices/AccessControlEventLogService';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import moment from 'moment';
import vehicleService from '../../services/ScaleoApiServices/VehiclesService';
import { Vehicle } from '../../types/weighing';

export const handleSort = (
  e: any,
  paginationParameters: PaginationParams,
  setPaginationParameters: React.Dispatch<React.SetStateAction<PaginationParams>>,
) => {
  setPaginationParameters({
    ...paginationParameters,
    order: {
      isAscending: e.sortOrder === 1 ? true : false,
      orderColumn: e.sortField,
    },
  });
};

export const changeFilter = (
  value: any,
  name: string,
  filters: Filters,
  setFilters: React.Dispatch<React.SetStateAction<Filters>>,
) => {
  const newFilters: any = { ...filters };

  name === 'date'
    ? value
      ? (newFilters[name] = [value[0].toString(), value[1] ? value[1].toString() : null])
      : (newFilters[name] = null)
    : (newFilters[name] = value);

  setFilters(newFilters);
};

export const changeRegistrationNumber = (
  value: string,
  filters: Filters,
  setFilters: React.Dispatch<React.SetStateAction<Filters>>,
) => {
  setFilters({ ...filters, registrationNumber: value });
};

export const handleClickClearFilters = (setFilters: React.Dispatch<React.SetStateAction<Filters>>) => {
  setFilters({ date: null, registrationNumber: null, global: null });
};

export const fetchData = (
  filters: Filters,
  paginationParameters: PaginationParams,
  paginatorState: PaginatorState,
  setRegistrationNumbersList: React.Dispatch<React.SetStateAction<string[]>>,
  setListOfLogs: React.Dispatch<React.SetStateAction<any>>,
  setPaginatorState: React.Dispatch<React.SetStateAction<PaginatorState>>,
) => {
  const dateFilters = {
    startDate: filters.date && filters?.date[0] ? new Date(filters?.date[0]) : '',
    endDate: filters.date && filters?.date[1] ? new Date(filters?.date[1]) : '',
  };

  accessControlEventLogService.getLogs(filters, paginationParameters, dateFilters).then((logs) => {
    const eventLogArray: AccessControlEventLogType[] = [];

    for (const element of logs.data.items) {
      const { firstName, lastName, ...newElement } = element;

      eventLogArray.push({ ...newElement, userName: `${firstName} ${lastName}` });
    }

    setListOfLogs(eventLogArray);

    setPaginatorState({
      ...paginatorState,
      totalPages: logs.data.totalPagesNumber,
      currentPage: paginatorState.currentPage > logs.data.totalPagesNumber ? 1 : paginatorState.currentPage,
    });
  });

  vehicleService.getVehicles().then((vehicles) => {
    setRegistrationNumbersList(vehicles.data.map((vehicle: Vehicle) => vehicle.registrationNumber));
  });
};

export const downloadImages = async (currentData: any) => {
  const result = await accessControlEventLogService.getDownloadableImages(currentData.id);

  const images = result.data;

  const zip = new JSZip();
  images.forEach((image: any, index: number) => {
    zip.file(`${index}.jpeg`, image.data, { base64: true });
  });

  void zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, 'images.zip');
  });
};

export const serviceToCall = async (currentData: AccessControlEventLogType) => {
  const result = await accessControlEventLogService.getImageUrls(currentData.id);
  return result.data;
};

export const accessEventLogDialogHeaderCallback = (currentData: AccessControlEventLogType) => {
  if (!currentData) return;

  return (
    <div className="gallery-header">
      <span className="ml-2">{`${moment.parseZone(new Date(currentData?.date)).format('DD-MM-YYYY HH:mm:ss')} - ${
        currentData?.event
      } `}</span>
    </div>
  );
};
