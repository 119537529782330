import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from '../../../../../_shared/FormErrorMessage';
import { useSelector } from 'react-redux';
import { ReducerState } from '../../../../../../types/reducer-state';
import { ProductAutoComplete } from '../../../../../_shared/ProductAutoComplete';
import { MeasurementButtons } from './MeasurementButtons';
import MeasurementAdditionalFieldsPlugin from './MeasurementsAdditionalFieldsPlugin';
import { findProductAdditionalFieldValue } from '../../../../../../utils/additionalFieldsUtils';
import CustomDivider from '../../../../../_shared/CustomDivider';

interface MeasurementsProps {
  formik: any;
  inputScales: any[];
  maxInputNumberValue: number;
  isSubmitClicked: boolean;
}

export const Measurements = ({ formik, inputScales, maxInputNumberValue, isSubmitClicked }: MeasurementsProps) => {
  const { t } = useTranslation('weighingWeighingList');
  const unit = useSelector((state: ReducerState) => state.appConfig.unit);

  const handleChangeProduct = (e: any, index: number) => {
    const measurements = formik.values.measurements;

    measurements[index].product = e;
    const measurementAdditionalFields = measurements[index]?.additionalFields;
    measurements[index].additionalFields = measurementAdditionalFields?.map((a: any) => ({
      ...a,
      value:
        measurementAdditionalFields?.[a?.id] ??
        findProductAdditionalFieldValue(a, measurements[index]?.product?.id) ??
        a?.baseDefaultValue,
    }));

    void formik.setFieldValue('measurements', measurements);

    const weighingAdditionalFields = formik.values.additionalFields;
    const productIds = measurements.map((m: any) => m.product?.id).filter((m: any) => m);

    void formik.setFieldValue(
      'additionalFields',
      weighingAdditionalFields?.map((a: any) => ({
        ...a,
        value:
          weighingAdditionalFields?.[a.id] ?? findProductAdditionalFieldValue(a, productIds[0]) ?? a.baseDefaultValue,
      })),
    );
  };

  return formik.values.measurements.length > 0 ? (
    formik.values.measurements
      ?.filter((m: any) => !m.toBeDeleted)
      ?.map((m: any, index: number) => (
        <>
          <CustomDivider />
          <MeasurementButtons formik={formik} index={index} type={m.type} />
          <div className="field col-12 md:col-3 w-5vw">
            <label htmlFor="index">{t('measurement')}</label>
            <InputNumber id="index" value={index + 1} mode="decimal" disabled />
          </div>
          <div className="field col-12 md:col-3 w-15vw">
            <label htmlFor="scale">{t('weighingDialogScale')}</label>
            <Dropdown
              id={`measurement-${index}-scale`}
              value={m.scale?.id}
              onChange={(e) => {
                const selectedScale = inputScales.find((inputScale) => {
                  return inputScale.id === e.value;
                });
                const measurements = formik.values.measurements;
                measurements[index].scale = selectedScale;
                formik.setFieldValue('measurements', measurements);
              }}
              options={inputScales}
              optionValue="id"
              optionLabel="name"
              disabled={m.type === 'MEASURED'}
            />
            <FormErrorMessage
              fieldName={`measurement-${index}-scale`}
              formikInstance={formik}
              withTouchedTrue={isSubmitClicked}
            />
          </div>
          <div className="field col-12 md:col-3 w-15vw">
            <label htmlFor="timestamp">{t('weighingDialogDate')}</label>
            <Calendar
              id={`measurement-${index}-scale`}
              dateFormat="dd.mm.yy"
              value={new Date(m.timestamp)}
              onChange={(e) => {
                const measurements = formik.values.measurements;
                measurements[index].timestamp = e.value;
                formik.setFieldValue('measurements', measurements);
              }}
              showTime
              showSeconds
              disabled={m.type === 'MEASURED'}
            />
            <FormErrorMessage
              fieldName={`measurement-${index}-timestamp`}
              formikInstance={formik}
              withTouchedTrue={isSubmitClicked}
            />
          </div>
          <div className="field col-12 md:col-3 w-10vw">
            <label htmlFor="indication">{t('weighingDialogIndication', { unit })}</label>
            <InputNumber
              id={`measurement-${index}-indication`}
              value={m.indication}
              onChange={(e) => {
                const measurements = formik.values.measurements;
                measurements[index].indication = e.value;
                formik.setFieldValue('measurements', measurements);
              }}
              mode="decimal"
              minFractionDigits={0}
              maxFractionDigits={5}
              min={0}
              max={maxInputNumberValue}
              disabled={m.type === 'MEASURED'}
            />
            <FormErrorMessage
              fieldName={`measurement-${index}-indication`}
              formikInstance={formik}
              withTouchedTrue={isSubmitClicked}
            />
          </div>
          <div className="field col-12 md:col-6 w-16vw">
            <label htmlFor="productsSummary">{t('weighingDialogProductsSummary')}</label>
            <ProductAutoComplete
              id="productsSummary"
              value={m.product?.name}
              onChange={(e: any) => handleChangeProduct(e, index)}
              conditionalFields={{
                transactionType: formik.values.transactionType?.id,
                contractorId: formik.values.contractor?.id,
              }}
              searchForCurrentLocation={undefined}
            />
            <FormErrorMessage fieldName="productsSummary" formikInstance={formik} withTouchedTrue={isSubmitClicked} />
          </div>
          <MeasurementAdditionalFieldsPlugin formikInstance={formik} measurementIndex={index} />
        </>
      ))
  ) : (
    <></>
  );
};
