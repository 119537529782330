import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import '../../../DataTable.css';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import { useWebhookForm } from '../../../../hooks/Webhooks/useWebhookForm';
import { Webhook, WebhookEvent } from '../../../../types/webhook';
import { MultiSelect } from 'primereact/multiselect';
import { Contractor } from '../../../../types/weighing';
import multiSelectPanelHeader from '../../../_shared/multiSelectPanelHeader';
import { Dropdown } from 'primereact/dropdown';
import { WebhookAuthentication } from './WebhookAuthentication';

interface WebhookDialogProps {
  visible: boolean;
  closeDialog: () => void;
  fetchData: () => Promise<void>;
  item: Webhook | null;
  contractors: Contractor[];
}

const WebhookDialog = ({ visible, closeDialog, item, fetchData, contractors }: WebhookDialogProps) => {
  const { t } = useTranslation('managementWebhooks');

  const { formik, selectContractorsHandler } = useWebhookForm(fetchData, item, closeDialog);

  const dialogFooter = (
    <>
      <Button type="reset" label={t('cancel')} icon="pi pi-times" text onClick={closeDialog} />
      <Button type="submit" label={t('save')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );

  const webhookEventsTranslations = [
    {
      id: WebhookEvent.WEIGHING_CLOSED,
      name: t('weighingClosed'),
    },
  ];

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <form onSubmit={formik.handleSubmit}>
        <div className="location-edit-dialog">
          <Dialog
            visible={visible}
            header={t('details')}
            modal
            className="p-fluid w-40vw"
            breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
            footer={dialogFooter}
            onHide={closeDialog}
          >
            <div className="field">
              <label htmlFor="name">{t('name')}</label>
              <InputText id="name" value={formik.values.name!} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="destinationUrl">{t('destinationUrl')}</label>
              <InputText id="destinationUrl" value={formik.values.destinationUrl!} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="destinationUrl" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="event">{t('event')}</label>
              <Dropdown
                value={formik.values.event}
                onChange={(e) => {
                  void formik.setFieldValue('event', e.value ?? e.target.value);
                }}
                options={webhookEventsTranslations}
                optionLabel="name"
                optionValue="id"
              />
            </div>
            <div className="field">
              <label htmlFor="contractor">{t('contractors')}</label>
              <MultiSelect
                value={formik.values.contractorIds}
                name="scopes"
                optionLabel="name"
                optionValue="id"
                options={contractors}
                onChange={selectContractorsHandler}
                placeholder={t('multiSelectPlaceholder')}
                panelHeaderTemplate={multiSelectPanelHeader}
                maxSelectedLabels={0}
                selectedItemsLabel={`${t('multiSelectSelected')}: ${formik.values.contractorIds?.length ?? 0}`}
                showClear
                tooltip={t('multiSelectContractorsTooltip')}
              />
            </div>
            <WebhookAuthentication formik={formik} />
          </Dialog>
        </div>
      </form>
    </FormErrorMessageScroller>
  );
};

export default WebhookDialog;
