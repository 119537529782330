import { TFunction } from 'i18next';
import { NodeType } from '../types/node';

const tareTypeLabels = { DECLARED: 'declared', MEASURED: 'measured' };

const weighingModeLabels = {
  SINGLE: 'weighingModeLabelsSingle',
  DOUBLE: 'weighingModeLabelsDouble',
  MULTIPLE: 'weighingModeLabelsMultiple',
  WEIGHING_SERIES: 'weighingModeLabelsWeighingSeries',
  DOUBLE_WEIGHING_SERIES: 'weighingModeLabelsDoubleWeighingSeries',
  TARE: 'weighingModeLabelsTare',
  CONTROL: 'weighingModeLabelsControl',
};

const weighingModeExtendedLabels = {
  SINGLE: 'weighingModeExtendedLabelsSingle',
  DOUBLE: 'weighingModeExtendedLabelsDouble',
  MULTIPLE: 'weighingModeExtendedLabelsMultiple',
  WEIGHING_SERIES: 'weighingModeExtendedLabelsWeighingSeries',
  DOUBLE_WEIGHING_SERIES: 'weighingModeLabelsDoubleWeighingSeries',
  TARE: 'weighingModeExtendedLabelsTare',
  CONTROL: 'weighingModeExtendedLabelsControl',
};

const transactionTypeLabels = {
  DELIVERY: 'delivery',
  RECEIVE: 'receive',
};

const productsActiveTypeLabels = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

const getPermissionLabels = (t: TFunction) => {
  return {
    WEIGHING: t('weighing'),
    WEIGHINGS_READ: t('weighingRead'),
    WEIGHINGS_EDIT: t('weighingEdit'),
    WEIGHINGS_DELETE: t('weighingDelete'),
    WEIGHINGS_RESTORE: t('weighingRestore'),
    WEIGHINGS_REPORTS: t('weighingReports'),
    WASTE_REGISTER: t('wasteRegister'),
    DATASETS_EDIT: t('datasetsEdit'),
    USERS_MANAGEMENT: t('usersManagement'),
    LOCATIONS_MANAGEMENT: t('locationsManagement'),
    NODES_MANAGEMENT: t('scalesManagement'),
    NODE_MODELS_MANAGEMENT: t('indicatorTypesManagement'),
    EDGE_DEVICES_MANAGEMENT: t('edgeDevicesManagement'),
    ROLES_MANAGEMENT: t('rolesManagement'),
    SYSTEM_CONFIGURATION: t('systemConfiguration'),
    DATA_IMPORT_EXPORT: t('dataImportExport'),
    BILLING_DATA_EDIT: t('billingDataEdit'),
    ACCESS_CODES_MANAGEMENT: t('accessCodesManagement'),
    API_MANAGEMENT: t('apiManagement'),
    WEIGHINGS_CONFIRMATION: t('weighingConfirmation'),
    ACCESS_CONTROL: t('accessControl'),
    ACCESS_CONTROL_EVENT_LOG: t('accessControlEventLog'),
    ACCESS_CONTROL_ARRIVAL_NOTIFICATION: t('accessControlArrivalNotifications'),
    DICTIONARIES_MANAGEMENT: t('dictionariesManagement'),
  };
};

const nodeTypeLabels = {
  [NodeType.WEIGHT_INDICATOR]: 'weightIndicator',
  [NodeType.DISPLAY]: 'display',
  [NodeType.BARRIER]: 'barrier',
  [NodeType.CAMERA]: 'camera',
  [NodeType.IO]: 'io',
  [NodeType.POS_PRINTER]: 'posPrinter',
  [NodeType.RFID_READER]: 'rfid',
  [NodeType.BARCODE_SCANNER]: 'barcodeScanner',
};

const getTranslatedLabel = (labels: Record<string, string>, key: any, t: TFunction) => t(labels[key]);

export {
  tareTypeLabels,
  weighingModeLabels,
  weighingModeExtendedLabels,
  transactionTypeLabels,
  productsActiveTypeLabels,
  getPermissionLabels,
  nodeTypeLabels,
  getTranslatedLabel,
};
