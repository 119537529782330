const formChangeFieldMethod = (event, type, formikInstance) => {
  switch (type) {
    case 'checkbox':
      formikInstance.setFieldValue(event.target.id, event.checked, true);
      break;

    case 'text':
    case 'textarea':
      formikInstance.setFieldValue(event.target.id, event.target.value, true);
      break;

    case 'autocomplete':
    case 'dropdown':
    case 'switch':
    case 'date':
    case 'chips':
      formikInstance.setFieldValue(event.target.id, event.value, true);
      break;

    default:
      formikInstance.handleChange(event);
      break;
  }
};

export default formChangeFieldMethod;
