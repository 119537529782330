import { Dropdown } from 'primereact/dropdown';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { countryOptionTemplate, selectedCountryTemplate } from './LanguageSelectorsTemplates';
import { getAvailableLanguages } from '../../i18next/resources/languages-config';

const LanguageSelectorUnauthorized = ({ visible }) => {
  const { i18n } = useTranslation();
  const [value, setValue] = useState(i18n.language);

  //INFO: we have no user context here, so we can't check if we should use polish bdo language, so we always use false
  const languages = getAvailableLanguages(false);

  const handleChange = (e) => {
    const newValue = e.value !== undefined ? e.value : null;
    if (newValue !== value) {
      setValue(newValue);
      void i18n.changeLanguage(newValue);
    }
  };

  if (visible) {
    return (
      <div className="col-12 p-ai-start justify-content-end flex">
        <Dropdown
          value={value}
          options={languages}
          onChange={handleChange}
          required
          itemTemplate={countryOptionTemplate}
          valueTemplate={selectedCountryTemplate}
          className="language-selector"
        />
      </div>
    );
  }

  return <></>;
};

export default LanguageSelectorUnauthorized;
