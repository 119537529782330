import React from 'react';

interface CustomDividerProps {
  children?: React.ReactNode;
}

const CustomDivider = ({ children }: CustomDividerProps) => {
  if (children) {
    return <div className="custom-divider">{children}</div>;
  }
  return <div className="custom-divider"></div>;
};

export default CustomDivider;
