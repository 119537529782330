import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import statusUtils from '../statusUtils';
import FormErrorMessage from '../../../../_shared/FormErrorMessage';
import formChangeFieldMethod from '../../../../../utils/formChangeFieldMethod';
import { DEFAULT_DIALOG_BREAKPOINTS, DEFAULT_DIALOG_STYLE, CancelButton, SubmitButton } from '../../../bdoUtils';
import * as moment from 'moment';
import FormErrorMessageScroller from '../../../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';

const INIT_FORM_STATE = { vehicleRegNumber: '', realTransportDate: null, realTransportTime: null };

const ConfirmationGeneratedCarrier = ({ visible, closeDialog, item }) => {
  const { t } = useTranslation('kpokComponents');

  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const minDate = moment(item?.plannedTransportTime).subtract(1, 'days');
  const maxDate = moment(item?.plannedTransportTime).add(1, 'days');

  const getInitialValues = useCallback(async () => {
    const { vehicleRegNumber, plannedTransportTime, realTransportTime } = item;

    const initValuesObj = {
      ...INIT_FORM_STATE,
      vehicleRegNumber,
      realTransportDate: realTransportTime ? new Date(realTransportTime) : new Date(plannedTransportTime),
      realTransportTime: realTransportTime ? new Date(realTransportTime) : new Date(plannedTransportTime),
    };

    setInitFormValues(initValuesObj);

    formik.setTouched('plannedTransportTime');
    formik.setTouched('plannedTransportTimeDate');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [INIT_FORM_STATE, item]);

  useEffect(() => {
    if (visible) {
      getInitialValues();
    }
  }, [getInitialValues, visible]);

  const validateMethod = (data) => {
    const errors = {};

    if (!data.vehicleRegNumber) {
      errors.vehicleRegNumber = t('vehicleRegistrationNumberNotProvided');
    }

    if (!data.realTransportDate) {
      errors.realTransportDate = t('realTransportDateNotProvided');
    } else {
      const realTransportDateTime = moment(data.realTransportDate)
        .set('hour', moment(data.realTransportTime).get('hours'))
        .set('minutes', moment(data.realTransportTime).get('minutes'));
      if (moment(realTransportDateTime.toDate()).isBefore(new Date(Date.now()))) {
        errors.realTransportDate = t('realTransportDatePastDate');
      } else if (moment(data.realTransportDate).isBefore(minDate) || moment(data.realTransportDate).isAfter(maxDate)) {
        errors.realTransportDate = t('realTransportDateError');
      }
    }

    if (!data.realTransportTime) {
      errors.realTransportTime = t('realTransportTimeNotProvided');
    }

    return errors;
  };

  const handleCloseDialog = (changes, statusFilter) => {
    formik.resetForm({
      values: INIT_FORM_STATE,
    });
    closeDialog(changes === true, statusFilter);
  };

  const handleSubmit = async (data) => {
    await statusUtils.changeStatusMethods.carrier.CONFIRMATION_GENERATED({
      kpokId: item.kpokId,
      ...data,
    });

    handleCloseDialog(true, 'CONFIRMATION_GENERATED');
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const dialogFooter = (
    <div className="grid justify-content-center">
      <CancelButton onClick={handleCloseDialog} />
      <SubmitButton onClick={formik.submitForm} />
    </div>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={visible}
        header={t('confirmationGeneratedCarrierHeader')}
        modal
        className="p-fluid"
        maskClassName="higher-rank"
        breakpoints={DEFAULT_DIALOG_BREAKPOINTS}
        style={DEFAULT_DIALOG_STYLE}
        footer={dialogFooter}
        onHide={handleCloseDialog}
      >
        <form>
          <div className="field col-12">
            <label htmlFor="realTransportDate">
              {t('provideRealTransportDate')}
              <span className="text-red-500">&nbsp;*</span>
            </label>
            <Calendar
              inline
              id="realTransportDate"
              value={formik.values.realTransportDate}
              onChange={(e) => formChangeFieldMethod(e, 'date', formik)}
              dateFormat="yy-mm-dd"
              placeholder={t('calendarPlaceholder')}
              footerTemplate={() => <></>}
              minDate={moment(minDate).isAfter(new Date(Date.now())) ? minDate.toDate() : new Date(Date.now())}
              maxDate={maxDate.toDate()}
            />
            <FormErrorMessage fieldName="realTransportDate" formikInstance={formik} />

            <br />
            <br />

            <label htmlFor="realTransportTime">
              {t('provideRealTransportHour')}
              <span className="text-red-500">&nbsp;*</span>
            </label>
            <Calendar
              inline
              id="realTransportTime"
              value={formik.values.realTransportTime}
              onChange={(e) => formChangeFieldMethod(e, 'date', formik)}
              timeOnly
              hourFormat="24"
              placeholder={t('calendarPlaceholder')}
            />
            <FormErrorMessage fieldName="realTransportTime" formikInstance={formik} />

            <br />
            <br />

            <label htmlFor="vehicleRegNumber">
              {t('vehicleRegistrationNumber')}
              <span className="text-red-500">&nbsp;*</span>
            </label>
            <InputText
              id="vehicleRegNumber"
              value={formik.values.vehicleRegNumber || ''}
              onChange={(e) =>
                void formik.setFieldValue('vehicleRegNumber', (e?.target?.value ?? e?.value)?.toUpperCase())
              }
            />
            <FormErrorMessage fieldName="vehicleRegNumber" formikInstance={formik} />

            <br />
            <br />
            <br />
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};

export default ConfirmationGeneratedCarrier;
