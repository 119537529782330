import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { PickList } from 'primereact/picklist';
import { Toolbar } from 'primereact/toolbar';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import permissionsService from '../../../services/ScaleoApiServices/PermissionsService';
import rolesService from '../../../services/ScaleoApiServices/RolesService';
import { CustomTableField, CustomButton } from '../../_shared/CustomComponents';
import '../../DataTable.css';
import DataTableFilterElements from '../../_shared/DataTableFilterElements';
import FormErrorMessage from '../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../_shared/FormErrorMessageScroller';
import { useTranslation, Trans } from 'react-i18next';
import getPaginatorTemplate from '../../../utils/getPaginatorTemplate';
import { getPermissionLabels } from '../../../utils/labels';
import { ReducerState } from '../../../types/reducer-state';
import { Role, Permission } from '../../../types/role';

const EMPTY_ROLE = {
  id: null,
  name: '',
};

const EMPTY_FILTERS = {
  name: null,
};

const EMPTY_PAGINATION_PARAMETERS = {
  order: {
    isAscending: true,
    orderColumn: 'id',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const EMPTY_PAGINATOR_STATE = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

export const Roles = () => {
  const { t } = useTranslation('managementRoles');
  const { t: t1 } = useTranslation('utils');

  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState<Permission[] | []>([]);
  const [role, setRole] = useState(EMPTY_ROLE);
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);

  const [roleDialogVisible, setRoleDialogVisible] = useState(false);
  const [deleteRolesDialogVisible, setDeleteRolesDialogVisible] = useState(false);
  const [deleteRoleDialogVisible, setDeleteRoleDialogVisible] = useState(false);
  const [availablePermissions, setAvailablePermissions] = useState<Permission[]>([]);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const [filters, setFilters] = useState(EMPTY_FILTERS);
  const [paginationParameters, setPaginationParameters] = useState(EMPTY_PAGINATION_PARAMETERS);
  const [paginatorState, setPaginatorState] = useState(EMPTY_PAGINATOR_STATE);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const fetchRoles = useCallback(
    async (_filters, _paginatorParam) => {
      const addPermissionsLabels = (permissions: Permission[]) => {
        return permissions.map((permission: Permission) => {
          return { id: permission.id, name: (getPermissionLabels(t1) as any)[permission.id] || '' };
        });
      };

      Promise.all([rolesService.searchRoles(_filters, _paginatorParam), permissionsService.getPermissions()]).then(
        (response) => {
          const roles = response[0].data.data;
          setRoles(
            roles.map((role: any) => {
              return { ...role, permissions: addPermissionsLabels(role.permissions) };
            }),
          );
          setPermissions(addPermissionsLabels(response[1].data));
          response[0].data.total_pages
            ? setPaginatorState({
                ...paginatorState,
                totalPages: response[0].data.total_pages,
              })
            : setPaginatorState({
                ...paginatorState,
                totalPages: 1,
                currentPage: 1,
              });
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paginatorState],
  );

  useEffect(() => {
    fetchRoles(filters, paginationParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, paginationParameters, loggedUserContext.currentLocationId, loggedUserContext?.currentCustomer?.id]);

  const openNew = () => {
    setAvailablePermissions(permissions);
    setRole(EMPTY_ROLE);
    setRoleDialogVisible(true);
  };
  const hideDialog = () => {
    setRoleDialogVisible(false);
    formik.resetForm({
      values: {
        id: null,
        name: '',
        permissions: [],
      },
    });
    setAvailablePermissions([]);
  };
  const deleteSelectedRoles = () => {
    for (const _role of selectedRoles) {
      rolesService.removeRole(_role.id).then(() => {
        fetchRoles(filters, paginationParameters);
      });
    }
    setDeleteRolesDialogVisible(false);
    setSelectedRoles([]);
  };
  const confirmDeleteSelected = () => {
    setDeleteRolesDialogVisible(true);
  };
  const hideDeleteRolesDialog = () => {
    setDeleteRolesDialogVisible(false);
  };
  const confirmDeleteRole = (role: any) => {
    setRole(role);
    setDeleteRoleDialogVisible(true);
  };
  const hideDeleteRoleDialog = () => {
    setDeleteRoleDialogVisible(false);
  };

  const formik = useFormik({
    initialValues: {
      id: null,
      name: '',
      permissions: [],
    },
    validate: (data: any) => {
      const errors: any = {};

      if (!data.name) {
        errors.name = t('noNameError');
      }
      return errors;
    },
    onSubmit: (formData: any, helpers: any) => {
      if (!formData.id) {
        rolesService.createRole(formData).then(() => {
          fetchRoles(filters, paginationParameters)
            .catch((error) => {
              if (error.response.status === 409) {
                helpers.setFieldError('rolename', t('roleExistError'));
              }
            })
            .finally(() => helpers.setSubmitting(false));
        });
      } else {
        rolesService.editRole(formData, formData.id).then(() => {
          fetchRoles(filters, paginationParameters)
            .catch((error) => {
              if (error.response.status === 409) {
                helpers.setFieldError('name', t('roleExistError'));
              }
            })
            .finally(() => helpers.setSubmitting(false));
        });
      }
      setRoleDialogVisible(false);
      setRole(EMPTY_ROLE);
      formik.resetForm({
        values: {
          id: null,
          name: '',
          permissions: [],
        },
      });
      setAvailablePermissions([]);
    },
  });

  const editRole = (role: any) => {
    formik.resetForm({
      values: {
        id: role.id,
        name: role.name,
        permissions: role.permissions,
      },
    });

    setRole({ ...role });
    setRoleDialogVisible(true);
    setAvailablePermissions(permissions.filter((ar: any) => !role.permissions.find((rm: any) => rm.id === ar.id)));
  };

  const deleteRole = () => {
    rolesService.removeRole(role.id).then(() => {
      fetchRoles(filters, paginationParameters);
    });

    setDeleteRoleDialogVisible(false);
    setRole(EMPTY_ROLE);
  };

  const changeFilter = (value: any) => {
    const newFilters = { ...filters };
    newFilters.name = value;
    setFilters(newFilters);
  };

  const clearFilters = () => {
    setFilters(EMPTY_FILTERS);
  };

  useEffect(() => {
    setPaginationParameters((prevPaginationParameters) => ({
      ...prevPaginationParameters,
      page: {
        ...prevPaginationParameters.page,
        index: 1,
      },
    }));
  }, [filters]);

  useEffect(() => {
    if (paginatorState.currentPage > paginatorState.totalPages)
      setPaginatorState((prevPaginatorState) => ({
        ...prevPaginatorState,
        currentPage: 1,
      }));
  }, [paginatorState]);

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const header = (
    <div className="table-header text-2xl p-2">
      {t('header')} &#160;&#160;
      <div className="flex">
        <Button label={t('clearFilters')} icon="pi pi-filter-slash" outlined className="mr-2" onClick={clearFilters} />
      </div>
    </div>
  );

  const roleDialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={hideDialog} />
      <Button type="submit" label={t('saveButton')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );
  const deleteRolesDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteRolesDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteSelectedRoles} />
    </>
  );
  const deleteRoleDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteRoleDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteRole} />
    </>
  );

  const leftToolbarTemplate = () => {
    return (
      <>
        <Button label={t('addButton')} icon="pi pi-plus" severity="success" className="mr-2" onClick={openNew} />
        <CustomButton
          label={t('deleteButton')}
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={
            !selectedRoles || !selectedRoles.length || selectedRoles.map((role: any) => role?.isUsed).includes(true)
          }
          tooltip={!selectedRoles.length ? t('selectRole') : t('cannotDelete')}
        />
      </>
    );
  };
  const rightToolbarTemplate = () => <></>;

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <Button icon="pi pi-pencil" raised severity="success" className="mr-2" onClick={() => editRole(rowData)} />
        {rowData.isUsed === false ? (
          <Button icon="pi pi-trash" raised severity="warning" onClick={() => confirmDeleteRole(rowData)} />
        ) : (
          <Button
            icon="pi pi-trash"
            raised
            severity="secondary"
            tooltip={rowData.name}
            tooltipOptions={{ position: 'bottom' }}
          />
        )}
      </div>
    );
  };

  const columnBodyTemplate = (rowData: any, column: any) => {
    return <CustomTableField row={rowData} col={column} />;
  };

  const columns = [
    { field: 'name', header: t('name'), type: 'text' },
    { field: 'permissions', header: t('permissions'), type: 'multiple', width: '70%' },
  ];

  const dynamicColumns = columns.map((col: any) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={columnBodyTemplate}
        fieldsType={col.type}
        sortable={col.type !== 'multiple' ? true : false}
        filter={col.type !== 'multiple' ? true : false}
        showFilterMenu={false}
        style={col.width ? { width: col.width } : undefined}
        filterElement={
          col.type !== 'multiple' ? (
            <DataTableFilterElements.Text
              initialValue={(filters as any)[col.field]}
              onChangeFilter={changeFilter}
              name={col.field}
              placeholder={t('filter')}
            />
          ) : null
        }
      ></Column>
    );
  });

  const itemTemplate = (item: any) => {
    return <div className="product-item">{t(item.name)}</div>;
  };
  const onChange = (event: any) => {
    setAvailablePermissions(event.source);
    formik.setFieldValue('permissions', event.target);
  };

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                responsiveLayout="scroll"
                value={roles}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                rowHover
                removableSort
                selectionMode="checkbox"
                selection={selectedRoles}
                onSelectionChange={(e) => setSelectedRoles(e.value)}
                paginator
                rows={paginatorState.rows}
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState)}
                emptyMessage={t('noData')}
                stripedRows
                filterDisplay="row"
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                {dynamicColumns}
                <Column body={actionBodyTemplate} className="actionBody"></Column>
              </DataTable>
              <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
                <Dialog
                  visible={roleDialogVisible}
                  header={t('roleDetails')}
                  modal
                  className="p-fluid w-40vw"
                  footer={roleDialogFooter}
                  onHide={hideDialog}
                  breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
                >
                  <form>
                    <div className="col-12">
                      <div className="field">
                        <label htmlFor="name">{t('name')}</label>
                        <InputText id="name" value={formik.values.name} onChange={formik.handleChange} />
                        <FormErrorMessage fieldName="name" formikInstance={formik} />
                        <br />
                      </div>
                      <div className="field">
                        <PickList
                          source={availablePermissions}
                          target={formik.values.permissions}
                          itemTemplate={itemTemplate}
                          sourceHeader={t('permissionsSourceHeader')}
                          targetHeader={t('permissionsTargetHeader')}
                          sourceStyle={{ height: '262px' }}
                          targetStyle={{ height: '262px' }}
                          onChange={onChange}
                          showSourceControls={false}
                          showTargetControls={false}
                        ></PickList>
                      </div>
                    </div>
                  </form>
                </Dialog>
              </FormErrorMessageScroller>
              <Dialog
                visible={deleteRolesDialogVisible}
                className="p-fluid min-width-450px"
                header={
                  <span className="flex align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
                    {t('confirm')}
                  </span>
                }
                modal
                footer={deleteRolesDialogFooter}
                onHide={hideDeleteRolesDialog}
                breakpoints={{ '896px': '90vw' }}
              >
                <div className="confirmation-content">{role && <span>{t('multipleContent')}</span>}</div>
              </Dialog>
              <Dialog
                visible={deleteRoleDialogVisible}
                className="p-fluid min-width-450px"
                header={
                  <span className="flex align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
                    {t('confirm')}
                  </span>
                }
                modal
                footer={deleteRoleDialogFooter}
                onHide={hideDeleteRoleDialog}
                breakpoints={{ '896px': '90vw' }}
              >
                <div className="confirmation-content">
                  {role && (
                    <span>
                      <Trans t={t} i18nKey="singleContent" values={{ role: role.name }} />
                    </span>
                  )}
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Roles;
