import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { useFormik } from 'formik';
import statusUtils from '../statusUtils';
import FormErrorMessage from '../../../../_shared/FormErrorMessage';
import formChangeFieldMethod from '../../../../../utils/formChangeFieldMethod';
import {
  DEFAULT_DIALOG_BREAKPOINTS,
  DEFAULT_DIALOG_STYLE,
  CancelButton,
  SubmitButton,
  prepareCorrectDateFormat,
} from '../../../bdoUtils';
import * as moment from 'moment';
import FormErrorMessageScroller from '../../../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';

const INIT_FORM_STATE = { receiptEndingDate: new Date(Date.now()), receiptEndingTime: new Date(Date.now()) };

const TransportConfirmationCarrier = ({ visible, closeDialog, item }) => {
  const { t } = useTranslation('kpokComponents');

  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const getInitialValues = useCallback(async () => {
    setInitFormValues(INIT_FORM_STATE);

    formik.setTouched('receiptEndingDate');
    formik.setTouched('receiptEndingTime');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [INIT_FORM_STATE, item]);

  useEffect(() => {
    if (visible) {
      getInitialValues();
    }
  }, [getInitialValues, visible]);

  const validateMethod = (data) => {
    const errors = {};

    if (!data.receiptEndingDate) {
      errors.receiptEndingDate = t('receiptEndingDateNotProvided');
    } else if (data.receiptEndingTime) {
      const dateTime = new Date(data.receiptEndingDate);
      dateTime.setHours(
        data.receiptEndingTime.getHours(),
        data.receiptEndingTime.getMinutes(),
        data.receiptEndingTime.getSeconds(),
        data.receiptEndingTime.getMilliseconds(),
      );

      if (moment(item.plannedTransportTime).isAfter(dateTime) || moment(item.kpokLastModifiedAt).isBefore(dateTime)) {
        errors.receiptEndingDate = `${t('receiptEndingDateError')} ${moment(item.plannedTransportTime).format(
          'DD-MM-YYYY HH:mm',
        )} ${t('to')} ${moment(item.kpokLastModifiedAt).format('DD-MM-YYYY HH:mm')}`;
      }
    }

    if (!data.receiptEndingTime) {
      errors.receiptEndingTime = t('receiptEndingTimeNotProvided');
    }

    return errors;
  };

  const handleCloseDialog = (changes, statusFilter) => {
    formik.resetForm({
      values: INIT_FORM_STATE,
    });
    closeDialog(changes === true, statusFilter);
  };

  const handleSubmit = async (data) => {
    const tempDate = new Date(data.receiptEndingDate);
    tempDate.setHours(
      data.receiptEndingTime.getHours(),
      data.receiptEndingTime.getMinutes(),
      data.receiptEndingTime.getSeconds(),
      data.receiptEndingTime.getMilliseconds(),
    );
    await statusUtils.changeStatusMethods.carrier.TRANSPORT_CONFIRMATION({
      kpokId: item.kpokId,
      receiptEndingTime: prepareCorrectDateFormat(tempDate, tempDate),
    });

    handleCloseDialog(true, 'TRANSPORT_CONFIRMATION');
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const dialogFooter = (
    <div className="grid justify-content-center">
      <CancelButton onClick={handleCloseDialog} />
      <SubmitButton onClick={formik.submitForm} />
    </div>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={visible}
        header={t('transportConfirmationCarrierHeader')}
        modal
        className="p-fluid"
        maskClassName="higher-rank"
        breakpoints={DEFAULT_DIALOG_BREAKPOINTS}
        style={DEFAULT_DIALOG_STYLE}
        footer={dialogFooter}
        onHide={handleCloseDialog}
      >
        <form>
          <div className="field col-12">
            <label htmlFor="receiptEndingDate">
              {t('receiptEndingDate')}
              <span className="text-red-500">&nbsp;*</span>
            </label>
            <Calendar
              inline
              id="receiptEndingDate"
              value={formik.values.receiptEndingDate}
              onChange={(e) => formChangeFieldMethod(e, 'date', formik)}
              dateFormat="yy-mm-dd"
              placeholder={t('calendarPlaceholder')}
              footerTemplate={() => <></>}
            />
            <FormErrorMessage fieldName="receiptEndingDate" formikInstance={formik} />

            <br />
            <br />

            <label htmlFor="receiptEndingTime">
              {t('receiptEndingTime')}
              <span className="text-red-500">&nbsp;*</span>
            </label>
            <Calendar
              inline
              id="receiptEndingTime"
              value={formik.values.receiptEndingTime}
              onChange={(e) => formChangeFieldMethod(e, 'date', formik)}
              timeOnly
              hourFormat="24"
              placeholder={'calendarPlaceholder'}
            />
            <FormErrorMessage fieldName="receiptEndingTime" formikInstance={formik} />

            <br />
            <br />
            <br />
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};

export default TransportConfirmationCarrier;
