import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import weighingsService from '../../../../services/ScaleoApiServices/WeighingsService';
import { useTranslation } from 'react-i18next';
import '../../../DataTable.css';

interface CloseWeighingDialogProps {
  visible: boolean;
  weighingId: string | null;
  onWeighingClosed: () => void;
  onCancelled: () => void;
  setWeighingDialogVisible: (b: boolean) => void;
}

const CloseWeighingDialog = ({
  visible,
  weighingId,
  onWeighingClosed,
  onCancelled,
  setWeighingDialogVisible,
}: CloseWeighingDialogProps) => {
  const { t } = useTranslation('weighingHandlingWeighings');

  const handleDialogClose = () => {
    onCancelled();
  };

  const dialogFooter = (
    <>
      <Button
        type="reset"
        label={t('closeWeighingDialogCancelButton')}
        icon="pi pi-times"
        text
        onClick={handleDialogClose}
      />
      <Button
        type="submit"
        label={t('closeWeighingDialogCloseAndEditWeighingButton')}
        icon="pi pi-check"
        text
        onClick={() =>
          weighingsService.closeWeighing(weighingId!).then(() => {
            onWeighingClosed();
            setWeighingDialogVisible(true);
          })
        }
      />
      <Button
        type="submit"
        label={t('closeWeighingDialogCloseWeighingButton')}
        icon="pi pi-check"
        text
        onClick={() =>
          weighingsService.closeWeighing(weighingId!).then(() => {
            onWeighingClosed();
          })
        }
      />
    </>
  );

  return (
    <>
      {
        <Dialog
          visible={visible}
          breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
          header={<span className="flex align-items-center">{t('closeWeighingDialogConfirmDialogTitle')}</span>}
          modal
          className="p-fluid w-30vw"
          footer={dialogFooter}
          onHide={handleDialogClose}
        ></Dialog>
      }
    </>
  );
};

export default CloseWeighingDialog;
