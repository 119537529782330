import { useTranslation } from 'react-i18next';

export const ApiKeysTableHeaders = () => {
  const { t } = useTranslation('managementApiKey');

  return (
    <div className="p-fluid formgrid grid pb-10px">
      <div className="col-2">
        <label htmlFor="name">
          <b>{t('clientName')}</b>
        </label>
      </div>
      {process.env.REACT_APP_SCALEO_MODE === 'ONLINE' ? (
        <>
          <div className="col-3">
            <label htmlFor="id">
              <b>{t('clientId')}</b>
            </label>
          </div>
          <div className="col-6">
            <label htmlFor="secret">
              <b>{t('clientSecret')}</b>
            </label>
          </div>
        </>
      ) : (
        <div className="col-6">
          <label htmlFor="headerAuthorization">
            <b>{t('headerAuthorization')}</b>
          </label>
        </div>
      )}
      <div className="col-1 text-center">
        <label htmlFor="actions">
          <b>{t('actions')}</b>
        </label>
      </div>
    </div>
  );
};
