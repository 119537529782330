import webhooksService from '../../services/ScaleoApiServices/WebhooksService';
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReducerState } from '../../types/reducer-state';
import { Webhook } from 'aws-sdk/clients/codebuild';
import { Contractor } from '../../types/weighing';
import contractorsService from '../../services/ScaleoApiServices/ContractorsService';

export const useWebhooksData = () => {
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const [webhooks, setWebhooks] = useState<Webhook[]>([]);
  const [contractors, setContractors] = useState<Contractor[]>([]);

  const fetchAPI = useCallback(async () => {
    const webhooksResponse = await webhooksService.getWebhooks();
    if (webhooksResponse.data) {
      setWebhooks(webhooksResponse.data);
    }

    const contractorsResponse = await contractorsService.getContractors();
    if (contractorsResponse.data) {
      setContractors(contractorsResponse.data);
    }
  }, [setWebhooks, setContractors]);

  useEffect(() => {
    fetchAPI();
  }, [loggedUserContext.role, fetchAPI]);

  return { fetchAPI, webhooks, contractors };
};
