import { Button } from 'primereact/button';

import { Dialog } from 'primereact/dialog';
import {} from 'primereact/toolbar';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import '../../DataTable.css';
import FormErrorMessage from '../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../_shared/FormErrorMessageScroller';
import { NodeType } from '../../../types/node';
import { ReducerState } from '../../../types/reducer-state';
import { Message } from 'primereact/message';
import { Dropdown } from 'primereact/dropdown';
import { allowAddingScales, generateNodeTypeSource } from './helpers';
import WeighingIndicatorFields from './Fields/WeighingIndicatorFields';
import BarrierFields from './Fields/BarrierFields';
import CameraFields from './Fields/CameraFields';
import DisplayFields from './Fields/DisplayFields';
import BarcodeScannerFields from './Fields/BarcodeScannerFields';
import RfidReaderFields from './Fields/RfidReaderFields';
import PosPrinterFields from './Fields/PosPrinterFields';
import IoFields from './Fields/IoFields';
import { getTranslatedLabel, nodeTypeLabels } from '../../../utils/labels';

const NodesDialog = ({ data, formik, hideDialogHandler }: any) => {
  const { t } = useTranslation('managementNodes');
  const { t: t1 } = useTranslation('utils');

  const { scales } = useSelector((state: ReducerState) => state.user.context);

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const nodeDialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={hideDialogHandler} />
      <Button type="submit" label={t('saveButton')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );

  const selectedItemsLabel = () => {
    const selectedCamerasNumber = formik.values.selectedCameras?.length || 0;

    if (selectedCamerasNumber === 0) return '';

    const baseText = `${t('selectedItems')}: ${selectedCamerasNumber} `;

    let cameraTextKey;
    if (selectedCamerasNumber === 1) {
      cameraTextKey = 'camerasText1';
    } else if (selectedCamerasNumber < 5) {
      cameraTextKey = 'camerasText2';
    } else {
      cameraTextKey = 'camerasText3';
    }

    return baseText + t(cameraTextKey);
  };

  const onSelectedCamerasChanged = (event: any, formik: any) => {
    const newCameras = event.value;
    formik.setFieldValue('selectedCameras', newCameras);
  };

  const handleNodeTypeChange = (e: any) => {
    formik.setFieldValue('type', e.value);
  };

  const dialogFields = (type: NodeType) => {
    switch (type) {
      case NodeType.WEIGHT_INDICATOR:
        return (
          <WeighingIndicatorFields
            formik={formik}
            data={data}
            onSelectedCamerasChanged={onSelectedCamerasChanged}
            selectedItemsLabel={selectedItemsLabel}
          />
        );
      case NodeType.BARCODE_SCANNER:
        return <BarcodeScannerFields formik={formik} data={data} />;
      case NodeType.BARRIER:
        return <BarrierFields formik={formik} data={data} />;
      case NodeType.CAMERA:
        return <CameraFields formik={formik} data={data} />;
      case NodeType.DISPLAY:
        return <DisplayFields formik={formik} scales={scales} data={data} />;
      case NodeType.IO:
        return <IoFields formik={formik} data={data} />;
      case NodeType.POS_PRINTER:
        return <PosPrinterFields formik={formik} data={data} />;
      case NodeType.RFID_READER:
        return <RfidReaderFields formik={formik} data={data} />;

      default:
        return <></>;
    }
  };

  const elementTypeOptions = () => {
    let options = generateNodeTypeSource(loggedUserContext, t1);

    if (!allowAddingScales(loggedUserContext)) {
      return options.filter(({ value }) => value !== NodeType.WEIGHT_INDICATOR);
    }

    const currentType = formik.values.type;
    const isCurrentTypeAvailable = options.find((option) => option.value === currentType);

    if (currentType && !isCurrentTypeAvailable) {
      const currentTypeOption = {
        label: getTranslatedLabel(nodeTypeLabels, currentType, t1),
        value: currentType,
      };
      options = [...options, currentTypeOption];
    }

    return options;
  };

  return (
    <div>
      <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
        <Dialog
          visible={true}
          header={t('nodeDetails')}
          modal
          className="p-fluid"
          footer={nodeDialogFooter}
          onHide={hideDialogHandler}
          breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
          style={{ width: '40vw' }}
        >
          <form>
            <div className="col-12">
              {!allowAddingScales(loggedUserContext) && (
                <Message
                  id={`error-msg`}
                  severity="info"
                  text={t('blockAddingScales')}
                  style={{ marginBottom: '5vh' }}
                />
              )}
              <div className="field">
                <label htmlFor="type">{t('elementType')}</label>
                <Dropdown
                  id="type"
                  value={formik.values.type}
                  onChange={handleNodeTypeChange}
                  options={elementTypeOptions()}
                  optionValue="value"
                  optionLabel="label"
                />
                <FormErrorMessage fieldName="type" formikInstance={formik} />
              </div>
              {formik.values.type && <>{dialogFields(formik.values.type)}</>}
            </div>
          </form>
        </Dialog>
      </FormErrorMessageScroller>
    </div>
  );
};

export default NodesDialog;
