import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Message } from 'primereact/message';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import { Toolbar } from 'primereact/toolbar';
import { SplitButton } from 'primereact/splitbutton';
import { Tooltip } from 'primereact/tooltip';
import DataTableFilterElements from '../../../_shared/DataTableFilterElements';
import BdoApiService from '../../../../services/BdoApiService';
import statusUtils from '../components/statusUtils';
import tableUtils from '../components/tableUtils';
import EditGeneratedConfirmationCardDialog from './components/EditGeneratedConfirmationCardDialog';
import ViewCardDialog from './components/ViewCardDialog';
import getPaginatorTemplate from '../../../../utils/getPaginatorTemplate';
import { ClearFiltersButton, ChooseLocation, downloadReport } from '../../bdoUtils';
import { hasPermissions } from '../../../../utils/permissionsUtils';
import { useTranslation } from 'react-i18next';

const INIT_FILTERS = {
  cardStatus: null,
  plannedTransportTime: null,
  wasteCode: null,
  vehicleRegNumber: null,
  senderFirstNameAndLastName: null,
};

const INIT_PAGINATION_PARAMETERS = {
  order: {
    isAscending: false,
    orderColumn: tableUtils.translateFieldNameToColumnName(''),
  },
  page: {
    index: 1,
    size: 10,
  },
};

const INIT_PAGINATOR_STATE = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

const Receiver = () => {
  const { t } = useTranslation('kpokReceiver');
  const { t: t1 } = useTranslation('kpoUtils');

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [listData, setListData] = useState([]);
  const [isCorrectConfig, setIsCorrectConfig] = useState(null);

  const [filters, setFilters] = useState(INIT_FILTERS);

  const [paginationParameters, setPaginationParameters] = useState(INIT_PAGINATION_PARAMETERS);
  const [paginatorState, setPaginatorState] = useState(INIT_PAGINATOR_STATE);

  const [isOpenEditGeneratedConfirmationCardDialog, setIsOpenEditGeneratedConfirmationCardDialog] = useState(false);
  const [isOpenViewCardDialog, setIsOpenViewCardDialog] = useState(false);

  const [selectedRowToEdit, setSelectedRowToEdit] = useState(null);

  const loggedUserContext = useSelector((state) => state.user.context);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const refreshListData = useCallback(async () => {
    const correctConfig = (await BdoApiService.checkLocationEupConfig())?.data?.correctConfig;

    if (correctConfig === true) {
      BdoApiService.KPOK_getReceiverSearch(
        { ...filters, cardSubtype: activeTabIndex === 0 ? 'RECEIVE' : 'TRANSFER' },
        paginationParameters,
      )
        .then((response) => {
          setListData(response.data);
          setPaginatorState({
            ...paginatorState,
            totalPages: response.data.totalPagesNumber,
            currentPage: paginatorState.currentPage > response.data.totalPagesNumber ? 1 : paginatorState.currentPage,
          });
          setIsCorrectConfig(true);
        })
        .catch(() => {
          setListData([]);
        });
    } else if (correctConfig === false) {
      setIsCorrectConfig(false);
    } else {
      setIsCorrectConfig(null);
    }
    // eslint-disable-next-line
  }, [filters, paginationParameters, activeTabIndex]);

  useEffect(() => {
    setPaginationParameters({
      ...paginationParameters,
      page: {
        index: 1,
        size: 10,
      },
    });
    // eslint-disable-next-line
  }, [activeTabIndex]);

  useEffect(async () => {
    if (loggedUserContext.currentLocationId) {
      await refreshListData();
    }
  }, [loggedUserContext.currentLocationId, loggedUserContext.currentCustomer.id, refreshListData]);

  const handleClickClearFilters = () => {
    setFilters({});
  };

  const header = (
    <div className="table-header">
      {t('kpokReceiver')}
      <ClearFiltersButton onClick={handleClickClearFilters} />
    </div>
  );

  const leftToolbarTemplate = () => <ChooseLocation />;

  const rightToolbarTemplate = () => {
    const triggerButton = (self) => {
      self.currentTarget.nextSibling.click();
    };

    return (
      <>
        {hasPermissions(['WEIGHINGS_REPORTS'], loggedUserContext.permissions) && (
          <>
            <div className="reportTooltip">
              <SplitButton
                className="p-button-help my-split-button"
                label={t('generateReport')}
                icon="pi pi-chevron-down"
                iconPos="right"
                model={[
                  {
                    label: 'Excel',
                    icon: 'pi pi-file-excel',
                    command: () => {
                      downloadReport('text/csv', filters, paginationParameters, {
                        companyType: ['KPOK', 'Receiver'],
                        functionName: 'KPOK_getReceiverSearch',
                      });
                    },
                  },
                ]}
                onClick={triggerButton}
                disabled={!filters.plannedTransportTime || !filters.plannedTransportTime[1]}
              />
            </div>
            <Tooltip
              target=".reportTooltip"
              disabled={filters.plannedTransportTime && filters.plannedTransportTime[1]}
              position="bottom"
            >
              {t('pickDateRange')}
            </Tooltip>
          </>
        )}
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    const currentStatus = rowData.cardStatusCodeName;
    const actionOptions = {
      PLANNED: [{ label: t('preview'), value: 'view' }],
      APPROVED: [{ label: t('preview'), value: 'view' }],
      CONFIRMATION_GENERATED: [
        { label: t('preview'), value: 'view' },
        { label: t('changeStatus'), value: 'edit-conf-gen' },
      ],
      RECEIVE_CONFIRMATION: [{ label: t('preview'), value: 'view' }],
      TRANSPORT_CONFIRMATION: [{ label: t('preview'), value: 'view' }],
      WITHDRAWN: [{ label: t('preview'), value: 'view' }],
      REJECTED: [{ label: t('preview'), value: 'view' }],
    };

    const handleChangeOption = (e) => {
      switch (e.value) {
        case 'view':
          setSelectedRowToEdit(rowData);
          setIsOpenViewCardDialog(true);
          break;
        case 'edit-conf-gen':
          setSelectedRowToEdit(rowData);
          setIsOpenEditGeneratedConfirmationCardDialog(true);
          break;

        default:
          break;
      }
    };

    return (
      <div className="actions">
        <Dropdown options={actionOptions[currentStatus]} onChange={handleChangeOption} placeholder={t('options')} />
      </div>
    );
  };

  const handleCloseDialog = async (changes, statusFilter) => {
    setIsOpenEditGeneratedConfirmationCardDialog(false);
    setIsOpenViewCardDialog(false);
    if (statusFilter) {
      setFilters({ cardStatus: statusFilter });
    } else if (changes) {
      await refreshListData();
    }
  };

  const handleChangeFilter = (value, name) => {
    const newFilters = { ...filters };
    newFilters[name] = value;
    setFilters(newFilters);
  };

  const handleSort = (e) => {
    setPaginatorState({ ...paginatorState, currentPage: 1 });
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: tableUtils.translateFieldNameToColumnName(e.sortField),
      },
    });
  };

  if (isCorrectConfig === null) {
    return <></>;
  }

  if (isCorrectConfig === false) {
    return (
      <div className="card">
        <Message severity="error" text={t('moduleConfigError')} />
      </div>
    );
  }

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <EditGeneratedConfirmationCardDialog
            visible={isOpenEditGeneratedConfirmationCardDialog}
            closeDialog={handleCloseDialog}
            item={selectedRowToEdit}
          />
          <ViewCardDialog visible={isOpenViewCardDialog} closeDialog={handleCloseDialog} item={selectedRowToEdit} />
          <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
            <TabPanel header={t('header1')} />
            <TabPanel header={t('header2')} />
          </TabView>
          <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                responsiveLayout="scroll"
                value={listData.items}
                header={header}
                className="p-datatable-responsive"
                dataKey="kpokId"
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={tableUtils.translateColumnNameToFieldName(paginationParameters.order.orderColumn)}
                removableSort
                rowHover
                rows={paginatorState.rows}
                paginator
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState)}
                emptyMessage={t('noRecords')}
                onSort={handleSort}
                stripedRows
                filterDisplay="row"
              >
                <Column
                  field="cardNumber"
                  header={t('cardNumber')}
                  sortable
                  body={(rowData) => tableUtils.bodyTemplates.cardNumberBodyTemplate(rowData, t1)}
                  className="text-center"
                ></Column>
                <Column
                  field="cardStatus"
                  header={t('cardStatus')}
                  body={(rowData) => tableUtils.bodyTemplates.cardStatusBodyTemplate(rowData, t1)}
                  filter
                  showFilterMenu={false}
                  filterElement={
                    <DataTableFilterElements.Dropdown
                      onChangeFilter={handleChangeFilter}
                      name="cardStatus"
                      placeholder={t('filter')}
                      options={statusUtils.statusesFilterOptions(t1)}
                      initialValue={filters.cardStatus}
                    />
                  }
                  className="text-center"
                ></Column>
                <Column
                  field="plannedTransportTime"
                  header={t('plannedTransportTime')}
                  sortable
                  body={(rowData) =>
                    tableUtils.bodyTemplates.plannedTransportTimeBodyTemplate(rowData, t('plannedTransportTime'))
                  }
                  filter
                  showFilterMenu={false}
                  filterElement={
                    <DataTableFilterElements.Date
                      onChangeFilter={handleChangeFilter}
                      name="plannedTransportTime"
                      placeholder={t('filter')}
                      initialValue={filters.plannedTransportTime}
                    />
                  }
                  filterFunction={DataTableFilterElements.dateFilterFunction}
                  className="text-center"
                ></Column>
                <Column
                  field="wasteCode"
                  header={t('wasteCode')}
                  sortable
                  body={(rowData) => tableUtils.bodyTemplates.wasteCodeBodyTemplate(rowData, t1)}
                  filter
                  showFilterMenu={false}
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.wasteCode}
                      onChangeFilter={handleChangeFilter}
                      name="wasteCode"
                      placeholder={t('filter')}
                    />
                  }
                  className="text-center"
                ></Column>
                <Column
                  field="wasteCodeExtended"
                  header={t('wasteCodeExtended')}
                  body={(rowData) => tableUtils.bodyTemplates.wasteCodeExtendedBodyTemplate(rowData, t1)}
                  className="text-center"
                ></Column>
                <Column
                  field="hazardousWasteReclassification"
                  header={t('hazardousWasteReclassification')}
                  body={(rowData) => tableUtils.bodyTemplates.hazardousWasteReclassificationBodyTemplate(rowData, t1)}
                  className="text-center"
                ></Column>
                <Column
                  field="vehicleRegNumber"
                  header={t('vehicleRegistrationNumber')}
                  sortable
                  body={(rowData) => tableUtils.bodyTemplates.vehicleRegNumberBodyTemplate(rowData, t1)}
                  filter
                  showFilterMenu={false}
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.vehicleRegNumber}
                      onChangeFilter={handleChangeFilter}
                      name="vehicleRegNumber"
                      placeholder={t('filter')}
                    />
                  }
                  className="text-center"
                ></Column>
                <Column
                  field="senderName"
                  header={t('senderName')}
                  sortable
                  body={(rowData) => tableUtils.bodyTemplates.senderNameBodyTemplate(rowData, t1)}
                  filter
                  showFilterMenu={false}
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.senderName}
                      onChangeFilter={handleChangeFilter}
                      name="senderName"
                      placeholder={t('filter')}
                    />
                  }
                  className="text-center"
                ></Column>
                <Column body={actionBodyTemplate} className="w-120px text-center"></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Receiver;
