import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import edgeDevicesService from '../../../services/ScaleoApiServices/EdgeDevicesService';
import { CustomAutoComplete } from '../../_shared/CustomComponents';
import FormErrorMessage from '../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../_shared/FormErrorMessageScroller';
import { Station } from '../../../types/station';
import { EdgeDevice } from '../../../types/edge-device';

interface NewOrEditEdgeDeviceDialogProps {
  edgeDevice: Partial<EdgeDevice>;
  stations: Station[];
  onCancel: () => void;
  visible: boolean;
  onEdgeDeviceCreated: () => void;
  onEdgeDeviceUpdated: () => void;
}

export const NewOrEditEdgeDeviceDialog = ({
  edgeDevice,
  stations,
  onCancel,
  visible,
  onEdgeDeviceCreated,
  onEdgeDeviceUpdated,
}: NewOrEditEdgeDeviceDialogProps) => {
  const { t } = useTranslation('managementEdgeDevices');

  const emptyEdgeDevice: {
    id: string | null | undefined;
    code?: string | null;
    name: string | undefined;
    station: { id: string; name: string } | undefined;
  } = {
    id: null,
    code: null,
    name: '',
    station: {
      name: '',
      id: '',
    },
  };

  const formik = useFormik({
    initialValues: {
      ...emptyEdgeDevice,
    },
    validate: (data: any) => {
      const errors: any = {};

      if (!data.name) {
        errors.name = t('noNameError');
      } else if (data.name !== data.name.replace(/ /g, '')) {
        errors.name = t('incorrectName1Error');
      } else if (!validator.isAlphanumeric(data.name)) {
        errors.name = t('incorrectName2Error');
      }

      if (!data.station) {
        errors.station = t('noStationError');
      }

      return errors;
    },
    onSubmit: async (formData: any, helpers: any) => {
      if (!formData.id) {
        await edgeDevicesService
          .createEdgeDevice(formData)
          .then(() => {
            onEdgeDeviceCreated();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('edgeDeviceExistError'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        edgeDevicesService
          .updateEdgeDevice(formData, formData.id)
          .then(() => {
            onEdgeDeviceUpdated();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('edgeDeviceExistError'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
  });

  useEffect(() => {
    formik.resetForm({
      values: {
        id: edgeDevice.id,
        name: edgeDevice.name,
        station: edgeDevice.station,
      },
    } as any);
  }, [edgeDevice]);

  const edgeDeviceDialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={onCancel} />
      <Button type="submit" label={t('saveButton')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={visible}
        header={t('edgeDeviceDetails')}
        modal
        className="p-fluid w-40vw"
        footer={edgeDeviceDialogFooter}
        onHide={onCancel}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      >
        <form>
          <div className="col-12">
            <div className="field">
              <label htmlFor="name">{t('name')}</label>
              <InputText id="name" value={formik.values.name} onChange={formik.handleChange} autoFocus />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="station">{t('station')}</label>
              <CustomAutoComplete
                id="station"
                value={formik.values.station}
                handler={formik.handleChange}
                source={stations}
              />
              <FormErrorMessage fieldName="station" formikInstance={formik} />
            </div>
            <div className="field">{/* <ProcessSection /> */}</div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};
