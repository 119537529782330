import { t } from 'i18next';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { ReducerState } from '../../types/reducer-state';
import { emptyWeighing } from '../../components/Weighing/WeighingsList/components/WeighingListInit';
import { userActions } from '../../store/user-slice';
import { Weighing } from '../../types/weighing';
import weighingsService from '../../services/ScaleoApiServices/WeighingsService';

export const useWeighingsListDialogs = (
  handleFetchComponentData: () => Promise<void>,
  selectedWeighings: Weighing[] | null,
  setSelectedWeighings: (w: Weighing[] | null) => void,
  locations?: any[],
) => {
  const dispatch = useDispatch();

  const filters = useSelector((state: ReducerState) => state.user.filtersState.weighings);

  const [weighing, setWeighing] = useState<Partial<Weighing> | null>(emptyWeighing as any);

  const [weighingDialogVisible, setWeighingDialogVisible] = useState(false);
  const [weighingHistoryVisible, setWeighingHistoryVisible] = useState(false);
  const [deleteSelectedWeighingsDialogVisible, setDeleteSelectedWeighingsDialogVisible] = useState(false);
  const [deleteWeighingDialogVisible, setDeleteWeighingDialogVisible] = useState(false);
  const [restoreWeighingDialogVisible, setRestoreWeighingDialogVisible] = useState(false);
  const [reportDialogVisible, setReportDialogVisible] = useState(false);
  const [sendEmailDialogVisible, setSendEmailDialogVisible] = useState(false);

  const [reportFormat, setReportFormat] = useState<string | null>(null);

  const [isOpenDialogKpo, setIsOpenDialogKpo] = useState(false);
  const [isOpenDialogKpok, setIsOpenDialogKpok] = useState(false);
  const [dialogKpokMode, setDialogKpokMode] = useState('');
  const [bdoDialogData, setBdoDialogData] = useState<any | null>(null);

  const [isOpenSnapshotsDialog, setIsOpenSnapshotsDialog] = useState(false);
  const [clickedRowId, setClickedRowId] = useState<string | null>(null);

  const [weighingsReceiptsToSend, setWeighingsReceiptsToSend] = useState<any>();

  const handleOpenNew = () => {
    setWeighing(emptyWeighing as any);
    setWeighingDialogVisible(true);
  };

  const handleHideDialog = () => {
    setWeighingDialogVisible(false);
    setWeighing(emptyWeighing as any);
  };

  const handleDeleteWeighing = () => {
    weighing?.id &&
      void weighingsService.removeWeighing(weighing?.id).then(() => {
        void handleFetchComponentData();
      });

    setDeleteWeighingDialogVisible(false);
    setWeighing(emptyWeighing as any);
    dispatch(userActions.shouldRefreshLoggedUserContext(true));
  };

  const handleDeleteSelectedWeighings = () => {
    // TODO: przerobić na jeden strzał do backendu
    for (const _weighing of selectedWeighings!) {
      void weighingsService.removeWeighing(_weighing.id!).then(() => {
        void handleFetchComponentData();
      });
    }
    setDeleteSelectedWeighingsDialogVisible(false);
    setSelectedWeighings(null);
    dispatch(userActions.shouldRefreshLoggedUserContext(true));
  };

  const handleClickDeleteSelectedWeighings = () => {
    setDeleteSelectedWeighingsDialogVisible(true);
  };

  const handleCloseDeleteWeighingDialog = () => {
    setDeleteWeighingDialogVisible(false);
  };

  const handleCloseRestoreWeighingDialog = () => {
    setRestoreWeighingDialogVisible(false);
  };

  const handleCloseDeleteSelectedWeighingsDialog = () => {
    setDeleteSelectedWeighingsDialogVisible(false);
  };

  const handleHideReportDialog = () => {
    setReportDialogVisible(false);
  };

  const restoreWeighing = () => {
    weighing?.id &&
      void weighingsService.restoreWeighing(weighing?.id).then(() => {
        void handleFetchComponentData();
      });

    setRestoreWeighingDialogVisible(false);
    setWeighing(null);
  };

  const restoreWeighingDialogFooter = (
    <>
      <Button
        type="button"
        label={t('no')}
        icon="pi pi-times"
        className="p-button-text"
        onClick={handleCloseRestoreWeighingDialog}
      />
      <Button type="button" label={t('yes')} icon="pi pi-check" className="p-button-text" onClick={restoreWeighing} />
    </>
  );

  const getReportDialogFilters = () => {
    const locationName = filters['locations.name'];
    if (!locationName) {
      return filters;
    }

    return {
      ...filters,
      label: locations?.find((location: any) => location.value === locationName)?.label,
    };
  };

  const showSendEmailDialog = (weighings: Weighing[] | null) => {
    setWeighingsReceiptsToSend(weighings?.map((weighing) => weighing.id));
    setSendEmailDialogVisible(true);
  };

  const hideSendEmailDialog = () => {
    setSendEmailDialogVisible(false);
  };

  const loadAfterChange = () => {
    void handleFetchComponentData();
    dispatch(userActions.shouldRefreshLoggedUserContext(true));
  };

  const handleCloseDialog = (changes: any) => {
    setIsOpenDialogKpo(false);
    setIsOpenDialogKpok(false);

    if (changes) {
      void handleFetchComponentData();
    }
  };

  return {
    setClickedRowId,
    setIsOpenSnapshotsDialog,
    setBdoDialogData,
    setDialogKpokMode,
    setIsOpenDialogKpo,
    setIsOpenDialogKpok,
    setWeighing,
    setWeighingDialogVisible,
    showSendEmailDialog,
    handleOpenNew,
    handleClickDeleteSelectedWeighings,
    setReportFormat,
    setReportDialogVisible,
    setWeighingHistoryVisible,
    setDeleteWeighingDialogVisible,
    setRestoreWeighingDialogVisible,
    weighing,
    weighingDialogVisible,
    weighingHistoryVisible,
    deleteSelectedWeighingsDialogVisible,
    deleteWeighingDialogVisible,
    restoreWeighingDialogVisible,
    reportDialogVisible,
    sendEmailDialogVisible,
    reportFormat,
    isOpenDialogKpo,
    isOpenDialogKpok,
    dialogKpokMode,
    bdoDialogData,
    isOpenSnapshotsDialog,
    clickedRowId,
    weighingsReceiptsToSend,
    handleHideDialog,
    handleDeleteWeighing,
    handleDeleteSelectedWeighings,
    handleCloseDeleteWeighingDialog,
    handleCloseRestoreWeighingDialog,
    handleCloseDeleteSelectedWeighingsDialog,
    handleHideReportDialog,
    restoreWeighing,
    restoreWeighingDialogFooter,
    getReportDialogFilters,
    hideSendEmailDialog,
    loadAfterChange,
    handleCloseDialog,
  };
};
