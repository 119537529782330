import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usersService from '../../../services/ScaleoApiServices/UsersService';
import getPaginatorTemplate from '../../../utils/getPaginatorTemplate';
import '../../DataTable.css';
import { CustomTableField } from '../../_shared/CustomComponents';
import DataTableFilterElements from '../../_shared/DataTableFilterElements';
import InviteNewUserDialog from './InviteNewUserDialog';
import NewOrEditUserDialog from './NewOrEditUserDialog';
import { useTranslation, Trans } from 'react-i18next';
import systemConfigurationService from '../../../services/ScaleoApiServices/SystemConfigurationService';
import { User } from '../../../types/user';
import { Tooltip } from 'primereact/tooltip';
import { ReducerState } from '../../../types/reducer-state';
import rolesService from '../../../services/ScaleoApiServices/RolesService';
import nodesService from '../../../services/ScaleoApiServices/NodesService';
import locationsService from '../../../services/ScaleoApiServices/LocationsService';
import { Node } from '../../../types/node';
import { userActions } from '../../../store/user-slice';

const ColumnAny = Column as any;

const emptyUser = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  newPassword: false,
  password: '',
  confPassword: '',
  role: '',
  nodeIds: [],
  locationIds: [],
};

const emptyFilters = {
  global: null,
  firstName: null,
  lastName: null,
  email: null,
};

const emptyPaginationParameters = {
  order: {
    isAscending: true,
    orderColumn: 'id',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const emptyPaginatorState = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

export const Users = () => {
  const { t } = useTranslation('managementUsers');
  const [users, setUsers] = useState<User[] | null>(null);
  const [smtpConfig, setSmtpConfig] = useState<any | null>(null);
  const [user, setUser] = useState<User | null>();
  const [selectedUsers, setSelectedUsers] = useState<User[] | null>(null);
  const [selectedRowToEdit, setSelectedRowToEdit] = useState<User | null>(null);
  const dispatch = useDispatch();

  const [userDialogVisible, setUserDialogVisible] = useState(false);
  const [inviteUserDialogVisible, setInviteUserDialogVisible] = useState(false);
  const [deleteUsersDialogVisible, setDeleteUsersDialogVisible] = useState(false);
  const [deleteUserDialogVisible, setDeleteUserDialogVisible] = useState(false);
  const [filters, setFilters] = useState<any>(emptyFilters);
  const [paginationParameters, setPaginationParameters] = useState(emptyPaginationParameters);
  const [paginatorState, setPaginatorState] = useState(emptyPaginatorState);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const [nodes, setNodes] = useState<Node[] | null>(null);
  const [userRoles, setUserRoles] = useState(null);
  const [locations, setLocations] = useState<any[] | null>(null);

  const dt = useRef(null);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const fetchComponentData = useCallback(
    async (filters, paginatorParam) => {
      Promise.all([
        usersService.searchUsers(filters, paginatorParam),
        systemConfigurationService.getSMTPConfig(),
        rolesService.getRoles(),
        nodesService.searchNodes(),
        locationsService.getAllLocations(),
      ]).then((response) => {
        setUsers(response[0].data.data);
        setSmtpConfig(response[1].data);
        response[0].data.totalPages
          ? setPaginatorState({
              ...paginatorState,
              totalPages: response[0].data.totalPages,
            })
          : setPaginatorState({
              ...paginatorState,
              totalPages: 1,
              currentPage: 1,
            });
        setUserRoles(response[2].data);
        setNodes(response[3]);
        setLocations(response[4].data);
      });
    },
    [paginatorState],
  );

  useEffect(() => {
    fetchComponentData(filters, paginationParameters);
  }, [
    filters,
    paginationParameters,
    loggedUserContext.currentCustomer?.id,
    loggedUserContext.currentLocationId,
    userDialogVisible,
    inviteUserDialogVisible,
  ]);

  const inviteNew = () => {
    setInviteUserDialogVisible(true);
  };

  const addNew = () => {
    setUserDialogVisible(true);
  };

  const addButtonLogic = () => {
    smtpConfig?.isClientSMTP || process.env.REACT_APP_SCALEO_MODE === 'ONLINE' ? inviteNew() : addNew();
  };

  const deleteSelectedUsers = () => {
    for (const user of selectedUsers!) {
      usersService.removeUser(user.id!).then(() => {
        fetchComponentData(filters, paginationParameters);
      });
    }
    dispatch(userActions.shouldRefreshLoggedUserContext(true));
    setDeleteUsersDialogVisible(false);
    setSelectedUsers(null);
  };
  const confirmDeleteSelected = () => {
    setDeleteUsersDialogVisible(true);
  };
  const hideDeleteUsersDialog = () => {
    setDeleteUsersDialogVisible(false);
  };
  const confirmDeleteUser = (user: User) => {
    setUser(user);
    setDeleteUserDialogVisible(true);
  };
  const resendInvitation = (user: User) => {
    usersService.resendInvitation({ userId: user.id }).then(() => {
      fetchComponentData(filters, paginationParameters);
    });
  };
  const hideDeleteUserDialog = () => {
    setDeleteUserDialogVisible(false);
  };

  const deleteUser = () => {
    usersService.removeUser(user!.id!).then(() => {
      fetchComponentData(filters, paginationParameters);
    });

    dispatch(userActions.shouldRefreshLoggedUserContext(true));
    setDeleteUserDialogVisible(false);
    setUser(emptyUser);
  };

  const changeFilter = (value: any, name: string) => {
    const newFilters = { ...filters };
    newFilters[name as keyof typeof newFilters] = value;
    setFilters(newFilters);
  };

  const clearFilters = () => {
    setFilters({} as any);
  };

  useEffect(() => {
    setPaginationParameters((prevPaginationParameters) => ({
      ...prevPaginationParameters,
      page: {
        ...prevPaginationParameters.page,
        index: 1,
      },
    }));
  }, [filters]);

  useEffect(() => {
    if (paginatorState.currentPage > paginatorState.totalPages)
      setPaginatorState((prevPaginatorState) => ({
        ...prevPaginatorState,
        currentPage: 1,
      }));
  }, [paginatorState]);

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const closeInviteNewUserDialogHandler = () => {
    setInviteUserDialogVisible(false);
  };

  const closeUserDialogHandler = () => {
    setUserDialogVisible(false);
  };

  const header = (
    <div className="table-header text-2xl p-2">
      {t('header')} &#160;&#160;
      <div className="flex">
        <Button label={t('clearFilters')} icon="pi pi-filter-slash" outlined className="mr-2" onClick={clearFilters} />
        <span>
          <DataTableFilterElements.Text
            initialValue={filters['global']}
            onChangeFilter={changeFilter}
            name={'global'}
            placeholder={t('searchPlaceholder')}
            isSearchIcon
          />
        </span>
      </div>
    </div>
  );

  const deleteUsersDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteUsersDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteSelectedUsers} />
    </>
  );
  const deleteUserDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteUserDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteUser} />
    </>
  );

  const allowAddingUsers = () =>
    Number.isInteger(loggedUserContext.limits?.maximumUsersNumber) &&
    Number.isInteger(loggedUserContext.limitsState?.currentUsersNumber)
      ? loggedUserContext.limits!.maximumUsersNumber! > loggedUserContext.limitsState!.currentUsersNumber
      : true;

  const leftToolbarTemplate = () => {
    return (
      <>
        <div className="reportTooltip">
          <Button
            label={t('addButton')}
            icon="pi pi-plus"
            severity="success"
            className="mr-2"
            onClick={addButtonLogic}
            disabled={!allowAddingUsers()}
          />
          {!allowAddingUsers() && (
            <Tooltip target=".reportTooltip" position="bottom">
              {t('blockAdding')}
            </Tooltip>
          )}
        </div>
        <Button
          label={t('deleteButton')}
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedUsers || !selectedUsers.length}
        />
      </>
    );
  };
  const rightToolbarTemplate = () => {
    return <></>;
  };

  const actionBodyTemplate = (rowData: User) => {
    const options = [
      { label: t('edit'), value: 'edit', icon: 'pi pi-pencil' },
      { label: t('delete'), value: 'delete', icon: 'pi pi-trash' },
    ];

    rowData.status === 'INVITATION_SENT' &&
      options.push({ label: t('resendNewInvite'), value: 'resend-invitation', icon: 'pi pi-envelope' });

    const handleChangeOption = (e: any) => {
      switch (e.value) {
        case 'edit':
          editUser(rowData);
          break;
        case 'delete':
          confirmDeleteUser(rowData);
          break;
        case 'resend-invitation':
          resendInvitation(rowData);
          break;

        default:
          break;
      }
    };

    const dropdownOptionTemplate = (option: any) => {
      return (
        <div className="item">
          <i className={option.icon}></i>&#160;
          {option.label}
        </div>
      );
    };

    return (
      <div className="actions">
        <Dropdown
          options={options}
          onChange={handleChangeOption}
          placeholder={t('dropdownPlaceholder')}
          itemTemplate={dropdownOptionTemplate}
        />
      </div>
    );
  };

  const statusBodyTemplate = (rowData: User) => {
    return (
      <React.Fragment>
        {rowData.status === 'ACTIVE' && (
          <Tag className="mr-2" icon="pi pi-check" severity="success" value={t('active')}></Tag>
        )}
        {rowData.status === 'INVITATION_SENT' && (
          <Tag className="mr-2" icon="pi pi-info-circle" severity="info" value={t('invitationSent')}></Tag>
        )}
        {rowData.status === 'PENDING_CONFIRMATION' && (
          <Tag className="mr-2" icon="pi pi-info-circle" severity="info" value={t('pendingConfirmation')}></Tag>
        )}
        {rowData.status === 'PASSWORD_CHANGE_REQUIRED' && (
          <Tag className="mr-2" icon="pi pi-info-circle" severity="info" value={t('passwordChangeRequired')}></Tag>
        )}
      </React.Fragment>
    );
  };

  const editUser = (_user: User) => {
    setUser(_user);
    setSelectedRowToEdit(_user);
    setUserDialogVisible(true);
  };

  const columnBodyTemplate = (rowData: User, column: string) => {
    return <CustomTableField row={rowData} col={column} withoutHeaders={undefined} />;
  };

  const columns = [
    { field: 'email', header: t('email'), type: 'text' },
    { field: 'firstName', header: t('firstName'), type: 'text' },
    { field: 'lastName', header: t('lastName'), type: 'text' },
  ];

  const dynamicColumns = columns.map((col) => {
    return (
      <ColumnAny
        key={col.field}
        field={col.field}
        header={col.header}
        body={columnBodyTemplate}
        fieldsType={col.type}
        sortable
        filter
        showFilterMenu={false}
        filterElement={
          <DataTableFilterElements.Text
            initialValue={filters[col.field]}
            onChangeFilter={changeFilter}
            name={col.field}
            placeholder={t('filters')}
          />
        }
      ></ColumnAny>
    );
  });

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                responsiveLayout="scroll"
                ref={dt}
                value={users as any}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                rowHover
                removableSort
                selectionMode="checkbox"
                selection={selectedUsers}
                onSelectionChange={(e: any) => setSelectedUsers(e.value)}
                paginator
                rows={paginatorState.rows}
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState) as any}
                emptyMessage={t('noData')}
                stripedRows
                filterDisplay="row"
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                {dynamicColumns}
                <Column body={statusBodyTemplate} header={t('status')} className="status"></Column>
                <Column body={actionBodyTemplate} className="actionBody"></Column>
              </DataTable>
              {userDialogVisible && (
                <NewOrEditUserDialog
                  closeDialogHandler={closeUserDialogHandler}
                  user={selectedRowToEdit!}
                  nodes={nodes}
                  setNodes={setNodes}
                  userRoles={userRoles}
                  locations={locations}
                />
              )}
              {inviteUserDialogVisible && (
                <InviteNewUserDialog
                  closeDialogHandler={closeInviteNewUserDialogHandler}
                  smtpConfig={smtpConfig}
                  nodes={nodes}
                  setNodes={setNodes}
                  userRoles={userRoles}
                  locations={locations}
                />
              )}
              <Dialog
                visible={deleteUsersDialogVisible}
                className="p-fluid min-width-450px"
                header={
                  <span className="flex align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
                    {t('confirm')}
                  </span>
                }
                modal
                footer={deleteUsersDialogFooter}
                onHide={hideDeleteUsersDialog}
                breakpoints={{ '896px': '90vw' }}
              >
                <div className="confirmation-content">{selectedUsers && <span>{t('multipleContent')}</span>}</div>
              </Dialog>
              <Dialog
                visible={deleteUserDialogVisible}
                className="p-fluid min-width-450px"
                header={
                  <span className="flex align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
                    {t('confirm')}
                  </span>
                }
                modal
                footer={deleteUserDialogFooter}
                onHide={hideDeleteUserDialog}
                breakpoints={{ '896px': '90vw' }}
              >
                <div className="confirmation-content">
                  {user && (
                    <span>
                      <Trans t={t} i18nKey="deleteContent" values={{ user: user.email }} />
                    </span>
                  )}
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
