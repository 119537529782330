import axios from 'axios';

class AppService {
  async getVersion() {
    return axios.get('/version');
  }
}

const appService = new AppService();
export default appService;
