import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { Station } from '../../../../types/station';
import { useStationForm } from '../../../../hooks/Stations/useStationForm';
import { CustomAutoComplete } from '../../../_shared/CustomComponents';

interface StationDialogProps {
  visible: boolean;
  setStationDialogVisible: (b: boolean) => void;
  onStationAdded?: (station: Station) => void;
  station: Station | null;
  locations: any[] | null;
}

export const StationDialog = ({
  visible,
  setStationDialogVisible,
  onStationAdded,
  station,
  locations,
}: StationDialogProps) => {
  const { t } = useTranslation('managementStations');

  const { formik, hideDialog } = useStationForm(station, onStationAdded, setStationDialogVisible);

  const stationDialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={hideDialog} />
      <Button type="submit" label={t('saveButton')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={visible}
        header={t('stationDetails')}
        modal
        className="p-fluid"
        footer={stationDialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="field">
            <label htmlFor="name">{t('name')}</label>
            <InputText id="name" value={formik.values.name} onChange={formik.handleChange} autoFocus />
            <FormErrorMessage fieldName="name" formikInstance={formik} />
          </div>
          <div className="field">
            <label htmlFor="installationId">{t('installationId')}</label>
            <InputText
              id="installationId"
              value={formik.values.installationId}
              onChange={formik.handleChange}
              autoFocus
            />
            <FormErrorMessage fieldName="installationId" formikInstance={formik} />
          </div>
          <div className="field">
            <label htmlFor="location">{t('location')}</label>
            <CustomAutoComplete
              id="location"
              value={locations?.find((l) => l.id === formik.values.locationId) ?? formik.values.locationId}
              handler={(e: any) => {
                void formik.setFieldValue('locationId', e.value?.id ?? e.target?.value?.id ?? e.target?.value);
              }}
              source={locations!}
              lookupField={'name'}
            />
            <FormErrorMessage fieldName="location" formikInstance={formik} withTouchedTrue />
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};
