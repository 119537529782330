import { Fieldset } from 'primereact/fieldset';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import appService from '../../services/ScaleoApiServices/AppService';

const Version = () => {
  const { t } = useTranslation('versionComponent');

  const [version, setVersion] = useState(null);

  useEffect(() => {
    void appService.getVersion().then((response) => {
      setVersion(response.data);
    });
  }, []);

  return (
    <div className="card" style={{ paddingBottom: '70px' }}>
      <Fieldset legend={t('header')}>
        <h4>{version}</h4>
      </Fieldset>
    </div>
  );
};
export default Version;
