import { useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import '../DataTable.css';
import { useSelector } from 'react-redux';
import { ReducerState } from '../../types/reducer-state';
import { useTranslation } from 'react-i18next';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import FormErrorMessage from '../_shared/FormErrorMessage';
import { CustomAutoComplete } from '../_shared/CustomComponents';

import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { AccessControlArrivalNotificationDialogProps } from './types';
import { Vehicle } from '../../types/weighing';
import { InputSwitch } from 'primereact/inputswitch';
import AdditionalFields from '../DataSets/AccessCodes/components/AdditionalFields';
import { Trailer } from '../../types/trailer';

const AccessControlArrivalNotificationDialog = ({
  isVisible,
  arrivalNotificationData,
  contractors,
  drivers,
  products,
  vehicles,
  closeDialog,
  formikInstance,
  updateForm,
  dictionaries,
  trailers,
}: AccessControlArrivalNotificationDialogProps) => {
  const { t } = useTranslation('accessControlArrivalNotification');
  const { weighingConfiguration } = useSelector((state: ReducerState) => state.weighing);
  const { additionalFields = [] } = weighingConfiguration || {};

  useEffect(() => {
    if (isVisible && arrivalNotificationData?.id) {
      const {
        id,
        declaredNetto,
        declaredArrivalTime,
        entry,
        entryUsed,
        exit,
        exitUsed,
        validFrom,
        validTo,
        additionalFields: itemAdditionalFields,
      } = arrivalNotificationData;

      const contractor = contractors.find((c) => c.id === arrivalNotificationData?.contractor?.id);
      const driver = drivers.find((d) => d.id === arrivalNotificationData?.driver?.id);
      const product = products.find((p) => p.id === arrivalNotificationData?.product?.id);
      const vehicle = vehicles.find((v) => v.id === arrivalNotificationData?.vehicle?.id);
      const trailer = trailers.find((t) => t.id === arrivalNotificationData?.trailer?.id);

      updateForm({
        id,
        vehicle,
        driver,
        contractor,
        product,
        trailer,
        declaredNetto,
        declaredArrivalTime,
        entry,
        entryUsed,
        exit,
        exitUsed,
        validFrom,
        validTo,
        additionalFields: additionalFields.map((a: any) => ({ ...a, value: itemAdditionalFields?.[a.id] })),
      });
    } else {
      updateForm({
        declaredNetto: null,
        declaredArrivalTime: null,
        entry: false,
        entryUsed: false,
        exit: false,
        exitUsed: false,
        validFrom: null,
        validTo: null,
        additionalFields,
      });
    }
  }, [arrivalNotificationData, isVisible, JSON.stringify(additionalFields)]);

  const dialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={closeDialog} />
      <Button type="submit" label={t('saveButton')} icon="pi pi-check" text onClick={formikInstance.submitForm} />
    </>
  );

  return (
    <div className="access-code-edit-dialog">
      <FormErrorMessageScroller formikInstance={formikInstance} beforeScroll={undefined}>
        <Dialog
          visible={isVisible}
          header={t('dialogHeader')}
          modal
          className="p-fluid w-40vw"
          breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
          footer={dialogFooter}
          onHide={closeDialog as any}
        >
          <form>
            <div className="col-12">
              <div className="field">
                <label htmlFor="vehicle">{t('registrationNumber')}</label>
                <CustomAutoComplete
                  id="vehicle"
                  value={formikInstance.values.vehicle?.registrationNumber}
                  handler={formikInstance.handleChange}
                  source={vehicles.map((vehicle: Vehicle) => ({ ...vehicle, name: vehicle.registrationNumber }))}
                />

                <FormErrorMessage fieldName="vehicle" formikInstance={formikInstance} />
              </div>
              {weighingConfiguration?.addTrailerRegistrationNumbersSupport && (
                <div className="field">
                  <label htmlFor="trailer">{t('trailerRegistrationNumber')}</label>
                  <CustomAutoComplete
                    id="trailer"
                    value={formikInstance.values.trailer?.registrationNumber}
                    handler={formikInstance.handleChange}
                    source={trailers?.map((trailer: Trailer) => ({ ...trailer, name: trailer.registrationNumber }))}
                  />

                  <FormErrorMessage fieldName="trailer" formikInstance={formikInstance} />
                </div>
              )}
              <div className="field">
                <label htmlFor="declaredNetto">{t('declaredNetto')}</label>
                <InputNumber
                  id="index"
                  value={formikInstance.values.declaredNetto}
                  mode="decimal"
                  max={214748364}
                  onChange={(e) => {
                    formikInstance.setFieldValue('declaredNetto', e.value);
                  }}
                />
              </div>
              <div className="field">
                <label htmlFor="driver">{t('driver')}</label>
                <CustomAutoComplete
                  id="driver"
                  value={formikInstance.values.driver}
                  handler={formikInstance.handleChange}
                  source={drivers}
                />
              </div>
              <div className="field">
                <label htmlFor="contractor">{t('contractor')}</label>
                <CustomAutoComplete
                  id="contractor"
                  value={formikInstance.values.contractor}
                  handler={formikInstance.handleChange}
                  source={contractors}
                />
              </div>
              <div className="field">
                <label htmlFor="product">{t('product')}</label>
                <CustomAutoComplete
                  id="product"
                  value={formikInstance.values.product}
                  handler={formikInstance.handleChange}
                  source={products}
                />
              </div>
              <div className="field">
                <label htmlFor="declaredArrivalTime">{t('declaredArrivalTime')}</label>
                <Calendar
                  id={`declaredArrivalTime`}
                  dateFormat="dd.mm.yy"
                  value={
                    formikInstance.values.declaredArrivalTime
                      ? new Date(formikInstance.values.declaredArrivalTime)
                      : undefined
                  }
                  onChange={(e) => {
                    formikInstance.setFieldValue('declaredArrivalTime', e.value);
                  }}
                  showTime
                  showSeconds
                />
                <FormErrorMessage fieldName={`declaredArrivalTime`} formikInstance={formikInstance} />
              </div>
              <div className="field">
                <label htmlFor="validFrom">{t('validFrom')}</label>
                <Calendar
                  id={`validFrom`}
                  dateFormat="dd.mm.yy"
                  value={formikInstance.values.validFrom ? new Date(formikInstance.values.validFrom) : undefined}
                  onChange={(e) => {
                    formikInstance.setFieldValue('validFrom', e.value);
                  }}
                  showTime
                  showSeconds
                />
                <FormErrorMessage fieldName={`validFrom`} formikInstance={formikInstance} />
              </div>
              <div className="field">
                <label htmlFor="validTo">{t('validTo')}</label>
                <Calendar
                  id={`validTo`}
                  dateFormat="dd.mm.yy"
                  value={formikInstance.values.validTo ? new Date(formikInstance.values.validTo) : undefined}
                  onChange={(e) => {
                    formikInstance.setFieldValue('validTo', e.value);
                  }}
                  showTime
                  showSeconds
                />
                <FormErrorMessage fieldName={`validTo`} formikInstance={formikInstance} />
              </div>
              <AdditionalFields formikInstance={formikInstance} dictionaries={dictionaries} />
              <div className="field flex justify-content-between">
                <label htmlFor="entry">{t('entry')}</label>
                <InputSwitch
                  id="entry"
                  checked={formikInstance.values.entry}
                  onChange={(e) => {
                    void formikInstance.handleChange(e);
                    if (e.target.value) {
                      void formikInstance.setFieldValue('entryUsed', false);
                    }
                  }}
                />
              </div>
              <div className="field flex justify-content-between">
                <label htmlFor="entryUsed">{t('entryUsed')}</label>
                <InputSwitch
                  id="entryUsed"
                  checked={formikInstance.values.entryUsed}
                  onChange={(e) => {
                    void formikInstance.handleChange(e);
                    if (e.target.value) {
                      void formikInstance.setFieldValue('entry', false);
                    }
                  }}
                />
              </div>
              <div className="field flex justify-content-between">
                <label htmlFor="exit">{t('exit')}</label>
                <InputSwitch
                  id="exit"
                  checked={formikInstance.values.exit}
                  onChange={(e) => {
                    void formikInstance.handleChange(e);
                    if (e.target.value) {
                      void formikInstance.setFieldValue('exitUsed', false);
                    }
                  }}
                />
              </div>
              <div className="field flex justify-content-between">
                <label htmlFor="exitUsed">{t('exitUsed')}</label>
                <InputSwitch
                  id="exitUsed"
                  checked={formikInstance.values.exitUsed}
                  onChange={(e) => {
                    void formikInstance.handleChange(e);
                    if (e.target.value) {
                      void formikInstance.setFieldValue('exit', false);
                    }
                  }}
                />
              </div>
              &nbsp;
            </div>
          </form>
        </Dialog>
      </FormErrorMessageScroller>
    </div>
  );
};

export default AccessControlArrivalNotificationDialog;
