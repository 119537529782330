import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { getTranslatedLabel, transactionTypeLabels } from '../../../../../utils/labels';
import { MultiSelect } from 'primereact/multiselect';
import multiSelectPanelHeader from '../../../../_shared/multiSelectPanelHeader';
import {
  WeighingNumerationGeneratedByField,
  WeighingNumerationLocationOption,
  WeighingNumerationPeriodOption,
  WeighingNumerationSeparatorOption,
} from '../../../../../enums/weighing-numeration.enum';
import moment from 'moment';

interface WeighingNumerationSectionProps {
  formikInstance: any;
  handleChangeField: (e: any, v: string) => void;
}

const WeighingNumerationSection = ({ formikInstance, handleChangeField }: WeighingNumerationSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationWeighing');
  const { t: t1 } = useTranslation('utils');

  const LOCATION_OPTIONS = [
    { label: t('locationOptionsNone'), value: WeighingNumerationLocationOption.NONE },
    { label: t('locationOptionsShort'), value: WeighingNumerationLocationOption.SHORT },
  ];

  const PERIOD_OPTIONS = [
    { label: t('periodOptionsMonthly'), value: WeighingNumerationPeriodOption.MONTHLY },
    { label: t('periodOptionsMonthlyShortYearly'), value: WeighingNumerationPeriodOption.MONTHLY_SHORT_YEARLY },
    { label: t('periodOptionsYearlyShort'), value: WeighingNumerationPeriodOption.SHORT_YEARLY },
    { label: t('periodOptionsYearly'), value: WeighingNumerationPeriodOption.YEARLY },
  ];

  const SEPARATOR_OPTIONS = [
    { label: t('separatorSlash'), value: WeighingNumerationSeparatorOption.SLASH },
    { label: t('separatorDash'), value: WeighingNumerationSeparatorOption.DASH },
    { label: t('separatorUnderscore'), value: WeighingNumerationSeparatorOption.UNDERSCORE },
  ];

  const ADDITIONAL_FIELD_OPTIONS = [
    { label: t('defaultEmpty'), value: null },
    ...formikInstance.values.additionalFields
      .filter((el: any) => !el.deleted)
      .map((el: any) => {
        return { label: el.name, value: { id: el.id, value: el.baseDefaultValue } };
      }),
  ];

  const SAMPLE_NUMBER = '0001';
  const LOCATION_SHORT = 'ODD';

  const GENERATE_BY_OPTIONS = [
    {
      label: t('numberWeighingType'),
      value: WeighingNumerationGeneratedByField.WEIGHING_TYPE,
    },
  ];

  if (formikInstance.values.numberLocation === WeighingNumerationLocationOption.SHORT) {
    GENERATE_BY_OPTIONS.push({ label: t('location'), value: WeighingNumerationGeneratedByField.NUMBER_LOCATION });
  }

  if (formikInstance.values.numberAdditionalField) {
    GENERATE_BY_OPTIONS.push({
      label: t('numberAdditionalField'),
      value: WeighingNumerationGeneratedByField.ADDITIONAL_FIELD,
    });
  }

  const generateSampleWeighingNumber = () => {
    const separator = formikInstance.values?.numberSeparator;
    const sampleWeighingMode = getTranslatedLabel(transactionTypeLabels, 'DELIVERY', t1);

    const isWeighingTypeSelected = formikInstance.values.numberGeneratedByFields?.includes(
      WeighingNumerationGeneratedByField.WEIGHING_TYPE,
    );
    const isAdditionalFieldSelected = formikInstance.values.numberGeneratedByFields?.includes(
      WeighingNumerationGeneratedByField.ADDITIONAL_FIELD,
    );

    const currentMonth = moment().format('MM');
    const currentYear = moment().format('YYYY');
    const shortCurrentYear = moment().format('YY');

    let numberString = '';

    if (formikInstance.values.numberLocation === WeighingNumerationLocationOption.SHORT) {
      numberString += LOCATION_SHORT + separator;
    }

    if (isWeighingTypeSelected) {
      numberString += sampleWeighingMode + separator;
    }

    let periodPart = '';

    if (formikInstance.values.numberPeriod === WeighingNumerationPeriodOption.MONTHLY) {
      periodPart += `${currentMonth}${separator}${currentYear}`;
    }

    if (formikInstance.values.numberPeriod === WeighingNumerationPeriodOption.MONTHLY_SHORT_YEARLY) {
      periodPart += `${currentMonth}${separator}${shortCurrentYear}`;
    }

    if (formikInstance.values.numberPeriod === WeighingNumerationPeriodOption.SHORT_YEARLY) {
      periodPart += `${shortCurrentYear}`;
    }

    if (formikInstance.values.numberPeriod === WeighingNumerationPeriodOption.YEARLY) {
      periodPart += `${currentYear}`;
    }

    numberString += SAMPLE_NUMBER + separator + periodPart;

    if (isAdditionalFieldSelected && formikInstance.values.numberAdditionalField?.value) {
      numberString += separator + formikInstance.values.numberAdditionalField?.value;
    }

    return numberString;
  };

  const onNumberGeneratedByFieldsChanged = (event: any) => {
    const newNumberGeneratedByFields = event.value;

    formikInstance.setFieldValue('numberGeneratedByFields', newNumberGeneratedByFields);
  };

  const selectedItemsLabel = `${t('selectedItems')}: ${formikInstance.values.numberGeneratedByFields?.length} ${t(
    'elements',
  )}`;

  return (
    <Fieldset legend={t('weighingNumerationSectionHeader')} className="mt-1vh">
      <div className="p-fluid formgrid grid">
        <label htmlFor="numberLocation" className="col-3">
          {t('fieldsNumberLocation')}
        </label>
        <div className="field col-2">
          <Dropdown
            id={`numberLocation`}
            options={LOCATION_OPTIONS}
            value={formikInstance.values.numberLocation}
            onChange={(e: any) => {
              handleChangeField(e, 'dropdown');

              if (e.value === WeighingNumerationLocationOption.NONE) {
                const updatedFields =
                  formikInstance.values.numberGeneratedByFields?.filter(
                    (field: string) => field !== WeighingNumerationGeneratedByField.NUMBER_LOCATION,
                  ) || [];
                formikInstance.setFieldValue('numberGeneratedByFields', updatedFields);
              }
            }}
            placeholder={t('numberLocationPlaceholder')}
          />
        </div>
      </div>
      <div className="p-fluid formgrid grid">
        <label htmlFor="numberPeriod" className="col-3">
          {t('fieldsNumberPeriod')}
        </label>
        <div className="field col-2">
          <Dropdown
            id={`numberPeriod`}
            options={PERIOD_OPTIONS}
            value={formikInstance.values.numberPeriod}
            onChange={(e: any) => handleChangeField(e, 'dropdown')}
            placeholder={t('fieldsNumberPeriodPlaceholder')}
          />
        </div>
      </div>

      <div className="p-fluid formgrid grid">
        <label htmlFor="numberSeparator" className="col-3">
          {t('numberSeparator')}
        </label>
        <div className="field col-2">
          <Dropdown
            id={`numberSeparator`}
            options={SEPARATOR_OPTIONS}
            value={formikInstance.values.numberSeparator}
            onChange={(e) => handleChangeField(e, 'dropdown')}
            placeholder={t('numberSeparatorPlaceholder')}
          />
        </div>
      </div>

      <div className="p-fluid formgrid grid">
        <label htmlFor="numberAdditionalField" className="col-3">
          {t('numberAdditionalField')}
        </label>
        <div className="field col-2">
          <Dropdown
            id={`numberAdditionalField`}
            options={ADDITIONAL_FIELD_OPTIONS}
            value={formikInstance.values.numberAdditionalField}
            onChange={(e: any) => {
              handleChangeField(e, 'dropdown');

              if (e.value === null) {
                const updatedFields =
                  formikInstance.values.numberGeneratedByFields?.filter(
                    (field: string) => field !== WeighingNumerationGeneratedByField.ADDITIONAL_FIELD,
                  ) || [];
                formikInstance.setFieldValue('numberGeneratedByFields', updatedFields);
              }
            }}
            placeholder={t('numberAdditionalFieldPlaceholder')}
          />
        </div>
      </div>

      {(formikInstance.values.numberLocation === WeighingNumerationLocationOption.SHORT ||
        formikInstance.values.numberAdditionalField) && (
        <div className="p-fluid formgrid grid mt-3">
          <label htmlFor="numberKey" className="col-3">
            {t('numberKey')}
          </label>
          <div className="field col-2">
            <MultiSelect
              id="multiselect"
              value={formikInstance.values.numberGeneratedByFields}
              onChange={onNumberGeneratedByFieldsChanged}
              options={GENERATE_BY_OPTIONS}
              optionLabel="label"
              optionValue="value"
              placeholder={t('numberKeyPlaceholder')}
              className="multiselect-custom"
              maxSelectedLabels={0}
              selectedItemsLabel={selectedItemsLabel}
              showClear={true}
              panelHeaderTemplate={multiSelectPanelHeader}
              filter
            />
          </div>
        </div>
      )}

      {(formikInstance.values.numberAdditionalField ||
        formikInstance.values.numberLocation === WeighingNumerationLocationOption.SHORT) && (
        <div className="p-fluid formgrid grid">
          <label htmlFor="sampleNumber" className="col-3">
            {t('fieldsSampleNumber')}
          </label>
          <div className="field col-6">{generateSampleWeighingNumber()}</div>
        </div>
      )}
    </Fieldset>
  );
};

export default WeighingNumerationSection;
