import { CardSubtype } from '../../../../enums/card-subtype.enum';
import BdoApiService from '../../../../services/BdoApiService';
import { getFileFromText } from '../../../../utils/getFile';

const KPOK_downloadConfirmation = async (kpokId) => {
  return BdoApiService.KPOK_downloadConfirmation(kpokId).then((response) => {
    getFileFromText(response.data, 'application/pdf');
  });
};

const KPOK_downloadDocument = async (kpokId, mode) => {
  if (mode === CardSubtype.RECEIVE) {
    return BdoApiService.KPOK_downloadReceiveDocument(kpokId).then((response) => {
      getFileFromText(response.data, 'application/pdf');
    });
  }
  if (mode === CardSubtype.TRANSFER) {
    return BdoApiService.KPOK_downloadTransferDocument(kpokId).then((response) => {
      getFileFromText(response.data, 'application/pdf');
    });
  }
};

const documentUtils = {
  KPOK_downloadConfirmation,
  KPOK_downloadDocument,
};

export default documentUtils;
