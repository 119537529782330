import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReducerState } from '../../../../../../types/reducer-state';
import { isModulesEnabled } from '../../../../../../utils/modulesUtils';

const ListLegend = () => {
  const { t } = useTranslation('weighingHandlingWeighings');
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  return (
    <div className="flex flex-wrap">
      <div className="flex align-items-center m-2">
        <div className="legend-qube" style={{ backgroundColor: 'rgba(255, 160, 160, 0.75)' }}></div>
        <div className="ml-2">{t('openWeighingsListLegendDeleted')}</div>
      </div>
      <div className="flex align-items-center m-2">
        <div className="legend-qube" style={{ backgroundColor: 'rgba(251, 255, 143, 0.75)' }} />
        <div className="ml-2">{t('openWeighingsListLegendEdited')}</div>
      </div>
      <div className="flex align-items-center m-2">
        <div className="legend-qube" style={{ backgroundColor: 'rgba(207, 207, 207, 0.75)' }} />
        <div className="ml-2">{t('openWeighingsListLegendTest')}</div>
      </div>

      {isModulesEnabled(['AUTOMATION'], loggedUserContext.currentCustomer?.subscriptionModules) && (
        <div className="flex align-items-center m-2">
          <div className="legend-qube" style={{ backgroundColor: 'rgba(7, 114, 179, 0.4)' }} />
          <div className="ml-2">{t('openWeighingsListLegendAutomatic')}</div>
        </div>
      )}
    </div>
  );
};
export default ListLegend;
