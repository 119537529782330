import React from 'react';
import { Dialog } from 'primereact/dialog';
import BdoApiService from '../../../../../services/BdoApiService';
import { CancelButton, SubmitButton } from '../../../bdoUtils';
import { useTranslation } from 'react-i18next';

const DeleteCardDialog = ({ visible, closeDialog, kpokId }) => {
  const { t } = useTranslation('kpokSender');

  const handleCloseDialog = (changes) => {
    closeDialog(changes === true);
  };

  const handleConfirm = async () => {
    BdoApiService.KPOK_deleteCard(kpokId).then(() => {
      handleCloseDialog(true);
    });
  };

  const dialogFooter = (
    <div className="grid justify-content-center">
      <CancelButton onClick={handleCloseDialog} />
      <SubmitButton label={t('delete')} onClick={handleConfirm} />
    </div>
  );

  return (
    <Dialog
      visible={visible}
      breakpoints={{ '896px': '90vw' }}
      header={
        <span className="flex align-items-center">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
          {t('confirm')}
        </span>
      }
      modal
      className="p-fluid min-width-450px"
      footer={dialogFooter}
      onHide={handleCloseDialog}
    >
      <div className="confirmation-content">
        <span>{t('deleteCardDialogConfirmationContent')}</span>
      </div>
    </Dialog>
  );
};

export default DeleteCardDialog;
