import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import FormErrorMessage from '../../_shared/FormErrorMessage';
import { DynamicSetting } from '../../../types/edge-device';
import { Dictionary } from '../../../types/dictionary';

interface DynamicSettingsProps {
  formik: any;
  dictionaries: Dictionary[] | null;
  isSubmitClicked: boolean;
}

const DynamicSettings = ({ formik, dictionaries, isSubmitClicked }: DynamicSettingsProps) => {
  const getInputFordynamicSetting = function (dynamicSetting: DynamicSetting, index: number) {
    const onChangeInputdynamicSettings = (e: any) => {
      const dynamicSettingsSnapshot = formik.values.dynamicSettings as any;
      dynamicSettingsSnapshot[index].value = e.target.value;
      formik.setFieldValue('dynamicSettings', dynamicSettingsSnapshot);
    };

    switch (dynamicSetting.type) {
      case 'number':
        return (
          <div className="field col-12" key={index}>
            <label htmlFor={dynamicSetting.name}>{dynamicSetting.label}</label>
            <InputNumber
              id={dynamicSetting.name}
              value={formik.values.dynamicSettings?.[index].value as any}
              onValueChange={onChangeInputdynamicSettings}
              key={index}
            />
            <FormErrorMessage
              withTouchedTrue={isSubmitClicked}
              fieldName={dynamicSetting.name}
              formikInstance={formik}
            />
          </div>
        );
      case 'string':
        return (
          <div className="field col-12" key={index}>
            <label htmlFor={dynamicSetting.name}>{dynamicSetting.label}</label>
            <InputText
              id={dynamicSetting.name}
              value={formik.values.dynamicSettings?.[index].value as any}
              onChange={onChangeInputdynamicSettings}
              key={index}
            />
            <FormErrorMessage
              withTouchedTrue={isSubmitClicked}
              fieldName={dynamicSetting.name}
              formikInstance={formik}
            />
          </div>
        );
      case 'boolean':
        return (
          <div className="field-checkbox col-12" key={index}>
            <Checkbox
              id={dynamicSetting.name}
              checked={formik.values.dynamicSettings?.[index].value}
              onChange={() => {
                const dynamicSettingsSnapshot = formik.values.dynamicSettings as any;
                dynamicSettingsSnapshot[index].value = !dynamicSettingsSnapshot[index].value;
                formik.setFieldValue('dynamicSettings', dynamicSettingsSnapshot);
              }}
              key={index}
            />
            <label htmlFor={dynamicSetting.name} className="checkbox-label">
              {dynamicSetting.label}
            </label>
          </div>
        );
      case 'dictionary':
        const setDictionary = dictionaries?.find((d) => d.name === dynamicSetting.dictionary);
        return (
          setDictionary && (
            <div className="field col-12" key={index}>
              <label htmlFor={dynamicSetting.name}>{dynamicSetting.label}</label>
              <Dropdown
                id={dynamicSetting.name}
                value={formik.values.dynamicSettings?.[index].value}
                onChange={onChangeInputdynamicSettings}
                options={setDictionary?.values}
              />
            </div>
          )
        );
      default:
        return <></>;
    }
  };

  return <>{formik.values.dynamicSettings?.map(getInputFordynamicSetting)}</>;
};

export default DynamicSettings;
