import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import FormErrorMessage from '../../../../../_shared/FormErrorMessage';
import { TooltipInfo } from '../../../../../_shared/TooltipInfo';
import { fetchDictionaries } from '../../../../../../utils/additionalFieldsUtilsForWeighingDialog';
import { DictionaryAdditionalFieldAutocomplete } from '../../../../../_shared/DictionaryAdditionalFieldAutocomplete';
import { useTranslation } from 'react-i18next';
import { Dictionary } from '../../../../../../types/dictionary';

const INIT_SHOW_DATA_STATE = {
  fields: false,
};

interface MeasurementsAdditionalFieldsPluginProps {
  formikInstance: any;
  measurementIndex: number;
}

const MeasurementAdditionalFieldsPlugin = ({
  formikInstance,
  measurementIndex,
}: MeasurementsAdditionalFieldsPluginProps) => {
  const { t } = useTranslation('weighingWeighingList');
  const [dictionariesData, setDictionariesData] = useState<Dictionary[]>([]);
  const [localTouched, setLocalTouched] = useState({});
  const [showData, setShowData] = useState(INIT_SHOW_DATA_STATE);

  useEffect(() => {
    setShowData({
      fields: !!formikInstance.values.measurements[measurementIndex].additionalFields?.length,
    });
  }, [measurementIndex, formikInstance.values.measurements[measurementIndex]]);

  useEffect(() => {
    void fetchDictionaries().then((result) => setDictionariesData(result));
  }, []);

  const renderAdditionalField = (field: any, index: number) => {
    const identifier = `masurements[${index}].additionalFields`;

    const getValueParent = () => formikInstance.values.measurements[measurementIndex].additionalFields || [];

    const handleChange = (value: any) => {
      const newValue = getValueParent();
      newValue[index] = { ...newValue[index], value };
      const measurements = formikInstance.values.measurements;
      measurements[measurementIndex] = { ...measurements[measurementIndex], additionalFields: newValue };
      formikInstance.setFieldValue('measurements', measurements, true);
      const newLocalTouched = localTouched as any;
      newLocalTouched[identifier] = true;
      setLocalTouched(newLocalTouched);
    };

    const renderDictionaryType = () => {
      const sourceDictionary = dictionariesData.find((d: any) => d.id === field.dictionaryId) as Dictionary;

      let source = [];

      if (sourceDictionary) {
        source = sourceDictionary.values;
      } else if (getValueParent()[index]?.value) {
        source.push(getValueParent()[index]?.value);
      }

      return (
        <DictionaryAdditionalFieldAutocomplete
          id={identifier}
          value={source?.find((el) => el === getValueParent()[index]?.value) || null}
          onChange={handleChange}
          source={source}
        />
      );
    };

    const renderNumberType = () => (
      <InputText
        id={identifier}
        type="number"
        value={getValueParent()[index]?.value || ''}
        onChange={(e) => handleChange(e.target.value)}
      />
    );

    const renderTextType = () => (
      <InputText
        id={identifier}
        value={getValueParent()[index]?.value || ''}
        onChange={(e) => handleChange(e.target.value)}
      />
    );

    const renderFormulaType = () => <InputText id={identifier} value={getValueParent()[index]?.value || ''} disabled />;

    const renderAutoIdType = () => <InputText id={identifier} value={getValueParent()[index]?.value || ''} disabled />;

    const METHODS = {
      DICTIONARY: renderDictionaryType,
      NUMBER: renderNumberType,
      TEXT: renderTextType,
      FORMULA: renderFormulaType,
      AUTO_ID: renderAutoIdType,
    };

    if (!!METHODS[field.type as keyof typeof METHODS]) {
      const showUnit = !!field.unit;
      const isFormulaType = field.type === 'FORMULA';
      const showRequiredStar = !field.isOptional;

      return (
        <>
          <label htmlFor={field.name}>
            {field.name}
            {showUnit && (
              <>
                &nbsp;<small>{`[${field.unit}]`}</small>
              </>
            )}
            {isFormulaType && (
              <>
                &nbsp;
                <TooltipInfo _key={identifier} text={t('weighingDialogAfPluginEvaluationInfo')} className={undefined} />
              </>
            )}
            {showRequiredStar && (
              <span className="text-red-500">
                <small>*</small>
              </span>
            )}
          </label>
          {METHODS[field.type as keyof typeof METHODS]()}
          {!isFormulaType && (
            <FormErrorMessage
              fieldName={identifier}
              formikInstance={formikInstance}
              withTouchedTrue={!!formikInstance.submitCount || !!localTouched[identifier as keyof typeof localTouched]}
            />
          )}
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      {showData.fields &&
        formikInstance.values.measurements[measurementIndex].additionalFields
          .filter(
            (a: any) =>
              a.productIds?.length === 0 ||
              a.productIds?.some(
                (p: string) => p === formikInstance.values.measurements[measurementIndex]?.product?.id,
              ),
          )
          .map(
            (field: any, index: number) =>
              !(field.type === 'FORMULA') &&
              (field.productIds?.length === 0 ||
                field.productIds?.some(
                  (p: string) => p === formikInstance.values.measurements[measurementIndex]?.product?.id,
                )) && (
                <div key={field.name} className="field col-12 md-4">
                  {renderAdditionalField(field, index)}
                </div>
              ),
          )}
    </>
  );
};

export default MeasurementAdditionalFieldsPlugin;
