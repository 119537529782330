import { Product } from 'aws-sdk/clients/ssm';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  emptyPaginationParameters,
  emptyPaginatorState,
} from '../../components/Weighing/WeighingsList/components/WeighingListInit';
import BdoApiService from '../../services/BdoApiService';
import containersService from '../../services/ScaleoApiServices/ContainersService';
import contractorsService from '../../services/ScaleoApiServices/ContractorsService';
import driversService from '../../services/ScaleoApiServices/DriversService';
import productsService from '../../services/ScaleoApiServices/ProductsService';
import trailersService from '../../services/ScaleoApiServices/TrailersService';
import vehiclesService from '../../services/ScaleoApiServices/VehiclesService';
import weighingsService from '../../services/ScaleoApiServices/WeighingsService';
import usersService from '../../services/ScaleoApiServices/UsersService';
import weighingConfigurationService from '../../services/ScaleoApiServices/WeighingConfigurationService';
import { weighingActions } from '../../store/weighing-slice';
import { Trailer } from '../../types/trailer';
import { Container } from '../../types/container';
import { Vehicle, Contractor, Driver, Weighing } from '../../types/weighing';
import { ReducerState } from '../../types/reducer-state';
import dictionariesService from '../../services/ScaleoApiServices/DictionariesService';
import { removeEmptyValuesFromObject } from '../../utils/weighingsListUtils';

export interface WeighingListData {
  inputVehicles: Vehicle[];
  inputContractors: Contractor[];
  inputProducts: Product[];
  inputDrivers: Driver[];
  inputScales: Node[];
  inputTrailers: Trailer[];
  weighings: Weighing[];
  containers: Container[];
  configuration: any | null;
  allAdditionalFields: any[];
  isCorrectBdoModuleConfig: boolean;
  dictionaries: [];
}

export const useWeighingsListData = () => {
  const dispatch = useDispatch();
  const filters = useSelector((state: ReducerState) => state.user.filtersState.weighings);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const [paginationParameters, setPaginationParameters] = useState<any>(emptyPaginationParameters);
  const [showDeletedWeighings, setShowDeletedWeighings] = useState(false);

  const [paginatorState, setPaginatorState] = useState(emptyPaginatorState);

  const [componentData, setComponentData] = useState<WeighingListData>({
    inputVehicles: [],
    inputContractors: [],
    inputProducts: [],
    inputDrivers: [],
    inputScales: [],
    inputTrailers: [],
    weighings: [],
    containers: [],
    configuration: null,
    allAdditionalFields: [],
    isCorrectBdoModuleConfig: false,
    dictionaries: [],
  });

  const adaptConfirmedWeighings = (weighings: Weighing[] | null, configuration: any) => {
    if (configuration?.confirmationRequiredCondition === 'AFTER_EACH_MEASUREMENT') {
      return (
        weighings?.map((w) => (w.measurements.find((m) => !m.isConfirmed) ? { ...w, isConfirmed: false } : w)) ?? []
      );
    }
    return weighings;
  };

  const fetchComponentData = useCallback(
    async (_filters, _paginationParameters, _showDeletedWeighings) => {
      void Promise.all([
        vehiclesService.getVehicles(),
        contractorsService.getContractors(),
        driversService.getDrivers(),
        weighingsService.searchWeighings(_filters, _paginationParameters, _showDeletedWeighings),
        productsService.getProducts({ searchText: null, searchForCurrentLocation: false, searchForActive: false }),
        usersService.getCurrentUserScales(),
        BdoApiService.checkLocationEupConfig(),
        weighingConfigurationService.getAllAdditionalFields(),
        weighingConfigurationService.getConfiguration(),
        containersService.getContainers(),
        trailersService.getTrailers(),
        dictionariesService.getDictionaries(),
      ]).then((response) => {
        response[3].data.total_pages
          ? setPaginatorState({
              ...paginatorState,
              totalPages: response[3].data.total_pages,
            })
          : setPaginatorState({
              ...paginatorState,
              totalPages: 1,
              currentPage: 1,
            });

        let allAdditionalFields = response[7].data;
        if (!componentData.allAdditionalFields.length) {
          allAdditionalFields = response[7].data
            .filter((additionalField: any) => additionalField.destination === 'WEIGHING')
            .map((additionalField: any) => ({
              ...additionalField,
              sortable: false,
              standardCol: false,
              type: additionalField.type.toLowerCase(),
            }));
        }

        const dictionaries = response[11].data.map((dictionary: any) => ({
          id: dictionary?.id,
          name: dictionary?.name,
          dictionaryValuesMapped: dictionary?.values?.map((value: any) => ({ id: value, name: value })),
        }));

        const data = {
          inputVehicles: response[0].data,
          inputContractors: response[1].data,
          inputProducts: response[4].data,
          inputDrivers: response[2].data,
          inputScales: response[5],
          inputTrailers: response[10].data,
          weighings: adaptConfirmedWeighings(response[3].data.data, response[8].data)!,
          containers: response[9].data,
          configuration: response[8].data,
          allAdditionalFields,
          isCorrectBdoModuleConfig: response[6].data?.correctConfig,
          dictionaries,
        };
        setComponentData(data);

        dispatch(weighingActions.weighingConfigurationFetched(response[8].data));
      });
    },
    [paginatorState, componentData.allAdditionalFields],
  );

  const productsMultiselectOnClearFilter = (currentValue: any) => {
    if (currentValue && Array.isArray(currentValue) && currentValue?.length > 0) {
      setComponentData((prev) => ({ ...prev, inputProducts: currentValue }));
    } else {
      const params = {
        searchText: null,
        searchForCurrentLocation: false,
        searchForActive: false,
      };
      void productsService.getProducts(params, { show_loader: false }).then((response) => {
        setComponentData((prev) => ({ ...prev, inputProducts: response.data }));
      });
    }
  };

  const productsMultiselectSearchMethod = (filter: any, currentValue: any) => {
    const searchText = filter.trim();
    if (searchText?.length > 0) {
      const params = {
        searchText: searchText,
        searchForCurrentLocation: false,
        searchForActive: false,
      };
      void productsService.getProducts(params, { show_loader: false }).then((response) => {
        setComponentData((prev) => ({ ...prev, inputProducts: response.data }));
      });
    } else {
      productsMultiselectOnClearFilter(currentValue);
    }
  };

  const { shouldReloadWeighingsListData } = useSelector((state: ReducerState) => state.weighing);

  useEffect(() => {
    if (shouldReloadWeighingsListData) {
      fetchComponentData(filters, paginationParameters, showDeletedWeighings);
      dispatch(weighingActions.weighingsListDataReloaded());
    }
  }, [fetchComponentData, shouldReloadWeighingsListData]);

  useEffect(() => {
    void fetchComponentData(filters, paginationParameters, showDeletedWeighings);
  }, [
    showDeletedWeighings,
    JSON.stringify(removeEmptyValuesFromObject(filters)),
    JSON.stringify(paginationParameters),
    loggedUserContext.currentCustomer?.id,
  ]);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [JSON.stringify(paginationParameters), JSON.stringify(paginatorState)]);

  useEffect(() => {
    if (paginatorState.currentPage > paginatorState.totalPages)
      setPaginatorState((prevPaginatorState: any) => ({
        ...prevPaginatorState,
        currentPage: 1,
      }));
  }, [paginatorState]);

  useEffect(() => {
    setPaginationParameters((prevPaginationParameters: any) => ({
      ...prevPaginationParameters,
      page: {
        ...prevPaginationParameters.page,
        index: 1,
      },
    }));
  }, [filters]);

  const locations = loggedUserContext.locations?.map((location) => {
    return { label: location.name, value: location.id };
  });
  const isWeighingSeriesEnabled = loggedUserContext.weighingConfiguration.weighingMode.some(
    (mode) => mode === 'WEIGHING_SERIES' || mode === 'DOUBLE_WEIGHING_SERIES',
  );

  const handleFetchComponentData = useCallback(
    async () => await fetchComponentData(filters, paginationParameters, showDeletedWeighings),
    [filters, paginationParameters, showDeletedWeighings],
  );

  return {
    componentData,
    paginatorState,
    handleFetchComponentData,
    productsMultiselectSearchMethod,
    setPaginatorState,
    isWeighingSeriesEnabled,
    locations,
    paginationParameters,
    showDeletedWeighings,
    setShowDeletedWeighings,
    setPaginationParameters,
  };
};
