import { useSelector } from 'react-redux';

const useCommandTopics = () => {
  const { currentLocationId } = useSelector((state) => state.user.context);

  return {
    WEIGH_COMMAND_TOPIC_NAME: process.env.REACT_APP_MQTT_WEIGH_COMMAND_TOPIC.replace('<location>', currentLocationId),
    SAVE_TEST_WEIGHING_MEASUREMENT_COMMAND_TOPIC_NAME:
      process.env.REACT_APP_MQTT_SAVE_TEST_WEIGHING_MEASUREMENT_COMMAND_TOPIC.replace('<location>', currentLocationId),
    SCALE_COMMAND_ZERO_TOPIC: process.env.REACT_APP_MQTT_SCALE_COMMAND_ZERO_TOPIC.replace(
      '<location>',
      currentLocationId,
    ),
  };
};

export default useCommandTopics;
