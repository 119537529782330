import { configureStore } from '@reduxjs/toolkit';
import appConfigSlice from './appConfig-slice';
import nodeSlice from './node-slice';
import indicationSlice from './indication-slice';
import uiSlice from './ui-slice';
import userSlice from './user-slice';
import weighingSlice from './weighing-slice';
import confirmationsSlice from './confirmations-slice';
import stateSlice from './state-slice';

const store = configureStore({
  reducer: {
    appConfig: appConfigSlice.reducer,
    ui: uiSlice.reducer,
    user: userSlice.reducer,
    weighing: weighingSlice.reducer,
    indication: indicationSlice.reducer,
    node: nodeSlice.reducer,
    confirmations: confirmationsSlice.reducer,
    state: stateSlice.reducer,
  },
});

export default store;
