import { Fieldset } from 'primereact/fieldset';
import { WeighingContainer } from '../../../../../../store/types/weighing-slice';
import { useTranslation } from 'react-i18next';
import { InputNumber } from 'primereact/inputnumber';
import { ReducerState } from '../../../../../../types/reducer-state';
import { useSelector } from 'react-redux';

interface ContainersSumUpProps {
  formik: any;
}

export const ContainersSumUp = ({ formik }: ContainersSumUpProps) => {
  const { t } = useTranslation('containersForm');
  const unit = useSelector((state: ReducerState) => state.appConfig.unit);

  if (!formik.values.containers || formik.values.containers.length === 0) {
    return <></>;
  }

  const calculateReceiveSum = (containers: WeighingContainer[]) => {
    return containers.reduce((sum, curr) => (sum = sum + curr.receiveNumber * (curr.tareValue ?? 0)), 0);
  };

  const calculateReturnSum = (containers: WeighingContainer[]) => {
    return containers.reduce((sum, curr) => (sum = sum + curr.returnNumber * (curr.tareValue ?? 0)), 0);
  };

  return (
    <>
      <div className="grid mt-5vh mr-0">
        <div className="col-12">
          <Fieldset legend={t('containersSumUp')}>
            <div className="field grid">
              <div className="col-12">
                <div className="flex align-items-center">
                  <div className="col-4">
                    <b>{t('receiveSum')}</b> &nbsp; {`[${unit}]`}:
                  </div>
                  <div className="col-6">
                    <InputNumber
                      id="receiveNumber"
                      value={calculateReceiveSum(formik.values.containers)}
                      mode="decimal"
                      className="ml-10px flex align-items-center justify-content-center"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 mt-4">
                <div className="flex align-items-center">
                  <div className="col-4">
                    <b>{t('returnSum')}</b> &nbsp;{`[${unit}]`}:
                  </div>
                  <div className="col-6">
                    <InputNumber
                      id="returnNumber"
                      value={calculateReturnSum(formik.values.containers)}
                      mode="decimal"
                      className="ml-10px flex align-items-center justify-content-center"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </Fieldset>
        </div>
      </div>
    </>
  );
};
