import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';
import { Panel } from 'primereact/panel';
import { Ripple } from 'primereact/ripple';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { WeighingListData } from '../../../../hooks/WeighingsList/useWeighingsListData';
import usersService from '../../../../services/ScaleoApiServices/UsersService';
import { userActions } from '../../../../store/user-slice';
import { ReducerState } from '../../../../types/reducer-state';
import DataTableFilterElements from '../../../_shared/DataTableFilterElements';
import multiSelectPanelHeader from '../../../_shared/multiSelectPanelHeader';
import ListLegend from './ListLegend';

interface HeaderProps {
  handleFetchComponentData: () => Promise<void>;
  componentData: WeighingListData;
  showDeletedWeighings: boolean;
  setShowDeletedWeighings: (b: boolean) => void;
  clearFilters: () => void;
  changeFilter: (
    value: any,
    name: string,
    singleDateMode?: boolean | undefined,
    shouldClearDate?: boolean | undefined,
  ) => void;
  selectedDynamicsColumns: any[];
  weighningMainColumns: any[];
  setClearFlag: (a: any) => void;
  setSelectedDynamicsColumns: (s: any[]) => void;
}

export const Header = ({
  componentData,
  showDeletedWeighings,
  setShowDeletedWeighings,
  clearFilters,
  changeFilter,
  selectedDynamicsColumns,
  weighningMainColumns,
  setClearFlag,
  setSelectedDynamicsColumns,
}: HeaderProps) => {
  const { t } = useTranslation('weighingWeighingList');

  const dispatch = useDispatch();
  const filters = useSelector((state: ReducerState) => state.user.filtersState.weighings);

  const dynamicsColumnToggle = (event: any) => {
    const selectedColumns = event.value;
    const addOrderedSelectedColumns = componentData.allAdditionalFields.filter((col: any) =>
      selectedColumns.some((sCol: any) => sCol.id === col.id),
    );
    const standardOrderedSelectedColumns = weighningMainColumns.filter((col) =>
      selectedColumns.some((sCol: any) => sCol.field === col.field),
    );
    setSelectedDynamicsColumns([...standardOrderedSelectedColumns, ...addOrderedSelectedColumns]);
    void usersService
      .updateUserColumnList([...standardOrderedSelectedColumns, ...addOrderedSelectedColumns])
      .then(() => {
        dispatch(userActions.setWeighingsFilters({}));
        setClearFlag((prev: boolean) => !prev);
        dispatch(userActions.shouldRefreshLoggedUserContext(true));
      });
  };

  const headerTemplate = (options: any) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const toggleString = options.collapsed ? t('headerMore') : `${t('headerLess')} \xa0`;

    return (
      <div className="p-1">
        <div className="grid m-0 p-0">
          <div className="col-12 sm:col-3">
            <h4 className="m-0 p-0">
              <b>{t('headerTitle')} </b>
            </h4>
          </div>
          <div className="col-12 sm:col-9 flex align-items-center justify-content-end">
            <Button
              type="button"
              label={t('headerClearFilters')}
              icon="pi pi-filter-slash"
              outlined
              className="mr-2"
              onClick={clearFilters}
            />
            <span className="flex">
              <DataTableFilterElements.Text
                initialValue={filters['global']}
                onChangeFilter={changeFilter}
                name={'global'}
                placeholder={t('headerFilterPlaceholder')}
                isSearchIcon
              />
            </span>
            <button
              type="button"
              className={`${options.togglerClassName} ml-4 w-62px`}
              onClick={options.onTogglerClick}
            >
              {toggleString}&#160;&#160;
              <span className={toggleIcon}></span>
              <Ripple />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Panel headerTemplate={headerTemplate} toggleable>
      <div className="grid">
        <div className="col-12 md:col-6">
          <ListLegend />
        </div>
        <div className="col-12 md:col-6 pb-0">
          <div className="flex flex-wrap justify-content-end">
            <MultiSelect
              className="pt-1 w-220px"
              value={selectedDynamicsColumns}
              options={[...weighningMainColumns, ...componentData.allAdditionalFields]}
              optionLabel="name"
              onChange={dynamicsColumnToggle}
              placeholder={t('headerColumnsPlaceholder')}
              fixedPlaceholder={t('headerColumnsPlaceholder') as any}
              panelHeaderTemplate={multiSelectPanelHeader}
            />
            <div className="m-2">
              <div className="flex">
                <InputSwitch checked={showDeletedWeighings} onChange={(e) => setShowDeletedWeighings(e.value)} />
                &#160;&#160; <p className="mt-1">{t('headerShowDeleted')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  );
};
