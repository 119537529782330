import { useFormik } from 'formik';

import { PaginationParams } from '../../types/general-types';
import { TFunction } from 'i18next';
import accessControlArrivalNotificationService from '../../services/ScaleoApiServices/AccessControlArrivalNotificaitonService';

const emptyArrivalNotification = {
  id: null,
  vehicle: '',
  driver: '',
  contractor: '',
  product: '',
  trailer: '',
  declaredNetto: undefined,
  declaredArrivalTime: null as Date | null,
  entry: false,
  entryUsed: false,
  exit: false,
  exitUsed: false,
  validFrom: null as Date | null,
  validTo: null as Date | null,
};

const useArrivalNotificationForm = (
  fetchData: (paginationParameters: PaginationParams) => void,
  paginationParameters: PaginationParams,
  handleCloseDialog: any,
  t: TFunction,
) => {
  const formik = useFormik({
    initialValues: emptyArrivalNotification,
    validate: (data) => {
      const errors: any = {};

      if (!data.vehicle) {
        errors.vehicle = t('emptyRegistrationNumber');
      }

      return errors;
    },
    onSubmit: async (formData: any) => {
      const body = {
        declaredNetto: formData.declaredNetto,
        declaredArrivalTime: formData.declaredArrivalTime,
        validFrom: formData.validFrom,
        validTo: formData.validTo,
        contractorId: formData.contractor?.id ?? null,
        driverId: formData.driver?.id ?? null,
        productId: formData.product?.id ?? null,
        vehicleId: formData.vehicle?.id ?? null,
        trailerId: formData.trailer?.id ?? null,
        entry: formData.entry,
        entryUsed: formData.entryUsed,
        exit: formData.exit,
        exitUsed: formData.exitUsed,
        additionalFields: formData.additionalFields
          ?.filter((a: any) => a.value)
          ?.reduce((acc: any, curr: any) => ({ ...acc, [curr.id]: curr.value }), {}),
      };

      formData?.id
        ? await accessControlArrivalNotificationService.updateArrivalNotification(body, formData.id)
        : await accessControlArrivalNotificationService.createArrivalNotification(body);

      handleCloseDialog(true);
      fetchData(paginationParameters);
    },
    enableReinitialize: true,
  });

  const updateForm = (data: any = emptyArrivalNotification) => {
    formik.resetForm({ values: data });
  };

  return { formik, updateForm };
};

export default useArrivalNotificationForm;
