export const reduceAdditionalFieldsArrayToObject = (array) =>
  array?.reduce((obj, item) => {
    return {
      ...obj,
      [item['id']]: item.value,
    };
  }, {});

export const additionalFieldsReduce = (array) => {
  if (!array) {
    return {};
  }
  return reduceAdditionalFieldsArrayToObject(
    array
      .map((el) => {
        if (el.type === 'NUMBER') {
          return { ...el, value: `${+el.value > 0 ? +el.value : ''}` };
        } else {
          return el;
        }
      })
      .filter((el) => !!el.value || el.value === ''),
  );
};

export const findProductAdditionalFieldValue = (additionalField, productId) => {
  if (!productId) {
    return null;
  }

  return additionalField?.specificDefaultValues?.find((d) => d.product?.id === productId)?.value;
};

/**
 * Ensures that a specific value is included in the source array.
 * This function is designed to handle scenarios where an item's value,
 * previously available in a dictionary or list, needs to be retained
 * even after its removal from the source. This is particularly useful
 * for maintaining the integrity of records where the item's value has
 * been utilized.
 */
export const ensureDictionaryValueIncluded = (source, value) => {
  if (value && !source.includes(value)) {
    return [...source, value];
  }
  return source;
};
