import { Message } from 'primereact/message';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReducerState } from '../../../../../../types/reducer-state';
import { formatDecimal } from '../../../../../../utils/formatUtils';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { Vehicle } from '../../../../../../types/weighing';
import { checkIfIndicationOutOfRange } from '../utils';

interface ScaleIndicationProps {
  isIndicationOutOfRange: any;
  setIsIndicationOutOfRange: (a: any) => void;
  zeroButtonVisible: boolean;
  zeroButtonDisabled: boolean;
  convertedIndication: number | null;
  onScaleZero: () => void;
  componentData: any;
  onScaleSelected: (scale: any) => void;
  automationCurrentMode: any;
  onAutomationModeChange: (e: any) => void;
  onAutoWeighingChange: (e: any) => void;
  enableAutomaticWeighings: boolean;
  vehicleData: Vehicle;
  edgeDeviceMaxWeightBrutto: number;
}

/**
 * INFO: there are many temporary solutions with styling components here
 * due to fact that migration to the newest version of primeflex is needed.
 */

const NotificationPanel = ({
  isIndicationOutOfRange,
  setIsIndicationOutOfRange,
  zeroButtonVisible,
  zeroButtonDisabled,
  convertedIndication,
  onScaleZero,
  componentData,
  onScaleSelected,
  automationCurrentMode,
  onAutomationModeChange,
  onAutoWeighingChange,
  enableAutomaticWeighings,
  vehicleData,
  edgeDeviceMaxWeightBrutto,
}: ScaleIndicationProps) => {
  const { t } = useTranslation('weighingHandlingWeighings');

  const messages = useRef<any>();

  const mqttConnected = useSelector((state: ReducerState) => state.ui.mqttConnected);
  const unit = useSelector((state: ReducerState) => state.appConfig.unit);
  const { currentIndication } = useSelector((state: ReducerState) => state.indication);
  const { selectedScale } = useSelector((state: ReducerState) => state.weighing);
  const vehiclePosition = useSelector((state: ReducerState) => state.state.vehiclePosition);

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const isTestWeighing = useSelector((state: ReducerState) => {
    const { locations, currentLocationId } = state.user.context;
    const currentLocation = locations?.find((l: any) => l.id === currentLocationId);
    return currentLocation?.isTestWeighing;
  });

  const [messagesArray, setMessagesArray] = useState<any>([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const checkMaxWeightBrutto = () => {
    if (!currentIndication) return false;

    if (vehicleData?.maxWeightBrutto && currentIndication > vehicleData?.maxWeightBrutto) {
      return true;
    }

    if (!vehicleData?.maxWeightBrutto && edgeDeviceMaxWeightBrutto && currentIndication > edgeDeviceMaxWeightBrutto) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (currentIndication) {
      const { minWeight, maxWeight } = checkIfIndicationOutOfRange(selectedScale, currentIndication);
      setIsIndicationOutOfRange({ minWeight, maxWeight });
    }
  }, [currentIndication, selectedScale, setIsIndicationOutOfRange]);

  useEffect(() => {
    const newMessages: any = [];
    messages?.current?.clear();

    const messageMainSettings = {
      sticky: true,
      closable: false,
    };

    const testWeighingMessage = {
      ...messageMainSettings,
      messageId: 'isTestWeighing',
      severity: 'warn',
      detail: t('scaleIndicationWarningMessageTestWeighing'),
    };

    const mqttDisconnectedMessage = {
      ...messageMainSettings,
      messageId: 'mqttConnected',
      severity: 'error',
      detail: t('scaleIndicationWarningMessageMqttDisconnected'),
    };

    const minWeightMessage = {
      ...messageMainSettings,
      messageId: 'minWeight',
      severity: 'warn',
      detail: t('scaleIndicationWarningMessageMinWeight'),
    };

    const maxWeightMessage = {
      ...messageMainSettings,
      messageId: 'maxWeight',
      severity: 'warn',
      detail: t('scaleIndicationWarningMessageMaxWeight'),
    };

    const maxWeightBruttoMessage = {
      ...messageMainSettings,
      messageId: 'maxWeightBrutto',
      severity: 'error',
      detail: t('maxWeightBruttoError'),
    };

    const incorrectVehiclePositionMessage = {
      ...messageMainSettings,
      messageId: 'temporaryIncorrectVehiclePosition',
      severity: 'error',
      detail: t('incorrectVehiclePosition'),
    };

    const waitingForCorrectVehiclePoisitionMessage = {
      ...messageMainSettings,
      messageId: 'waitingForCorrectVehiclePoisition',
      severity: 'warn',
      detail: t('waitingForCorrectVehiclePoisition'),
    };

    const noVehiclePositionMessage = {
      ...messageMainSettings,
      messageId: 'temporaryNoVehiclePosition',
      severity: 'warn',
      detail: t('noVehiclePosition'),
    };

    if (isTestWeighing) {
      newMessages.push(testWeighingMessage);
    }

    if (!mqttConnected) {
      newMessages.push(mqttDisconnectedMessage);
    }

    if (isIndicationOutOfRange.minWeight) {
      newMessages.push(minWeightMessage);
    }

    if (isIndicationOutOfRange.maxWeight) {
      newMessages.push(maxWeightMessage);
    }

    if (checkMaxWeightBrutto()) {
      newMessages.push(maxWeightBruttoMessage);
    }

    if (automationCurrentMode?.vehiclePositionControl) {
      const currentVehiclePosition = vehiclePosition[selectedScale?.edgeDevice?.id];
      if (currentVehiclePosition === 'INCORRECT') {
        newMessages.push(incorrectVehiclePositionMessage);
      } else if (currentVehiclePosition === 'ON_SCALE') {
        newMessages.push(waitingForCorrectVehiclePoisitionMessage);
      } else if (currentVehiclePosition !== 'CORRECT') {
        newMessages.push(noVehiclePositionMessage);
      }
    }

    setMessagesArray(newMessages);
  }, [
    mqttConnected,
    isTestWeighing,
    convertedIndication,
    vehicleData,
    vehiclePosition,
    selectedScale,
    automationCurrentMode,
    loggedUserContext.appLanguage,
  ]);

  useEffect(() => {
    messages?.current?.show(messagesArray);
  }, [messagesArray]);

  const determineOverallSeverity = useCallback(() => {
    if (selectedScale?.station?.type !== 'WEIGHING_STATION') {
      return 'info';
    }
    if (messagesArray.some((message: any) => message.severity === 'error')) {
      return 'error';
    } else if (messagesArray.some((message: any) => message.severity === 'warn')) {
      return 'warn';
    }
    return 'info';
  }, [JSON.stringify(messagesArray), JSON.stringify(selectedScale)]);

  const overallSeverity = determineOverallSeverity();

  const indicationContent = (
    <>
      <div className="grid flex w-full">
        {selectedScale?.station?.installationId && (
          <div className="col-12 nowrap flex justify-content-end text-lg">{selectedScale?.station?.installationId}</div>
        )}
        <div
          className={`grid flex align-items-center w-full mr-auto mb-auto ml-auto mt-25px xl:justify-content-between
          `}
        >
          {selectedScale?.station?.type === 'WEIGHING_STATION' && (
            <>
              <div className="col-12 lg:col-6 xl:col-8 flex justify-content-center">
                <div className="font-size-30px font-bold white-space-nowrap w-full flex justify-content-center lg:justify-content-start">
                  {`${formatDecimal(convertedIndication)} ${unit}`}
                </div>
              </div>
            </>
          )}

          {selectedScale?.station?.type === 'WEIGHING_STATION' && (
            <div className="col-12 lg:col-6 xl:col-4 mt-4">
              <div>
                {messagesArray.map((message: any, index: number) => (
                  <div className="flex w-full mt-2">
                    <Message
                      className="flex w-full h-30px text-center"
                      style={{
                        backgroundColor: 'rgba(255, 255, 255, 0.4)',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                      }}
                      key={index}
                      severity={message.severity}
                      text={message.detail}
                      content={message.detail}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div
          className={`col-fixed grid mb-0 pb-0 ${
            windowWidth < 768
              ? 'w-full justify-content-center m-auto'
              : `${windowWidth > 1320 ? 'w-50p' : 'w-full'} mt-20px mb--30px`
          }`}
        >
          {zeroButtonVisible && selectedScale?.station?.type === 'WEIGHING_STATION' && (
            <div className="col-12 md:col-4">
              <Button
                label={t('scaleIndicationScaleZeroButton')}
                name="scale-zero"
                type="button"
                disabled={!mqttConnected || zeroButtonDisabled}
                onClick={onScaleZero}
              ></Button>
            </div>
          )}

          <div className="col-12 md:col-4">
            <Dropdown
              id="scale-search"
              value={selectedScale?.name}
              onChange={(e) => onScaleSelected(componentData?.scales.find((scale: any) => scale.name === e.value))}
              options={componentData?.scales}
              optionValue="name"
              optionLabel="name"
            />
          </div>

          {selectedScale?.edgeDevice?.automationStates &&
            (selectedScale?.edgeDevice?.automationStates?.length !== 1 || !automationCurrentMode?.modeId) && (
              <div className="col-12 md:col-4">
                <Dropdown
                  value={automationCurrentMode?.modeId}
                  options={selectedScale?.edgeDevice?.automationStates}
                  optionValue="modeId"
                  optionLabel="label"
                  placeholder={t('chooseAutomationMode')}
                  onChange={onAutomationModeChange}
                />
              </div>
            )}

          {enableAutomaticWeighings && !selectedScale?.edgeDevice?.automationStates && (
            <>
              <div className="col-10 md:col-2">
                <label htmlFor="autoWeighingButton">{t('scaleIndicationAutoWeighingButton')}</label>
              </div>

              <div className="col-2 md:col-1 ">
                <InputSwitch
                  id="autoWeighingButton"
                  checked={selectedScale?.isAutoWeighing}
                  onChange={onAutoWeighingChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="grid p-0 ">
        <div className="col-12 xl:col-12 m-auto">
          <div>
            <Message className="border-primary w-full" severity={overallSeverity} content={indicationContent} />
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationPanel;
