import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Chips } from 'primereact/chips';
import { Dialog } from 'primereact/dialog';
import validator from 'validator';
import { Weighing } from '../../../../types/weighing';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import weighingsService from '../../../../services/ScaleoApiServices/WeighingsService';
import { useTranslation } from 'react-i18next';

interface SendEmailDialogProps {
  weighingsReceiptsToSend: string[];
  hideSendEmailDialog: () => void;
  setSelectedWeighings: (w: Weighing[] | null) => void;
  sendEmailDialogVisible: boolean;
}

export const SendEmailDialog = ({
  weighingsReceiptsToSend,
  hideSendEmailDialog,
  setSelectedWeighings,
  sendEmailDialogVisible,
}: SendEmailDialogProps) => {
  const { t } = useTranslation('weighingWeighingList');

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data: any) => {
      const errors: any = {};
      if (data.emails.length === 0) {
        errors.emails = t('validationNoEmails');
      }
      data.emails.forEach((email: any) => {
        if (!validator.isEmail(email)) {
          errors.emails = t('validationIncorrectEmails');
          errors[email] = true;
        }
      });

      return errors;
    },
    onSubmit: async (formData: any) => {
      void weighingsService.sendWeighingReceipts(weighingsReceiptsToSend, formData.emails).then(() => {
        hideSendEmailDialog();
        formik.resetForm(initFormValues as any);
        setSelectedWeighings(null);
      });
    },
  });

  const onEmailAdded = (event: any) => {
    const newEmail = event.value;
    const alreadyAddedEmails = formik.values.emails;
    void formik.setFieldValue('emails', [...alreadyAddedEmails, newEmail]);
  };

  const handleBlurChips = (e: any) => {
    if (e.target.value) {
      void formik.setFieldValue('emails', [...formik.values.emails, e.target.value]);
      e.target.value = '';
    }
  };

  const onEmailRemoved = (event: any) => {
    const emailToBeRemoved = event.value[0];
    const alreadyAddedEmails = formik.values.emails;
    void formik.setFieldValue(
      'emails',
      alreadyAddedEmails.filter(function (email: string) {
        return email !== emailToBeRemoved;
      }),
    );
  };

  const emailChipTemplate = (email: string | number) => {
    return (
      <div>
        {(formik.errors as any)[email] && <span style={{ backgroundColor: '#FFCDD2', color: '#73000c' }}>{email}</span>}
        {!(formik.errors as any)[email as any] && <span>{email}</span>}
      </div>
    );
  };

  const sendEmailDialogFooter = (
    <>
      <Button type="reset" label={t('emailDialogCancelButton')} icon="pi pi-times" text onClick={hideSendEmailDialog} />
      <Button type="submit" label={t('emailDialogSendButton')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={sendEmailDialogVisible}
        header={t('sendReceiptDialogTitle')}
        modal
        className="p-fluid w-40vw"
        footer={sendEmailDialogFooter}
        onHide={hideSendEmailDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      >
        <form>
          <div className="col-12">
            <div className="field">
              <label htmlFor="email">{t('sendReceiptDialogEmails')}</label>
              <Chips
                value={formik.values.emails}
                onAdd={onEmailAdded}
                onRemove={onEmailRemoved}
                itemTemplate={emailChipTemplate}
                onBlur={handleBlurChips}
              />
              <FormErrorMessage fieldName="emails" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};

const initFormValues = {
  emails: [],
};
