import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import BdoApiService from '../../services/BdoApiService';
import usersService from '../../services/ScaleoApiServices/UsersService';
import { trimString } from '../../utils/stringUtils';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '../../store/user-slice';
import { formatDateTime } from '../../utils/formatUtils';
import { getFileFromBuffer } from '../../utils/getFile';
import * as moment from 'moment';

export const DEFAULT_DIALOG_BREAKPOINTS = { '1400px': '75vw', '896px': '90vw' };

export const DEFAULT_DIALOG_STYLE = { width: '60vw' };

export const checkCommaSeparator = (value) => {
  return parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 4 });
};

export const disableDotInTextInput = (e) => {
  if (e.keyCode === 190 || e.keyCode === 110) {
    e.preventDefault();
  }
  return e;
};

export const limitOfDigitsAfterDecimalsFilter = (e, count) => {
  if (e.target.value.indexOf('.') !== -1 && e.target.value.length - e.target.value.indexOf('.') > count + 1) {
    e.target.value = e.target.value.slice(0, -1);
  }
  return e;
};

export const transformWasteGeneratingPlace = (el) => {
  if (el) {
    return {
      ...el,
      fulltext: el?.fulltext_html.replaceAll('<b>', '').replaceAll('</b>', ''),
    };
  }
  return null;
};

export const transformWasteProcessId = (el) => {
  if (el) {
    return {
      ...el,
      fulltext: `[${el?.codeName}] - ${el?.name}}`,
      fulltext_html: `<b>[${el?.codeName}]</b> - ${el?.name}`,
    };
  }
  return null;
};

export const transformWasteCodeId = (el) => {
  if (el) {
    return {
      ...el,
      fulltext: `[${el?.code}] - ${el?.name}`,
      fulltext_html: `<b>[${el?.code}]</b> - ${el?.name}`,
    };
  }
  return null;
};

export const transformCommuneId = (el) => {
  if (el) {
    return {
      ...el,
      fulltext: el?.fulltext_html.replaceAll('<b>', '').replaceAll('</b>', ''),
    };
  }
  return null;
};

export const searchWasteCodeId = (event, setAutocompleteOptions) => {
  const searchText = event.query.trim();
  if (searchText.length) {
    BdoApiService.getWasteCodes(searchText, { show_loader: false })
      .then((response) => {
        const options = response.data.length ? response.data.map((el) => transformWasteCodeId(el)) : [];
        setAutocompleteOptions(options);
      })
      .catch((error) => console.error(error));
  } else {
    setAutocompleteOptions([]);
  }
};

export const wasteCodeIdItemTemplate = (item) => {
  if (item.wasteCodeId) {
    return (
      <div key={item.wasteCodeId}>
        <Tooltip
          className="max-width-unset"
          target={`.pr-item-tooltip-${item.wasteCodeId}`}
          mouseTrack
          mouseTrackLeft={10}
        >
          {item.name}
        </Tooltip>
        <div
          className={`pr-item-tooltip-${item.wasteCodeId}`}
          dangerouslySetInnerHTML={{
            __html: trimString(item.fulltext_html, 150, true),
          }}
        />
      </div>
    );
  }
  return <></>;
};

export const searchEup = (event, setAutocompleteOptions, receiverEupIdOptions) => {
  if (event.query.trim().length) {
    setAutocompleteOptions(
      receiverEupIdOptions.filter(
        (el) =>
          el.registrationNumber.includes(event.query.trim()) ||
          el.address.includes(event.query.trim()) ||
          el.name.includes(event.query.trim()),
      ),
    );
  } else {
    setAutocompleteOptions([...receiverEupIdOptions]);
  }
};

export const eupItemTemplate = (item, t) => {
  if (item.eupId) {
    return (
      <div key={item.eupId}>
        <Tooltip className="max-width-unset" target={`.pr-item-tooltip-${item.eupId}`} mouseTrack mouseTrackLeft={10}>
          <div className="flex flex-column">
            {item.address
              ? item.address
                  .replaceAll(': ', ':<b> ')
                  .split(', ')
                  .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
              : ''}
          </div>
          <span>
            <b>{t('registrationNumber')}: </b>
            {item.registrationNumber || ''}
          </span>
        </Tooltip>
        <div className={`pr-item-tooltip-${item.eupId}`}>{item.name}</div>
      </div>
    );
  }
  return <></>;
};

export const searchCompanyId = (event, setAutocompleteOptions) => {
  if (event.query.trim().length) {
    BdoApiService.getCompanies(event.query.trim(), { show_loader: false })
      .then((response) => {
        setAutocompleteOptions(response.data.length ? response.data : []);
      })
      .catch((error) => console.error(error));
  } else {
    setAutocompleteOptions([]);
  }
};

export const companyItemTemplate = (item, t) => {
  if (item.registrationNumber) {
    return (
      <div key={item.registrationNumber}>
        <Tooltip
          className="max-width-unset"
          target={`.pr-item-tooltip-${item.registrationNumber}`}
          mouseTrack
          mouseTrackLeft={10}
        >
          <div className="flex flex-column">
            {item.address
              ? item.address
                  .replaceAll(': ', ':<b> ')
                  .split(', ')
                  .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
              : ''}
            <span>
              <b>{t('identificationNumber')}:</b>
              {item.nip || ` ${t('noData')} `}
            </span>
            <span>
              <b>{t('identificationNumberEu')}:</b>
              {item.nipEu || ` ${t('noData')} `}
            </span>
            <span>
              <b>{t('personalIdNumber')}:</b>
              {item.pesel || ` ${t('noData')} `}
            </span>
          </div>
        </Tooltip>
        <div className={`pr-item-tooltip-${item.registrationNumber}`}>{item.name}</div>
      </div>
    );
  }
  return <></>;
};

export const searchCommuneId = (event, setAutocompleteOptions) => {
  if (event.query.trim().length) {
    BdoApiService.getCommunes(event.query.trim(), { show_loader: false })
      .then((response) => {
        const options = response.data.length ? response.data.map((el) => transformCommuneId(el)) : [];
        setAutocompleteOptions(options);
      })
      .catch((error) => console.error(error));
  } else {
    setAutocompleteOptions([]);
  }
};

export const communeIdItemTemplate = (item) => {
  if (item.PK) {
    return (
      <div key={item.PK}>
        <Tooltip className="max-width-unset" target={`.pr-item-tooltip-${item.PK}`} mouseTrack mouseTrackLeft={10}>
          {item.fulltext}
        </Tooltip>
        <div
          className={`pr-item-tooltip-${item.PK}`}
          dangerouslySetInnerHTML={{ __html: trimString(item.fulltext_html, 150, true) }}
        />
      </div>
    );
  }
  return <></>;
};

export const searchWasteGeneratingPlace = (event, setAutocompleteOptions) => {
  if (event.query.trim().length) {
    BdoApiService.getCommunes(event.query.trim(), { show_loader: false })
      .then((response) => {
        const options = response.data.length ? response.data.map((el) => transformWasteGeneratingPlace(el)) : [];
        setAutocompleteOptions(options);
      })
      .catch((error) => console.error(error));
  } else {
    setAutocompleteOptions([]);
  }
};

export const wasteGeneratingPlaceItemTemplate = (item) => {
  if (item.PK) {
    return (
      <div key={item.PK}>
        <Tooltip className="max-width-unset" target={`.pr-item-tooltip-${item.PK}`} mouseTrack mouseTrackLeft={10}>
          {item.fulltext}
        </Tooltip>
        <div
          className={`pr-item-tooltip-${item.PK}`}
          dangerouslySetInnerHTML={{ __html: trimString(item.fulltext_html, 150, true) }}
        />
      </div>
    );
  }
  return <></>;
};

export const searchWasteProcess = (event, wasteProcesses, setAutocompleteOptions) => {
  if (event.query.trim().length) {
    const options = wasteProcesses
      .filter((el) => el.name.includes(event.query.trim()) || el.codeName.includes(event.query.trim()))
      .map((el) => transformWasteProcessId(el));
    setAutocompleteOptions(options);
  }
};

export const wasteProcessIdItemTemplate = (item) => {
  if (item.fulltext_html) {
    return (
      <div key={item.codeName}>
        <Tooltip
          className="max-width-unset"
          target={`.pr-item-tooltip-${item.codeName}`}
          mouseTrack
          mouseTrackLeft={10}
        >
          {item.name}
        </Tooltip>
        <div
          className={`pr-item-tooltip-${item.codeName}`}
          dangerouslySetInnerHTML={{ __html: trimString(item.fulltext_html, 150, true) }}
        />
      </div>
    );
  }
  return <></>;
};

export const SubmitButton = ({ onClick, label }) => (
  <Button type="submit" label={label || 'Zapisz'} icon="pi pi-check" text onClick={onClick} />
);

export const CancelButton = ({ onClick, label }) => (
  <Button type="reset" label={label || 'Anuluj'} icon="pi pi-times" text onClick={onClick} />
);

export const ClearFiltersButton = ({ onClick, label }) => (
  <Button
    type="button"
    label={label || 'Wyczyść filtry'}
    icon="pi pi-filter-slash"
    outlined
    className="mr-2"
    onClick={onClick}
  />
);

export const ClearSectionButton = ({ onClick, label }) => (
  <Button type="button" label={label || 'Wyczyść'} text onClick={onClick} />
);

export const ChangeStatusDropdown = ({ options, onChange }) => (
  <Dropdown options={options} onChange={onChange} placeholder="Zmień status" className="mt-0 mr-0.5rem mb-0 ml-0" />
);

export const PrintButton = ({ onClick, label }) => (
  <Button
    type="button"
    label={label || 'Podgląd/Drukuj'}
    icon="pi pi-print"
    severity="info"
    className="ml-3"
    onClick={onClick}
  />
);

export const NewButton = ({ onClick, label }) => (
  <Button label={label || 'Nowa karta'} icon="pi pi-plus" severity="success" className="mr-2" onClick={onClick} />
);

export const prepareCorrectDateFormat = (dateObject, timeObject) => {
  const year = dateObject.getFullYear();
  const month = `${dateObject.getMonth() + 1}`.padStart(2, '0');
  const day = `${dateObject.getDate()}`.padStart(2, '0');
  const hour = `${timeObject.getHours()}`.padStart(2, '0');
  const minute = `${timeObject.getMinutes()}`.padStart(2, '0');

  return `${year}-${month}-${day}T${hour}:${minute}:00`;
};

export const ChooseLocation = () => {
  const dispatch = useDispatch();

  const loggedUserContext = useSelector((state) => state.user.context);
  const loggedUser = useSelector((state) => state.user.loggedUser);

  const locations = loggedUserContext.locations
    ?.filter((location) => location.bdoEupId)
    ?.map((location) => {
      return { label: location.name, value: location.id };
    });

  const handleChangeLocation = (e) => {
    usersService
      .setCurrentLocationId(e.value, loggedUser.id)
      .then(() => dispatch(userActions.setCurrentLocationId(e.value)))
      .catch((error) => console.error(error));
  };

  return (
    <>
      {locations.length > 1 && (
        <Dropdown
          value={loggedUserContext.currentLocationId}
          options={locations}
          onChange={handleChangeLocation}
          placeholder="Wybierz oddział"
        />
      )}
      {locations.length === 1 && locations[0].label}
    </>
  );
};

export const downloadReport = (reportFormat, filters, paginationParameters, params) => {
  const generateReports = (options) => {
    BdoApiService.getBdoReport(options.format, filters, paginationParameters, params)
      .then((report) => {
        !options.download && getFileFromBuffer(report.data, options.format);
        options.download &&
          getFileFromBuffer(
            report.data,
            options.format,
            'raport_' + formatDateTime(moment()).replace(' ', '_') + options.extension,
          );
      })
      .catch((error) => console.error(error));
  };

  if (reportFormat === 'text/csv') generateReports({ download: true, format: 'text/csv', extension: '.xlsx' });
};
