import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from '../../../../_shared/FormErrorMessage';

interface ReceiptFormatSectionProps {
  formikInstance: any;
  handleChangeField: (a: any, t: string) => void;
}

const ReceiptFormatSection = ({ formikInstance, handleChangeField }: ReceiptFormatSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationPrints');

  const RECEIPT_ORIENTATION_OPTIONS = [
    { label: t('orientationOptionsLandscape'), value: false },
    { label: t('orientationOptionsHorizontal'), value: true },
  ];
  const RECEIPT_FORMAT_OPTIONS = [
    { label: 'A4', value: 'a4' },
    { label: 'A5', value: 'a5' },
  ];

  const receiptSetDefaultValue = () => {
    formikInstance.setFieldValue('receiptOrientation', false);
    formikInstance.setFieldValue('receiptFormat', 'a4');
    formikInstance.setFieldValue('receiptFont', 12);
    formikInstance.setFieldValue('receiptMargin', 20);
  };

  return (
    <Fieldset legend={t('header')} className="mt-1vh">
      <div className="p-fluid formgrid grid">
        <label htmlFor="receiptOrientation" className="col-3">
          {t('receiptOrientation')}
        </label>
        <div className="field col-2">
          <SelectButton
            id="receiptOrientation"
            value={formikInstance.values.receiptOrientation}
            options={RECEIPT_ORIENTATION_OPTIONS}
            onChange={formikInstance.handleChange}
          />
        </div>
      </div>
      <div className="p-fluid formgrid grid">
        <label htmlFor="receiptFormat" className="col-3">
          {t('receiptFormat')}
        </label>
        <div className="field col-2">
          <Dropdown
            id={`receiptFormat`}
            value={formikInstance.values.receiptFormat}
            options={RECEIPT_FORMAT_OPTIONS}
            onChange={(e) => handleChangeField(e, 'dropdown')}
            placeholder={t('receiptFormatPlaceholder')}
          />
        </div>
      </div>
      <div className="p-fluid formgrid grid">
        <label htmlFor="receiptFont" className="col-3">
          {t('receiptFont')}
        </label>
        <div className="field col-2">
          <InputNumber
            id="receiptFont"
            value={formikInstance.values.receiptFont}
            onValueChange={formikInstance.handleChange}
            mode="decimal"
            min={6}
            max={24}
            suffix=" px"
          />
        </div>
        <FormErrorMessage fieldName="receiptFont" formikInstance={formikInstance} />
      </div>
      <div className="p-fluid formgrid grid">
        <label htmlFor="receiptMargin" className="col-3">
          {t('receiptMargin')}
        </label>
        <div className="field col-2">
          <InputNumber
            id="receiptMargin"
            value={formikInstance.values.receiptMargin}
            onValueChange={formikInstance.handleChange}
            mode="decimal"
            min={0}
            max={100}
            suffix=" px"
          />
        </div>
        <FormErrorMessage fieldName="receiptMargin" formikInstance={formikInstance} />
      </div>
      <Button type="button" label={t('setDefaultValuesButton')} onClick={receiptSetDefaultValue} />
    </Fieldset>
  );
};

export default ReceiptFormatSection;
