import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomButton } from '../../_shared/CustomComponents';
import locationsService from '../../../services/ScaleoApiServices/LocationsService';
import DataTableFilterElements from '../../_shared/DataTableFilterElements';
import DeleteLocationDialog from './components/DeleteLocationDialog';
import NewOrEditLocationDialog from './components/NewOrEditLocationDialog';
import getPaginatorTemplate from '../../../utils/getPaginatorTemplate';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../types/reducer-state';
import { Tooltip } from 'primereact/tooltip';
import DeleteLocationsDialog from './components/DeleteLocationsDialog';
import { Location } from '../../../types/location';

const DataTableAny = DataTable as any;

const INIT_FILTERS = {
  name: null,
  shortName: null,
};

const INIT_PAGINATION_PARAMETERS = {
  order: {
    isAscending: true,
    orderColumn: 'name',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const INIT_PAGINATOR_STATE = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

const Locations = () => {
  const { t } = useTranslation('managementLocations');
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const [filters, setFilters] = useState<any>(INIT_FILTERS);
  const [paginationParameters, setPaginationParameters] = useState(INIT_PAGINATION_PARAMETERS);
  const [paginatorState, setPaginatorState] = useState(INIT_PAGINATOR_STATE);
  const [isOpenNewOrEditDialog, setIsOpenNewOrEditDialog] = useState(false);
  const [isOpenDeleteLocationDialog, setIsOpenDeleteLocationDialog] = useState(false);
  const [isOpenDeleteLocationsDialog, setIsOpenDeleteLocationsDialog] = useState(false);
  const [listData, setListData] = useState([]);
  const [selectedRow, setSelectedRow] = useState<Location | null>(null);
  const [selectedRows, setSelectedRows] = useState<Location[]>([]);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const refreshListData = useCallback(() => {
    locationsService
      .getLocationsSearch(filters, paginationParameters, globalFilter)
      .then((response) => {
        setListData(response.data.items);
        setPaginatorState({
          ...paginatorState,
          totalPages: response.data.totalPagesNumber,
          currentPage: paginatorState.currentPage > response.data.totalPagesNumber ? 1 : paginatorState.currentPage,
        });
      })
      .catch(() => {
        setListData([]);
      });
  }, [filters, paginationParameters, globalFilter]);

  useEffect(() => {
    refreshListData();
  }, [loggedUserContext.currentCustomer?.id, refreshListData]);

  const handleClickClearFilters = () => {
    setFilters({});
    setGlobalFilter('');
  };

  const header = (
    <div className="table-header text-2xl p-2">
      {t('header')} &#160;&#160;
      <div className="flex">
        <Button
          label={t('clearFilters')}
          icon="pi pi-filter-slash"
          className="mr-2"
          outlined
          onClick={handleClickClearFilters}
        />
        <span>
          <DataTableFilterElements.Text
            initialValue={globalFilter}
            onChangeFilter={setGlobalFilter}
            name={'global'}
            placeholder={t('searchPlaceholder')}
            isSearchIcon
          />
        </span>
      </div>
    </div>
  );

  const leftToolbarTemplate = () => {
    const handleClickNew = () => {
      setSelectedRow(null);

      setIsOpenNewOrEditDialog(true);
    };

    const handleClickDeleteLocations = () => {
      setSelectedRows(selectedRows);

      setIsOpenDeleteLocationsDialog(true);
    };

    return (
      <>
        <div className="reportTooltip">
          <Button
            label={t('addButton')}
            icon="pi pi-plus"
            className="mr-2"
            severity="success"
            onClick={handleClickNew}
            disabled={!allowAddingLocations()}
          />
          {!allowAddingLocations() && (
            <Tooltip target=".reportTooltip" position="bottom">
              {t('addingDenied')}
            </Tooltip>
          )}
        </div>
        <CustomButton
          label={t('deleteButton')}
          icon="pi pi-trash"
          severity="danger"
          onClick={handleClickDeleteLocations}
          disabled={!selectedRows || !selectedRows.length || selectedRows.map((row) => row?.isUsed).includes(true)}
          tooltip={!selectedRows.length ? t('selectLocation') : t('cannotDelete')}
          name={undefined}
          dataFlag={undefined}
          type={undefined}
        />
      </>
    );
  };

  const nameBodyTemplate = (rowData: any) => (
    <>
      <span className="p-column-title">{t('name')}</span>
      {rowData.name}
    </>
  );

  const shortNameBodyTemplate = (rowData: any) => (
    <>
      <span className="p-column-title">{t('shortName')}</span>
      {rowData.shortName}
    </>
  );

  const actionBodyTemplate = (rowData: any) => {
    const handleClickEditLocationRow = () => {
      setSelectedRow(rowData);

      setIsOpenNewOrEditDialog(true);
    };

    const handleClickDeleteLocationRow = () => {
      setSelectedRow(rowData);

      setIsOpenDeleteLocationDialog(true);
    };

    return (
      <div className="actions">
        <Button icon="pi pi-pencil" raised severity="success" className="mr-2" onClick={handleClickEditLocationRow} />
        {rowData.isUsed === false ? (
          <Button icon="pi pi-trash" raised severity="warning" onClick={handleClickDeleteLocationRow} />
        ) : (
          <Button
            icon="pi pi-trash"
            raised
            severity="secondary"
            tooltip={t('tooltip')}
            tooltipOptions={{ position: 'bottom' }}
          />
        )}
      </div>
    );
  };

  const handleCloseNewOrEditDialog = () => {
    setSelectedRow(null);
    setIsOpenNewOrEditDialog(false);

    refreshListData();
  };

  const handleCloseDeleteLocationDialog = () => {
    setSelectedRow(null);
    setIsOpenDeleteLocationDialog(false);

    refreshListData();
  };

  const handleCloseDeleteLocationsDialog = () => {
    setSelectedRows([]);
    setIsOpenDeleteLocationsDialog(false);

    refreshListData();
  };

  const handleChangeFilter = (value: any, name: string) => {
    const newFilters = { ...filters };
    newFilters[name] = value;
    setFilters(newFilters);
  };

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const allowAddingLocations = () =>
    Number.isInteger(loggedUserContext.limits?.locationsNumber) &&
    Number.isInteger(loggedUserContext.limitsState?.currentLocationsNumber)
      ? loggedUserContext.limits!.locationsNumber! > loggedUserContext.limitsState!.currentLocationsNumber
      : true;

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <NewOrEditLocationDialog
            visible={isOpenNewOrEditDialog}
            closeDialog={handleCloseNewOrEditDialog}
            item={selectedRow}
          />
          <DeleteLocationDialog
            visible={isOpenDeleteLocationDialog}
            closeDialog={handleCloseDeleteLocationDialog}
            selectedRow={selectedRow!}
          />
          <DeleteLocationsDialog
            visible={isOpenDeleteLocationsDialog}
            closeDialog={handleCloseDeleteLocationsDialog}
            selectedRows={selectedRows}
          />
          <Toolbar left={leftToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTableAny
                ref={dt}
                value={listData}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                removableSort
                rowHover
                rows={paginatorState.rows}
                paginator
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState)}
                emptyMessage={t('noData')}
                onSort={handleSort}
                selectionMode="checkbox"
                selection={selectedRows}
                onSelectionChange={(e: any) => setSelectedRows(e.value)}
                stripedRows
                filterDisplay="row"
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column field="id" header={t('id')}></Column>
                <Column
                  field="name"
                  header={t('name')}
                  sortable
                  body={nameBodyTemplate}
                  filter
                  showFilterMenu={false}
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.name}
                      onChangeFilter={handleChangeFilter}
                      name="name"
                      placeholder={t('filters')}
                    />
                  }
                ></Column>
                <Column
                  field="shortName"
                  header={t('shortName')}
                  sortable
                  body={shortNameBodyTemplate}
                  filter
                  showFilterMenu={false}
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.shortName}
                      onChangeFilter={handleChangeFilter}
                      name="shortName"
                      placeholder={t('filters')}
                    />
                  }
                ></Column>
                <Column body={actionBodyTemplate} className="w-100px text-center"></Column>
              </DataTableAny>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Locations;
