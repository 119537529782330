import BdoApiService from '../../../../services/BdoApiService';
import { prepareCorrectDateFormat } from '../../bdoUtils';

const possibleStatuses = {
  sender: {
    PLANNED: ['APPROVED'],
    APPROVED: ['CONFIRMATION_GENERATED', 'WITHDRAWN'],
    CONFIRMATION_GENERATED: ['WITHDRAWN'],
    WITHDRAWN: [],
    REJECTED: ['CORRECT'],
    RECEIVE_CONFIRMATION: [],
    TRANSPORT_CONFIRMATION: [],
  },
  carrier: {
    PLANNED: [],
    APPROVED: ['CONFIRMATION_GENERATED'],
    CONFIRMATION_GENERATED: [],
    WITHDRAWN: [],
    REJECTED: [],
    RECEIVE_CONFIRMATION: ['TRANSPORT_CONFIRMATION'],
    TRANSPORT_CONFIRMATION: [],
  },
  receiver: {
    PLANNED: [],
    APPROVED: [],
    CONFIRMATION_GENERATED: ['REJECTED', 'RECEIVE_CONFIRMATION'],
    WITHDRAWN: [],
    REJECTED: [],
    RECEIVE_CONFIRMATION: ['TRANSPORT_CONFIRMATION'],
    TRANSPORT_CONFIRMATION: [],
  },
};

const changeStatusMethods = {
  sender: {
    APPROVED: ({ kpokId }) => BdoApiService.KPOK_setStatusApproved({ kpokId }),
    CONFIRMATION_GENERATED: ({ kpokId }) => BdoApiService.KPOK_setStatusGeneratedConfirmation({ kpokId }),
    WITHDRAWN: ({ kpokId, remarks }) => BdoApiService.KPOK_setStatusWithDrawn({ kpokId, remarks }),
    CORRECT: ({
      kpokId,
      wasteCodeId,
      wasteMass,
      wasteCodeExtended,
      wasteCodeExtendedDescription,
      hazardousWasteReclassification,
      hazardousWasteReclassificationDescription,
    }) =>
      BdoApiService.KPOK_reviseCard({
        kpokId,
        wasteCodeId,
        wasteMass,
        wasteCodeExtended,
        wasteCodeExtendedDescription,
        hazardousWasteReclassification,
        hazardousWasteReclassificationDescription,
      }),
  },
  carrier: {
    CONFIRMATION_GENERATED: ({ kpokId, vehicleRegNumber, realTransportDate, realTransportTime }) =>
      BdoApiService.KPOK_setStatusGeneratedConfirmationCarrier({
        kpokId,
        vehicleRegNumber,
        realTransportDate: prepareCorrectDateFormat(realTransportDate, realTransportTime),
        realTransportTime:
          `0${realTransportTime.getHours()}`.slice(-2) + ':' + `0${realTransportTime.getMinutes()}`.slice(-2),
      }),
    TRANSPORT_CONFIRMATION: ({ kpokId, receiptEndingTime }) =>
      BdoApiService.KPOK_setStatusTransportConfirmation({ kpokId, receiptEndingTime }),
  },
  receiver: {
    REJECTED: ({ kpokId, remarks }) => BdoApiService.KPOK_setStatusRejected({ kpokId, remarks }),
    RECEIVE_CONFIRMATION: ({ kpokId, correctedWasteMass, remarks }) =>
      BdoApiService.KPOK_setStatusReceiveConfirmation({ kpokId, correctedWasteMass, remarks }),
  },
};

const statusesFilterOptions = (t) => {
  return [
    {
      label: t('planned'),
      value: 'PLANNED',
    },
    {
      label: t('approved'),
      value: 'APPROVED',
    },
    {
      label: t('confirmationGenerated'),
      value: 'CONFIRMATION_GENERATED',
    },
    {
      label: t('receiveConfirmation'),
      value: 'RECEIVE_CONFIRMATION',
    },
    {
      label: t('transportConfirmation'),
      value: 'TRANSPORT_CONFIRMATION',
    },
    {
      label: t('withdrawn'),
      value: 'WITHDRAWN',
    },
    {
      label: t('rejected'),
      value: 'REJECTED',
    },
  ];
};

const getStatuses = async () => {
  return BdoApiService.getStatuses().then((response) => {
    return response.data?.map((el) => {
      return {
        label: el.Name,
        value: el.CodeName,
      };
    });
  });
};

const statusUtils = {
  possibleStatuses,
  changeStatusMethods,
  statusesFilterOptions,
  getStatuses,
};

export default statusUtils;
