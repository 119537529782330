import React, { useState } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { RadioButton } from 'primereact/radiobutton';

interface GeneralSectionProps {
  formikInstance: any;
  handleChangeField: (e: any, t: string) => void;
}

const GeneralSection = ({ formikInstance, handleChangeField }: GeneralSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationWeighing');
  const [isSummaryScreenClicked, setIsSummaryScreenClicked] = useState(false);

  const isSummaryScreenVisible =
    formikInstance.values.summaryScreenAfterClosingWeighingVisible ||
    formikInstance.values.summaryScreenAfterMeasurementVisible;

  const UNIT_OPTIONS = [
    { label: t('unitOptionsKg'), value: 'kg' },
    { label: t('unitOptionsT'), value: 't' },
    { label: t('unitOptionsMg'), value: 'Mg' },
  ];

  return (
    <Fieldset legend={t('generalSectionHeader')} className="mt-1vh">
      <div className="p-fluid formgrid grid">
        <div className="field-checkbox col-12">
          <Checkbox
            id="summaryScreenVisible"
            checked={isSummaryScreenVisible || isSummaryScreenClicked}
            onChange={(e) => {
              setIsSummaryScreenClicked(!!e.target.checked);
              if (!e.target.checked) {
                formikInstance.setFieldValue('summaryScreenAfterClosingWeighingVisible', false);
                formikInstance.setFieldValue('summaryScreenAfterMeasurementVisible', false);
              } else {
                formikInstance.setFieldValue('summaryScreenAfterClosingWeighingVisible', true);
              }
            }}
          ></Checkbox>
          <label htmlFor="summaryScreenVisible" className="checkbox-label">
            {t('summaryScreenVisible')}
          </label>
        </div>
        <div className="ml-1vw">
          <div className="field-checkbox col-12">
            <RadioButton
              id="summaryScreenAfterMeasurementVisible"
              checked={formikInstance.values.summaryScreenAfterMeasurementVisible}
              onChange={() => {
                formikInstance.setFieldValue('summaryScreenAfterClosingWeighingVisible', false);
                formikInstance.setFieldValue('summaryScreenAfterMeasurementVisible', true);
                setIsSummaryScreenClicked(true);
              }}
            ></RadioButton>
            <label htmlFor="summaryScreenAfterMeasurementVisible" className="checkbox-label">
              {t('summaryScreenAfterMeasurementVisible')}
            </label>
          </div>
          <div className="field-checkbox col-12">
            <RadioButton
              id="summaryScreenAfterClosingWeighingVisible"
              checked={formikInstance.values.summaryScreenAfterClosingWeighingVisible}
              onChange={() => {
                formikInstance.setFieldValue('summaryScreenAfterClosingWeighingVisible', true);
                formikInstance.setFieldValue('summaryScreenAfterMeasurementVisible', false);
                setIsSummaryScreenClicked(true);
              }}
            ></RadioButton>
            <label htmlFor="summaryScreenAfterClosingWeighingVisible" className="checkbox-label">
              {t('summaryScreenAfterClosingWeighingVisible')}
            </label>
          </div>
        </div>
      </div>
      <div className="p-fluid formgrid grid">
        <div className="field-checkbox col-12">
          <Checkbox
            id="multipleOpenWeighingsForTheSameVehicle"
            checked={formikInstance.values.multipleOpenWeighingsForTheSameVehicle}
            onChange={(e) => handleChangeField(e, 'checkbox')}
          ></Checkbox>
          <label htmlFor="multipleOpenWeighingsForTheSameVehicle" className="checkbox-label">
            {t('multipleOpenWeighingsForTheSameVehicle')}
          </label>
        </div>
      </div>
      <div className="p-fluid formgrid grid">
        <div className="field-checkbox col-12">
          <Checkbox
            id="autoPrintReceiptWeight"
            checked={formikInstance.values.autoPrintReceiptWeight}
            onChange={(e) => handleChangeField(e, 'checkbox')}
          ></Checkbox>
          <label htmlFor="autoPrintReceiptWeight" className="checkbox-label">
            {t('autoPrintReceiptWeight')}
          </label>
        </div>
        <div className="field-checkbox col-12">
          <Checkbox
            id="printPosReceiptDefaultValue"
            checked={formikInstance.values.printPosReceiptDefaultValue}
            onChange={(e) => handleChangeField(e, 'checkbox')}
          ></Checkbox>
          <label htmlFor="printPosReceiptDefaultValue" className="checkbox-label">
            {t('printPosReceiptDefaultValue')}
          </label>
        </div>
        <div className="field-checkbox col-12">
          <Checkbox
            id="mergeWeighingsSummary"
            checked={formikInstance.values.mergeWeighingsSummary}
            onChange={(e) => handleChangeField(e, 'checkbox')}
          ></Checkbox>
          <label htmlFor="mergeWeighingsSummary" className="checkbox-label">
            {t('mergeWeighingsSummary')}
          </label>
        </div>
        <div className="field-checkbox col-12">
          <Checkbox
            id="playSoundWhileAutomaticSummary"
            checked={formikInstance.values.playSoundWhileAutomaticSummary}
            onChange={(e) => handleChangeField(e, 'checkbox')}
          ></Checkbox>
          <label htmlFor="playSoundWhileAutomaticSummary" className="checkbox-label">
            {t('playSoundWhileAutomaticSummary')}
          </label>
        </div>
        <div className="field-checkbox col-12">
          <Checkbox
            id="addTrailerRegistrationNumbersSupport"
            checked={formikInstance.values.addTrailerRegistrationNumbersSupport}
            onChange={(e) => handleChangeField(e, 'checkbox')}
          ></Checkbox>
          <label htmlFor="addTrailerRegistrationNumbersSupport" className="checkbox-label">
            {t('addTrailerRegistrationNumbersSupport')}
          </label>
        </div>
      </div>
      {/* <div className="p-fluid formgrid grid">
      <div className="field-checkbox col-12">
        <Checkbox
          id="autoEmailReceiptWeight"
          checked={formikInstance.values.autoEmailReceiptWeight}
          onChange={(e) => handleChangeField(e, 'checkbox')}
        ></Checkbox>
        <label htmlFor="autoEmailReceiptWeight" className="checkbox-label">
          {t('autoEmailReceiptWeight')}&nbsp;
          <TooltipInfo _key={'autoEmailReceiptWeight'} text={t('autoEmailReceiptWeightTooltip')} />
        </label>
      </div>
    </div> */}
      <div className="p-fluid formgrid grid mt-5">
        <div className="field col-2">
          <label htmlFor="transactionTypeDefault">{t('transactionTypeDefault')}</label>
          <Dropdown
            id={`unit`}
            options={UNIT_OPTIONS}
            value={formikInstance.values.unit}
            onChange={(e) => handleChangeField(e, 'dropdown')}
            placeholder={t('transactionTypeDefaultPlaceholder')}
          />
        </div>
      </div>
    </Fieldset>
  );
};

export default GeneralSection;
