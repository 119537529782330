import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FORMIK_FIELD_NAMES } from '../../components/Weighing/HandlingWeighings/components/NewWeighingForm/components/variables';
import {
  getRestAdditionalFieldsValues,
  checkIfIndicationOutOfRange,
  posPrinterNode,
} from '../../components/Weighing/HandlingWeighings/components/NewWeighingForm/utils';
import { Weighing } from '../../types/weighing';
import { additionalFieldsReduce } from '../../utils/additionalFieldsUtils';
import useCommandTopics from '../../utils/useCommandTopics';
import { ReducerState } from '../../types/reducer-state';
import { v4 as uuidv4 } from 'uuid';

export const useNewWeighingFormSubmit = (
  initFormValues: any,
  publishWeighCommandHandler: (s: string, a: any) => void,
  setIsIndicationOutOfRange: (a: any) => void,
  onWeighingCloseRequested: (s: string) => void,
  setCloseWeighing: (w: Weighing) => void,
) => {
  const { t } = useTranslation('weighingHandlingWeighings');

  const { WEIGH_COMMAND_TOPIC_NAME, SAVE_TEST_WEIGHING_MEASUREMENT_COMMAND_TOPIC_NAME } = useCommandTopics();

  const [measurementType, setMeasurementType] = useState<string | null>(null);
  const [testWeighingDialogData, setTestWeighingDialogData] = useState({ visible: false, weighingAction: null });

  const { weighingConfiguration, formState, weighingState, selectedScale } = useSelector(
    (state: ReducerState) => state.weighing,
  );
  const { currentLocationId, currentCustomer, nodes } = useSelector((state: ReducerState) => state.user.context);
  const { currentIndication } = useSelector((state: ReducerState) => state.indication);

  const validateMethod = (data: any) => {
    const errors = {} as Record<string, string>;

    if (!formState.selectedVehicle && !formState.selectedContainer) {
      if (!formState.selectedVehicle) {
        errors.vehicle = t('newWeighingFormValidationNoVehicle');
      } else {
        errors.container = t('newWeighingFormValidationNoContainer');
      }
    }
    if (
      !data.transactionType &&
      weighingConfiguration.transactionTypeRequired &&
      data.selectedWeighingModeId !== 'CONTROL' &&
      data.selectedWeighingModeId !== 'TARE'
    ) {
      errors.transactionType = t('newWeighingFormValidationNoType');
    }

    const validateAdditionalField = (field: any, id: string) => {
      if (!field.isOptional && !field.value) {
        errors[id] = t('newWeighingFormValidationEmptyValue');
      } else if (
        field.type === 'NUMBER' &&
        field.min &&
        field.min !== '' &&
        field.value !== '' &&
        +field.value < +field.min
      ) {
        errors[id] = t('newWeighingFormValidationMinValue', { fieldMax: field.min });
      } else if (
        field.type === 'NUMBER' &&
        field.max &&
        field.max !== '' &&
        field.value !== '' &&
        +field.value > +field.max
      ) {
        errors[id] = t('newWeighingFormValidationMaxValue', { fieldMax: field.max });
      }
    };

    data.additionalFields?.forEach((field: any, i: number) =>
      validateAdditionalField(field, `${FORMIK_FIELD_NAMES.additionalFields}-${i}`),
    );
    data.measurementAdditionalFields?.forEach((field: any, i: number) =>
      validateAdditionalField(field, `${FORMIK_FIELD_NAMES.measurementAdditionalFields}-${i}`),
    );

    return errors;
  };

  const handleSubmit = async (data: any) => {
    const { reducedMeasurementRestAdditionalFields, reducedWeighingRestAdditionalFields } =
      getRestAdditionalFieldsValues(data.product?.id, weighingConfiguration.additionalFields);

    const weighingAction = (document?.activeElement as any)?.dataset.flag ?? measurementType; // custom data-flag attribute as a workaround to have multiple submit buttons in formik

    if (weighingAction === 'MANUAL_CLOSE') {
      setCloseWeighing(weighingState.openWeighing);
      onWeighingCloseRequested(weighingState.openWeighing.id);
    } else if (selectedScale.testScale) {
      setTestWeighingDialogData({ visible: true, weighingAction });
    } else {
      const { minWeight, maxWeight } = checkIfIndicationOutOfRange(selectedScale, currentIndication!);
      const indicationOutOfRange = [minWeight, maxWeight].some((v) => v === true);

      if (indicationOutOfRange) {
        setIsIndicationOutOfRange({
          minWeight,
          maxWeight,
        });
        window.scrollTo(0, 0);
      } else {
        publishWeighCommandHandler(WEIGH_COMMAND_TOPIC_NAME, {
          sessionId: uuidv4(),
          weighingId: weighingState?.openWeighing?.id,
          weighingMode: formState.selectedWeighingModeId,
          transactionType: data.transactionType?.id,
          vehicleId: data.vehicle.id,
          vehicle: data.vehicle.registrationNumber,
          newVehicleRegistrationNumber: data.vehicle?.isNew ? data.vehicle.registrationNumber : null,
          newVehicleVisible: data.saveVehicle,
          updateTare: data.updateTare,
          contractorId: data.contractor?.id,
          productId: data.product?.id,
          driverId: data.driver?.id,
          updateVehicleDefaultData: data.updateData,
          posReceipt: {
            print: data.printPosReceipt,
            printerNodeId: posPrinterNode(nodes!)?.id,
          },
          scaleId: selectedScale.id,
          scale: selectedScale.name,
          locationId: currentLocationId,
          customerId: currentCustomer?.id,
          weighingMeasurementType: weighingAction,
          additionalFields: {
            ...reducedWeighingRestAdditionalFields,
            ...additionalFieldsReduce(data.additionalFields),
          },
          measurementAdditionalFields: {
            ...reducedMeasurementRestAdditionalFields,
            ...additionalFieldsReduce(data.measurementAdditionalFields),
          },
          containers: data.containers,
          trailerId: data.trailer?.id,
          newTrailerRegistrationNumber: data.trailer?.isNew ? data.trailer.registrationNumber : null,
        });
      }
    }
  };

  const handleSaveTestWeighing = (manualIndication: number, alibi: string) => {
    const { minWeight, maxWeight } = checkIfIndicationOutOfRange(selectedScale, currentIndication!, manualIndication);
    const indicationOutOfRange = [minWeight, maxWeight].some((v) => v === true);

    const { reducedMeasurementRestAdditionalFields, reducedWeighingRestAdditionalFields } =
      getRestAdditionalFieldsValues(formik.values.product?.id, weighingConfiguration.additionalFields);

    if (indicationOutOfRange) {
      setIsIndicationOutOfRange({
        minWeight: minWeight,
        maxWeight: maxWeight,
      });
      window.scrollTo(0, 0);
    } else {
      publishWeighCommandHandler(SAVE_TEST_WEIGHING_MEASUREMENT_COMMAND_TOPIC_NAME, {
        sessionId: uuidv4(),
        weighingId: weighingState?.openWeighing?.id,
        weighingMode: formState.selectedWeighingModeId,
        transactionType: formik.values.transactionType?.id,
        vehicleId: formik.values.vehicle?.id,
        newVehicleRegistrationNumber: formState.selectedVehicle?.isNew
          ? formState.selectedVehicle.registrationNumber
          : null,
        newVehicleVisible: formik.values.saveVehicle,
        updateTare: formik.values.updateTare,
        contractorId: formik.values.contractor?.id,
        productId: formik.values.product?.id,
        driverId: formik.values.driver?.id,
        updateVehicleDefaultData: formik.values.updateData,
        posReceipt: {
          print: formik.values.printPosReceipt,
          printerNodeId: posPrinterNode(nodes!)?.id,
        },
        scaleId: selectedScale.id,
        alibi: alibi,
        locationId: currentLocationId,
        customerId: currentCustomer?.id,
        weighingMeasurementType: testWeighingDialogData.weighingAction,
        manualIndication: manualIndication,
        additionalFields: {
          ...reducedWeighingRestAdditionalFields,
          ...additionalFieldsReduce(formik.values.additionalFields),
        },
        measurementAdditionalFields: {
          ...reducedMeasurementRestAdditionalFields,
          ...additionalFieldsReduce(formik.values.measurementAdditionalFields),
        },
        containers: formik.values.containers,
        container: formik.values.container,
        trailerId: formik.values.trailer?.id,
        newTrailerRegistrationNumber: formState.selectedTrailer?.isNew
          ? formState.selectedTrailer.registrationNumber
          : null,
      });
    }

    setTestWeighingDialogData({ visible: false, weighingAction: null });
  };

  const formik = useFormik({
    initialValues: initFormValues as any,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return {
    validateMethod,
    setMeasurementType,
    formik,
    testWeighingDialogData,
    setTestWeighingDialogData,
    handleSaveTestWeighing,
  };
};
