import axios from 'axios';

class DeploymentsService {
  async getScheduledDeployments() {
    return axios.get(`/deployments/scheduled`);
  }
  async confirmDeployment(postData) {
    return axios.post(`/deployments/confirm`, {
      data: postData,
    });
  }
}

const deploymentsService = new DeploymentsService();
export default deploymentsService;
