import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { useWeighingsListReceipts } from '../../../../hooks/WeighingsList/useWeighingsListReceipts';
import { hasOneOfPermissions, hasPermissions } from '../../../../utils/permissionsUtils';
import { FileUpload } from 'primereact/fileupload';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReducerState } from '../../../../types/reducer-state';
import { Weighing } from '../../../../types/weighing';
import { useWeighingsListToolbar } from '../../../../hooks/WeighingsList/useWeighingsListToolbar';

const FileUploadAny = FileUpload as any;

interface WeighingListToolbarProps {
  selectedWeighings: Weighing[];
  handleOpenNew: () => void;
  handleClickDeleteSelectedWeighings: () => void;
  showSendEmailDialog: (w: Weighing[]) => void;
  setReportFormat: (s: string) => void;
  setReportDialogVisible: (b: boolean) => void;
}

export const WeighingListToolbar = ({
  selectedWeighings,
  handleOpenNew,
  handleClickDeleteSelectedWeighings,
  showSendEmailDialog,
  setReportFormat,
  setReportDialogVisible,
}: WeighingListToolbarProps) => {
  const { t } = useTranslation('weighingWeighingList');

  const filters = useSelector((state: ReducerState) => state.user.filtersState.weighings);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const fileUploader = useRef<any>(null);

  const { allowAddingMeasurements, canGenerateReports, importData } = useWeighingsListToolbar(fileUploader);
  const receiptButtonOptions = useReceiptButtonOptions(selectedWeighings, showSendEmailDialog);

  const leftToolbarTemplate = () => {
    return (
      <>
        {hasOneOfPermissions(['WEIGHING', 'WEIGHINGS_EDIT'], loggedUserContext.permissions) && (
          <div className="reportTooltipMeasurements">
            <Button
              type="button"
              label={t('toolbarAddButton')}
              icon="pi pi-plus"
              severity="success"
              className="mr-2"
              onClick={handleOpenNew}
              disabled={!allowAddingMeasurements()}
            />
            {!allowAddingMeasurements() && (
              <Tooltip target=".reportTooltipMeasurements" position="bottom">
                {t('blockAdding')}
              </Tooltip>
            )}
          </div>
        )}

        {hasPermissions(['WEIGHINGS_DELETE'], loggedUserContext.permissions) && (
          <Button
            type="button"
            label={t('toolbarDeleteButton')}
            icon="pi pi-trash"
            severity="danger"
            onClick={handleClickDeleteSelectedWeighings}
            disabled={!selectedWeighings || !selectedWeighings.length}
          />
        )}
      </>
    );
  };

  const rightToolbarTemplate = () => {
    const triggerButton = (self: any) => {
      self.currentTarget.nextSibling.click();
    };

    return (
      <>
        {hasOneOfPermissions(['WEIGHING', 'WEIGHINGS_REPORTS'], loggedUserContext.permissions) && (
          <SplitButton
            className="p-button-help my-split-button"
            label={t('toolbarReceiptButton')}
            icon="pi pi-chevron-down"
            model={receiptButtonOptions}
            onClick={triggerButton}
            disabled={!selectedWeighings || !selectedWeighings.length}
          />
        )}
        &#160;&#160;
        {hasPermissions(['WEIGHINGS_REPORTS'], loggedUserContext.permissions) && (
          <>
            <div className="reportTooltipLocations">
              <SplitButton
                className="p-button-help my-split-button"
                label={t('toolbarReportButton')}
                icon="pi pi-chevron-down"
                model={[
                  {
                    label: t('toolbarReportExcelButton'),
                    icon: 'pi pi-file-excel',
                    command: () => {
                      setReportFormat('text/csv');
                      setReportDialogVisible(true);
                    },
                  },
                  {
                    label: t('toolbarReportPdfButton'),
                    icon: 'pi pi-file-pdf',
                    command: () => {
                      setReportFormat('application/pdf');
                      setReportDialogVisible(true);
                    },
                  },
                ]}
                onClick={triggerButton}
                disabled={!filters.date || !filters.date[1]}
              />
              <Tooltip target=".reportTooltipLocations" disabled={filters.date && filters.date[1]} position="bottom">
                {!canGenerateReports() ? t('blockGeneratingReports') : t('toolbarReportTooltip')}
              </Tooltip>
            </div>
          </>
        )}
        <FileUploadAny
          visible={false}
          ref={fileUploader}
          mode="basic"
          accept=".csv"
          maxFileSize={1000000}
          name={t('toolbarImport')}
          chooseLabel={t('toolbarImport')}
          className="mr-2 hidden"
          customUpload
          auto
          uploadHandler={importData}
        />
      </>
    );
  };

  return <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />;
};

const useReceiptButtonOptions = (selectedWeighings: Weighing[], showSendEmailDialog: (w: Weighing[]) => void) => {
  const { t } = useTranslation('weighingWeighingList');

  const { printReceipts, generateReceipts } = useWeighingsListReceipts(selectedWeighings);

  const receiptButtonOptions = [
    {
      label: t('toolbarReceiptDownloadButton'),
      icon: 'pi pi-download',
      command: () => {
        generateReceipts({ download: true });
      },
    },
    {
      label: t('toolbarReceiptSendButton'),
      icon: 'pi pi-reply',
      command: () => {
        showSendEmailDialog(selectedWeighings);
      },
    },
  ];

  if (navigator.userAgent.indexOf('Firefox') !== -1) {
    receiptButtonOptions.unshift({
      label: t('toolbarReceiptViewPrintButton'),
      icon: 'pi pi-eye',
      command: () => {
        generateReceipts({ download: false });
      },
    });
  } else {
    receiptButtonOptions.unshift({
      label: t('toolbarReceiptPrintButton'),
      icon: 'pi pi-print',
      command: () => {
        printReceipts();
      },
    });
    receiptButtonOptions.unshift({
      label: t('toolbarReceiptViewButton'),
      icon: 'pi pi-eye',
      command: () => {
        generateReceipts({ download: false });
      },
    });
  }

  return receiptButtonOptions;
};
