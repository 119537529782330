import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dictionary } from '../../../../types/dictionary';
import { DictionaryAdditionalFieldAutocomplete } from '../../../_shared/DictionaryAdditionalFieldAutocomplete';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import { ensureDictionaryValueIncluded } from '../../../../utils/additionalFieldsUtils';

interface AdditionalFieldsProps {
  formikInstance: any;
  dictionaries: Dictionary[] | null;
}

const AdditionalFields = ({ formikInstance, dictionaries }: AdditionalFieldsProps) => {
  const [localTouched, setLocalTouched] = useState<Record<string, any>>({});

  const renderAdditionalField = (field: any, index: number) => {
    if (!field.isActive) {
      return <></>;
    }
    const identifier = `additionalFields-${index}`;
    const fieldValue = formikInstance.values.additionalFields?.[index]?.value;

    const handleChange = (value: any) => {
      const oldValue = formikInstance.values.additionalFields;
      const newValue = [];

      for (const [oldValueIndex, oldValueField] of oldValue.entries()) {
        if (index === oldValueIndex) {
          newValue.push({ ...oldValueField, value });
        } else {
          newValue.push({ ...oldValueField });
        }
      }
      const newLocalTouched = localTouched;
      newLocalTouched[identifier] = true;
      setLocalTouched(newLocalTouched);
      void formikInstance.setFieldValue('additionalFields', newValue);
    };

    const renderDictionaryType = () => {
      const sourceDictionary = dictionaries && dictionaries.find((d) => d.id === field.dictionaryId);

      let source = [];

      if (sourceDictionary) {
        source = sourceDictionary.values;
      } else if (fieldValue) {
        source.push(fieldValue);
      }

      source = ensureDictionaryValueIncluded(source, field.value);

      if (field.value && !source.includes(field.value)) {
        source.push(field.value);
      }

      return (
        <DictionaryAdditionalFieldAutocomplete
          id={identifier}
          value={source?.find((el: string) => el === fieldValue) || null}
          onChange={handleChange}
          source={source}
        />
      );
    };

    const renderNumberType = () => (
      <InputText
        id={identifier}
        value={fieldValue || ''}
        onChange={(e) => handleChange(e.target.value)}
        type="number"
      />
    );

    const renderTextType = () => (
      <InputText id={identifier} value={fieldValue || ''} onChange={(e) => handleChange(e.target.value)} />
    );

    const METHODS = {
      DICTIONARY: renderDictionaryType,
      NUMBER: renderNumberType,
      TEXT: renderTextType,
    };

    if (!!METHODS[field.type as keyof typeof METHODS]) {
      const showUnit = !!field.unit;
      const showRequiredStar = !field.isOptional;

      return (
        <div key={field.name} className="field">
          <label htmlFor={identifier}>
            {field.name}
            {showUnit && (
              <>
                &nbsp;<small>{`[${field.unit}]`}</small>
              </>
            )}
            {showRequiredStar && (
              <span className="text-red-500">
                <small>*</small>
              </span>
            )}
          </label>
          {METHODS[field.type as keyof typeof METHODS]()}
          <FormErrorMessage
            fieldName={identifier}
            formikInstance={formikInstance}
            withTouchedTrue={!!formikInstance.submitCount || !!localTouched[identifier]}
          />
        </div>
      );
    }

    return <React.Fragment key={field.name}></React.Fragment>;
  };

  return <>{formikInstance?.values?.additionalFields?.map(renderAdditionalField)}</>;
};

export default AdditionalFields;
