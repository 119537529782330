import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from 'primereact/panel';
import { useFormik } from 'formik';
import BdoApiService from '../../../../../services/BdoApiService';
import FormErrorMessage from '../../../../_shared/FormErrorMessage';
import statusUtils from '../../components/statusUtils';
import ChangeStatusDialogs from '../../components/ChangeStatusDialogs';
import formChangeFieldMethod from '../../../../../utils/formChangeFieldMethod';
import {
  DEFAULT_DIALOG_BREAKPOINTS,
  DEFAULT_DIALOG_STYLE,
  CancelButton,
  communeIdItemTemplate,
  searchCommuneId,
  searchWasteCodeId,
  SubmitButton,
  transformCommuneId,
  transformWasteCodeId,
  wasteCodeIdItemTemplate,
  prepareCorrectDateFormat,
  ChangeStatusDropdown,
  PrintButton,
  limitOfDigitsAfterDecimalsFilter,
  disableDotInTextInput,
  searchWasteGeneratingPlace,
  wasteGeneratingPlaceItemTemplate,
} from '../../../bdoUtils';
import documentUtils from '../../components/documentUtils';
import * as moment from 'moment';
import FormErrorMessageScroller from '../../../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { CardSubtype } from '../../../../../enums/card-subtype.enum';

const INIT_FORM_STATE = {
  communeId: null,
  communeArea: null,
  wasteCodeId: null,
  vehicleRegNumber: '',
  wasteMass: '',
  plannedTransportTime: new Date(Date.now()),
  plannedTransportTimeDate: new Date(Date.now()),
  realTransportTime: null,
  additionalInfo: '',
  wasteCodeExtended: false,
  wasteCodeExtendedDescription: '',
  hazardousWasteReclassification: false,
  hazardousWasteReclassificationDescription: '',
};

const EditApprovedCardDialog = ({ visible, closeDialog, item }) => {
  const { t } = useTranslation('kpokSender');

  const mode = item?.cardSubtype;

  const CHANGE_STATUS_OPTIONS = [
    { label: t('generateConfirmation'), value: 'GENERATE_CONFIRMATION' },
    { label: t('withdrawn'), value: 'WITHDRAWN' },
  ];

  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [currentEupInfo, setCurrentEupInfo] = useState(null);
  const [currentSenderCompanyInfo, setCurrentSenderCompanyInfo] = useState(null);
  const [currentCarrierCompanyInfo, setCurrentCarrierCompanyInfo] = useState(null);
  const [isOpenChangeStatusDialog, setIsOpenChangeStatusDialog] = useState(false);
  const [currentReceiverCompanyInfo, setCurrentReceiverCompanyInfo] = useState(null);
  const [currentReceiverEupInfo, setCurrentReceiverEupInfo] = useState(null);

  useEffect(() => {
    BdoApiService.getCurrentEupInfo().then((eupResponse) => {
      setCurrentEupInfo(eupResponse.data);
      BdoApiService.getCompany(eupResponse.data.companyId).then((companyResponse) => {
        setCurrentSenderCompanyInfo(companyResponse.data);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialValues = useCallback(async () => {
    const response = await BdoApiService.KPOK_getApprovedCard(item.kpokId, 'Sender');

    BdoApiService.getCompany(response.data.carrierCompanyId).then((res) => {
      setCurrentCarrierCompanyInfo(res.data);
    });
    BdoApiService.getCompany(response.data.receiverCompanyId).then((res) => {
      setCurrentReceiverCompanyInfo(res.data);
    });
    BdoApiService.getEup(response.data.receiverEupId).then((res) => {
      setCurrentReceiverEupInfo(res.data);
    });

    const {
      wasteCodeId,
      vehicleRegNumber,
      plannedTransportTime,
      realTransportTime,
      additionalInfo,
      wasteCodeExtended,
      wasteCodeExtendedDescription,
      hazardousWasteReclassification,
      hazardousWasteReclassificationDescription,
      year,
      cardNumber,
      cardStatus,
      communeId,
      communeArea,
      approvalUser,
      cardApprovalTime,
    } = response.data;

    const wasteMass = response.data.wasteMass || null;

    const initValuesObj = {
      ...INIT_FORM_STATE,
      vehicleRegNumber,
      wasteMass,
      plannedTransportTime: new Date(plannedTransportTime),
      plannedTransportTimeDate: new Date(plannedTransportTime),
      additionalInfo,
      wasteCodeExtended,
      hazardousWasteReclassification,
      realTransportTime: new Date(realTransportTime),
      year,
      cardNumber,
      cardStatus,
      communeArea,
      approvalUser,
      cardApprovalTime: new Date(cardApprovalTime),
    };
    if (wasteCodeExtended) {
      initValuesObj.wasteCodeExtendedDescription = wasteCodeExtendedDescription;
    }
    if (hazardousWasteReclassification) {
      initValuesObj.hazardousWasteReclassificationDescription = hazardousWasteReclassificationDescription;
    }

    const wasteCodeIdResponse = await BdoApiService.getWasteCodeByWasteCodeId(wasteCodeId);
    initValuesObj.wasteCodeId = transformWasteCodeId(wasteCodeIdResponse.data);

    const communeIdResponse = await BdoApiService.getCommune(communeId);
    initValuesObj.communeId = transformCommuneId(communeIdResponse.data);

    setInitFormValues(initValuesObj);
    formik.setTouched('plannedTransportTime');
    formik.setTouched('plannedTransportTimeDate');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [INIT_FORM_STATE, item]);

  useEffect(() => {
    if (visible) {
      getInitialValues();
    }
  }, [getInitialValues, visible]);

  const validateMethod = (data) => {
    const errors = {};

    if (!data.wasteCodeId) {
      errors.wasteCodeId = t('emptyFieldError');
    }

    if (mode === CardSubtype.RECEIVE && !data.communeId) {
      errors.communeId = t('emptyFieldError');
    }

    if (mode === CardSubtype.TRANSFER && !data.wasteMass) {
      errors.wasteMass = t('emptyFieldError');
    }

    if (!data.vehicleRegNumber) {
      errors.vehicleRegNumber = t('emptyFieldError');
    }

    if (!data.plannedTransportTimeDate) {
      errors.plannedTransportTimeDate = t('emptyFieldError');
    } else {
      const plannedTransportTimeDateTime = moment(data.plannedTransportTimeDate)
        .set('hour', moment(data.plannedTransportTime).get('hours'))
        .set('minutes', moment(data.plannedTransportTime).get('minutes'));
      if (moment(plannedTransportTimeDateTime.toDate()).isBefore(new Date(Date.now()))) {
        errors.plannedTransportTimeDate = t('plannedTransportTimeDateError');
      }
    }

    if (!data.plannedTransportTime) {
      errors.plannedTransportTime = t('emptyFieldError');
    }

    if (data.wasteCodeExtended && !data.wasteCodeExtendedDescription) {
      errors.wasteCodeExtendedDescription = t('emptyFieldError');
    }

    return errors;
  };

  const handleCloseDialog = (changes, statusFilter) => {
    formik.resetForm({
      values: INIT_FORM_STATE,
    });
    closeDialog(changes === true, statusFilter);
  };

  const saveChanges = async (data) => {
    let resultBody = {
      kpokId: item.kpokId,
      communeId: data.communeId?.PK || data.communeId,
      communeArea: data.communeArea,
      wasteCodeId: data.wasteCodeId?.wasteCodeId || data.wasteCodeId,
      vehicleRegNumber: data.vehicleRegNumber || '',
      plannedTransportTime: prepareCorrectDateFormat(data.plannedTransportTimeDate, data.plannedTransportTime),
      realTransportTime: prepareCorrectDateFormat(data.plannedTransportTimeDate, data.plannedTransportTime),
      additionalInfo: data.additionalInfo || '',
      wasteCodeExtended: data.wasteCodeExtended,
      wasteCodeExtendedDescription: data.wasteCodeExtendedDescription || '',
      hazardousWasteReclassification: data.hazardousWasteReclassification,
      hazardousWasteReclassificationDescription: data.hazardousWasteReclassificationDescription || '',
    };

    if (mode === CardSubtype.RECEIVE) {
      await BdoApiService.KPOK_updateApprovedReceiveCard(resultBody);
    }

    if (mode === CardSubtype.TRANSFER) {
      resultBody = { ...resultBody, wasteMass: parseFloat(data.wasteMass) || 0 };
      await BdoApiService.KPOK_updateApprovedTransferCard(resultBody);
    }
  };

  const handleSubmit = async (data) => {
    await saveChanges(data);
    handleCloseDialog(true);
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleCloseChangeStatusDialog = (changes, statusFilter) => {
    setIsOpenChangeStatusDialog(false);
    if (changes) {
      handleCloseDialog(true, statusFilter);
    }
  };

  const handleChangeStatus = (e) => {
    const METHODS = {
      GENERATE_CONFIRMATION: () =>
        formik.validateForm().then((errors) => {
          if (Object.keys(errors).length > 0) {
            formik.setTouched({ ...formik.touched, ...errors });
          } else {
            saveChanges(formik.values).then(() => {
              statusUtils.changeStatusMethods.sender.CONFIRMATION_GENERATED({ kpokId: item.kpokId }).then(() => {
                handleCloseDialog(true, 'CONFIRMATION_GENERATED');
              });
            });
          }
        }),
      WITHDRAWN: () => setIsOpenChangeStatusDialog(true),
    };

    METHODS[e.value] && METHODS[e.value]();
  };

  const dialogFooter = (
    <div className="grid justify-content-center">
      <CancelButton onClick={handleCloseDialog} />
      <SubmitButton onClick={formik.submitForm} />
      <ChangeStatusDropdown options={CHANGE_STATUS_OPTIONS} onChange={handleChangeStatus} />
      <PrintButton onClick={() => documentUtils.KPOK_downloadDocument(item.kpokId, mode)} label={t('print')} />
    </div>
  );

  const handleChangeField = (e, type) => {
    formChangeFieldMethod(e, type, formik);
    if (!formik.values.wasteCodeExtended) {
      formik.setFieldValue('wasteCodeExtendedDescription', INIT_FORM_STATE.wasteCodeExtendedDescription, false);
    }
    if (!formik.values.hazardousWasteReclassification) {
      formik.setFieldValue(
        'hazardousWasteReclassificationDescription',
        INIT_FORM_STATE.hazardousWasteReclassificationDescription,
        false,
      );
    }
    if (!formik.values.receiverCompanyId) {
      formik.setFieldValue('receiverEupId', INIT_FORM_STATE.receiverEupId, false);
    }
  };

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={visible}
        header={
          <>
            {t('editApprovedCardDialogHeader')}
            <br />
            <small className="color-green-500">{mode === CardSubtype.RECEIVE ? t('header1') : t('header2')}</small>
          </>
        }
        modal
        className="p-fluid"
        breakpoints={DEFAULT_DIALOG_BREAKPOINTS}
        style={DEFAULT_DIALOG_STYLE}
        footer={dialogFooter}
        onHide={handleCloseDialog}
      >
        <form>
          <Panel header=" " className="pt-1vh">
            <div className="p-fluid formgrid grid">
              <div className="field col-4">
                <label>{t('calendarYear')}</label>
                <p>
                  <b>{initFormValues?.year || '-'}</b>
                </p>
              </div>
              <div className="field col-4">
                <label>{t('fullCardNumber')}</label>
                <p>
                  <b>{initFormValues?.cardNumber || '-'}</b>
                </p>
              </div>
              <div className="field col-4">
                <label>{t('cardStatus')}</label>
                <p>
                  <b>{initFormValues?.cardStatus || '-'}</b>
                </p>
              </div>
            </div>
          </Panel>
          <Panel
            header={mode === CardSubtype.RECEIVE ? t('receiverData') : t('senderData')}
            toggleable
            className="pt-1vh"
          >
            <div className="p-fluid formgrid grid pt-1vh">
              <div className="field col-4">
                <label>{t('senderRegistrationNumber')}</label>
                <p>
                  <b>{currentSenderCompanyInfo?.registrationNumber || '-'}</b>
                </p>
              </div>
              <div className="field col-4">
                <label>{t('senderIdentificationNumber')}</label>
                <p>
                  <b>{currentSenderCompanyInfo?.nip || '-'}</b>
                </p>
              </div>
              <div className="field col-4">
                <label>{t('identificationNumberEu')}</label>
                <p>
                  <b>{currentSenderCompanyInfo?.nipEu || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('fullSenderName')}</label>
                <p>
                  <b>{currentSenderCompanyInfo?.name || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('senderAddress')}</label>
                <p>
                  {currentSenderCompanyInfo?.address
                    ? currentSenderCompanyInfo?.address
                        .replaceAll(': ', ':<b> ')
                        .split(', ')
                        .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                    : '-'}
                </p>
              </div>
              {mode === CardSubtype.TRANSFER ? (
                <>
                  <div className="field col-4">
                    <label>{t('senderPlaceNumber')}</label>
                    <p>
                      <b>{currentEupInfo?.registrationNumber || '-'}</b>
                    </p>
                  </div>
                  <div className="field col-8">
                    <label>{t('senderPlaceName')}</label>
                    <p>
                      <b>{currentEupInfo?.name || '-'}</b>
                    </p>
                  </div>
                  <div className="field col-12">
                    <label>{t('senderPlaceAddress')}</label>
                    <p>
                      {currentEupInfo?.address
                        ? currentEupInfo?.address
                            .replaceAll(': ', ':<b> ')
                            .split(', ')
                            .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                        : '-'}
                    </p>
                  </div>
                  <div className="field-checkbox col-12">
                    <Checkbox
                      id="isWasteGenerating"
                      checked={formik.values.isWasteGenerating}
                      onChange={(e) => handleChangeField(e, 'checkbox')}
                    ></Checkbox>
                    <label htmlFor="isWasteGenerating" className="p-checkbox-label">
                      {t('wasteInfo')}
                    </label>
                    <FormErrorMessage fieldName="isWasteGenerating" formikInstance={formik} />
                  </div>
                  <div className="field col-12">
                    <label htmlFor="wasteGeneratedTerytPk">
                      {t('wasteGeneratedTeryt')}
                      {formik.values.isWasteGenerating ? <span className="text-red-500">&nbsp;*</span> : ''}
                    </label>
                    <AutoComplete
                      id="wasteGeneratedTerytPk"
                      field="fulltext"
                      value={formik.values.wasteGeneratedTerytPk}
                      suggestions={autocompleteOptions}
                      completeMethod={(event) => searchWasteGeneratingPlace(event, setAutocompleteOptions)}
                      onChange={(e) => handleChangeField(e, 'autocomplete')}
                      forceSelection
                      itemTemplate={wasteGeneratingPlaceItemTemplate}
                      disabled={!formik.values.isWasteGenerating}
                    />
                    <FormErrorMessage fieldName="wasteGeneratedTerytPk" formikInstance={formik} />
                  </div>
                  <div className="field col-12">
                    <label htmlFor="wasteGeneratingAdditionalInfo">{t('wasteGeneratingAdditionalInfo')}</label>
                    <InputText
                      id="wasteGeneratingAdditionalInfo"
                      value={formik.values.wasteGeneratingAdditionalInfo}
                      onChange={(e) => handleChangeField(e, 'text')}
                      disabled={!formik.values.isWasteGenerating}
                    />
                    <FormErrorMessage fieldName="wasteGeneratingAdditionalInfo" formikInstance={formik} />
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </Panel>
          <Panel header={t('transporterData')} toggleable className="pt-1vh">
            <div className="p-fluid formgrid grid">
              <div className="field col-4">
                <label>{t('transporterRegistrationNumber')}</label>
                <p>
                  <b>{currentCarrierCompanyInfo?.registrationNumber || '-'}</b>
                </p>
              </div>
              <div className="field col-4">
                <label>{t('transporterIdentificationNumber')}</label>
                <p>
                  <b>{currentCarrierCompanyInfo?.nip || '-'}</b>
                </p>
              </div>
              <div className="field col-4">
                <label>{t('identificationNumberEu')}</label>
                <p>
                  <b>{currentCarrierCompanyInfo?.nipEu || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('transporterName')}</label>
                <p>
                  <b>{currentCarrierCompanyInfo?.name || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('transporterAddress')}</label>
                <p>
                  {currentCarrierCompanyInfo?.address
                    ? currentCarrierCompanyInfo?.address
                        .replaceAll(': ', ':<b> ')
                        .split(', ')
                        .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                    : '-'}
                </p>
              </div>
            </div>
          </Panel>
          <Panel header={t('receiverData')} toggleable className="pt-1vh">
            <div className="p-fluid formgrid grid">
              <div className="field col-4">
                <label>{t('receiverRegistrationNumber')}</label>
                <p>
                  <b>{currentReceiverCompanyInfo?.registrationNumber || '-'}</b>
                </p>
              </div>
              <div className="field col-4">
                <label>{t('receiverIdentificationNumber')}</label>
                <p>
                  <b>{currentReceiverCompanyInfo?.nip || '-'}</b>
                </p>
              </div>
              <div className="field col-4">
                <label>{t('identificationNumberEu')}</label>
                <p>
                  <b>{currentReceiverCompanyInfo?.nipEu || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('fullReceiverName')}</label>
                <p>
                  <b>{currentReceiverCompanyInfo?.name || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('receiverAddress')}</label>
                <p>
                  {currentReceiverCompanyInfo?.address
                    ? currentReceiverCompanyInfo?.address
                        .replaceAll(': ', ':<b> ')
                        .split(', ')
                        .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                    : '-'}
                </p>
              </div>
              <div className="field col-4">
                <label>{t('receiverPlaceNumber')}</label>
                <p>
                  <b>{currentReceiverEupInfo?.registrationNumber || '-'}</b>
                </p>
              </div>
              <div className="field col-8">
                <label>{t('receiverPlaceName')}</label>
                <p>
                  <b>{currentReceiverEupInfo?.name || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('receiverPlaceAddress')}</label>
                <p>
                  {currentReceiverEupInfo?.address
                    ? currentReceiverEupInfo?.address
                        .replaceAll(': ', ':<b> ')
                        .split(', ')
                        .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                    : '-'}
                </p>
              </div>
            </div>
          </Panel>
          <Panel
            header={mode === CardSubtype.RECEIVE ? t('receiverWasteData') : t('senderWasteData')}
            toggleable
            className="pt-1vh"
          >
            <div className="p-fluid formgrid grid">
              <div className="field col-12">
                <label htmlFor="communeId">
                  {t('communeName')}
                  {mode === CardSubtype.RECEIVE ? <span className="text-red-500">&nbsp;*</span> : ''}
                </label>
                <AutoComplete
                  id="communeId"
                  field="fulltext"
                  value={formik.values.communeId}
                  suggestions={autocompleteOptions}
                  completeMethod={(event) => searchCommuneId(event, setAutocompleteOptions)}
                  onChange={(e) => handleChangeField(e, 'autocomplete')}
                  forceSelection
                  itemTemplate={communeIdItemTemplate}
                  placeholder={t('search')}
                />
                <FormErrorMessage fieldName="communeId" formikInstance={formik} />
              </div>

              <div className="field col-12">
                <label htmlFor="communeArea">{t('communeArea')}</label>
                <InputText
                  id="communeArea"
                  value={formik.values.communeArea || ''}
                  onChange={(e) => handleChangeField(e, 'text')}
                />
                <FormErrorMessage fieldName="communeArea" formikInstance={formik} />
              </div>

              <div className="field col-12">
                <label htmlFor="wasteCodeId">
                  {t('wasteCodeAndType')}
                  <span className="text-red-500">&nbsp;*</span>
                </label>
                <AutoComplete
                  id="wasteCodeId"
                  field="fulltext"
                  value={formik.values.wasteCodeId}
                  suggestions={autocompleteOptions}
                  completeMethod={(event) => searchWasteCodeId(event, setAutocompleteOptions)}
                  onChange={(e) => handleChangeField(e, 'autocomplete')}
                  forceSelection
                  itemTemplate={wasteCodeIdItemTemplate}
                  placeholder={t('search')}
                />
                <FormErrorMessage fieldName="wasteCodeId" formikInstance={formik} />
              </div>
              <div className="field-checkbox col-4">
                <Checkbox
                  id="wasteCodeExtended"
                  checked={formik.values.wasteCodeExtended}
                  onChange={(e) => handleChangeField(e, 'checkbox')}
                ></Checkbox>
                <label htmlFor="wasteCodeExtended" className="p-checkbox-label">
                  {t('wasteCodeExtended')}
                </label>
                <FormErrorMessage fieldName="wasteCodeExtended" formikInstance={formik} />
              </div>
              <div className="field col-8">
                <label htmlFor="wasteCodeExtendedDescription">{t('wasteTypeExtended')}</label>
                <InputText
                  id="wasteCodeExtendedDescription"
                  value={formik.values.wasteCodeExtendedDescription || ''}
                  onChange={(e) => handleChangeField(e, 'text')}
                  disabled={!formik.values.wasteCodeExtended}
                />
                <FormErrorMessage fieldName="wasteCodeExtendedDescription" formikInstance={formik} />
              </div>
              <div className="field-checkbox col-4">
                <Checkbox
                  id="hazardousWasteReclassification"
                  checked={formik.values.hazardousWasteReclassification}
                  onChange={(e) => handleChangeField(e, 'checkbox')}
                ></Checkbox>
                <label htmlFor="hazardousWasteReclassification" className="p-checkbox-label">
                  {t('hazardousWasteReclassificationFull')}
                </label>
                <FormErrorMessage fieldName="hazardousWasteReclassification" formikInstance={formik} />
              </div>
              <div className="field col-8">
                <label htmlFor="hazardousWasteReclassificationDescription">
                  {t('hazardousWasteReclassificationType')}
                </label>
                <InputText
                  id="hazardousWasteReclassificationDescription"
                  value={formik.values.hazardousWasteReclassificationDescription || ''}
                  onChange={(e) => handleChangeField(e, 'text')}
                  disabled={!formik.values.hazardousWasteReclassification}
                />
                <FormErrorMessage fieldName="hazardousWasteReclassificationDescription" formikInstance={formik} />
              </div>

              {mode === CardSubtype.TRANSFER ? (
                <div className="field col-12">
                  <label htmlFor="wasteMass">
                    {t('wasteMass')}
                    <span className="text-red-500">&nbsp;*</span>
                  </label>
                  <InputText
                    id="wasteMass"
                    value={formik.values.wasteMass || ''}
                    onKeyDown={(e) => disableDotInTextInput(e)}
                    onInput={(e) => limitOfDigitsAfterDecimalsFilter(e, 4)}
                    onChange={(e) => handleChangeField(e, 'text')}
                    type="number"
                  />
                  <FormErrorMessage fieldName="wasteMass" formikInstance={formik} />
                </div>
              ) : (
                ''
              )}

              <div className="field col-12">
                <label htmlFor="vehicleRegNumber">
                  {t('vehicleRegistrationNumber')}
                  <span className="text-red-500">&nbsp;*</span>
                </label>
                <InputText
                  id="vehicleRegNumber"
                  value={formik.values.vehicleRegNumber || ''}
                  onChange={(e) =>
                    void formik.setFieldValue('vehicleRegNumber', (e?.target?.value ?? e?.value)?.toUpperCase())
                  }
                />
                <FormErrorMessage fieldName="vehicleRegNumber" formikInstance={formik} />
              </div>
              <div className="field col-12 lg:col-6">
                <label htmlFor="plannedTransportTimeDate">
                  {mode === CardSubtype.RECEIVE ? t('plannedReceiveTime') : t('plannedTransportTime')}
                  <span className="text-red-500">&nbsp;*</span>
                </label>
                <Calendar
                  inline
                  id="plannedTransportTimeDate"
                  value={formik.values.plannedTransportTimeDate}
                  onChange={(e) => handleChangeField(e, 'date')}
                  dateFormat="yy-mm-dd"
                  placeholder={t('select')}
                  footerTemplate={() => <></>}
                  minDate={new Date(Date.now())}
                />
                <FormErrorMessage fieldName="plannedTransportTimeDate" formikInstance={formik} />
              </div>
              <div className="field col-12 lg:col-6">
                <label htmlFor="plannedTransportTime">
                  {mode === CardSubtype.RECEIVE ? t('plannedTransportHour') : t('plannedReceiveHour')}
                  <span className="text-red-500">&nbsp;*</span>
                </label>
                <Calendar
                  inline
                  id="plannedTransportTime"
                  value={formik.values.plannedTransportTime}
                  onChange={(e) => formik.setFieldValue(e.target.id, e.value, false)}
                  timeOnly
                  hourFormat="24"
                  placeholder={t('search')}
                />
                <FormErrorMessage fieldName="plannedTransportTime" formikInstance={formik} />
              </div>
            </div>
          </Panel>
          {initFormValues?.approvalUser ? (
            <Panel header={t('cardData')} toggleable className="pt-1vh">
              <div className="p-fluid formgrid grid">
                <div className="field col-12 md:col-6 lg:col-8">
                  <label>{t('approvalUser')}</label>
                  <p>
                    <b>{initFormValues?.approvalUser || '-'}</b>
                  </p>
                </div>
                <div className="field col-12 md:col-6 lg:col-4">
                  <label>{t('cardApprovalTime')}</label>
                  <p>
                    <b>{initFormValues?.cardApprovalTime?.toLocaleString() || '-'}</b>
                  </p>
                </div>
              </div>
            </Panel>
          ) : (
            ''
          )}
          <Panel header={t('additionalInfo')} toggleable className="pt-1vh">
            <div className="p-fluid formgrid grid">
              <div className="field col-12">
                <label htmlFor="additionalInfo">{t('additionalInfo')}</label>
                <InputTextarea
                  id="additionalInfo"
                  value={formik.values.additionalInfo}
                  onChange={(e) => handleChangeField(e, 'textarea')}
                  rows={5}
                  autoResize
                />
                <FormErrorMessage fieldName="additionalInfo" formikInstance={formik} />
              </div>
            </div>
          </Panel>
          <br />
          <br />
          <br />
        </form>
      </Dialog>
      <ChangeStatusDialogs.Sender.WITHDRAWN
        visible={isOpenChangeStatusDialog}
        closeDialog={handleCloseChangeStatusDialog}
        item={item}
      />
    </FormErrorMessageScroller>
  );
};

export default EditApprovedCardDialog;
