import { default as KpoSender } from './Kpo/Sender/Sender';
import { default as KpoCarrier } from './Kpo/Carrier/Carrier';
import { default as KpoReceiver } from './Kpo/Receiver/Receiver';
import { default as KpokSender } from './Kpok/Sender/Sender';
import { default as KpokCarrier } from './Kpok/Carrier/Carrier';
import { default as KpokReceiver } from './Kpok/Receiver/Receiver';
import './style.scss';

const Bdo = {
  Kpo: {
    Sender: KpoSender,
    Carrier: KpoCarrier,
    Receiver: KpoReceiver,
  },
  Kpok: {
    Sender: KpokSender,
    Carrier: KpokCarrier,
    Receiver: KpokReceiver,
  },
};

export default Bdo;
