import { Fieldset } from 'primereact/fieldset';
import { WeighingContainer } from '../../../../../../store/types/weighing-slice';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { useTranslation } from 'react-i18next';

interface ContainersListProps {
  formik: any;
  updateFormState: (a: any) => void;
}

export const ContainersList = ({ formik, updateFormState }: ContainersListProps) => {
  const { t } = useTranslation('containersForm');

  if (!formik.values.containers || formik.values.containers.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className="grid mt-5vh">
        <div className="col-12 flex">
          <Fieldset className="p-0 m-0" legend={t('containersSumUp')}>
            {formik.values.containers?.map((c: WeighingContainer, index: number) => (
              <>
                <div className="card" key={index}>
                  <div className="grid">
                    <div className="col-12 mt-3 mb-3">
                      <label htmlFor="containerNameCode" className="text-xl p-3">
                        {`${c.name}(${c.code})`}
                      </label>
                    </div>
                    <div className="col-2 flex justify-content-center align-items-center">
                      <label htmlFor="transactionType">{t('receive')}</label>
                    </div>
                    <div className="col-3 mt-2">
                      <InputNumber
                        id="receiveNumber"
                        value={c.receiveNumber}
                        mode="decimal"
                        className="ml-6"
                        onChange={(e) => {
                          if (!e.value || e?.value < 0) {
                            return;
                          }
                          const containers = [...formik.values.containers];
                          const containerToChange = containers[index];
                          containers[index] = { ...containerToChange, receiveNumber: e.value };
                          formik.setFieldValue('containers', containers);
                          updateFormState({ containers });
                        }}
                      />
                    </div>
                    <div className="col-6 mt-2">
                      <Button
                        type="button"
                        icon="pi pi-angle-up"
                        severity="info"
                        onClick={() => {
                          const containers = [...formik.values.containers];
                          const containerToChange = containers[index];
                          containers[index] = {
                            ...containerToChange,
                            receiveNumber: containerToChange.receiveNumber + 1,
                          };
                          formik.setFieldValue('containers', containers);
                          updateFormState({ containers });
                        }}
                        className="ml-2 mr-1"
                      />
                      <Button
                        type="button"
                        icon="pi pi-angle-down"
                        severity="info"
                        onClick={() => {
                          const containers = [...formik.values.containers];
                          const containerToChange = containers[index];
                          if (containerToChange.receiveNumber === 0) {
                            return;
                          }
                          containers[index] = {
                            ...containerToChange,
                            receiveNumber: containerToChange.receiveNumber - 1,
                          };
                          formik.setFieldValue('containers', containers);
                          updateFormState({ containers });
                        }}
                      />
                    </div>

                    <div className="col-2 mt-3 flex justify-content-center align-items-center">
                      <label htmlFor="transactionType">{t('return')}</label>
                    </div>
                    <div className="col-3 mt-3">
                      <InputNumber
                        id="receiveNumber"
                        value={c.returnNumber}
                        mode="decimal"
                        className="ml-6"
                        onChange={(e) => {
                          if (!e.value || e?.value < 0) {
                            return;
                          }
                          const containers = [...formik.values.containers];
                          const containerToChange = containers[index];
                          containers[index] = { ...containerToChange, returnNumber: e.value };
                          formik.setFieldValue('containers', containers);
                          updateFormState({ containers });
                        }}
                      />
                    </div>
                    <div className="col-6 mt-3">
                      <Button
                        type="button"
                        icon="pi pi-angle-up"
                        severity="info"
                        onClick={() => {
                          const containers = [...formik.values.containers];
                          const containerToChange = containers[index];
                          containers[index] = {
                            ...containerToChange,
                            returnNumber: containerToChange.returnNumber + 1,
                          };
                          formik.setFieldValue('containers', containers);
                          updateFormState({ containers });
                        }}
                        className="ml-2 mr-1"
                      />
                      <Button
                        type="button"
                        icon="pi pi-angle-down"
                        severity="info"
                        onClick={() => {
                          const containers = [...formik.values.containers];
                          const containerToChange = containers[index];
                          if (containerToChange.returnNumber === 0) {
                            return;
                          }
                          containers[index] = {
                            ...containerToChange,
                            returnNumber: containerToChange.returnNumber - 1,
                          };
                          formik.setFieldValue('containers', containers);
                          updateFormState({ containers });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            ))}
          </Fieldset>
        </div>
      </div>
    </>
  );
};
