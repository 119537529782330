import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import React, { useState, useEffect } from 'react';
import '../../../DataTable.css';
import contractorsService from '../../../../services/ScaleoApiServices/ContractorsService';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import { InputMask } from 'primereact/inputmask';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { isModulesEnabled } from '../../../../utils/modulesUtils';
import { ReducerState } from '../../../../types/reducer-state';
import { useSelector } from 'react-redux';
import { ReceiverUpdatedStatus } from '../../../../enums/receiver-updated-status.enum';

const INIT_FORM_STATE = {
  id: null,
  code: '',
  companyName: '',
  firstName: '',
  lastName: '',
  taxId: '',
  email: '',
  phone: '',
  personalId: '',
  idCard: '',
  street: '',
  zip: '',
  city: '',
  autoPrint: false,
  autoSend: false,
  type: 'company',
  kpoReceiverUpdatedStatus: null,
  kpoReceiverUpdateMass: null,
  kpoReceiverAddMassToCardRemarks: null,
  kpoReceiverUpdatedStatusCard: false,
  kpokReceiveReceiverUpdatedStatus: null,
  kpokReceiveReceiverAddMassToCardRemarks: null,
  kpokReceiveReceiverUpdatedStatusCard: false,
  kpokTransferReceiverUpdatedStatus: null,
  kpokTransferReceiverUpdateMass: null,
  kpokTransferReceiverAddMassToCardRemarks: null,
  kpokTransferReceiverUpdatedStatusCard: false,
};

interface NewOrEditContractorDialogProps {
  contractorDialogVisible: boolean;
  setContractorDialogVisible: (b: boolean) => void;
  items: any;
  onContractorAdded?: (a: any) => void;
}

const NewOrEditContractorDialog = ({
  contractorDialogVisible,
  setContractorDialogVisible,
  items,
  onContractorAdded,
}: NewOrEditContractorDialogProps) => {
  const { t } = useTranslation('datasetsContractors');
  const { t: t2 } = useTranslation('managementSystemConfigurationBdo');
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const hideDialog = () => {
    setContractorDialogVisible(false);
    formik.resetForm(INIT_FORM_STATE as any);
  };

  useEffect(() => {
    if (contractorDialogVisible && items?.id) {
      setInitFormValues({
        ...items,
        kpoReceiverUpdatedStatusCard: items.kpoReceiverUpdatedStatus !== null,
        kpokReceiveReceiverUpdatedStatusCard: items.kpokReceiveReceiverUpdatedStatus !== null,
        kpokTransferReceiverUpdatedStatusCard: items.kpokTransferReceiverUpdatedStatus !== null,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
  }, [contractorDialogVisible, items]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data) => {
      const errors: any = {};

      if (!data.code) {
        errors.code = t('noCodeError');
      } else if (data.code.length > 20) {
        errors.code = t('tooLongCodeError');
      }
      if (!data.companyName && data.type === 'company') {
        errors.companyName = t('noCompanyNameError');
      }
      if (!data.firstName && data.type === 'individual') {
        errors.firstName = t('noFirstNameError');
      }
      if (!data.lastName && data.type === 'individual') {
        errors.lastName = t('noLastNameError');
      }
      if (data.email) {
        const re = /\S+@\S+\.\S+/;
        if (!re.test(data.email)) errors.email = t('incorrectEmailError');
      }
      if (data.phone) {
        const re = /\+?\d+/;
        if (!re.test(data.phone)) errors.phone = t('incorrectPhoneError');
      }

      return errors;
    },
    onSubmit: async (formData, helpers) => {
      if (!formData.id) {
        contractorsService
          .createContractors(formData)
          .then((res) => {
            hideDialog();
            onContractorAdded && onContractorAdded(contractorsService.mapContractor(res.data));
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('code', t('contractorExistError'));
            }
          });
      } else {
        contractorsService
          .editContractors(formData, formData.id)
          .then(() => {
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('code', t('contractorExistError'));
            }
          });
      }
    },
    enableReinitialize: true,
  });

  const taxIdHandler = (event: any) => {
    const reg = /^[a-zA-Z0-9]/;
    if (reg.test(event.nativeEvent.data)) formik.setFieldValue('taxId', event.target.value);
  };

  const contractorDialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={hideDialog} />
      <Button type="submit" label={t('saveButton')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={contractorDialogVisible}
        header={t('details')}
        modal
        className="p-fluid w-40vw"
        footer={contractorDialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      >
        <form>
          <div className="col-12">
            <div className="field">
              <SelectButton
                value={formik.values.type}
                options={[
                  { label: 'Firma', value: 'company' },
                  { label: 'Osoba fizyczna', value: 'individual' },
                ]}
                id="type"
                onChange={formik.handleChange}
              />
            </div>
            <div className="field">
              <label htmlFor="code">{t('code')}</label>
              <InputText id="code" value={formik.values.code} onChange={formik.handleChange} autoFocus />
              <FormErrorMessage fieldName="code" formikInstance={formik} />
            </div>
            {formik.values.type === 'company' && (
              <div className="field">
                <label htmlFor="companyName">{t('companyName')}</label>
                <InputText id="companyName" value={formik.values.companyName} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="companyName" formikInstance={formik} />
              </div>
            )}
            {formik.values.type === 'individual' && (
              <>
                <div className="grid">
                  <div className="col-6">
                    <label htmlFor="firstName">{t('firstName')}</label>
                    <InputText id="firstName" value={formik.values.firstName} onChange={formik.handleChange} />
                    <FormErrorMessage fieldName="firstName" formikInstance={formik} />
                  </div>
                  <div className="col-6">
                    <label htmlFor="lastName">{t('lastName')}</label>
                    <InputText id="lastName" value={formik.values.lastName} onChange={formik.handleChange} />
                    <FormErrorMessage fieldName="lastName" formikInstance={formik} />
                  </div>
                </div>
                <div className="grid">
                  <div className="col-6">
                    <label htmlFor="personalId">{t('personalId')}</label>
                    <InputText id="personalId" value={formik.values.personalId} onChange={formik.handleChange} />
                    <FormErrorMessage fieldName="personalId" formikInstance={formik} />
                  </div>
                  <div className="col-6">
                    <label htmlFor="idCard">{t('idCard')}</label>
                    <InputText id="idCard" value={formik.values.idCard} onChange={formik.handleChange} />
                    <FormErrorMessage fieldName="idCard" formikInstance={formik} />
                  </div>
                </div>
              </>
            )}
            <div className="field">
              <label htmlFor="street">{t('street')}</label>
              <InputText id="street" value={formik.values.street} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="street" formikInstance={formik} />
            </div>
            <div className="grid">
              <div className="col-4">
                <label htmlFor="zip">{t('zip')}</label>
                <InputMask mask="99-999" id="zip" value={formik.values.zip} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="zip" formikInstance={formik} />
              </div>
              <div className="col-8">
                <label htmlFor="city">{t('city')}</label>
                <InputText id="city" value={formik.values.city} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="city" formikInstance={formik} />
              </div>
            </div>

            <div className="field">
              <label htmlFor="taxId">{t('taxId')}</label>
              <div className="vat">
                <InputText id="taxId" value={formik.values.taxId} onChange={taxIdHandler} />
              </div>
            </div>
            <div className="field">
              <label htmlFor="email">{t('email')}</label>
              <InputText id="email" value={formik.values.email} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="email" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="phone">{t('phone')}</label>
              <InputText id="phone" value={formik.values.phone} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="phone" formikInstance={formik} />
            </div>
            {isModulesEnabled(['BDO'], loggedUserContext.currentCustomer?.subscriptionModules) && (
              <>
                <span className="field-checkbox top-auto">
                  <Checkbox
                    id="kpoReceiverUpdatedStatusCard"
                    checked={formik.values.kpoReceiverUpdatedStatusCard}
                    onChange={(e) => {
                      formik.handleChange(e);
                      if (!e.target.value) {
                        formik.setFieldValue('kpoReceiverUpdatedStatus', null);
                        formik.setFieldValue('kpoReceiverUpdateMass', null);
                        formik.setFieldValue('kpoReceiverAddMassToCardRemarks', null);
                      }
                    }}
                  />
                  <label htmlFor="kpoReceiverUpdatedStatusCard">{t2('updateKpoStatusForWeighing')}</label>
                </span>
                {formik.values.kpoReceiverUpdatedStatusCard && (
                  <div className="field">
                    <label htmlFor="kpoReceiverUpdatedStatus">{t('status')}</label>
                    <Dropdown
                      id="kpoReceiverUpdatedStatus"
                      value={formik.values.kpoReceiverUpdatedStatus}
                      onChange={(e) => formik.setFieldValue('kpoReceiverUpdatedStatus', e.value)}
                      options={[
                        { value: ReceiverUpdatedStatus.RECEIVE_CONFIRMATION, name: t2('receiveConfirmation') },
                        { value: ReceiverUpdatedStatus.REJECT, name: t2('reject') },
                      ]}
                      optionValue="value"
                      optionLabel="name"
                    />
                  </div>
                )}
                {formik.values.kpoReceiverUpdatedStatus === ReceiverUpdatedStatus.RECEIVE_CONFIRMATION && (
                  <span className="field-checkbox">
                    <Checkbox
                      id="kpoReceiverUpdateMass"
                      checked={!!formik.values.kpoReceiverUpdateMass}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="kpoReceiverUpdateMass">{t2('receiverUpdateMass')}</label>
                  </span>
                )}
                {(formik.values.kpoReceiverUpdatedStatus === ReceiverUpdatedStatus.RECEIVE_CONFIRMATION ||
                  formik.values.kpoReceiverUpdatedStatus === ReceiverUpdatedStatus.REJECT) && (
                  <span className="field-checkbox top-auto">
                    <Checkbox
                      id="kpoReceiverAddMassToCardRemarks"
                      checked={!!formik.values.kpoReceiverAddMassToCardRemarks}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="kpoReceiverAddMassToCardRemarks">{t2('receiverAddMassToCardRemarks')}</label>
                  </span>
                )}

                <span className="field-checkbox top-auto">
                  <Checkbox
                    id="kpokReceiveReceiverUpdatedStatusCard"
                    checked={formik.values.kpokReceiveReceiverUpdatedStatusCard}
                    onChange={(e) => {
                      formik.handleChange(e);
                      if (!e.target.value) {
                        formik.setFieldValue('kpokReceiveReceiverUpdatedStatus', null);
                        formik.setFieldValue('kpokReceiveReceiverUpdateMass', null);
                        formik.setFieldValue('kpokReceiveReceiverAddMassToCardRemarks', null);
                      }
                    }}
                  />
                  <label htmlFor="kpokReceiveReceiverUpdatedStatusCard">
                    {t2('updateKpokReceiveStatusForWeighing')}
                  </label>
                </span>
                {formik.values.kpokReceiveReceiverUpdatedStatusCard && (
                  <div className="field">
                    <label htmlFor="kpokReceiveReceiverUpdatedStatus">{t('status')}</label>
                    <Dropdown
                      id="kpokReceiveReceiverUpdatedStatus"
                      value={formik.values.kpokReceiveReceiverUpdatedStatus}
                      onChange={(e) => formik.setFieldValue('kpokReceiveReceiverUpdatedStatus', e.value)}
                      options={[
                        { value: ReceiverUpdatedStatus.RECEIVE_CONFIRMATION, name: t2('receiveConfirmation') },
                        { value: ReceiverUpdatedStatus.REJECT, name: t2('reject') },
                      ]}
                      optionValue="value"
                      optionLabel="name"
                    />
                  </div>
                )}
                {(formik.values.kpokReceiveReceiverUpdatedStatus === ReceiverUpdatedStatus.RECEIVE_CONFIRMATION ||
                  formik.values.kpokReceiveReceiverUpdatedStatus === ReceiverUpdatedStatus.REJECT) && (
                  <span className="field-checkbox top-auto">
                    <Checkbox
                      id="kpokReceiveReceiverAddMassToCardRemarks"
                      checked={!!formik.values.kpokReceiveReceiverAddMassToCardRemarks}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="kpokReceiveReceiverAddMassToCardRemarks">
                      {t2('receiverAddMassToCardRemarks')}
                    </label>
                  </span>
                )}

                <span className="field-checkbox top-auto">
                  <Checkbox
                    id="kpokTransferReceiverUpdatedStatusCard"
                    checked={formik.values.kpokTransferReceiverUpdatedStatusCard}
                    onChange={(e) => {
                      formik.handleChange(e);
                      if (!e.target.value) {
                        formik.setFieldValue('kpokTransferReceiverUpdatedStatus', null);
                        formik.setFieldValue('kpokTransferReceiverUpdateMass', null);
                        formik.setFieldValue('kpokTransferReceiverAddMassToCardRemarks', null);
                      }
                    }}
                  />
                  <label htmlFor="kpokTransferReceiverUpdatedStatusCard">
                    {t2('updateKpokTransferStatusForWeighing')}
                  </label>
                </span>
                {formik.values.kpokTransferReceiverUpdatedStatusCard && (
                  <div className="field">
                    <label htmlFor="kpokTransferReceiverUpdatedStatus">{t('status')}</label>
                    <Dropdown
                      id="kpokTransferReceiverUpdatedStatus"
                      value={formik.values.kpokTransferReceiverUpdatedStatus}
                      onChange={(e) => formik.setFieldValue('kpokTransferReceiverUpdatedStatus', e.value)}
                      options={[
                        { value: ReceiverUpdatedStatus.RECEIVE_CONFIRMATION, name: t2('receiveConfirmation') },
                        { value: ReceiverUpdatedStatus.REJECT, name: t2('reject') },
                      ]}
                      optionValue="value"
                      optionLabel="name"
                    />
                  </div>
                )}
                {formik.values.kpokTransferReceiverUpdatedStatus === ReceiverUpdatedStatus.RECEIVE_CONFIRMATION && (
                  <span className="field-checkbox top-auto">
                    <Checkbox
                      id="kpokTransferReceiverUpdateMass"
                      checked={!!formik.values.kpokTransferReceiverUpdateMass}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="kpokTransferReceiverUpdateMass">{t2('receiverUpdateMass')}</label>
                  </span>
                )}
                {(formik.values.kpokTransferReceiverUpdatedStatus === ReceiverUpdatedStatus.RECEIVE_CONFIRMATION ||
                  formik.values.kpokTransferReceiverUpdatedStatus === ReceiverUpdatedStatus.REJECT) && (
                  <span className="field-checkbox top-auto">
                    <Checkbox
                      id="kpokTransferReceiverAddMassToCardRemarks"
                      checked={!!formik.values.kpokTransferReceiverAddMassToCardRemarks}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="kpokTransferReceiverAddMassToCardRemarks">
                      {t2('receiverAddMassToCardRemarks')}
                    </label>
                  </span>
                )}
              </>
            )}
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};

export default NewOrEditContractorDialog;
