import { Dialog } from 'primereact/dialog';
import { Trans, useTranslation } from 'react-i18next';
import { SNAPSHOT_DIALOG_TYPE } from '../../../../enums/snapshot-dialog.enum';
import SnapshotsDialog from '../../../SnapshotDialog/SnapshotDialog';
import DeleteWeighingDialog from '../../components/DeleteWeighingDialog';
import ReportDialog from './ReportDialog/ReportDialog';
import WeighingDialog from './WeighingDialog/WeighingDialog';
import WeighingHistoryDialog from './WeighingDialog/WeighingHistoryDialog';
import { serviceToCall, downloadWeighingImages } from './helpers';
import { SendEmailDialog } from './SendEmailDialog';
import { WeighingListData } from '../../../../hooks/WeighingsList/useWeighingsListData';
import { default as NewCardDialogKpo } from '../../../Bdo/Kpo/Sender/components/NewCardDialog';
import { default as NewCardDialogKpok } from '../../../Bdo/Kpok/Sender/components/NewCardDialog';
import { Weighing } from '../../../../types/weighing';

interface WeighingDialogsProps {
  sendEmailDialogVisible: boolean;
  isOpenSnapshotsDialog: boolean;
  isOpenDialogKpo: boolean;
  isOpenDialogKpok: boolean;
  weighingDialogVisible: boolean;
  weighingHistoryVisible: boolean;
  deleteWeighingDialogVisible: boolean;
  deleteSelectedWeighingsDialogVisible: boolean;
  restoreWeighingDialogVisible: boolean;
  reportDialogVisible: boolean;
  componentData: WeighingListData;
  weighingsReceiptsToSend: string[];
  hideSendEmailDialog: () => void;
  setSelectedWeighings: (w: Weighing[] | null) => void;
  clickedRowId: string | null;
  setIsOpenSnapshotsDialog: (value: boolean) => void;
  handleCloseDialog: (changes: any) => void;
  bdoDialogData: any;
  dialogKpokMode: string;
  handleHideDialog: () => void;
  loadAfterChange: () => void;
  weighing: Partial<Weighing> | null;
  modulesConfiguration: any;
  setWeighingHistoryVisible: (value: boolean) => void;
  paginationParameters: any;
  handleCloseDeleteWeighingDialog: () => void;
  handleDeleteWeighing: () => void;
  handleCloseDeleteSelectedWeighingsDialog: () => void;
  handleDeleteSelectedWeighings: () => void;
  restoreWeighingDialogFooter: JSX.Element;
  handleCloseRestoreWeighingDialog: () => void;
  handleHideReportDialog: () => void;
  getReportDialogFilters: () => void;
  reportFormat: string | null;
  isWeighingSeriesEnabled: boolean;
}

export const WeighingDialogs = ({
  sendEmailDialogVisible,
  isOpenSnapshotsDialog,
  isOpenDialogKpo,
  isOpenDialogKpok,
  weighingDialogVisible,
  weighingHistoryVisible,
  deleteWeighingDialogVisible,
  deleteSelectedWeighingsDialogVisible,
  restoreWeighingDialogVisible,
  reportDialogVisible,
  componentData,
  weighingsReceiptsToSend,
  hideSendEmailDialog,
  setSelectedWeighings,
  clickedRowId,
  setIsOpenSnapshotsDialog,
  handleCloseDialog,
  bdoDialogData,
  dialogKpokMode,
  handleHideDialog,
  loadAfterChange,
  weighing,
  modulesConfiguration,
  setWeighingHistoryVisible,
  paginationParameters,
  handleCloseDeleteWeighingDialog,
  handleDeleteWeighing,
  handleCloseDeleteSelectedWeighingsDialog,
  handleDeleteSelectedWeighings,
  restoreWeighingDialogFooter,
  handleCloseRestoreWeighingDialog,
  handleHideReportDialog,
  getReportDialogFilters,
  reportFormat,
  isWeighingSeriesEnabled,
}: WeighingDialogsProps) => {
  const { t } = useTranslation('weighingWeighingList');

  return (
    <>
      {sendEmailDialogVisible && (
        <SendEmailDialog
          weighingsReceiptsToSend={weighingsReceiptsToSend}
          hideSendEmailDialog={hideSendEmailDialog}
          setSelectedWeighings={setSelectedWeighings}
          sendEmailDialogVisible={sendEmailDialogVisible}
        />
      )}
      {isOpenSnapshotsDialog && (
        <SnapshotsDialog
          data={componentData.weighings as any[]}
          currentDataId={clickedRowId as any}
          visible={isOpenSnapshotsDialog}
          closeDialog={() => setIsOpenSnapshotsDialog(false)}
          visibleText={false}
          type={SNAPSHOT_DIALOG_TYPE.WEIGHINGS}
          serviceToCall={serviceToCall}
          downloadImagesCallback={downloadWeighingImages}
        />
      )}
      {isOpenDialogKpo && (
        <NewCardDialogKpo
          visible={isOpenDialogKpo}
          closeDialog={handleCloseDialog}
          imposedValues={bdoDialogData.form}
          weighingId={bdoDialogData.weighingId}
          weighingContractor={bdoDialogData.weighingContractor}
        />
      )}
      {isOpenDialogKpok && (
        <NewCardDialogKpok
          visible={isOpenDialogKpok}
          closeDialog={handleCloseDialog}
          mode={dialogKpokMode}
          imposedValues={bdoDialogData.form}
          weighingId={bdoDialogData.weighingId}
          weighingContractor={bdoDialogData.weighingContractor}
        />
      )}
      {weighingDialogVisible && (
        <WeighingDialog
          visible={weighingDialogVisible}
          onClose={handleHideDialog}
          weighing={weighing}
          inputVehicles={componentData.inputVehicles}
          inputTrailers={componentData.inputTrailers}
          inputContractors={componentData.inputContractors}
          inputDrivers={componentData.inputDrivers}
          inputScales={componentData.inputScales}
          afterSubmitAction={loadAfterChange}
          canEnableBdoPlugin={componentData.isCorrectBdoModuleConfig && modulesConfiguration.bdo === 'enabled'}
          kpoWeighings={componentData.weighings?.filter((w) => !!w.cardId && !!w.cardType) as any[]}
          isCombine={false}
          weighingConfiguration={componentData.configuration}
          containers={componentData.containers}
        />
      )}
      {weighingHistoryVisible && (
        <WeighingHistoryDialog
          visible={weighingHistoryVisible}
          onClose={() => setWeighingHistoryVisible(false)}
          weighing={weighing}
          isCombine={false}
        />
      )}
      {deleteWeighingDialogVisible && (
        <DeleteWeighingDialog
          visible={deleteWeighingDialogVisible}
          onClose={handleCloseDeleteWeighingDialog}
          onDelete={handleDeleteWeighing}
          weighingNumber={weighing?.number}
        />
      )}
      {deleteSelectedWeighingsDialogVisible && (
        <DeleteWeighingDialog
          visible={deleteSelectedWeighingsDialogVisible}
          onClose={handleCloseDeleteSelectedWeighingsDialog}
          onDelete={handleDeleteSelectedWeighings}
          weighingNumber={undefined}
        />
      )}
      {restoreWeighingDialogVisible && (
        <Dialog
          visible={restoreWeighingDialogVisible}
          className="dialog-md"
          header={t('confirmRestoreDialogTitle')}
          modal
          footer={restoreWeighingDialogFooter}
          onHide={handleCloseRestoreWeighingDialog}
        >
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
            {weighing && (
              <span>
                <Trans t={t} i18nKey="confirmRestoreDialogQuestion" values={{ weighingNumber: weighing.number }} />
              </span>
            )}
          </div>
        </Dialog>
      )}
      {reportDialogVisible && (
        <ReportDialog
          visible={reportDialogVisible}
          onClose={handleHideReportDialog}
          filters={getReportDialogFilters()}
          reportFormat={reportFormat!}
          isWeighingSeriesEnabled={!!isWeighingSeriesEnabled}
          paginationOrder={paginationParameters?.order}
        />
      )}
    </>
  );
};
