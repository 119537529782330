import { useSelector } from 'react-redux';
import { ReducerState } from '../../types/reducer-state';
import weighingsService from '../../services/ScaleoApiServices/WeighingsService';

export const useWeighingsListToolbar = (fileUploader: any) => {
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const allowAddingMeasurements = () =>
    Number.isInteger(loggedUserContext.limits?.maximumMeasurementsNumber) &&
    Number.isInteger(loggedUserContext.limitsState?.currentMonthMeasurementsNumber)
      ? loggedUserContext.limits!.maximumMeasurementsNumber! >=
        loggedUserContext.limitsState!.currentMonthMeasurementsNumber + 2
      : true;

  const canGenerateReports = () => !(loggedUserContext.limits?.allowGenerateReports === false);

  const importData = (event: any) => {
    let csvData = '';
    const [f] = event.files;
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      csvData = Buffer.from(e.target.result).toString('base64');
      await weighingsService.importData(csvData);
      fileUploader.current?.clear();
    };
    reader.readAsText(f);
  };

  return {
    allowAddingMeasurements,
    canGenerateReports,
    importData,
  };
};
