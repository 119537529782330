import { Button } from 'primereact/button';
import { Chips } from 'primereact/chips';
import { Dialog } from 'primereact/dialog';
import { Fieldset } from 'primereact/fieldset';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import weighingConfigurationService from '../../../../../services/ScaleoApiServices/WeighingConfigurationService';
import weighingService from '../../../../../services/ScaleoApiServices/WeighingsService';
import { getFileFromBuffer, printFileFromBuffer } from '../../../../../utils/getFile';
import { getTranslatedWeighingReportLabel } from '../../../components/weighingLabels';
import ReportColumns from './components/ReportColumns';
import { useSelector } from 'react-redux';
import { ReducerState } from '../../../../../types/reducer-state';

interface ReportDialogProps {
  visible: boolean;
  onClose: () => void;
  filters: any;
  reportFormat: string;
  isWeighingSeriesEnabled: boolean;
  paginationOrder: any;
}

const ReportDialog = ({
  visible,
  onClose,
  filters,
  reportFormat,
  isWeighingSeriesEnabled,
  paginationOrder,
}: ReportDialogProps) => {
  const { t } = useTranslation('weighingWeighingList');
  const { t: t1 } = useTranslation('weighingComponents');
  const { weighingConfiguration } = useSelector((state: ReducerState) => state.weighing);

  const loadAdditionalFields = () => {
    void weighingConfigurationService.getAllAdditionalFields().then((response) => {
      setInitAdditionalColumns(response.data);
    });
  };

  useEffect(() => {
    loadAdditionalFields();
  }, []);

  const initialCheckedColumns = [
    'contractor',
    'vehicle',
    weighingConfiguration.addTrailerRegistrationNumbersSupport ? 'trailer' : null,
    'indDates',
    'transactionType',
    'number',
    'products',
    'indication1',
    'indication2',
    'netto',
    isWeighingSeriesEnabled ? 'sum' : null,
    'isConfirmed',
  ];

  const [emails, setEmails] = useState<string[] | undefined>([]);
  const [initAdditionalColumns, setInitAdditionalColumns] = useState([]);
  const [checkedStandardColumns, setCheckedStandardColumns] = useState(initialCheckedColumns);
  const [checkedAdditionalColumns, setCheckedAdditionalColumns] = useState<string[]>([]);
  const toast = useRef<any>(null);

  const [isHorizontal, setIsHorizontal] = useState(false);

  const translations = {
    'text/csv': 'Excel',
    'application/pdf': 'Pdf',
  };

  const generateReports = (options: any) => {
    void weighingService
      .getWeighingReport(
        options.format,
        filters,
        checkedStandardColumns,
        checkedAdditionalColumns,
        isHorizontal,
        paginationOrder,
      )
      .then((report) => {
        !options.download && getFileFromBuffer(report.data, options.format);
        options.download && getFileFromBuffer(report.data, options.format, 'raport' + options.extension);
      });
  };

  const getReportPreview = () => {
    generateReports({ download: false, format: reportFormat });
    onClose();
  };
  const downloadReport = () => {
    if (reportFormat === 'text/csv') generateReports({ download: true, format: '.xlsx', extension: '.xlsx' });
    else generateReports({ download: true, format: 'application/pdf', extension: '.pdf' });
    onClose();
  };
  const sendReport = () => {
    if (!emails?.length) {
      return toast.current.show({
        severity: 'error',
        summary: t('reportDialogSendCaution'),
        detail: t('reportDialogSendDetail'),
        life: 3000,
      });
    }

    if (emails?.length > 0)
      void weighingService
        .sendWeighingReport(
          reportFormat,
          filters,
          checkedStandardColumns,
          checkedAdditionalColumns,
          emails,
          isHorizontal,
          paginationOrder,
        )
        .then(() => {
          onClose();
        });
  };

  const reportUsedFilters = (_filters: any) => {
    let additionalFields: ({ name: string; value: string } | null)[] = [];

    if (_filters.additional_field) {
      additionalFields = initAdditionalColumns
        .map((column: any) => {
          const value = _filters.additional_field[column.id];
          if (value) {
            return { name: column.name, value: value };
          }
          return null;
        })
        .filter((item: any) => item !== null);
    }

    const usedFilters = Object.keys(_filters).map(function (key) {
      if (key === 'label') return null;
      let filter = _filters[key];
      if (key === 'locations.name') filter = _filters.label;

      if (filter && filters[key].length) {
        if (key === 'date')
          return (
            <div key={getTranslatedWeighingReportLabel(key, t)} className="grid m-2">
              <div className="col-5 p-0">{getTranslatedWeighingReportLabel(key, t1)}</div>
              <div className="col-7 p-0">
                {(filter[0]
                  ? ' od: ' + new Date(filter[0]).toLocaleString('pl-PL', { dateStyle: 'short', timeStyle: 'short' })
                  : '') +
                  (filter[1]
                    ? ' do: ' + new Date(filter[1]).toLocaleString('pl-PL', { dateStyle: 'short', timeStyle: 'short' })
                    : '')}
              </div>
            </div>
          );
        else
          return (
            <>
              <div key={getTranslatedWeighingReportLabel(key, t)} className="grid m-2">
                <div className="col-5 p-0">{getTranslatedWeighingReportLabel(key, t1)} :</div>
                <div className="col-7 p-0">
                  {filter instanceof Object
                    ? filter.map((key2: any) => {
                        if (key2['name'] != null) return key2['name'] + ' ';
                        else return '';
                      })
                    : filter}{' '}
                </div>
              </div>
            </>
          );
      } else return null;
    });
    const check = (element: any) => element !== null;

    return (
      <>
        {usedFilters.some(check) && (
          <>
            <b>{t('reportDialogFiltersCriteria')}</b> <br />
            {usedFilters}
            {additionalFields.map((field: any, index: number) => (
              <div key={index} className="grid m-2">
                <div className="col-5 p-0">{field?.name}:</div>
                <div className="col-7 p-0">{field?.value}</div>
              </div>
            ))}
            <br />
            <br />
          </>
        )}
      </>
    );
  };

  const onStandardColumnChange = (e: any) => {
    const selectedColumns = [...checkedStandardColumns];

    if (e.checked) selectedColumns.push(e.value);
    else selectedColumns.splice(selectedColumns.indexOf(e.value), 1);

    setCheckedStandardColumns(selectedColumns);
  };

  const onAdditionalColumnChange = (e: any) => {
    const selectedColumns = [...checkedAdditionalColumns];

    if (e.checked) selectedColumns.push(e.value);
    else selectedColumns.splice(selectedColumns.indexOf(e.value), 1);

    setCheckedAdditionalColumns(selectedColumns);
  };

  const printWeighingReceipt = async () => {
    //temporary fix for Firefox
    if (navigator.userAgent.indexOf('Firefox') === -1) {
      void weighingService
        .getWeighingReport(
          'application/pdf',
          filters,
          checkedStandardColumns,
          checkedAdditionalColumns,
          isHorizontal,
          paginationOrder,
        )
        .then((report) => {
          printFileFromBuffer(report.data, 'application/pdf');
        });
    }
  };

  const renderPrintButton = () => {
    //temporary fix for Firefox
    if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return (
        <Button
          type="button"
          label={t('reportDialogFooterPreviewPrintButton')}
          text
          icon="pi pi-print"
          onClick={getReportPreview}
        />
      );
    } else {
      return (
        <>
          <Button
            type="reset"
            label={t('reportDialogFooterPrintButton')}
            icon="pi pi-print"
            text
            onClick={printWeighingReceipt}
          />
          <Button
            type="reset"
            label={t('reportDialogFooterPreviewButton')}
            icon="pi pi-eye"
            text
            onClick={getReportPreview}
          />
        </>
      );
    }
  };

  const reportDialogFooter = (
    <>
      {reportFormat === 'application/pdf' &&
        checkedStandardColumns.length + checkedAdditionalColumns.length > 10 &&
        !isHorizontal && (
          <p className="column-warning float-left mt-5px mb-0 mr-0 ml-0" style={{ color: '#d10a00' }}>
            <i className="pi pi-fw pi-info-circle"></i> {t('reportDialogFooterSelectionInfo')}
          </p>
        )}
      <Button type="button" label={t('reportDialogFooterCancelButton')} text icon="pi pi-times" onClick={onClose} />
      <Button
        type="button"
        label={t('reportDialogFooterDownloadButton')}
        text
        icon="pi pi-download"
        onClick={downloadReport}
      />
      {reportFormat === 'application/pdf' && renderPrintButton()}
      <Button type="button" label={t('reportDialogFooterSendButton')} text icon="pi pi-send" onClick={sendReport} />
    </>
  );

  const handleBlurChips = (e: any) => {
    if (e.target.value) {
      setEmails([...(emails ?? []), e.target.value]);
      e.target.value = '';
    }
  };

  return (
    <Dialog
      visible={visible}
      className="dialog-lg"
      header={t('reportDialogTitle')}
      modal
      footer={reportDialogFooter}
      onHide={onClose}
    >
      <div className="grid">
        <div className="col-6">
          <Fieldset legend={t('reportDialogMainInfo')}>
            <div className="max-width-400px">
              <div className="break-inside-avoid">
                <b>{t('reportDialogMainInfoFile')}</b>
                <div className="m-2">
                  {t('reportDialogMainInfoFormat')}: {translations[reportFormat as keyof typeof translations]}
                </div>
                <br />
                {visible && reportUsedFilters(filters)}
              </div>
              <div></div>
            </div>
            <b>{t('reportDialogMainInfoEmails')}</b>
            <br />
            <br />
            <div className="flex align-items-center flex-wrap">
              <Chips
                value={emails}
                onChange={(e) => setEmails(e.value || [])}
                className="mb-2"
                onBlur={handleBlurChips}
              />
            </div>
            {reportFormat === 'application/pdf' && (
              <div className="field grid mt-5 mb-0 ">
                <label htmlFor="name3" className="col-5">
                  <b>{t('reportDialogMainInfoOrientationHorizontal')}</b>
                </label>
                <div className="col-2">
                  <InputSwitch id="isHorizontal" checked={isHorizontal} onChange={(e) => setIsHorizontal(e.value)} />
                </div>
              </div>
            )}
          </Fieldset>
        </div>
        <Toast ref={toast} />
        <div className="col-6">
          {checkedStandardColumns && initAdditionalColumns && (
            <ReportColumns
              onStandardColumnChange={onStandardColumnChange}
              onAdditionalColumnChange={onAdditionalColumnChange}
              initAdditionalColumns={initAdditionalColumns}
              checkedStandardColumns={checkedStandardColumns}
              checkedAdditionalColumns={checkedAdditionalColumns}
              isWeighingSeriesEnabled={isWeighingSeriesEnabled}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};
export default ReportDialog;
