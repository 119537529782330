import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';

import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { Toast } from 'primereact/toast';
import billingDataService from '../../services/ScaleoApiServices/BillingDataService';
import { userActions } from '../../store/user-slice';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../types/reducer-state';
import { Message } from 'primereact/message';

const BillingData = () => {
  const { t } = useTranslation('billingData');

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const toast = useRef<any>();
  const dispatch = useDispatch();

  const [initFormValues] = useState({
    taxId: loggedUserContext.currentCustomer?.taxId,
    companyName: loggedUserContext.currentCustomer?.companyName,
  });

  const validateMethod = (data: any) => {
    const errors = {} as Record<string, string>;

    if (!data.taxId) errors.taxId = t('taxIdError');

    if (!data.companyName) errors.companyName = t('companyNameError');

    if (data.companyName && !data.companyName.match(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ. 0-9-"'„]+$/)) {
      errors.companyName =
        'Nazwa może składać się z dużych i małych liter, cyfr, spacji i znaków "-" lub ".", cudzysłowu lub apostrofu';
    }

    return errors;
  };

  const handleSubmit = async (data: any) => {
    billingDataService.editBillingData(data, loggedUserContext.currentCustomer?.id).then(() => {
      toast.current.show({
        severity: 'success',
        summary: t('updateSuccess'),
        life: 4000,
      });
      dispatch(userActions.shouldRefreshLoggedUserContext(true));
    });
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="card pb-70px">
      <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
        <form onSubmit={formik.handleSubmit}>
          <Fieldset legend={t('header')}>
            <div className="p-fluid formgrid grid">
              <div className="col-12 md:col-6 lg:col-4">
                {(!formik.values.companyName || !formik.values.taxId) && (
                  <>
                    <Message className="h-4vh w-20vw mb-2vh" text={'Musisz dodać nazwię firmy i NIP'} />
                  </>
                )}
                <div className="field">
                  <label htmlFor="companyName">{t('companyName')}</label>
                  <InputText
                    id="companyName"
                    value={formik.values.companyName as string}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage fieldName="companyName" formikInstance={formik} />
                </div>
                <div className="field">
                  <label htmlFor="taxId">{t('taxId')}</label>
                  <InputText id="taxId" value={formik.values.taxId as string} onChange={formik.handleChange} />
                  <FormErrorMessage fieldName="taxId" formikInstance={formik} />
                </div>
              </div>
            </div>
            <div className="action-buttons">
              <div className="flex flex-column md:flex-row justify-content-end p-ai-end">
                <Button type="submit" label={t('saveButton')} icon="pi pi-check" text />
              </div>
            </div>
          </Fieldset>
        </form>
      </FormErrorMessageScroller>
      <Toast ref={toast as any} />
    </div>
  );
};

export default BillingData;
