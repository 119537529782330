import { useState } from 'react';
import { Dictionary } from '../../../../../../types/dictionary';
import { EdgeDevice, AutomationProcessConfig } from '../../../../../../types/edge-device';
import edgeDevicesService from '../../../../../../services/ScaleoApiServices/EdgeDevicesService';
import { useEffect } from 'react';
import DynamicSettingsDialog from '../../../../../Management/DynamicSettings/DynamicSettingsDialog';
import { DynamicSettingsControl } from '../../../../../../types/controls';

type EdgeDeviceControlProps = DynamicSettingsControl & {
  dictionaries: Dictionary[] | null;
  edgeDevices: EdgeDevice[] | null;
};

const DynamicSettingsControlElement = ({
  edgeDevices,
  dictionaries,
  node,
  label,
  edgeDeviceId,
}: EdgeDeviceControlProps) => {
  const [isEdgeDeviceVisible, setIsEdgeDeviceVisible] = useState(false);
  const [automationProcessConfig, setAutomationProcessConfig] = useState<AutomationProcessConfig | null>();

  const hideAutomationConfigDialog = () => {
    setIsEdgeDeviceVisible(false);
  };

  useEffect(() => {
    edgeDeviceId &&
      edgeDevicesService.getAutomationConfig(edgeDeviceId).then((automationProcessConfigResponse) => {
        setAutomationProcessConfig(automationProcessConfigResponse.data);
      });
  }, [edgeDeviceId]);

  const edgeDevice = edgeDevices?.find((e) => e.id === edgeDeviceId);

  return (
    <>
      {isEdgeDeviceVisible && (
        <DynamicSettingsDialog
          edgeDevice={edgeDevice}
          dynamicSettings={automationProcessConfig!.dynamicSettings}
          onCancel={hideAutomationConfigDialog}
          visible={isEdgeDeviceVisible}
          onConfigSaved={hideAutomationConfigDialog}
          dictionaries={dictionaries}
        />
      )}
      <button onClick={() => setIsEdgeDeviceVisible(true)} key={node} className={`weighing-sidebar-node`}>
        <i className={`weighing-sidebar-node-icon pi pi-cog`} />
        <span className="weighing-sidebar-node-label">
          <b>{label}</b>
        </span>
        <span className="weighing-sidebar-node-button w-8vw"></span>
      </button>
    </>
  );
};

export default DynamicSettingsControlElement;
