import { createSlice } from '@reduxjs/toolkit';
import { ConfirmationsSlice } from './types/confirmations-slice';

const initialState: ConfirmationsSlice = {
  confirmationsToHandle: [],
};

const confirmationsSlice = createSlice({
  name: 'confirmations',
  initialState: { ...initialState },
  reducers: {
    updateConfirmations(_state: ConfirmationsSlice, value) {
      const receivedConfirmation = value.payload;
      if (
        !_state.confirmationsToHandle.find(
          (c) =>
            c.direction === receivedConfirmation.direction &&
            c.registrationNumber === receivedConfirmation.registrationNumber &&
            c.locationId === receivedConfirmation.locationId &&
            c.edgeDeviceId === receivedConfirmation.edgeDeviceId,
        )
      ) {
        _state.confirmationsToHandle = [..._state.confirmationsToHandle, receivedConfirmation];
      }
    },
    deleteConfirmation(_state: ConfirmationsSlice, value) {
      const confirmationToBeDeleted = value.payload;
      _state.confirmationsToHandle = _state.confirmationsToHandle.filter(
        (c) =>
          !(
            c.direction === confirmationToBeDeleted.direction &&
            c.registrationNumber === confirmationToBeDeleted.registrationNumber &&
            c.locationId === confirmationToBeDeleted.locationId &&
            c.edgeDeviceId === confirmationToBeDeleted.edgeDeviceId
          ),
      );
    },
    deleteAllConfirmations(_state: ConfirmationsSlice) {
      _state.confirmationsToHandle = [];
    },
  },
});

export const confirmationsActions = confirmationsSlice.actions;

export default confirmationsSlice;
