import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useRef, useState } from 'react';
import { Weighing } from '../../../../types/weighing';
import getPaginatorTemplate from '../../../../utils/getPaginatorTemplate';
import { isModulesEnabled } from '../../../../utils/modulesUtils';
import { hasPermissions } from '../../../../utils/permissionsUtils';
import DataTableFilterElements from '../../../_shared/DataTableFilterElements';
import {
  columnBodyTemplate,
  additionalFieldsBodyTemplate,
  weighingRowExpansionTemplate,
} from '../../components/Templates';
import { ReducerState } from '../../../../types/reducer-state';
import '../../../DataTable.css';
import { MultiSelect } from 'primereact/multiselect';
import { useSelector } from 'react-redux';
import { CustomAutoComplete } from '../../../_shared/CustomComponents';
import multiSelectPanelHeader from '../../../_shared/multiSelectPanelHeader';
import { getWeighingMainColumnsConfig, translateWeighingListColSource } from './WeighingListInit';
import { WeighingListData } from '../../../../hooks/WeighingsList/useWeighingsListData';
import { useWeighingsListFilters } from '../../../../hooks/WeighingsList/useWeighingsListFilters';
import useTransactionType from '../../../../utils/useTransactionType';
import { useTranslation } from 'react-i18next';
import { ActionBodyTemplateComponent } from './ActionBodyTemplateComponent';
import { Header } from './Header';

const DataTableAny = DataTable as any;
const ColumnAny = Column as any;
const DropdownAny = DataTableFilterElements.Dropdown as any;

interface WeighingsListTableProps {
  handleFetchComponentData: () => Promise<void>;
  componentData: WeighingListData;
  modulesConfiguration: any;
  showSendEmailDialog: (w: Weighing[]) => void;
  selectedWeighings: Weighing[] | null;
  setWeighing: (w: Weighing | null) => void;
  setWeighingDialogVisible: (b: boolean) => void;
  setWeighingHistoryVisible: (b: boolean) => void;
  setBdoDialogData: (a: any) => void;
  setDeleteWeighingDialogVisible: (b: boolean) => void;
  setRestoreWeighingDialogVisible: (b: boolean) => void;
  setIsOpenDialogKpo: (b: boolean) => void;
  setIsOpenDialogKpok: (b: boolean) => void;
  setDialogKpokMode: (s: string) => void;
  setClickedRowId: (s: string) => void;
  setIsOpenSnapshotsDialog: (b: boolean) => void;
  setPaginationParameters: (a: any) => void;
  paginationParameters: any;
  paginatorState: any;
  setPaginatorState: (a: any) => void;
  locations?: any[];
  isWeighingSeriesEnabled: boolean;
  handleSelectionChange: (a: any) => void;
  showDeletedWeighings: boolean;
  setShowDeletedWeighings: (b: boolean) => void;
  productsMultiselectSearchMethod: (a: any, b: any) => void;
  weighingConfiguration: any;
}

export const WeighingsListTable = ({
  handleFetchComponentData,
  componentData,
  modulesConfiguration,
  selectedWeighings,
  setWeighing,
  setWeighingDialogVisible,
  setWeighingHistoryVisible,
  setBdoDialogData,
  showSendEmailDialog,
  setDeleteWeighingDialogVisible,
  setRestoreWeighingDialogVisible,
  setIsOpenDialogKpo,
  setIsOpenDialogKpok,
  setDialogKpokMode,
  setClickedRowId,
  setIsOpenSnapshotsDialog,
  setPaginationParameters,
  paginationParameters,
  paginatorState,
  setPaginatorState,
  locations,
  isWeighingSeriesEnabled,
  handleSelectionChange,
  showDeletedWeighings,
  setShowDeletedWeighings,
  productsMultiselectSearchMethod,
  weighingConfiguration,
}: WeighingsListTableProps) => {
  const { t } = useTranslation('weighingWeighingList');

  const filters = useSelector((state: ReducerState) => state.user.filtersState.weighings);

  const dt = useRef(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [selectedDynamicsColumns, setSelectedDynamicsColumns] = useState<any[]>([]);
  const [weighningMainColumns, setWeighningMainColumns] = useState<any[]>([]);

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const { ALL_TRANSACTION_TYPES } = useTransactionType();

  const {
    clearFlag,
    setClearFlag,
    changeFilter,
    additionalFieldChangeFilter,
    autocompleteChangeFilter,
    multiSelectChangeFilter,
    clearFilters,
  } = useWeighingsListFilters();

  const handleSort = (e: any) => {
    let orderColumn = e.sortField;
    let orderByAdditionalField = false;
    if (orderColumn?.includes('additionalField.')) {
      orderColumn = orderColumn.replace('additionalField.', '');
      orderByAdditionalField = true;
    }
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: orderColumn,
        orderByAdditionalField: orderByAdditionalField as any,
      },
    });
  };

  const rowClass = (rowData: Weighing) => {
    return {
      rowDeleted: rowData.deleted === true,
      rowEdited: rowData.updatedAt !== null,
      rowManual: !rowData.measurements.some((m) => m.type === 'MEASURED'),
      rowKPO: rowData.cardId !== null,
      rowTest: rowData.isTest,
    };
  };

  const getFilterSource = (source: any) => {
    const sources = {
      transactionTypes: ALL_TRANSACTION_TYPES?.map((transactionType) => ({
        label: transactionType.name,
        value: transactionType.id,
      })),
      locations,
      inputProducts: componentData.inputProducts,
      inputVehicles: componentData.inputVehicles,
      inputContractors: componentData.inputContractors,
      inputDrivers: componentData.inputDrivers,
      inputScales: componentData.inputScales?.map((scale: any) => scale.name) ?? [],
      inputTrailers: componentData.inputTrailers,
      trueFalseStatuses: [
        { label: t('yes'), name: 'true' },
        { label: t('no'), name: 'false' },
      ],
    } as any;
    if (typeof source === 'string') return sources[source as keyof typeof source];
    else return source;
  };

  const actionBodyTemplate = (rowData: any, { rowIndex }: any) => (
    <ActionBodyTemplateComponent
      rowData={rowData}
      rowIndex={rowIndex}
      handleFetchComponentData={handleFetchComponentData}
      componentData={componentData}
      modulesConfiguration={modulesConfiguration}
      setWeighing={setWeighing}
      setWeighingDialogVisible={setWeighingDialogVisible}
      setWeighingHistoryVisible={setWeighingHistoryVisible}
      setBdoDialogData={setBdoDialogData}
      showSendEmailDialog={showSendEmailDialog}
      setDeleteWeighingDialogVisible={setDeleteWeighingDialogVisible}
      setRestoreWeighingDialogVisible={setRestoreWeighingDialogVisible}
      setIsOpenDialogKpo={setIsOpenDialogKpo}
      setIsOpenDialogKpok={setIsOpenDialogKpok}
      setDialogKpokMode={setDialogKpokMode}
      setClickedRowId={setClickedRowId}
      setIsOpenSnapshotsDialog={setIsOpenSnapshotsDialog}
      selectedWeighings={selectedWeighings}
    />
  );

  const dynamicColumns = [...selectedDynamicsColumns].map((column) =>
    renderSpecificColumn({
      t,
      column,
      componentData,
      getFilterSource,
      changeFilter,
      multiSelectChangeFilter,
      autocompleteChangeFilter,
      productsMultiselectSearchMethod,
      additionalFieldChangeFilter,
      filters,
    }),
  );

  useEffect(() => {
    const dict = { 'modulesConfiguration.bdo': modulesConfiguration.bdo };

    let columnsWithPermission = getWeighingMainColumnsConfig(t)
      .filter((col) => {
        return !col.requiredModuleName || dict[col.requiredModuleName as keyof typeof dict] !== 'disabled';
      })
      .map((col) => ({ ...col, name: t(col.name) }));
    !isWeighingSeriesEnabled && (columnsWithPermission = columnsWithPermission.filter((c) => c.field !== 'sum'));

    if (
      hasPermissions(['WEIGHINGS_CONFIRMATION'], loggedUserContext.permissions) &&
      componentData.configuration?.weighingConfirmation
    ) {
      columnsWithPermission = columnsWithPermission.filter((c) => c.field !== 'isConfirmed');
    }

    if (!isModulesEnabled(['CONTAINERS'], loggedUserContext.currentCustomer?.subscriptionModules)) {
      columnsWithPermission = columnsWithPermission.filter((c) => c.field !== 'containsAnyContainers');
    }

    if (!weighingConfiguration?.addTrailerRegistrationNumbersSupport) {
      columnsWithPermission = columnsWithPermission.filter((c) => c.field !== 'trailer.registrationNumber');
    }

    setWeighningMainColumns(columnsWithPermission);
    const standardOrderedSelectedColumns = [...columnsWithPermission, ...componentData.allAdditionalFields].filter(
      (col) => loggedUserContext.weighingListColumns.some((sCol) => sCol.id === col.id),
    );
    standardOrderedSelectedColumns.length
      ? setSelectedDynamicsColumns(standardOrderedSelectedColumns)
      : setSelectedDynamicsColumns(columnsWithPermission);
  }, [isWeighingSeriesEnabled, componentData.configuration?.weighingConfirmation, modulesConfiguration.bdo]);

  return (
    <div className="datatable-responsive">
      <div className="card datatable-card">
        <DataTableAny
          responsiveLayout="scroll"
          ref={dt}
          value={componentData.weighings}
          header={
            <Header
              handleFetchComponentData={handleFetchComponentData}
              componentData={componentData}
              showDeletedWeighings={showDeletedWeighings}
              setShowDeletedWeighings={setShowDeletedWeighings}
              clearFilters={clearFilters}
              changeFilter={changeFilter}
              selectedDynamicsColumns={selectedDynamicsColumns}
              weighningMainColumns={weighningMainColumns}
              setClearFlag={setClearFlag}
              setSelectedDynamicsColumns={setSelectedDynamicsColumns}
            />
          }
          className="p-datatable-responsive weighing-list"
          dataKey="id"
          rowHover
          selection={selectedWeighings}
          selectionMode="checkbox"
          onSelectionChange={handleSelectionChange}
          emptyMessage={t('tableNoData')}
          removableSort
          sortOrder={paginationParameters.order.isAscending ? 1 : -1}
          sortField={paginationParameters.order.orderColumn}
          onSort={handleSort}
          paginator
          rows={paginatorState.rows}
          paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState)}
          rowExpansionTemplate={(weighing: Weighing) => weighingRowExpansionTemplate(weighing, t)}
          expandedRows={expandedRows}
          onRowToggle={(e: any) => setExpandedRows(e.data)}
          rowClassName={rowClass}
          stripedRows
          filterDisplay="row"
        >
          <Column expander className="w-3rem" />
          <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} />
          {dynamicColumns}
          <ColumnAny
            key={'closedAt'}
            field={'closedAt'}
            header={t('columnsClosedAt')}
            body={columnBodyTemplate}
            fieldsType={'datetime'}
            sortable={true}
            filter
            showFilterMenu={false}
            filterElement={
              <DataTableFilterElements.CustomDatePicker
                filters={filters}
                onChangeFilter={changeFilter}
                name={'date'}
                clearFiltersFlag={clearFlag}
                setClearFiltersFlag={setClearFlag}
              />
            }
          />
          <Column body={actionBodyTemplate} className="w-150px text-right" />
        </DataTableAny>
      </div>
    </div>
  );
};

const renderSpecificColumn = ({
  t,
  column,
  componentData,
  getFilterSource,
  changeFilter,
  multiSelectChangeFilter,
  autocompleteChangeFilter,
  productsMultiselectSearchMethod,
  additionalFieldChangeFilter,
  filters,
}: any) => {
  if (column.standardCol) {
    const filter = column.field !== 'sum';

    return (
      <ColumnAny
        key={column.field}
        field={column.field}
        header={t(column.name)}
        body={columnBodyTemplate}
        fieldsType={column.type as any}
        sortable={column.sortable}
        filter={filter}
        showFilterMenu={false}
        filterElement={
          !filter ? undefined : column.multiselect ? (
            <MultiSelect
              value={filters[column.filterField]}
              name={column.filterField}
              options={getFilterSource(translateWeighingListColSource(t, column.source))}
              onChange={multiSelectChangeFilter}
              optionLabel="name"
              placeholder={t('columnsFilterPlaceholder')}
              emptyFilterMessage={t('columnsFilterNoData')}
              panelHeaderTemplate={multiSelectPanelHeader}
              maxSelectedLabels={0}
              selectedItemsLabel={`${t('columnsFilterSelectedItems')}: {0}`}
              showClear
              filter
              resetFilterOnHide
              className="w-full"
              onFilter={({ filter }) =>
                column.type === 'product' && productsMultiselectSearchMethod(filter, filters[column.filterField])
              }
              tooltip={column.tooltip}
            />
          ) : column.dropdown ? (
            <DropdownAny
              initialValue={filters[column.filterField]}
              name={column.filterField}
              options={getFilterSource(translateWeighingListColSource(t, column.source))}
              optionValue={
                column.field === 'location.name' ||
                column.field === 'isConfirmed' ||
                column.field === 'containsAnyContainers'
                  ? 'label'
                  : 'name'
              }
              onChangeFilter={changeFilter}
              placeholder={t('columnsFilterPlaceholder')}
            />
          ) : column.autocomplete ? (
            <CustomAutoComplete
              id={column.filterField}
              lookupField={
                column.field === 'vehicle.registrationNumber' || column.field === 'trailer.registrationNumber'
                  ? 'registrationNumber'
                  : 'name'
              }
              value={filters[column.filterField]}
              handler={autocompleteChangeFilter}
              source={getFilterSource(translateWeighingListColSource(t, column.source))}
              placeholder={t('columnsFilterPlaceholder')}
            />
          ) : (
            <DataTableFilterElements.Text
              initialValue={filters[column.filterField]}
              onChangeFilter={changeFilter}
              name={column.filterField}
              placeholder={t('columnsFilterPlaceholder')}
            />
          )
        }
      />
    );
  } else {
    const dictionaryData: any =
      componentData.dictionaries.find((dictionary: any) => dictionary?.id === column?.dictionaryId) || [];

    return (
      <ColumnAny
        key={column.id}
        field={column.id}
        header={t(column.name)}
        unit={column.unit}
        type={column.type}
        body={additionalFieldsBodyTemplate}
        sortable={false}
        sortField={`additionalField.${column.id}`}
        showFilterMenu={false}
        filter
        filterElement={
          column.type === 'dictionary' ? (
            dictionaryData.dictionaryValuesMapped &&
            dictionaryData.dictionaryValuesMapped.length > 0 && (
              <CustomAutoComplete
                id={column.id}
                lookupField={'name'}
                value={filters.additional_field?.[column?.id]}
                handler={(e: any) =>
                  additionalFieldChangeFilter(
                    e.target?.value?.name ? e.target?.value?.name : e.target?.value,
                    e.target?.id,
                  )
                }
                source={dictionaryData.dictionaryValuesMapped}
                placeholder={t('columnsFilterPlaceholder')}
              />
            )
          ) : (
            <DataTableFilterElements.Text
              initialValue={filters.additional_field ? filters.additional_field[column.id] : null}
              onChangeFilter={additionalFieldChangeFilter}
              name={column.id}
              placeholder={t('columnsFilterPlaceholder')}
            />
          )
        }
      />
    );
  }
};
