import axios from 'axios';

class UsersService {
  async searchUsers(filters: any, paginatorParam: any) {
    return axios.post('/users/search', { filters, paginatorParam });
  }
  async createUser(postData: any) {
    return axios.post('/users', mapToRequestBody(postData));
  }
  async inviteUser(postData: any) {
    return axios.post('/user-invitations', mapToRequestBody(postData));
  }
  async createAccount(params: any) {
    return axios.post('/user-invitations/create-account', params);
  }
  async resendInvitation(params: any) {
    return axios.post('/user-invitations/resend', params);
  }
  async signUp(user: any) {
    return axios.post('/users/sign-up', user);
  }
  async confirmSignUp(params: any) {
    return axios.post('/users/confirm-sign-up', params);
  }
  async forgotPassword(params: any) {
    return axios.post('/users/forgot-password', params);
  }
  async confirmForgotPassword(params: any) {
    return axios.post('/users/confirm-forgot-password', params);
  }
  async editUser(postData: any, id: string) {
    return axios.put(`/users/${id}`, mapToRequestBody(postData));
  }
  async editUserProfile(user: any, id: string) {
    return axios.put(`/users/${id}/profile`, {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      password: user.newPassword ? user.password : null,
      previousPassword: user.previousPassword ? user.previousPassword : null,
      accessToken: user.accessToken,
    });
  }
  async removeUser(id: string) {
    return axios.delete(`/users/${id}`);
  }
  async getLoggedUserContext() {
    return axios.get(`/users/me/context`);
  }

  async getUserSessionByEmail(email: string, password: string, loggedDeviceInfo: any) {
    const userSession = await axios.post(`/users/get-session`, { email, password, loggedDeviceInfo });

    return userSession.data;
  }

  async dropSession(email: string) {
    return axios.delete(`/users/${email}/session`);
  }

  async getLoggedUserCustomers() {
    return axios.get(`/users/me/customers`);
  }

  async getById(userId: string) {
    return axios.get(`/users/${userId}`);
  }

  async getCurrentUserScales() {
    const scales = await axios.get('/users/me/scales');
    return scales.data.map(mapScaleFromResponseBody);
  }

  async updateUserColumnList(columnList: any[]) {
    return axios.put('/users/me/settings', { columnList: columnList.map(mapWeighingColumList) });
  }

  async setCurrentLocationId(locationId: string, userId: string) {
    return axios.put(`/users/${userId}/current-location`, { locationId: locationId });
  }

  async setCurrentScaleAndLocation(locationId: string, userId: string, scaleId: string) {
    return axios.put(`/users/${userId}/current-scale-and-location`, { locationId: locationId, scaleId: scaleId });
  }

  async setAppLanguage(locationId: string, userId: string, appLanguage: string) {
    return axios.put(`/users/${userId}/app-language`, { locationId, appLanguage });
  }

  async setCurrentCustomerId(customerId: string, userId: string) {
    return axios.put(`/users/${userId}/current-customer`, { customerId: customerId });
  }
}

const mapWeighingColumList = (column: any) => {
  return {
    id: column.id,
    name: column.name,
  };
};

const mapScaleFromResponseBody = (scale: any) => {
  return {
    id: scale.id,
    name: scale.name,
    testScale: scale.testScale,
    location: scale.location,
    station: scale.station,
    nodeModelId: scale.nodeModelId,
    host: scale.host,
    port: scale.port.toString(),
    baudrate: scale.baudrate?.toString(),
    weighingUnit: scale.weighingUnit,
    division: scale.division?.toString(),
    maxWeight: scale.maxWeight?.toString(),
    isUsed: scale.isUsed,
    isAutoWeighing: scale.isAutoWeighing,
    edgeDevice: scale.edgeDevice,
    playSoundSpecifiedWeight: scale.playSoundSpecifiedWeight,
  };
};

const mapToRequestBody = (user: any) => {
  return {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email ? user.email : null,
    phone: user.phone ? user.phone : null,
    password: user.newPassword || !user.id ? user.password : null,
    roles: [{ id: user.role ? user.role.id : null }],
    locations: user.locationIds.map((locationId: string) => {
      return { id: locationId };
    }),
    nodes: user.nodeIds.map((nodeId: string) => {
      return { id: nodeId };
    }),
  };
};

const usersService = new UsersService();
export default usersService;
