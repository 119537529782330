import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface EditDialogProps {
  visible: any;
  closeDialog: () => void;
  formikInstance: any;
}

const EditDialog = ({ visible, closeDialog, formikInstance }: EditDialogProps) => {
  const { t } = useTranslation('managementSystemConfigurationWeighing');

  const getInitialState = ({ additionalFields, fieldsOrder }: any) => {
    const includeValues = (items: any) =>
      items.map((item: any) => {
        const value = fieldsOrder.find((el: any) => el.id === item.id)?.value || null;
        return { ...item, value };
      });

    const _constantFields = [
      { id: 'transactionType', name: t('transactionType'), value: null },
      { id: 'contractor', name: t('contractor'), value: null },
      { id: 'product', name: t('product'), value: null },
      { id: 'driver', name: t('driver'), value: null },
    ];
    const _additionalFields = additionalFields.map((f: any) => {
      return { id: f.id, name: f.name, value: null };
    });
    return includeValues([..._constantFields, ..._additionalFields]);
  };

  const [value, setValue] = useState(getInitialState(formikInstance.values));

  const handleClose = () => {
    closeDialog();
  };

  const handleSave = () => {
    formikInstance.setFieldValue('fieldsOrder', value, false);
    closeDialog();
  };

  const dialogFooter = (
    <>
      <Button type="button" onClick={handleClose} label={t('cancelButton')} />
      <Button type="button" onClick={handleSave} label={t('confirmButton')} />
    </>
  );

  const renderField = (field: any, index: number) => {
    const handleChange = (v: any) => {
      const newValue = [...value];
      newValue.find((el) => el.id === field.id).value = v;
      setValue(newValue);
    };

    return (
      <div className="p-fluid formgrid grid">
        <label htmlFor={`field-${index}`} className="col-3">
          {field.name}
        </label>
        <div className="field col-2">
          <InputText
            id={`field-${index}`}
            type="number"
            value={field.value}
            onChange={(e) => handleChange(e.target.value)}
          />
        </div>
      </div>
    );
  };

  return (
    <Dialog
      visible={visible}
      header={t('editDialogHeader')}
      modal
      className="p-fluid w-40vw"
      breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      footer={dialogFooter}
      onHide={handleClose}
    >
      <form>{value.map((v: any, i: number) => renderField(v, i))}</form>
    </Dialog>
  );
};

export default EditDialog;
