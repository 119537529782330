import IndicatorTypes from './IndicatorTypes/IndicatorTypes';
import Locations from './Locations/Locations';
import Nodes from './Nodes/Nodes';
import EdgeDevices from './EdgeDevices/EdgeDevices';
import Users from './Users/Users';
import Roles from './Roles/Roles';
import SystemConfiguration from './SystemConfiguration';
import ApiKeys from './ApiKey/ApiKeys';
import WebHooks from './Webhooks/Webhooks';
import ImportExport from './ImportExport/ImportExport';
import Stations from './Stations/Stations';

const Management = {
  SystemConfiguration,
  Locations,
  IndicatorTypes,
  Nodes,
  EdgeDevices,
  Users,
  Roles,
  ApiKeys,
  ImportExport,
  WebHooks,
  Stations,
};

export default Management;
