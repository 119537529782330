import axios from 'axios';

class DictionariesService {
  async exportData() {
    return axios.get(`/dictionaries/csv`);
  }
  async importData(postData) {
    return axios.post(`/dictionaries/csv`, {
      data: postData,
    });
  }
  async getDictionariesSearch(filters, paginationParameters, globalFilter) {
    return axios.post('/dictionaries/search', { filters, paginationParameters, globalFilter });
  }
  async getDictionaries(requestConfig) {
    return axios.get(`/dictionaries`, { ...requestConfig, withCredentials: true });
  }

  async getMultipleDictionaries(dictionariesIds) {
    return axios.post(`/dictionaries/get-multiple`, { data: dictionariesIds });
  }

  async createDictionary(postData) {
    return axios.post(`/dictionaries`, {
      data: postData,
    });
  }
  async editDictionary(postData, id) {
    return axios.put(`/dictionaries/${id}`, {
      data: postData,
    });
  }
  async removeDictionary(id) {
    return axios.delete(`/dictionaries/${id}`);
  }
}

const dictionariesService = new DictionariesService();
export default dictionariesService;
