import React from 'react';
import { Dialog } from 'primereact/dialog';
import { CancelButton, SubmitButton } from '../Bdo/bdoUtils';
import { useTranslation } from 'react-i18next';

const LoginDialog = ({ loggedDeviceInfo, isDialogVisible, handleDialogVisible, handleLogoutUser }: any) => {
  const { t } = useTranslation('loginPage');

  const dialogFooter = (
    <div className="grid justify-content-center">
      <CancelButton label={t('close')} onClick={handleDialogVisible} />
      <SubmitButton label={t('logoutUser')} onClick={handleLogoutUser} />
    </div>
  );

  return (
    <Dialog
      visible={isDialogVisible}
      breakpoints={{ '896px': '90vw' }}
      header={
        <span className="flex align-items-center">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
          {t('confirm')}
        </span>
      }
      modal
      className="p-fluid min-width-450px"
      footer={dialogFooter}
      onHide={handleDialogVisible}
    >
      <div className="confirmation-content">
        <span>
          {t('userLoggedInfo')}
          <b>{loggedDeviceInfo}</b>.<br />
          {t('logoutUserQuestion')}
        </span>
      </div>
    </Dialog>
  );
};

export default LoginDialog;
