import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import BdoApiService from '../../../../../services/BdoApiService';
import statusUtils from '../../components/statusUtils';
import {
  DEFAULT_DIALOG_BREAKPOINTS,
  DEFAULT_DIALOG_STYLE,
  CancelButton,
  transformWasteCodeId,
  transformWasteGeneratingPlace,
  transformWasteProcessId,
  ChangeStatusDropdown,
  PrintButton,
  checkCommaSeparator,
} from '../../../bdoUtils';
import documentUtils from '../../components/documentUtils';
import { useTranslation } from 'react-i18next';

const EditReceiveConfirmationCardDialog = ({ visible, closeDialog, item }) => {
  const { t } = useTranslation('kpoCarrierEditReceiveConfirmationCardDialog');

  const [currentCardInfo, setCurrentCardInfo] = useState(null);
  const [currentSenderEupInfo, setCurrentSenderEupInfo] = useState(null);
  const [currentSenderCompanyInfo, setCurrentSenderCompanyInfo] = useState(null);
  const [currentCarrierCompanyInfo, setCurrentCarrierCompanyInfo] = useState(null);
  const [currentReceiverCompanyInfo, setCurrentReceiverCompanyInfo] = useState(null);
  const [currentReceiverEupInfo, setCurrentReceiverEupInfo] = useState(null);

  const CHANGE_STATUS_OPTIONS = [{ label: t('confirmTransport'), value: 'CONFIRM_TRANSPORT' }];

  const getInitialValues = useCallback(async () => {
    const response = await BdoApiService.KPO_getReceiveConfirmationCard(item.kpoId, 'Carrier');

    BdoApiService.getCompany(response.data.senderCompanyId).then((res) => {
      setCurrentSenderCompanyInfo(res.data);
    });
    BdoApiService.getEup(response.data.senderEupId).then((res) => {
      setCurrentSenderEupInfo(res.data);
    });
    BdoApiService.getCompany(response.data.carrierCompanyId).then((res) => {
      setCurrentCarrierCompanyInfo(res.data);
    });
    BdoApiService.getCompany(response.data.receiverCompanyId).then((res) => {
      setCurrentReceiverCompanyInfo(res.data);
    });
    BdoApiService.getEup(response.data.receiverEupId).then((res) => {
      setCurrentReceiverEupInfo(res.data);
    });

    const {
      wasteCodeId,
      wasteProcessId,
      wasteMass,
      vehicleRegNumber,
      certificateNumberAndBoxNumbers,
      plannedTransportTime,
      realTransportTime,
      additionalInfo,
      wasteCodeExtended,
      wasteCodeExtendedDescription,
      hazardousWasteReclassification,
      hazardousWasteReclassificationDescription,
      isWasteGenerating,
      wasteGeneratedTerytPk,
      wasteGeneratingAdditionalInfo,
      approvalUser,
      cardApprovalTime,
      year,
      cardNumber,
      cardStatus,
      isRevised,
      revisedAt,
      revisedBy,
      revisedWasteCodeId,
      revisedWasteMass,
      generatingConfirmationTime,
      generatingConfirmationUser,
      receiveConfirmationUser,
      receiveConfirmationTime,
      correctedWasteMass,
      remarks,
    } = response.data;

    const cardInfo = {
      vehicleRegNumber,
      wasteMass,
      plannedTransportTime: new Date(plannedTransportTime),
      plannedTransportTimeDate: new Date(plannedTransportTime),
      certificateNumberAndBoxNumbers,
      additionalInfo,
      wasteCodeExtended,
      hazardousWasteReclassification,
      isWasteGenerating,
      realTransportTime: new Date(realTransportTime),
      approvalUser,
      cardApprovalTime: new Date(cardApprovalTime),
      year,
      cardNumber,
      cardStatus,
      isRevised,
      revisedAt: new Date(revisedAt),
      revisedBy,
      revisedWasteCodeId,
      revisedWasteMass,
      generatingConfirmationTime: new Date(generatingConfirmationTime),
      generatingConfirmationUser,
      receiveConfirmationUser,
      receiveConfirmationTime: new Date(receiveConfirmationTime),
      correctedWasteMass,
      remarks,
    };
    if (wasteCodeExtended) {
      cardInfo.wasteCodeExtendedDescription = wasteCodeExtendedDescription;
    }
    if (hazardousWasteReclassification) {
      cardInfo.hazardousWasteReclassificationDescription = hazardousWasteReclassificationDescription;
    }
    if (isWasteGenerating) {
      const wasteGeneratedTerytPkResponse = await BdoApiService.getCommune(wasteGeneratedTerytPk);
      cardInfo.wasteGeneratedTerytPk = transformWasteGeneratingPlace(wasteGeneratedTerytPkResponse.data);

      cardInfo.wasteGeneratingAdditionalInfo = wasteGeneratingAdditionalInfo;
    }
    const wasteProcessIdResponse = await BdoApiService.getWasteProcesses();
    cardInfo.wasteProcessId = transformWasteProcessId(
      wasteProcessIdResponse.data.find((el) => el.wasteProcessId === wasteProcessId),
    );

    const wasteCodeIdResponse = await BdoApiService.getWasteCodeByWasteCodeId(wasteCodeId);
    cardInfo.wasteCodeId = transformWasteCodeId(wasteCodeIdResponse.data);

    const revisedWasteCodeIdResponse = await BdoApiService.getWasteCodeByWasteCodeId(revisedWasteCodeId);
    cardInfo.revisedWasteCodeId = transformWasteCodeId(revisedWasteCodeIdResponse.data);

    setCurrentCardInfo(cardInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    if (visible) {
      getInitialValues();
    }
  }, [getInitialValues, visible]);

  const handleCloseDialog = (changes, statusFilter) => {
    closeDialog(changes === true, statusFilter);
  };

  const handleChangeStatus = (e) => {
    const METHODS = {
      CONFIRM_TRANSPORT: () =>
        statusUtils.changeStatusMethods.carrier.TRANSPORT_CONFIRMATION({ kpoId: item.kpoId }).then(() => {
          handleCloseDialog(true, 'TRANSPORT_CONFIRMATION');
        }),
    };

    METHODS[e.value] && METHODS[e.value]();
  };

  const dialogFooter = (
    <div className="grid justify-content-center">
      <CancelButton onClick={handleCloseDialog} />
      <ChangeStatusDropdown options={CHANGE_STATUS_OPTIONS} onChange={handleChangeStatus} />
      <PrintButton onClick={() => documentUtils.KPO_downloadDocument(item.kpoId)} label={t('printButton')} />
    </div>
  );

  return (
    <>
      <Dialog
        visible={visible}
        header={t('confirmationDialogHeader')}
        modal
        className="p-fluid"
        breakpoints={DEFAULT_DIALOG_BREAKPOINTS}
        style={DEFAULT_DIALOG_STYLE}
        footer={dialogFooter}
        onHide={handleCloseDialog}
      >
        <Panel header=" " className="pt-1vh">
          <div className="p-fluid formgrid grid">
            <div className="field col-12 md:col-3">
              <label>{t('calendar')}</label>
              <p>
                <b>{currentCardInfo?.year || '-'}</b>
              </p>
            </div>
            <div className="field col-12 md:col-5">
              <label> {t('cardNumber')}</label>
              <p>
                <b>{currentCardInfo?.cardNumber || '-'}</b>
              </p>
            </div>
            <div className="field col-12 md:col-4">
              <label>{t('cardStatus')}</label>
              <p>
                <b>{currentCardInfo?.cardStatus || '-'}</b>
              </p>
            </div>
          </div>
        </Panel>
        <Panel header={t('senderData')} toggleable className="pt-1vh">
          <div className="p-fluid formgrid grid pt-1vh">
            <div className="field col-12 md:col-4">
              <label>{t('senderRegistrationNumber')}</label>
              <p>
                <b>{currentSenderCompanyInfo?.registrationNumber || '-'}</b>
              </p>
            </div>
            <div className="field col-12 md:col-4">
              <label>{t('senderIdentificationNumber')}</label>
              <p>
                <b>{currentSenderCompanyInfo?.nip || '-'}</b>
              </p>
            </div>
            <div className="field col-12 md:col-4">
              <label>{t('identificationNumberEu')}</label>
              <p>
                <b>{currentSenderCompanyInfo?.nipEu || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('senderName')}</label>
              <p>
                <b>{currentSenderCompanyInfo?.name || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('senderAddress')}</label>
              <p>
                {currentSenderCompanyInfo?.address
                  ? currentSenderCompanyInfo?.address
                      .replaceAll(': ', ':<b> ')
                      .split(', ')
                      .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                  : '-'}
              </p>
            </div>
            <div className="field col-12 lg:col-4">
              <label>{t('senderPlaceNumber')}</label>
              <p>
                <b>{currentSenderEupInfo?.registrationNumber || '-'}</b>
              </p>
            </div>
            <div className="field col-12 lg:col-8">
              <label>{t('senderPlaceName')}</label>
              <p>
                <b>{currentSenderEupInfo?.name || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('senderPlaceAddress')}</label>
              <p>
                {currentSenderEupInfo?.address
                  ? currentSenderEupInfo?.address
                      .replaceAll(': ', ':<b> ')
                      .split(', ')
                      .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                  : '-'}
              </p>
            </div>
            <div className="field col-12">
              <label>{t('wasteInfo')}</label>
              <p>
                <b>{currentCardInfo?.isWasteGenerating ? t('yes') : t('no')}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('wasteGeneratedTeryt')}</label>
              <p>
                <b>{currentCardInfo?.wasteGeneratedTerytPk?.fulltext || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('senderAdditionalInfo')}</label>
              <p>
                <b>{currentCardInfo?.wasteGeneratingAdditionalInfo || '-'}</b>
              </p>
            </div>
          </div>
        </Panel>
        <Panel header={t('transporterData')} toggleable className="pt-1vh">
          <div className="p-fluid formgrid grid">
            <div className="field col-12 md:col-4">
              <label>{t('transporterRegistrationNumber')}</label>
              <p>
                <b>{currentCarrierCompanyInfo?.registrationNumber || '-'}</b>
              </p>
            </div>
            <div className="field col-12 md:col-4">
              <label>{t('transporterIdentificationNumber')}</label>
              <p>
                <b>{currentCarrierCompanyInfo?.nip || '-'}</b>
              </p>
            </div>
            <div className="field col-12 md:col-4">
              <label>{t('identificationNumberEu')}</label>
              <p>
                <b>{currentCarrierCompanyInfo?.nipEu || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('transporterName')}</label>
              <p>
                <b>{currentCarrierCompanyInfo?.name || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('transporterAddress')}</label>
              <p>
                {currentCarrierCompanyInfo?.address
                  ? currentCarrierCompanyInfo?.address
                      .replaceAll(': ', ':<b> ')
                      .split(', ')
                      .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                  : '-'}
              </p>
            </div>
          </div>
        </Panel>
        <Panel header={t('receiverData')} toggleable className="pt-1vh">
          <div className="p-fluid formgrid grid">
            <div className="field col-12 md:col-4">
              <label>{t('receiverRegistrationNumber')}</label>
              <p>
                <b>{currentReceiverCompanyInfo?.registrationNumber || '-'}</b>
              </p>
            </div>
            <div className="field col-12 md:col-4">
              <label>{t('receiverIdentificationNumber')}</label>
              <p>
                <b>{currentReceiverCompanyInfo?.nip || '-'}</b>
              </p>
            </div>
            <div className="field col-12 md:col-4">
              <label>{t('identificationNumberEu')}</label>
              <p>
                <b>{currentReceiverCompanyInfo?.nipEu || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('receiverName')}</label>
              <p>
                <b>{currentReceiverCompanyInfo?.name || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('receiverAddress')}</label>
              <p>
                {currentReceiverCompanyInfo?.address
                  ? currentReceiverCompanyInfo?.address
                      .replaceAll(': ', ':<b> ')
                      .split(', ')
                      .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                  : '-'}
              </p>
            </div>
            <div className="field col-12 lg:col-4">
              <label>{t('receiverPlaceNumber')}</label>
              <p>
                <b>{currentReceiverEupInfo?.registrationNumber || '-'}</b>
              </p>
            </div>
            <div className="field col-12 lg:col-8">
              <label>{t('receiverPlaceName')}</label>
              <p>
                <b>{currentReceiverEupInfo?.name || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('receiverPlaceAddress')}</label>
              <p>
                {currentReceiverEupInfo?.address
                  ? currentReceiverEupInfo?.address
                      .replaceAll(': ', ':<b> ')
                      .split(', ')
                      .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                  : '-'}
              </p>
            </div>
          </div>
        </Panel>
        <Panel header={t('wasteData')} toggleable className="pt-1vh">
          <div className="p-fluid formgrid grid">
            <div className="field col-12">
              <label>{t('wasteProcessType')}</label>
              <p>
                <b>{currentCardInfo?.wasteProcessId?.fulltext || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('wasteCodeAndType')}</label>
              <p>
                <b>{currentCardInfo?.wasteCodeId?.fulltext || '-'}</b>
              </p>
            </div>
            <div className="field col-12 md:col-6 lg:col-4">
              <label>{t('wasteCodeExtended')}</label>
              <p>
                <b>{currentCardInfo?.wasteCodeExtended ? t('yes') : t('no')}</b>
              </p>
            </div>
            <div className="field col-12 md:col-6 lg:col-8">
              <label>{t('wasteCodeExtendedType')}</label>
              <p>
                <b>{currentCardInfo?.wasteCodeExtendedDescription || '-'}</b>
              </p>
            </div>
            <div className="field col-12 md:col-6 lg:col-4">
              <label>{t('hazardousWasteReclassification')}</label>
              <p>
                <b>{currentCardInfo?.hazardousWasteReclassification ? t('yes') : t('no')}</b>
              </p>
            </div>
            <div className="field col-12 md:col-6 lg:col-8">
              <label>{t('hazardousWasteType')}</label>
              <p>
                <b>{currentCardInfo?.hazardousWasteReclassificationDescription || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('certificateNumberAndBoxNumber')}</label>
              <p>
                <b>{currentCardInfo?.certificateNumberAndBoxNumbers || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('wasteMass')}</label>
              <p>
                <b>{checkCommaSeparator(currentCardInfo?.wasteMass) || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('vehicleRegistrationNumber')}</label>
              <p>
                <b>{currentCardInfo?.vehicleRegNumber || '-'}</b>
              </p>
            </div>
            <div className="field col-12">
              <label>{t('plannedTransportDate')}</label>
              <p>
                <b>{currentCardInfo?.plannedTransportTimeDate?.toLocaleString() || '-'}</b>
              </p>
            </div>
          </div>
        </Panel>
        {currentCardInfo?.approvalUser ? (
          <Panel header={t('cardInfo')} toggleable className="pt-1vh">
            <div className="p-fluid formgrid grid">
              <div className="field col-12 md:col-6 lg:col-8">
                <label>{t('approvalUser')}</label>
                <p>
                  <b>{currentCardInfo?.approvalUser || '-'}</b>
                </p>
              </div>
              <div className="field col-12 md:col-6 lg:col-4">
                <label>{t('cardApprovalDate')}</label>
                <p>
                  <b>{currentCardInfo?.cardApprovalTime?.toLocaleString() || '-'}</b>
                </p>
              </div>
            </div>
          </Panel>
        ) : (
          ''
        )}
        {currentCardInfo?.generatingConfirmationUser ? (
          <Panel header={t('realTransportHour')} toggleable className="pt-1vh">
            <div className="p-fluid formgrid grid">
              <div className="field col-8">
                <label>{t('realTransportDate')}</label>
                <p>
                  <b>{currentCardInfo?.realTransportTime?.toLocaleString() || '-'}</b>
                </p>
              </div>
              <div className="field col-12 md:col-6 lg:col-8">
                <label>{t('generatingConfirmationUserName')}</label>
                <p>
                  <b>{currentCardInfo?.generatingConfirmationUser || '-'}</b>
                </p>
              </div>
              <div className="field col-12 md:col-6 lg:col-4">
                <label>{t('confirmationTime')}</label>
                <p>
                  <b>{currentCardInfo?.generatingConfirmationTime?.toLocaleString() || '-'}</b>
                </p>
              </div>
            </div>
          </Panel>
        ) : (
          ''
        )}
        {currentCardInfo?.receiveConfirmationUser ? (
          <Panel header={t('wasteConfirmationInfo')} toggleable className="pt-1vh">
            <div className="p-fluid formgrid grid">
              <div className="field col-12 md:col-6 lg:col-8">
                <label>{t('receiveConfirmationUser')}</label>
                <p>
                  <b>{currentCardInfo?.receiveConfirmationUser || '-'}</b>
                </p>
              </div>
              <div className="field col-12 md:col-6 lg:col-4">
                <label>{t('dateAndHour')}</label>
                <p>
                  <b>{currentCardInfo?.receiveConfirmationTime?.toLocaleString() || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('wasteMass')}</label>
                <p>
                  <b>{checkCommaSeparator(currentCardInfo?.correctedWasteMass) || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('remarks')}</label>
                <p>
                  <b>{currentCardInfo?.remarks || '-'}</b>
                </p>
              </div>
            </div>
          </Panel>
        ) : (
          ''
        )}
        {currentCardInfo?.isRevised ? (
          <Panel header={t('adjustmentInfo')} toggleable className="pt-1vh">
            <div className="p-fluid formgrid grid">
              <div className="field col-12">
                <label>{t('revisedWasteMass')}</label>
                <p>
                  <b>{checkCommaSeparator(currentCardInfo?.revisedWasteMass) || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('preRevisionWasteCode')}</label>
                <p>
                  <b>{currentCardInfo?.revisedWasteCodeId?.fulltext || '-'}</b>
                </p>
              </div>
              <div className="field col-12 md:col-6 lg:col-8">
                <label>{t('revisorName')}</label>
                <p>
                  <b>{currentCardInfo?.revisedBy || '-'}</b>
                </p>
              </div>
              <div className="field col-12 md:col-6 lg:col-4">
                <label>{t('dateAndHour')}</label>
                <p>
                  <b>{currentCardInfo?.revisedAt?.toLocaleString() || '-'}</b>
                </p>
              </div>
            </div>
          </Panel>
        ) : (
          ''
        )}
        <Panel header={t('additionalInfo')} toggleable className="pt-1vh">
          <div className="p-fluid formgrid grid">
            <div className="field col-12">
              <label>{t('additionalInfo')}</label>
              <p>
                <b>{currentCardInfo?.additionalInfo || '-'}</b>
              </p>
            </div>
          </div>
        </Panel>
        <br />
        <br />
        <br />
      </Dialog>
    </>
  );
};

export default EditReceiveConfirmationCardDialog;
