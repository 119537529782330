import axios from 'axios';

class AccessCodesService {
  async getAccessCodesSearch(filters: any, paginationParameters: any, globalFilter: any) {
    return axios.post('/access-codes/search', { filters, paginationParameters, globalFilter });
  }
  async getAllAccessCodes() {
    return axios.get('/access-codes');
  }
  async postAccessCode(data: any) {
    return axios.post(`/access-codes`, data);
  }
  async editAccessCode(data: any, id: string) {
    return axios.put(`/access-codes/${id}`, data);
  }
  async removeAccessCode(id: string) {
    return axios.delete(`/access-codes/${id}`);
  }
  async getQrCode(id: string) {
    return axios.get(`/access-codes/${id}/qr-code`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'iamge/png',
      },
    });
  }
}
const accessCodesService = new AccessCodesService();
export default accessCodesService;
