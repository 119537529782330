import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import '../.././DataTable.css';
import getPaginatorTemplate from '../../../utils/getPaginatorTemplate';
import { useTranslation } from 'react-i18next';
import { NewOrEditContainerDialog } from './components/NewOrEditContainerDialog';
import { DeleteContainerDialog } from './components/DeleteContainerDialog';
import { DeleteContainersDialog } from './components/DeleteContainersDialog';
import moment from 'moment';
import { Button } from 'primereact/button';
import { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import containersService from '../../../services/ScaleoApiServices/ContainersService';
import { userActions } from '../../../store/user-slice';
import { formatStringToDate } from '../../../utils/formatUtils';
import { CustomButton, CustomTableField } from '../../_shared/CustomComponents';
import DataTableFilterElements from '../../_shared/DataTableFilterElements';
import { Container } from '../../../types/container';
import { ReducerState } from '../../../types/reducer-state';

export const Containers = () => {
  const { t } = useTranslation('datasetsContainers');
  const [containers, setContainers] = useState<Container[] | null>(null);
  const [selectedContainers, setSelectedContainers] = useState<Container[]>([]);
  const [container, setContainer] = useState<Container>(emptyContainer);
  const [containerDialogVisible, setContainerDialogVisible] = useState(false);
  const [deleteContainerDialogVisible, setDeleteContainerDialogVisible] = useState(false);
  const [deleteContainersDialogVisible, setDeleteContainersDialogVisible] = useState(false);
  const dt = useRef(null);

  const filters = useSelector((state: ReducerState) => state.user.filtersState.containers);
  const [internalFilters, setInternalFilters] = useState(filters);
  const [paginationParameters, setPaginationParameters] = useState(emptyPaginationParameters);
  const [paginatorState, setPaginatorState] = useState(emptyPaginatorState);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const dispatch = useDispatch();

  const fetchContainers = useCallback(
    async (_filters, _paginationParameters) => {
      Promise.all([containersService.searchContainers(formatSearchFilters(_filters), _paginationParameters)]).then(
        (response) => {
          setContainers(response[0].data);

          response[0].totalPages
            ? setPaginatorState({
                ...paginatorState,
                totalPages: response[0].totalPages,
              })
            : setPaginatorState({
                ...paginatorState,
                totalPages: 1,
                currentPage: 1,
              });
        },
      );
    },
    [paginatorState],
  );

  const formatSearchFilters = (internalFilters: any) => {
    return {
      ...internalFilters,
      tareTimestamp: internalFilters.tareTimestamp?.map((stringDate: string) => formatStringToDate(stringDate)),
    };
  };

  useEffect(() => {
    fetchContainers(internalFilters, paginationParameters);
  }, [internalFilters, paginationParameters, loggedUserContext.currentCustomer?.id]);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const openNew = () => {
    setContainer(emptyContainer);
    setContainerDialogVisible(true);
  };

  const editContainer = async (_container: Container) => {
    setContainer({ ..._container });
    setContainerDialogVisible(true);
  };

  const hideDeleteContainerDialog = () => {
    setDeleteContainerDialogVisible(false);
  };

  const confirmDeleteSelected = () => {
    setDeleteContainersDialogVisible(true);
  };

  const hideDeleteContainersDialog = () => {
    setDeleteContainersDialogVisible(false);
  };

  const deleteContainer = () => {
    containersService.removeManyContainers([container]).then(() => {
      fetchContainers(internalFilters, paginationParameters);
    });
    setDeleteContainerDialogVisible(false);
    setContainer(emptyContainer);
  };

  const deleteSelectedContainers = () => {
    containersService.removeManyContainers(selectedContainers).then(() => {
      fetchContainers(internalFilters, paginationParameters);
    });

    setDeleteContainersDialogVisible(false);
    setSelectedContainers([]);
  };

  const changeFilter = (value: any, name: string) => {
    const newFilters = { ...internalFilters };
    name === 'tareTimestamp'
      ? value
        ? (newFilters[name] = [
            value[0].toString(),
            value[1] ? moment(value[1]).add(23, 'hours').add(59, 'minutes').toDate().toString() : null,
          ])
        : (newFilters[name] = null)
      : (newFilters[name] = value);

    dispatch(userActions.setContainersFilters(newFilters));
    setInternalFilters(newFilters);
  };

  const clearFilters = () => {
    dispatch(userActions.setContainersFilters({}));
    setInternalFilters({});
  };

  useEffect(() => {
    setPaginationParameters((prevPaginationParameters) => ({
      ...prevPaginationParameters,
      page: {
        ...prevPaginationParameters.page,
        index: 1,
      },
    }));
  }, [internalFilters]);

  useEffect(() => {
    if (paginatorState.currentPage > paginatorState.totalPages)
      setPaginatorState((prevPaginatorState) => ({
        ...prevPaginatorState,
        currentPage: 1,
      }));
  }, [paginatorState]);

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const header = (
    <div className="table-header text-2xl p-2">
      {t('header')} &#160;&#160;
      <div className="flex">
        <Button label={t('clearFilters')} icon="pi pi-filter-slash" outlined className="mr-2" onClick={clearFilters} />
        <span>
          <DataTableFilterElements.Text
            initialValue={internalFilters['global']}
            onChangeFilter={changeFilter}
            name={'global'}
            placeholder={t('searchPlaceholder')}
            isSearchIcon
          />
        </span>
      </div>
    </div>
  );

  const leftToolbarTemplate = () => {
    return (
      <>
        <Button label={t('addButton')} icon="pi pi-plus" severity="success" className="mr-2" onClick={openNew} />
        <CustomButton
          label={t('deleteButton')}
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedContainers || !selectedContainers.length}
          tooltip={!selectedContainers.length ? t('selectContainer') : t('cannotDelete')}
          name={undefined}
          dataFlag={undefined}
          type={undefined}
        />
      </>
    );
  };

  const actionBodyTemplate = (rowData: Container) => {
    return (
      <div className="actions">
        <Button icon="pi pi-pencil" raised severity="success" className="mr-2" onClick={() => editContainer(rowData)} />
        <Button
          icon="pi pi-trash"
          raised
          severity="secondary"
          tooltip={t('tooltip')}
          tooltipOptions={{ position: 'bottom' }}
          onClick={() => {
            setContainer(rowData);
            setDeleteContainerDialogVisible(true);
          }}
        />
      </div>
    );
  };

  const columnBodyTemplate = (rowData: Container, column: any) => {
    return <CustomTableField row={rowData} col={column} withoutHeaders={undefined} />;
  };

  const columns = [
    { field: 'name', header: t('name'), type: 'text' },
    { field: 'code', header: t('code'), type: 'text' },
    { field: 'tareValue', header: t('tareValue'), type: 'kg' },
  ];

  const dynamicColumns = columns.map((col) => {
    const AnyColumn = Column as any;

    return (
      <AnyColumn
        key={col.field}
        field={col.field}
        header={col.header}
        body={columnBodyTemplate}
        fieldsType={col.type as any}
        sortable
        showFilterMenu={false}
        filter={col.type === 'noFilter' ? false : true}
        filterElement={
          col.type === 'date' ? (
            <DataTableFilterElements.Date
              initialValue={internalFilters[col.field]?.map((f: any) => {
                return formatStringToDate(f);
              })}
              onChangeFilter={changeFilter}
              name={col.field}
              placeholder={t('filters')}
            />
          ) : (
            <DataTableFilterElements.Text
              initialValue={internalFilters[col.field]}
              onChangeFilter={changeFilter}
              name={col.field}
              placeholder={t('filters')}
            />
          )
        }
      />
    );
  });

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                responsiveLayout="scroll"
                ref={dt}
                value={containers ?? []}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                rowHover
                selection={selectedContainers}
                selectionMode="checkbox"
                onSelectionChange={(e) => setSelectedContainers(e.value)}
                emptyMessage={t('noData')}
                removableSort
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                paginator
                rows={paginatorState.rows}
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState) as any}
                stripedRows
                filterDisplay="row"
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                {dynamicColumns}
                <Column body={actionBodyTemplate} className="actionBody"></Column>
              </DataTable>
              <NewOrEditContainerDialog
                visible={containerDialogVisible}
                onContainerAdded={() => fetchContainers(internalFilters, paginationParameters)}
                setContainerDialogVisible={setContainerDialogVisible}
                container={container}
                displayTare={true}
              />
              <DeleteContainerDialog
                deleteContainerDialogVisible={deleteContainerDialogVisible}
                hideDeleteContainerDialog={hideDeleteContainerDialog}
                container={container}
                deleteContainer={deleteContainer}
              />
              <DeleteContainersDialog
                deleteContainersDialogVisible={deleteContainersDialogVisible}
                hideDeleteContainersDialog={hideDeleteContainersDialog}
                deleteSelectedContainers={deleteSelectedContainers}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const emptyContainer = {
  id: null,
  name: '',
  code: '',
  tareValue: null,
} as Container;

const emptyPaginationParameters = {
  order: {
    isAscending: true,
    orderColumn: 'id',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const emptyPaginatorState = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};
