import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import dictionariesService from '../../../../services/ScaleoApiServices/DictionariesService';
import '../../../DataTable.css';
import { useTranslation, Trans } from 'react-i18next';
import { Dictionary } from '../../../../types/dictionary';

interface DeleteDictionaryDialogProps {
  visible: boolean;
  closeDialog: () => void;
  selectedRow: Dictionary;
}

const DeleteDictionaryDialog = ({ visible, closeDialog, selectedRow }: DeleteDictionaryDialogProps) => {
  const { t } = useTranslation('managementDictionaries');

  const handleCloseDialog = () => {
    closeDialog();
  };

  const handleConfirm = async () => {
    await dictionariesService.removeDictionary(selectedRow.id);

    handleCloseDialog();
  };

  const dialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={handleCloseDialog} />
      <Button type="button" label={t('deleteButton')} icon="pi pi-check" text onClick={handleConfirm} />
    </>
  );

  return (
    <Dialog
      visible={visible}
      breakpoints={{ '896px': '90vw' }}
      header={
        <span className="flex align-items-center">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
          {t('confirm')}
        </span>
      }
      modal
      className="p-fluid min-width-450px"
      footer={dialogFooter}
      onHide={handleCloseDialog}
    >
      {selectedRow ? (
        <div className="confirmation-content">
          <span>
            <Trans t={t} i18nKey="contentDetail" values={{ dictionary: selectedRow.name }} />
          </span>
        </div>
      ) : (
        ''
      )}
    </Dialog>
  );
};

export default DeleteDictionaryDialog;
