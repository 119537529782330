import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { appConfigActions } from '../../store/appConfig-slice';
import { useTranslation } from 'react-i18next';

const ChangesConfirmationDialog = () => {
  const { t } = useTranslation('sharedComponents');
  const confirmationOfWindowChange = useSelector((state) => state.appConfig.confirmationOfWindowChange);
  const dispatch = useDispatch();

  useEffect(() => {
    if (confirmationOfWindowChange.trigger && confirmationOfWindowChange.approved) {
      const onFinishOperations = () => {
        confirmationOfWindowChange.trigger.click();
        dispatch(
          appConfigActions.setConfirmationOfWindowChange({
            ...confirmationOfWindowChange,
            trigger: null,
            save: false,
            saveMethod: null,
          }),
        );
      };

      if (!!confirmationOfWindowChange.save && typeof confirmationOfWindowChange.saveMethod === 'function') {
        confirmationOfWindowChange.saveMethod().then(() => onFinishOperations());
      } else {
        onFinishOperations();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationOfWindowChange.approved]);

  const onHideChangesDialog = (discardChanges, saveChanges) => {
    if (!discardChanges && !saveChanges) {
      dispatch(
        appConfigActions.setConfirmationOfWindowChange({ ...confirmationOfWindowChange, trigger: null, save: false }),
      ); // CANCEL
    }
    if (discardChanges && !saveChanges) {
      dispatch(
        appConfigActions.setConfirmationOfWindowChange({ ...confirmationOfWindowChange, approved: true, save: false }),
      ); // DISCARD CHANGES
    }
    if (!discardChanges && saveChanges) {
      dispatch(
        appConfigActions.setConfirmationOfWindowChange({ ...confirmationOfWindowChange, approved: true, save: true }),
      ); // SAVE CHANGES
    }
  };

  const changesDialogFooter = (
    <>
      <Button label={t('cancelButton')} icon="pi pi-times" text onClick={() => onHideChangesDialog(false, false)} />
      <Button label={t('discardButton')} icon="pi pi-trash" text onClick={() => onHideChangesDialog(true, false)} />
      <Button label={t('saveButton')} icon="pi pi-check" text onClick={() => onHideChangesDialog(false, true)} />
    </>
  );

  return (
    <Dialog
      visible={!!confirmationOfWindowChange.trigger}
      header={
        <span className="flex align-items-center">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
        </span>
      }
      modal
      className="p-fluid min-width-450px"
      footer={changesDialogFooter}
      onHide={onHideChangesDialog}
      breakpoints={{ '896px': '90vw' }}
    >
      <div className="confirmation-content">
        <span>{t('dataWasNotSaved')}</span>
      </div>
    </Dialog>
  );
};

export default ChangesConfirmationDialog;
