import axios from 'axios';

class InstallationService {
  async isAppVerified() {
    return axios.get('/installation/verified-status');
  }

  async verifyLicenseKey(key: string) {
    return axios.post('/installation/verify-license-key', {
      key,
    });
  }

  async updateLicenseKey(key: string) {
    return axios.post('/installation/update-license-key', {
      key,
    });
  }

  async getDeviceKey() {
    return axios.get('/installation/device-key');
  }
}

const installationService = new InstallationService();
export default installationService;
