import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import nodesService from '../../../services/ScaleoApiServices/NodesService';
import { userActions } from '../../../store/user-slice';
import { getTranslatedLabel, nodeTypeLabels } from '../../../utils/labels';
import '../../DataTable.css';
import DataTableFilterElements from '../../_shared/DataTableFilterElements';
import { NodeType, Node, UnpreparedNode } from '../../../types/node';
import { ReducerState } from '../../../types/reducer-state';
import useManagementNodesData from '../../../hooks/ManagementNodes/useManagementNodesData';
import useManagementNodesForm from '../../../hooks/ManagementNodes/useManagementNodesForm';
import usePagination from '../../../hooks/_shared/usePagination';
import { generateNodeTypeSource } from './helpers';
import NodesDialog from './NodesDialog';

const Nodes = () => {
  const { t } = useTranslation('managementNodes');
  const { t: t1 } = useTranslation('utils');

  const dispatch = useDispatch();
  const dt = useRef(null);

  const { currentLocationId, currentCustomer, scales } = useSelector((state: ReducerState) => state.user.context);
  const filters = useSelector((state: ReducerState) => state.user.filtersState.nodes);

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const { data, fetchData, nodeData, changeNodeData, initialNodeValues } = useManagementNodesData(filters);

  const { paginationParameters, setPaginationParameters } = usePagination(fetchData, '', true);

  useEffect(() => {
    setPaginationParameters;
  }, [paginationParameters]);

  const handleOnSubmit = () => {
    fetchData(paginationParameters);
    setIsDialogOpen(false);
    changeNodeData();
    dispatch(userActions.shouldRefreshLoggedUserContext(true));
  };

  const { formik, resetForm } = useManagementNodesForm(data, handleOnSubmit, initialNodeValues, t);

  const [deleteNodeDialogVisible, setDeleteNodeDialogVisible] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    fetchData(paginationParameters);
  }, [fetchData, currentLocationId, currentCustomer?.id, paginationParameters]);

  const openNew = () => {
    changeNodeData();

    resetForm();
    setIsDialogOpen(true);
  };

  const mapNode = (unpreparedNode: UnpreparedNode) => {
    const mappedNodeData = {
      id: unpreparedNode.id,
      type: unpreparedNode?.testScale ? NodeType.WEIGHT_INDICATOR : unpreparedNode.type,
      name: unpreparedNode.name,
      host: unpreparedNode.host,
      port: unpreparedNode.port,
      location: unpreparedNode?.location,
      station: unpreparedNode?.station,
      nodeModel:
        data &&
        data?.nodeModels.find((nodeModel: any) => {
          return nodeModel?.id === unpreparedNode?.nodeModelId;
        }),
      edgeDevice: unpreparedNode?.edgeDevice,
      weighingUnit: { name: unpreparedNode?.weighingUnit },
      division: { name: unpreparedNode?.division },
      maxWeight: unpreparedNode?.maxWeight,
      openPin: unpreparedNode?.openPin,
      closePin: unpreparedNode?.closePin,
      statusPin: unpreparedNode?.statusPin,
      addImageData: unpreparedNode?.addImageData,
      sourceScale: scales?.filter((scale: any) => scale.id === unpreparedNode?.sourceScaleId)?.[0],
      playSoundSpecifiedWeight: unpreparedNode?.playSoundSpecifiedWeight,
      selectedCameras: unpreparedNode?.selectedCameras,
      connectionType: unpreparedNode?.connectionType,
      baudrate: unpreparedNode?.baudrate,
      testScale: unpreparedNode?.testScale,
    };

    resetForm(mappedNodeData);
    changeNodeData({ ...unpreparedNode });
    setIsDialogOpen(true);
  };

  const confirmDeleteNode = (_node: Node) => {
    changeNodeData(_node);
    setDeleteNodeDialogVisible(true);
  };

  const hideDialogHandler = () => setIsDialogOpen(false);

  const hideDeleteNodeDialog = () => setDeleteNodeDialogVisible(false);

  const deleteNode = () => {
    nodesService.deleteNode(nodeData.id!).then(() => {
      fetchData(paginationParameters);

      setDeleteNodeDialogVisible(false);

      changeNodeData();
      dispatch(userActions.shouldRefreshLoggedUserContext(true));
    });
  };

  const deleteNodeDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteNodeDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteNode} />
    </>
  );

  const clearFilters = () => {
    dispatch(userActions.setNodesFilters({}));
  };

  const changeFilter = (value: any, name: string) => {
    const newFilters = { ...filters };
    newFilters[name] = value;
    dispatch(userActions.setNodesFilters(newFilters));
  };

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const header = (
    <div className="table-header text-2xl p-2">
      {t('header')} &#160;&#160;
      <div className="flex">
        <Button label={t('clearFilters')} icon="pi pi-filter-slash" outlined onClick={clearFilters} />
      </div>
    </div>
  );

  const leftToolbarTemplate = () => (
    <Button label={t('addButton')} icon="pi pi-plus" severity="success" className="mr-2" onClick={openNew} />
  );

  const rightToolbarTemplate = () => <></>;

  const nameBodyTemplate = (rowData: Node) => (
    <>
      <span className="p-column-title">{t('name')}</span>
      {rowData.name}
    </>
  );

  const typeBodyTemplate = (rowData: Node) => (
    <>
      <span className="p-column-title">{t('type')}</span>
      {getTranslatedLabel(nodeTypeLabels, rowData.type, t1)}
    </>
  );

  const stationBodyTemplate = (rowData: Node) => (
    <>
      <span className="p-column-title">{t('station')}</span>
      {rowData.station?.name}
    </>
  );

  const actionBodyTemplate = (rowData: Node) => {
    const handleClickEditNodeRow = () => {
      mapNode(rowData as UnpreparedNode);
      setIsDialogOpen(true);
    };

    return (
      <div className="actions">
        <Button icon="pi pi-pencil" raised severity="success" className="mr-2" onClick={handleClickEditNodeRow} />
        {!rowData.isUsed && (
          <Button icon="pi pi-trash" raised severity="warning" onClick={() => confirmDeleteNode(rowData)} />
        )}
        {rowData.isUsed && (
          <Button
            icon="pi pi-trash"
            raised
            severity="secondary"
            tooltip={t('cannotDelete')}
            tooltipOptions={{ position: 'bottom' }}
          />
        )}
      </div>
    );
  };

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                responsiveLayout="scroll"
                ref={dt}
                value={data.nodes}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                rowHover
                emptyMessage={t('noData')}
                removableSort
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                stripedRows
                filterDisplay="row"
              >
                <Column field="id" header={t('id')}></Column>
                <Column
                  field="name"
                  header={t('name')}
                  sortable
                  body={nameBodyTemplate}
                  filter
                  showFilterMenu={false}
                  filterPlaceholder={t('searchByName')}
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters['name']}
                      onChangeFilter={changeFilter}
                      name="name"
                      placeholder={t('filter')}
                    />
                  }
                ></Column>
                <Column
                  field="type"
                  header={t('type')}
                  sortable
                  body={typeBodyTemplate}
                  filter
                  showFilterMenu={false}
                  filterPlaceholder={t('searchByType')}
                  filterElement={
                    <DataTableFilterElements.Dropdown
                      initialValue={filters['type']}
                      options={generateNodeTypeSource(loggedUserContext, t1)}
                      onChangeFilter={changeFilter}
                      name="type"
                      placeholder={t('filter')}
                    />
                  }
                ></Column>
                <Column
                  field="stationId"
                  header={t('station')}
                  sortable
                  body={stationBodyTemplate}
                  filter
                  showFilterMenu={false}
                  filterElement={
                    <DataTableFilterElements.Dropdown
                      initialValue={filters['stationId']}
                      options={data.stations?.map((s) => ({ label: s.name, value: s.id })) || []}
                      onChangeFilter={changeFilter}
                      name="stationId"
                      placeholder={t('filter')}
                    />
                  }
                  filterPlaceholder={t('searchByStation')}
                />
                <Column body={actionBodyTemplate} style={{ width: '100px', textAlign: 'center' }}></Column>
              </DataTable>

              {isDialogOpen && <NodesDialog data={data} hideDialogHandler={hideDialogHandler} formik={formik} />}

              <Dialog
                visible={deleteNodeDialogVisible}
                header={
                  <span className="flex align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
                    {t('confirm')}
                  </span>
                }
                modal
                footer={deleteNodeDialogFooter}
                onHide={hideDeleteNodeDialog}
                className="p-fluid min-width-450px"
                breakpoints={{ '896px': '90vw' }}
              >
                <div className="confirmation-content">
                  {nodeData && (
                    <span>
                      <Trans t={t} i18nKey="content" values={{ node: nodeData.name }} />
                    </span>
                  )}
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nodes;
