import axios from 'axios';
import { getLoggedDeviceInfo } from '../utils/getLoggedDeviceInfo';

export const authenticateUser = (enteredEmail: string, enteredPassword: string, callback: Function) => {
  return axios
    .post(
      '/auth/login',
      {
        username: enteredEmail,
        password: enteredPassword,
        sessionLoggedDeviceInfo: getLoggedDeviceInfo(),
      },
      { withCredentials: false },
    )
    .then((res) => {
      callback(null, res.data.access_token, res.data.access_token, res.data.id, res.data.email);
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        callback({ code: 'NotAuthorizedException' }, null);
      } else {
        callback(error, null);
      }
    });
};

export const signOut = () => {
  //TODO: signOut
};
