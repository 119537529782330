import axios from 'axios';

class IndicatorTypesService {
  async getNodeModels() {
    return axios.get(`/node-models`);
  }
  async getIndicatorTypes() {
    return axios.get(`/node-models/weight-indicator`);
  }
  async getNodeModelDetails(id) {
    return axios.get(`/node-models/${id}`);
  }
}
const indicatorTypesService = new IndicatorTypesService();
export default indicatorTypesService;
