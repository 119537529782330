import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './resources/languages-config';

const init = () =>
  i18n.use(initReactI18next).init({
    resources: resources,
    lng: 'pl-PL',
    fallbackLng: 'pl-PL',
    interpolation: { escapeValue: false },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b'],
      transWrapTextNodes: '',
    },
  });

export default init;
