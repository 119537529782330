import React from 'react';
import { useSelector } from 'react-redux';
import { getTranslatedLabel, weighingModeLabels } from '../../../../../../utils/labels';
import useTransactionType from '../../../../../../utils/useTransactionType';
import MeasurementsList from '../../../../components/MeasurementsList';
import {
  WeighingSummaryTextFieldTemplate,
  WeighingSummaryDecimalFieldTemplate,
} from '../../../../components/WeighingSummaryFieldTemplates';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../../../types/reducer-state';
import DoubleWeighingSeriesProductsSummary from '../../../../components/DoubleWeighingSeriesProductsSummary';
import { Weighing } from '../../../../../../types/weighing';
import CustomDivider from '../../../../../_shared/CustomDivider';

interface DoubleWeighingSeriesSummaryDataProps {
  weighing: Weighing;
}

const DoubleWeighingSeriesSummaryData = ({ weighing }: DoubleWeighingSeriesSummaryDataProps) => {
  const { t } = useTranslation('weighingHandlingWeighings');
  const { t: t1 } = useTranslation('utils');

  const unit = useSelector((state: ReducerState) => state.appConfig.unit);
  const { getTransactionTypeLabel } = useTransactionType();

  const renderAdditionalField = (field: any) =>
    ['DICTIONARY', 'TEXT', 'AUTO_ID'].includes(field.type) ? (
      <WeighingSummaryTextFieldTemplate label={field.name} value={field.value} />
    ) : (
      <WeighingSummaryDecimalFieldTemplate label={field.name} value={field.value} unit={field.unit} />
    );

  const compareMeasurements = (a: any, b: any) => {
    if (a.scale.name === b.scale.name) return a.timestamp > b.timestamp ? 1 : -1;
    return a.scale.name > b.scale.name ? 1 : -1;
  };

  const calculateDoubleWeighingSeriesSum = () =>
    weighing.measurements
      .sort(compareMeasurements)
      .reduce((acc: number, firstMeasurement: any, index: number, sortedMeasurements: any[]) => {
        if (index % 2 !== 0) {
          return acc;
        }
        const secondMeasurement = sortedMeasurements[index + 1];
        if (!secondMeasurement) {
          return acc + firstMeasurement.indication;
        }
        return acc + Math.abs(firstMeasurement.indication - secondMeasurement.indication);
      }, 0);

  const sum = calculateDoubleWeighingSeriesSum();

  return (
    <>
      <div className="lg:col-6 md:col-12">
        <div className="formgrid grid">
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleWeighingNumber')}
            value={weighing?.number as string}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleWeighingMode')}
            value={getTranslatedLabel(weighingModeLabels, weighing?.weighingMode, t1)}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleWeighingType')}
            value={getTransactionTypeLabel(weighing?.transactionType)}
          />
          <WeighingSummaryDecimalFieldTemplate label={t('reportColumnsSum')} value={sum} unit={unit} />
        </div>
      </div>
      <div className="lg:col-6 md:col-12">
        <div className="formgrid grid">
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleRegistrationNumber')}
            value={weighing?.vehicle.registrationNumber}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleProduct')}
            value={weighing?.product?.name}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleDriver')}
            value={weighing?.driver?.name}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleContractor')}
            value={weighing?.contractor?.name}
          />
          {weighing?.additionalFields?.map(renderAdditionalField)}
        </div>
      </div>
      <div className="lg:col-12 md:col-12">
        <CustomDivider>
          <span className="tag">{t('openWeighingsListSummaryMultipleMeasurementsList')}</span>
        </CustomDivider>
        <MeasurementsList measurements={weighing.measurements} showAdditionalFields columnEmptyValue={undefined} />
        <CustomDivider>
          <span className="tag">{t('openWeighingsListSummaryMultipleProductsSummary')}</span>
        </CustomDivider>
        <DoubleWeighingSeriesProductsSummary measurements={weighing.measurements} />
      </div>
    </>
  );
};

export default DoubleWeighingSeriesSummaryData;
