import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import EditDialog from './components/EditDialog';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReducerState } from '../../../../../../types/reducer-state';
import { Tooltip } from 'primereact/tooltip';

interface AdditionalFieldsSectionProps {
  formikInstance: any;
}

const AdditionalFieldsSection = ({ formikInstance }: AdditionalFieldsSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationWeighing');
  const [editDialogData, setEditDialogData] = useState({ isOpen: false, fieldIndex: null });
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const handleClickOpenEditDialog = (fieldIndex: any) => {
    setEditDialogData({ isOpen: true, fieldIndex });
  };

  const handleClickCloseEditDialog = (changes: any, fieldData: any) => {
    if (fieldData && !fieldData?.id) fieldData.id = crypto.randomUUID();

    if (changes) {
      const newValue = formikInstance.values.additionalFields;
      if (editDialogData.fieldIndex !== null && editDialogData.fieldIndex > -1) {
        newValue[editDialogData.fieldIndex] = { ...newValue[editDialogData.fieldIndex], ...fieldData };
      } else {
        newValue.push(fieldData);
      }
      formikInstance.setFieldValue('additionalFields', newValue, false);
    }
    setEditDialogData({ isOpen: false, fieldIndex: null });
  };

  const handleClickNewAdditionalField = () => {
    handleClickOpenEditDialog(null);
  };

  const handleRemoveAdditionalField = (i: number) => {
    const newValue = [...formikInstance.values.additionalFields];
    const removedFieldId = newValue[i]?.id;

    if (removedFieldId) {
      newValue[i]['deleted'] = true;

      if (formikInstance.values.numberAdditionalField?.id === removedFieldId) {
        formikInstance.setFieldValue('numberAdditionalField', null);

        const updatedFields =
          formikInstance.values.numberGeneratedByFields?.filter((field: string) => field !== 'additionalField') || [];
        formikInstance.setFieldValue('numberGeneratedByFields', updatedFields);
      }
    } else {
      newValue.splice(i, 1);
    }

    formikInstance.setFieldValue('additionalFields', newValue, false);
  };

  const allowAddingAdditionalFields = () =>
    loggedUserContext.limits?.additionalFieldsNumber !== null &&
    loggedUserContext.limits?.additionalFieldsNumber !== undefined &&
    formikInstance.values.additionalFields
      ? Number(loggedUserContext.limits!.additionalFieldsNumber) > formikInstance.values.additionalFields.length
      : true;

  const renderHeaders = (index: number) => {
    if (index === 0 && formikInstance.values.additionalFields.findIndex((el: any) => el.deleted !== true) > -1) {
      return (
        <div className="p-fluid formgrid grid pb-10px">
          <div className="col-2">
            <label htmlFor="name">
              <b>{t('headersName')}</b>
            </label>
          </div>
          <div className="col-1 text-center">
            <label htmlFor="actions">
              <b>{t('headersActions')}</b>
            </label>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const renderRows = (field: any, index: number) => {
    if (!field.deleted) {
      return (
        <div
          className="p-fluid formgrid grid align-items-center pt-2px pb-2px pr-0px pl-0px"
          style={{ backgroundColor: index % 2 === 0 ? 'whitesmoke' : '' }}
        >
          <div className="col-2">
            <span>{field.name}</span>
          </div>
          <div className="col-1 flex flex-nowrap text-center">
            <Button
              icon="pi pi-pencil"
              type="button"
              text
              className="p-button-sm "
              onClick={() => handleClickOpenEditDialog(index)}
              tooltip={t('rowsEdit')}
            />
            <Button
              icon="pi pi-trash"
              type="button"
              severity="danger"
              className="p-button-sm ml-2"
              onClick={() => handleRemoveAdditionalField(index)}
              tooltip={t('rowsEdit')}
              disabled={field.isUsed}
            />
          </div>
        </div>
      );
    }
    return <></>;
  };

  return (
    <>
      <Fieldset legend={t('additionalFieldsHeader')} className="mt-1vh">
        {formikInstance.values.additionalFields?.map((field: any, i: number) => (
          <div key={`additionalField--${i}`}>
            {renderHeaders(i)}
            {renderRows(field, i)}
          </div>
        ))}
        <div className="reportTooltip">
          <Button
            type="button"
            label={t('addButton')}
            icon="pi pi-plus"
            severity="success"
            className="mt-4"
            onClick={handleClickNewAdditionalField}
            disabled={!allowAddingAdditionalFields()}
          />
          {!allowAddingAdditionalFields() && (
            <Tooltip target=".reportTooltip" position="bottom">
              {t('blockAdding')}
            </Tooltip>
          )}
        </div>
      </Fieldset>
      {!!editDialogData.isOpen && (
        <EditDialog
          visible={editDialogData.isOpen}
          closeDialog={handleClickCloseEditDialog}
          field={
            formikInstance.values.additionalFields[editDialogData.fieldIndex as keyof typeof editDialogData.fieldIndex]
          }
          allAdditionalFields={formikInstance.values.additionalFields}
        />
      )}
    </>
  );
};

export default AdditionalFieldsSection;
