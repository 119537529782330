import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import FormErrorMessage from '../../../../_shared/FormErrorMessage';
import { Chips } from 'primereact/chips';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

interface CyclicMailReportSectionProps {
  formikInstance: any;
  handleChangeField: Function;
}

const REPORT_FORMATS = [
  { label: 'PDF', option: 'PDF' },
  { label: 'Excel', option: 'EXCEL' },
];
const MILISECONDS_IN_MINUTE = 60 * 1000;

const CyclicMailReportSection = ({ formikInstance, handleChangeField }: CyclicMailReportSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationReports');

  return (
    <Fieldset legend={t('dailyReport')} className="mt-1vh">
      <div className="grid pt-1vh">
        <div className="col-12 lg:col-5">
          <span className="field-checkbox md:col-12">
            <Checkbox
              id="enableCyclicMailReport"
              checked={formikInstance.values.enableCyclicMailReport}
              onChange={(e) => handleChangeField(e, 'checkbox')}
            ></Checkbox>
            <label htmlFor="enableCyclicMailReport" className="checkbox-label">
              {t('dailyReportEnable')}
            </label>
          </span>
          {formikInstance.values.enableCyclicMailReport && (
            <>
              <div className="col-2 lg:col-7 p-fluid mt:col-2">
                {t('dailyReportTime')}
                <Calendar
                  className="w-6vw mt-0.5vw"
                  showTime
                  timeOnly
                  hourFormat="24"
                  value={
                    formikInstance.values.cyclicMailSendingReportMinutesAfterMidnight &&
                    new Date(formikInstance.values.cyclicMailSendingReportMinutesAfterMidnight * MILISECONDS_IN_MINUTE)
                  }
                  onChange={(e: any) =>
                    formikInstance.setFieldValue(
                      'cyclicMailSendingReportMinutesAfterMidnight',
                      e?.value?.getUTCHours() * 60 + e?.value?.getUTCMinutes(),
                    )
                  }
                />
                <FormErrorMessage
                  fieldName="cyclicMailSendingReportMinutesAfterMidnight"
                  formikInstance={formikInstance}
                />
              </div>
              <div className="col-2 lg:col-7 p-fluid mt:col-2">
                <label htmlFor="transactionTypeDefault">{t('dailyReportFormat')}</label>
                <Dropdown
                  className="w-6vw mt-0.5vw"
                  id="cyclicMailSendingReportFormat"
                  options={REPORT_FORMATS}
                  value={REPORT_FORMATS.find((f) => f.option === formikInstance.values.cyclicMailSendingReportFormat)}
                  onChange={(e) => formikInstance.setFieldValue('cyclicMailSendingReportFormat', e.value.option)}
                  placeholder={t('transactionTypeDefaultPlaceholder')}
                />
              </div>
              <div className="flex align-items-center flex-wrap mt-2 ml-1vh">
                <p className="mt-3 mr-3">{t('dailyReportMails')}</p>
                <br />
                <Chips
                  id="cyclicMailSendingReportMails"
                  value={formikInstance.values.cyclicMailSendingReportMails}
                  onChange={(e) => handleChangeField(e, 'chips')}
                  className="pt-2"
                />
                <FormErrorMessage fieldName="cyclicMailSendingReportMails" formikInstance={formikInstance} />
              </div>
            </>
          )}
        </div>
      </div>
    </Fieldset>
  );
};

export default CyclicMailReportSection;
