import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { useTranslation } from 'react-i18next';
import { EdgeDevice } from '../../../types/edge-device';

interface ActivationDialogProps {
  edgeDevice: Partial<EdgeDevice>;
  activationOtp: any;
  onClose: () => void;
  visible: boolean;
}

export const ActivationDialog = ({ edgeDevice, activationOtp, onClose, visible }: ActivationDialogProps) => {
  const { t } = useTranslation('managementEdgeDevices');

  const activationCodeDialogFooter = <Button label={t('closeButton')} icon="pi pi-check" text onClick={onClose} />;

  return (
    <Dialog
      visible={visible}
      header={<span className="flex align-items-center">{t('activationHeader')}</span>}
      footer={activationCodeDialogFooter}
      onHide={onClose}
      modal
      className="p-fluid min-width-450px"
      breakpoints={{ '896px': '90vw' }}
    >
      <h6>{t('codeDevice')}</h6>
      <div className="confirmation-content">
        <mark className="text-2xl">{edgeDevice.code}</mark>
      </div>
      <h6>{t('codeActivation')}</h6>
      <div className="confirmation-content">
        <mark className="text-4xl">{activationOtp}</mark>
        <Message className="mt-4" severity="info" text={t('codeActivationInfo')} />
      </div>
    </Dialog>
  );
};
