import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { InputTextarea } from 'primereact/inputtextarea';

interface ReceiptContentSectionProps {
  formikInstance: any;
  handleChangeField: Function;
}

const ReceiptsCustomFooterSection = ({ formikInstance, handleChangeField }: ReceiptContentSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationReceipts');

  return (
    <Fieldset legend={t('content')} className="mt-1vh">
      <div className="grid pt-1vh">
        <div className="col-12 lg:col-5">
          <span className="field-checkbox md:col-12">
            <Checkbox
              id="shouldIncludePosReceiptCustomFooter"
              checked={formikInstance.values.shouldIncludePosReceiptCustomFooter}
              onChange={(e) => handleChangeField(e, 'checkbox')}
            />
            <label htmlFor="shouldIncludePosReceiptCustomFooter" className="checkbox-label">
              {t('includeCustomText')}
            </label>
          </span>

          {formikInstance.values.shouldIncludePosReceiptCustomFooter && (
            <span className="field md:col-12">
              <div className="mt-2 ml-3">
                <span className="p-float-label">
                  <InputTextarea
                    id="posReceiptCustomFooter"
                    value={formikInstance.values.posReceiptCustomFooter}
                    onChange={(e: any) => handleChangeField(e, 'textarea')}
                    rows={5}
                    maxLength={1000}
                    className="textarea-custom-size"
                  />
                  <label htmlFor="posReceiptCustomFooter">{t('customText')}</label>
                </span>
              </div>
            </span>
          )}
        </div>
      </div>
    </Fieldset>
  );
};

export default ReceiptsCustomFooterSection;
