import axios from 'axios';

class LogosService {
  async saveLogo(file) {
    return axios.post(`/logos`, {
      file: Array.from(file),
    });
  }
  async getLogoUrl() {
    return axios.get(`/logos/url`);
  }
  async deleteLogo() {
    return axios.delete(`/logos`);
  }
}

const logosService = new LogosService();
export default logosService;
