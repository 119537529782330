import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';

interface ReportDisableSectionProps {
  formikInstance: any;
  handleChangeField: Function;
}

const ReportHideSection = ({ formikInstance, handleChangeField }: ReportDisableSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationReports');

  return (
    <Fieldset legend={t('hideData')} className="mt-1vh">
      <div className="grid pt-1vh">
        <div className="col-12 lg:col-5">
          <span className="field-checkbox md:col-12">
            <Checkbox
              id="hideCreationDate"
              checked={formikInstance.values.hideCreationDate}
              onChange={(e) => handleChangeField(e, 'checkbox')}
            ></Checkbox>
            <label htmlFor="hideCreationDate" className="checkbox-label">
              {t('hideCreationDate')}
            </label>
          </span>
        </div>
      </div>
    </Fieldset>
  );
};

export default ReportHideSection;
