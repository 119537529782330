import BdoConfiguration from './Bdo/BdoConfiguration';
import WeighingConfiguration from './WeighingConfiguration/WeighingConfiguration';
import PrintsConfiguration from './PrintsConfiguration/PrintsConfiguration';
import EmailsConfiguration from './EmailsConfiguration/EmailsConfiguration';
import ReportsConfiguration from './ReportsConfiguration/ReportsConfiguration';
import ConfirmationsConfiguration from './ConfirmationsConfiguration/ConfirmationsConfiguration';
import PosReceiptsConfiguration from './PosReceiptsConfiguration/PosReceiptsConfiguration';

const SystemConfiguration = {
  BdoConfiguration,
  WeighingConfiguration,
  PrintsConfiguration,
  EmailsConfiguration,
  ConfirmationsConfiguration,
  ReportsConfiguration,
  PosReceiptsConfiguration,
};

export default SystemConfiguration;
