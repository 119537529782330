import { Checkbox } from 'primereact/checkbox';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import { useTranslation } from 'react-i18next';

interface WebhookAuthenticationProps {
  formik: any;
}

export const WebhookAuthentication = ({ formik }: WebhookAuthenticationProps) => {
  const { t } = useTranslation('managementWebhooks');

  return (
    <>
      <span className="field-checkbox md:col-12">
        <Checkbox
          id="showWebhooks"
          checked={formik.values.destinationPassword !== null}
          onChange={() => {
            formik.setFieldValue('destinationId', formik.values.destinationPassword === null ? '' : null);
            formik.setFieldValue('destinationPassword', formik.values.destinationPassword === null ? '' : null);
          }}
        />
        <label htmlFor="showWebhooks" className="checkbox-label">
          {t('changeAuthCredentials')}
        </label>
      </span>
      {formik.values.destinationPassword !== null && (
        <>
          <Fieldset legend={t('basicAuth')} className="w-90p">
            <div className="grid p-fluid pt-1vh">
              <div className="col-12">
                <label htmlFor="destinationId" className="col-12 md:col-1">
                  {t('destinationId')}
                </label>
                <div className="col-12 inline-block">
                  <InputText
                    id="destinationId"
                    type="password"
                    value={formik.values.destinationId}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage fieldName="destinationId" formikInstance={formik} />
                </div>
                <label htmlFor="destinationPassword" className="col-12 md:col-1">
                  {t('destinationPassword')}
                </label>
                <div className="col-12 inline-block">
                  <InputText
                    id="destinationPassword"
                    type="password"
                    value={formik.values.destinationPassword}
                    onChange={formik.handleChange}
                  />
                  <FormErrorMessage fieldName="destinationPassword" formikInstance={formik} />
                </div>
              </div>
            </div>
          </Fieldset>
        </>
      )}
    </>
  );
};
