import axios from 'axios';

class DriversService {
  async searchDrivers(filters, paginatorParam) {
    const drivers = await axios.post('/drivers/search', { filters, paginatorParam });
    return { data: drivers.data.data.map(mapFromResponse), totalPages: drivers.data.totalPages };
  }
  async exportData() {
    return axios.get(`/drivers/csv`);
  }
  async importData(postData) {
    return axios.post(`/drivers/csv`, {
      data: postData,
    });
  }
  async getDrivers() {
    return axios.get(`/drivers`);
  }
  async createDrivers(postData) {
    return axios.post(`/drivers`, {
      data: mapToRequestBody(postData),
    });
  }
  async editDriver(postData, id) {
    return axios.put(`/drivers/${id}`, {
      data: mapToRequestBody(postData),
    });
  }
  async removeDriver(id) {
    return axios.delete(`/drivers/${id}`);
  }
}

const mapToRequestBody = (driver) => {
  return {
    id: driver.id,
    firstName: driver.firstName,
    lastName: driver.lastName,
    email: driver.email ? driver.email : null,
    phone: driver.phone ? driver.phone : null,
    personalId: driver.personalId ? driver.personalId : null,
    idCard: driver.idCard ? driver.idCard : null,
    street: driver.street ? driver.street : null,
    zip: driver.zip ? driver.zip : null,
    city: driver.city ? driver.city : null,
    taxId: driver.taxId ? driver.taxId : null,
  };
};

const mapFromResponse = (driver) => {
  return {
    ...driver,
    firstName: driver.firstName.length > 50 ? driver.firstName.substring(0, 50) + '...' : driver.firstName,
    lastName: driver.lastName.length > 50 ? driver.lastName.substring(0, 50) + '...' : driver.lastName,
  };
};

const driversService = new DriversService();
export default driversService;
