import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTranslatedLabel, weighingModeLabels } from '../../../../../../utils/labels';
import useTransactionType from '../../../../../../utils/useTransactionType';
import MeasurementsList from '../../../../components/MeasurementsList';
import ProductsSummary from '../../../../components/ProductsSummary';
import {
  WeighingSummaryTextFieldTemplate,
  WeighingSummaryDecimalFieldTemplate,
} from '../../../../components/WeighingSummaryFieldTemplates';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../../../types/reducer-state';
import { Weighing } from '../../../../../../types/weighing';
import filesService from '../../../../../../services/ScaleoApiServices/FilesService';
import CustomDivider from '../../../../../_shared/CustomDivider';

interface MultipleWeighingSummaryDataProps {
  weighing: Weighing;
}

const MultipleWeighingSummaryData = ({ weighing }: MultipleWeighingSummaryDataProps) => {
  const { t } = useTranslation('weighingHandlingWeighings');
  const { t: t1 } = useTranslation('utils');

  const unit = useSelector((state: ReducerState) => state.appConfig.unit);
  const { getTransactionTypeLabel } = useTransactionType();

  const finishedWeighingMeasurement = useSelector((state: ReducerState) => state.weighing.finishedWeighingMeasurement);
  const [audio, setAudio] = useState<HTMLAudioElement | undefined>(undefined);

  const fetchComponentData = useCallback(async () => {
    const response = await filesService.getSoundAlarmUrl();
    setAudio(new Audio(response.data));
  }, []);

  useEffect(() => {
    void fetchComponentData();
  }, []);

  useEffect(() => {
    if (!!audio) {
      playSound();
    }
  }, [audio]);

  const playSound = async () => {
    if (
      finishedWeighingMeasurement?.weighingMeasurementType === 'AUTOMATIC' &&
      finishedWeighingMeasurement?.automationPlaySoundWhileSummary
    ) {
      audio?.play();
      setTimeout(() => {
        audio?.pause();
      }, 2000);
    }
  };

  const renderAdditionalField = (field: any) =>
    ['DICTIONARY', 'TEXT', 'AUTO_ID'].includes(field.type) ? (
      <WeighingSummaryTextFieldTemplate label={field.name} value={field.value} />
    ) : (
      <WeighingSummaryDecimalFieldTemplate label={field.name} value={field.value} unit={field.unit} />
    );

  return (
    <>
      <div className="lg:col-6 md:col-12">
        <div className="formgrid grid">
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleWeighingNumber')}
            value={weighing?.number as string}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleWeighingMode')}
            value={getTranslatedLabel(weighingModeLabels, weighing?.weighingMode, t1)}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleWeighingType')}
            value={getTransactionTypeLabel(weighing?.transactionType)}
          />
          <WeighingSummaryDecimalFieldTemplate
            label={t('openWeighingsListSummaryMultipleNetto')}
            value={weighing.netto}
            unit={unit}
          />
        </div>
      </div>
      <div className="lg:col-6 md:col-12">
        <div className="formgrid grid">
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleRegistrationNumber')}
            value={weighing?.vehicle.registrationNumber}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleProduct')}
            value={weighing?.product?.name}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleDriver')}
            value={weighing?.driver?.name}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleContractor')}
            value={weighing?.contractor?.name}
          />
          {weighing?.additionalFields?.map(renderAdditionalField)}
        </div>
      </div>
      <div className="lg:col-12 md:col-12">
        <CustomDivider>
          <span className="tag">{t('openWeighingsListSummaryMultipleMeasurementsList')}</span>
        </CustomDivider>
        <MeasurementsList measurements={weighing.measurements} showAdditionalFields columnEmptyValue={undefined} />
        {weighing.weighingMode !== 'WEIGHING_SERIES' && weighing.weighingMode !== 'DOUBLE_WEIGHING_SERIES' && (
          <>
            <CustomDivider>
              <span className="tag">{t('openWeighingsListSummaryMultipleProductsSummary')}</span>
            </CustomDivider>
            <ProductsSummary productsSummary={weighing.productsSummary as any} />
          </>
        )}
      </div>
    </>
  );
};

export default MultipleWeighingSummaryData;
