import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { useSelector } from 'react-redux';
import { formatDecimal } from '../../../utils/formatUtils';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../types/reducer-state';

interface WeighingSeriesProductsSummaryProps {
  measurements: any[];
}

const WeighingSeriesProductsSummary = ({ measurements }: WeighingSeriesProductsSummaryProps) => {
  const { t } = useTranslation('weighingComponents');

  const unit = useSelector((state: ReducerState) => state.appConfig.unit);

  const indicationTemplate = (productsSummary: any) => {
    return <>{`${formatDecimal(productsSummary.indication)} ${unit}`}</>;
  };

  const scaleTemplate = (productsSummary: any) => {
    return <>{productsSummary.scale.name}</>;
  };

  return (
    <>
      <DataTable
        responsiveLayout="scroll"
        value={measurements}
        className="mb-6"
        emptyMessage={t('productsSummaryEmptyMsg')}
      >
        <Column field="product.name" header={t('productsSummaryProduct')} />
        <Column field="netto" body={indicationTemplate} header={t('productsSummaryIndication')} />
        <Column field="scale" body={scaleTemplate} header={t('productsSummaryScale')} />
        {measurements.find((m) => m.accessCodeName) && (
          <Column field="accessCodeName" header={t('productsSummaryAccessCodeName')} />
        )}
        {measurements.find((m) => m.arrivalNotificationNumber) && (
          <Column field="arrivalNotificationNumber" header={t('productsSummaryarrivalNotificationNumber')} />
        )}
      </DataTable>
    </>
  );
};

export default WeighingSeriesProductsSummary;
