import plPLtranslations from './pl-PL/config';
import plPLBDOtranslations from './pl-PL-BDO/config';
import enGBtranslations from './en-GB/config';
import deDEtranslations from './de-DE/config';
import { LanguageValue } from '../../enums/language-value.enum';
import { LanguageLabel } from '../../enums/language-label.enum';

const resources = {
  'en-GB': enGBtranslations,
  'pl-PL-BDO': plPLBDOtranslations,
  'pl-PL': plPLtranslations,
  'de-DE': deDEtranslations,
};

const AVAILABLE_LANGUAGES = [
  { label: LanguageLabel.ENGLISH, value: LanguageValue.ENGLISH },
  { label: LanguageLabel.GERMAN, value: LanguageValue.GERMAN },
];

export const getAvailableLanguages = (usePolishBDOLanguage: boolean) => {
  const languages = [...AVAILABLE_LANGUAGES];

  if (usePolishBDOLanguage) {
    languages.push({ label: LanguageLabel.POLISH, value: LanguageValue.POLISH_BDO });
  } else {
    languages.push({ label: LanguageLabel.POLISH, value: LanguageValue.POLISH });
  }

  return languages;
};

export default resources;
