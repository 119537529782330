import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import contractorsService from '../../../services/ScaleoApiServices/ContractorsService';
import '../../DataTable.css';
import { CustomTableField, CustomButton } from '../../_shared/CustomComponents';
import DataTableFilterElements from '../../_shared/DataTableFilterElements';
import ImportInformationDialog from '../../_shared/ImportInformationDialog';
import NewOrEditContractorDialog from './components/NewOrEditContractorDialog';
import { userActions } from '../../../store/user-slice';
import { getFileFromText } from '../../../utils/getFile';
import getPaginatorTemplate from '../../../utils/getPaginatorTemplate';
import { useTranslation, Trans } from 'react-i18next';
import { ReducerState } from '../../../types/reducer-state';
import { Contractor } from '../../../types/weighing';
// import { ReceiverUpdatedStatus } from '../../../enums/receiver-updated-status.enum';

const FileUploadAny = FileUpload as any;
const DataTableAny = DataTable as any;

const emptyContractor = {
  id: '',
  code: '',
  name: '',
  taxId: '',
  email: '',
  phone: '',
  autoPrint: false,
  autoSend: false,
  type: '',
};

const emptyPaginationParameters = {
  order: {
    isAscending: true,
    orderColumn: 'id',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const emptyPaginatorState = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

export const Contractors = () => {
  const { t } = useTranslation('datasetsContractors');
  // const { t: t2 } = useTranslation('managementSystemConfigurationBdo');

  const [contractors, setContractors] = useState<Contractor | null>(null);
  const [selectedContractors, setSelectedContractors] = useState<Contractor[]>([]);
  const [contractor, setContractor] = useState<Contractor>(emptyContractor);
  const [contractorDialogVisible, setContractorDialogVisible] = useState(false);
  const [deleteContractorDialogVisible, setDeleteContractorDialogVisible] = useState(false);
  const [selectedRowToEdit, setSelectedRowToEdit] = useState<Contractor | null>(null);

  const dispatch = useDispatch();

  const [deleteContractorsDialogVisible, setDeleteContractorsDialogVisible] = useState(false);
  const dt = useRef(null);
  const fileUploader = useRef<any | null>(null);
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
  const [importInformationDialogData, setImportInformationDialogData] = useState(null);

  const filters = useSelector((state: ReducerState) => state.user.filtersState.contractors);
  const [paginationParameters, setPaginationParameters] = useState(emptyPaginationParameters);
  const [paginatorState, setPaginatorState] = useState(emptyPaginatorState);

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  // const bdoUpdatedStatuses = [
  //   { value: ReceiverUpdatedStatus.RECEIVE_CONFIRMATION, name: t2('receiveConfirmation') },
  //   { value: ReceiverUpdatedStatus.REJECT, name: t2('reject') },
  // ];

  const fetchContractors = useCallback(
    async (_filters, _paginatorParam) => {
      contractorsService.searchContractors(_filters, _paginatorParam).then((response) => {
        setContractors(response.data);
        response.totalPages
          ? setPaginatorState({
              ...paginatorState,
              totalPages: response.totalPages,
            })
          : setPaginatorState({
              ...paginatorState,
              totalPages: 1,
              currentPage: 1,
            });
      });
    },
    [paginatorState],
  );

  useEffect(() => {
    fetchContractors(filters, paginationParameters);
  }, [filters, paginationParameters, loggedUserContext.currentCustomer?.id, contractorDialogVisible]);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const openNew = () => {
    setContractor(emptyContractor);
    setSelectedRowToEdit(null);
    setContractorDialogVisible(true);
  };
  const editContractor = (_contractor: Contractor) => {
    setContractor(_contractor);
    setSelectedRowToEdit(_contractor);
    setContractorDialogVisible(true);
  };

  const confirmDeleteContractor = (_contractor: Contractor) => {
    setContractor(_contractor);
    setDeleteContractorDialogVisible(true);
  };
  const hideDeleteContractorDialog = () => {
    setDeleteContractorDialogVisible(false);
  };

  const confirmDeleteSelected = () => {
    setDeleteContractorsDialogVisible(true);
  };
  const hideDeleteContractorsDialog = () => {
    setDeleteContractorsDialogVisible(false);
  };

  const deleteContractor = () => {
    contractorsService.removeContractors(contractor.id).then(() => {
      fetchContractors(filters, paginationParameters);
    });

    setDeleteContractorDialogVisible(false);
    setContractor(emptyContractor);
  };

  const deleteSelectedContractors = () => {
    for (const _contractors of selectedContractors) {
      contractorsService.removeContractors(_contractors.id).then(() => {
        fetchContractors(filters, paginationParameters);
      });
    }
    setDeleteContractorsDialogVisible(false);
    setSelectedContractors([]);
  };

  const changeFilter = (value: string, name: string) => {
    const newFilters = { ...filters };
    newFilters[name] = value;
    dispatch(userActions.setContractorsFilters(newFilters));
  };

  const clearFilters = () => {
    dispatch(userActions.setContractorsFilters({}));
  };

  useEffect(() => {
    setPaginationParameters((prevPaginationParameters: any) => ({
      ...prevPaginationParameters,
      page: {
        ...prevPaginationParameters.page,
        index: 1,
      },
    }));
  }, [filters]);

  useEffect(() => {
    if (paginatorState.currentPage > paginatorState.totalPages)
      setPaginatorState((prevPaginatorState: any) => ({
        ...prevPaginatorState,
        currentPage: 1,
      }));
  }, [paginatorState]);

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const deleteContractorDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteContractorDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteContractor} />
    </>
  );
  const deleteContractorsDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteContractorsDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteSelectedContractors} />
    </>
  );

  const header = (
    <div className="table-header text-2xl p-2">
      {t('header')} &#160;&#160;
      <div className="flex">
        <Button label={t('clearFilters')} icon="pi pi-filter-slash" outlined className="mr-2" onClick={clearFilters} />
        <span className="">
          <DataTableFilterElements.Text
            initialValue={filters['global']}
            onChangeFilter={changeFilter}
            name={'global'}
            placeholder={t('searchPlaceholder')}
            isSearchIcon
          />
        </span>
      </div>
    </div>
  );

  const leftToolbarTemplate = () => {
    return (
      <>
        <Button label={t('addButton')} icon="pi pi-plus" severity="success" className="mr-2" onClick={openNew} />
        <CustomButton
          label={t('deleteButton')}
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={
            !selectedContractors ||
            !selectedContractors.length ||
            selectedContractors.map((contractor) => contractor?.isUsed).includes(true)
          }
          tooltip={!selectedContractors.length ? t('selectContractor') : t('cannotDelete')}
        />
      </>
    );
  };

  const exportData = async () => {
    const csvResponse = await contractorsService.exportData();
    const csv = csvResponse.data;
    getFileFromText(csv, 'text/csv', `contractors-${Date.now().toString()}.csv`);
  };

  const importData = (event: any) => {
    let csvData = '';
    const [f] = event.files;
    const reader = new FileReader();
    reader.onload = async (e) => {
      csvData = Buffer.from(e?.target?.result as any).toString('base64');
      const insertResponse = await contractorsService.importData(csvData);
      fileUploader.current?.clear();
      await fetchContractors(filters, paginationParameters);
      setImportInformationDialogData(insertResponse.data);
      setIsOpenImportDialog(true);
    };
    reader.readAsText(f);
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <FileUploadAny
          ref={fileUploader}
          mode="basic"
          accept=".csv"
          maxFileSize={1000000}
          label={t('import')}
          chooseLabel={t('import')}
          className="mr-2"
          customUpload
          auto
          uploadHandler={importData}
        />
        <Button label={t('export')} icon="pi pi-upload" severity="help" className="mr-2" onClick={exportData} />
      </>
    );
  };

  const actionBodyTemplate = (rowData: Contractor) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          raised
          severity="success"
          className="mr-2"
          onClick={() => editContractor(rowData)}
        />
        {rowData.isUsed === false ? (
          <Button icon="pi pi-trash" raised severity="warning" onClick={() => confirmDeleteContractor(rowData)} />
        ) : (
          <Button
            icon="pi pi-trash"
            raised
            severity="secondary"
            tooltip={t('tooltip')}
            tooltipOptions={{ position: 'bottom' }}
          />
        )}
      </div>
    );
  };

  const columnBodyTemplate = (rowData: Contractor, column: any) => {
    return <CustomTableField row={rowData} col={column} />;
  };

  const columns = [
    { field: 'code', header: t('code'), type: 'text' },
    { field: 'companyName', header: t('companyName'), type: 'text' },
    { field: 'firstName', header: t('firstName'), type: 'text' },
    { field: 'lastName', header: t('lastName'), type: 'text' },
    { field: 'taxId', header: t('taxId'), type: 'text' },
    { field: 'email', header: t('email'), type: 'text' },
  ];

  const dynamicColumns = columns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={columnBodyTemplate}
        fieldsType={col.type}
        sortable
        filter
        showFilterMenu={false}
        filterElement={
          <DataTableFilterElements.Text
            initialValue={filters[col.field]}
            onChangeFilter={changeFilter}
            name={col.field}
            placeholder={t('filters')}
          />
        }
      />
    );
  });

  // if (isModulesEnabled(['BDO'], loggedUserContext.currentCustomer?.subscriptionModules)) {
  //   dynamicColumns.push(
  //     <Column
  //       field="bdoReceiverUpdatedStatus"
  //       header={t('receivedCardStatus')}
  //       sortable
  //       filter
  //       showFilterMenu={false}
  //       body={(row) => <p>{bdoUpdatedStatuses.find((s) => s.value === row.bdoReceiverUpdatedStatus)?.name}</p>}
  //       filterElement={
  //         <Dropdown
  //           optionLabel="name"
  //           value={filters.bdoReceiverUpdatedStatus}
  //           options={bdoUpdatedStatuses}
  //           onChange={(e) => changeFilter(e.value, 'bdoReceiverUpdatedStatus')}
  //           className="column-filter"
  //           placeholder={t('filter')}
  //           showClear
  //         />
  //       }
  //     />,
  //   );
  // }g

  return (
    <div className="grid">
      <ImportInformationDialog
        data={importInformationDialogData}
        visible={isOpenImportDialog}
        closeDialog={() => setIsOpenImportDialog(false)}
      />
      <div className="col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTableAny
                ref={dt}
                value={contractors}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                rowHover
                selectionMode="checkbox"
                selection={selectedContractors}
                onSelectionChange={(e: any) => setSelectedContractors(e.value)}
                emptyMessage={t('noData')}
                removableSort
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                paginator
                rows={paginatorState.rows}
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState)}
                stripedRows
                filterDisplay="row"
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                {dynamicColumns}
                <Column body={actionBodyTemplate} className="actionBody"></Column>
              </DataTableAny>
              <NewOrEditContractorDialog
                contractorDialogVisible={contractorDialogVisible}
                setContractorDialogVisible={setContractorDialogVisible}
                items={selectedRowToEdit}
              />
              <Dialog
                visible={deleteContractorDialogVisible}
                header={
                  <span className="flex align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
                    {t('confirm')}
                  </span>
                }
                modal
                className="p-fluid min-w-min"
                footer={deleteContractorDialogFooter}
                onHide={hideDeleteContractorDialog}
              >
                <div className="confirmation-content">
                  {contractor && (
                    <span>
                      <Trans t={t} i18nKey="singleContent" values={{ contractor: contractor.name }} />
                    </span>
                  )}
                </div>
              </Dialog>
              <Dialog
                visible={deleteContractorsDialogVisible}
                header={
                  <span className="flex align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
                    {t('confirm')}
                  </span>
                }
                modal
                className="p-fluid min-w-min"
                footer={deleteContractorsDialogFooter}
                onHide={hideDeleteContractorsDialog}
              >
                <div className="confirmation-content">{contractor && <span>{t('multipleContent')}</span>}</div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
