import { useFormik } from 'formik';
import { userActions } from '../../store/user-slice';
import weighingConfigurationService from '../../services/ScaleoApiServices/WeighingConfigurationService';

const usePosReceiptConfigurationForm = (receiptConfigurationData: any, dispatch: any) => {
  const formik = useFormik({
    initialValues: receiptConfigurationData,
    validate: () => {
      const errors: any = {};

      return errors;
    },
    onSubmit: async (formData) => {
      await weighingConfigurationService.updatePOSConfigurationReceipts({
        posReceiptCustomFooter: formData.posReceiptCustomFooter,
        posReceiptNumberOfCopies: formData.posReceiptNumberOfCopies,
        posReceiptFieldsOrder: formData.posReceiptFieldsOrder,
      });
      dispatch(userActions.shouldRefreshSystemConfiguration(true));
    },
    enableReinitialize: true,
  });

  const resetForm = () => {
    formik.resetForm();
  };

  return { formik, resetForm };
};

export default usePosReceiptConfigurationForm;
