import { useState, useEffect, useCallback } from 'react';

import usePagination from '../_shared/usePagination';
import { PaginationParams } from '../../types/general-types';
import accessControlArrivalNotificationService from '../../services/ScaleoApiServices/AccessControlArrivalNotificaitonService';
import { Filters } from '../../components/AccessControlArrivalNotification/types';
import dictionariesService from '../../services/ScaleoApiServices/DictionariesService';
import weighingConfigurationService from '../../services/ScaleoApiServices/WeighingConfigurationService';
import { weighingActions } from '../../store/weighing-slice';
import { useDispatch } from 'react-redux';

const useAccessControlArrivalNotificationData = (initialFilters: Filters) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({ arrivalNotifications: [], dictionaries: [] });
  const [totalPages, setTotalPages] = useState(0);
  const orderColumn = 'createdAt';

  const fetchData = useCallback(
    async (paginationParameters: PaginationParams) => {
      const arrivalNotificationsResponse = await accessControlArrivalNotificationService.searchArrivalNotifications(
        initialFilters,
        paginationParameters,
      );
      const dictionariesResponse = await dictionariesService.getDictionaries();
      const weighingConfigurationResponse = await weighingConfigurationService.getConfiguration();

      if (arrivalNotificationsResponse.data) {
        setData({
          arrivalNotifications: arrivalNotificationsResponse.data.data,
          dictionaries: dictionariesResponse.data,
        });
        setTotalPages(arrivalNotificationsResponse.data?.totalPagesNumber);
        dispatch(weighingActions.weighingConfigurationFetched(weighingConfigurationResponse.data));
      }
    },
    [initialFilters],
  );

  const { paginationParameters, setPaginationParameters, paginatorState, setPaginatorState } = usePagination(
    fetchData,
    orderColumn,
  );

  useEffect(() => {
    setPaginatorState((prevState) => ({ ...prevState, totalPages }));
  }, [totalPages]);

  return { data, paginatorState, setPaginatorState, paginationParameters, setPaginationParameters, fetchData };
};

export default useAccessControlArrivalNotificationData;
