import axios from 'axios';
import moment from 'moment';
import { formatStringToDate } from '../../utils/formatUtils';
import { Container } from '../../types/container';

class ContainersService {
  formatSearchFilters(filters: any) {
    const startOfDay = (day: number) => moment(day).startOf('day').toDate().toString();
    const endOfDay = (day: number) => moment(day).endOf('day').toDate().toString();

    const prepareDateFilter = (_filters: any) => {
      if (_filters?.tareTimestamp) {
        if (_filters.tareTimestamp[1]) {
          return {
            ..._filters,
            tareTimestamp: [startOfDay(_filters.tareTimestamp[0]), endOfDay(_filters.tareTimestamp[1])],
          };
        } else {
          return {
            ..._filters,
            tareTimestamp: [startOfDay(_filters.tareTimestamp[0]), endOfDay(_filters.tareTimestamp[0])],
          };
        }
      } else {
        return _filters;
      }
    };

    return {
      ...filters,
      tareTimestamp: prepareDateFilter(filters).tareTimestamp?.map((stringDate: string) =>
        formatStringToDate(stringDate),
      ),
    };
  }
  async searchContainers(filters: any, paginatorParam: any) {
    const containers = await axios.post(`/containers/search`, {
      filters: this.formatSearchFilters(filters),
      paginatorParam,
    });

    return { data: containers.data.data.map(mapContainersFromResponse), totalPages: containers.data.totalPages };
  }

  async getContainers() {
    return axios.get(`/containers`);
  }

  async createContainer(container: Container) {
    return axios.post(`/containers`, {
      data: container,
    });
  }

  async editContainer(container: Container, id: string) {
    return axios.put(`/containers/${id}`, {
      data: container,
    });
  }

  async removeManyContainers(containers: any[]) {
    return axios.post(`/containers/remove-many`, {
      data: containers.map((v) => v.id),
    });
  }

  async getContainerHistory(container_id: string) {
    const tares = await axios.post(`/tares`, {
      data: container_id,
    });

    return { data: tares.data.map(mapTaresFromResponse) };
  }
}

const mapTaresFromResponse = (tare: any) => {
  return {
    ...tare,
    type: tare.type === 'DECLARED' ? 'Deklaracja użytkownika' : tare.type === 'MEASURED' ? 'Pomiar' : '',
  };
};
const mapContainersFromResponse = (container: any) => {
  return {
    ...container,
    'lt.type':
      container['lt.type'] === 'DECLARED'
        ? 'Deklaracja użytkownika'
        : container['lt.type'] === 'MEASURED'
        ? 'Pomiar'
        : '',
  };
};

const containersService = new ContainersService();
export default containersService;
