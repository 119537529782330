import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { Message } from 'primereact/message';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BdoApiService from '../../../../../services/BdoApiService';
import productsService from '../../../../../services/ScaleoApiServices/ProductsService';
import weighingsService from '../../../../../services/ScaleoApiServices/WeighingsService';
import { userActions } from '../../../../../store/user-slice';
import { weighingActions } from '../../../../../store/weighing-slice';
import { ReducerState } from '../../../../../types/reducer-state';
import { additionalFieldsReduce } from '../../../../../utils/additionalFieldsUtils';
import { validateAdditionalField } from '../../../../../utils/additionalFieldsUtilsForWeighingDialog';
import useTransactionType from '../../../../../utils/useTransactionType';
import { CustomAutoComplete } from '../../../../_shared/CustomComponents';
import FormErrorMessage from '../../../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../../../_shared/FormErrorMessageScroller';
import { VehicleAutoComplete } from '../../../../_shared/VehicleAutoComplete';
import AdditionalFieldsPlugin from './components/AdditionalFieldsPlugin';
import AutocompleteDataButton from './components/AutocompleteDataButton';
import BdoPlugin from './components/BdoPlugin';
import HistoryPlugin from './components/HistoryPlugin';
import { Measurements } from './components/Measurements';
import { Tooltip } from 'primereact/tooltip';
import { Weighing } from '../../../../../types/weighing';
import { Calendar } from 'primereact/calendar';
import { SplitButton } from 'primereact/splitbutton';
import { ContainersForm } from '../../../HandlingWeighings/components/NewWeighingForm/components/ContainersForm';
import { Container } from '../../../../../types/container';
import { Trailer } from '../../../../../types/trailer';
import CustomDivider from '../../../../_shared/CustomDivider';

const INIT_FORM_STATE = {
  cardType: '',
  card: null,
  id: null,
  alibi: '',
  vehicle: '' as any,
  trailer: '' as any,
  saveVehicle: false,
  contractor: '' as any,
  productsSummary: '' as any,
  driver: '',
  transactionType: '' as any,
  measurements: [],
  additionalFields: [],
  closedAt: null,
  updateTare: false,
  containers: [],
};

interface WeighingDialogProps {
  visible: boolean;
  onClose: () => void;
  weighing: any;
  setWeighing?: (arg: any) => void;
  inputVehicles: any[];
  inputTrailers: Trailer[];
  inputContractors: any[];
  inputDrivers: any[];
  inputScales: any[];
  afterSubmitAction: () => void;
  canEnableBdoPlugin: boolean | null;
  isCombine: boolean;
  kpoWeighings: any[];
  weighingConfiguration: any;
  containers: Container[];
}

const WeighingDialog = ({
  visible,
  onClose,
  weighing,
  setWeighing,
  inputVehicles,
  inputContractors,
  inputDrivers,
  inputScales,
  inputTrailers,
  afterSubmitAction,
  canEnableBdoPlugin,
  isCombine,
  kpoWeighings,
  weighingConfiguration,
  containers,
}: WeighingDialogProps) => {
  const { t } = useTranslation('weighingWeighingList');
  const dispatch = useDispatch();
  const [initialWeighing, setInitialWeighing] = useState<any | null>(null);
  const [initialValues, setInitialValues] = useState(INIT_FORM_STATE);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const unit = useSelector((state: ReducerState) => state.appConfig.unit);
  const [isNewVehicle, setIsNewVehicle] = useState(false);
  const [isNewTrailer, setIsNewTrailer] = useState(false);

  const { ALL_TRANSACTION_TYPES, getTransactionTypeLabel } = useTransactionType();

  const isManual = !isCombine && !weighing.id;
  const weighingAdditionalFields = weighingConfiguration?.additionalFields?.filter(
    (a: any) => a.destination === 'WEIGHING',
  );
  const measurementAdditionalFields = weighingConfiguration?.additionalFields?.filter(
    (a: any) => a.destination === 'MEASUREMENT',
  );

  useEffect(() => {
    setInitialWeighing(weighing);
  }, []);

  const getInitialValues = useCallback(async () => {
    const productsSummary = weighing?.productsSummary?.length
      ? weighing?.productsSummary?.map((productSummary: any) => ({
          ...productSummary,
          product: productsService.mapProduct(productSummary.product),
        }))
      : [];

    const measurements = await prepareInitialMeasurements(weighing, isCombine, measurementAdditionalFields);
    setInitialValues({
      cardType: weighing.cardType,
      card: weighing.cardId ? await getBdoCard(weighing.cardId, weighing.cardType) : null,
      id: weighing.id,
      alibi: weighing.alibi,
      vehicle: weighing.vehicle,
      trailer: weighing.trailer?.registrationNumber ? weighing.trailer : null,
      saveVehicle: weighingConfiguration?.saveVehicleDefaultValue,
      contractor: weighing.contractor,
      productsSummary,
      driver: weighing.driver,
      number: weighing.number,
      closedAt: weighing.closedAt,
      measurements: measurements.map((m: any) => ({
        ...m,
        scale: inputScales?.find((s) => s.name === m.scale.name),
        scaleName: m.scale.name,
        scaleId: inputScales?.find((s) => s.name === m.scale.name)?.id,
        additionalFields: measurementAdditionalFields?.map((a: any) => ({ ...a, value: m.additionalFields?.[a.id] })),
      })),
      transactionType: weighing.transactionType && {
        id: weighing.transactionType,
        name: getTransactionTypeLabel(weighing.transactionType),
      },
      additionalFields: weighingAdditionalFields?.map((a: any) => ({ ...a, value: weighing.additionalFields?.[a.id] })),
      containers: weighing.containers ?? [],
    } as any);
  }, [weighing]);

  useEffect(() => {
    void getInitialValues();
  }, [getInitialValues, weighing]);

  const validateMethod = (data: any) => {
    const errors: any = {};

    if (!data.vehicle && !weighing.automatic && !isCombine) {
      errors.vehicle = t('weighingDialogValidationNoVehicle');
    }
    if (data.cardType && !data.card) {
      errors.card = t('weighingDialogValidationSelectCard');
    }
    if (!data.transactionType && weighingConfiguration.transactionTypeRequired) {
      errors.transactionType = t('weighingDialogValidationNoType');
    }
    if (weighing.closed && !data.closedAt) {
      errors.closedAt = t('weighingDialogValidationNoClosedAt');
    }
    if (Array.isArray(data.measurements)) {
      for (const [index, measurement] of data.measurements.entries()) {
        if (!measurement.scale?.id) {
          errors[`measurement-${index}-scale`] = t('weighingDialogValidationNoValue');
        }
        if (!measurement.timestamp) {
          errors[`measurement-${index}-timestamp`] = t('weighingDialogValidationNoDate');
        }
        if (!measurement.indication) {
          errors[`measurement-${index}-indication`] = t('weighingDialogValidationNoValue');
        }
        measurement?.additionalFields?.forEach((field: any, i: number) =>
          validateAdditionalField(field, `measurement-${i + 1}-additionalFields-${i}`, errors, t),
        );
      }
    }
    return errors;
  };

  const onSubmitButtonClicked = () => {
    setIsSubmitClicked(true);
    formik.submitForm();
  };

  const handleSubmit = (formData: any) => {
    const getCorrectCardId = (card: any, cardType: string) => {
      if (card) {
        const searchMethods = {
          kpo: card.kpoId || null,
          'kpok-receive': card.kpokId || null,
          'kpok-transfer': card.kpokId || null,
        };
        return searchMethods[cardType as keyof typeof searchMethods];
      } else {
        return null;
      }
    };

    const onCombine = async () => {
      const combineWeighing = {
        additionalFields: additionalFieldsReduce(formData.additionalFields),
        cardId: getCorrectCardId(formData.card, formData.cardType),
        cardType: formData.cardType,
        contractor: formData.contractor,
        driver: formData.driver,
        id: formData.id,
        measurements: formData.measurements,
        number: weighing.number,
        saveVehicle: formData.saveVehicle,
        vehicle: isNewVehicle ? { registrationNumber: formData.vehicle } : formData.vehicle,
        transactionType: formData?.transactionType?.id || null,
        updateTare: !!formData.updateTare,
        containers: formData.containers,
        trailer: isNewTrailer ? { registrationNumber: formData.trailer } : formData.trailer,
      };
      const weighingToPull = weighing.weighingsData.filter((w: Weighing) => w.id !== formData.id)[0];
      void weighingsService.combineWeighings(combineWeighing as any, weighingToPull).then((val) => {
        afterSubmitAction();
        dispatch(
          weighingActions.weighingMeasurementFinished({
            ...val.data,
            automationMergeWeighingsSummary: weighingConfiguration.mergeWeighingsSummary,
            isCombined: true,
          }),
        );
      });
    };

    const onEdit = () => {
      const postData = {
        additionalFields: additionalFieldsReduce(formData.additionalFields),
        cardId: getCorrectCardId(formData.card, formData.cardType),
        cardType: formData.cardType,
        contractor: formData.contractor,
        closedAt: formData.closedAt,
        driver: formData.driver,
        id: formData?.id,
        measurements: formData.measurements,
        saveVehicle: formData?.saveVehicle,
        vehicle: isNewVehicle ? { registrationNumber: formData.vehicle } : formData.vehicle,
        transactionType: formData?.transactionType?.id || null,
        updateTare: !!formData.updateTare,
        containers: formData.containers,
        trailer: isNewTrailer ? { registrationNumber: formData.trailer } : formData.trailer,
      };

      void weighingsService.editWeighing(postData, formData.id).then(() => {
        afterSubmitAction();
      });
    };

    const onCreateManual = () => {
      const manualWeighing = {
        additionalFields: additionalFieldsReduce(formData.additionalFields),
        cardType: formData.cardType,
        cardId: getCorrectCardId(formData.card, formData.cardType),
        closedAt: formData.closedAt,
        transactionType: formData?.transactionType?.id,
        vehicleId: formData.vehicle.id,
        saveVehicle: formData?.saveVehicle,
        newVehicleRegistrationNumber: typeof formData?.vehicle === 'string' ? formData?.vehicle : '',
        newVehicleVisible: formData.saveVehicle,
        contractor: formData.contractor,
        driver: formData.driver,
        measurements: formData.measurements,
        updateTare: !!formData.updateTare,
        containers: formData.containers,
        trailer: isNewTrailer ? { registrationNumber: formData.trailer } : formData.trailer,
      } as any;

      void weighingsService.createManualWeighing(manualWeighing).then(() => {
        afterSubmitAction();
        dispatch(weighingActions.clearSelectedScale());
        dispatch(userActions.setLastUsedScaleId(formData.scale.id));
      });
    };

    formData.measurements = formData.measurements.map((m: any) => ({
      ...m,
      additionalFields: additionalFieldsReduce(m.additionalFields),
    }));
    if (isCombine) {
      void onCombine();
    } else if (formData.id) {
      onEdit();
    } else {
      onCreateManual();
    }

    formik.resetForm({
      values: INIT_FORM_STATE,
    });
    onClose();
  };

  const formik = useFormik({
    initialValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const dialogFooter = (
    <>
      <Button type="reset" label={t('weighingDialogCancelButton')} icon="pi pi-times" text onClick={onClose} />
      <Button
        type="submit"
        label={t('weighingDialogSaveButton')}
        icon="pi pi-check"
        text
        onClick={onSubmitButtonClicked}
      />
    </>
  );

  const checkIfExistingVehicle = (registrationNumber: string) => {
    if (registrationNumber) {
      registrationNumber = registrationNumber.toUpperCase().replace(/[^a-z0-9]/gi, '');
      const existingVehicleIndex = inputVehicles
        .map((vehicle) => vehicle.registrationNumber)
        .indexOf(registrationNumber);
      if (existingVehicleIndex > -1) {
        void formik.setFieldValue('vehicle', inputVehicles[existingVehicleIndex]);
        setIsNewVehicle(false);
      } else {
        void formik.setFieldValue('vehicle', registrationNumber);
        setIsNewVehicle(true);
      }
    } else {
      void formik.setFieldValue('vehicle', '');
      setIsNewVehicle(false);
    }
  };

  const checkIfExistingTrailer = (registrationNumber: string) => {
    if (registrationNumber) {
      registrationNumber = registrationNumber.toUpperCase().replace(/[^a-z0-9]/gi, '');
      const existingTrailerIndex = inputTrailers
        .map((trailer) => trailer.registrationNumber)
        .indexOf(registrationNumber);
      if (existingTrailerIndex > -1) {
        void formik.setFieldValue('trailer', inputVehicles[existingTrailerIndex]);
        setIsNewTrailer(false);
      } else {
        void formik.setFieldValue('trailer', registrationNumber);
        setIsNewTrailer(true);
      }
    } else {
      void formik.setFieldValue('trailer', '');
      setIsNewTrailer(false);
    }
  };

  const handleClickAutocompleteDataButton = (e: any) => {
    setWeighing && setWeighing({ ...weighing, ...weighing.weighingsData[e] });
    formik.resetForm({
      values: INIT_FORM_STATE,
    });
  };

  const newMeasurementsNumber =
    initialWeighing?.measurements && formik.values.measurements
      ? formik.values.measurements.filter((m: any) => !m.toBeDeleted)?.length - initialWeighing.measurements?.length
      : 0;

  const allowAddingMeasurements = () =>
    Number.isInteger(loggedUserContext.limits?.maximumMeasurementsNumber) &&
    Number.isInteger(loggedUserContext.limitsState?.currentMonthMeasurementsNumber)
      ? loggedUserContext.limits!.maximumMeasurementsNumber! >=
        loggedUserContext.limitsState!.currentMonthMeasurementsNumber + newMeasurementsNumber + 1
      : true;

  const currentVehicle = inputVehicles.find((v) => v.registrationNumber === formik.values.vehicle?.registrationNumber);

  const canAddMeasurement = (!isCombine || !weighing.id) && !isManual;

  const addMeasurementOptions = [
    {
      label: t('addMeasurement'),
      icon: 'pi pi-file-excel',
      disabled: !canAddMeasurement,
      command: async () => {
        await formik.setFieldValue('measurements', [
          ...(formik.values.measurements ?? []),
          createEmptyMeasurement(measurementAdditionalFields),
        ]);
      },
    },
    {
      label: t('addSavedTare'),
      icon: 'pi pi-file-pdf',
      disabled: !(canAddMeasurement && currentVehicle?.tareValue),
      command: async () => {
        await formik.setFieldValue('measurements', [
          {
            timestamp: currentVehicle.tareTimestamp,
            indication: currentVehicle.tareValue,
            scale: inputScales.find((s) => s.id === currentVehicle.tareScaleId),
            type: currentVehicle.tareType,
            isConfirmed: false,
            alibi: '',
            additionalFields: measurementAdditionalFields,
          },
          ...(formik.values.measurements ?? []),
        ]);
      },
    },
  ];

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={visible}
        header={isCombine ? t('weighingDialogHeaderCombine') : t('weighingDialogHeader') + ` ${weighing.number || ''}`}
        modal
        className="p-fluid w-60vw"
        breakpoints={{ '1400px': '75vw', '896px': '90vw' }}
        footer={dialogFooter}
        onHide={onClose}
      >
        <form>
          <div className="p-fluid formgrid grid">
            <AutocompleteDataButton isCombine={isCombine} onClick={handleClickAutocompleteDataButton} />
            {areExistedDifferences(initialWeighing, formik.values.vehicle) && (
              <div className="field col-12">
                <div className="col-5 grid my-0">
                  <Button
                    label={t('weighingDialogSetDefaults')}
                    className="mr-4 col p-2"
                    onClick={() => {
                      const currentVehicle = inputVehicles.find(
                        (v) =>
                          v.registrationNumber === formik.values.vehicle.registrationNumber ||
                          v.id === formik.values.vehicle.id,
                      );
                      void formik.setFieldValue(
                        'contractor',
                        formik.values.vehicle?.defaultContractor ?? currentVehicle.defaultContractor,
                      );
                      void formik.setFieldValue(
                        'driver',
                        formik.values.vehicle?.defaultDriver ?? currentVehicle.defaultDriver,
                      );
                      void formik.setFieldValue(
                        'product',
                        formik.values.vehicle?.defaultProduct ?? currentVehicle.defaultProduct,
                      );
                      void formik.setFieldValue(
                        'trailer',
                        formik.values.vehicle?.defaultTrailer ?? currentVehicle.defaultTrailer,
                      );
                    }}
                    type="button"
                  />
                </div>
              </div>
            )}
            <CustomDivider />
            <div className="field col-12 md:col-6">
              <label htmlFor="vehicle">{t('weighingDialogVehicle')}</label>
              <VehicleAutoComplete
                id="vehicle"
                value={formik.values.vehicle}
                onChange={(value: any) => {
                  if (typeof value === 'string') {
                    checkIfExistingVehicle(value);
                  } else if (typeof value === 'object') {
                    void formik.setFieldValue('vehicle', value);
                    setIsNewVehicle(false);
                  }
                }}
                items={inputVehicles}
                onBlurFuntion={undefined}
              />
              <FormErrorMessage fieldName="vehicle" formikInstance={formik} />
              {isNewVehicle ? <Message severity="info" text={t('weighingDialogNewVehicle')} /> : <></>}
              {isNewVehicle && weighingConfiguration.saveVehicleShowCheckbox ? (
                <div className="field grid mt-2">
                  <div className="col-2">
                    <InputSwitch
                      id="saveVehicle"
                      checked={formik.values.saveVehicle}
                      onChange={(e) => {
                        void formik.setFieldValue('saveVehicle', e.value);
                      }}
                    />
                  </div>
                  <label className="col-5">{t('weighingDialogSaveNewVehicle')}</label>
                </div>
              ) : (
                <></>
              )}
            </div>
            {weighingConfiguration?.addTrailerRegistrationNumbersSupport && (
              <div className="field col-12 md:col-6">
                <label htmlFor="trailer"> {t('trailerRegistrationNumber')}</label>{' '}
                <VehicleAutoComplete
                  id="trailer"
                  value={formik.values.trailer}
                  onChange={(value: Trailer | string) => {
                    if (typeof value === 'string') {
                      checkIfExistingTrailer(value);
                    } else if (typeof value === 'object') {
                      void formik.setFieldValue('trailer', value);
                      setIsNewTrailer(false);
                    }
                  }}
                  items={inputTrailers}
                  onBlurFuntion={undefined}
                />
                <FormErrorMessage fieldName="trailer" formikInstance={formik} />
                {isNewTrailer ? <Message severity="info" text={t('weighingDialogNewTrailer')} /> : <></>}
              </div>
            )}
            <div className="field col-12 md:col-6">
              <label htmlFor="transactionType">{t('weighingDialogTransactionType')}</label>
              <CustomAutoComplete
                id="transactionType"
                value={formik.values.transactionType}
                handler={formik.handleChange}
                source={ALL_TRANSACTION_TYPES}
              />
              <FormErrorMessage fieldName="transactionType" formikInstance={formik} />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="driver">{t('weighingDialogDriver')}</label>
              <CustomAutoComplete
                id="driver"
                value={formik.values.driver}
                handler={formik.handleChange}
                source={inputDrivers}
              />
              <FormErrorMessage fieldName="driver" formikInstance={formik} />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="contractor">{t('weighingDialogContractor')}</label>
              <CustomAutoComplete
                id="contractor"
                value={formik.values.contractor}
                handler={(e: any) => {
                  void formik.setFieldValue('contractor', e.value);
                }}
                source={inputContractors}
              />
              <FormErrorMessage fieldName="contractor" formikInstance={formik} />
            </div>
            {weighing.closed && (
              <div className="field col-12 md:col-6">
                <label htmlFor="closedAt">{t('weighingDialogClosedAt')}</label>
                <Calendar
                  id={`closedAt`}
                  dateFormat="dd.mm.yy"
                  value={formik.values.closedAt ? new Date(formik.values.closedAt) : undefined}
                  onChange={formik.handleChange}
                  showTime
                  showSeconds
                />
                <FormErrorMessage fieldName={`closedAt`} formikInstance={formik} withTouchedTrue={isSubmitClicked} />
              </div>
            )}
            <CustomDivider />
            {addMeasurementOptions.length > 0 && (
              <div className="splitButton">
                <SplitButton
                  className="p-button-help my-split-button ml-1vw"
                  label={t('addMeasurement')}
                  icon="pi pi-chevron-down"
                  model={addMeasurementOptions}
                  onClick={(self: any) => {
                    self.currentTarget.nextSibling.click();
                  }}
                  disabled={!allowAddingMeasurements()}
                />
                {!allowAddingMeasurements() && (
                  <Tooltip target=".splitButton" position="bottom">
                    {t('blockAdding')}
                  </Tooltip>
                )}
              </div>
            )}

            {formik.values.measurements.length <= 2 &&
              weighing.weighingMode !== 'WEIGHING_SERIES' &&
              weighing.weighingMode !== 'DOUBLE_WEIGHING_SERIES' && (
                <>
                  <div className="col-10 mt-2">
                    <div className="field flex mb-0">
                      <label htmlFor="updateTare">{t('updateTare')}</label>
                      <div className="ml-3">
                        <InputSwitch
                          id="updateTare"
                          checked={formik.values.updateTare}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            <Measurements
              formik={formik}
              maxInputNumberValue={getMaxInputNumberValue(unit!)}
              inputScales={inputScales}
              isSubmitClicked={isSubmitClicked}
            />
            <AdditionalFieldsPlugin weighing={weighing} formikInstance={formik} />
            <BdoPlugin
              weighing={weighing}
              formikInstance={formik}
              canEnable={canEnableBdoPlugin}
              kpoWeighings={kpoWeighings}
            />
            <CustomDivider />
            {!isCombine && (
              <ContainersForm
                formik={formik}
                containers={containers}
                updateFormState={() => undefined}
                width={'58vw'}
              />
            )}
          </div>
          {formik.values.id && !isCombine ? <HistoryPlugin weighingId={weighing.id} /> : <></>}
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};

const getBdoCard = async (cardId: string, cardType: string) => {
  const searchMethods = {
    kpo: async () => {
      return { kpoId: cardId, ...(await BdoApiService.KPO_getPrintingPageData(cardId)).data };
    },
    'kpok-receive': async () => {
      return { kpokId: cardId, ...(await BdoApiService.KPOK_getPrintingPageDataReceive(cardId)).data };
    },
    'kpok-transfer': async () => {
      return { kpokId: cardId, ...(await BdoApiService.KPOK_getPrintingPageDataTransfer(cardId)).data };
    },
  };

  return searchMethods[cardType as keyof typeof searchMethods]();
};

const areExistedDifferences = (weighing: any, vehicle: any) =>
  weighing?.contractor?.id !== vehicle?.defaultContractor?.id ||
  weighing?.driver?.id !== vehicle?.defaultDriver?.id ||
  weighing?.product?.id !== vehicle?.defaultProduct?.id;

const getMaxInputNumberValue = (unit: string) => {
  const maxValues = {
    kg: 999999,
    Mg: 999,
    t: 999,
  };
  return maxValues[unit as keyof typeof maxValues];
};

const createEmptyMeasurement = (additionalFields: any) => ({
  timestamp: new Date(),
  indication: null,
  scale: { name: '' },
  type: 'DECLARED',
  isConfirmed: false,
  alibi: '',
  additionalFields,
});

const prepareInitialMeasurements = async (weighing: Weighing, isCombine: boolean, additionalFields: any) => {
  let result = [];

  if (!isCombine && !weighing.id) {
    result = [createEmptyMeasurement(additionalFields), createEmptyMeasurement(additionalFields)];
  } else if (!!weighing?.measurements) {
    result = weighing.measurements;
  } else {
    result = [];
  }

  return result;
};

export default WeighingDialog;
