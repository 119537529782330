import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useRef } from 'react';
import FormErrorMessage from '../../../../../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../../../../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';

interface EditDialogProps {
  visible: boolean;
  closeDialog: (a: boolean, b: any) => void;
  field: any;
}

const CLEAR_TRANSACTION_TYPE = {
  id: null,
  isUsed: false,
  name: '',
};

const EditDialog = ({ visible, closeDialog, field }: EditDialogProps) => {
  const { t } = useTranslation('managementSystemConfigurationWeighing');
  const ref = useRef(null);

  const handleSubmit = () => {
    closeDialog(true, formik.values);
  };

  const handleClose = () => {
    closeDialog(false, null);
  };

  const validateMethod = (data: any) => {
    const errors: any = {};

    if (!data.name) {
      errors.name = t('editDialogErrorsName');
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: field ? { ...CLEAR_TRANSACTION_TYPE, ...field } : CLEAR_TRANSACTION_TYPE,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const dialogFooter = (
    <>
      <Button type="button" onClick={handleClose} label={t('cancelButton')} />
      <Button type="button" onClick={formik.submitForm} label={t('confirmButton')} />
    </>
  );

  const handleChange = (fieldName: any, value: any) => {
    formik.setFieldValue(fieldName, value, true);
  };

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={visible}
        header={field ? t('editDialogHeaderEdit') : t('editDialogHeaderAdd')}
        modal
        className="p-fluid w-40vw"
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        footer={dialogFooter}
        onHide={handleClose}
      >
        <form>
          <div className="col-12" ref={ref}>
            <div className="field">
              <label htmlFor="name">{t('editDialogFieldsName')}</label>
              <InputText id="name" value={formik.values.name} onChange={(e) => handleChange('name', e.target.value)} />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};

export default EditDialog;
