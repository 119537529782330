import axios from 'axios';
import { Webhook } from '../../types/webhook';

class WebhooksService {
  async getWebhooks() {
    return axios.get('/webhooks-configuration');
  }
  async createWebhook(webhook: Webhook) {
    return axios.post(`/webhooks-configuration`, webhook);
  }
  async updateWebhook(webhook: Webhook) {
    return axios.put(`/webhooks-configuration`, webhook);
  }
  async deleteWebhook(id: string) {
    return axios.delete(`/webhooks-configuration`, {
      data: {
        id,
      },
    });
  }
}
const webhooksService = new WebhooksService();
export default webhooksService;
