import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useRef } from 'react';
import DataTableFilterElements from '../_shared/DataTableFilterElements';
import getPaginatorTemplate from '../../utils/getPaginatorTemplate';
import { useTranslation } from 'react-i18next';
import { formatStringToDate } from '../../utils/formatUtils';
import { changeFilter, handleClickClearFilters, handleSort } from './helpers';

import { CustomTableField } from '../_shared/CustomComponents';
import { AccessControlArrivalNotificationsParams } from './types';
import { Contractor, Driver, Product, Vehicle } from '../../types/weighing';
import { ReducerState } from '../../types/reducer-state';
import { useSelector } from 'react-redux';
import { Trailer } from '../../types/trailer';

const ColumnAny = Column as any;

const AccessControlArrivalNotification = ({
  arrivalNotificationData,
  paginatorState,
  setPaginatorState,
  paginationParameters,
  setPaginationParameters,
  filters,
  setFilters,
  contractorsData,
  driversData,
  productsData,
  vehiclesData,
  trailersData,
  openDialog,
  openDeleteDialog,
  selectedArrivalNotifications,
  setSelectedArrivalNotifications,
  fetchData,
}: AccessControlArrivalNotificationsParams) => {
  const { t } = useTranslation('accessControlArrivalNotification');
  const { weighingConfiguration } = useSelector((state: ReducerState) => state.weighing);
  const { additionalFields = [] } = weighingConfiguration || {};

  const dt = useRef(null);

  const header = (
    <div className="table-header text-2xl p-2">
      {t('header')} &#160;&#160;
      <div className="flex">
        <Button
          label={t('filter')}
          icon="pi pi-filter-slash"
          outlined
          className="mr-2"
          onClick={() => handleClickClearFilters(setFilters)}
        />
        <span>
          <DataTableFilterElements.Text
            initialValue={filters['global']}
            onChangeFilter={(value: string) => setFilters({ ...filters, global: value })}
            name={'global'}
            placeholder={t('searchPlaceholder')}
            isClearButtonVisible={filters['global'] !== '' ? true : false}
            isSearchIcon
          />
        </span>
      </div>
    </div>
  );

  const columnBodyTemplate = (rowData: any, column: any) => {
    return <CustomTableField row={rowData} col={column} withoutHeaders={undefined} />;
  };

  const columns = [
    {
      field: 'vehicle.registrationNumber',
      filterField: 'registrationNumber',
      header: t('registrationNumber'),
      type: 'subfield',
      dropdown: true,
      source: vehiclesData.map((vehicle: Vehicle) => vehicle.registrationNumber),
    },
    weighingConfiguration?.addTrailerRegistrationNumbersSupport && {
      field: 'trailer.registrationNumber',
      filterField: 'trailerRegistrationNumber',
      header: t('trailerRegistrationNumber'),
      type: 'subfield',
      dropdown: true,
      source: trailersData.map((trailer: Trailer) => trailer.registrationNumber),
    },
    { field: 'number', header: t('number'), type: 'text' },
    { field: 'declaredNetto', header: t('declaredNetto'), type: 'text' },
    {
      field: 'driver.name',
      filterField: 'driver',
      header: t('driver'),
      type: 'subfield',
      dropdown: true,
      source: driversData.map((driver: Driver) => driver.name),
    },
    {
      field: 'contractor.name',
      filterField: 'contractor',
      header: t('contractor'),
      type: 'subfield',
      dropdown: true,
      source: contractorsData.map((contractor: Contractor) => contractor.name),
    },
    {
      field: 'product.name',
      filterField: 'product',
      header: t('product'),
      type: 'subfield',
      dropdown: true,
      source: productsData.map((product: Product) => product.name),
    },
  ].filter((c) => c);

  const nonSortableColumns = ['declaredNetto'];

  const dynamicColumns = columns.map((col) => (
    <Column
      key={col.field}
      field={col.field}
      header={col.header}
      body={columnBodyTemplate}
      fieldsType={col.type}
      sortable={!nonSortableColumns.includes(col.field)}
      filter={!nonSortableColumns.includes(col.field)}
      showFilterMenu={false}
      filterElement={
        col.dropdown ? (
          <DataTableFilterElements.Dropdown
            initialValue={filters[col.field]}
            options={col.source}
            onChangeFilter={(value: any, name: string) => changeFilter(value, name, filters, setFilters)}
            name={col.filterField || col.field}
            placeholder={t('filterPlaceholder')}
          />
        ) : (
          <DataTableFilterElements.Text
            initialValue={filters[col.field]}
            onChangeFilter={(value: any, name: string) => changeFilter(value, name, filters, setFilters)}
            name={col.filterField || col.field}
            placeholder={t('filterPlaceholder')}
          />
        )
      }
    />
  ));

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <Button icon="pi pi-pencil" raised severity="success" className="mr-2" onClick={() => openDialog(rowData)} />
        <Button icon="pi pi-trash" severity="warning" raised onClick={() => openDeleteDialog(rowData)} />
      </div>
    );
  };

  const additionalFieldsBodyTemplate = (rowData: any) => {
    const containsAdditionaFields = Object.keys(rowData?.additionalFields ?? {}).length > 0;

    if (!containsAdditionaFields) {
      return (
        <>
          <span className="p-column-title">{t('name')}</span>
          {t('noConstrait')}
        </>
      );
    }

    const additionalFieldsConstrait = Object.entries(rowData.additionalFields).map((e) => (
      <>
        {additionalFields?.find((a: any) => a.id === e[0])?.name}:{e[1]}
        <br />
      </>
    ));

    return (
      <>
        <span className="p-column-title">{t('name')}</span>
        {containsAdditionaFields && additionalFieldsConstrait}
      </>
    );
  };

  return (
    <>
      <DataTable
        responsiveLayout="scroll"
        ref={dt}
        value={arrivalNotificationData}
        header={header}
        className="p-datatable-responsive"
        dataKey="id"
        sortOrder={paginationParameters.order.isAscending ? 1 : -1}
        sortField={paginationParameters.order.orderColumn}
        selectionMode="checkbox"
        selection={selectedArrivalNotifications}
        onSelectionChange={(e) => setSelectedArrivalNotifications(e.value)}
        removableSort
        rowHover
        rows={paginatorState.rows}
        paginator
        paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState) as any}
        emptyMessage={t('noData')}
        onSort={(e) => handleSort(e, paginationParameters, setPaginationParameters, fetchData)}
        stripedRows
        filterDisplay="row"
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
        {dynamicColumns}
        <ColumnAny
          field="declaredArrivalTime"
          header={t('declaredArrivalTime')}
          fieldsType={'datetime'}
          body={columnBodyTemplate}
          sortable
        />
        <Column field="additionalFields" header={t('additionalFields')} body={additionalFieldsBodyTemplate} />
        <ColumnAny
          field="validFrom"
          header={t('validFrom')}
          fieldsType={'datetime'}
          body={columnBodyTemplate}
          sortable
          filter
          showFilterMenu={false}
          filterElement={
            <DataTableFilterElements.Date
              initialValue={filters.validFrom
                ?.filter((f: any) => f)
                .map((f: any) => {
                  return formatStringToDate(f);
                })}
              onChangeFilter={(value: any, name: string) => changeFilter(value, name, filters, setFilters)}
              name={'validFrom'}
              placeholder={t('filterPlaceholder')}
            />
          }
        />
        <ColumnAny
          field="validTo"
          header={t('validTo')}
          fieldsType={'datetime'}
          body={columnBodyTemplate}
          sortable
          filter
          showFilterMenu={false}
          filterElement={
            <DataTableFilterElements.Date
              initialValue={filters.validTo
                ?.filter((f: any) => f)
                .map((f: any) => {
                  return formatStringToDate(f);
                })}
              onChangeFilter={(value: any, name: string) => changeFilter(value, name, filters, setFilters)}
              name={'validTo'}
              placeholder={t('filterPlaceholder')}
            />
          }
        />
        <ColumnAny
          field="createdAt"
          header={t('createdAt')}
          fieldsType={'datetime'}
          body={columnBodyTemplate}
          sortable
        />
        <Column body={actionBodyTemplate} className="actionBody" />
      </DataTable>
    </>
  );
};

export default AccessControlArrivalNotification;
