import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { useTranslation } from 'react-i18next';
import { WebhooksTableHeaders } from './WebhooksTableHeaders';
import { WebhookRow } from './WebhookRow';
import { Webhook } from '../../../../types/webhook';

interface WebhooksTableProps {
  fetchAPI: () => Promise<void>;
  webhooks: any[];
  setWebhook: (a: any) => void;
  setWebhookDialogOpen: (b: boolean) => void;
}

export const WebhooksTable = ({ fetchAPI, webhooks, setWebhook, setWebhookDialogOpen }: WebhooksTableProps) => {
  const { t } = useTranslation('managementWebhooks');

  return (
    <Fieldset legend={t('webhooksHeader')} className="mt-1vh">
      {webhooks.length > 0 && <WebhooksTableHeaders />}
      {webhooks?.map((field: Webhook, i: number) => (
        <WebhookRow
          field={field}
          index={i}
          fetchAPI={fetchAPI}
          setWebhook={setWebhook}
          setWebhookDialogOpen={setWebhookDialogOpen}
        />
      ))}
      <div className="reportTooltip">
        <Button
          type="button"
          label={t('add')}
          icon="pi pi-plus"
          severity="success"
          className="mt-4"
          onClick={() => setWebhookDialogOpen(true)}
        />
      </div>
    </Fieldset>
  );
};
