import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import EditDialog from './components/EditDialog';
import { useTranslation } from 'react-i18next';

interface FieldsOrderSectionProps {
  formikInstance: any;
}

const FieldsOrderSection = ({ formikInstance }: FieldsOrderSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationWeighing');
  const [editDialogVisible, setEditDialogVisible] = useState(false);

  const handleOpenDialog = () => {
    setEditDialogVisible(true);
  };

  const handleCloseDialog = () => {
    setEditDialogVisible(false);
  };

  return (
    <>
      <Fieldset legend={t('fieldsOrderSectionHeader')} className="mt-1vh">
        <Button
          type="button"
          label={t('editButton')}
          icon="pi pi-plus"
          severity="success"
          className="mt-4"
          onClick={handleOpenDialog}
        />
      </Fieldset>
      {!!editDialogVisible && (
        <EditDialog visible={editDialogVisible} closeDialog={handleCloseDialog} formikInstance={formikInstance} />
      )}
    </>
  );
};

export default FieldsOrderSection;
