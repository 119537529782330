import { useTranslation } from 'react-i18next';

const Template = ({ className, checkboxElement, filterElement, closeElement }) => {
  const { t } = useTranslation('sharedComponents');

  return (
    <div className={className}>
      {checkboxElement}
      {filterElement || t('selectAll')}
      {closeElement}
    </div>
  );
};

const multiSelectPanelHeader = (options) => {
  return (
    <Template
      className={options.className}
      checkboxElement={options.checkboxElement}
      filterElement={options.filterElement}
      closeElement={options.closeElement}
    />
  );
};

export default multiSelectPanelHeader;
