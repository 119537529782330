import { Button } from 'primereact/button';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appConfigActions } from '../../../../store/appConfig-slice';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../types/reducer-state';
import ReceiptsCustomFooterSection from './PosReceiptsCustomFooterSection';
import usePosReceiptConfigurationData from '../../../../hooks/PosReceiptsConfiguration/usePosReceiptConfigurationData';
import usePosReceiptConfigurationForm from '../../../../hooks/PosReceiptsConfiguration/usePosReceiptConfigurationForm';
import ReceiptsSettingsSection from './PosReceiptsSettingsSection';
import PosReceiptsFieldsOrderSection from './PosReceiptsFieldsOrderSection';

const PosReceiptsConfiguration = () => {
  const { t } = useTranslation('managementSystemConfigurationReceipts');

  const dispatch = useDispatch();

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const confirmationOfWindowChange = useSelector((state: ReducerState) => state.appConfig.confirmationOfWindowChange);

  const { data: receiptConfigurationData, stringifiedData, fetchData } = usePosReceiptConfigurationData();
  const { formik, resetForm } = usePosReceiptConfigurationForm(receiptConfigurationData, dispatch);

  useEffect(() => {
    fetchData();
  }, [loggedUserContext.currentCustomer?.id]);

  useEffect(() => {
    const changes = JSON.stringify(formik.values) !== stringifiedData;
    if (confirmationOfWindowChange.required !== changes) {
      dispatch(
        appConfigActions.setConfirmationOfWindowChange({
          ...confirmationOfWindowChange,
          required: changes,
          saveMethod: formik.submitForm,
        }),
      );
    }
  }, [JSON.stringify(formik.values)]);

  const handleClickSubmit = () => {
    formik.submitForm().then(() => {
      dispatch(appConfigActions.setConfirmationOfWindowChange({ ...confirmationOfWindowChange, required: false }));
    });
  };

  const handleChangeField = (e: any, type: any) => {
    if (type === 'checkbox') {
      formik.setFieldValue('shouldIncludePosReceiptCustomFooter', e.checked);

      if (!e.checked) {
        formik.setFieldValue('posReceiptCustomFooter', '');
      }
    }

    if (type === 'dropdown') {
      formik.setFieldValue(e.target.id, e.value, false);
    }

    if (type === 'textarea') {
      formik.setFieldValue('posReceiptCustomFooter', e.target.value);
    }
  };

  return (
    <div className="card pb-70px">
      <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
        <form onSubmit={formik.handleSubmit}>
          <ReceiptsCustomFooterSection formikInstance={formik} handleChangeField={handleChangeField} />
          <ReceiptsSettingsSection formikInstance={formik} handleChangeField={handleChangeField} />
          <PosReceiptsFieldsOrderSection formikInstance={formik} />

          <div>
            <div
              className="flex justify-content-end w-full p-ai-end bg-white fixed bottom-0px right-0px pt-10px pr-40px pb-10px pl-0px"
              style={{
                boxShadow: '0px 10px 15px black',
              }}
            >
              <Button type="reset" label={t('reset')} icon="pi pi-times" text onClick={() => resetForm()} />
              <Button type="button" label={t('save')} icon="pi pi-check" text onClick={handleClickSubmit} />
            </div>
          </div>
        </form>
      </FormErrorMessageScroller>
    </div>
  );
};

export default PosReceiptsConfiguration;
