import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { AutoComplete } from 'primereact/autocomplete';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from 'primereact/panel';
import { useFormik } from 'formik';
import BdoApiService from '../../../../../services/BdoApiService';
import FormErrorMessage from '../../../../_shared/FormErrorMessage';
import statusUtils from '../../components/statusUtils';
import DeleteCardDialog from './DeleteCardDialog';
import formChangeFieldMethod from '../../../../../utils/formChangeFieldMethod';
import {
  DEFAULT_DIALOG_BREAKPOINTS,
  DEFAULT_DIALOG_STYLE,
  CancelButton,
  companyItemTemplate,
  eupItemTemplate,
  searchCompanyId,
  searchEup,
  searchWasteCodeId,
  searchWasteGeneratingPlace,
  searchWasteProcess,
  SubmitButton,
  transformWasteCodeId,
  transformWasteGeneratingPlace,
  transformWasteProcessId,
  wasteCodeIdItemTemplate,
  wasteGeneratingPlaceItemTemplate,
  wasteProcessIdItemTemplate,
  prepareCorrectDateFormat,
  ClearSectionButton,
  ChangeStatusDropdown,
  limitOfDigitsAfterDecimalsFilter,
  disableDotInTextInput,
} from '../../../bdoUtils';
import * as moment from 'moment';
import FormErrorMessageScroller from '../../../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';

const INIT_FORM_STATE = {
  carrierCompanyId: null,
  receiverCompanyId: null,
  receiverEupId: null,
  wasteCodeId: null,
  vehicleRegNumber: '',
  wasteMass: '',
  plannedTransportTime: new Date(Date.now()),
  plannedTransportTimeDate: new Date(Date.now()),
  wasteProcessId: null,
  certificateNumberAndBoxNumbers: '',
  additionalInfo: '',
  wasteCodeExtended: false,
  wasteCodeExtendedDescription: '',
  hazardousWasteReclassification: false,
  hazardousWasteReclassificationDescription: '',
  isWasteGenerating: false,
  wasteGeneratedTerytPk: null,
  wasteGeneratingAdditionalInfo: '',
};

const EditPlannedCardDialog = ({ visible, closeDialog, item }) => {
  const { t } = useTranslation('kpoSenderComponents');

  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [receiverEupIdOptions, setReceiverEupIdOptions] = useState([]);
  const [wasteProcesses, setWasteProcesses] = useState([]);
  const [currentEupInfo, setCurrentEupInfo] = useState(null);
  const [currentSenderCompanyInfo, setCurrentSenderCompanyInfo] = useState(null);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  const CHANGE_STATUS_OPTIONS = [
    { label: t('approve'), value: 'APPROVED' },
    { label: t('approveAndGenerateConfirmation'), value: 'APPROVED_AND_GENERATE_CONFIRMATION' },
    { label: t('delete'), value: 'DELETE' },
  ];

  useEffect(() => {
    BdoApiService.getCurrentEupInfo().then((eupResponse) => {
      setCurrentEupInfo(eupResponse.data);
      BdoApiService.getCompany(eupResponse.data.companyId).then((companyResponse) => {
        setCurrentSenderCompanyInfo(companyResponse.data);
      });
    });
    BdoApiService.getWasteProcesses().then((response) => {
      setWasteProcesses(response.data.length ? response.data : []);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialValues = useCallback(async () => {
    const response = await BdoApiService.KPO_getPlannedCard(item.kpoId, 'Sender');

    const {
      carrierCompanyId,
      receiverCompanyId,
      receiverEupId,
      wasteCodeId,
      vehicleRegNumber,
      wasteMass,
      plannedTransportTime,
      wasteProcessId,
      certificateNumberAndBoxNumbers,
      additionalInfo,
      wasteCodeExtended,
      wasteCodeExtendedDescription,
      hazardousWasteReclassification,
      hazardousWasteReclassificationDescription,
      isWasteGenerating,
      wasteGeneratedTerytPk,
      wasteGeneratingAdditionalInfo,
      year,
      cardNumber,
      cardStatus,
    } = response.data;

    const initValuesObj = {
      ...INIT_FORM_STATE,
      vehicleRegNumber,
      wasteMass,
      plannedTransportTime: new Date(plannedTransportTime),
      plannedTransportTimeDate: new Date(plannedTransportTime),
      certificateNumberAndBoxNumbers,
      additionalInfo,
      wasteCodeExtended,
      hazardousWasteReclassification,
      isWasteGenerating,
      year,
      cardNumber,
      cardStatus,
    };
    if (wasteCodeExtended) {
      initValuesObj.wasteCodeExtendedDescription = wasteCodeExtendedDescription;
    }
    if (hazardousWasteReclassification) {
      initValuesObj.hazardousWasteReclassificationDescription = hazardousWasteReclassificationDescription;
    }
    if (isWasteGenerating) {
      const wasteGeneratedTerytPkResponse = await BdoApiService.getCommune(wasteGeneratedTerytPk);
      initValuesObj.wasteGeneratedTerytPk = transformWasteGeneratingPlace(wasteGeneratedTerytPkResponse.data);

      initValuesObj.wasteGeneratingAdditionalInfo = wasteGeneratingAdditionalInfo;
    }
    const wasteProcessIdResponse = await BdoApiService.getWasteProcesses();
    initValuesObj.wasteProcessId = transformWasteProcessId(
      wasteProcessIdResponse.data.find((el) => el.wasteProcessId === wasteProcessId),
    );

    const wasteCodeIdResponse = await BdoApiService.getWasteCodeByWasteCodeId(wasteCodeId);
    initValuesObj.wasteCodeId = transformWasteCodeId(wasteCodeIdResponse.data);

    const carrierCompanyIdResponse = await BdoApiService.getCompany(carrierCompanyId);
    initValuesObj.carrierCompanyId = carrierCompanyIdResponse.data;

    const receiverCompanyIdResponse = await BdoApiService.getCompany(receiverCompanyId);
    initValuesObj.receiverCompanyId = receiverCompanyIdResponse.data;

    const receiverEupIdResponse = await BdoApiService.getEup(receiverEupId);
    initValuesObj.receiverEupId = receiverEupIdResponse.data;

    setInitFormValues(initValuesObj);
    formik.setTouched('plannedTransportTime');
    formik.setTouched('plannedTransportTimeDate');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [INIT_FORM_STATE, item]);

  useEffect(() => {
    if (visible) {
      getInitialValues();
    }
  }, [getInitialValues, visible]);

  const validateMethod = (data) => {
    const errors = {};

    if (!data.carrierCompanyId) {
      errors.carrierCompanyId = t('carrierCompanyIdNotProvided');
    }

    if (!data.receiverCompanyId) {
      errors.receiverCompanyId = t('receiverCompanyIdNotProvided');
    }

    if (data.receiverCompanyId && !data.receiverEupId) {
      errors.receiverEupId = t('receiverEupIdNotProvided');
    }

    if (!data.wasteCodeId) {
      errors.wasteCodeId = t('wasteCodeIdNotProvided');
    }

    if (!data.wasteMass) {
      errors.wasteMass = t('wasteMassNotProvided');
    }

    if (!data.vehicleRegNumber) {
      errors.vehicleRegNumber = t('vehicleRegNumberNotProvided');
    }

    if (!data.plannedTransportTimeDate) {
      errors.plannedTransportTimeDate = t('plannedTransportTimeDateNotProvided');
    } else {
      const plannedTransportTimeDateTime = moment(data.plannedTransportTimeDate)
        .set('hour', moment(data.plannedTransportTime).get('hours'))
        .set('minutes', moment(data.plannedTransportTime).get('minutes'));
      if (moment(plannedTransportTimeDateTime.toDate()).isBefore(new Date(Date.now()))) {
        errors.plannedTransportTimeDate = t('plannedTransportTimeDatePastDate');
      }
    }

    if (!data.plannedTransportTime) {
      errors.plannedTransportTime = t('plannedTransportTimeNotProvided');
    }

    if (data.isWasteGenerating && !data.wasteGeneratedTerytPk) {
      errors.wasteGeneratedTerytPk = t('wasteGeneratedTerytPkNotProvided');
    }

    if (data.wasteCodeExtended && !data.wasteCodeExtendedDescription) {
      errors.wasteCodeExtendedDescription = t('wasteCodeExtendedDescriptionNotProvided');
    }

    return errors;
  };

  const handleCloseDialog = (changes, statusFilter) => {
    formik.resetForm({
      values: INIT_FORM_STATE,
    });
    closeDialog(changes === true, statusFilter);
  };

  const saveChanges = async (data) => {
    let resultBody = {
      kpoId: item.kpoId,
      carrierCompanyId: data.carrierCompanyId?.companyId || data.carrierCompanyId,
      receiverCompanyId: data.receiverCompanyId?.companyId || data.receiverCompanyId,
      receiverEupId: data.receiverEupId.eupId,
      wasteCodeId: data.wasteCodeId?.wasteCodeId || data.wasteCodeId,
      vehicleRegNumber: data.vehicleRegNumber || '',
      wasteMass: parseFloat(data.wasteMass) || 0,
      plannedTransportTime: prepareCorrectDateFormat(data.plannedTransportTimeDate, data.plannedTransportTime),
      wasteProcessId: data.wasteProcessId?.wasteProcessId || data.wasteProcessId,
      certificateNumberAndBoxNumbers: data.certificateNumberAndBoxNumbers || '',
      additionalInfo: data.additionalInfo || '',
      wasteCodeExtended: data.wasteCodeExtended,
      wasteCodeExtendedDescription: data.wasteCodeExtendedDescription || '',
      hazardousWasteReclassification: data.hazardousWasteReclassification,
      hazardousWasteReclassificationDescription: data.hazardousWasteReclassificationDescription || '',
    };

    resultBody = data.isWasteGenerating
      ? {
          ...resultBody,
          isWasteGenerating: false,
          wasteGeneratedTerytPk: data.wasteGeneratedTerytPk?.PK || data.wasteGeneratedTerytPk,
          wasteGeneratingAdditionalInfo: data.wasteGeneratingAdditionalInfo || '',
        }
      : resultBody;

    await BdoApiService.KPO_updatePlannedCard(resultBody);
  };

  const handleSubmit = async (data) => {
    await saveChanges(data);
    handleCloseDialog(true);
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (formik.values.receiverCompanyId?.registrationNumber) {
      BdoApiService.getCompanyEups(formik.values.receiverCompanyId.companyId).then((response) => {
        setReceiverEupIdOptions(response.data?.length ? response.data : []);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.receiverCompanyId]);

  const handleChangeStatus = (e) => {
    const METHODS = {
      APPROVED: () =>
        formik.validateForm().then((errors) => {
          if (Object.keys(errors).length > 0) {
            formik.setTouched({ ...formik.touched, ...errors });
          } else {
            saveChanges(formik.values).then(() => {
              statusUtils.changeStatusMethods.sender.APPROVED({ kpoId: item.kpoId }).then(() => {
                handleCloseDialog(true, 'APPROVED');
              });
            });
          }
        }),
      APPROVED_AND_GENERATE_CONFIRMATION: () =>
        formik.validateForm().then((errors) => {
          if (Object.keys(errors).length > 0) {
            formik.setTouched({ ...formik.touched, ...errors });
          } else {
            saveChanges(formik.values).then(() => {
              statusUtils.changeStatusMethods.sender.APPROVED({ kpoId: item.kpoId }).then(() => {
                statusUtils.changeStatusMethods.sender.CONFIRMATION_GENERATED({ kpoId: item.kpoId }).then(() => {
                  handleCloseDialog(true, 'CONFIRMATION_GENERATED');
                });
              });
            });
          }
        }),
      DELETE: () => setIsOpenDeleteDialog(true),
    };

    METHODS[e.value] && METHODS[e.value]();
  };

  const dialogFooter = (
    <div className="grid justify-content-center">
      <CancelButton onClick={handleCloseDialog} />
      <SubmitButton onClick={formik.submitForm} />
      <ChangeStatusDropdown options={CHANGE_STATUS_OPTIONS} onChange={handleChangeStatus} />
    </div>
  );

  const handleChangeField = (e, type) => {
    formChangeFieldMethod(e, type, formik);

    if (!formik.values.isWasteGenerating) {
      formik.setFieldValue('wasteGeneratedTerytPk', INIT_FORM_STATE.wasteGeneratedTerytPk, false);
      formik.setFieldValue('wasteGeneratingAdditionalInfo', INIT_FORM_STATE.wasteGeneratingAdditionalInfo, false);
    }
    if (!formik.values.wasteCodeExtended) {
      formik.setFieldValue('wasteCodeExtendedDescription', INIT_FORM_STATE.wasteCodeExtendedDescription, false);
    }
    if (!formik.values.hazardousWasteReclassification) {
      formik.setFieldValue(
        'hazardousWasteReclassificationDescription',
        INIT_FORM_STATE.hazardousWasteReclassificationDescription,
        false,
      );
    }
    if (!formik.values.receiverCompanyId) {
      formik.setFieldValue('receiverEupId', INIT_FORM_STATE.receiverEupId, false);
    }
  };

  const handleCloseDeleteDialog = (changes) => {
    setIsOpenDeleteDialog(false);
    if (changes) {
      handleCloseDialog(true);
    }
  };

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={visible}
        header={t('editPlannedCardDialogHeader')}
        modal
        className="p-fluid"
        breakpoints={DEFAULT_DIALOG_BREAKPOINTS}
        style={DEFAULT_DIALOG_STYLE}
        footer={dialogFooter}
        onHide={handleCloseDialog}
      >
        <form>
          <Panel header=" " className="pt-1vh">
            <div className="p-fluid formgrid grid">
              <div className="field col-4">
                <label>{t('calendar')}</label>
                <p>
                  <b>{initFormValues?.year || '-'}</b>
                </p>
              </div>
              <div className="field col-4">
                <label>{t('cardNumber')}</label>
                <p>
                  <b>{initFormValues?.cardNumber || '-'}</b>
                </p>
              </div>
              <div className="field col-4">
                <label>{t('cardStatus')}</label>
                <p>
                  <b>{initFormValues?.cardStatus || '-'}</b>
                </p>
              </div>
            </div>
          </Panel>

          <Panel header={t('senderData')} toggleable className="pt-1vh">
            <div className="p-fluid formgrid grid pt-1vh">
              <div className="field col-4">
                <label>{t('senderRegistrationNumber')}</label>
                <p>
                  <b>{currentSenderCompanyInfo?.registrationNumber || '-'}</b>
                </p>
              </div>
              <div className="field col-4">
                <label>{t('senderIdentificationNumber')}</label>
                <p>
                  <b>{currentSenderCompanyInfo?.nip || '-'}</b>
                </p>
              </div>
              <div className="field col-4">
                <label>{t('identificationNumberEu')}</label>
                <p>
                  <b>{currentSenderCompanyInfo?.nipEu || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('senderName')}</label>
                <p>
                  <b>{currentSenderCompanyInfo?.name || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('senderAddress')}</label>
                <p>
                  {currentSenderCompanyInfo?.address
                    ? currentSenderCompanyInfo?.address
                        .replaceAll(': ', ':<b> ')
                        .split(', ')
                        .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                    : '-'}
                </p>
              </div>
              <div className="field col-4">
                <label>{t('senderPlaceNumber')}</label>
                <p>
                  <b>{currentEupInfo?.registrationNumber || '-'}</b>
                </p>
              </div>
              <div className="field col-8">
                <label>{t('senderPlaceName')}</label>
                <p>
                  <b>{currentEupInfo?.name || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('senderPlaceAddress')}</label>
                <p>
                  {currentEupInfo?.address
                    ? currentEupInfo?.address
                        .replaceAll(': ', ':<b> ')
                        .split(', ')
                        .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                    : '-'}
                </p>
              </div>
              <div className="field-checkbox col-12">
                <Checkbox
                  id="isWasteGenerating"
                  checked={formik.values.isWasteGenerating}
                  onChange={(e) => handleChangeField(e, 'checkbox')}
                ></Checkbox>
                <label htmlFor="isWasteGenerating" className="p-checkbox-label">
                  {t('wasteInfo')}
                </label>
                <FormErrorMessage fieldName="isWasteGenerating" formikInstance={formik} />
              </div>
              <div className="field col-12">
                <label htmlFor="wasteGeneratedTerytPk">
                  {t('wasteGeneratedTeryt')}
                  {formik.values.isWasteGenerating ? <span className="text-red-500">&nbsp;*</span> : ''}
                </label>
                <AutoComplete
                  id="wasteGeneratedTerytPk"
                  field="fulltext"
                  value={formik.values.wasteGeneratedTerytPk}
                  suggestions={autocompleteOptions}
                  completeMethod={(event) => searchWasteGeneratingPlace(event, setAutocompleteOptions)}
                  onChange={(e) => handleChangeField(e, 'autocomplete')}
                  forceSelection
                  itemTemplate={wasteGeneratingPlaceItemTemplate}
                  disabled={!formik.values.isWasteGenerating}
                />
                <FormErrorMessage fieldName="wasteGeneratedTerytPk" formikInstance={formik} />
              </div>
              <div className="field col-12">
                <label htmlFor="wasteGeneratingAdditionalInfo">{t('wasteGeneratingAdditionalInfo')}</label>
                <InputText
                  id="wasteGeneratingAdditionalInfo"
                  value={formik.values.wasteGeneratingAdditionalInfo}
                  onChange={(e) => handleChangeField(e, 'text')}
                  disabled={!formik.values.isWasteGenerating}
                />
                <FormErrorMessage fieldName="wasteGeneratingAdditionalInfo" formikInstance={formik} />
              </div>
            </div>
          </Panel>

          <Panel header={t('transporterData')} toggleable className="pt-1vh">
            <div className="p-fluid formgrid grid">
              <div className="field col-4">
                <label htmlFor="carrierCompanyId">
                  {t('transporterRegistrationNumber')}
                  <span className="text-red-500">&nbsp;*</span>
                </label>
                <AutoComplete
                  id="carrierCompanyId"
                  field="registrationNumber"
                  value={formik.values.carrierCompanyId}
                  suggestions={autocompleteOptions}
                  completeMethod={(event) => searchCompanyId(event, setAutocompleteOptions)}
                  onChange={(e) => handleChangeField(e, 'autocomplete')}
                  forceSelection
                  itemTemplate={(item) => companyItemTemplate(item, t)}
                  placeholder={t('search')}
                />
                <FormErrorMessage fieldName="carrierCompanyId" formikInstance={formik} />
              </div>
              <div className="field col-4">
                <label>{t('transporterIdentificationNumber')}</label>
                <p>
                  <b>{formik.values.carrierCompanyId?.nip || '-'}</b>
                </p>
              </div>
              <div className="field col-4">
                <label>{t('identificationNumberEu')}</label>
                <p>
                  <b>{formik.values.carrierCompanyId?.nipEu || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('transporterName')}</label>
                <p>
                  <b>{formik.values.carrierCompanyId?.name || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('transporterAddress')}</label>
                <p>
                  {formik.values.carrierCompanyId?.address
                    ? formik.values.carrierCompanyId?.address
                        .replaceAll(': ', ':<b> ')
                        .split(', ')
                        .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                    : '-'}
                </p>
              </div>
              {formik.values.carrierCompanyId ? (
                <div className="field col-12">
                  <ClearSectionButton onClick={() => formik.setFieldValue('carrierCompanyId', null, false)} />
                </div>
              ) : (
                <></>
              )}
            </div>
          </Panel>
          <Panel header={t('receiverData')} toggleable className="pt-1vh">
            <div className="p-fluid formgrid grid">
              <div className="field col-4">
                <label htmlFor="receiverCompanyId">
                  {t('receiverRegistrationNumber')}
                  <span className="text-red-500">&nbsp;*</span>
                </label>
                <AutoComplete
                  id="receiverCompanyId"
                  field="registrationNumber"
                  value={formik.values.receiverCompanyId}
                  suggestions={autocompleteOptions}
                  completeMethod={(event) => searchCompanyId(event, setAutocompleteOptions)}
                  onChange={(e) => handleChangeField(e, 'autocomplete')}
                  forceSelection
                  itemTemplate={(item) => companyItemTemplate(item, t)}
                  placeholder={t('search')}
                />
                <FormErrorMessage fieldName="receiverCompanyId" formikInstance={formik} />
              </div>
              <div className="field col-4">
                <label>{t('receiverIdentificationNumber')}</label>
                <p>
                  <b>{formik.values.receiverCompanyId?.nip || '-'}</b>
                </p>
              </div>
              <div className="field col-4">
                <label>{t('identificationNumberEu')}</label>
                <p>
                  <b>{formik.values.receiverCompanyId?.nipEu || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('receiverName')}</label>
                <p>
                  <b>{formik.values.receiverCompanyId?.name || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('receiverAddress')}</label>
                <p>
                  {formik.values.receiverCompanyId?.address
                    ? formik.values.receiverCompanyId?.address
                        .replaceAll(': ', ':<b> ')
                        .split(', ')
                        .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                    : '-'}
                </p>
              </div>
              <div className="field col-4">
                <label htmlFor="receiverEupId">
                  {t('receiverPlaceNumber')}
                  <span className="text-red-500">&nbsp;*</span>
                </label>
                <AutoComplete
                  id="receiverEupId"
                  field="registrationNumber"
                  dropdown
                  value={formik.values.receiverEupId}
                  suggestions={autocompleteOptions}
                  completeMethod={(event) => searchEup(event, setAutocompleteOptions, receiverEupIdOptions)}
                  onChange={(e) => handleChangeField(e, 'autocomplete')}
                  forceSelection
                  itemTemplate={(item) => eupItemTemplate(item, t)}
                  placeholder={t('search')}
                  disabled={!formik.values.receiverCompanyId}
                />
                <FormErrorMessage fieldName="receiverEupId" formikInstance={formik} />
              </div>
              <div className="field col-8">
                <label>{t('receiverPlaceName')}</label>
                <p>
                  <b>{formik.values.receiverEupId?.name || '-'}</b>
                </p>
              </div>
              <div className="field col-12">
                <label>{t('receiverPlaceAddress')}</label>
                <p>
                  {formik.values.receiverEupId?.address
                    ? formik.values.receiverEupId?.address
                        .replaceAll(': ', ':<b> ')
                        .split(', ')
                        .map((el) => <span key={el} dangerouslySetInnerHTML={{ __html: `</b>${el}<br/>` }} />)
                    : '-'}
                </p>
              </div>
              {formik.values.receiverCompanyId || formik.values.receiverEupId ? (
                <div className="field col-12">
                  <ClearSectionButton
                    onClick={() => {
                      formik.setFieldValue('receiverCompanyId', null, false);
                      formik.setFieldValue('receiverEupId', null, false);
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </Panel>
          <Panel header={t('wasteData')} toggleable className="pt-1vh">
            <div className="p-fluid formgrid grid">
              <div className="field col-12">
                <label htmlFor="wasteProcessId">{t('wasteProcessType')}</label>
                <AutoComplete
                  id="wasteProcessId"
                  field="fulltext"
                  value={formik.values.wasteProcessId}
                  suggestions={autocompleteOptions}
                  completeMethod={(event) => searchWasteProcess(event, wasteProcesses, setAutocompleteOptions)}
                  onChange={(e) => handleChangeField(e, 'autocomplete')}
                  forceSelection
                  itemTemplate={wasteProcessIdItemTemplate}
                />
                <FormErrorMessage fieldName="wasteProcessId" formikInstance={formik} />
              </div>
              <div className="field col-12">
                <label htmlFor="wasteCodeId">
                  {t('wasteCodeAndType')}
                  <span className="text-red-500">&nbsp;*</span>
                </label>
                <AutoComplete
                  id="wasteCodeId"
                  field="fulltext"
                  value={formik.values.wasteCodeId}
                  suggestions={autocompleteOptions}
                  completeMethod={(event) => searchWasteCodeId(event, setAutocompleteOptions)}
                  onChange={(e) => handleChangeField(e, 'autocomplete')}
                  forceSelection
                  itemTemplate={wasteCodeIdItemTemplate}
                  placeholder={t('search')}
                />
                <FormErrorMessage fieldName="wasteCodeId" formikInstance={formik} />
              </div>
              <div className="field-checkbox col-4">
                <Checkbox
                  id="wasteCodeExtended"
                  checked={formik.values.wasteCodeExtended}
                  onChange={(e) => handleChangeField(e, 'checkbox')}
                ></Checkbox>
                <label htmlFor="wasteCodeExtended" className="checkbox-label">
                  {t('wasteCodeExtended')}
                </label>
                <FormErrorMessage fieldName="wasteCodeExtended" formikInstance={formik} />
              </div>
              <div className="field col-8">
                <label htmlFor="wasteCodeExtendedDescription">{t('wasteTypeExtended')}</label>
                <InputText
                  id="wasteCodeExtendedDescription"
                  value={formik.values.wasteCodeExtendedDescription || ''}
                  onChange={(e) => handleChangeField(e, 'text')}
                  disabled={!formik.values.wasteCodeExtended}
                />
                <FormErrorMessage fieldName="wasteCodeExtendedDescription" formikInstance={formik} />
              </div>
              <div className="field-checkbox col-4">
                <Checkbox
                  id="hazardousWasteReclassification"
                  checked={formik.values.hazardousWasteReclassification}
                  onChange={(e) => handleChangeField(e, 'checkbox')}
                ></Checkbox>
                <label htmlFor="hazardousWasteReclassification" className="checkbox-label">
                  {t('hazardousWasteReclassification')}
                </label>
                <FormErrorMessage fieldName="hazardousWasteReclassification" formikInstance={formik} />
              </div>
              <div className="field col-12">
                <label htmlFor="certificateNumberAndBoxNumbers">{t('certificateNumberAndBoxNumbers')}</label>
                <InputText
                  id="certificateNumberAndBoxNumbers"
                  value={formik.values.certificateNumberAndBoxNumbers}
                  onChange={(e) => handleChangeField(e, 'text')}
                />
                <FormErrorMessage fieldName="certificateNumberAndBoxNumbers" formikInstance={formik} />
              </div>
              <div className="field col-12">
                <label htmlFor="wasteMass">
                  {t('wasteMass')}
                  <span className="text-red-500">&nbsp;*</span>
                </label>
                <InputText
                  id="wasteMass"
                  value={formik.values.wasteMass || ''}
                  onKeyDown={(e) => disableDotInTextInput(e)}
                  onInput={(e) => limitOfDigitsAfterDecimalsFilter(e, 4)}
                  onChange={(e) => handleChangeField(e, 'text')}
                  type="number"
                />
                <FormErrorMessage fieldName="wasteMass" formikInstance={formik} />
              </div>
              <div className="field col-12">
                <label htmlFor="vehicleRegNumber">
                  {t('vehicleRegistrationNumber')}
                  <span className="text-red-500">&nbsp;*</span>
                </label>
                <InputText
                  id="vehicleRegNumber"
                  value={formik.values.vehicleRegNumber || ''}
                  onChange={(e) =>
                    void formik.setFieldValue('vehicleRegNumber', (e?.target?.value ?? e?.value)?.toUpperCase())
                  }
                />
                <FormErrorMessage fieldName="vehicleRegNumber" formikInstance={formik} />
              </div>
              <div className="field col-12 lg:col-6">
                <label htmlFor="plannedTransportTimeDate">
                  {t('plannedTransportDate')}
                  <span className="text-red-500">&nbsp;*</span>
                </label>
                <Calendar
                  inline
                  id="plannedTransportTimeDate"
                  value={formik.values.plannedTransportTimeDate}
                  onChange={(e) => handleChangeField(e, 'date')}
                  dateFormat="yy-mm-dd"
                  placeholder={t('select')}
                  footerTemplate={() => <></>}
                  minDate={new Date(Date.now())}
                />
                <FormErrorMessage fieldName="plannedTransportTimeDate" formikInstance={formik} />
              </div>
              <div className="field col-12 lg:col-6">
                <label htmlFor="plannedTransportTime">
                  {t('plannedTransportTime')}
                  <span className="text-red-500">&nbsp;*</span>
                </label>
                <Calendar
                  inline
                  id="plannedTransportTime"
                  value={formik.values.plannedTransportTime}
                  onChange={(e) => formik.setFieldValue(e.target.id, e.value, false)}
                  timeOnly
                  hourFormat="24"
                  placeholder={t('select')}
                />
                <FormErrorMessage fieldName="plannedTransportTime" formikInstance={formik} />
              </div>
            </div>
          </Panel>

          <Panel header={t('additionalInfo')} toggleable className="pt-1vh">
            <div className="p-fluid formgrid grid">
              <div className="field col-12">
                <label htmlFor="additionalInfo">{t('additionalInfo')}</label>
                <InputTextarea
                  id="additionalInfo"
                  value={formik.values.additionalInfo}
                  onChange={(e) => handleChangeField(e, 'textarea')}
                  rows={5}
                  autoResize
                />
                <FormErrorMessage fieldName="additionalInfo" formikInstance={formik} />
              </div>
            </div>
          </Panel>

          <br />
          <br />
          <br />
        </form>
      </Dialog>
      <DeleteCardDialog visible={isOpenDeleteDialog} closeDialog={handleCloseDeleteDialog} kpoId={item?.kpoId} />
    </FormErrorMessageScroller>
  );
};

export default EditPlannedCardDialog;
