import axios from 'axios';
import moment from 'moment';
import { formatStringToDate } from '../../utils/formatUtils';
import { Weighing } from '../../types/weighing';

class WeighingService {
  async searchOpenWeighings(showDeletedWeighings: boolean, requestConfig: any) {
    return axios.post(`/weighing-state/open-weighings`, { showDeletedWeighings }, requestConfig);
  }

  async searchNotConfirmedWeighings(showDeletedWeighings: boolean, requestConfig: any) {
    return axios.post(`/weighing-state/not-confirmed-weighings`, { showDeletedWeighings }, requestConfig);
  }

  async getVehicleWeighingState(vehicleId: string, trailerId: string | null) {
    return axios.get(`/weighing-state?vehicle_id=${vehicleId}${trailerId ? `&trailer_id=${trailerId}` : ''}`);
  }

  async closeWeighing(weighingId: string) {
    return axios.post(`/weighing-state/open-weighings/${weighingId}/close`);
  }

  async searchWeighings(filters: any, paginatorParam: any, showDeletedWeighings: boolean) {
    return axios.post(`/weighings/search`, {
      filters: this.formatSearchFilters(filters),
      paginatorParam,
      showDeletedWeighings,
    });
  }

  async getWeighing(id: string) {
    return axios.get(`/weighings/${id}`);
  }

  async getWeighingReceipt(weighingId: string) {
    return axios.get(`/weighings/${weighingId}/receipt`, {
      responseType: 'arraybuffer',
      loader_timeout: false,
      headers: {
        Accept: 'application/pdf',
      },
    });
  }

  async getWeighingReceipts(weighingIds: string[]) {
    return axios.post(`/weighings/receipts/bulk-requests`, weighingIds, {
      responseType: 'arraybuffer',
      loader_timeout: false,
      headers: {
        Accept: 'application/pdf',
      },
    });
  }

  async combineWeighings(combinedWeighing: Weighing, weighingToPull: Weighing) {
    return axios.post('/weighings/combine-weighings', {
      data: {
        combinedWeighing,
        weighingToPull,
      },
    });
  }

  async createManualWeighing(weighing: string) {
    return axios.post('/weighings', { data: weighing });
  }

  async sendWeighingReceipts(weighingIds: string[], emailAddresses: string[]) {
    return axios.post(`/weighings/receipt-email`, { weighingIds: weighingIds, emailAddresses: emailAddresses });
  }

  async editWeighing(postData: any, id: string) {
    return axios.put(`/weighings/${id}`, {
      newWeighing: postData,
    });
  }
  async removeWeighing(id: string) {
    return axios.delete(`/weighings/${id}`);
  }
  async restoreWeighing(id: string) {
    return axios.post(`/weighings/restore`, { weighingId: id });
  }
  async getWeighingEditHistory(id: string) {
    return axios.get(`/weighings-history/${id}`);
  }
  async bindWeighingWithCard(weighingId: string, cardId: string, cardType: string) {
    return axios.post(`/weighings/bind-card`, { weighingId, cardId, cardType });
  }
  async getWeighingReport(
    fileFormat: string,
    filters: any,
    checkedStandardColumns: any,
    checkedAdditionalColumns: any,
    isHorizontal: boolean,
    paginationOrder: any,
  ) {
    return axios.post(
      `/weighings/report`,
      {
        filters: this.formatSearchFilters(filters),
        fileFormat,
        checkedStandardColumns,
        checkedAdditionalColumns,
        isHorizontal,
        paginationOrder,
      },
      {
        responseType: 'arraybuffer',
      },
    );
  }
  async sendWeighingReport(
    fileFormat: string,
    filters: any,
    checkedStandardColumns: (string | null)[],
    checkedAdditionalColumns: string[],
    emailAddresses: string[],
    isHorizontal: boolean,
    paginationOrder: any,
  ) {
    return axios.post(`/weighings/report-email`, {
      filters: this.formatSearchFilters(filters),
      fileFormat,
      checkedStandardColumns,
      checkedAdditionalColumns,
      emailAddresses,
      isHorizontal,
      paginationOrder,
    });
  }
  async getWeighingNetto(id: string, unit: string) {
    return axios.get(`/weighings/netto`, { params: { id, unit } });
  }

  formatSearchFilters(filters: any) {
    const endOfDayCustom = (date: Date) => {
      return moment(date)
        .endOf('day')
        .hours(23)
        .minutes(59)
        .seconds(59)
        .milliseconds(999)
        .format('YYYY-MM-DD HH:mm:ss.SSS');
    };

    const startOfDateWithTime = (date: Date) => {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    };

    const endOfDateWithTime = (date: Date) => {
      return moment(date).milliseconds(999).format('YYYY-MM-DD HH:mm:ss.SSS');
    };

    const prepareDateFilter = (filters: any) => {
      if (!filters?.date) return filters;

      const [startDate, endDate] = filters.date;

      if (startDate === endDate || !endDate) {
        return {
          ...filters,
          date: [startOfDateWithTime(startDate), endOfDayCustom(startDate)],
        };
      }

      return {
        ...filters,
        date: [startOfDateWithTime(startDate), endOfDateWithTime(endDate)],
      };
    };

    return {
      ...filters,
      date: prepareDateFilter(filters).date?.map((stringDate: string) => formatStringToDate(stringDate)),
    };
  }
  async getImageUrls(measurementId: string) {
    return axios.get(`/weighings/${measurementId}/image-urls`);
  }

  async getDownloadableImages(measurementId: string) {
    return axios.get(`/weighings/${measurementId}/download-images`);
  }

  async printPosReceipt(weighingId: string, printerNodeId: string) {
    return axios.post(`/weighings/${weighingId}/receipt/print-pos`, { printerNodeId: printerNodeId });
  }

  async importData(postData: any) {
    return axios.post(`/weighings/csv`, {
      data: postData,
    });
  }

  async splitWeighing(weighingId: string) {
    return axios.post(`/weighings/${weighingId}/split`);
  }

  async openWeighing(weighingId: string) {
    return axios.post(`/weighings/${weighingId}/open`);
  }

  async confirmWeighing(weighingId: string, comment: string | null) {
    return axios.post(`/weighings/${weighingId}/confirm`, {
      comment,
    });
  }

  async undoConfirmWeighing(weighingId: string) {
    return axios.post(`/weighings/${weighingId}/undo-confirm`);
  }

  async confirmLegacyWeighings() {
    return axios.post(`/weighings/confirm/all`);
  }
}

const weighingService = new WeighingService();
export default weighingService;
