import React from 'react';
import { useSelector } from 'react-redux';
import { ReducerState } from '../../../../../../types/reducer-state';
import { ControlType, Control } from '../../../../../../types/controls';
import DynamicSettingsControlElement from './DynamicSettingsControlElement';
import NodeControlElement from './NodeControlElement';

import { EdgeDevice } from '../../../../../../types/edge-device';
import { Dictionary } from '../../../../../../types/dictionary';

interface ControlsListProps {
  controls: Control[];
  edgeDevices: EdgeDevice[];
  dictionaries: Dictionary[];
}

const ControlsList = ({ controls, edgeDevices, dictionaries }: ControlsListProps) => {
  const { nodesState } = useSelector((state: ReducerState) => state.node);

  const renderAdjustedControl = (c: Control, index: number) => (
    <div key={index}>
      {c.type === ControlType.DYNAMIC_SETTINGS
        ? DynamicSettingsControlElement({
            ...c,
            edgeDevices,
            dictionaries,
          })
        : NodeControlElement({
            ...c,
            nodeStatus: nodesState.find(
              (el) => el.nodeId === c.nodeId && !!c.modes.find((m) => m.signal === el.signalName),
            ),
          })}
    </div>
  );

  return <>{controls.map(renderAdjustedControl)}</>;
};

export default ControlsList;
