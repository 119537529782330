import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import dictionariesService from '../../../../services/ScaleoApiServices/DictionariesService';
import '../../../DataTable.css';
import { useTranslation, Trans } from 'react-i18next';
import { Dictionary } from '../../../../types/dictionary';

interface DeleteDictionariesDialogProps {
  visible: boolean;
  closeDialog: (e: any) => void;
  selectedRows: Dictionary[];
}

const DeleteDictionariesDialog = ({ visible, closeDialog, selectedRows }: DeleteDictionariesDialogProps) => {
  const { t } = useTranslation('managementDictionaries');

  const handleCloseDialog = () => {
    closeDialog(true);
  };

  const handleConfirm = async () => {
    await Promise.all(selectedRows.map((item: Dictionary) => dictionariesService.removeDictionary(item.id))).then(
      () => {
        handleCloseDialog();
      },
    );
  };

  const dialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={handleCloseDialog} />
      <Button type="button" label={t('deleteButton')} icon="pi pi-check" text onClick={handleConfirm} />
    </>
  );

  return (
    <Dialog
      visible={visible}
      breakpoints={{ '896px': '90vw' }}
      header={
        <span className="flex align-items-center">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
          {t('confirm')}
        </span>
      }
      modal
      className="p-fluid min-width-450px"
      footer={dialogFooter}
      onHide={handleCloseDialog}
    >
      {selectedRows ? (
        <div className="confirmation-content">
          {selectedRows.length > 1 ? (
            <span>{t('content')}</span>
          ) : (
            <span>
              <Trans t={t} i18nKey="contentDetail" values={{ dictionary: selectedRows[0]?.name }} />
            </span>
          )}
        </div>
      ) : (
        ''
      )}
    </Dialog>
  );
};

export default DeleteDictionariesDialog;
