import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { InputTextarea } from 'primereact/inputtextarea';

interface ReceiptContentSectionProps {
  formikInstance: any;
}

const ReceiptsCustomFooter = ({ formikInstance }: ReceiptContentSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationPrints');

  const handleChangeField = (e: any, type: any) => {
    if (type === 'checkbox') {
      formikInstance.setFieldValue('shouldIncludeReceiptCustomFooter', e.checked);

      if (!e.checked) {
        formikInstance.setFieldValue('receiptCustomFooter', '');
      }
    }

    if (type === 'textarea') {
      formikInstance.setFieldValue('receiptCustomFooter', e.target.value);
    }
  };

  return (
    <Fieldset legend={t('content')} className="mt-1vh">
      <div className="grid pt-1vh">
        <div className="col-12 lg:col-5">
          <span className="field-checkbox md:col-12">
            <Checkbox
              id="shouldIncludeReceiptCustomFooter"
              checked={formikInstance.values.shouldIncludeReceiptCustomFooter}
              onChange={(e) => handleChangeField(e, 'checkbox')}
            ></Checkbox>
            <label htmlFor="shouldIncludeReceiptCustomFooter" className="checkbox-label">
              {t('includeCustomText')}
            </label>
          </span>

          {formikInstance.values.shouldIncludeReceiptCustomFooter && (
            <span className="field md:col-12">
              <div className="mt-2 ml-3">
                <span className="p-float-label">
                  <InputTextarea
                    id="receiptCustomFooter"
                    value={formikInstance.values.receiptCustomFooter}
                    onChange={(e: any) => handleChangeField(e, 'textarea')}
                    rows={5}
                    maxLength={1000}
                    className="textarea-custom-size"
                  />
                  <label htmlFor="receiptCustomFooter">{t('customText')}</label>
                </span>
              </div>
            </span>
          )}
        </div>
      </div>
    </Fieldset>
  );
};

export default ReceiptsCustomFooter;
