import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../store/user-slice';
import bdoConfigurationService from '../services/ScaleoApiServices/BdoConfigurationService';
import { ReducerState } from '../types/reducer-state';

const INIT_MODULES_CONFIG = {
  bdo: 'disabled',
};

const checkActivityBdoModule = async () => {
  const configResponse = await bdoConfigurationService.getConfiguration();
  if (configResponse.data?.moduleEnabled) {
    return true;
  }
  return false;
};

const useModulesConfiguration = () => {
  const dispatch = useDispatch();
  const [config, setConfig] = useState(INIT_MODULES_CONFIG);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const shouldRefreshSystemConfiguration = useSelector(
    (state: ReducerState) => state.user.shouldRefreshSystemConfiguration,
  );

  useEffect(() => {
    if (shouldRefreshSystemConfiguration || loggedUserContext.currentCustomer?.id) {
      void Promise.all([checkActivityBdoModule()]).then((values) => {
        setConfig({ bdo: values[0] ? 'enabled' : 'disabled' });
        dispatch(userActions.shouldRefreshSystemConfiguration(false));
      });
    }
  }, [loggedUserContext.currentCustomer?.id, dispatch, shouldRefreshSystemConfiguration]);

  return config;
};

export default useModulesConfiguration;
