import { createSlice, Middleware } from '@reduxjs/toolkit';
import { FormState, WeighingSlice } from './types/weighing-slice';

const initialState: WeighingSlice = {
  automationState: 'Automatyczny',
  weighingState: null,
  weighingModes: [],
  selectedScale: null,
  isTestWeighing: false,
  enableAutomaticWeighings: false,
  formState: {
    selectedWeighingModeId: null,
    selectedTransactionType: null,
    updateTare: null,
    updateData: null,
    saveVehicle: null,
    selectedProduct: null,
    selectedContractor: null,
    selectedDriver: null,
    selectedVehicle: null,
    selectedContainer: null,
    additionalFields: null,
    measurementAdditionalFields: null,
    continueWeighing: null,
    printPosReceipt: null,
    containers: [],
    selectedTrailer: null,
  },
  weighingMeasurementStatus: 'NOT_STARTED',
  weighingMeasurementSessionId: null,
  shouldResetWeighingForm: false,
  shouldReloadHandlingWeighingsData: false,
  shouldReloadWeighingsListData: false,
  shouldReloadWeighingForm: false,
  tareUpdatedToastData: null,
  shouldDisplayWeighingSuccessToast: false,
  automaticWeighingSuccessToastData: null,
  shouldDisplayWeighingErrorToast: false,
  finishedWeighingMeasurement: null,
  weighingSummaryDialogProps: {
    visible: false,
    weighingId: null,
    lastShowedWeighingMeasurementId: null,
    isConfirmedClick: null,
    isPrinted: false,
  },
  weighingConfiguration: null,
  isAutoWeighing: false,
  allOpenWeighings: [],
  allNotConfirmedWeighings: [],
  scaleZero: null,
  selectedVehicle: null,
  selectedTrailer: null,
};

const weighingSlice = createSlice({
  name: 'weighing',
  initialState: { ...initialState },
  reducers: {
    resetState(_state: WeighingSlice) {
      _state = { ...initialState };
    },
    weighingConfigurationFetched(state: WeighingSlice, value) {
      const weighingConfiguration = value.payload;
      state.weighingConfiguration = weighingConfiguration;
      if (!state.formState.selectedWeighingModeId) {
        state.formState.selectedWeighingModeId = weighingConfiguration.weighingMode[0];
      }
      state.isTestWeighing = value?.payload.isTestWeighing;
      state.enableAutomaticWeighings = value?.payload.enableAutomaticWeighings;
    },
    weighingStateFetched(state: WeighingSlice, value) {
      state.weighingState = value.payload;
    },
    clearSelectedScale(state: WeighingSlice) {
      state.selectedScale = null;
    },
    changeEnableAutomaticWeighings(state: WeighingSlice, value) {
      state.selectedScale.enableAutomaticWeighings = value.payload;
    },
    changeScaleAutoWeighing(state: WeighingSlice, value) {
      state.selectedScale.isAutoWeighing = value.payload;
    },
    updateFormState(state: WeighingSlice, value) {
      const selectedVehicleMiddleware = (vehicle: any) => {
        if (vehicle === null || vehicle?.isNew) {
          state.weighingState = null;
        }

        if (vehicle === null) {
          state.formState.selectedTransactionType = null;
          state.formState.selectedContractor = null;
          state.formState.selectedDriver = null;
          state.formState.selectedProduct = null;
          state.formState.additionalFields = null;
          state.formState.measurementAdditionalFields = null;
          state.formState.continueWeighing = null;
        }
      };

      const middlewares = { selectedVehicle: selectedVehicleMiddleware };

      Object.keys(value.payload).forEach((key) => {
        if (!!middlewares[key as keyof Middleware] && typeof middlewares[key as keyof Middleware] === 'function') {
          (middlewares[key as keyof Middleware] as Function)(value.payload[key]);
        }
        state.formState[key as keyof FormState] = value.payload[key];
      });
    },
    weighingMeasurementRequested(state: WeighingSlice, value) {
      state.weighingMeasurementStatus = 'REQUESTED';
      state.weighingMeasurementSessionId = value.payload;
    },
    weighingMeasurementFinished(state: WeighingSlice, value) {
      const finishedWeighingMeasurement = value.payload;
      if (
        state.weighingMeasurementStatus === 'REQUESTED' &&
        value.payload.sessionId === state.weighingMeasurementSessionId
      ) {
        state.weighingMeasurementStatus = value.payload.error ? 'FAILED' : 'SUCCEEDED';
        state.finishedWeighingMeasurement = finishedWeighingMeasurement;
        state.weighingState = null;
        state.formState.selectedProduct = null;
        state.formState.selectedVehicle = null;
        state.formState.selectedTrailer = null;
        state.formState.selectedContractor = null;
        state.formState.selectedDriver = null;
        state.formState.selectedContainer = null;
        state.formState.saveVehicle = null;
        state.formState.updateTare = null;
        state.formState.updateData = null;
        state.formState.additionalFields = null;
        state.formState.measurementAdditionalFields = null;
        state.formState.containers = [];
        state.shouldReloadHandlingWeighingsData = true;
        state.shouldReloadWeighingForm = true;

        if (finishedWeighingMeasurement.weighingMeasurementType === 'TARE') {
          state.tareUpdatedToastData = { success: true };
        } else if (finishedWeighingMeasurement.error) {
          state.shouldDisplayWeighingErrorToast = true;
        } else if (finishedWeighingMeasurement.weighingClosed) {
          state.weighingSummaryDialogProps = {
            visible: true,
            weighingId: finishedWeighingMeasurement.weighingId,
            lastShowedWeighingMeasurementId: null,
            isConfirmedClick: null,
            isPrinted: false,
          };
        }
      } else if (finishedWeighingMeasurement.weighingMeasurementType === 'AUTOMATIC') {
        state.automaticWeighingSuccessToastData = {
          success: true,
          registrationNumber: finishedWeighingMeasurement.registrationNumber,
        };
        state.finishedWeighingMeasurement = finishedWeighingMeasurement;
        state.weighingSummaryDialogProps = {
          visible: !!finishedWeighingMeasurement.firstAutomaticWeighingId,
          weighingId: finishedWeighingMeasurement.firstAutomaticWeighingId,
          lastShowedWeighingMeasurementId: null,
          isConfirmedClick: null,
          isPrinted: false,
        };
        state.finishedWeighingMeasurement.isCombined = !!finishedWeighingMeasurement.firstAutomaticWeighingId;
        state.shouldReloadHandlingWeighingsData = true;
      } else if (finishedWeighingMeasurement.weighingMeasurementType === 'AUTOMATIC_TARE') {
        state.tareUpdatedToastData = { success: true, registrationNumber: value.payload.registrationNumber };
      } else {
        // combined manual weighing
        state.finishedWeighingMeasurement = finishedWeighingMeasurement;
        state.weighingSummaryDialogProps = {
          visible: !!finishedWeighingMeasurement.id,
          weighingId: finishedWeighingMeasurement.id,
          lastShowedWeighingMeasurementId: null,
          isConfirmedClick: null,
          isPrinted: false,
        };
        state.shouldReloadHandlingWeighingsData = true;
      }
    },
    setWeighingSummaryIsPrinted(state: WeighingSlice) {
      state.weighingSummaryDialogProps.isPrinted = true;
    },
    setWeighingSummaryDialogProps(state: WeighingSlice, value) {
      state.weighingSummaryDialogProps = value.payload;
    },
    clearFinishedWeighingMeasurementProps(state: WeighingSlice) {
      state.finishedWeighingMeasurement = null;
    },
    weighingClosed(state: WeighingSlice) {
      state.shouldReloadHandlingWeighingsData = true;
      state.shouldReloadWeighingForm = true;
      state.weighingState = null;
      state.formState.selectedProduct = null;
      state.formState.selectedVehicle = null;
      state.formState.selectedTrailer = null;
      state.formState.selectedContractor = null;
      state.formState.selectedDriver = null;
      state.formState.selectedContainer = null;
      state.formState.saveVehicle = null;
      state.formState.updateTare = null;
      state.formState.updateData = null;
      state.formState.additionalFields = null;
      state.formState.measurementAdditionalFields = null;
      state.formState.containers = [];
    },
    displayTareUpdatedToast(state: WeighingSlice) {
      state.tareUpdatedToastData = null;
    },
    displayWeighingSuccessToast(state: WeighingSlice) {
      state.shouldDisplayWeighingSuccessToast = true;
    },
    weighingFormReset(state: WeighingSlice) {
      state.shouldResetWeighingForm = false;
    },
    handlingWeighingsDataReloaded(state: WeighingSlice) {
      state.shouldReloadHandlingWeighingsData = false;
    },
    weighingsListDataReloaded(state: WeighingSlice) {
      state.shouldReloadWeighingsListData = false;
    },
    setAllOpenWeighings(state: WeighingSlice, value) {
      state.allOpenWeighings = value.payload;
    },
    setAllNotConfirmedWeighings(state: WeighingSlice, value) {
      state.allNotConfirmedWeighings = value.payload;
    },
    weighingFormReloaded(state: WeighingSlice) {
      state.shouldReloadWeighingForm = false;
    },
    tareUpdatedToastDisplayed(state: WeighingSlice) {
      state.tareUpdatedToastData = null;
    },
    weighingSuccessToastDisplayed(state: WeighingSlice) {
      state.shouldDisplayWeighingSuccessToast = false;
    },
    automaticWeighingSuccessToastDisplayed(state: WeighingSlice) {
      state.automaticWeighingSuccessToastData = null;
    },
    weighingErrorToastDisplayed(state: WeighingSlice) {
      state.shouldDisplayWeighingErrorToast = false;
    },
    scaleSelected(state: WeighingSlice, value) {
      state.selectedScale = value.payload;
      state.isAutoWeighing = value?.payload.isAutoWeighing;
    },
    changeAutomationMode(state: WeighingSlice, value) {
      const currentMode = state.selectedScale.edgeDevice?.automationStates?.find((mode: any) => {
        return mode.modeId === value.payload;
      });

      state.automationCurrentMode = {
        modeId: value.payload,
        weighingFormMode: currentMode?.weighingFormMode,
        vehiclePositionControl: currentMode?.vehiclePositionControl?.toLowerCase() === 'true',
      };
    },
    reloadHandlingWeighingsData(state: WeighingSlice) {
      state.shouldReloadHandlingWeighingsData = true;
    },
    reloadWeighingsListData(state: WeighingSlice) {
      state.shouldReloadWeighingsListData = true;
    },
  },
});

export const weighingActions = weighingSlice.actions;

export default weighingSlice;
