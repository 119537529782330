import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { EdgeDevice } from '../../../types/edge-device';

interface DeleteEdgeDeviceConfirmationDialogProps {
  edgeDevice: Partial<EdgeDevice>;
  onCancel: () => void;
  onConfirm: () => void;
  visible: boolean;
}

export const DeleteEdgeDeviceConfirmationDialog = ({
  edgeDevice,
  onCancel,
  onConfirm,
  visible,
}: DeleteEdgeDeviceConfirmationDialogProps) => {
  const { t } = useTranslation('managementEdgeDevices');

  const deleteEdgeDeviceDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={onCancel} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={onConfirm} />
    </>
  );
  return (
    <Dialog
      visible={visible}
      header={
        <span className="flex align-items-center">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
          {t('confirm')}
        </span>
      }
      modal
      footer={deleteEdgeDeviceDialogFooter}
      onHide={onCancel}
      className="p-fluid min-width-450px"
      breakpoints={{ '896px': '90vw' }}
    >
      <div className="confirmation-content">
        {edgeDevice && <span>{t('content', { edgeDevice: edgeDevice.name })}</span>}
      </div>
    </Dialog>
  );
};
