import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import isIn from '../../../../../../utils/isIn';
import { hasPermissions } from '../../../../../../utils/permissionsUtils';
import ComponentItemTemplates from '../../../../../_shared/ComponentItemTemplates';
import { CustomAutoComplete } from '../../../../../_shared/CustomComponents';
import FormErrorMessage from '../../../../../_shared/FormErrorMessage';
import { ProductAutoComplete } from '../../../../../_shared/ProductAutoComplete';
import AdditionalFields from './AdditionalFields';
import { getOrderNumber } from './getOrderNumber';
import { FORMIK_FIELD_NAMES } from './variables';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../../../types/reducer-state';
import { useDispatch, useSelector } from 'react-redux';
import { weighingActions } from '../../../../../../store/weighing-slice';
import useTransactionType from '../../../../../../utils/useTransactionType';

import { Contractor, Driver, Product } from '../../../../../../types/weighing';
import { Dictionary } from '../../../../../../types/dictionary';
import { useEffect, useState } from 'react';
import NewOrEditContractorDialog from '../../../../../DataSets/Contractors/components/NewOrEditContractorDialog';
import { NewOrEditDriverDialog } from '../../../../../DataSets/Drivers/components/NewOrEditDriverDialog';
import { NewOrEditProductDialog } from '../../../../../DataSets/Products/components/NewOrEditProductDialog';

interface WeighingDataFormProps {
  contractors: Contractor[];
  drivers: Driver[];
  dictionaries: Dictionary[];
  formik: any;
  localTouched: any;
  setLocalTouched: (a: any) => void;
  fetchComponentData: () => void;
}

export const WeighingDataForm = ({
  formik,
  contractors,
  drivers,
  dictionaries,
  localTouched,
  setLocalTouched,
  fetchComponentData,
}: WeighingDataFormProps) => {
  const { t } = useTranslation('weighingHandlingWeighings');

  const { weighingConfiguration, formState } = useSelector((state: ReducerState) => state.weighing);
  const { permissions } = useSelector((state: ReducerState) => state.user.context);

  const dispatch = useDispatch();
  const updateFormState = (state: any) => dispatch(weighingActions.updateFormState(state));

  const { ALL_TRANSACTION_TYPES } = useTransactionType();

  const [contractorDialogVisible, setContractorDialogVisible] = useState(false);
  const [productDialogVisible, setProductDialogVisible] = useState(false);
  const [driverDialogVisible, setDriverDialogVisible] = useState(false);

  const selectTransactionType = (selectedTransactionType: any) => {
    updateFormState({ selectedTransactionType });
  };

  const selectProduct = (selectedProduct: Product) => {
    updateFormState({ selectedProduct });
  };

  const selectContractor = (selectedContractor: Contractor) => {
    updateFormState({ selectedContractor });
  };

  const selectDriver = (selectedDriver: Driver) => {
    updateFormState({ selectedDriver });
  };

  useEffect(() => {
    fetchComponentData();
  }, [fetchComponentData, contractorDialogVisible, driverDialogVisible, productDialogVisible]);

  return (
    <div className=" col-12 xl:col-6">
      <div className="grid">
        <div className="col-12">
          {isIn(formState.selectedWeighingModeId, [
            'SINGLE',
            'DOUBLE',
            'MULTIPLE',
            'WEIGHING_SERIES',
            'DOUBLE_WEIGHING_SERIES',
          ]) && (
            <Fieldset legend={t('newWeighingFormDetailsTitle')} className="weighing-data-fieldset">
              <div
                className="field grid"
                style={{ order: getOrderNumber('transactionType', weighingConfiguration.fieldsOrder) }}
              >
                <label htmlFor="transactionType" className="col-12 sm:col-4">
                  {t('newWeighingFormDetailsType')}
                </label>
                <div className="col-10 sm:col-6">
                  <CustomAutoComplete
                    id="transactionType"
                    field="name"
                    value={formik.values.transactionType}
                    handler={(e: any) => selectTransactionType(e.value)}
                    source={ALL_TRANSACTION_TYPES}
                  />
                  <FormErrorMessage fieldName="transactionType" formikInstance={formik} />
                </div>
              </div>
              <div
                className="field grid"
                style={{ order: getOrderNumber('contractor', weighingConfiguration.fieldsOrder) }}
              >
                <label htmlFor="contractor" className="col-12 sm:col-4">
                  {t('newWeighingFormDetailsContractor')}
                </label>
                <div className="col-10 sm:col-6">
                  <CustomAutoComplete
                    id="contractor"
                    lookupField="fulltext"
                    value={formik.values.contractor}
                    handler={(e: any) => selectContractor(e.value)}
                    source={contractors}
                    customItemTemplate={ComponentItemTemplates.Contractor}
                  />
                  <FormErrorMessage fieldName="contractor" formikInstance={formik} />
                </div>
                {hasPermissions(['DATASETS_EDIT'], permissions) && (
                  <div className="col-1">
                    <Button
                      type="button"
                      icon="pi pi-plus"
                      severity="success"
                      onClick={() => setContractorDialogVisible(true)}
                    />
                  </div>
                )}
              </div>
              <div
                className="field grid"
                style={{ order: getOrderNumber('product', weighingConfiguration.fieldsOrder) }}
              >
                <label htmlFor="product" className="col-12 sm:col-4">
                  {t('newWeighingFormDetailsProduct')}
                </label>
                <div className="col-10 sm:col-6">
                  <ProductAutoComplete
                    id="product"
                    value={formik.values.product}
                    onChange={selectProduct}
                    conditionalFields={{
                      transactionType: formik.values.transactionType?.id,
                      contractorId: formik.values.contractor?.id,
                    }}
                    searchForCurrentLocation={undefined}
                  />
                  <FormErrorMessage fieldName="product" formikInstance={formik} />
                </div>
                {hasPermissions(['DATASETS_EDIT'], permissions) && (
                  <div className="col-1">
                    <Button
                      type="button"
                      icon="pi pi-plus"
                      severity="success"
                      onClick={() => setProductDialogVisible(true)}
                    />
                  </div>
                )}
              </div>
              <div
                className="field grid"
                style={{ order: getOrderNumber('driver', weighingConfiguration.fieldsOrder) }}
              >
                <label htmlFor="driver" className="col-12 sm:col-4">
                  {t('newWeighingFormDetailsDriver')}
                </label>
                <div className="col-10 sm:col-6">
                  <CustomAutoComplete
                    id="driver"
                    field="name"
                    value={formik.values.driver}
                    handler={(e: any) => {
                      selectDriver(e.value);
                    }}
                    source={drivers}
                  />
                  <FormErrorMessage fieldName="driver" formikInstance={formik} />
                </div>
                {hasPermissions(['DATASETS_EDIT'], permissions) && (
                  <div className="col-1">
                    <Button
                      type="button"
                      icon="pi pi-plus"
                      severity="success"
                      onClick={() => setDriverDialogVisible(true)}
                    />
                  </div>
                )}
              </div>
              {!!formik.values.additionalFields?.length && (
                <AdditionalFields
                  formikInstance={formik}
                  fieldName={FORMIK_FIELD_NAMES.additionalFields}
                  localTouched={localTouched}
                  setLocalTouched={setLocalTouched}
                  dictionaries={dictionaries}
                />
              )}
              {!!formik.values.measurementAdditionalFields?.length && (
                <AdditionalFields
                  formikInstance={formik}
                  fieldName={FORMIK_FIELD_NAMES.measurementAdditionalFields}
                  localTouched={localTouched}
                  setLocalTouched={setLocalTouched}
                  dictionaries={dictionaries}
                />
              )}
            </Fieldset>
          )}
        </div>
      </div>
      <NewOrEditContractorDialog
        contractorDialogVisible={contractorDialogVisible}
        setContractorDialogVisible={setContractorDialogVisible}
        onContractorAdded={selectContractor}
        items={undefined}
      />
      <NewOrEditProductDialog
        productDialogVisible={productDialogVisible}
        setProductDialogVisible={setProductDialogVisible}
        onProductAdded={selectProduct}
        items={undefined}
      />
      <NewOrEditDriverDialog
        driverDialogVisible={driverDialogVisible}
        setDriverDialogVisible={setDriverDialogVisible}
        onDriverAdded={selectDriver}
        items={undefined}
      />
    </div>
  );
};
