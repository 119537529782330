import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import EditDialog from './components/EditDialog';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { getTranslatedLabel, transactionTypeLabels } from '../../../../../../utils/labels';
import { useTranslation } from 'react-i18next';

interface TransactionTypeSectionProps {
  formikInstance: any;
  handleChangeField: (a: any, s: string) => void;
}

const TransactionTypeSection = ({ formikInstance, handleChangeField }: TransactionTypeSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationWeighing');
  const { t: t1 } = useTranslation('utils');

  const [editDialogData, setEditDialogData] = useState<{ isOpen: boolean; typeIndex: number | null }>({
    isOpen: false,
    typeIndex: null,
  });

  const BASE_TYPE_OPTIONS = [
    { label: getTranslatedLabel(transactionTypeLabels, 'DELIVERY', t1), value: 'DELIVERY' },
    { label: getTranslatedLabel(transactionTypeLabels, 'RECEIVE', t1), value: 'RECEIVE' },
  ];

  const handleClickOpenEditDialog = (typeIndex: number | null) => {
    setEditDialogData({ isOpen: true, typeIndex });
  };

  const handleClickCloseEditDialog = (changes: boolean, fieldData: any) => {
    if (changes) {
      const newValue = formikInstance.values.customTransactionTypes;
      if (editDialogData.typeIndex !== null && editDialogData.typeIndex > -1) {
        newValue[editDialogData.typeIndex] = { ...newValue[editDialogData.typeIndex], ...fieldData };
      } else {
        newValue.push({ ...fieldData, id: uuidv4(), unsaved: true });
      }
      formikInstance.setFieldValue('customTransactionTypes', newValue, false);
    }
    setEditDialogData({ isOpen: false, typeIndex: null });
  };

  const handleClickNew = () => {
    handleClickOpenEditDialog(null);
  };

  const handleClickRemove = (i: number) => {
    const newValue = formikInstance.values.customTransactionTypes;
    newValue.splice(i, 1);
    formikInstance.setFieldValue('customTransactionTypes', newValue, false);
  };

  const renderHeaders = (index: number) => {
    if (index === 0 && formikInstance.values.customTransactionTypes) {
      return (
        <div className="p-fluid formgrid grid pb-10px">
          <div className="col-2">
            <label htmlFor="name">
              <b>{t('headersName')}</b>
            </label>
          </div>
          <div className="col-1 text-center">
            <label htmlFor="actions">
              <b>{t('headersActions')}</b>
            </label>
          </div>
        </div>
      );
    }
    return <></>;
  };

  const renderRows = (field: any, index: number) => {
    return (
      <div
        className="p-fluid formgrid grid align-items-center pt-2px pb-2px pr-0px pl-0px"
        style={{ backgroundColor: index % 2 === 0 ? 'whitesmoke' : '' }}
      >
        <div className="col-2">
          <span>{field.name}</span>
        </div>
        <div className="col-1 text-center flex-nowrap flex">
          <Button
            icon="pi pi-pencil"
            type="button"
            text
            className="p-button-sm"
            onClick={() => handleClickOpenEditDialog(index)}
            tooltip={t('editButton')}
          />
          <Button
            icon="pi pi-trash"
            type="button"
            severity="danger"
            text
            className="p-button-sm ml-2"
            onClick={() => handleClickRemove(index)}
            tooltip={t('deleteButton')}
            disabled={field.isUsed}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Fieldset legend={t('transactionTypeSectionHeader')} className="mt-1vh">
        <div className="field">
          {formikInstance.values.customTransactionTypes?.length ? (
            formikInstance.values.customTransactionTypes?.map((field: any, i: number) => (
              <div key={`customTransactionType--${i}`}>
                {renderHeaders(i)}
                {renderRows(field, i)}
              </div>
            ))
          ) : (
            <></>
          )}
          <Button
            type="button"
            label={t('addButton')}
            icon="pi pi-plus"
            severity="success"
            className="mt-4"
            onClick={handleClickNew}
          />
        </div>
        <div className="p-fluid formgrid grid">
          <div className="field col-2">
            <label htmlFor="transactionTypeDefault">{t('transactionTypeSectionDefault')}</label>
            <Dropdown
              id={`transactionTypeDefault`}
              options={[
                { label: t('transactionTypeDefaultEmpty'), value: null },
                ...BASE_TYPE_OPTIONS,
                ...formikInstance.values.customTransactionTypes.map((el: any) => {
                  return { label: el.name, value: el.id };
                }),
              ]}
              value={formikInstance.values.transactionTypeDefault}
              onChange={(e) => handleChangeField(e, 'dropdown')}
              placeholder={t('transactionTypeDefaultPlaceholder')}
            />
          </div>
        </div>
        <div className="p-fluid formgrid grid">
          <div className="field-checkbox col-12">
            <Checkbox
              id="transactionTypeRequired"
              checked={formikInstance.values.transactionTypeRequired}
              onChange={(e) => handleChangeField(e, 'checkbox')}
            ></Checkbox>
            <label htmlFor="transactionTypeRequired" className="checkbox-label">
              {t('transactionTypeRequired')}
            </label>
          </div>
        </div>
        <div className="p-fluid formgrid grid">
          <div className="field-checkbox col-12">
            <Checkbox
              id="transactionTypeAutomatic"
              checked={formikInstance.values.transactionTypeAutomatic}
              onChange={(e) => handleChangeField(e, 'checkbox')}
            ></Checkbox>
            <label htmlFor="transactionTypeAutomatic" className="checkbox-label">
              {t('transactionTypeAutomatic')}
            </label>
          </div>
        </div>
      </Fieldset>
      {editDialogData.isOpen ? (
        <EditDialog
          visible={editDialogData.isOpen}
          closeDialog={handleClickCloseEditDialog}
          field={
            formikInstance.values.customTransactionTypes[
              editDialogData.typeIndex as keyof typeof formikInstance.values.customTransactionTypes
            ]
          }
        />
      ) : (
        ''
      )}
    </>
  );
};

export default TransactionTypeSection;
