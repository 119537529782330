import { Button } from 'primereact/button';
import React from 'react';
import { useSelector } from 'react-redux';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import ReceiptFormatSection from './components/ReceiptFormatSection';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../types/reducer-state';
import ReceiptContentSection from './components/ReceiptContentSection';
import ReceiptAutoSendSection from './components/ReceiptAutoSendSection';
import { isModulesEnabled } from '../../../../utils/modulesUtils';
import ReceiptsCustomFooter from './components/ReceiptCustomFooterSection';
import ReceiptFieldsOrderSection from './components/ReceiptFieldsOrderSection';
import GraphicElements from '../GraphicElements/GraphicElements';
import { usePrintsConfigurationForm } from '../../../../hooks/PrintsConfiguration/usePrintsConfigurationForm';
import { usePrintsConfigurationData } from '../../../../hooks/PrintsConfiguration/usePrintsConfigurationData';

const PrintsConfiguration = () => {
  const { t } = useTranslation('managementSystemConfigurationPrints');

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const { fetchAPI, stringifiedInitFormValues, fileBuffer, setFileBuffer, logo, initFormValues } =
    usePrintsConfigurationData();

  const { handleChangeField, handleClickSubmit, handleClickCancel, formik } = usePrintsConfigurationForm(
    fileBuffer,
    initFormValues,
    stringifiedInitFormValues,
  );

  return (
    <div className="card pb-70px">
      <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
        <form onSubmit={formik.handleSubmit}>
          <ReceiptAutoSendSection formikInstance={formik} handleChangeField={handleChangeField} />
          <ReceiptFormatSection formikInstance={formik} handleChangeField={handleChangeField} />
          {isModulesEnabled(['CAMERAS'], loggedUserContext.currentCustomer?.subscriptionModules) && (
            <ReceiptContentSection formikInstance={formik} handleChangeField={handleChangeField} />
          )}
          <ReceiptsCustomFooter formikInstance={formik} />
          <ReceiptFieldsOrderSection formikInstance={formik} />
          <div>
            <div
              className="flex justify-content-end w-full p-ai-end bg-white fixed bottom-0 right-0 pt-10px pr-40px pb-10px pl-0px"
              style={{
                boxShadow: '0px 10px 15px black',
              }}
            >
              <Button type="reset" label={t('reset')} icon="pi pi-times" text onClick={handleClickCancel} />
              <Button type="button" label={t('save')} icon="pi pi-check" text onClick={handleClickSubmit} />
            </div>
          </div>
        </form>
        <GraphicElements formik={formik} fetchAPI={fetchAPI} setFileBuffer={setFileBuffer} logoUrl={logo} />
      </FormErrorMessageScroller>
    </div>
  );
};

export default PrintsConfiguration;
