export enum WebhookEvent {
  WEIGHING_CLOSED = 'WEIGHING_CLOSED',
}

export interface Webhook {
  id?: string;
  name: string;
  destinationUrl: string;
  event: WebhookEvent;
  contractorIds: string[];
  destinationId: string;
  destinationPassword: string;
}
