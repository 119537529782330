import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';

interface NewVehicleSavingSectionProps {
  formikInstance: any;
  handleChangeField: any;
}

const NewVehicleSavingSection = ({ formikInstance, handleChangeField }: NewVehicleSavingSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationWeighing');

  return (
    <Fieldset legend={t('newVehicleSavingSectionHeader')} className="mt-1vh">
      <div className="p-fluid formgrid grid">
        <div className="field-checkbox col-12">
          <Checkbox
            id="saveVehicleShowCheckbox"
            checked={formikInstance.values.saveVehicleShowCheckbox}
            onChange={(e) => handleChangeField(e, 'checkbox')}
          ></Checkbox>
          <label htmlFor="saveVehicleShowCheckbox" className="checkbox-label">
            {t('saveVehicleShowCheckbox')}
          </label>
        </div>
        <div className="field-checkbox col-12">
          <Checkbox
            id="saveVehicleDefaultValue"
            checked={formikInstance.values.saveVehicleDefaultValue}
            onChange={(e) => handleChangeField(e, 'checkbox')}
          ></Checkbox>
          <label htmlFor="saveVehicleDefaultValue" className="checkbox-label">
            {t('saveVehicleDefaultValue')}
          </label>
        </div>
      </div>
    </Fieldset>
  );
};

export default NewVehicleSavingSection;
