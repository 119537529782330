import React from 'react';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { CustomButton } from '../../../../../_shared/CustomComponents';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'primereact/tooltip';
import { ReducerState } from '../../../../../../types/reducer-state';
import { Vehicle } from '../../../../../../types/weighing';
import { isModulesEnabled } from '../../../../../../utils/modulesUtils';

interface WeighingActionsButtonsProps {
  isNewVehicle: boolean;
  isIndicationOutOfRange: any;
  setMeasurementType: (type: string) => void;
  vehicleData: Vehicle;
  currentIndication: number | null;
  edgeDeviceMaxWeightBrutto: number | null;
}

const WeighingActionsButtons = ({
  isNewVehicle,
  isIndicationOutOfRange,
  setMeasurementType,
  vehicleData,
  currentIndication,
  edgeDeviceMaxWeightBrutto,
}: WeighingActionsButtonsProps) => {
  const { t } = useTranslation('weighingHandlingWeighings');

  const selectedWeighingMode = useSelector((state: ReducerState) => state.weighing.formState.selectedWeighingModeId);
  const mqttConnected = useSelector((state: ReducerState) => state.ui.mqttConnected);
  const weighingAppState = useSelector((state: ReducerState) => state.weighing);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const vehiclePositionState = useSelector((state: ReducerState) => state.state.vehiclePosition);
  const { selectedScale, automationCurrentMode } = useSelector((state: ReducerState) => state.weighing);

  const isOpenWeighing = weighingAppState.weighingState?.openWeighing;
  const selectedVehicle = weighingAppState.formState.selectedVehicle;
  const vehicleWeighingModes = weighingAppState.weighingState?.weighingMode;
  const vehicleTare = weighingAppState.weighingState?.tare;
  const configurationWeighingModes = weighingAppState.weighingConfiguration?.weighingMode;

  const isInModes = (modes: string[], modeName: string) => {
    if (Array.isArray(modes)) {
      return modes?.includes(modeName);
    }
    return false;
  };

  const isInManualMode =
    (!selectedScale?.edgeDevice?.automationStates && !selectedScale?.isAutoWeighing) ||
    automationCurrentMode?.weighingFormMode === 'FULL';

  const isVehiclePositionIncorrect = () => {
    const isVehiclePositionControlEnabled = automationCurrentMode?.vehiclePositionControl;
    const allowManualWeighingIfVehicleIncorrectPosition =
      isInManualMode && selectedScale?.edgeDevice?.allowManualWeighingIfVehicleIncorrectPosition;

    if (!isVehiclePositionControlEnabled || allowManualWeighingIfVehicleIncorrectPosition) {
      return false;
    }

    const vehiclePosition = vehiclePositionState[selectedScale?.edgeDevice?.id];
    return vehiclePosition !== 'CORRECT';
  };

  const isMaxWeightBruttoExceeded = () => {
    const allowManualWeighingIfVehicleOverloaded =
      isInManualMode && selectedScale?.edgeDevice?.allowManualWeighingIfVehicleOverloaded;
    if (!isVehicleOverloadControlEnabled || allowManualWeighingIfVehicleOverloaded) {
      return false;
    }

    return checkMaxWeightBrutto();
  };

  const isVehicleOverloadControlEnabled = isModulesEnabled(
    ['VEHICLE_OVERLOAD_CONTROL'],
    loggedUserContext.currentCustomer?.subscriptionModules,
  );

  const checkMaxWeightBrutto = () => {
    if (!currentIndication) return false;

    if (vehicleData?.maxWeightBrutto && currentIndication > vehicleData?.maxWeightBrutto) {
      return true;
    }

    if (!vehicleData?.maxWeightBrutto && edgeDeviceMaxWeightBrutto && currentIndication > edgeDeviceMaxWeightBrutto) {
      return true;
    }

    return false;
  };

  const tooltipText = (modeName: string) => {
    if (!mqttConnected) {
      return t('actionButtonsTooltipMqttDisconnected');
    } else if (isIndicationOutOfRange?.minWeight || (isIndicationOutOfRange?.maxWeight && !selectedScale.testScale)) {
      return t('actionButtonsTooltipOverflow');
    } else if (!selectedVehicle?.id && !isNewVehicle) {
      return t('actionButtonsTooltipNoVehicle');
    } else if (isInModes(['MULTIPLE', 'FINAL'], modeName)) {
      return t('actionButtonsTooltipDisabledMultipleMode');
    } else if (!isInModes(vehicleWeighingModes, modeName) && !isNewVehicle) {
      return t('actionButtonsTooltipDisabledMode');
    } else if (isInModes(['SINGLE'], modeName) && !vehicleTare?.value) {
      return t('actionButtonsTooltipNoTare');
    } else if (isMaxWeightBruttoExceeded()) {
      return t('actionButtonsTooltipMaxWeightBruttoError');
    }
    return '';
  };

  const weighingButtonsDisabled = {
    single:
      !mqttConnected ||
      isIndicationOutOfRange?.minWeight ||
      (isIndicationOutOfRange?.maxWeight && !selectedScale.testScale) ||
      !(isInModes(vehicleWeighingModes, 'SINGLE') && vehicleTare?.value) ||
      isMaxWeightBruttoExceeded() ||
      isVehiclePositionIncorrect(),
    first:
      !mqttConnected ||
      isIndicationOutOfRange?.minWeight ||
      (isIndicationOutOfRange?.maxWeight && !selectedScale.testScale) ||
      !(
        (isInModes(vehicleWeighingModes, 'DOUBLE') && selectedWeighingMode === 'DOUBLE') ||
        (isInModes(vehicleWeighingModes, 'MULTIPLE') && selectedWeighingMode === 'MULTIPLE') ||
        (isInModes(vehicleWeighingModes, 'WEIGHING_SERIES') && selectedWeighingMode === 'WEIGHING_SERIES') ||
        (isInModes(vehicleWeighingModes, 'DOUBLE_WEIGHING_SERIES') &&
          selectedWeighingMode === 'DOUBLE_WEIGHING_SERIES') ||
        isNewVehicle
      ) ||
      isMaxWeightBruttoExceeded() ||
      isVehiclePositionIncorrect(),
    tare:
      !mqttConnected ||
      isIndicationOutOfRange?.minWeight ||
      (isIndicationOutOfRange?.maxWeight && !selectedScale.testScale) ||
      !(isInModes(vehicleWeighingModes, 'TARE') || isNewVehicle) ||
      isMaxWeightBruttoExceeded() ||
      isVehiclePositionIncorrect(),
    control:
      !mqttConnected ||
      isIndicationOutOfRange?.minWeight ||
      (isIndicationOutOfRange?.maxWeight && !selectedScale.testScale) ||
      !(isInModes(vehicleWeighingModes, 'CONTROL') || isNewVehicle) ||
      isMaxWeightBruttoExceeded() ||
      isVehiclePositionIncorrect(),
    next:
      !mqttConnected ||
      isIndicationOutOfRange?.minWeight ||
      (isIndicationOutOfRange?.maxWeight && !selectedScale.testScale) ||
      !(
        (isInModes(vehicleWeighingModes, 'DOUBLE') && isOpenWeighing?.measurements?.length < 2) ||
        isInModes(vehicleWeighingModes, 'MULTIPLE') ||
        isInModes(vehicleWeighingModes, 'WEIGHING_SERIES') ||
        isInModes(vehicleWeighingModes, 'DOUBLE_WEIGHING_SERIES')
      ) ||
      isMaxWeightBruttoExceeded() ||
      isVehiclePositionIncorrect(),
    final:
      !mqttConnected ||
      isIndicationOutOfRange?.minWeight ||
      (isIndicationOutOfRange?.maxWeight && !selectedScale.testScale) ||
      !(
        (isInModes(vehicleWeighingModes, 'DOUBLE') && isOpenWeighing?.measurements?.length < 2) ||
        isInModes(vehicleWeighingModes, 'MULTIPLE') ||
        isInModes(vehicleWeighingModes, 'WEIGHING_SERIES') ||
        isInModes(vehicleWeighingModes, 'DOUBLE_WEIGHING_SERIES')
      ) ||
      isMaxWeightBruttoExceeded() ||
      isVehiclePositionIncorrect(),
  };

  const allowAddingMeasurements = () =>
    Number.isInteger(loggedUserContext.limits?.maximumMeasurementsNumber) &&
    Number.isInteger(loggedUserContext.limitsState?.currentMonthMeasurementsNumber)
      ? loggedUserContext.limits!.maximumMeasurementsNumber! >=
        loggedUserContext.limitsState!.currentMonthMeasurementsNumber + 1
      : true;

  if (!allowAddingMeasurements()) {
    return (
      <div className="col-12">
        <div className="flex flex-column md:flex-row">
          <div className="reportTooltip">
            <div className="mb-2 mr-2">
              <Button
                type="button"
                label={t('actionButtonsSingleWeighingButton')}
                severity="success"
                className="mr-2"
                disabled={!allowAddingMeasurements()}
              />
            </div>
            {!allowAddingMeasurements() && (
              <Tooltip target=".reportTooltip" position="bottom">
                {t('blockAdding')}
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="col-12">
      <div className="flex flex-column md:flex-row">
        {!isOpenWeighing ? (
          <>
            {isInModes(configurationWeighingModes, 'SINGLE') && selectedWeighingMode === 'SINGLE' ? (
              <div className="mb-2 mr-2">
                <CustomButton
                  label={t('actionButtonsSingleWeighingButton')}
                  name="single-weighing"
                  dataFlag="SINGLE"
                  tooltip={tooltipText('SINGLE')}
                  disabled={weighingButtonsDisabled.single}
                  onClick={() => setMeasurementType('SINGLE')}
                  icon={undefined}
                  className={undefined}
                  type={undefined}
                ></CustomButton>
              </div>
            ) : (
              ''
            )}
            {(isInModes(configurationWeighingModes, 'DOUBLE') && selectedWeighingMode === 'DOUBLE') ||
            (isInModes(configurationWeighingModes, 'MULTIPLE') && selectedWeighingMode === 'MULTIPLE') ||
            (isInModes(configurationWeighingModes, 'WEIGHING_SERIES') && selectedWeighingMode === 'WEIGHING_SERIES') ||
            (isInModes(configurationWeighingModes, 'DOUBLE_WEIGHING_SERIES') &&
              selectedWeighingMode === 'DOUBLE_WEIGHING_SERIES') ? (
              <div className="mb-2 mr-2">
                <CustomButton
                  label={t('actionButtonsFirstWeighingButton')}
                  name="first-weighing"
                  dataFlag="FIRST"
                  tooltip={tooltipText(selectedWeighingMode)}
                  disabled={weighingButtonsDisabled.first}
                  onClick={() => setMeasurementType('FIRST')}
                  icon={undefined}
                  className={undefined}
                  type={undefined}
                ></CustomButton>
              </div>
            ) : (
              ''
            )}
            {isInModes(configurationWeighingModes, 'TARE') && selectedWeighingMode === 'TARE' ? (
              <div className="mb-2 mr-2">
                <CustomButton
                  label={t('actionButtonsTareWeighingButton')}
                  name="tare-weighing"
                  dataFlag="TARE"
                  tooltip={tooltipText('TARE')}
                  disabled={weighingButtonsDisabled.tare}
                  onClick={() => setMeasurementType('TARE')}
                  icon={undefined}
                  className={undefined}
                  type={undefined}
                ></CustomButton>
              </div>
            ) : (
              ''
            )}
            {isInModes(configurationWeighingModes, 'CONTROL') && selectedWeighingMode === 'CONTROL' ? (
              <div className="mb-2 mr-2">
                <CustomButton
                  label={t('actionButtonsControlWeighingButton')}
                  name="control-weighing"
                  dataFlag="CONTROL"
                  tooltip={tooltipText('CONTROL')}
                  disabled={weighingButtonsDisabled.control}
                  onClick={() => setMeasurementType('CONTROL')}
                  icon={undefined}
                  className={undefined}
                  type={undefined}
                ></CustomButton>
              </div>
            ) : (
              ''
            )}
            {selectedWeighingMode === 'CONTAINER' ? (
              <div className="mb-2 mr-2">
                <CustomButton
                  label={t('Taruj')}
                  name="container-tare-weighing"
                  dataFlag="CONTAINER"
                  tooltip={tooltipText('CONTAINER')}
                  onClick={() => setMeasurementType('CONTAINER')}
                  icon={undefined}
                  className={undefined}
                  type={undefined}
                />
              </div>
            ) : (
              ''
            )}
          </>
        ) : (
          <>
            {(isInModes(configurationWeighingModes, 'MULTIPLE') && selectedWeighingMode === 'MULTIPLE') ||
            (isInModes(configurationWeighingModes, 'WEIGHING_SERIES') && selectedWeighingMode === 'WEIGHING_SERIES') ||
            (isInModes(configurationWeighingModes, 'DOUBLE_WEIGHING_SERIES') &&
              selectedWeighingMode === 'DOUBLE_WEIGHING_SERIES') ? (
              <div className="mb-2 mr-2">
                <CustomButton
                  label={t('actionButtonsNextWeighingButton')}
                  name="next-weighing"
                  dataFlag="NEXT"
                  tooltip={tooltipText(selectedWeighingMode)}
                  disabled={weighingButtonsDisabled.next}
                  onClick={() => setMeasurementType('NEXT')}
                  icon={undefined}
                  className={undefined}
                  type={undefined}
                ></CustomButton>
              </div>
            ) : (
              ''
            )}
            {(selectedWeighingMode === 'DOUBLE' ||
              (selectedWeighingMode === 'MULTIPLE' && isOpenWeighing?.measurements?.length > 1) ||
              selectedWeighingMode === 'WEIGHING_SERIES' ||
              (selectedWeighingMode === 'DOUBLE_WEIGHING_SERIES' &&
                isOpenWeighing?.measurements?.length % 2 === 1)) && (
              <div className="mb-2 mr-2">
                <CustomButton
                  label={t('actionButtonsFinalWeighingButton')}
                  name="final-weighing"
                  dataFlag="FINAL"
                  tooltip={tooltipText('FINAL')}
                  disabled={weighingButtonsDisabled.final}
                  onClick={() => setMeasurementType('FINAL')}
                  icon={undefined}
                  className={undefined}
                  type={undefined}
                ></CustomButton>
              </div>
            )}
            {(selectedWeighingMode === 'DOUBLE' ||
              selectedWeighingMode === 'MULTIPLE' ||
              selectedWeighingMode === 'WEIGHING_SERIES' ||
              selectedWeighingMode === 'DOUBLE_WEIGHING_SERIES') && (
              <div className="mb-2 mr-2">
                <Button
                  type="submit"
                  label={t('actionButtonsManualFinalWeighingButton')}
                  name="final-weighing"
                  data-flag="MANUAL_CLOSE"
                  onClick={() => setMeasurementType('MANUAL_CLOSE')}
                ></Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default WeighingActionsButtons;
