import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import validator from 'validator';
import FormErrorMessage from '../components/_shared/FormErrorMessage';
import LanguageSelectorUnauthorized from '../components/_shared/LanguageSelectorUnauthorized';
import usersService from '../services/ScaleoApiServices/UsersService';
import { uiActions } from '../store/ui-slice';
import { useTranslation } from 'react-i18next';

export const SignUp = () => {
  const { t } = useTranslation('signUpPage');

  const history = useHistory();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      companyName: '',
      email: '',
      password: '',
      repeatPassword: '',
      consent: false,
    },
    validate: (data) => {
      const errors = {};

      if (!data.email) {
        errors.email = t('noEmailError');
      } else {
        if (!validator.isEmail(data.email)) {
          errors.email = t('incorrectEmailError');
        }
      }

      if (!data.firstName) {
        errors.firstName = t('noFirstNameError');
      } else {
        if (!data.firstName.match(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/)) {
          errors.firstName = t('incorrectFirstNameError');
        }
      }

      if (!data.lastName) {
        errors.lastName = t('noLastNameError');
      } else {
        if (!data.lastName.match(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/)) {
          errors.lastName = t('incorrectLastNameError');
        }
      }

      if (data.companyName !== '' && !data.companyName.match(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ. 0-9-"']+$/)) {
        errors.companyName = t('incorrectCompanyNameError');
      }

      if (!data.password) {
        errors.password = t('noPasswordError');
      }
      if (!data.repeatPassword) {
        errors.repeatPassword = t('noRepeatPasswordError');
      } else if (data.password !== data.repeatPassword) {
        errors.repeatPassword = t('incorrectRepeatPasswordError');
      }

      if (!data.consent) {
        errors.consent = t('consent');
      }

      return errors;
    },
    onSubmit: (formData, helpers) => {
      dispatch(uiActions.toggleIsLoading({ isLoading: true }));

      usersService
        .signUp({
          email: formData.email,
          password: formData.password,
          firstName: formData.firstName,
          lastName: formData.lastName,
          companyName: formData.companyName,
        })
        .then(() => {
          history.push(`/confirm-sign-up?email=${formData.email}`);
          scheduleHideLoader();
        })
        .catch((error) => {
          scheduleHideLoader();
          const errorCode = error.response.data.error;
          // TODO: i18n
          errorCode === 'CUSTOMER_COMPANY_NAME_EXISTS' &&
            helpers.setFieldError('companyName', t('customerCompanyNameExistsError'));
          errorCode === 'EMAIL_EXISTS' && helpers.setFieldError('email', t('emailExistsError'));
          errorCode === 'INVALID_PASSWORD' && helpers.setFieldError('password', t('invalidPasswordError'));
        });
    },
  });

  const scheduleHideLoader = () => {
    setTimeout(() => {
      dispatch(uiActions.toggleIsLoading({ isLoading: false }));
    }, 400);
  };

  return (
    <div className="login-body">
      <div className="card login-panel p-fluid">
        <LanguageSelectorUnauthorized visible={false} />
        <div className="login-panel-content">
          <form>
            <div className="grid">
              <div className="col-12 logo-container">
                <img src="assets/layout/images/logo_scaleo.png" className="w-100" alt={t('logoAlt')} />
              </div>
              <div className="col-12 sm:col-6 md:col-6">
                <span className="guest-sign-in">{t('header')}</span>
              </div>
              <div className="col-12">
                <label htmlFor="email">{t('email')}</label>
                <InputText id="email" value={formik.values.email} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="email" formikInstance={formik} />
              </div>
              <div className="col-12">
                <label htmlFor="firstName">{t('firstName')}</label>
                <InputText id="firstName" value={formik.values.firstName} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="firstName" formikInstance={formik} />
              </div>
              <div className="col-12">
                <label htmlFor="lastName">{t('lastName')}</label>
                <InputText id="lastName" value={formik.values.lastName} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="lastName" formikInstance={formik} />
              </div>
              <div className="col-12">
                <label htmlFor="companyName">{t('companyName')}</label>
                <InputText id="companyName" value={formik.values.companyName} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="companyName" formikInstance={formik} />
              </div>
              <div className="col-12 password-container">
                <label>{t('password')}</label>
                <Password
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  feedback={false}
                  toggleMask
                />
                <FormErrorMessage fieldName="password" formikInstance={formik} />
              </div>
              <div className="col-12 password-container">
                <label>{t('repeatPassword')}</label>
                <Password
                  id="repeatPassword"
                  name="repeatPassword"
                  value={formik.values.repeatPassword}
                  onChange={formik.handleChange}
                  feedback={false}
                  toggleMask
                />
                <FormErrorMessage fieldName="repeatPassword" formikInstance={formik} />
              </div>
              <div className="col-12">
                <label>
                  {t('confirmRules1')}
                  <a href="https://scaleo.eu/files/regulamin.pdf" target="_blank" rel="noreferrer">
                    {t('rules')}
                  </a>{' '}
                  {t('confirmRules2')}
                  <a href="https://scaleo.eu/files/polityka_prywatnosci.pdf" target="_blank" rel="noreferrer">
                    {t('privacyPolicy')}
                  </a>
                  .
                </label>
                <InputSwitch id="consent" checked={formik.values.consent} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="consent" formikInstance={formik} />
              </div>
            </div>
          </form>

          <div className="grid">
            <div className="col-6">
              <Button type="submit" label={t('submitButton')} icon="pi pi-check" onClick={formik.submitForm} />
            </div>
            <div className="col-6">
              {t('question')}&nbsp;
              <br />
              <a href="#/login" className="sign-up">
                {t('login')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
