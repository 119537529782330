import { Dialog } from 'primereact/dialog';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Station } from '../../../../types/station';

interface DeleteStationDialogProps {
  deleteStationDialogVisible: boolean;
  hideDeleteStationDialog: () => void;
  station: Station;
  deleteStation: () => void;
}

export const DeleteStationDialog = ({
  deleteStationDialogVisible,
  hideDeleteStationDialog,
  station,
  deleteStation,
}: DeleteStationDialogProps) => {
  const { t } = useTranslation('managementStations');

  const deleteStationDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteStationDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteStation} />
    </>
  );

  return (
    <Dialog
      visible={deleteStationDialogVisible}
      header={
        <span className="flex align-items-center">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
          {t('confirm')}
        </span>
      }
      modal
      className="p-fluid min-width-450px"
      footer={deleteStationDialogFooter}
      onHide={hideDeleteStationDialog}
      breakpoints={{ '896px': '90vw' }}
    >
      <div className="confirmation-content">
        {station && (
          <span>
            <Trans t={t} i18nKey="deleteStationContent" values={{ station: station.name }} />
          </span>
        )}
      </div>
    </Dialog>
  );
};
