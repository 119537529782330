import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import weighingsService from '../../../../../../services/ScaleoApiServices/WeighingsService';
import { formatDateTime } from '../../../../../../utils/formatUtils';
import useTransactionType from '../../../../../../utils/useTransactionType';
import { useTranslation } from 'react-i18next';
import { WeighingContainer } from '../../../../../../store/types/weighing-slice';
import { Trailer } from '../../../../../../types/trailer';
import CustomDivider from '../../../../../_shared/CustomDivider';

interface HistoryPluginProps {
  weighingId: string;
  alwaysVisible?: boolean;
  noInnerTitle?: boolean;
}

const HistoryPlugin = ({ weighingId, alwaysVisible, noInnerTitle }: HistoryPluginProps) => {
  const { t } = useTranslation('weighingWeighingList');

  const [editHistoryVisible, setEditHistoryVisible] = useState(false);
  const [records, setRecords] = useState<EditHistoryRow[] | null>(null);

  const { getTransactionTypeLabel } = useTransactionType();

  const STATUS_LABELS = {
    active: t('weighingDialogHistoryLabelActive'),
    deleted: t('weighingDialogHistoryLabelDeleted'),
    RECEIVE: t('weighingDialogHistoryLabelReceive'),
    DELIVERY: t('weighingDialogHistoryLabelDelivery'),
  };

  type StatusLabel = keyof typeof STATUS_LABELS;

  useEffect(() => {
    void weighingsService.getWeighingEditHistory(weighingId).then((response) => {
      setRecords(response.data);
    });
  }, []);

  const ChangedRecordTemplate = ({ label, beforeText, afterText }: ChangedRecordTemplateProps) => (
    <div className="grid align-items-center">
      <div className="col-3 text-left">{label}:</div>
      <div className="col-3 text-center" style={{ color: '#d32f2f' }}>
        {beforeText}
      </div>
      <div className="col-3 text-center">
        <i className={'pi pi-arrow-right'}></i>
      </div>
      <div className="col-3 text-center" style={{ color: '#689f38' }}>
        {afterText}
      </div>
    </div>
  );

  const changedRecordsByName = (label: string, row: any[]) => (
    <ChangedRecordTemplate label={label} beforeText={row[0]?.name} afterText={row[1]?.name} />
  );

  const changedRecordsTimestamp = (label: string, row: any[]) => {
    const beforeTimestamp = row[0]?.name ? new Date(row[0].name) : '';
    const afterTimestamp = row[1]?.name ? new Date(row[1].name) : '';
    return (
      <ChangedRecordTemplate
        label={label}
        beforeText={beforeTimestamp.toLocaleString()}
        afterText={afterTimestamp.toLocaleString()}
      />
    );
  };

  const changedRecordsSingle = (label: string, row: StatusLabel[]) => (
    <ChangedRecordTemplate label={label} beforeText={STATUS_LABELS[row[0]]} afterText={STATUS_LABELS[row[1]]} />
  );

  const changedRecordsBdo = (label: string, row: Array<any>) => {
    const names = {
      kpo: t('weighingDialogHistoryLabelKpo'),
      'kpok-receive': t('weighingDialogHistoryLabelKpokReceive'),
      'kpok-transfer': t('weighingDialogHistoryLabelKpokTransfer'),
    };

    return (
      <ChangedRecordTemplate
        label={label}
        beforeText={
          row[0]?.name ? (
            <>
              {names[row[0]?.name as keyof typeof names]}
              <br />
              <small>id: {row[0]?.id}</small>
            </>
          ) : (
            '-'
          )
        }
        afterText={
          row[1]?.name ? (
            <>
              {names[row[1]?.name as keyof typeof names]}
              <br />
              <small>id: {row[1]?.id}</small>
            </>
          ) : (
            '-'
          )
        }
      />
    );
  };

  const changedRecordsAdditionalField = (label: string, values: any[], id?: string) => (
    <ChangedRecordTemplate
      label={`${label}${id ? ` [${id}]` : ''}`}
      beforeText={values[0] || '-'}
      afterText={values[1] || '-'}
      key={id}
    />
  );

  const changedRecordsMeasurement = (measurement: any, label: string, isChangedMeasurement: boolean) =>
    isChangedMeasurement ? (
      <>
        <h6>{label}</h6>
        {measurement?.indication && (
          <ChangedRecordTemplate
            label={t('weighingDialogHistoryLabelIndication')}
            beforeText={measurement.indication[0]}
            afterText={measurement.indication[1]}
          />
        )}
        {measurement?.timestamp && (
          <ChangedRecordTemplate
            label={t('weighingDialogHistoryLabelDate')}
            beforeText={measurement.timestamp[0]}
            afterText={measurement.timestamp[1]}
          />
        )}
        {measurement?.scale && (
          <ChangedRecordTemplate
            label={t('weighingDialogHistoryLabelScale')}
            beforeText={measurement.scale[0]?.name}
            afterText={measurement.scale[1]?.name}
          />
        )}
        {measurement?.product && (
          <ChangedRecordTemplate
            label={t('weighingDialogHistoryLabelProduct')}
            beforeText={measurement.product[0]?.name}
            afterText={measurement.product[1]?.name}
          />
        )}
        <CustomDivider />
      </>
    ) : (
      <>
        <h6>{label}</h6>
        <p>
          {measurement?.indication && `${t('weighingDialogHistoryLabelIndication')}: ${measurement.indication} kg `}
        </p>
        <p>
          {measurement?.timestamp &&
            `${t('weighingDialogHistoryLabelDate')}: ${new Date(measurement.timestamp).toLocaleString()} `}
        </p>
        <p>{measurement?.scale && `${t('weighingDialogHistoryLabelScale')}: ${measurement.scale?.name}`}</p>
        <CustomDivider />
      </>
    );

  const changedRecordsContainer = (containers: WeighingContainer[]) => {
    if (containers[0] && containers[1]) {
      return (
        <>
          <h6>{`${t('weighingDialogHistoryLabelChangeContainer')}: ${containers[0].name}(${containers[0].code}):`}</h6>
          {containers[0].name !== containers[1].name && (
            <ChangedRecordTemplate
              label={t('weighingDialogHistoryLabelName')}
              beforeText={containers[0].name}
              afterText={containers[1].name}
            />
          )}
          {containers[0].code !== containers[1].code && (
            <ChangedRecordTemplate
              label={t('weighingDialogHistoryLabelCode')}
              beforeText={containers[0].code}
              afterText={containers[1].code}
            />
          )}
          {containers[0].tareValue !== containers[1].tareValue && (
            <ChangedRecordTemplate
              label={t('weighingDialogHistoryLabelTare')}
              beforeText={containers[0].tareValue}
              afterText={containers[1].tareValue}
            />
          )}
          {containers[0].receiveNumber !== containers[1].receiveNumber && (
            <ChangedRecordTemplate
              label={t('weighingDialogHistoryLabelReceiveContainer')}
              beforeText={containers[0].receiveNumber}
              afterText={containers[1].receiveNumber}
            />
          )}
          {containers[0].returnNumber !== containers[1].returnNumber && (
            <ChangedRecordTemplate
              label={t('weighingDialogHistoryLabelReturn')}
              beforeText={containers[0].returnNumber}
              afterText={containers[1].returnNumber}
            />
          )}
          <CustomDivider />
        </>
      );
    } else if (containers[0] === null) {
      return (
        <>
          <h6>{t('weighingDialogHistoryLabelAddContainer')}</h6>
          <p>{`${t('weighingDialogHistoryLabelName')}: ${containers[1].name}`}</p>
          <p>{`${t('weighingDialogHistoryLabelCode')}: ${containers[1].code}`}</p>
          <p>{`${t('weighingDialogHistoryLabelTare')}: ${containers[1].tareValue}`}</p>
          <p>{`${t('weighingDialogHistoryReceiveNumber')}: ${containers[1].receiveNumber}`}</p>
          <p>{`${t('weighingDialogHistoryReturnNumber')}: ${containers[1].returnNumber}`}</p>
          <CustomDivider />
        </>
      );
    } else {
      return (
        <>
          <h6>{t('weighingDialogHistoryLabelRemoveContainer')}</h6>
          <p>{`${t('weighingDialogHistoryLabelName')}: ${containers[0].name}`}</p>
          <p>{`${t('weighingDialogHistoryLabelCode')}: ${containers[0].code}`}</p>
          <p>{`${t('weighingDialogHistoryLabelTare')}: ${containers[0].tareValue}`}</p>
          <p>{`${t('weighingDialogHistoryReceiveNumber')}: ${containers[0].receiveNumber}`}</p>
          <p>{`${t('weighingDialogHistoryReturnNumber')}: ${containers[0].returnNumber}`}</p>
          <CustomDivider />
        </>
      );
    }
  };

  const changedRecordsTransactionType = (label: string, values: any[]) => (
    <ChangedRecordTemplate
      label={label}
      beforeText={getTransactionTypeLabel(values[0]) || '-'}
      afterText={getTransactionTypeLabel(values[1]) || '-'}
    />
  );

  const renderHistoryRow = (col: EditHistoryRow) => {
    const changedProducts =
      col.data?.product &&
      Object.entries(col.data.product).map((col2: any, i: number) => {
        return (
          <div key={'product' + i}>
            {col2[1]?.length > 0 &&
              changedRecordsByName(
                `${t('weighingDialogHistoryLabelProduct')} ${col2[0] !== '0' ? col2[0] : ''} `,
                col2[1],
              )}
          </div>
        );
      });
    return (
      <div key={col.id} className="card pb-0">
        <div className="grid mb-0">
          <div className="text-left col pb-0"> {formatDateTime(col.timestamp)}</div>
          <div className="text-middle col pb-0">
            {col.data.updateWasteRegisterStatusError && t('weighingDialogHistoryLabelWasteRegisterUpdateStatus')}
            {col.data.closedType &&
              `${t('weighingDialogHistoryLabelWeighingClosed')} ${
                col.data.closedType === 'MANUAL'
                  ? t('weighingDialogHistoryLabelManual')
                  : t('weighingDialogHistoryLabelAutomatic')
              }`}
            {col.data.isSplitted && t('weighingDialogHistoryLabelSplit')}
            {col.data.isOpened && t('weighingDialogHistoryLabelOpen')}
            {col.data.isConfirmed === true && t('weighingDialogHistoryLabelConfirmed')}
            {col.data.isConfirmed === false && t('weighingDialogHistoryLabelWithdrawalConfirmation')}
            {col.data.isConfirmed && col.data.comment && (
              <>
                <br />
                {`${t('weighingDialogHistoryLabelComment')}: ${col.data.comment}`}
              </>
            )}
            {col.data.isConfirmed && col.data.measurementIndex && (
              <>
                <br />
                {`${t('weighingDialogHistoryLabelMeasurementIndex')}: ${col.data.measurementIndex}`}
              </>
            )}
          </div>
          <div className="text-right col font-italic pb-0">{col.user}</div>
        </div>
        <CustomDivider />
        {col.data.measurements &&
          col.data.measurements.map((measurement) =>
            measurement.deleted
              ? changedRecordsMeasurement(measurement.deleted, t('deletedMeasurement'), false)
              : changedRecordsMeasurement(measurement, t('changedMeasurement'), true),
          )}
        {col.data.additionalMeasurements &&
          col.data.additionalMeasurements.map((measurement) =>
            changedRecordsMeasurement(measurement, t('addedMeasurement'), false),
          )}
        {col.data.closedAt?.length > 0 && changedRecordsTimestamp(t('Data zamknięcia'), col.data.closedAt)}
        {col.data.brutto?.length > 0 && changedRecordsByName(t('weighingDialogHistoryLabelBrutto'), col.data.brutto)}
        {col.data.number?.length > 0 && changedRecordsByName(t('weighingDialogHistoryLabelNumber'), col.data.number)}
        {col.data.location?.length > 0 &&
          changedRecordsByName(t('weighingDialogHistoryLabelLocation'), col.data.location)}
        {col.data.vehicle?.length > 0 && changedRecordsByName(t('weighingDialogHistoryLabelVehicle'), col.data.vehicle)}
        {col.data.trailer?.length > 0 && changedRecordsByName(t('weighingDialogHistoryLabelTrailer'), col.data.trailer)}
        {col.data.contractor?.length > 0 &&
          changedRecordsByName(t('weighingDialogHistoryLabelContractor'), col.data.contractor)}
        {col.data.driver?.length > 0 && changedRecordsByName(t('weighingDialogHistoryLabelDriver'), col.data.driver)}
        {col.data.transactionType?.length > 0 &&
          changedRecordsTransactionType(t('weighingDialogHistoryLabelWeighingType'), col.data.transactionType)}
        {col.data.status?.length > 0 && changedRecordsSingle(t('weighingDialogHistoryLabelStatus'), col.data.status)}
        {col.data.bdoCard?.length > 0 && changedRecordsBdo(t('weighingDialogHistoryLabelBdoCard'), col.data.bdoCard)}
        {col.data.additionalFields &&
          col.data.additionalFields.map((field: any) => changedRecordsAdditionalField(field.name, field.value))}
        {col.data.measurementsAdditionalFields &&
          col.data.measurementsAdditionalFields.map((row: any) =>
            row.map((field: any) => changedRecordsAdditionalField(field.name, field.value, field.id)),
          )}
        {col.data.containers && col.data.containers.map((c) => changedRecordsContainer(c))}
        {changedProducts}
      </div>
    );
  };

  if (!editHistoryVisible && !alwaysVisible) {
    return (
      <>
        <Button
          type="button"
          onClick={() => setEditHistoryVisible(true)}
          label={t('weighingDialogHistoryLabelHistory')}
          className="mr-2"
        />
        <br />
        <br />
      </>
    );
  } else {
    return (
      <>
        {!alwaysVisible && (
          <Button
            type="button"
            onClick={() => setEditHistoryVisible(false)}
            label={t('weighingDialogHistoryLabelHide')}
            className="mr-2"
          ></Button>
        )}
        {!noInnerTitle && (
          <>
            <br />
            <br />
            <b>{t('weighingDialogHistoryLabelTitle')}</b>
            <br />
            <br />
          </>
        )}
        {records?.length && records?.length > 0
          ? records.map(renderHistoryRow)
          : t('weighingDialogHistoryLabelNoHistory')}
        <br />
        <br />
      </>
    );
  }
};

export default HistoryPlugin;

interface ChangedRecordTemplateProps {
  label: string;
  beforeText: any;
  afterText: any;
}

type EditHistoryRowData = {
  number: string[];
  location: string[];
  additionalFields: any[];
  bdoCard: any[];
  brutto: any[];
  closedType: string;
  contractor: any[];
  driver: any[];
  measurementsAdditionalFields: any[];
  product: any[];
  status: any[];
  transactionType: any[];
  vehicle: any[];
  trailer: Trailer[];
  isSplitted: boolean;
  isOpened: boolean;
  isConfirmed: boolean | null;
  comment: string | null;
  measurementIndex: number | null;
  closedAt: any[];
  additionalMeasurements: any[];
  measurements: any[];
  updateWasteRegisterStatusError: boolean;
  containers: WeighingContainer[][];
};

type EditHistoryRow = {
  data: EditHistoryRowData;
  id: string;
  timestamp: number;
  user: any;
};
