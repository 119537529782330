import { useTranslation } from 'react-i18next';
import '../../../DataTable.css';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import { CustomAutoComplete } from '../../../_shared/CustomComponents';
import { Checkbox } from 'primereact/checkbox';
import { MultiSelect } from 'primereact/multiselect';
import { ConnectionType } from '../../../../enums/connection-type.enum';
import { filterCameras } from '../helpers';
import multiSelectPanelHeader from '../../../_shared/multiSelectPanelHeader';
import { ManagementNode } from '../types';

interface WeighingIndicatorFieldsProps {
  formik: any;
  data: ManagementNode;
  onSelectedCamerasChanged: (e: any, f: any) => void;
  selectedItemsLabel: () => string;
}

const WeighingIndicatorFields = ({
  formik,
  data,
  onSelectedCamerasChanged,
  selectedItemsLabel,
}: WeighingIndicatorFieldsProps) => {
  const { t } = useTranslation('managementNodes');

  return (
    <>
      <div className="field-checkbox">
        <Checkbox id="testScale" checked={formik.values.testScale} onChange={formik.handleChange} />
        <label htmlFor="testScale" className="checkbox-label">
          {t('testScale')}
        </label>
      </div>

      <div className="field">
        <label htmlFor="name">{t('name')}</label>
        <InputText id="name" value={formik.values.name as any} onChange={formik.handleChange} autoFocus />
        <FormErrorMessage fieldName="name" formikInstance={formik} />
      </div>

      <div className="field">
        <label htmlFor="station">{t('station')}</label>
        <CustomAutoComplete
          id="station"
          value={formik.values.station}
          handler={(e: any) => {
            formik.setFieldValue('station', {
              automationType: e?.value?.automation_type || e?.target?.value?.automation_type,
              id: e?.value?.id || e?.target?.value?.id,
              installationId: e?.value?.installation_id || e?.target?.value?.installation_id,
              name: e?.value?.name || e?.target?.value?.name,
              type: e?.value?.type || e?.target?.value?.type,
            });

            formik.setFieldValue('selectedCameras', []);
          }}
          source={data.stations}
        />
        <FormErrorMessage fieldName="station" formikInstance={formik} />
      </div>

      <div className="field">
        <label htmlFor="nodeModel">{t('nodeModel')}</label>
        <CustomAutoComplete
          id="nodeModel"
          value={formik.values.nodeModel}
          handler={formik.handleChange}
          source={data.nodeModelsByNodeType[formik.values.type]}
          lookupField="name"
        />
        <FormErrorMessage fieldName="nodeModel" formikInstance={formik} />
      </div>

      {!formik.values.testScale && (
        <>
          <div className="field">
            <label htmlFor="connectionType">{t('connectionType')}</label>
            <Dropdown
              id="connectionType"
              value={formik.values.connectionType}
              onChange={formik.handleChange}
              options={[
                { name: ConnectionType.TCP, label: t('tcp') },
                { name: ConnectionType.SERIAL_PORT, label: t('serialPort') },
              ]}
              optionValue="name"
              optionLabel="label"
            />
            <FormErrorMessage fieldName="scale" formikInstance={formik} />
          </div>
          {formik.values.connectionType !== ConnectionType.SERIAL_PORT && (
            <div className="field">
              <label htmlFor="host">{t('host')}</label>
              <InputText id="host" value={formik.values.host!} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="host" formikInstance={formik} />
            </div>
          )}

          <div className="field">
            <label htmlFor="port">{t('port')}</label>
            <InputText id="port" value={formik.values.port!} onChange={formik.handleChange} />
            <FormErrorMessage fieldName="port" formikInstance={formik} />
          </div>

          {formik.values.connectionType === ConnectionType.SERIAL_PORT && (
            <div className="field">
              <label htmlFor="baudrate">{t('baudrate')}</label>
              <InputText id="baudrate" value={formik.values.baudrate!} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="baudrate" formikInstance={formik} />
            </div>
          )}
        </>
      )}

      <div className="field">
        <label htmlFor="weighingUnit">{t('weighingUnit')}</label>
        <CustomAutoComplete
          id="weighingUnit"
          value={formik.values.weighingUnit}
          handler={formik.handleChange}
          source={data.weighingUnits}
        />
        <FormErrorMessage fieldName="weighingUnit" formikInstance={formik} />
      </div>

      <div className="field">
        <label htmlFor="division">{t('division')}</label>
        <CustomAutoComplete
          id="division"
          value={formik.values.division}
          handler={formik.handleChange}
          source={data.divisionsScale}
          scrollHeight={'110px' as any}
        />
        <FormErrorMessage fieldName="division" formikInstance={formik} />
      </div>

      <div className="field">
        <label htmlFor="maxWeight">{t('maxWeight')}</label>
        <InputText id="maxWeight" value={formik.values.maxWeight as any} onChange={formik.handleChange} />
        <FormErrorMessage fieldName="maxWeight" formikInstance={formik} />
      </div>

      {!formik.values.testScale && (
        <>
          <div className="field">
            <label htmlFor="selectedCameras">{t('selectedCameras')}</label>
            <MultiSelect
              value={formik.values.selectedCameras}
              onChange={(e: any) => onSelectedCamerasChanged(e, formik)}
              options={filterCameras(formik.values?.station, data.cameras)}
              optionLabel="name"
              optionValue="id"
              placeholder={
                formik.values?.station
                  ? t('noSelectedCamerasPlaceholder')
                  : t('noSelectedCamerasPlaceholderSelectStation')
              }
              className="multiselect-custom"
              maxSelectedLabels={0}
              selectedItemsLabel={selectedItemsLabel()}
              showClear={true}
              panelHeaderTemplate={multiSelectPanelHeader}
            />
            <FormErrorMessage fieldName="selectedCameras" formikInstance={formik} />
          </div>

          <div className="field-checkbox">
            <Checkbox
              id="playSoundOnSpecifiedWeight"
              checked={formik.values.playSoundSpecifiedWeight !== null}
              onChange={() => {
                formik.values.playSoundSpecifiedWeight !== null
                  ? formik.setFieldValue('playSoundSpecifiedWeight', null)
                  : formik.setFieldValue('playSoundSpecifiedWeight', 0);
              }}
            />
            <label htmlFor="playSoundOnSpecifiedWeight" className="checkbox-label">
              {t('playSoundOnSpecifiedWeight')}
            </label>
          </div>

          {formik.values.playSoundSpecifiedWeight !== null && (
            <div className="field">
              <label htmlFor="playSoundSpecifiedWeight">{t('playSoundSpecifiedWeight')}</label>
              <InputText
                id="playSoundSpecifiedWeight"
                value={formik.values.playSoundSpecifiedWeight}
                onChange={formik.handleChange}
              />
              <FormErrorMessage fieldName="playSoundSpecifiedWeight" formikInstance={formik} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default WeighingIndicatorFields;
