import React from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Error = () => {
  const { t } = useTranslation('errorPage');

  const history = useHistory();

  return (
    <div className="exception-body error">
      <div className="exception-panel">
        <div className="exception-content">
          <img src="assets/layout/images/pages/icon-error.svg" alt="roma" />
          <h1>{t('header')}</h1>
          <p>{t('content')}</p>
          <Button
            label={t('submitButton')}
            icon="pi pi-arrow-left"
            onClick={() => {
              history.push('/');
            }}
          />
        </div>
      </div>
    </div>
  );
};
