import { Fieldset } from 'primereact/fieldset';
import { Tag } from 'primereact/tag';
import { Timeline } from 'primereact/timeline';
import React from 'react';
import { useSelector } from 'react-redux';
import { formatDecimal, formatTime } from '../../../../../../utils/formatUtils';
import { useTranslation } from 'react-i18next';

const WeighingState = () => {
  const { weighingState } = useSelector((state) => state.weighing);
  const unit = useSelector((state) => state.appConfig.unit);

  const { t } = useTranslation('weighingHandlingWeighings');

  const getWeighingStateTimelineData = () => {
    const data = [];
    if (weighingState?.openWeighing) {
      data.push({
        label: t('enter'),
        time: formatTime(weighingState?.openWeighing?.measurements[0].timestamp),
      });
      weighingState?.openWeighing?.measurements.forEach((measurement, index) => {
        data.push({
          label: `${t('measurement')} ${index + 1}`,
          indication: `${formatDecimal(measurement.indication)} ${unit}`,
          time: formatTime(measurement.timestamp),
        });
      });
    }
    return data;
  };

  return (
    <Fieldset legend={t('weighingState')}>
      <div className="field grid">
        {weighingState?.openWeighing && (
          <>
            <Timeline
              value={getWeighingStateTimelineData()}
              opposite={(item) => (
                <>
                  <Tag className="mr-2" value={item.label}></Tag>
                  <strong>{item.indication}</strong>
                </>
              )}
              content={(item) => <small className="text-secondary">{item.time && `${item.time}`}</small>}
            />
          </>
        )}
        {!weighingState?.openWeighing && (
          <label className="col-5">
            <strong>{t('missingFirstWeighing')}</strong>
          </label>
        )}

        <div className="col-7">
          <span></span>
        </div>
      </div>
    </Fieldset>
  );
};

export default WeighingState;
