import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { Chips } from 'primereact/chips';
import FormErrorMessage from '../../../../_shared/FormErrorMessage';
import { useTranslation } from 'react-i18next';

interface ReceiptAutoSendSectionProps {
  formikInstance: any;
  handleChangeField: Function;
}

const ReceiptAutoSendSection = ({ formikInstance, handleChangeField }: ReceiptAutoSendSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationPrints');

  return (
    <Fieldset legend={t('automaticSendingReceipts')} className="mt-1vh">
      <div className="grid pt-1vh">
        <div className="col-12 lg:col-5">
          <span className="field-checkbox md:col-12 pb-0">
            <Checkbox
              id="receiptAutoSend"
              checked={formikInstance.values.receiptAutoSend}
              onChange={(e) => handleChangeField(e, 'checkbox')}
            />
            <label htmlFor="receiptAutoSend" className="checkbox-label">
              {t('automaticSendingReceipts')}
            </label>
          </span>
          <FormErrorMessage className="p-2 ml-3 mb-3" fieldName="receiptAutoSend" formikInstance={formikInstance} />
          <span className="field-checkbox md:col-12">
            <Checkbox
              id="receiptSendToDrivers"
              checked={formikInstance.values.receiptSendToDrivers}
              onChange={(e) => handleChangeField(e, 'checkbox')}
            />
            <label htmlFor="receiptSendToDrivers" className="checkbox-label">
              {t('sendingReceiptsToDrivers')}
            </label>
          </span>
          <span className="field-checkbox md:col-12">
            <Checkbox
              id="receiptSendToContractors"
              checked={formikInstance.values.receiptSendToContractors}
              onChange={(e) => handleChangeField(e, 'checkbox')}
            />
            <label htmlFor="receiptSendToContractors" className="checkbox-label">
              {t('sendingReceiptsToContractors')}
            </label>
          </span>
        </div>
        <div className="col-12 lg:col-7 p-fluid mt-2">
          {t('defaultMailsToSendReceipt')}
          <br />
          <Chips
            id="receiptEmails"
            value={formikInstance.values.receiptEmails}
            onChange={(e) => handleChangeField(e, 'chips')}
            className="pt-1"
          />
          <FormErrorMessage fieldName="receiptEmails" formikInstance={formikInstance} />
        </div>
      </div>
    </Fieldset>
  );
};

export default ReceiptAutoSendSection;
