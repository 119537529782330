import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { weighingActions } from '../../../../../store/weighing-slice';
import '../../../../DataTable.css';
import { ReducerState } from '../../../../../types/reducer-state';
import { TabView, TabPanel } from 'primereact/tabview';
import OpenOrNotConfirmedWeighingsList from './OpenOrNotConfirmedWeighingsList';
import { isModulesEnabled } from '../../../../../utils/modulesUtils';
import weighingsService from '../../../../../services/ScaleoApiServices/WeighingsService';
import { hasPermissions } from '../../../../../utils/permissionsUtils';
import { useTranslation } from 'react-i18next';
import { Weighing } from '../../../../../types/weighing';

const SECOND = 1000;

interface OpenAndNotConfirmedWeighingsListWrapperProps {
  onWeighingCloseRequested: (a: any) => void;
  optionsData: any;
  weighingConfiguration: any;
  isInactive: boolean;
  setCloseWeighing: (w: Weighing) => void;
  isCorrectBdoModuleConfig: boolean;
}

const OpenAndNotConfirmedWeighingsListWrapper = ({
  optionsData,
  onWeighingCloseRequested,
  weighingConfiguration,
  isInactive,
  setCloseWeighing,
  isCorrectBdoModuleConfig,
}: OpenAndNotConfirmedWeighingsListWrapperProps) => {
  const { t } = useTranslation('weighingHandlingWeighings');

  const dispatch = useDispatch();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [time, setTime] = useState<number | undefined>(undefined);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const { allOpenWeighings, allNotConfirmedWeighings, shouldReloadHandlingWeighingsData } = useSelector(
    (state: ReducerState) => state.weighing,
  );
  const [showDeletedOpenWeighings, setShowDeletedOpenWeighings] = useState(false);
  const [showDeletedNotConfirmedWeighings, setShowDeletedNotConfirmedWeighings] = useState(false);
  const [blockReloadingWeighings, setBlockReloadingWeighings] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 10 * SECOND);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const displaysAllThumbnails = () =>
    allOpenWeighings.length === allOpenWeighings.filter((weighing) => !!weighing.thumbnail).length;

  const prepareWeighingsObject = (weighingsData: any[]) => {
    return weighingsData.map((w) => {
      return {
        ...w,
        firstMeasurementIndication: ['DOUBLE', 'UNKNOWN'].includes(w.weighingMode)
          ? w.measurements[0].indication
          : null,
      };
    });
  };

  const fetchComponentData = useCallback(() => {
    Promise.all([
      weighingsService.searchOpenWeighings(showDeletedOpenWeighings, { show_loader: false }),
      weighingsService.searchNotConfirmedWeighings(showDeletedNotConfirmedWeighings, { show_loader: false }),
    ]).then((values) => {
      dispatch(weighingActions.setAllOpenWeighings(prepareWeighingsObject([...values[0].data])));
      dispatch(weighingActions.setAllNotConfirmedWeighings(prepareWeighingsObject([...values[1].data])));
    });
  }, [dispatch, showDeletedNotConfirmedWeighings, showDeletedOpenWeighings]);

  useEffect(() => {
    const shouldReloadOpenAndNotConfirmedWeighings =
      !displaysAllThumbnails() &&
      isModulesEnabled(['CAMERAS'], loggedUserContext.currentCustomer?.subscriptionModules) &&
      !blockReloadingWeighings &&
      isInactive;

    if (shouldReloadOpenAndNotConfirmedWeighings || !time) {
      fetchComponentData();
    }
  }, [fetchComponentData, loggedUserContext.currentLocationId, time]);

  useEffect(() => {
    if (shouldReloadHandlingWeighingsData) {
      fetchComponentData();
      dispatch(weighingActions.handlingWeighingsDataReloaded());
    }
  }, [fetchComponentData, shouldReloadHandlingWeighingsData]);

  useEffect(() => {
    fetchComponentData();
    dispatch(weighingActions.handlingWeighingsDataReloaded());
  }, [fetchComponentData, showDeletedNotConfirmedWeighings, showDeletedOpenWeighings]);

  const confirmWeighingsList = () => {
    switch (optionsData.configuration?.confirmationRequiredCondition) {
      case 'AFTER_EACH_MEASUREMENT':
        return allNotConfirmedWeighings.filter((w) => w.measurements?.find((m: any) => !m.isConfirmed));
      case 'AFTER_CLOSING_WEIGHING':
        return allNotConfirmedWeighings.filter((w) => !w.isConfirmed && w.closed);
      default:
        return [];
    }
  };

  const handleConfirmWeighingsListClickRow = ({ data, originalEvent, isManualClick }: any) => {
    const canConfirmWeighing = originalEvent?.target?.classList?.contains('continue') || isManualClick;
    (canConfirmWeighing || (!data.vehicle && originalEvent?.target?.classList.length === 0)) &&
      dispatch(
        weighingActions.setWeighingSummaryDialogProps({
          visible: true,
          weighingId: data.id,
          lastShowedWeighingMeasurementId: null,
          isConfirmedClick: true,
        }),
      );
  };

  const handleOpenWeighingsListClickRow = ({ data, originalEvent }: any) => {
    const canContinueWeighing = originalEvent?.target?.classList?.contains('continue');

    !!canContinueWeighing &&
      dispatch(weighingActions.updateFormState({ selectedVehicle: data.vehicle, continueWeighing: data }));
  };

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card ">
          {optionsData.configuration?.weighingConfirmation &&
            hasPermissions(['WEIGHINGS_CONFIRMATION'], loggedUserContext.permissions) &&
            optionsData.configuration?.confirmationRequiredCondition !== 'NEVER' && (
              <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
                <TabPanel header={t('openWeighingsList')} />
                <TabPanel header={t('notConfirmedWeighingsList')} />
              </TabView>
            )}
          {activeTabIndex === 0 && (
            <OpenOrNotConfirmedWeighingsList
              onWeighingCloseRequested={onWeighingCloseRequested}
              optionsData={optionsData}
              openOrToConfirmWeighings={allOpenWeighings}
              handleClickRow={handleOpenWeighingsListClickRow}
              fetchComponentData={fetchComponentData}
              isCorrectBdoModuleConfig={isCorrectBdoModuleConfig}
              setShowDeletedWeighings={setShowDeletedOpenWeighings}
              showDeletedWeighings={showDeletedOpenWeighings}
              weighingConfiguration={weighingConfiguration}
              setBlockReloadingWeighings={setBlockReloadingWeighings}
              setCloseWeighing={setCloseWeighing}
            />
          )}
          {activeTabIndex === 1 && (
            <OpenOrNotConfirmedWeighingsList
              onWeighingCloseRequested={onWeighingCloseRequested}
              optionsData={optionsData}
              openOrToConfirmWeighings={confirmWeighingsList()}
              handleClickRow={handleConfirmWeighingsListClickRow}
              fetchComponentData={fetchComponentData}
              isCorrectBdoModuleConfig={isCorrectBdoModuleConfig}
              setShowDeletedWeighings={setShowDeletedNotConfirmedWeighings}
              showDeletedWeighings={showDeletedNotConfirmedWeighings}
              setBlockReloadingWeighings={setBlockReloadingWeighings}
              isNotConfirmedList
              weighingConfiguration={weighingConfiguration}
              setCloseWeighing={setCloseWeighing}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OpenAndNotConfirmedWeighingsListWrapper;
