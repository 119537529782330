import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Control } from '../types/controls';
import { ReducerState } from '../types/reducer-state';
import { Node } from '../types/node';

const checkPermissionsToControls = (controls: Control[], nodes: Node[], userNodes: Node[]) => {
  const permittedNodes = nodes.filter((node) => userNodes.findIndex((userNode) => userNode.id === node.id) > -1);
  const permittedNodesAliases = permittedNodes.map((node) => node.alias);
  return controls.filter((control) => {
    return permittedNodesAliases.includes(control.node);
  });
};

const useControls = () => {
  const [controls, setControls] = useState<any[]>([]);
  const { selectedScale, automationCurrentMode } = useSelector((state: ReducerState) => state.weighing);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  useEffect(() => {
    if (selectedScale?.edgeDevice?.automationStates) {
      const currentStateConfig = selectedScale?.edgeDevice?.automationStates.find(
        (state: any) => state.modeId === automationCurrentMode?.modeId,
      );

      if (currentStateConfig?.controls) {
        const filteredControls = checkPermissionsToControls(
          currentStateConfig.controls,
          currentStateConfig.nodes,
          loggedUserContext.nodes!,
        );
        const controlsWithNodeId = filteredControls.map((control) => {
          return {
            ...control,
            nodeId: currentStateConfig.nodes.find((node: Node) => {
              return node.alias === control.node;
            }).id,
          };
        });
        setControls(controlsWithNodeId);
      }
    }
  }, [
    selectedScale?.edgeDevice?.automationStates,
    automationCurrentMode?.modeId,
    loggedUserContext.nodes,
    selectedScale?.edgeDevice?.automationCurrentMode,
  ]);

  return controls;
};

export default useControls;
