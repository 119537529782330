import React from 'react';
import { Button } from 'primereact/button';
import { useDispatch } from 'react-redux';
import { confirmationsActions } from '../../../../store/confirmations-slice';
import { ConfirmationRow } from './ConfirmationRow';
import vehicleService from '../../../../services/ScaleoApiServices/VehiclesService';
import { Direction } from '../types/Direction';
import { useTranslation } from 'react-i18next';

interface ConfirmationProps {
  registrationNumber: string;
  direction: Direction;
  locationId: string;
  edgeDeviceId: string;
}

export const Confirmation = ({ registrationNumber, direction, locationId, edgeDeviceId }: ConfirmationProps) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('accessControl');

  const confirmVehicleAccess = async () => {
    dispatch(confirmationsActions.deleteConfirmation({ registrationNumber, direction, locationId, edgeDeviceId }));
    await vehicleService.confirmVehicleAccess(registrationNumber, direction, locationId, edgeDeviceId);
  };

  const DIRECTION_TRANSLATIONS = {
    [Direction.ENTRY]: t('entry'),
    [Direction.EXIT]: t('exit'),
    [Direction.ANY]: null,
  };

  return (
    <div className="lg:col-15 md:col-15 mb-1vw">
      <div className="formgrid grid">
        <ConfirmationRow title={t('registrationNumber')} value={registrationNumber} />
        <ConfirmationRow title={t('direction')} value={DIRECTION_TRANSLATIONS[direction]} />
      </div>
      <Button type="submit" label={t('confirm')} onClick={confirmVehicleAccess} className="w-25vw" />
    </div>
  );
};
