import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import weighingService from '../../../../services/ScaleoApiServices/WeighingsService';
import { Weighing } from '../../../../types/weighing';

export const weighingDialogHeaderCallback = (currentData: any) => {
  return (
    <div className="gallery-header">
      <span className="p-ml-2">{currentData?.number}</span>
    </div>
  );
};

export const downloadWeighingImages = async (currentData: Weighing) => {
  if (!currentData) return;

  const res = await Promise.all(
    currentData?.measurements?.map((measurement: any) => weighingService.getDownloadableImages(measurement?.id)),
  );
  const images = res.reduce((acc, curr) => acc.concat(curr.data), []);

  const zip = new JSZip();
  images.forEach((image: any, index: number) => {
    zip.file(`${index}.jpeg`, image.data, { base64: true });
  });

  void zip.generateAsync({ type: 'blob' }).then((content) => {
    saveAs(content, 'images.zip');
  });
};

export const serviceToCall = async (currentData: Weighing) => {
  if (!currentData) return;

  const result = await Promise.all(
    currentData?.measurements?.map((measurement: any) => weighingService.getImageUrls(measurement?.id)),
  );

  const unzippedData = result.map((value) => value.data);

  return unzippedData;
};
