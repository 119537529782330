export const formatTime = (timestamp) => {
  return new Date(timestamp).toLocaleTimeString();
};

export const formatDate = (timestamp) => {
  return new Date(timestamp).toLocaleDateString();
};

export const formatDateTime = (timestamp) => {
  return `${formatDate(timestamp)} ${formatTime(timestamp)}`;
};

export const formatStringToDate = (dateString) => {
  if (dateString) return new Date(dateString);
  else return null;
};

export const formatDecimal = (value) => {
  const numberValue = Number(value);
  return !Number.isNaN(numberValue)
    ? numberValue.toLocaleString(undefined, {
        maximumFractionDigits: 5,
        minimumFractionDigits: 0,
      })
    : '--';
};
