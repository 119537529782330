import { Fieldset } from 'primereact/fieldset';
import { InputSwitch } from 'primereact/inputswitch';
import isIn from '../../../../../../utils/isIn';
import FormErrorMessage from '../../../../../_shared/FormErrorMessage';
import { VehicleAutoComplete } from '../../../../../_shared/VehicleAutoComplete';
import { renderNewVehicleInfoMsg, renderNewTrailerInfoMsg, getTrailer, getVehicle, posPrinterNode } from '../utils';
import VehiclePersitedTareInfo from './VehiclePersistedTareInfo';
import WeighingState from './WeighingState';
import { getOrderNumber } from './getOrderNumber';
import { useTranslation } from 'react-i18next';
import { weighingActions } from '../../../../../../store/weighing-slice';
import { useDispatch, useSelector } from 'react-redux';
import { Trailer } from '../../../../../../types/trailer';
import { Vehicle } from '../../../../../../types/weighing';
import { ReducerState } from '../../../../../../types/reducer-state';

interface WeighingVehicleFormProps {
  vehicles: Vehicle[];
  trailers: Trailer[];
  formik: any;
}

export const WeighingVehicleForm = ({ vehicles, trailers, formik }: WeighingVehicleFormProps) => {
  const { t } = useTranslation('weighingHandlingWeighings');
  const dispatch = useDispatch();

  const { weighingConfiguration, formState, selectedVehicle, selectedTrailer } = useSelector(
    (state: ReducerState) => state.weighing,
  );
  const { nodes } = useSelector((state: ReducerState) => state.user.context);

  const setFirstWeighingForNewVehicleInformationFields = (vehicle: Vehicle) => {
    const newFormState = {
      selectedVehicle: vehicle,
      updateTare: weighingConfiguration.updateVehicleTareDefaultValue,
      updateData: weighingConfiguration.updateVehicleDataDefaultValue,
      printPosReceipt: weighingConfiguration.printPosReceiptDefaultValue,
      saveVehicle: weighingConfiguration.saveVehicleDefaultValue,
    };

    updateFormState(newFormState);
  };

  const handleChangeVehicle = (value: Vehicle | string) => {
    if (typeof value === 'object') {
      selectVehicle(value);
    }

    if (typeof value === 'string') {
      let registrationNumber = value;
      if (registrationNumber.length === 0) {
        selectVehicle(null);
      } else {
        registrationNumber = registrationNumber.toUpperCase().replace(/[^a-z0-9]/gi, '');

        const existingVehicle = getVehicle(registrationNumber, vehicles);

        if (existingVehicle) {
          if (selectedVehicle?.id !== existingVehicle.id) {
            updateFormState({ selectedVehicle: existingVehicle });
          }
        } else if (formState.selectedVehicle?.registrationNumber !== registrationNumber) {
          const newVehicle = { isNew: true, registrationNumber };
          setFirstWeighingForNewVehicleInformationFields(newVehicle as Vehicle);
        }
      }
    }
  };

  const handleChangeTrailer = (value: Trailer | string) => {
    if (typeof value === 'object') {
      if (value.registrationNumber) {
        selectTrailer(value);
      } else {
        selectTrailer(null);
      }
    }

    if (typeof value === 'string') {
      let registrationNumber = value;
      if (registrationNumber.length === 0) {
        selectTrailer(null);
      } else {
        registrationNumber = registrationNumber.toUpperCase().replace(/[^a-z0-9]/gi, '');

        const existingTrailer = getTrailer(registrationNumber, vehicles);

        if (existingTrailer) {
          if (selectedTrailer?.id !== existingTrailer?.id) {
            selectTrailer(existingTrailer);
          }
        } else if (formState.selectedTrailer?.registrationNumber !== registrationNumber) {
          const newTrailer = { isNew: true, registrationNumber };
          selectTrailer(newTrailer as any);
        }
      }
    }
  };

  const updateFormState = (state: any) => dispatch(weighingActions.updateFormState(state));

  const changeSaveVehicle = (saveVehicle: boolean) => {
    updateFormState({ saveVehicle });
  };

  const changeUpdateTare = (updateTare: boolean) => {
    updateFormState({ updateTare });
  };

  const changeUpdateData = (updateData: boolean) => {
    updateFormState({ updateData });
  };

  const changePrintPosReceipt = (printPosReceipt: boolean) => {
    updateFormState({ printPosReceipt });
  };

  const selectTrailer = (selectedTrailer: Trailer | null) => {
    updateFormState({ selectedTrailer });
  };

  const selectVehicle = (vehicle: Vehicle | null) => {
    updateFormState({ selectedVehicle: vehicle });
  };

  return (
    <div className="xl:col-6 lg:col-12 md:col-12 sm:col-12">
      <div className="grid">
        <div className="col-12">
          <Fieldset legend={t('newWeighingFormVehicleTitle')}>
            <div className="field grid">
              <label htmlFor="vehicle" className="col-12 sm:col-5 text-2xl">
                {t('newWeighingFormVehicleRegistrationNumber')}
              </label>
              <div className="col-12 sm:col-7 relative vehicle-auto-complete-container">
                <VehicleAutoComplete
                  id="vehicle"
                  value={formik.values.vehicle}
                  onChange={handleChangeVehicle}
                  items={vehicles}
                  onBlurFuntion={undefined}
                />

                {!!formik.values.vehicle && (
                  <i
                    onClick={() => selectVehicle(null)}
                    className="pi pi-times text-xl  vehicle-auto-complete-close-icon"
                    style={{ color: '#6c757d' }}
                  />
                )}
                <FormErrorMessage fieldName="vehicle" formikInstance={formik} />
                {renderNewVehicleInfoMsg(formik.values.vehicle)}
              </div>
            </div>
            {weighingConfiguration?.addTrailerRegistrationNumbersSupport && (
              <div className="field grid">
                <label htmlFor="trailer" className="col-12 sm:col-5 text-2xl">
                  {t('newWeighingFormTrailerRegistrationNumber')}
                </label>
                <div className="col-12 sm:col-7 relative">
                  <VehicleAutoComplete
                    id="trailer"
                    value={formik.values.trailer}
                    onChange={handleChangeTrailer}
                    items={trailers}
                    onBlurFuntion={undefined}
                  />
                  {!!formik.values.trailer && (
                    <i
                      onClick={() => selectTrailer(null)}
                      style={{ color: '#6c757d' }}
                      className="pi pi-times  text-xl vehicle-auto-complete-close-icon"
                    />
                  )}
                  <FormErrorMessage fieldName="trailer" formikInstance={formik} />
                  {renderNewTrailerInfoMsg(formik.values.trailer)}
                </div>
              </div>
            )}
            {!!(weighingConfiguration?.saveVehicleShowCheckbox && formik.values.vehicle?.isNew) && (
              <div className="field grid">
                <label htmlFor="saveVehicle" className="col-5">
                  {t('newWeighingFormVehicleSave')}
                </label>
                <div className="col-7">
                  <InputSwitch
                    id="saveVehicle"
                    checked={formik.values.saveVehicle}
                    onChange={(e) => changeSaveVehicle(e.value)}
                  />
                </div>
              </div>
            )}
            {isIn(formState.selectedWeighingModeId, ['SINGLE', 'TARE']) && !formik.values.vehicle?.isNew && (
              <VehiclePersitedTareInfo />
            )}

            {weighingConfiguration?.updateVehicleTareShowCheckbox &&
            (!formState.selectedVehicle?.isNew || (formState.selectedVehicle?.isNew && formState.saveVehicle)) &&
            isIn(formState.selectedWeighingModeId, [
              'DOUBLE',
              'MULTIPLE',
              'WEIGHING_SERIES',
              'DOUBLE_WEIGHING_SERIES',
            ]) ? (
              <div className="field grid">
                <label htmlFor="updateTare" className="col-5">
                  {t('newWeighingFormVehicleUpdateTare')}
                </label>
                <div className="col-7">
                  <InputSwitch
                    id="updateTare"
                    checked={formik.values.updateTare}
                    onChange={(e) => changeUpdateTare(e.value)}
                  />
                </div>
              </div>
            ) : (
              ''
            )}
            {weighingConfiguration?.updateVehicleDataShowCheckbox &&
              !!(!formState.selectedVehicle?.isNew || (formState.selectedVehicle?.isNew && formState.saveVehicle)) && (
                <div
                  className="field grid"
                  style={{ order: getOrderNumber('driver', weighingConfiguration.fieldsOrder) }}
                >
                  <label htmlFor="updateData" className="col-5">
                    {t('newWeighingFormVehicleUpdateData')}
                  </label>
                  <div className="col-7">
                    <InputSwitch
                      id="updateData"
                      checked={formik.values.updateData}
                      onChange={(e) => changeUpdateData(e.value)}
                    />
                  </div>
                </div>
              )}

            {/* TODO 
              - wziąć pod uwagę konfigurowalną kolejność pól
              */}
            {posPrinterNode(nodes!) &&
              !isIn(formState.selectedWeighingModeId, ['MULTIPLE', 'WEIGHING_SERIES', 'DOUBLE_WEIGHING_SERIES']) && (
                <div className="field grid">
                  <label htmlFor="printPosReceipt" className="col-5">
                    {t('newWeighingFormVehiclePrintPosReceipt')}
                  </label>
                  <div className="col-7">
                    <InputSwitch
                      id="printPosReceipt"
                      checked={formik.values.printPosReceipt}
                      onChange={(e) => changePrintPosReceipt(e.value)}
                    />
                  </div>
                </div>
              )}
          </Fieldset>
        </div>
        <div className="col-12">
          {formik.values.vehicle &&
            isIn(formState.selectedWeighingModeId, [
              'DOUBLE',
              'MULTIPLE',
              'WEIGHING_SERIES',
              'DOUBLE_WEIGHING_SERIES',
            ]) && <WeighingState />}
        </div>
      </div>
    </div>
  );
};
