import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NodeSlice, NodeState } from './types/node-slice';

const NODES: Array<NodeState> = [];

const initialState: NodeSlice = {
  nodesState: NODES,
};

const nodeSlice = createSlice({
  name: 'nodes',
  initialState: { ...initialState },
  reducers: {
    updateNodes(state, action: PayloadAction<NodeState>) {
      const newNodesStates: Array<NodeState> = state.nodesState.filter((nodeState: NodeState) => {
        return !(nodeState.nodeId === action.payload.nodeId && nodeState.signalName === action.payload.signalName);
      });
      newNodesStates.push(action.payload);
      state.nodesState = newNodesStates;
    },
  },
});

export const nodeActions = nodeSlice.actions;

export default nodeSlice;
