import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { appConfigActions } from './appConfig-slice';
import { indicationActions } from './indication-slice';
import { userActions } from './user-slice';
import { weighingActions } from './weighing-slice';
import { stateActions } from './state-slice';
// import usersService from '../services/ScaleoApiServices/UsersService';

const AuthContext = React.createContext({
  login: (
    _idToken: string,
    _accessToken: string,
    _id: string,
    _email: string,
    _expirationTime: string | null,
    _credentials?: any,
  ) => {
    // initial login method
  },
  logout: () => {
    // initial logout method
  },
});

const retrieveStoredLoggedUser = (dispatch: Function) => {
  const storedIdToken = localStorage.getItem('id_token');
  const storedAccessToken = localStorage.getItem('access_token');
  const storedId = localStorage.getItem('id');
  const storedEmail = localStorage.getItem('email');

  const hasAnyCustomers = localStorage.getItem('hasAnyCustomers');

  storedIdToken &&
    storedAccessToken &&
    storedId &&
    storedEmail &&
    dispatch(userActions.setLoggedUserCustomers(Boolean(hasAnyCustomers))) &&
    dispatch(
      userActions.setLoggedUser({
        idToken: storedIdToken,
        accessToken: storedAccessToken,
        id: storedId,
        email: storedEmail,
      }),
    );
};

interface AuthContextProviderProps {
  children: React.ReactNode;
}

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  retrieveStoredLoggedUser(dispatch);

  const logoutHandler = useCallback(async () => {
    dispatch(userActions.resetState());
    dispatch(weighingActions.resetState());
    dispatch(indicationActions.resetState());
    dispatch(appConfigActions.resetState());
    dispatch(appConfigActions.resetState());
    dispatch(stateActions.resetState());

    // await usersService.dropSession(localStorage.getItem('email'));

    localStorage.removeItem('id_token');
    localStorage.removeItem('access_token');
    localStorage.removeItem('id');
    localStorage.removeItem('email');
    localStorage.removeItem('expirationTime');
    localStorage.removeItem('hasAnyCustomers');

    history.push('/login');
  }, [dispatch, history]);

  const loginHandler = (
    idToken: string,
    accessToken: string,
    id: string,
    email: string,
    expirationTime: string | null,
    credentials: any,
  ) => {
    dispatch(userActions.setLoggedUser({ idToken: idToken, accessToken: accessToken, id, email: email, credentials }));
    localStorage.setItem('id_token', idToken);
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('id', id);
    localStorage.setItem('email', email);
    localStorage.setItem('expirationTime', expirationTime!);

    sessionStorage.setItem('checkItOut', 'work');
    history.push('/');
  };

  const contextValue = {
    login: loginHandler,
    logout: logoutHandler,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContext;
