import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { useSelector } from 'react-redux';
import { formatDecimal } from '../../../utils/formatUtils';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../types/reducer-state';

interface DoubleWeighingSeriesProductsSummaryProps {
  measurements: any[];
}

const DoubleWeighingSeriesProductsSummary = ({ measurements }: DoubleWeighingSeriesProductsSummaryProps) => {
  const { t } = useTranslation('weighingComponents');

  const unit = useSelector((state: ReducerState) => state.appConfig.unit);

  const compareMeasurements = (a: any, b: any) => {
    if (a.scale.name === b.scale.name) return a.timestamp > b.timestamp ? 1 : -1;
    return a.scale.name > b.scale.name ? 1 : -1;
  };

  const createDoubleWeighingSeriesSummaryData = () =>
    measurements
      .sort(compareMeasurements)
      .map((firstMeasurement, index, sortedMeasurements) => {
        if (index % 2 !== 0) {
          return null;
        }
        const secondMeasurement = sortedMeasurements[index + 1];
        const netto = Math.abs(firstMeasurement.indication - (secondMeasurement?.indication ?? 0));
        const brutto = Math.max(firstMeasurement.indication, secondMeasurement?.indication ?? 0);
        const tare = Math.min(firstMeasurement.indication, secondMeasurement?.indication ?? 0);
        const scaleName = firstMeasurement.scale.name;
        const product = firstMeasurement.product ?? secondMeasurement?.product;

        return {
          netto,
          brutto,
          tare,
          scaleName,
          product,
        };
      })
      .filter((m) => m);

  const indicationTemplate = (productsSummary: any, field: string) => {
    return <>{`${formatDecimal(productsSummary[field])} ${unit}`}</>;
  };

  return (
    <>
      <DataTable
        responsiveLayout="scroll"
        value={createDoubleWeighingSeriesSummaryData() as any}
        className="mb-6"
        emptyMessage={t('productsSummaryEmptyMsg')}
      >
        <Column field="product.name" header={t('productsSummaryProduct')}></Column>
        <Column
          field="netto"
          body={(summary) => indicationTemplate(summary, 'netto')}
          header={t('productsSummaryNetto')}
        ></Column>
        <Column
          field="tare"
          body={(summary) => indicationTemplate(summary, 'tare')}
          header={t('productsSummaryTare')}
        ></Column>
        <Column
          field="brutto"
          body={(summary) => indicationTemplate(summary, 'brutto')}
          header={t('productsSummaryBrutto')}
        ></Column>
        <Column field="scaleName" header={t('productsSummaryScale')} />
      </DataTable>
    </>
  );
};

export default DoubleWeighingSeriesProductsSummary;
