import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Password } from 'primereact/password';
import React, { useEffect, useState } from 'react';
import validator from 'validator';
import nodesService from '../../../services/ScaleoApiServices/NodesService';
import usersService from '../../../services/ScaleoApiServices/UsersService';
import { CustomAutoComplete } from '../../_shared/CustomComponents';
import FormErrorMessage from '../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../_shared/FormErrorMessageScroller';
import multiSelectPanelHeader from '../../_shared/multiSelectPanelHeader';
import { useTranslation } from 'react-i18next';
import { User } from '../../../types/user';
import { Node } from '../../../types/node';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../store/user-slice';

const INIT_FORM_STATE = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  newPassword: false,
  password: '',
  confPassword: '',
  role: '',
  nodeIds: [],
  locationIds: [],
};

interface NewOrEditUserDialogProps {
  closeDialogHandler: () => void;
  user: User;
  nodes: Node[] | null;
  userRoles: any[] | null;
  locations: any[] | null;
  setNodes: React.Dispatch<React.SetStateAction<Node[] | null>>;
}

function NewOrEditUserDialog({
  closeDialogHandler,
  user,
  nodes,
  userRoles,
  locations,
  setNodes,
}: NewOrEditUserDialogProps) {
  const { t } = useTranslation('managementUsers');
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const dispatch = useDispatch();

  const hideDialog = () => {
    formik.resetForm(INIT_FORM_STATE as any);
    closeDialogHandler();
  };

  useEffect(() => {
    if (user?.id) {
      const userNodeIds = user?.nodes?.map((node) => node.id);
      setInitFormValues({
        id: user.id as any,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email ? user.email : '',
        phone: user.phone ? user.phone : '',
        newPassword: false,
        password: '',
        confPassword: '',
        role: user.role as string,
        locationIds: user?.locations?.map((location) => {
          return location.id;
        }) as any,
        nodeIds: userNodeIds as any,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
  }, [user]);

  const formik = useFormik({
    initialValues: initFormValues,
    validate: (data) => {
      const errors = {} as any;

      const nameRegex = /^[A-Za-z\u00C0-\u024F\s]+(?:-[A-Za-z\u00C0-\u024F\s]+)?$/;

      if ((!data.id && !data.password) || (data.newPassword && !data.password)) {
        errors.password = t('noPasswordError');
      }
      if (data.password !== data.confPassword) {
        errors.confPassword = t('noConfPasswordError');
      }
      if (!data.firstName) {
        errors.firstName = t('noFirstNameError');
      }
      if (!data.lastName) {
        errors.lastName = t('noLastNameError');
      }

      if (data.firstName && !nameRegex.test(data.firstName)) {
        errors.firstName = t('incorrectFirstNameError');
      }

      if (data.lastName && !nameRegex.test(data.lastName)) {
        errors.lastName = t('incorrectLastNameError');
      }

      if (!data.role) {
        errors.role = t('noRoleError');
      }
      if (!data.email) {
        errors.email = t('noEmailError');
      } else if (!validator.isEmail(data.email)) {
        errors.email = t('incorrectEmailError');
      }
      if (data.phone) {
        const re = /\D+/;
        if (re.test(data.phone)) errors.phone = t('incorrectPhoneError');
      }

      return errors;
    },
    onSubmit: async (formData, helpers) => {
      if (!formData.id) {
        usersService
          .createUser(formData)
          .then(() => {
            dispatch(userActions.shouldRefreshLoggedUserContext(true));
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('email', t('userExistError'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        usersService
          .editUser(formData, formData.id)
          .then(() => {
            dispatch(userActions.shouldRefreshLoggedUserContext(true));
            hideDialog();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('userExistError'));
            }
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    nodesService.searchNodes({ filters: { locationIds: formik.values.locationIds } }).then((_nodes) => {
      setNodes(_nodes);
      !formik.values.id &&
        formik.setFieldValue(
          'nodeIds',
          _nodes.map((node: Node) => node.id),
        );
      formik.values.id &&
        formik.setFieldValue(
          'nodeIds',
          formik.values.nodeIds.filter((nodeId) => _nodes.map((node: Node) => node.id).includes(nodeId)),
        );
    });
  }, [formik.values.locationIds]);

  const onLocationChanged = (event: any) => {
    const newLocationIds = event.value;
    formik.setFieldValue('locationIds', newLocationIds);
  };

  const onNodeChanged = (event: any) => {
    const newNodeIds = event.value;
    formik.setFieldValue('nodeIds', newNodeIds);
  };

  const userDialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={hideDialog} />
      <Button type="submit" label={t('saveButton')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible
        header={t('userDetails')}
        modal
        className="p-fluid w-40vw"
        footer={userDialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      >
        <form>
          <div className="col-12">
            <div className="field">
              <label htmlFor="email">{t('email')}</label>
              <InputText
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                readOnly={formik.values.id ? true : false}
                disabled={formik.values.id ? true : false}
              />
              <FormErrorMessage fieldName="email" formikInstance={formik} />
            </div>
            <div className="field">
              {formik.values.id ? (
                <div className="field-checkbox">
                  <Checkbox id="newPassword" checked={formik.values.newPassword} onChange={formik.handleChange} />
                  <label htmlFor="newPassword">{t('newPassword')}</label>
                </div>
              ) : null}
            </div>
            {formik.values.newPassword || !formik.values.id ? (
              <>
                <div className="field">
                  <label htmlFor="password">{t('password')}</label>
                  <Password
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    feedback={false}
                    toggleMask
                  />
                  <FormErrorMessage fieldName="password" formikInstance={formik} />
                </div>
                <div className="field">
                  <label htmlFor="confPassword">{t('confPassword')}</label>
                  <Password
                    id="confPassword"
                    name="confPassword"
                    value={formik.values.confPassword}
                    onChange={formik.handleChange}
                    feedback={false}
                  />
                  <FormErrorMessage fieldName="confPassword" formikInstance={formik} />
                </div>
                <hr />
              </>
            ) : null}
            <div className="field">
              <label htmlFor="firstName">{t('firstName')}</label>
              <InputText id="firstName" value={formik.values.firstName} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="firstName" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="lastName">{t('lastName')}</label>
              <InputText id="lastName" value={formik.values.lastName} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="lastName" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="phone">{t('phone')}</label>
              <InputText id="phone" value={formik.values.phone} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="phone" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="role">{t('role')}</label>
              <CustomAutoComplete
                id="role"
                value={formik.values.role}
                handler={formik.handleChange}
                source={userRoles!}
              />
              <FormErrorMessage fieldName="role" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="locationIds">{t('locationIds')}</label>
              <MultiSelect
                value={formik.values.locationIds}
                onChange={onLocationChanged}
                options={locations!}
                optionLabel="name"
                optionValue="id"
                placeholder={t('locationIdsPlaceholder')}
                className="multiselect-custom"
                maxSelectedLabels={0}
                selectedItemsLabel={t('locationIdsSelectedLabel')}
                showClear={true}
                panelHeaderTemplate={multiSelectPanelHeader}
              />
              <FormErrorMessage fieldName="locationIds" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="nodeIds">{t('nodeIds')}</label>
              <MultiSelect
                value={formik.values.nodeIds}
                onChange={onNodeChanged}
                options={nodes!}
                optionLabel="name"
                optionValue="id"
                placeholder={t('nodeIdsPlaceholder')}
                className="multiselect-custom"
                maxSelectedLabels={0}
                selectedItemsLabel={t('nodeIdsSelectedLabel')}
                showClear={true}
                panelHeaderTemplate={multiSelectPanelHeader}
              />
              <FormErrorMessage fieldName="nodeIds" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default NewOrEditUserDialog;
