import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import '../.././DataTable.css';
import getPaginatorTemplate from '../../../utils/getPaginatorTemplate';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { useRef } from 'react';
import { CustomButton, CustomTableField } from '../../_shared/CustomComponents';
import DataTableFilterElements from '../../_shared/DataTableFilterElements';
import { Trailer } from '../../../types/trailer';
import { DeleteTrailerDialog } from './components/DeleteTrailerDialog';
import { DeleteTrailersDialog } from './components/DeleteTrailersDialog';
import { TrailerDialog } from './components/TrailerDialog';
import { useTrailersData } from '../../../hooks/Trailers/useTrailersData';
import { useTrailerDialogs } from '../../../hooks/Trailers/useTrailerDialogs';

export const Trailers = () => {
  const { t } = useTranslation('datasetsTrailers');

  const dt = useRef(null);

  const {
    fetchTrailers,
    trailers,
    handleSort,
    paginationParameters,
    paginatorState,
    setPaginatorState,
    internalFilters,
    changeFilter,
    clearFilters,
  } = useTrailersData();

  const {
    setSelectedTrailers,
    openNew,
    confirmDeleteSelected,
    hideDeleteTrailerDialog,
    deleteTrailer,
    deleteSelectedTrailers,
    editTrailer,
    hideDeleteTrailersDialog,
    setTrailer,
    setDeleteTrailerDialogVisible,
    setTrailerDialogVisible,
    trailerDialogVisible,
    deleteTrailerDialogVisible,
    deleteTrailersDialogVisible,
    trailer,
    selectedTrailers,
  } = useTrailerDialogs(fetchTrailers);

  const header = (
    <div className="table-header text-2xl p-2">
      {t('header')} &#160;&#160;
      <div className="flex">
        <Button label={t('clearFilters')} icon="pi pi-filter-slash" outlined className="mr-2" onClick={clearFilters} />
        <span>
          <DataTableFilterElements.Text
            initialValue={internalFilters['global']}
            onChangeFilter={changeFilter}
            name={'global'}
            placeholder={t('searchPlaceholder')}
            isSearchIcon
          />
        </span>
      </div>
    </div>
  );

  const leftToolbarTemplate = () => {
    return (
      <>
        <Button label={t('addButton')} icon="pi pi-plus" severity="success" className="mr-2" onClick={openNew} />
        <CustomButton
          label={t('deleteButton')}
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedTrailers || !selectedTrailers.length}
          tooltip={!selectedTrailers.length ? t('selectTrailer') : t('cannotDelete')}
          name={undefined}
          dataFlag={undefined}
          type={undefined}
        />
      </>
    );
  };

  const actionBodyTemplate = (rowData: Trailer) => {
    return (
      <div className="actions">
        <Button icon="pi pi-pencil" raised severity="success" className="mr-2" onClick={() => editTrailer(rowData)} />
        <Button
          icon="pi pi-trash"
          raised
          severity="secondary"
          onClick={() => {
            setTrailer(rowData);
            setDeleteTrailerDialogVisible(true);
          }}
        />
      </div>
    );
  };

  const columnBodyTemplate = (rowData: Trailer, column: any) => {
    return <CustomTableField row={rowData} col={column} withoutHeaders={undefined} />;
  };

  const columns = [{ field: 'registrationNumber', header: t('registrationNumber'), type: 'text' }];

  const dynamicColumns = columns.map((col) => {
    const AnyColumn = Column as any;

    return (
      <AnyColumn
        key={col.field}
        field={col.field}
        header={col.header}
        body={columnBodyTemplate}
        fieldsType={col.type as any}
        sortable
        showFilterMenu={false}
        filter={col.type === 'noFilter' ? false : true}
        filterElement={
          <DataTableFilterElements.Text
            initialValue={internalFilters[col.field]}
            onChangeFilter={changeFilter}
            name={col.field}
            placeholder={t('filters')}
          />
        }
      />
    );
  });

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                responsiveLayout="scroll"
                ref={dt}
                value={trailers ?? []}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                rowHover
                selection={selectedTrailers}
                selectionMode="checkbox"
                onSelectionChange={(e) => setSelectedTrailers(e.value)}
                emptyMessage={t('noData')}
                removableSort
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                paginator
                rows={paginatorState.rows}
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState) as any}
                stripedRows
                filterDisplay="row"
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                {dynamicColumns}
                <Column body={actionBodyTemplate} className="actionBody"></Column>
              </DataTable>
              <TrailerDialog
                visible={trailerDialogVisible}
                onTrailerAdded={() => fetchTrailers()}
                setTrailerDialogVisible={setTrailerDialogVisible}
                trailer={trailer}
              />
              <DeleteTrailerDialog
                deleteTrailerDialogVisible={deleteTrailerDialogVisible}
                hideDeleteTrailerDialog={hideDeleteTrailerDialog}
                trailer={trailer}
                deleteTrailer={deleteTrailer}
              />
              <DeleteTrailersDialog
                deleteTrailersDialogVisible={deleteTrailersDialogVisible}
                hideDeleteTrailersDialog={hideDeleteTrailersDialog}
                deleteSelectedTrailers={deleteSelectedTrailers}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
