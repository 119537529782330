import React from 'react';
import { InputText } from 'primereact/inputtext';
import { useDispatch, useSelector } from 'react-redux';
import { weighingActions } from '../../../../../../store/weighing-slice';
import FormErrorMessage from '../../../../../_shared/FormErrorMessage';
import { FORMIK_FIELD_NAMES } from './variables';
import { getOrderNumber } from './getOrderNumber';
import { DictionaryAdditionalFieldAutocomplete } from '../../../../../_shared/DictionaryAdditionalFieldAutocomplete';
import { Dictionary } from '../../../../../../types/dictionary';
import { ReducerState } from '../../../../../../types/reducer-state';

interface AdditionalFieldsProps {
  formikInstance: any;
  fieldName: string;
  localTouched: Record<string, boolean>;
  setLocalTouched: (localTouched: Record<string, boolean>) => void;
  dictionaries: Dictionary[];
}

const AdditionalFields = ({
  formikInstance,
  fieldName,
  localTouched,
  setLocalTouched,
  dictionaries,
}: AdditionalFieldsProps) => {
  const dispatch = useDispatch();
  const updateFormState = (state: any) => dispatch(weighingActions.updateFormState({ ...state }));

  const { weighingConfiguration } = useSelector((state: ReducerState) => state.weighing);

  const renderAdditionalField = (field: any, index: number) => {
    if (!field.showOnWeighingForm || !field.isActive) {
      return <></>;
    }
    const identifier = `${fieldName}-${index}`;
    const fieldValue = formikInstance.values[fieldName][index]?.value;

    const handleChange = (value: any) => {
      const oldValue = formikInstance.values[fieldName];
      const newValue = [];

      for (const [oldValueIndex, oldValueField] of oldValue.entries()) {
        if (index === oldValueIndex) {
          newValue.push({ ...oldValueField, value });
        } else {
          newValue.push({ ...oldValueField });
        }
      }

      fieldName === FORMIK_FIELD_NAMES.additionalFields &&
        dispatch(
          weighingActions.updateFormState({
            additionalFields: newValue,
          }),
        );
      fieldName === FORMIK_FIELD_NAMES.measurementAdditionalFields &&
        updateFormState({ measurementAdditionalFields: newValue });

      const newLocalTouched = localTouched;
      newLocalTouched[identifier] = true;
      setLocalTouched(newLocalTouched);
    };

    const renderDictionaryType = () => {
      const sourceDictionary = dictionaries && dictionaries.find((d) => d.id === field.dictionaryId);

      let source = [];

      if (sourceDictionary) {
        source = sourceDictionary.values;
      } else if (fieldValue) {
        source.push(fieldValue);
      }

      return (
        <DictionaryAdditionalFieldAutocomplete
          id={identifier}
          value={source?.find((el) => el === fieldValue) || null}
          onChange={handleChange}
          source={source}
        />
      );
    };

    const renderNumberType = () => (
      <InputText
        id={identifier}
        value={fieldValue || ''}
        onChange={(e) => handleChange(e.target.value)}
        type="number"
      />
    );

    const renderTextType = () => (
      <InputText id={identifier} value={fieldValue || ''} onChange={(e) => handleChange(e.target.value)} />
    );

    const METHODS = {
      DICTIONARY: renderDictionaryType,
      NUMBER: renderNumberType,
      TEXT: renderTextType,
    };

    if (!!METHODS[field.type as keyof typeof METHODS]) {
      const showUnit = !!field.unit;
      const showRequiredStar = !field.isOptional;

      return (
        <div
          key={field.name}
          className="field grid"
          style={{ order: getOrderNumber(field.id, weighingConfiguration.fieldsOrder) }}
        >
          <label htmlFor={identifier} className="col-12 sm:col-4">
            {field.name}
            {showUnit && (
              <>
                &nbsp;<small>{`[${field.unit}]`}</small>
              </>
            )}
            {showRequiredStar && (
              <span className="text-red-500">
                <small>*</small>
              </span>
            )}
          </label>
          <div className="col-10 sm:col-6">
            {METHODS[field.type as keyof typeof METHODS]()}
            <FormErrorMessage
              fieldName={identifier}
              formikInstance={formikInstance}
              withTouchedTrue={!!formikInstance.submitCount || !!localTouched[identifier]}
            />
          </div>
        </div>
      );
    }

    return <React.Fragment key={field.name}></React.Fragment>;
  };

  if (!!fieldName) {
    return (
      <>
        {fieldName === FORMIK_FIELD_NAMES.additionalFields &&
          formikInstance.values.additionalFields.map(renderAdditionalField)}
        {fieldName === FORMIK_FIELD_NAMES.measurementAdditionalFields &&
          formikInstance.values.measurementAdditionalFields.map(renderAdditionalField)}
      </>
    );
  }

  return <></>;
};

export default AdditionalFields;
