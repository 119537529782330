import React, { useEffect, useState } from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '../../../../../store/ui-slice';
import { blockBodyScroll, isMobile, unblockBodyScroll } from '../../../../../utils/uiUtils';
import ControlsList from './components/ControlsList';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../../types/reducer-state';
import { EdgeDevice } from '../../../../../types/edge-device';
import { Dictionary } from '../../../../../types/dictionary';

interface WeighingSidebarProps {
  edgeDevices: EdgeDevice[];
  dictionaries: Dictionary[];
  controls: any;
}

const WeighingSidebar = ({ edgeDevices, dictionaries, controls }: WeighingSidebarProps) => {
  const { t } = useTranslation('weighingHandlingWeighings');
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state: ReducerState) => state.ui.isSidebarOpen);

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (isMobile()) {
      if (isSidebarOpen) {
        blockBodyScroll();
      } else {
        unblockBodyScroll();
      }
    }
  }, [isSidebarOpen]);

  const handleClickOpenButton = () => {
    dispatch(uiActions.setIsSidebarOpen(!isSidebarOpen));
  };

  if (controls.length > 0) {
    return (
      <>
        <div
          className={`weighing-sidebar-mask ${!isSidebarOpen && 'weighing-sidebar-mask--close'}`}
          onClick={handleClickOpenButton}
        />
        <div
          className={`grid nested-grid weighing-sidebar-container ${
            !isSidebarOpen && 'weighing-sidebar-container--close'
          }`}
        >
          <div className="weighing-sidebar-button" onClick={handleClickOpenButton}>
            <i className={`pi pi-angle-${isSidebarOpen ? 'right' : 'left'}`} />
          </div>
          <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header={t('newWeighingFormSidebarHeader')}>
              <ControlsList controls={controls} edgeDevices={edgeDevices} dictionaries={dictionaries} />
            </TabPanel>
          </TabView>
        </div>
      </>
    );
  }

  return <></>;
};

export default WeighingSidebar;
