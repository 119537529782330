import axios from 'axios';

class BdoConfigurationService {
  async getConfiguration() {
    return axios.get('/bdo-configuration');
  }

  async updateConfiguration(bdoConfigurationId: string, data: any) {
    return axios.put(`/bdo-configuration/${bdoConfigurationId}`, data);
  }
}

const bdoConfigurationService = new BdoConfigurationService();
export default bdoConfigurationService;
