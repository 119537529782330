import { Galleria } from 'primereact/galleria';
import { useRef, useState } from 'react';
import Magnifier from 'react-magnifier';
import ClipLoader from 'react-spinners/ClipLoader';

type Snapshot = {
  url: string;
  isAdapted: boolean;
};

interface GalleryProps {
  snapshots: Snapshot[];
  timestamp: number;
  visibleText?: boolean;
  weight?: number | null;
}

const Gallery = ({ snapshots, timestamp, visibleText, weight }: GalleryProps) => {
  const ref = useRef<any>(null);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const itemTemplate = (item: string) => (
    <div className="gallery-image-magnifier">
      <Magnifier src={item} height="100%" width="100%" mgWidth={200} mgHeight={200} zoomFactor={2} />
    </div>
  );

  const weighingText = weight ? `${weight} kg, ` : '';

  const imageText = `${weighingText}${new Date(timestamp).toLocaleTimeString()} ${new Date(
    timestamp,
  ).toLocaleDateString()}`;

  return (
    <div>
      <Galleria
        ref={ref}
        value={snapshots.map((s: { url: string; isAdapted: boolean }) => s.url)}
        numVisible={10}
        activeIndex={activeImageIndex}
        onItemChange={(e) => setActiveImageIndex(e.index)}
        circular
        fullScreen
        showItemNavigators
        showThumbnails={false}
        item={itemTemplate}
        caption={visibleText ? () => <h4 className="mt-2">{imageText}</h4> : undefined}
      />

      <div className="grid mt-2 mb-4 max-width-800px">
        {snapshots &&
          Array.isArray(snapshots) &&
          snapshots.map((snapshot, index) => (
            <div className="col-4 flex h-200px" key={index}>
              {!snapshot.isAdapted ? (
                <ClipLoader color="#0f97c7" size={120} loading />
              ) : (
                <div
                  className="relative text-white text-center"
                  style={{
                    textShadow: '2px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000',
                  }}
                >
                  <img
                    src={snapshot.url}
                    alt={`snapshot-${index + 1}-thumbnail`}
                    className="w-full m-auto  border-2 border-solid cursor-pointer"
                    style={{
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.25)',
                      border: 'whitesmoke',
                    }}
                    onClick={() => {
                      setActiveImageIndex(index);
                      ref.current?.show();
                    }}
                  />
                  <div className="absolute top-8px left-16px">{visibleText && imageText}</div>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default Gallery;
