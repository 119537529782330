import { TFunction } from 'i18next';
import { UserContext } from '../../../store/types/user-slice';
import { NodeType, UnpreparedNode } from '../../../types/node';
import { getTranslatedLabel, nodeTypeLabels } from '../../../utils/labels';
import { ComponentCamera } from './types';
import { isModulesEnabled } from '../../../utils/modulesUtils';
import { Station } from '../../../types/station';

export const parseNodeModels = (nodesResponse: UnpreparedNode[]) => {
  return Object.keys(NodeType).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: nodesResponse.filter((node) => node.type === curr),
    }),
    {},
  );
};

export const generateNodeTypeSource = (loggedUserContext: UserContext, t: TFunction) => {
  const mappedOptions = Object.entries(nodeTypeLabels).map(([key]) => ({
    label: getTranslatedLabel(nodeTypeLabels, key, t),
    value: key,
  }));

  const shouldIncludeNode = (element: any) => {
    const subscriptionModules = loggedUserContext.currentCustomer?.subscriptionModules;

    if (element.value === NodeType.CAMERA) {
      return isModulesEnabled(['CAMERAS'], subscriptionModules);
    }
    if (element.value === NodeType.BARCODE_SCANNER) {
      return isModulesEnabled(['QR_ACCESS_CONTROL'], subscriptionModules);
    }

    return true;
  };

  const refilteredOptions = mappedOptions.filter((element) => shouldIncludeNode(element));

  return refilteredOptions;
};

export const checkIfCameraAvailable = (station: Station, selectedCameras: string[], data: any) => {
  if (!selectedCameras || !selectedCameras.length) return false;

  const allCameras = data.cameras;

  const camerasForStation = allCameras.filter((camera: ComponentCamera) => camera.stationId === station.id);

  return selectedCameras.some((selectedCameraId: string) =>
    camerasForStation.some((camera: ComponentCamera) => camera.id === selectedCameraId),
  );
};

export const filterCameras = (station: Station, cameras: ComponentCamera[]) => {
  const filteredCameras = cameras.filter((camera: ComponentCamera) => camera.stationId === station?.id);

  return filteredCameras;
};

export const allowAddingScales = (loggedUserContext: UserContext) =>
  Number.isInteger(loggedUserContext.limits?.scalesNumber) &&
  Number.isInteger(loggedUserContext.limitsState?.currentScalesNumber)
    ? loggedUserContext.limits!.scalesNumber! > loggedUserContext.limitsState!.currentScalesNumber
    : true;
