import axios from 'axios';
import { trimString } from '../../utils/stringUtils';

class ContractorsService {
  async searchContractors(filters, paginatorParam) {
    const contractors = await axios.post(`/contractors/search`, { filters, paginatorParam });
    return { data: contractors.data.data.map(mapFromResponse), totalPages: contractors.data.totalPages };
  }
  async exportData() {
    return axios.get(`/contractors/csv`);
  }
  async importData(postData) {
    return axios.post(`/contractors/csv`, {
      data: postData,
    });
  }
  async getContractors() {
    const contractors = await axios.get(`/contractors`, {});
    return { data: contractors.data.map(mapFromResponse) };
  }
  async createContractors(postData) {
    return axios.post(`/contractors`, {
      data: mapToRequestBody(postData),
    });
  }
  async editContractors(postData, id) {
    return axios.put(`/contractors/${id}`, {
      data: mapToRequestBody(postData),
    });
  }
  async removeContractors(id) {
    return axios.delete(`/contractors/${id}`);
  }
  mapContractor(contractor) {
    if (contractor) return { ...contractor, fulltext: `[${contractor.code}] ${trimString(contractor.name, 50, true)}` };
  }
}

const mapToRequestBody = (contractor) => {
  return {
    id: contractor.id,
    code: contractor.code,
    name: contractor.type === 'company' ? contractor.companyName : contractor.firstName + ' ' + contractor.lastName,
    companyName: contractor.type === 'company' ? contractor.companyName : null,
    firstName: contractor.type === 'individual' ? contractor.firstName : null,
    lastName: contractor.type === 'individual' ? contractor.lastName : null,
    taxId: contractor.taxId,
    email: contractor.email,
    phone: contractor.phone,
    personalId: contractor.type === 'individual' ? contractor.personalId : null,
    idCard: contractor.type === 'individual' ? contractor.idCard : null,
    street: contractor.street,
    zip: contractor.zip,
    city: contractor.city,
    autoPrint: contractor.autoPrint,
    autoSend: contractor.autoSend,
    type: contractor.type,
    kpoReceiverUpdateMass: !!contractor.kpoReceiverUpdateMass,
    kpoReceiverAddMassToCardRemarks: !!contractor.kpoReceiverAddMassToCardRemarks,
    kpoReceiverUpdatedStatus: contractor.kpoReceiverUpdatedStatus,
    kpokReceiveReceiverAddMassToCardRemarks: !!contractor.kpokReceiveReceiverAddMassToCardRemarks,
    kpokReceiveReceiverUpdatedStatus: contractor.kpokReceiveReceiverUpdatedStatus,
    kpokTransferReceiverUpdateMass: !!contractor.kpokTransferReceiverUpdateMass,
    kpokTransferReceiverAddMassToCardRemarks: !!contractor.kpokTransferReceiverAddMassToCardRemarks,
    kpokTransferReceiverUpdatedStatus: contractor.kpokTransferReceiverUpdatedStatus,
  };
};

const mapFromResponse = (contractor) => {
  return {
    ...contractor,
    name: contractor.name.length > 100 ? contractor.name.substring(0, 100) + '...' : contractor.name,
    fulltext: `[${contractor.code}] ${trimString(contractor.name, 50, true)}`,
  };
};

const contractorsService = new ContractorsService();
export default contractorsService;
