import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import deploymentsService from '../../../services/ScaleoApiServices/DeploymentsService';
import DowntimeInfoDialog from './components/DowntimeInfoDialog';

const DowntimeInfoModule = () => {
  const [deployments, setDeployments] = useState([]);
  const location = useLocation();

  const fetchDeploymentsData = useCallback(async () => {
    const deploymentsData = (await deploymentsService.getScheduledDeployments())?.data;

    if (deploymentsData !== null) {
      setDeployments(deploymentsData);
    }
  }, []);

  useEffect(() => {
    void fetchDeploymentsData();
  }, [fetchDeploymentsData, location]);

  return <>{deployments.length > 0 && deployments.map((deployment) => <DowntimeInfoDialog item={deployment} />)}</>;
};

export default DowntimeInfoModule;
