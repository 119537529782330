import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { RadioButton } from 'primereact/radiobutton';

interface ConfirmationsContentProps {
  formikInstance: any;
  handleChangeField: Function;
}

const ConfirmationsContent = ({ formikInstance, handleChangeField }: ConfirmationsContentProps) => {
  const { t } = useTranslation('managementSystemConfigurationConfirmations');

  const onChangeRequiredCondition = (e: any) => {
    formikInstance.setFieldValue('confirmationRequiredCondition', e.value);
  };

  return (
    <Fieldset legend={t('title')} className="mt-1vh">
      <div className="p-fluid formgrid grid">
        <div className="field-checkbox col-12">
          <Checkbox
            id="weighingConfirmation"
            checked={formikInstance.values.weighingConfirmation}
            onChange={(e) => {
              formikInstance.setFieldValue('confirmationComment', false);
              formikInstance.setFieldValue('requireConfirmationComment', false);
              formikInstance.setFieldValue('enableConfirmedWeighingsEdit', false);
              formikInstance.setFieldValue('confirmationRequiredCondition', 'NEVER');
              handleChangeField(e, 'checkbox');
            }}
          ></Checkbox>
          <label htmlFor="weighingConfirmation" className="checkbox-label">
            {t('weighingConfirmation')}
          </label>
        </div>
      </div>
      <div className="p-fluid formgrid grid">
        <div className="field-checkbox col-12">
          <Checkbox
            id="confirmationComment"
            checked={formikInstance.values.confirmationComment}
            onChange={(e) => {
              if (formikInstance.values.confirmationComment) {
                void formikInstance.setFieldValue('requireConfirmationComment', false);
              }
              handleChangeField(e, 'checkbox');
            }}
            disabled={!formikInstance.values.weighingConfirmation}
          ></Checkbox>
          <label htmlFor="confirmationComment" className="checkbox-label">
            {t('confirmationComment')}
          </label>
        </div>
        <div className="field-checkbox col-12">
          <Checkbox
            id="requireConfirmationComment"
            checked={formikInstance.values.requireConfirmationComment}
            onChange={(e) => handleChangeField(e, 'checkbox')}
            disabled={!formikInstance.values.weighingConfirmation || !formikInstance.values.confirmationComment}
          ></Checkbox>
          <label htmlFor="requireConfirmationComment" className="checkbox-label">
            {t('requireConfirmationComment')}
          </label>
        </div>
        <div className="field-checkbox col-12">
          <Checkbox
            id="enableConfirmedWeighingsEdit"
            checked={formikInstance.values.enableConfirmedWeighingsEdit}
            onChange={(e) => handleChangeField(e, 'checkbox')}
            disabled={!formikInstance.values.weighingConfirmation}
          ></Checkbox>
          <label htmlFor="enableConfirmedWeighingsEdit" className="checkbox-label">
            {t('enableConfirmedWeighingsEdit')}
          </label>
        </div>
        <div className="ml-1vw mt-1vw">
          <label htmlFor="confirmationRequiredCondition">{t('confirmationRequiredCondition')}</label>
          <br />
          <div className="flex ml-1vw mt-1vw">
            <div className="field-radiobutton mr-3">
              <RadioButton
                inputId="confirmationRequiredCondition0"
                name="confirmationRequiredCondition0"
                value="NEVER"
                onChange={onChangeRequiredCondition}
                checked={formikInstance.values.confirmationRequiredCondition === 'NEVER'}
                disabled={!formikInstance.values.weighingConfirmation}
              />
              <label htmlFor="confirmationRequiredCondition0">{t('never')}</label>
            </div>
            <div className="field-radiobutton mr-3">
              <RadioButton
                inputId="confirmationRequiredCondition1"
                name="confirmationRequiredCondition1"
                value="AFTER_EACH_MEASUREMENT"
                onChange={onChangeRequiredCondition}
                checked={formikInstance.values.confirmationRequiredCondition === 'AFTER_EACH_MEASUREMENT'}
                disabled={!formikInstance.values.weighingConfirmation}
              />
              <label htmlFor="confirmationRequiredCondition1">{t('afterEachMeasurement')}</label>
            </div>
            <div className="field-radiobutton mr-3">
              <RadioButton
                inputId="confirmationRequiredCondition2"
                name="confirmationRequiredCondition2"
                value="AFTER_CLOSING_WEIGHING"
                onChange={onChangeRequiredCondition}
                checked={formikInstance.values.confirmationRequiredCondition === 'AFTER_CLOSING_WEIGHING'}
                disabled={!formikInstance.values.weighingConfirmation}
              />
              <label htmlFor="confirmationRequiredCondition2">{t('afterClosingWeighing')}</label>
            </div>
          </div>
        </div>
      </div>
    </Fieldset>
  );
};

export default ConfirmationsContent;
