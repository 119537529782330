import React, { useEffect, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const dateFilterFunction = (value, filter) => {
  const formatDate = (date) => {
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    return date.getFullYear() + '-' + month + '-' + day;
  };

  if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
    return true;
  }

  if (value === undefined || value === null) {
    return false;
  }

  return formatDate(new Date(value)) === formatDate(filter);
};

const DateFilterElement = ({ initialValue, onChangeFilter, name, placeholder }) => {
  const [value, setValue] = useState(initialValue || null);

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const onChange = (e) => {
    if (e.value !== value) {
      setValue(e.value);
      onChangeFilter(e.value, name);
    }
  };

  const onClickClear = () => {
    setValue(null);
    onChangeFilter(null, name);
  };

  return (
    <div className="relative">
      <Calendar
        value={value}
        onChange={onChange}
        dateFormat="dd.mm.y"
        className="p-column-filter"
        placeholder={placeholder}
        selectionMode="range"
        footerTemplate={() => <></>}
      />
      {value ? (
        <i
          onClick={onClickClear}
          style={{ color: '#6c757d' }}
          className="pi pi-times absolute right-8px top-9px cursor-pointer"
        />
      ) : (
        ''
      )}
    </div>
  );
};

const CustomDatePicker = ({ filters, onChangeFilter, name, clearFiltersFlag, setClearFiltersFlag }) => {
  const { t } = useTranslation('dateTableFilterElements');

  const [startDate, setStartDate] = useState(filters.date && filters.date[0] ? filters.date[0] : undefined);
  const [endDate, setEndDate] = useState(filters.date && filters.date[1] ? filters.date[1] : undefined);

  const [tempStartDate, setTempStartDate] = useState(startDate);
  const [tempEndDate, setTempEndDate] = useState(endDate);

  const [isModalVisible, setModalVisible] = useState(false);
  const [singleDateMode, setSingleDateMode] = useState(filters.singleDateMode ? filters.singleDateMode : false);
  const [invalidDateError, setInvalidDateError] = useState(false);
  const [displayValue, setDisplayValue] = useState('');

  const shouldClearDate = true;

  const updateDisplayValue = (firstDate, secondDate) => {
    if (singleDateMode) {
      setDisplayValue(firstDate ? moment(firstDate).format('DD.MM.YYYY') : '');
    }

    if (!singleDateMode && firstDate && secondDate) {
      setDisplayValue(
        `${moment(firstDate).format('DD.MM.YYYY HH:mm:ss')} - ${moment(secondDate).format('DD.MM.YYYY HH:mm:ss')}`,
      );
    }
  };

  useEffect(() => {
    setStartDate(filters.date && filters.date[0] ? filters.date[0] : undefined);
    setEndDate(filters.date && filters.date[1] ? filters.date[1] : undefined);
    updateDisplayValue(filters.date?.[0], filters.date?.[1]);
  }, [filters.date]);

  const handleClearAll = () => {
    setDisplayValue('');
    setTempStartDate(null);
    setTempEndDate(null);
    setStartDate(null);
    setEndDate(null);
    onChangeFilter(null, name, singleDateMode, shouldClearDate);
    setModalVisible(false);
  };

  useEffect(() => {
    if (tempStartDate && tempEndDate && moment(tempStartDate).isAfter(moment(tempEndDate))) {
      setInvalidDateError(true);
    } else {
      setInvalidDateError(false);
    }
  }, [tempStartDate, tempEndDate]);

  useEffect(() => {
    if (clearFiltersFlag) {
      handleClearAll();
      setClearFiltersFlag(false);
    }
  }, [clearFiltersFlag]);

  useEffect(() => {
    if (singleDateMode) {
      setEndDate(null);
      setTempEndDate(null);
    }
  }, [singleDateMode]);

  const handleConfirm = () => {
    let combinedValue = null;
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);

    if (singleDateMode && tempStartDate) {
      combinedValue = [
        tempStartDate,
        moment(tempStartDate)
          .endOf('day')
          .hours(23)
          .minutes(59)
          .seconds(59)
          .milliseconds(999)
          .format('YYYY-MM-DD HH:mm:ss.SSS'),
      ];
    }

    if (tempStartDate && tempEndDate) {
      combinedValue = [tempStartDate, tempEndDate];
    }

    onChangeFilter(combinedValue, name, singleDateMode);

    updateDisplayValue(tempStartDate, tempEndDate);
    setModalVisible(false);
  };

  const viewDateHandler = (isStartDate) => {
    const currentDate = new Date();

    currentDate.setHours(isStartDate ? 0 : 23);
    currentDate.setMinutes(isStartDate ? 0 : 59);
    currentDate.setSeconds(isStartDate ? 0 : 59);

    return currentDate;
  };

  /**TODO: its temporary solution with placeholders, if we dont fix somehow calendar bug
   * with displaying values after retrieving them from filters.date,
   * this solution is the best one we currently can have */
  const generateStartDatePlaceholder = () => {
    if (!tempStartDate) return '';

    if (singleDateMode) {
      return moment(tempStartDate).format('DD.MM.YYYY');
    }

    return moment(tempStartDate).format('DD.MM.YYYY HH:mm:ss');
  };

  const generateEndDatePlaceholder = () => {
    if (!tempEndDate) return '';

    return moment(tempEndDate).format('DD.MM.YYYY HH:mm:ss');
  };

  const modalFooter = (
    <div className="flex justify-content-between mt-4">
      <div>
        <Button label={t('clearAll')} icon="pi pi-trash" onClick={handleClearAll} text className="mr-2" />
      </div>
      <div>
        <Button label={t('close')} icon="pi pi-times" onClick={() => setModalVisible(false)} text className="mr-2" />
        <Button
          label={t('accept')}
          icon="pi pi-check"
          disabled={
            invalidDateError ||
            (singleDateMode && !tempStartDate) ||
            (!singleDateMode && (!tempStartDate || !tempEndDate))
          }
          onClick={handleConfirm}
          autoFocus
        />
      </div>
    </div>
  );

  return (
    <div>
      <div>
        <InputText
          value={displayValue}
          placeholder={t('chooseDateOrRange')}
          readOnly
          onClick={() => setModalVisible(true)}
        />
      </div>

      <Dialog
        header={t('chooseDateOrRange')}
        visible={isModalVisible}
        className="w-50vw"
        footer={modalFooter}
        onHide={() => setModalVisible(false)}
      >
        <div className="pt-3 pb-5">
          <Checkbox
            inputId="singleDateModeCheckbox"
            onChange={(e) => setSingleDateMode(e.checked)}
            checked={singleDateMode}
          />
          <label htmlFor="singleDateModeCheckbox"> {t('chooseSingleDate')}</label>
        </div>

        <div className="grid">
          <div className="col-6">
            <h6>{singleDateMode ? t('date') : t('startDate')}</h6>

            <Calendar
              value={tempStartDate}
              dateFormat="dd.mm.yy"
              viewDate={!singleDateMode && viewDateHandler(true)}
              onChange={(e) => setTempStartDate(e.value)}
              showTime={!singleDateMode ? true : false}
              showSeconds={!singleDateMode ? true : false}
              hourFormat="24"
              placeholder={generateStartDatePlaceholder()}
              showIcon
              showButtonBar
              className={invalidDateError ? 'p-invalid' : ''}
            />

            {invalidDateError && <div className="pt-3 text-red-500">{t('invalidDateError')}</div>}
          </div>
          {!singleDateMode && (
            <div className="col-6">
              <h6>{t('endDate')}</h6>
              <Calendar
                value={tempEndDate}
                dateFormat="dd.mm.yy"
                viewDate={viewDateHandler(false)}
                onChange={(e) => setTempEndDate(e.value)}
                showTime
                showSeconds
                hourFormat="24"
                placeholder={generateEndDatePlaceholder()}
                showIcon
                showButtonBar
                className={invalidDateError ? 'p-invalid' : ''}
              />
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};

const TextFilterElement = ({
  initialValue,
  onChangeFilter,
  name,
  placeholder,
  isSearchIcon = false,
  isClearButtonVisible = false,
}) => {
  const [value, setValue] = useState(initialValue || '');
  const [valueAfterChange, setValueAfterChange] = useState(initialValue || '');
  const [showClearBtn, setShowClearBtn] = useState(false);

  const checkClearBtnVisible = () => {
    if (valueAfterChange !== value) {
      setShowClearBtn(true);
    } else if (isClearButtonVisible) {
      setShowClearBtn(true);
    } else {
      setShowClearBtn(false);
    }
  };

  useEffect(() => {
    checkClearBtnVisible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialValue !== value) {
      if (!initialValue) {
        onClickClear();
      } else {
        setValue(initialValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    if (valueAfterChange !== value) {
      setValueAfterChange(value);
      onChangeFilter(value, name);
      checkClearBtnVisible();
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter' && valueAfterChange !== value) {
      setValueAfterChange(value);
      onChangeFilter(value, name);
      checkClearBtnVisible();
    }
  };

  const onClickClear = () => {
    setValue('');
    setValueAfterChange('');
    onChangeFilter('', name);
    checkClearBtnVisible();
  };

  return (
    <div className="relative">
      {isSearchIcon && (
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="text"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            className="p-column-filter pr-5"
          />
        </span>
      )}
      {!isSearchIcon && (
        <InputText
          type="text"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          className="p-column-filter"
        />
      )}
      {showClearBtn ? (
        <i
          onClick={onClickClear}
          style={{ color: '#6c757d' }}
          className="pi pi-times absolute cursor-pointer right-8px top-9px"
        />
      ) : (
        ''
      )}
    </div>
  );
};

const DropdownFilterElement = ({ initialValue, onChangeFilter, name, placeholder, options }) => {
  const [value, setValue] = useState(initialValue || null);

  const onChange = (e) => {
    const newValue = e.value !== undefined ? e.value : null;
    if (newValue !== value) {
      setValue(newValue);
      onChangeFilter(newValue, name);
    }
  };

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return (
    <div className="relative">
      <Dropdown
        value={value}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        className="p-column-filter"
        showClear
      />
    </div>
  );
};

const DataTableFilterElements = {
  Date: DateFilterElement,
  Text: TextFilterElement,
  Dropdown: DropdownFilterElement,
  dateFilterFunction,
  CustomDatePicker,
};

export default DataTableFilterElements;
