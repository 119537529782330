import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import apiKeysService, { ApiKeyCredentials } from '../../services/ScaleoApiServices/ApiKeyService';
import { ReducerState } from '../../types/reducer-state';
import { Contractor } from '../../types/weighing';
import contractorsService from '../../services/ScaleoApiServices/ContractorsService';

export const useApiKeysData = () => {
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const [apiKeys, setApiKeys] = useState<ApiKeyCredentials[]>([]);
  const [contractors, setContractors] = useState<Contractor[]>([]);

  const fetchAPI = useCallback(async () => {
    const apiKeyResponse = await apiKeysService.getApiKeys();
    const contractorsResponse = await contractorsService.getContractors();

    if (apiKeyResponse.data) {
      setApiKeys(apiKeyResponse.data);
    }

    if (contractorsResponse.data) {
      setContractors(contractorsResponse.data);
    }
  }, [setApiKeys]);

  useEffect(() => {
    fetchAPI();
  }, [loggedUserContext.currentCustomer?.id, fetchAPI]);

  return {
    apiKeys,
    fetchAPI,
    contractors,
  };
};
