import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import nodeModelsService from '../../../services/ScaleoApiServices/NodeModelsService';
import '../../DataTable.css';
import { useTranslation } from 'react-i18next';

const IndicatorTypes = () => {
  const { t } = useTranslation('managementIndicatorTypes');

  const [indicatorTypes, setIndicatorTypes] = useState(null);
  const loggedUserContext = useSelector((state) => state.user.context);

  const dt = useRef(null);

  const fetchIndocatorTypes = useCallback(async () => {
    nodeModelsService.getIndicatorTypes().then((response) => {
      setIndicatorTypes(response.data);
    });
  }, []);

  useEffect(() => {
    fetchIndocatorTypes();
  }, [fetchIndocatorTypes, loggedUserContext.currentCustomer.id]);

  const header = <div className="table-header">{t('header')}</div>;

  const rightToolbarTemplate = () => {
    return <></>;
  };

  const nameBodyTemplate = (rowData) => {
    return <>{rowData.name}</>;
  };

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <Toolbar right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                responsiveLayout="scroll"
                ref={dt}
                value={indicatorTypes}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                sortOrder={1}
                sortField="name"
              >
                <Column field="name" header={t('name')} body={nameBodyTemplate}></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndicatorTypes;
