import { Button } from 'primereact/button';
import { hasPermissions } from '../../../../../../utils/permissionsUtils';
import ComponentItemTemplates from '../../../../../_shared/ComponentItemTemplates';
import { CustomAutoComplete } from '../../../../../_shared/CustomComponents';
import FormErrorMessage from '../../../../../_shared/FormErrorMessage';
import { Container } from '../../../../../../types/container';
import { ReducerState } from '../../../../../../types/reducer-state';
import { useSelector } from 'react-redux';
import isIn from '../../../../../../utils/isIn';
import { Fieldset } from 'primereact/fieldset';
import { useTranslation } from 'react-i18next';
import { NewOrEditContainerDialog } from '../../../../../DataSets/Containers/components/NewOrEditContainerDialog';
import { useEffect, useState } from 'react';

interface ContainerTareFormProps {
  formik: any;
  containers: Container[];
  fetchComponentData: () => void;
  selectContainer: (c: Container) => void;
}

export const ContainerTareForm = ({
  formik,
  containers,
  selectContainer,
  fetchComponentData,
}: ContainerTareFormProps) => {
  const { t } = useTranslation('containersForm');
  const { permissions } = useSelector((state: ReducerState) => state.user.context);
  const { formState } = useSelector((state: ReducerState) => state.weighing);

  const [containerDialogVisible, setContainerDialogVisible] = useState(false);

  useEffect(() => {
    fetchComponentData();
  }, [fetchComponentData, containerDialogVisible]);

  return isIn(formState.selectedWeighingModeId, ['CONTAINER']) ? (
    <>
      <div className="lg:col-6 md:col-12 sm:col-12">
        <div className="grid">
          <div className="col-12">
            <Fieldset legend={t('newWeighingFormContainerTitle')}>
              <div className="field grid">
                <label htmlFor="container" className="col-12 sm:col-5 text-2xl">
                  {t('newWeighingFormDetailsContainer')}
                </label>
                <div className="col-8 sm:col-5">
                  <CustomAutoComplete
                    id="container"
                    field="fullName"
                    value={addContainerNameAndCode(formik.values.container)}
                    handler={(e: any) => e.value && selectContainer(e.value)}
                    source={containers?.map(addContainerNameAndCode)}
                    customItemTemplate={ComponentItemTemplates.Container}
                  />
                  <FormErrorMessage fieldName="container" formikInstance={formik} />
                </div>
                {hasPermissions(['DATASETS_EDIT'], permissions) && (
                  <div className="col-1">
                    <Button
                      type="button"
                      icon="pi pi-plus"
                      severity="success"
                      onClick={() => setContainerDialogVisible(true)}
                    />
                  </div>
                )}
              </div>
            </Fieldset>
          </div>
        </div>
        <NewOrEditContainerDialog
          visible={containerDialogVisible}
          setContainerDialogVisible={setContainerDialogVisible}
          onContainerAdded={selectContainer}
          container={null}
          displayTare={false}
        />
      </div>
    </>
  ) : (
    <></>
  );
};

const addContainerNameAndCode = (c: Container) => c && { ...c, fullName: `${c.name}(${c.code})` };
