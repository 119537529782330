import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import contractorsService from '../../../../../services/ScaleoApiServices/ContractorsService';
import productsService from '../../../../../services/ScaleoApiServices/ProductsService';
import { weighingActions } from '../../../../../store/weighing-slice';
import isIn from '../../../../../utils/isIn';
import useTransactionType from '../../../../../utils/useTransactionType';
import FormErrorMessageScroller from '../../../../_shared/FormErrorMessageScroller';
import '../../../MainPage.css';
import ContinueWeighingDialog from './components/ContinueWeighingDialog';
import ScaleIndication from './components/ScaleIndication';
import TestWeighingDialog from './components/TestWeighingDialog';
import WeighingButtons from './components/WeighingButtons';
import { Vehicle, Weighing } from '../../../../../types/weighing';
import { ReducerState } from '../../../../../types/reducer-state';
import { ContainersForm } from './components/ContainersForm';
import { ContainerTareForm } from './components/ContainerTareForm';
import { Container } from '../../../../../types/container';
import { WeighingVehicleForm } from './components/WeighingVehicleForm';
import { WeighingDataForm } from './components/WeighingDataForm';
import { useNewWeighingFormSubmit } from '../../../../../hooks/NewWeighingForm/useNewWeighingFormSubmit';
import { useNewWeighingFormData } from '../../../../../hooks/NewWeighingForm/useNewWeighingFormData';
import { useIsWeighingStation } from '../../../../../hooks/_shared/useIsWeighingStation';

interface NewWeighingFormProps {
  publishWeighCommandHandler: (topic: string, data: any) => void;
  publishScaleCommandHandler: (topic: string, data: any) => void;
  onWeighingCloseRequested: (id: string) => void;
  optionsData: any;
  fetchComponentData: () => Promise<void>;
  setCloseWeighing: (w: Weighing) => void;
}

const NewWeighingForm = ({
  publishWeighCommandHandler,
  publishScaleCommandHandler,
  onWeighingCloseRequested,
  optionsData,
  fetchComponentData,
  setCloseWeighing,
}: NewWeighingFormProps) => {
  const dispatch = useDispatch();

  const { weighingConfiguration, automationCurrentMode, formState, weighingState, selectedScale } = useSelector(
    (state: ReducerState) => state.weighing,
  );
  const { currentIndication } = useSelector((state: ReducerState) => state.indication);
  const isWeighingStation = useIsWeighingStation();

  // TODO: cleanup when we remove isAutoWeighing as scale attribute
  const isManualWeighingForm =
    (!selectedScale?.edgeDevice?.automationStates && !selectedScale?.isAutoWeighing) ||
    automationCurrentMode?.weighingFormMode === 'FULL';

  const [continueWeighingDialogData, setContinueWeighingDialogData] = useState<{
    visible: boolean;
    weighingState: any;
  }>({
    visible: false,
    weighingState: null,
  });
  const [isIndicationOutOfRange, setIsIndicationOutOfRange] = useState({ minWeight: false, maxWeight: false });

  const { getTransactionTypeLabel } = useTransactionType();
  const [localTouched, setLocalTouched] = useState({});

  const updateFormState = (state: any) => dispatch(weighingActions.updateFormState(state));

  const setWeighingInformationFieldsValues = async (openWeighing: Weighing | null, vehicle: Vehicle) => {
    if (openWeighing) {
      await setOpenWeighingInformationFields(openWeighing);
    } else {
      await setFirstWeighingInformationFields(vehicle);
    }
    setLocalTouched({});
  };

  const { initFormValues, fetchAdditionalFields, fetchVehicleAndTrailerWeighingState } = useNewWeighingFormData(
    fetchComponentData,
    optionsData.transactionTypes,
    setContinueWeighingDialogData,
    setWeighingInformationFieldsValues,
  );
  const { formik, setMeasurementType, testWeighingDialogData, handleSaveTestWeighing, setTestWeighingDialogData } =
    useNewWeighingFormSubmit(
      initFormValues,
      publishWeighCommandHandler,
      setIsIndicationOutOfRange,
      onWeighingCloseRequested,
      setCloseWeighing,
    );

  const selectContainer = (selectedContainer: Container) => {
    updateFormState({ selectedContainer });
  };

  const setOpenWeighingInformationFields = async (openWeighing: Weighing) => {
    const isAutomaticWeighing = !!openWeighing.automatic;
    const preparedOpenWeighing = {
      ...openWeighing,
      weighingMode: isAutomaticWeighing ? 'DOUBLE' : openWeighing.weighingMode,
    };

    const productFromOpenWeighing =
      preparedOpenWeighing?.measurements[preparedOpenWeighing?.measurements.length - 1].product || null;
    const product = productsService.mapProduct(productFromOpenWeighing);

    const { additionalFields, measurementAdditionalFields } = await fetchAdditionalFields(
      product?.id,
      preparedOpenWeighing,
      true,
      true,
    );

    const newFormState = {
      selectedWeighingModeId: preparedOpenWeighing.weighingMode,
      selectedTransactionType: preparedOpenWeighing.transactionType && {
        id: preparedOpenWeighing.transactionType,
        name: getTransactionTypeLabel(preparedOpenWeighing.transactionType),
      },
      selectedProduct: product,
      selectedContractor: contractorsService.mapContractor(preparedOpenWeighing.contractor),
      selectedDriver: preparedOpenWeighing?.driver,
      updateTare: openWeighing.updateVehicleTare,
      updateData: openWeighing.updateVehicleDefaultData,
      printPosReceipt: openWeighing.printPosReceipt,
      saveVehicle: weighingConfiguration.saveVehicleDefaultValue,
      additionalFields,
      measurementAdditionalFields,
      containers: openWeighing.containers,
      selectedTrailer: openWeighing.trailer,
    };
    updateFormState(newFormState);
  };

  const setFirstWeighingInformationFields = async (vehicle: Vehicle | null) => {
    if (!vehicle) {
      return;
    }
    const product = vehicle?.defaultProduct && productsService.mapProduct(vehicle.defaultProduct);

    const { additionalFields, measurementAdditionalFields } = await fetchAdditionalFields(product?.id, null);

    const newFormState = {
      selectedTransactionType: weighingConfiguration.transactionTypeDefaultId && {
        id: weighingConfiguration.transactionTypeDefaultId,
        name: getTransactionTypeLabel(weighingConfiguration.transactionTypeDefaultId),
      },
      selectedProduct: product,
      selectedContractor: contractorsService.mapContractor(vehicle?.defaultContractor),
      selectedDriver: vehicle?.defaultDriver,
      updateTare: weighingConfiguration.updateVehicleTareDefaultValue,
      updateData: weighingConfiguration.updateVehicleDataDefaultValue,
      printPosReceipt: weighingConfiguration.printPosReceiptDefaultValue,
      saveVehicle: weighingConfiguration.saveVehicleDefaultValue,
      additionalFields,
      measurementAdditionalFields,
    };
    updateFormState(newFormState);
  };

  const selectSpecifiedVehicle = useCallback(async () => {
    const vehicle = formState.selectedVehicle;
    const trailer = formState.selectedTrailer;
    if (
      !!vehicle &&
      !vehicle.isNew &&
      (weighingState?.id !== vehicle?.id ||
        (formState.continueWeighing && formState.continueWeighing?.id !== weighingState.openWeighing?.id))
    ) {
      await fetchVehicleAndTrailerWeighingState(vehicle, trailer);
    } else if (!!vehicle && vehicle.isNew) {
      await setFirstWeighingInformationFields(null);
    } else if (!vehicle) {
      const newFormState = {
        updateTare: weighingConfiguration.updateVehicleTare,
        updateData: weighingConfiguration.updateVehicleDefaultData,
        printPosReceipt: weighingConfiguration.printPosReceiptDefaultValue,
        saveVehicle: weighingConfiguration.saveVehicleDefaultValue,
      };
      updateFormState(newFormState);
    }
  }, [formState.selectedVehicle, fetchVehicleAndTrailerWeighingState, formState.selectedTrailer]);

  useEffect(() => {
    void selectSpecifiedVehicle();
  }, [formState.selectedVehicle, fetchVehicleAndTrailerWeighingState, formState.selectedTrailer?.id]);

  const onHideContinueWeighingDialog = async (continueWeighing: Weighing) => {
    if (continueWeighing) {
      dispatch(weighingActions.updateFormState({ continueWeighing }));
      dispatch(
        weighingActions.weighingStateFetched({
          ...continueWeighingDialogData.weighingState,
          openWeighing: continueWeighing,
        }),
      );
      await setWeighingInformationFieldsValues(continueWeighing, formState.selectedVehicle);
    } else {
      dispatch(weighingActions.updateFormState({ continueWeighing: null }));
      dispatch(
        weighingActions.weighingStateFetched({ ...continueWeighingDialogData.weighingState, openWeighing: null }),
      );
      await setWeighingInformationFieldsValues(null, formState.selectedVehicle);
    }
    setContinueWeighingDialogData({ visible: false, weighingState: null });
  };

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid">
          <div className="col-12">
            <ScaleIndication
              publishScaleCommandHandler={publishScaleCommandHandler}
              isIndicationOutOfRange={isIndicationOutOfRange}
              setIsIndicationOutOfRange={setIsIndicationOutOfRange}
              vehicleData={formik?.values?.vehicle}
              edgeDeviceMaxWeightBrutto={selectedScale?.edgeDevice?.maxWeightBrutto}
            />
          </div>

          {isWeighingStation && (
            <ContainerTareForm
              containers={optionsData.containers}
              formik={formik}
              selectContainer={selectContainer}
              fetchComponentData={fetchComponentData}
            />
          )}
          {isManualWeighingForm && !isIn(formState.selectedWeighingModeId, ['CONTAINER']) && isWeighingStation && (
            <>
              <WeighingVehicleForm vehicles={optionsData.vehicles} trailers={optionsData.trailers} formik={formik} />
              <WeighingDataForm
                contractors={optionsData.contractors}
                drivers={optionsData.drivers}
                dictionaries={optionsData.dictionaries}
                localTouched={localTouched}
                setLocalTouched={setLocalTouched}
                formik={formik}
                fetchComponentData={fetchComponentData}
              />
              <ContainersForm formik={formik} containers={optionsData.containers} updateFormState={updateFormState} />
            </>
          )}
          {isManualWeighingForm && isWeighingStation && (
            <WeighingButtons
              isNewVehicle={formik.values.vehicle?.isNew}
              isIndicationOutOfRange={isIndicationOutOfRange}
              setMeasurementType={setMeasurementType}
              vehicleData={formik?.values?.vehicle}
              currentIndication={currentIndication}
              edgeDeviceMaxWeightBrutto={selectedScale?.edgeDevice?.maxWeightBrutto}
            />
          )}
        </div>
      </form>
      <ContinueWeighingDialog visible={continueWeighingDialogData.visible} onHide={onHideContinueWeighingDialog} />
      <TestWeighingDialog
        visible={testWeighingDialogData.visible}
        onTestWeighingSaved={handleSaveTestWeighing}
        onHide={() => setTestWeighingDialogData({ visible: false, weighingAction: null })}
      />
    </FormErrorMessageScroller>
  );
};

export default NewWeighingForm;
