import axios from 'axios';
import { RequestConfig } from './types/RequestConfig';

class WeighingConfigurationService {
  async getConfiguration() {
    return axios.get(`/weighing-configuration`);
  }

  async updateConfiguration(postData: any) {
    return axios.put(`/weighing-configuration`, {
      data: postData,
    });
  }

  async searchAdditionalFields(postData: any, requestConfig: RequestConfig) {
    return axios.post(
      `/weighing-configuration/additional-fields`,
      {
        data: postData,
      },
      requestConfig,
    );
  }

  async getAllAdditionalFields() {
    return axios.get(`/weighing-configuration/all-additional-fields`);
  }

  async getConfigurationReceipts() {
    return axios.get(`/weighing-configuration/receipts`);
  }

  async updateConfigurationReceipts(postData: any) {
    return axios.put(`/weighing-configuration/receipts`, {
      data: postData,
    });
  }

  async getConfigurationConfirmations(configuration?: any) {
    return axios.get(`/weighing-configuration/confirmations`, configuration);
  }

  async updateConfigurationConfirmations(postData: any) {
    return axios.put(`/weighing-configuration/confirmations`, {
      data: postData,
    });
  }

  async getConfigurationReports() {
    return axios.get(`/weighing-configuration/reports`);
  }

  async updateConfigurationReports(postData: any) {
    return axios.put(`/weighing-configuration/reports`, {
      data: postData,
    });
  }

  async updatePOSConfigurationReceipts(postData: any) {
    return axios.put(`/weighing-configuration/pos-receipts`, {
      data: postData,
    });
  }
}

const weighingConfigurationService = new WeighingConfigurationService();
export default weighingConfigurationService;
