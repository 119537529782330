import React, { useEffect, useState } from 'react';
import installationService from '../services/ScaleoApiServices/InstallationService';
import { VerifyLicenseKey } from './VerifyLicenseKey';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

export const UpdateLicenseKey = () => {
  const { t } = useTranslation('updateLicenseKeyPage');

  const [isSuccess, setIsSuccess] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setIsChanged(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isChanged && !isSuccess) {
      window.location.replace('/');
    }
  }, [isSuccess]);

  return (
    <>
      {isSuccess && (
        <Dialog
          visible
          breakpoints={{ '960px': '90vw', '640px': '100vw' }}
          header={t('header')}
          modal
          className="p-fluid w-33vw"
          onHide={() => setIsSuccess(false)}
        />
      )}
      <VerifyLicenseKey verifyOrUpdateKey={installationService.updateLicenseKey} setIsSuccess={setIsSuccess} />
    </>
  );
};
