import axios from 'axios';
import { EdgeDevice, ValueDynamicSetting } from '../../types/edge-device';

class EdgeDevicesService {
  async searchEdgeDevices(query?: any) {
    const edgeDevices = await axios.post('/edge-devices/search', query);
    return edgeDevices.data.map(mapFromResponseBody);
  }
  async getActivationOtp(edgeDevice: EdgeDevice) {
    const activationOtpResponse = await axios.get(`/edge-devices/${edgeDevice.id}/activation-otp`);
    return activationOtpResponse.data.otp;
  }
  async createEdgeDevice(edgeDevice: EdgeDevice) {
    return axios.post('/edge-devices', mapToRequestBody(edgeDevice));
  }
  async updateEdgeDevice(edgeDevice: EdgeDevice, id: string) {
    return axios.put(`/edge-devices/${id}`, mapToRequestBody(edgeDevice));
  }
  async deleteEdgeDevice(id: string) {
    return axios.delete(`/edge-devices/${id}`);
  }
  async getAutomationConfig(id: string) {
    return axios.get(`/edge-devices/${id}/automation-config`);
  }

  async saveAutomationConfig(id: string, config: any) {
    return axios.put(`/edge-devices/${id}/automation-config`, config);
  }

  async saveDynamicSettings(id: string, settings: ValueDynamicSetting[]) {
    return axios.put(`/edge-devices/${id}/automation-config/dynamic-settings`, settings);
  }

  async changeAutomationCurrentMode(modeId: string, edgeDeviceId: string) {
    return axios.put(`/edge-devices/${edgeDeviceId}/automation-current-mode`, {
      modeId: modeId,
    });
  }

  async syncEdgeDeviceData(edgeDeviceId: string) {
    return axios.post(`/edge-devices/${edgeDeviceId}/configuration-update`);
  }
}

const mapToRequestBody = (edgeDevice: EdgeDevice) => {
  return {
    id: edgeDevice.id,
    name: edgeDevice.name,
    station: edgeDevice.station,
  };
};

const mapFromResponseBody = (edgeDevice: EdgeDevice) => {
  return {
    id: edgeDevice.id,
    code: edgeDevice.code,
    location: edgeDevice.location,
    station: edgeDevice.station,
    activationStatus: edgeDevice.activationStatus,
    name: edgeDevice.name,
  };
};

const edgeDevicesService = new EdgeDevicesService();
export default edgeDevicesService;
