import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { CustomAutoComplete } from '../../../_shared/CustomComponents';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import { ProductAutoComplete } from '../../../_shared/ProductAutoComplete';
import { Tares } from '../../Tares';
import vehiclesService from '../../../../services/ScaleoApiServices/VehiclesService';
import { Vehicle, Driver, Contractor } from '../../../../types/weighing';
import { useSelector } from 'react-redux';
import { ReducerState } from '../../../../types/reducer-state';
import { useTranslation } from 'react-i18next';
import { AutomaticWeighingMode } from '../../../../enums/automatic-weighing-mode.enum';
import { AccessControlPanel } from './AccessControlPanel';
import { Trailer } from '../../../../types/trailer';
import { InputNumber } from 'primereact/inputnumber';
import { isModulesEnabled } from '../../../../utils/modulesUtils';

interface NewOrEditVehicleDialogProps {
  formik: any;
  visible: boolean;
  vehicle: Vehicle;
  setTares: (t: any) => void;
  hideDialog: () => void;
  configurationWeighingModes: string[];
  inputDrivers: Driver[] | null;
  inputContractors: Contractor[] | null;
  inputTrailers: Trailer[] | null;
  tares: any[];
}

export const NewOrEditVehicleDialog = ({
  formik,
  visible,
  vehicle,
  setTares,
  hideDialog,
  configurationWeighingModes,
  inputDrivers,
  inputContractors,
  inputTrailers,
  tares,
}: NewOrEditVehicleDialogProps) => {
  const { t } = useTranslation('datasetsVehicles');
  const unit = useSelector((state: ReducerState) => state.appConfig.unit);
  const { weighingConfiguration } = useSelector((state: ReducerState) => state.weighing);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  function getTranslationKey(weighingMode: string) {
    switch (weighingMode) {
      case 'SINGLE':
        return 'singleWeighing';
      case 'DOUBLE':
        return 'doubleWeighing';
      case 'DEFAULT':
        return 'defaultAutomaticWeighing';
      default:
        return '';
    }
  }

  const automaticWeighingModes = [
    {
      name: t('defaultAutomaticWeighing'),
      value: AutomaticWeighingMode.DEFAULT,
    },
    {
      name: t('singleWeighing'),
      value: AutomaticWeighingMode.SINGLE,
    },
    {
      name: t('doubleWeighing'),
      value: AutomaticWeighingMode.DOUBLE,
    },
  ];

  const onChangeAutomaticWeighingMode = (e: any) => {
    formik.setFieldValue('automaticWeighingMode', e?.value?.value);
  };

  const onChangeWeighingModeSwitch = (e: any, modeName: string) => {
    formik.setFieldValue(
      'weighingMode',
      e.value
        ? [...formik.values.weighingMode, modeName]
        : formik.values.weighingMode?.filter((el: any) => el !== modeName),
      true,
    );
  };
  const showHistory = () => {
    vehiclesService.getVehicleHistory(vehicle.id as any).then((response) => {
      setTares(response.data);
    });
  };
  const registrationHandler = (event: any) => {
    const reg = /^[a-zA-Z0-9]/;
    if (reg.test(event.nativeEvent.data))
      formik.setFieldValue('registrationNumber', event.target.value.toUpperCase().replace(/ /g, ''));
  };

  const tareValueHandler = (e: any) => {
    formik.setFieldValue('tareValue', e.target.value);
    formik.setFieldValue('tareChanged', true);
  };

  const maxWeightBruttoHandler = (e: any) => {
    formik.setFieldValue('maxWeightBrutto', e?.value);
  };

  const selectProduct = (product: any) => {
    formik.setFieldValue('product', product);
  };

  const vehicleDialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={hideDialog} />
      <Button type="submit" label={t('saveButton')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={visible}
        header={t('vehicleDetails')}
        modal
        className="p-fluid w-40vw"
        footer={vehicleDialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      >
        <form>
          <div className="field">
            <label htmlFor="registrationNumber">{t('registrationNumber')}</label>
            <InputText
              id="registrationNumber"
              value={formik.values.registrationNumber}
              onChange={registrationHandler}
              autoFocus
            />
            <FormErrorMessage fieldName="registrationNumber" formikInstance={formik} />
          </div>
          {weighingConfiguration?.addTrailerRegistrationNumbersSupport && (
            <div className="field">
              <label htmlFor="trailer">{t('trailer')}</label>
              <CustomAutoComplete
                id="trailer"
                value={formik.values?.trailer?.registrationNumber}
                handler={formik.handleChange}
                source={inputTrailers!}
              />
            </div>
          )}
          <div className="field">
            <label htmlFor="tareValue">{`${t('tareValue')} [${unit}] `}</label>
            <InputText id="tareValue" type="number" value={formik.values.tareValue} onChange={tareValueHandler} />
          </div>
          {isModulesEnabled(['VEHICLE_OVERLOAD_CONTROL'], loggedUserContext.currentCustomer?.subscriptionModules) && (
            <div className="field">
              <label htmlFor="maxWeightBrutto">{`${t('maxWeightBrutto')} [${unit}]`}</label>
              <InputNumber
                id="maxWeightBrutto"
                value={formik.values.maxWeightBrutto}
                onChange={maxWeightBruttoHandler}
              />
            </div>
          )}
          <div className="font-bold my-4">{t('weighingModes')}</div>
          {configurationWeighingModes.includes('SINGLE') && (
            <div className="field">
              <label htmlFor="singleWeighing">{t('singleWeighing')}</label>
              <InputSwitch
                className="float-right"
                id="singleWeighing"
                checked={formik.values.weighingMode.includes('SINGLE')}
                onChange={(e) => onChangeWeighingModeSwitch(e, 'SINGLE')}
              />
            </div>
          )}
          {configurationWeighingModes.includes('DOUBLE') && (
            <div className="field">
              <label htmlFor="doubleWeighing">{t('doubleWeighing')}</label>
              <InputSwitch
                className="float-right"
                id="doubleWeighing"
                checked={formik.values.weighingMode.includes('DOUBLE')}
                onChange={(e) => onChangeWeighingModeSwitch(e, 'DOUBLE')}
              />
            </div>
          )}
          {configurationWeighingModes.includes('MULTIPLE') && (
            <div className="field">
              <label htmlFor="multipleWeighing">{t('multipleWeighing')}</label>
              <InputSwitch
                className="float-right"
                id="multipleWeighing"
                checked={formik.values.weighingMode.includes('MULTIPLE')}
                onChange={(e) => onChangeWeighingModeSwitch(e, 'MULTIPLE')}
              />
            </div>
          )}
          {configurationWeighingModes.includes('WEIGHING_SERIES') && (
            <div className="field">
              <label htmlFor="weighingSeries">{t('weighingSeries')}</label>
              <InputSwitch
                className="float-right"
                id="weighingSeries"
                checked={formik.values.weighingMode.includes('WEIGHING_SERIES')}
                onChange={(e) => onChangeWeighingModeSwitch(e, 'WEIGHING_SERIES')}
              />
            </div>
          )}
          {configurationWeighingModes.includes('DOUBLE_WEIGHING_SERIES') && (
            <div className="field">
              <label htmlFor="doubleWeighingSeries">{t('doubleWeighingSeries')}</label>
              <InputSwitch
                className="float-right"
                id="doubleWeighingSeries"
                checked={formik.values.weighingMode.includes('DOUBLE_WEIGHING_SERIES')}
                onChange={(e) => onChangeWeighingModeSwitch(e, 'DOUBLE_WEIGHING_SERIES')}
              />
            </div>
          )}
          {configurationWeighingModes.includes('CONTROL') && (
            <div className="field">
              <label htmlFor="controlWeighing">{t('controlWeighing')}</label>
              <InputSwitch
                className="float-right"
                id="controlWeighing"
                checked={formik.values.weighingMode.includes('CONTROL')}
                onChange={(e) => onChangeWeighingModeSwitch(e, 'CONTROL')}
              />
            </div>
          )}
          {configurationWeighingModes.includes('TARE') && (
            <div className="field">
              <label htmlFor="tareWeighing">{t('tareWeighing')}</label>
              <InputSwitch
                className="float-right"
                id="tareWeighing"
                checked={formik.values.weighingMode.includes('TARE')}
                onChange={(e) => onChangeWeighingModeSwitch(e, 'TARE')}
              />
            </div>
          )}

          <div className="font-bold my-4">{t('automaticWeighingModes')}</div>
          <div className="field">
            <CustomAutoComplete
              id="automaticWeighingMode"
              value={t(getTranslationKey(formik.values.automaticWeighingMode))}
              handler={onChangeAutomaticWeighingMode}
              source={automaticWeighingModes}
            />
          </div>
          <AccessControlPanel formik={formik} />
          <div className="font-bold my-4">{t('defaultData')}</div>
          <div className="field">
            <label htmlFor="driver">{t('driver')}</label>
            <CustomAutoComplete
              id="driver"
              value={formik.values.driver}
              handler={formik.handleChange}
              source={inputDrivers!}
            />
          </div>
          <div className="field">
            <label htmlFor="contractor">{t('contractor')}</label>
            <CustomAutoComplete
              id="contractor"
              value={formik.values.contractor}
              handler={formik.handleChange}
              source={inputContractors!}
            />
          </div>
          <div className="field">
            <label htmlFor="product">{t('product')}</label>
            <ProductAutoComplete
              id="product"
              value={formik.values.product}
              onChange={selectProduct}
              searchForCurrentLocation={false}
              conditionalFields={{ contractorId: formik.values.contractor?.id }}
            />
          </div>
          {vehicle.id && !tares && (
            <Button type="reset" onClick={showHistory} label={t('tareHistory')} className="mr-2"></Button>
          )}
          {tares && <Tares tares={tares}></Tares>}
          <br />
          <br />
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};
