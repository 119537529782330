import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { useSelector } from 'react-redux';
import { formatDecimal } from '../../../utils/formatUtils';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../types/reducer-state';

interface ProductsSummaryProps {
  productsSummary: any[];
}

const ProductsSummary = ({ productsSummary }: ProductsSummaryProps) => {
  const { t } = useTranslation('weighingComponents');

  const unit = useSelector((state: ReducerState) => state.appConfig.unit);

  const measurement1Template = (productsSummary: any) => {
    const measurement1Comment = productsSummary.measurement1?.alibi
      ? t('productsSummaryComment', { alibi: productsSummary.measurement1?.alibi })
      : '';
    return <>{`${formatDecimal(productsSummary.measurement1?.indication)} ${unit} ${measurement1Comment}`}</>;
  };

  const measurement2Template = (productsSummary: any) => {
    const measurement2Comment = productsSummary.measurement2?.alibi
      ? t('productsSummaryComment', { alibi: productsSummary.measurement2?.alibi })
      : '';
    return <>{`${formatDecimal(productsSummary.measurement2?.indication)} ${unit} ${measurement2Comment}`}</>;
  };

  const nettoTemplate = (productsSummary: any) => {
    return <>{`${formatDecimal(productsSummary.netto)} ${unit}`}</>;
  };

  const measurementTemplate = (productsSummary: any, columnNumber: number) => {
    const { measurement1, measurement2 } = productsSummary;

    const isMeasurement1Greater = measurement1?.indication > measurement2?.indication;
    const isMeasurementEqual = measurement1?.indication === measurement2?.indication;
    const isMeasurement1Tare = measurement1?.isTare;
    const isMeasurement2Tare = measurement2?.isTare;

    if (isMeasurement1Greater) {
      return columnNumber === 1 ? measurement1Template(productsSummary) : measurement2Template(productsSummary);
    }

    if (isMeasurementEqual && isMeasurement1Tare) {
      return columnNumber === 1 ? measurement2Template(productsSummary) : measurement1Template(productsSummary);
    }
    if (isMeasurementEqual && isMeasurement2Tare) {
      return columnNumber === 1 ? measurement1Template(productsSummary) : measurement2Template(productsSummary);
    }

    return columnNumber === 1 ? measurement2Template(productsSummary) : measurement1Template(productsSummary);
  };

  const accessCodeNameTemplate = (productsSummary: any) => {
    return <>{productsSummary.accessCodeNames?.join(' -> ')}</>;
  };

  const arrivalNotificationNumberTemplate = (productsSummary: any) => {
    return <>{productsSummary.arrivalNotificationNumbers?.join(' -> ')}</>;
  };

  return (
    <>
      <DataTable
        responsiveLayout="scroll"
        value={productsSummary}
        className="mb-6"
        emptyMessage={t('productsSummaryEmptyMsg')}
      >
        <Column field="product.name" header={t('productsSummaryProduct')}></Column>
        <Column field="netto" body={nettoTemplate} header={t('productsSummaryNetto')}></Column>
        <Column
          field="measurement1"
          header={t('productsSummaryBrutto')}
          body={(productsSummary) => measurementTemplate(productsSummary, 1)}
        ></Column>
        <Column
          field="measurement2"
          header={t('productsSummaryTare')}
          body={(productsSummary) => measurementTemplate(productsSummary, 2)}
        ></Column>
        {productsSummary.find((summ) => summ.accessCodeNames) && (
          <Column field="accessCodeName" header={t('productsSummaryAccessCodeName')} body={accessCodeNameTemplate} />
        )}
        {productsSummary.find((summ) => summ.arrivalNotificationNumbers) && (
          <Column
            field="arrivalNotificationNumbers"
            header={t('productsSummaryarrivalNotificationNumber')}
            body={arrivalNotificationNumberTemplate}
          />
        )}
      </DataTable>
    </>
  );
};

export default ProductsSummary;
