export const getOrderNumber = (id, fieldsOrder) => {
  if (!fieldsOrder) {
    return 0;
  }

  let highestValue = 0;
  fieldsOrder.forEach((field) => {
    if (field.value > highestValue) {
      highestValue = field.value;
    }
  });

  return fieldsOrder.find((field) => field.id === id)?.value || +highestValue + 1;
};
