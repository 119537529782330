import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useCallback, useEffect, useState } from 'react';
import { AxiosConfigContainer } from '../components/AxiosConfig';
import LanguageSelectorUnauthorized from '../components/_shared/LanguageSelectorUnauthorized';
import { useTranslation } from 'react-i18next';
import installationService from '../services/ScaleoApiServices/InstallationService';
import { Message } from 'primereact/message';
import { Checkbox } from 'primereact/checkbox';
import { AxiosResponse } from 'axios';

interface VerifyLicenseKeyProps {
  verifyOrUpdateKey: (key: string) => Promise<AxiosResponse>;
  setIsSuccess: ((b: boolean) => void) | null;
}

export const VerifyLicenseKey = ({ verifyOrUpdateKey, setIsSuccess }: VerifyLicenseKeyProps) => {
  const { t } = useTranslation('verifyLicenseKeyPage');

  const [key, setKey] = useState<string | null>(null);
  const [keyError, setKeyError] = useState<string | null>(null);
  const [deviceKey, setDeviceKey] = useState<string | null>(null);
  const [isShowedDeviceKey, setIsShowedDeviceKey] = useState(false);
  const [isCopiedDeviceKey, setIsCopiedDeviceKey] = useState(false);

  const fetchDeviceKey = useCallback(() => {
    installationService.getDeviceKey().then((response) => {
      setDeviceKey(response.data);
    });
  }, []);

  const handleClickCopy = async (text: string, setIsCopied: (b: boolean) => void) => {
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  useEffect(() => {
    fetchDeviceKey();
  }, [fetchDeviceKey]);

  const onSubmit = useCallback(() => {
    key &&
      verifyOrUpdateKey(key)
        .then((response) => {
          if (response.status === 201) {
            setIsSuccess ? setIsSuccess(true) : window.location.replace('/');
          }
        })
        .catch((e) => {
          window.alert(e.response.data.error);
          setKeyError(e.response.data.error);
        });
  }, [key, setKey, setKeyError]);

  return (
    <>
      <AxiosConfigContainer />
      <div className={setIsSuccess ? '' : 'login-body'}>
        <div className="card login-panel p-fluid">
          <LanguageSelectorUnauthorized visible={false} />
          <div className="login-panel-content">
            <div className="grid">
              <div className="col-12 key">
                <label>{t('header')}</label>
                <div className="key-input mb-2">
                  <InputText type="key" onChange={(e: any) => setKey(e.target.value)} />
                </div>
                {keyError && (
                  <div className="col invalid-login-or-password-container mt--2vh ml--1vw">
                    <Message severity="error" text={t('invalidLicenseKeyError')} />
                  </div>
                )}
              </div>
            </div>
            <div className="col-6 ml--1vw mt--4vh">
              <Button label={t('verifyKey')} icon="pi pi-check" onClick={onSubmit} />
            </div>
            <div className="grid mt-5vh">
              <span className="field-checkbox md:col-12">
                <Checkbox
                  id="showApiKey"
                  checked={isShowedDeviceKey}
                  onChange={() => setIsShowedDeviceKey((prev) => !prev)}
                  disabled={!deviceKey}
                />
                <label htmlFor="showApiKey" className="checkbox-label ml-0.5vw">
                  {t('showInstallationKey')}
                </label>
              </span>
              {deviceKey && isShowedDeviceKey && (
                <div className="col-12 key mt--5vh">
                  <label>{t('deviceKey')}</label>
                  <div className="login-input mb-2">
                    <InputText type="key" value={deviceKey} />
                    {isCopiedDeviceKey && <Message className="mt-0.1vw h-1.9vw mb--2vw" text={t('dataCopied')} />}
                    <Button
                      className="w-8vw mt-2.1vw"
                      onClick={() => handleClickCopy(deviceKey, setIsCopiedDeviceKey)}
                      disabled={isCopiedDeviceKey}
                    >
                      {t('copy')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
