import ReactCountryFlag from 'react-country-flag';

const countryOptionTemplate = (option) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <ReactCountryFlag
      svg
      countryCode={option.value.slice(3, 5)}
      aria-label={option.value}
      className="font-size-30px line-height-30px"
    />
    <div className="ml-8px">{option.label}</div>
  </div>
);

const selectedCountryTemplate = (option) => {
  if (option) {
    return (
      <ReactCountryFlag
        svg
        countryCode={option.value.slice(3, 5)}
        aria-label={option.value}
        className="font-size-30px line-height-30px"
      />
    );
  }

  return <></>;
};

export { countryOptionTemplate, selectedCountryTemplate };
