import { InputText } from 'primereact/inputtext';
import { DictionaryAdditionalFieldAutocomplete } from '../components/_shared/DictionaryAdditionalFieldAutocomplete';
import FormErrorMessage from '../components/_shared/FormErrorMessage';
import { TooltipInfo } from '../components/_shared/TooltipInfo';
import dictionariesService from '../services/ScaleoApiServices/DictionariesService';
import weighingConfigurationService from '../services/ScaleoApiServices/WeighingConfigurationService';

const filterByActive = (fields) => fields.filter((el) => !!el.isActive);

const getAllProductIds = (weighing) => {
  const productsSummary = weighing.productsSummary;
  if (!!productsSummary?.length) {
    return (
      Array.isArray(productsSummary) ? productsSummary.map(({ product }) => product?.id) : [productsSummary?.id]
    ).filter((id) => id != null);
  }
  const measurements = weighing.measurements;
  return (Array.isArray(measurements) ? measurements.map(({ product }) => product?.id) : []).filter((id) => id != null);
};

const getMeasurementProductId = (measurement) => {
  return measurement?.product?.id;
};

const getDefaultValue = (fieldConfig, currentProductId) => {
  if (fieldConfig && fieldConfig.type !== 'FORMULA') {
    const specificDefaultValue = fieldConfig.specificDefaultValues?.find(
      (el) => el.product.id === currentProductId,
    )?.value;
    const baseDefaultValue = fieldConfig.baseDefaultValue;
    if (specificDefaultValue || specificDefaultValue === '') return specificDefaultValue;
    if (baseDefaultValue || baseDefaultValue === '') return baseDefaultValue;
  }
  return null;
};

const mergeAdditionalFields = ({
  actualAdditionalFields,
  configAdditionalFields,
  productIds,
  disableDefaultValues,
}) => {
  const filterFields = () => {
    if (configAdditionalFields) {
      return configAdditionalFields.filter((f) => {
        const keys = Object.keys(actualAdditionalFields || {});
        if (keys.includes(f.id)) {
          f.value = actualAdditionalFields[f.id];
          return (
            keys.includes(f.id) &&
            (f.productIds.length === 0 || f.productIds.findIndex((product_id) => productIds.includes(product_id)) > -1)
          );
        }
        return false;
      });
    } else {
      return [];
    }
  };

  const filterFieldsConfig = () => {
    if (configAdditionalFields) {
      return configAdditionalFields.filter(
        (f) => !Object.keys(actualAdditionalFields || {}).includes(f.id) && f.isActive,
      );
    } else {
      return [];
    }
  };

  const prepareFieldsValues = (_fields) => {
    return _fields.map((el) => {
      const isDictionaryType = el.type === 'DICTIONARY';
      const isValueKey = Object.keys(el).includes('value');
      const isValueDefined = typeof el.value !== 'undefined';
      const isValue = !!el.value;
      const valueIsEmptyString = el.value === '';
      const isDisabledDefaultValue = !!disableDefaultValues;

      if (isValue || valueIsEmptyString || isDisabledDefaultValue) {
        return el;
      } else if (isDictionaryType && isValueKey && !isValueDefined) {
        return { ...el, value: '' };
      } else {
        return {
          ...el,
          value: getDefaultValue(
            (configAdditionalFields || []).find(({ name }) => name === el.name),
            productIds[0],
          ),
        };
      }
    });
  };

  const sortFields = (fields) =>
    fields.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

  const filteredFieldsConfig = filterFieldsConfig();
  const filteredFields = filterFields();
  const sortedMergedFields = sortFields([...filteredFieldsConfig, ...filteredFields]);

  return prepareFieldsValues(sortedMergedFields);
};

export const fetchAdditionalFieldsConfigForMeasurement = async (filters) => {
  const response = await weighingConfigurationService.searchAdditionalFields(
    { destination: 'MEASUREMENT', ...filters },
    { show_loader: false },
  );
  return response.data || [];
};

const getAdditionalFieldsForMeasurement = async (productId, additionalFields, weighingId, weighingMode) => {
  const filters = { productIds: productId ? [productId] : [], weighingMode };

  const getForNewWeighing = async () => {
    filters['isActive'] = true;
    const configAdditionalFields = await fetchAdditionalFieldsConfigForMeasurement(filters);

    const mergeParameters = {
      actualAdditionalFields: {},
      configAdditionalFields,
      productIds: [productId],
      disableDefaultValues: false,
    };
    const mergedFields = mergeAdditionalFields(mergeParameters);
    const filteredFields = filterByActive(mergedFields);

    return [...filteredFields];
  };

  const getForExistingWeighing = async () => {
    const configAdditionalFields = await fetchAdditionalFieldsConfigForMeasurement(filters);

    const mergeParameters = {
      actualAdditionalFields: additionalFields,
      configAdditionalFields,
      productIds: [productId],
      disableDefaultValues: true,
    };
    const mergedFields = mergeAdditionalFields(mergeParameters);
    const filteredFields = filterByActive(mergedFields);

    return [...filteredFields];
  };

  if (!weighingId) {
    return getForNewWeighing();
  } else {
    return getForExistingWeighing();
  }
};

export const fetchAdditionalFieldsConfigForWeighing = async (filters) => {
  const response = await weighingConfigurationService.searchAdditionalFields(
    { destination: 'WEIGHING', ...filters },
    { show_loader: false },
  );
  return response.data || [];
};

const getAdditionalFieldsForWeighings = async (productIds, additionalFields, weighingId, weighingMode) => {
  const filters = { productIds, weighingMode };

  const getForNewWeighing = async () => {
    filters['isActive'] = true;
    const configAdditionalFields = await fetchAdditionalFieldsConfigForWeighing(filters);

    const mergeParameters = {
      actualAdditionalFields: {},
      configAdditionalFields,
      productIds,
      disableDefaultValues: false,
    };
    const mergedFields = mergeAdditionalFields(mergeParameters);
    const filteredFields = filterByActive(mergedFields);

    return [...filteredFields];
  };

  const getForExistingWeighing = async () => {
    const configAdditionalFields = await fetchAdditionalFieldsConfigForWeighing(filters);

    const mergeParameters = {
      actualAdditionalFields: additionalFields,
      configAdditionalFields,
      productIds,
      disableDefaultValues: true,
    };

    const mergedFields = mergeAdditionalFields(mergeParameters);
    const filteredFields = filterByActive(mergedFields);

    return [...filteredFields];
  };

  if (!weighingId) {
    return getForNewWeighing();
  } else {
    return getForExistingWeighing();
  }
};

export const fetchWeighingAdditionalFields = async (weighing) => {
  const weighingId = weighing.id;
  const weighingMode = weighing.weighingMode;
  const additionalFields = weighing.additionalFields;
  const productIds = getAllProductIds(weighing);

  const result = await getAdditionalFieldsForWeighings(productIds, additionalFields, weighingId, weighingMode);

  return [...result];
};

export const fetchMeasurementAdditionalFields = async (weighing, measurement, specificProductId) => {
  if (!measurement || measurement.isTare) {
    return [];
  } else {
    const weighingId = weighing.id;
    const weighingMode = weighing.weighingMode;
    const additionalFields = measurement.additionalFields;

    const productId = specificProductId || getMeasurementProductId(measurement);
    const result = await getAdditionalFieldsForMeasurement(productId, additionalFields, weighingId, weighingMode);
    return [...result];
  }
};

export const validateAdditionalField = (field, id, errors, t) => {
  if (!field.isOptional && !field.value) {
    errors[id] = t('errors1');
  } else if (
    field.type === 'NUMBER' &&
    field.min &&
    field.min !== '' &&
    field.value !== '' &&
    +field.value < +field.min
  ) {
    errors[id] = t('errors2', { min: field.min });
  } else if (
    field.type === 'NUMBER' &&
    field.max &&
    field.max !== '' &&
    field.value !== '' &&
    +field.value > +field.max
  ) {
    errors[id] = t('errors3', { max: field.max });
  }
};

export const renderAdditionalField = (
  field,
  index,
  measurement,
  dictionariesData,
  localTouched,
  setLocalTouched,
  formikInstance,
  t,
) => {
  const identifier = `measurement-${index + 1}-additionalFields-${index}`;

  const handleChange = (value) => {
    const newMeasurement = JSON.parse(JSON.stringify(measurement));
    newMeasurement.additionalFields[index].value = value;

    const i = formikInstance.values.measurements.findIndex((el) => el.id === measurement.id);
    const newMeasurements = JSON.parse(JSON.stringify([...formikInstance.values.measurements]));
    newMeasurements[i] = newMeasurement;

    formikInstance.setFieldValue('measurements', newMeasurements);

    const newLocalTouched = localTouched;
    newLocalTouched[identifier] = true;
    setLocalTouched(newLocalTouched);
  };

  const getValue = measurement.additionalFields[index]?.value || '';

  const renderDictionaryType = () => {
    const getDictionaryTypeValue = (items) => {
      return measurement.additionalFields
        ? items?.find((el) => el === measurement.additionalFields[index]?.value) || null
        : null;
    };

    const sourceDictionary = dictionariesData.find((d) => d.id === field.dictionaryId);

    let source = [];

    if (sourceDictionary) {
      source = sourceDictionary.values;
    } else if (getDictionaryTypeValue(source)) {
      source.push(getDictionaryTypeValue(source));
    }

    return (
      <DictionaryAdditionalFieldAutocomplete
        id={identifier}
        value={getDictionaryTypeValue(source)}
        onChange={handleChange}
        source={source}
      />
    );
  };
  const renderNumberType = () => (
    <InputText id={identifier} type="number" value={getValue} onChange={(e) => handleChange(e.target.value)} />
  );

  const renderTextType = () => (
    <InputText id={identifier} value={getValue} onChange={(e) => handleChange(e.target.value)} />
  );

  const renderFormulaType = () => <InputText id={identifier} value={getValue} disabled />;

  const METHODS = {
    DICTIONARY: renderDictionaryType,
    NUMBER: renderNumberType,
    TEXT: renderTextType,
    FORMULA: renderFormulaType,
  };

  if (!!METHODS[field.type]) {
    const showUnit = !!field.unit;
    const isFormulaType = field.type === 'FORMULA';
    const showRequiredStar = !field.isOptional;

    return (
      <>
        <label htmlFor={field.name}>
          {field.name}
          {showUnit && (
            <>
              &nbsp;<small>{`[${field.unit}]`}</small>
            </>
          )}
          {isFormulaType && (
            <>
              &nbsp;
              <TooltipInfo _key={identifier} text={t('tooltip')} />
            </>
          )}
          {showRequiredStar && (
            <span className="text-red-500">
              <small>*</small>
            </span>
          )}
        </label>
        {METHODS[field.type]()}
        {!isFormulaType && (
          <FormErrorMessage
            fieldName={identifier}
            formikInstance={formikInstance}
            withTouchedTrue={!!formikInstance.submitCount || !!localTouched[identifier]}
          />
        )}
      </>
    );
  }

  return <></>;
};

export const fetchDictionaries = async () => {
  const dictionariesResponse = await dictionariesService.getDictionaries({ show_loader: false });
  return dictionariesResponse.data;
};

export const getArraysCommonElements = (arr1, arr2) => {
  const set2 = new Set(arr2);
  return arr1.filter((item) => set2.has(item));
};
