import axios from 'axios';
class BillingDataService {
  async editBillingData(postData, companyId) {
    return axios.put(`/billing-data/${companyId}`, {
      data: postData,
    });
  }
}
const billingDataService = new BillingDataService();
export default billingDataService;
