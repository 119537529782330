import axios from 'axios';

class ImportExportService {
  async getDatabaseCSV() {
    return axios.get(`/import-export/db/csv`);
  }
  async insertDatabaseCSV(postData) {
    return axios.post(`/import-export/db/csv`, {
      data: postData,
    });
  }
  async getConfigurationCSV() {
    return axios.get(`/import-export/configuration/csv`);
  }
  async insertConfigurationCSV(postData) {
    return axios.post(`/import-export/configuration/csv`, {
      data: postData,
    });
  }
}

const importExportService = new ImportExportService();
export default importExportService;
