import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import locationsService from '../../../../services/ScaleoApiServices/LocationsService';
import '../../../DataTable.css';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../../store/user-slice';
import { useTranslation, Trans } from 'react-i18next';
import { Location } from '../../../../types/location';

interface DeleteLocationsDialogProps {
  visible: boolean;
  closeDialog: () => void;
  selectedRows: Location[];
}

const DeleteLocationsDialog = ({ visible, closeDialog, selectedRows }: DeleteLocationsDialogProps) => {
  const { t } = useTranslation('managementLocations');

  const dispatch = useDispatch();

  const handleCloseDialog = () => {
    closeDialog();
  };

  const handleConfirm = async () => {
    await Promise.all(selectedRows.map((item: Location) => locationsService.removeLocation(item.id))).then(() => {
      dispatch(userActions.shouldRefreshLoggedUserContext(true));
      handleCloseDialog();
    });
  };

  const dialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={handleCloseDialog} />
      <Button type="button" label={t('deleteButton')} icon="pi pi-check" text onClick={handleConfirm} />
    </>
  );

  return (
    <Dialog
      visible={visible}
      breakpoints={{ '896px': '90vw' }}
      header={
        <span className="flex align-items-center">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
          {t('confirm')}
        </span>
      }
      modal
      className="p-fluid min-width-450px"
      footer={dialogFooter}
      onHide={handleCloseDialog}
    >
      {selectedRows ? (
        <div className="confirmation-content">
          {selectedRows.length > 1 ? (
            <span>{t('content')}</span>
          ) : (
            <span>
              <Trans t={t} i18nKey="contentDetail" values={{ location: selectedRows[0]?.name }} />
            </span>
          )}
        </div>
      ) : (
        ''
      )}
    </Dialog>
  );
};

export default DeleteLocationsDialog;
