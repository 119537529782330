import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import apiKeysService from '../../services/ScaleoApiServices/ApiKeyService';

export const useApiKeyForm = (
  item: DialogApiKey | null,
  fetchData: () => Promise<void>,
  closeDialog: (a?: any) => void,
) => {
  const { t } = useTranslation('managementApiKey');

  const validateMethod = (data: DialogApiKey) => {
    const errors: Partial<DialogApiKey> = {};

    if (!data.name) {
      errors.name = t('noNameError');
    }

    if (data.scopes.length === 0) {
      errors.scopes = t('noScopesError');
    }

    return errors;
  };

  const handleSubmit = (data: DialogApiKey, helpers: any) => {
    const identifier = 'ClientId' in data ? data.ClientId : data?.id;
    if (identifier) {
      apiKeysService
        .updateApiKey(identifier, data!.name!, data.scopes, data.contractorIds)
        .then(() => {
          closeDialog();
          void fetchData();
        })
        .catch(() => {
          helpers.setFieldError('name', t('apiKeyNameExistError'));
        });
    } else {
      apiKeysService
        .generateApiKey(data!.name!, data.scopes, data.contractorIds)
        .then(() => {
          closeDialog();
          void fetchData();
        })
        .catch(() => {
          helpers.setFieldError('name', t('apiKeyNameExistError'));
        });
    }
  };

  const formik = useFormik({
    initialValues: item ?? INIT_FORM_STATE,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const selectScopesHandler = (e: any) => {
    const newScopes: string[] = e.value ?? e.target.value ?? [];

    void formik.setFieldValue('scopes', newScopes);
    if (!newScopes?.some((scope) => scope === 'weighings/read')) {
      void formik.setFieldValue('contractorId', null);
    }
  };

  const selectContractorsHandler = (e: any) => {
    formik.setFieldValue('contractorIds', e.value ?? e.target.value ?? []);
  };

  return {
    formik,
    selectScopesHandler,
    selectContractorsHandler,
  };
};

const INIT_FORM_STATE = {
  name: null,
  ClientId: null,
  contractorIds: [],
  scopes: [],
};

type DialogApiKey =
  | {
      name: string | null;
      ClientId: string | null;
      contractorIds: string[];
      scopes: string[];
    }
  | {
      name: string | null;
      id: string | null;
      contractorIds: string[];
      scopes: string[];
    };
