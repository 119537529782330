import { useState } from 'react';
import trailersService from '../../services/ScaleoApiServices/TrailersService';
import { Trailer } from '../../types/trailer';

export const useTrailerDialogs = (fetchTrailers: () => Promise<void>) => {
  const [selectedTrailers, setSelectedTrailers] = useState<Trailer[]>([]);
  const [trailer, setTrailer] = useState<Trailer>(emptyTrailer);
  const [trailerDialogVisible, setTrailerDialogVisible] = useState(false);
  const [deleteTrailerDialogVisible, setDeleteTrailerDialogVisible] = useState(false);
  const [deleteTrailersDialogVisible, setDeleteTrailersDialogVisible] = useState(false);

  const openNew = () => {
    setTrailer(emptyTrailer);
    setTrailerDialogVisible(true);
  };

  const editTrailer = async (_trailer: Trailer) => {
    setTrailer({ ..._trailer });
    setTrailerDialogVisible(true);
  };

  const hideDeleteTrailerDialog = () => {
    setDeleteTrailerDialogVisible(false);
  };

  const confirmDeleteSelected = () => {
    setDeleteTrailersDialogVisible(true);
  };

  const hideDeleteTrailersDialog = () => {
    setDeleteTrailersDialogVisible(false);
  };

  const deleteTrailer = () => {
    void trailersService.removeManyTrailers([trailer]).then(() => {
      void fetchTrailers();
    });
    setDeleteTrailerDialogVisible(false);
    setTrailer(emptyTrailer);
  };

  const deleteSelectedTrailers = () => {
    void trailersService.removeManyTrailers(selectedTrailers).then(() => {
      void fetchTrailers();
    });

    setDeleteTrailersDialogVisible(false);
    setSelectedTrailers([]);
  };

  return {
    setSelectedTrailers,
    openNew,
    confirmDeleteSelected,
    hideDeleteTrailerDialog,
    deleteTrailer,
    deleteSelectedTrailers,
    editTrailer,
    hideDeleteTrailersDialog,
    setTrailer,
    setDeleteTrailerDialogVisible,
    setTrailerDialogVisible,
    trailerDialogVisible,
    deleteTrailerDialogVisible,
    deleteTrailersDialogVisible,
    trailer,
    selectedTrailers,
  };
};

const emptyTrailer = {
  id: null,
  registrationNumber: '',
} as Trailer;
