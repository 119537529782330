import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { useSelector } from 'react-redux';
import FormErrorMessageScroller from '../../../../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';

const INIT_FORM_STATE = {
  alibi: '',
  manualIndication: '',
};

const TestWeighingDialog = ({ visible, onTestWeighingSaved, onHide }) => {
  const { t } = useTranslation('weighingHandlingWeighings');
  const scale = useSelector((state) => state.weighing.selectedScale);

  const validateMethod = (data) => {
    const errors = {};
    if (!(data.manualIndication > 0)) {
      errors.manualIndication = t('testWeighingDialogValidationIncorrectValue');
    }
    return errors;
  };

  const handleSubmit = (data) => {
    onTestWeighingSaved(data.manualIndication, data.alibi);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: INIT_FORM_STATE,
    validate: validateMethod,
    onSubmit: handleSubmit,
  });

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={visible}
        header={t('testWeighingDialogTitle')}
        modal
        className="p-fluid w-450px"
        footer={
          <Button
            type="button"
            label={t('testWeighingDialogSaveButton')}
            icon="pi pi-check"
            text
            onClick={formik.submitForm}
          />
        }
        onHide={onHide}
      >
        <form>
          <div className="field col-5">
            <label htmlFor="manualInducation">{`${t('testWeighingDialogManualInducation')} [${
              scale?.weighingUnit
            }]`}</label>
            <InputText
              type="number"
              id="manualIndication"
              autoFocus
              value={formik.values.manualIndication || ''}
              onChange={formik.handleChange}
            />
            <br />
            <label htmlFor="alibi">{t('testWeighingDialogAlibi')}</label>
            <InputText type="number" id="alibi" value={formik.values.alibi} onChange={formik.handleChange} />
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};

export default TestWeighingDialog;
