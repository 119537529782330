import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableExpandedRows } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import weighingsService from '../../../../../services/ScaleoApiServices/WeighingsService';
import { weighingActions } from '../../../../../store/weighing-slice';
import useModulesConfiguration from '../../../../../utils/useModulesConfiguration';
import MeasurementsList from '../../../components/MeasurementsList';
import { dropdownOptionTemplate, columnBodyTemplate } from '../../../components/Templates';
import WeighingDialog from '../../../WeighingsList/components/WeighingDialog/WeighingDialog';
import { InputSwitch } from 'primereact/inputswitch';
import { Panel } from 'primereact/panel';
import ListLegend from './components/ListLegend';
import '../../../../DataTable.css';
import { Ripple } from 'primereact/ripple';
import { formatDecimal } from '../../../../../utils/formatUtils';
import { CustomButton } from '../../../../_shared/CustomComponents';
import { hasPermissions } from '../../../../../utils/permissionsUtils';
import { useTranslation, Trans } from 'react-i18next';
import { isModulesEnabled } from '../../../../../utils/modulesUtils';
import ClipLoader from 'react-spinners/ClipLoader';
import { Checkbox } from 'primereact/checkbox';
import { ReducerState } from '../../../../../types/reducer-state';
import SnapshotsDialog from '../../../../SnapshotDialog/SnapshotDialog';
import { SNAPSHOT_DIALOG_TYPE } from '../../../../../enums/snapshot-dialog.enum';
import { serviceToCall, downloadWeighingImages } from '../../../WeighingsList/components/helpers';
import { Weighing } from '../../../../../types/weighing';

const ImprovedColumn = Column as any;

interface OpenWeighingsListProps {
  onWeighingCloseRequested: (a: any) => void;
  optionsData: any;
  openOrToConfirmWeighings: any[];
  handleClickRow: any;
  fetchComponentData: any;
  setShowDeletedWeighings: (b: boolean) => void;
  isCorrectBdoModuleConfig: boolean;
  showDeletedWeighings: boolean;
  isNotConfirmedList?: boolean;
  weighingConfiguration: any;
  setBlockReloadingWeighings: (b: boolean) => void;
  setCloseWeighing: (w: Weighing) => void;
}

const OpenOrNotConfirmedWeighingsList = ({
  onWeighingCloseRequested,
  optionsData,
  openOrToConfirmWeighings,
  handleClickRow,
  fetchComponentData,
  isCorrectBdoModuleConfig,
  setShowDeletedWeighings,
  showDeletedWeighings,
  isNotConfirmedList,
  weighingConfiguration,
  setBlockReloadingWeighings,
  setCloseWeighing,
}: OpenWeighingsListProps) => {
  const { t } = useTranslation('weighingHandlingWeighings');

  const dispatch = useDispatch();

  const modulesConfiguration = useModulesConfiguration();
  const { weighingState, allOpenWeighings } = useSelector((state: ReducerState) => state.weighing);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const unit = useSelector((state: ReducerState) => state.appConfig.unit);
  const [isOpenSnapshotsDialog, setIsOpenSnapshotsDialog] = useState(false);
  const [clickedRowId, setClickedRowId] = useState<number | null>(null);

  const [expandedRows, setExpandedRows] = useState<any[] | DataTableExpandedRows>([]);

  const [weighing, setWeighing] = useState<any | null>(null);
  const [selectedWeighings, setSelectedWeighings] = useState<Weighing[]>([]);
  const [combineWeighing, setCombineWeighing] = useState<any>(null);
  const [deleteWeighingDialogVisible, setDeleteWeighingDialogVisible] = useState(false);
  const [restoreWeighingDialogVisible, setRestoreWeighingDialogVisible] = useState(false);
  const [weighingDialogVisible, setWeighingDialogVisible] = useState(false);
  const [selectedWeighingsDialogVisible, setSelectedWeighingsDialogVisible] = useState(false);

  const canMergeWeighings = selectedWeighings?.length === 2 && selectedWeighings?.every((w) => !w.deleted);
  const dt = useRef(null);

  const confirmDeleteWeighing = (_weighing: any) => {
    setWeighing(_weighing);
    setDeleteWeighingDialogVisible(true);
  };

  const confirmRestoreWeighing = (_weighing: any) => {
    setWeighing(_weighing);
    setRestoreWeighingDialogVisible(true);
  };

  const hideDeleteWeighingDialog = () => {
    setDeleteWeighingDialogVisible(false);
  };

  const hideRestoreWeighingDialog = () => {
    setRestoreWeighingDialogVisible(false);
  };

  const hideDialog = () => {
    setWeighingDialogVisible(false);
    setWeighing(null);
    setCombineWeighing(null);
    setSelectedWeighings([]);
  };

  const deleteWeighing = () => {
    setSelectedWeighings((prev) => prev?.filter((v) => weighing.id !== v.id) ?? null);
    weighingsService.removeWeighing(weighing.id).then(() => {
      if (weighing.id !== weighingState?.openWeighing?.id) {
        fetchComponentData();
      } else {
        dispatch(weighingActions.weighingClosed());
      }
    });

    setDeleteWeighingDialogVisible(false);
    setWeighing(null);
  };

  const restoreWeighing = () => {
    weighingsService.restoreWeighing(weighing.id).then(() => {
      fetchComponentData();
    });

    setRestoreWeighingDialogVisible(false);
    setWeighing(null);
  };

  const handleSelectionChange = (e: any) => {
    const getLastSelectedItem = () => {
      let result = null;
      if (e.value.length === 1) {
        result = e.value[0];
      } else if (e.value.length > 1) {
        e.value.forEach((item: any) => {
          if (!selectedWeighings?.map((el) => el?.id).includes(item.id)) {
            result = item;
          }
        });
      }
      return result;
    };

    if (e.value.length > 2) {
      setSelectedWeighingsDialogVisible(true);
    }

    if (e.value.length) {
      const lastSelectedItem = getLastSelectedItem();

      if (lastSelectedItem?.measurements.length === 1) {
        const selected = [] as any[];
        e.value.forEach((item: any) => {
          if (selected.length < 2) {
            selected.push(item);
          }
        });

        selected?.sort(
          (weighing1, weighing2) => weighing1.measurements[0].timestamp - weighing2.measurements[0].timestamp,
        );
        setSelectedWeighings(selected);
      }
    } else {
      setSelectedWeighings([]);
    }
  };

  const weighingRowExpansionTemplate = (_weighing: Weighing) => {
    return (
      <>
        <strong>{t('openWeighingsListRowTitle')}</strong>
        <MeasurementsList measurements={_weighing.measurements} columnEmptyValue="--" showAdditionalFields={false} />
      </>
    );
  };

  const actionBodyTemplate = (rowData: any) => {
    const options = [];

    if (!!rowData.deleted) {
      hasPermissions(['WEIGHINGS_RESTORE'], loggedUserContext.permissions) &&
        options.push({ label: t('openWeighingsListOptionsRestore'), value: 'restore', icon: 'pi pi-replay' });
    } else {
      if (!rowData.automatic && !isNotConfirmedList) {
        hasPermissions(['WEIGHING'], loggedUserContext.permissions) &&
          options.push({ label: t('openWeighingsListOptionsContinue'), value: 'continue', icon: 'pi pi-caret-right' });
      }
      !isNotConfirmedList &&
        hasPermissions(['WEIGHING'], loggedUserContext.permissions) &&
        options.push({
          label: t('openWeighingsListOptionsFinish'),
          value: 'finishIncomplete',
          icon: 'pi pi-step-forward',
        });
      hasPermissions(['WEIGHINGS_EDIT'], loggedUserContext.permissions) &&
        options.push({ label: t('openWeighingsListOptionsEdit'), value: 'editWeighing', icon: 'pi pi-pencil' });
      hasPermissions(['WEIGHINGS_DELETE'], loggedUserContext.permissions) &&
        options.push({ label: t('openWeighingsListOptionsDelete'), value: 'delete', icon: 'pi pi-trash' });
      isNotConfirmedList &&
        hasPermissions(['WEIGHINGS_CONFIRMATION'], loggedUserContext.permissions) &&
        options.push({ label: t('actionsConfirm'), value: 'confirm', icon: 'pi pi-check' });
    }

    const handleChangeOption = (e: any) => {
      switch (e.value) {
        case 'continue':
          dispatch(weighingActions.updateFormState({ selectedVehicle: rowData.vehicle }));
          break;
        case 'finishIncomplete':
          setWeighing(rowData);
          setCloseWeighing(rowData);
          onWeighingCloseRequested(rowData.id);
          break;
        case 'editWeighing':
          setWeighing({ ...rowData });
          setWeighingDialogVisible(true);
          break;
        case 'delete':
          confirmDeleteWeighing(rowData);
          break;
        case 'restore':
          confirmRestoreWeighing(rowData);
          break;
        case 'confirm':
          handleClickRow({
            isManualClick: true,
            data: {
              id: rowData.id,
            },
          });
          break;

        default:
          break;
      }
    };

    return (
      <div className="actions">
        {!!options.length && (
          <Dropdown
            options={options}
            onChange={handleChangeOption}
            placeholder={t('openWeighingsListOptionsPlaceholder')}
            itemTemplate={dropdownOptionTemplate}
          />
        )}
      </div>
    );
  };

  const deleteWeighingDialogFooter = (
    <>
      <Button type="button" label={t('no')} icon="pi pi-times" text onClick={hideDeleteWeighingDialog} />
      <Button type="button" label={t('yes')} icon="pi pi-check" text onClick={deleteWeighing} />
    </>
  );

  const restoreWeighingDialogFooter = (
    <>
      <Button type="button" label={t('no')} icon="pi pi-times" text onClick={hideRestoreWeighingDialog} />
      <Button type="button" label={t('yes')} icon="pi pi-check" text onClick={restoreWeighing} />
    </>
  );

  const handleCombineWeighings = () => {
    const measurements = selectedWeighings?.map((selectedWeighing, index) => ({
      ...selectedWeighing.measurements[0],
      index: index + 1,
      isTare: index === 0,
    }));

    const mapWeighingData = (weighing: any) => ({
      additionalFields: weighing.additionalFields,
      automatic: weighing.automatic,
      closed: weighing.closed,
      contractor: weighing.contractor,
      driver: weighing.driver,
      id: weighing.id,
      lastSessionId: weighing.lastSessionId,
      location: weighing.location,
      number: weighing.number,
      productId: weighing.productId,
      vehicle: weighing.vehicle,
      trailer: weighing.trailer,
      productsSummary: [
        {
          index: 1,
          measurement1: { ...measurements?.[0], index: 1 },
          measurement2: { ...measurements?.[1], index: 2 },
          netto: Math.abs(measurements?.[1].indication - measurements?.[0].indication),
          product: weighing.measurements[0].product,
        },
      ],
    });
    setCombineWeighing({
      measurements,
      weighingMode: 'DOUBLE',
      tare: 0,
      ...mapWeighingData(selectedWeighings?.[0]),
      weighingsData: [mapWeighingData(selectedWeighings?.[0]), mapWeighingData(selectedWeighings?.[1])],
    });
    setWeighingDialogVisible(true);
  };

  const headerTemplate = (options: any) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    const toggleString = options.collapsed
      ? t('openWeighingsListHeaderMore')
      : ` ${t('openWeighingsListHeaderLess')} \xa0`;

    return (
      <div>
        <div className="grid m-0 p-0">
          <div className="col-7">
            <h4 className="m-0 p-0">{!isNotConfirmedList && <b>{t('openWeighingsListTitle')}</b>}</h4>
          </div>
          <div className="col-5 flex align-items-center justify-content-end">
            {!isNotConfirmedList && (
              <CustomButton
                type="button"
                label={t('openWeighingsListCombine')}
                disabled={!canMergeWeighings}
                icon="pi pi-sitemap"
                severity="help"
                className="mr-2"
                onClick={handleCombineWeighings}
                tooltip={(!canMergeWeighings && t('openWeighingsListSelectToCombine')) as string}
                name={undefined}
                dataFlag={undefined}
              />
            )}
            <button
              type="button"
              className={`${options.togglerClassName} ml-4 w-62px`}
              onClick={options.onTogglerClick}
            >
              {toggleString}&#160;&#160;
              <span className={toggleIcon}></span>
              <Ripple />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const header = (
    <Panel headerTemplate={headerTemplate} toggleable>
      <div className="grid">
        <div className="col-12 md:col-6">
          <ListLegend />
        </div>
        <div className="col-12 md:col-6 pb-0">
          <div className="flex flex-wrap align-items-center justify-content-end">
            <div className="m-2">
              <div className="flex align-items-center">
                <InputSwitch checked={showDeletedWeighings} onChange={(e) => setShowDeletedWeighings(e.value)} />
                &#160;&#160; {t('openWeighingsListShowDeleted')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  );

  const rowClass = (rowData: any) => {
    return {
      noExpander: rowData.weighingMode === 'TARE' || rowData.weighingMode === 'CONTROL',
      rowAutomatic: rowData.automatic,
      rowDeleted: rowData.deleted === true,
      rowEdited: rowData.updatedAt !== null,
      rowTest: rowData.isTest,
    };
  };

  const cellClass = (_: any, { expander, rowData, canContinue }: any) => {
    if (!rowData) return;

    const isExpanderColumn = !!expander;
    const isClickableContinueColumn = !!canContinue;
    const isDeletedWeighing = rowData?.deleted;
    const hasPermission = hasPermissions(['WEIGHING'], loggedUserContext.permissions);
    const hasVehicle = !!rowData.vehicle?.registrationNumber;
    const isCurrentWeighing = rowData.id === weighingState?.openWeighing?.id;

    return {
      continue:
        !isExpanderColumn &&
        isClickableContinueColumn &&
        !isDeletedWeighing &&
        hasPermission &&
        hasVehicle &&
        !isCurrentWeighing,
    };
  };

  const columnBody = (rowData: any, column: any) => columnBodyTemplate(rowData, { ...column, emptyValue: '--' });

  const firstMeasurementIndicationColumnBody = (rowData: any) => {
    if (rowData.firstMeasurementIndication) {
      return `${formatDecimal(rowData.firstMeasurementIndication)} ${unit}`;
    }
    return '';
  };

  const weighingImageTemplate = (weighing: any) => {
    if (!weighing.thumbnail && new Date().valueOf() - new Date(weighing.createdAt).valueOf() > 15 * 60 * 1000) {
      return <></>;
    }

    return weighing.thumbnail ? (
      <img
        src={weighing.thumbnail}
        alt="loading"
        width="80px"
        onClick={() => handleOpenSnapshots(weighing.id)}
        className="cursor-pointer"
      />
    ) : (
      <ClipLoader color="#0f97c7" size={40} loading />
    );
  };

  const handleOpenSnapshots = (rowDataId: number) => {
    setClickedRowId(rowDataId);
    setBlockReloadingWeighings(true);
    setIsOpenSnapshotsDialog(true);
  };

  const checkBoxBody = (rowData: any) => {
    if (rowData.deleted) {
      return <></>;
    }

    const checked = !!selectedWeighings && !!selectedWeighings?.find((w) => w.id === rowData.id);

    const handleCheckboxClick = () => {
      let resultWeighings;
      if (!selectedWeighings) {
        resultWeighings = [rowData];
      } else if (checked) {
        resultWeighings = selectedWeighings.filter((w) => w.id !== rowData.id);
      } else {
        resultWeighings = [...selectedWeighings, rowData];
      }

      handleSelectionChange({
        value: resultWeighings,
      });
    };

    return <Checkbox checked={checked} onMouseDown={handleCheckboxClick} />;
  };

  return (
    <div className="datatable-responsive">
      <div className="card datatable-card">
        <DataTable
          responsiveLayout="scroll"
          ref={dt}
          value={openOrToConfirmWeighings}
          paginator
          header={header}
          rows={10}
          className="p-datatable-responsive weighing-list"
          sortField="createdAt"
          sortOrder={-1}
          rowExpansionTemplate={weighingRowExpansionTemplate}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowClassName={rowClass}
          cellClassName={cellClass}
          selection={selectedWeighings}
          selectionMode="checkbox"
          onRowClick={handleClickRow}
          emptyMessage={t('noRecordsFound')}
          stripedRows
          filterDisplay="row"
        >
          <Column expander className="w-3rem" />
          <Column body={checkBoxBody} headerStyle={{ width: '3rem' }} />
          <ImprovedColumn
            field="scalesNames"
            header={t('columnNamesScale')}
            body={columnBody}
            fieldsType={'scalesNames'}
            canContinue
          />
          <ImprovedColumn
            field="number"
            header={t('openWeighingsListTableHeadersWeighingNumber')}
            body={columnBody}
            fieldsType={'weighingNumber'}
            sortable
            canContinue
          />
          <ImprovedColumn
            field="transactionType"
            header={t('openWeighingsListTableHeadersWeighingType')}
            body={columnBody}
            fieldsType={'transactionType'}
            sortable
            canContinue
          />
          <ImprovedColumn
            field="firstMeasurementIndication"
            header={t('openWeighingsListTableHeadersFirstMeasurementIndication')}
            body={firstMeasurementIndicationColumnBody}
            sortable
            canContinue
          />
          <ImprovedColumn
            field="vehicle.registrationNumber"
            header={t('openWeighingsListTableHeadersRegistrationNumber')}
            body={columnBody}
            fieldsType={'subfield'}
            sortable
            canContinue
          />
          {weighingConfiguration?.addTrailerRegistrationNumbersSupport && (
            <ImprovedColumn
              field="trailer.registrationNumber"
              header={t('openWeighingsListTableHeadersTrailerRegistrationNumber')}
              body={columnBody}
              fieldsType={'subfield'}
              sortable
              canContinue
            />
          )}
          <ImprovedColumn
            field="contractor.name"
            header={t('openWeighingsListTableHeadersContractor')}
            body={columnBody}
            fieldsType={'subfield'}
            sortable
            canContinue
          />
          <ImprovedColumn
            field="product.name"
            header={t('openWeighingsListTableHeadersProduct')}
            body={columnBody}
            fieldsType={'product'}
            sortable
            canContinue
          />
          {isModulesEnabled(['CAMERAS'], loggedUserContext.currentCustomer?.subscriptionModules) && (
            <Column field="photo" header={t('openWeighingsListTableHeadersPhoto')} body={weighingImageTemplate} />
          )}
          <ImprovedColumn
            key={'createdAt'}
            field={'createdAt'}
            header={t('openWeighingsListTableHeadersCreatedAt')}
            body={columnBody}
            fieldsType={'datetime'}
            sortable
            canContinue
          />
          <Column className="text-left w-150px" body={actionBodyTemplate}></Column>
        </DataTable>
        {isOpenSnapshotsDialog && (
          <SnapshotsDialog
            data={allOpenWeighings}
            currentDataId={clickedRowId!}
            visible={true}
            closeDialog={() => {
              setBlockReloadingWeighings(false);
              setIsOpenSnapshotsDialog(false);
            }}
            visibleText={false}
            type={SNAPSHOT_DIALOG_TYPE.WEIGHINGS}
            serviceToCall={serviceToCall}
            downloadImagesCallback={downloadWeighingImages}
          />
        )}
        <Dialog
          visible={deleteWeighingDialogVisible}
          className="dialog-md"
          header={t('openWeighingsListDeleteDialogConfirm')}
          modal
          footer={deleteWeighingDialogFooter}
          onHide={hideDeleteWeighingDialog}
        >
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
            {weighing && (
              <span>
                <Trans
                  t={t}
                  i18nKey="openWeighingsListDeleteDialogQuestion"
                  values={{ weighingNumber: weighing.number }}
                />
              </span>
            )}
          </div>
        </Dialog>
        <Dialog
          visible={restoreWeighingDialogVisible}
          className="dialog-md"
          header={t('openWeighingsListRestoreDialogConfirm')}
          modal
          footer={restoreWeighingDialogFooter}
          onHide={hideRestoreWeighingDialog}
        >
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
            {weighing && (
              <span>
                <Trans
                  t={t}
                  i18nKey="openWeighingsListRestoreDialogQuestion"
                  values={{ weighingNumber: weighing.number }}
                />
              </span>
            )}
          </div>
        </Dialog>
        <Dialog
          visible={selectedWeighingsDialogVisible}
          className="dialog-md"
          header={t('openWeighingsListSelectedTooMoreWeighings')}
          modal
          onHide={() => setSelectedWeighingsDialogVisible(false)}
        ></Dialog>
        {weighingDialogVisible && (
          <WeighingDialog
            visible={weighingDialogVisible}
            onClose={hideDialog}
            weighing={combineWeighing ? combineWeighing : weighing}
            setWeighing={setCombineWeighing}
            inputVehicles={optionsData.vehicles}
            inputTrailers={optionsData.trailers}
            inputContractors={optionsData.contractors}
            inputDrivers={optionsData.drivers}
            inputScales={optionsData.scales}
            afterSubmitAction={fetchComponentData}
            canEnableBdoPlugin={
              !!selectedWeighings?.length && isCorrectBdoModuleConfig && modulesConfiguration.bdo === 'enabled'
            }
            isCombine={!!combineWeighing}
            kpoWeighings={[]}
            weighingConfiguration={weighingConfiguration}
            containers={optionsData.containers}
          />
        )}
      </div>
    </div>
  );
};

export default OpenOrNotConfirmedWeighingsList;
