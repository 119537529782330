const getFileFromText = (data, mime, downloadFileName, clearText) => {
  const blob = clearText
    ? new Blob([Buffer.from(data)], { type: mime || 'application/octet-stream' })
    : new Blob([Buffer.from(data, 'base64')], { type: mime || 'application/octet-stream' });

  const url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.target = '_blank';
  if (downloadFileName) {
    anchor.download = downloadFileName;
  }
  anchor.click();
};

const getFileFromBuffer = (buffer, mime, downloadFileName) => {
  const blob = new Blob([buffer], { type: mime || 'application/octet-stream' });
  getFileFromBlob(blob, downloadFileName);
};

const getFileFromBlob = (blob, downloadFileName) => {
  const url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.target = '_blank';
  if (downloadFileName) {
    anchor.download = downloadFileName;
  }
  anchor.click();
};

const printFileFromBuffer = (buffer, mime) => {
  const blob = new Blob([buffer], { type: mime || 'application/octet-stream' });
  const url = URL.createObjectURL(blob);

  var iframe;
  var existedIframe = document.querySelector('iframe');
  existedIframe ? (iframe = existedIframe) : (iframe = document.createElement('iframe'));

  document.body.appendChild(iframe);
  iframe.style.display = 'none';
  iframe.src = url;
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus();
      iframe.contentWindow.print();
    }, 1);
  };
};

export { getFileFromText, getFileFromBuffer, getFileFromBlob, printFileFromBuffer };
