import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';

interface DeleteStationsDialogProps {
  deleteStationsDialogVisible: boolean;
  hideDeleteStationsDialog: () => void;
  deleteSelectedStations: () => void;
}

export const DeleteStationsDialog = ({
  deleteStationsDialogVisible,
  hideDeleteStationsDialog,
  deleteSelectedStations,
}: DeleteStationsDialogProps) => {
  const { t } = useTranslation('managementStations');

  const deleteStationsDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteStationsDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteSelectedStations} />
    </>
  );

  return (
    <Dialog
      visible={deleteStationsDialogVisible}
      header={
        <span className="flex align-items-center">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
          {t('confirm')}
        </span>
      }
      modal
      className="p-fluid min-width-450px"
      footer={deleteStationsDialogFooter}
      onHide={hideDeleteStationsDialog}
      breakpoints={{ '896px': '90vw' }}
    >
      <div className="confirmation-content">{<span>{t('deleteStationsContent')}</span>}</div>
    </Dialog>
  );
};
