import { Fieldset } from 'primereact/fieldset';
import { useTranslation } from 'react-i18next';
import { Container } from '../../../../../../types/container';
import { ContainersList } from './ContainersList';
import { WeighingContainer } from '../../../../../../store/types/weighing-slice';
import { useSelector } from 'react-redux';
import { ReducerState } from '../../../../../../types/reducer-state';
import { isModulesEnabled } from '../../../../../../utils/modulesUtils';
import { ContainersSumUp } from './ContainersSumUp';
import { MultiSelect } from 'primereact/multiselect';

interface ContainerFormProps {
  formik: any;
  containers: Container[];
  updateFormState: (a: any) => void;
  width?: string;
}

export const ContainersForm = ({ formik, containers, updateFormState, width }: ContainerFormProps) => {
  const { t } = useTranslation('containersForm');
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  if (!isModulesEnabled(['CONTAINERS'], loggedUserContext.currentCustomer?.subscriptionModules)) {
    return <></>;
  }

  const onContainersChange = (e: any) => {
    const newContainers = e.value
      ?.map((id: string) => {
        const currentContainer = formik.values.containers?.find((c: WeighingContainer) => c.id === id) as Container;
        if (!currentContainer) {
          const newContainer = containers?.find((c) => c.id === id);
          if (!newContainer?.tareValue) {
            return null;
          }
          return {
            ...newContainer,
            receiveNumber: 0,
            returnNumber: 0,
          };
        }
        return currentContainer;
      })
      .filter((c: WeighingContainer) => c);
    formik.setFieldValue('containers', newContainers);
    updateFormState({ containers: newContainers });
  };

  const weighingContainers =
    containers?.map((c) => ({ ...c, nameCode: `${c.name}(${c.code}) ${c.tareValue ? '' : `- ${t('noTare')}`}` })) ?? [];

  return (
    <div className="xl:col-12 lg:col-12 md:col-12 sm:col-12">
      <div className="grid" style={{ width }}>
        <div className="col-12">
          <Fieldset legend={t('containers')}>
            <div className="field grid">
              <label htmlFor="container" className="col-12 sm:col-5 text-2xl">
                {t('chooseContainers')}
              </label>
              <div className="col-12 sm:col-7 relative">
                <MultiSelect
                  value={formik.values.containers?.map((c: WeighingContainer) => c.id) ?? []}
                  id="container"
                  options={weighingContainers}
                  onChange={onContainersChange}
                  optionLabel="nameCode"
                  optionValue="id"
                  placeholder={t('columnsFilterPlaceholder')}
                  emptyFilterMessage={t('columnsFilterNoData')}
                  maxSelectedLabels={0}
                  selectedItemsLabel={`${t('columnsFilterSelectedItems')}: {0}`}
                  showClear
                  filter
                  resetFilterOnHide
                />
              </div>
              <div className="col-12 xl:col-6">
                <ContainersList formik={formik} updateFormState={updateFormState} />
              </div>
              <div className="col-12 xl:col-6">
                <ContainersSumUp formik={formik} />
              </div>
            </div>
          </Fieldset>
        </div>
      </div>
    </div>
  );
};
