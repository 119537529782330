import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { WeighingListData } from '../../../../hooks/WeighingsList/useWeighingsListData';
import { useWeighingsListReceipts } from '../../../../hooks/WeighingsList/useWeighingsListReceipts';
import { weighingActions } from '../../../../store/weighing-slice';
import { NodeType } from '../../../../types/node';
import { Weighing } from '../../../../types/weighing';
import { isModulesEnabled } from '../../../../utils/modulesUtils';
import { hasPermissions, hasOneOfPermissions } from '../../../../utils/permissionsUtils';
import weighingsService from '../../../../services/ScaleoApiServices/WeighingsService';
import { ReducerState } from '../../../../types/reducer-state';

interface ActionBodyTemplateComponentProps {
  rowData: Weighing;
  rowIndex: number;
  handleFetchComponentData: () => Promise<void>;
  componentData: WeighingListData;
  modulesConfiguration: any;
  setWeighing: (w: Weighing | null) => void;
  setWeighingDialogVisible: (b: boolean) => void;
  setWeighingHistoryVisible: (b: boolean) => void;
  setBdoDialogData: (a: any) => void;
  showSendEmailDialog: (w: Weighing[]) => void;
  setDeleteWeighingDialogVisible: (b: boolean) => void;
  setRestoreWeighingDialogVisible: (b: boolean) => void;
  setIsOpenDialogKpo: (b: boolean) => void;
  setIsOpenDialogKpok: (b: boolean) => void;
  setDialogKpokMode: (s: string) => void;
  setClickedRowId: (s: string) => void;
  setIsOpenSnapshotsDialog: (b: boolean) => void;
  selectedWeighings: Weighing[] | null;
}

export const ActionBodyTemplateComponent = ({
  rowData,
  rowIndex,
  handleFetchComponentData,
  componentData,
  modulesConfiguration,
  setWeighing,
  setWeighingDialogVisible,
  setWeighingHistoryVisible,
  setBdoDialogData,
  showSendEmailDialog,
  setDeleteWeighingDialogVisible,
  setRestoreWeighingDialogVisible,
  setIsOpenDialogKpo,
  setIsOpenDialogKpok,
  setDialogKpokMode,
  setClickedRowId,
  setIsOpenSnapshotsDialog,
  selectedWeighings,
}: ActionBodyTemplateComponentProps) => {
  const { t } = useTranslation('weighingWeighingList');
  const dispatch = useDispatch();

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const { printReceipts, generateReceipts } = useWeighingsListReceipts(selectedWeighings);

  const items = [] as any[];
  const menu = useRef<any>(null);

  const splitWeighing = async () => {
    await weighingsService.splitWeighing(rowData.id!);
    await handleFetchComponentData();
  };

  const openWeighing = async () => {
    await weighingsService.openWeighing(rowData.id!);
    await handleFetchComponentData();
  };

  const posPrinterNode = (edgeDeviceId?: string) => {
    const posPrinter = loggedUserContext?.nodes?.find((node) => {
      const isCorrectEdgeDevice = edgeDeviceId ? edgeDeviceId === node.edgeDevice.id : true;

      return node.type === NodeType.POS_PRINTER && isCorrectEdgeDevice;
    });

    return posPrinter;
  };

  const printPosReceipt = (weighing: Weighing) => {
    const scaleName = weighing.measurements.find((m) => m.scale?.name)?.scale?.name;
    const edgeDevice = loggedUserContext?.nodes?.find((n) => n.name === scaleName)?.edgeDevice;

    void weighingsService.printPosReceipt(weighing.id!, posPrinterNode(edgeDevice?.id).id);
  };

  const handleConfirmWeighingsListClickRow = (index: number) => {
    dispatch(
      weighingActions.setWeighingSummaryDialogProps({
        visible: true,
        weighingId: componentData.weighings?.[index]?.id,
        lastShowedWeighingMeasurementId: null,
        isConfirmedClick: true,
      }),
    );
  };

  const handleUndoConfirmWeighingsListClickRow = async (index: number) => {
    componentData.weighings?.[index]?.id &&
      (await weighingsService.undoConfirmWeighing(componentData.weighings?.[index]?.id as string));
    dispatch(weighingActions.reloadWeighingsListData());
  };

  const handleClickItem = (value: string) => {
    switch (value) {
      case 'showSendEmailDialog':
        showSendEmailDialog([rowData]);
        break;
      case 'printReceipt':
        printReceipts([rowData]);
        break;
      case 'printPosReceipt':
        printPosReceipt(rowData);
        break;
      case 'generateReceiptDownload':
        generateReceipts({ download: true }, [rowData]);
        break;
      case 'generateReceipt':
        generateReceipts({ download: false }, [rowData]);
        break;
      case 'editWeighing':
        setWeighing({ ...rowData });
        setWeighingDialogVisible(true);
        break;
      case 'weighingHistory':
        setWeighing({ ...rowData });
        setWeighingHistoryVisible(true);
        break;

      case 'deleteWeighing':
        setWeighing(rowData);
        setDeleteWeighingDialogVisible(true);
        break;
      case 'restoreWeighing':
        setWeighing(rowData);
        setRestoreWeighingDialogVisible(true);
        break;
      case 'kpo':
        void prepareData(true).then((dialogData) => {
          setBdoDialogData(dialogData);
          setIsOpenDialogKpo(true);
        });
        break;
      case 'kpok-receive':
        void prepareData(false).then((dialogData) => {
          setDialogKpokMode('RECEIVE');
          setBdoDialogData(dialogData);
          setIsOpenDialogKpok(true);
        });
        break;
      case 'kpok-transfer':
        void prepareData(true).then((dialogData) => {
          setDialogKpokMode('TRANSFER');
          setBdoDialogData(dialogData);
          setIsOpenDialogKpok(true);
        });
        break;
      case 'splitWeighing':
        void splitWeighing();
        break;
      case 'openWeighing':
        void openWeighing();
        break;
      default:
        break;
    }
  };

  const prepareItems = () => {
    const itemsReceipt = [];
    const itemsBdo = [];
    const bdoOptionsEnabled =
      modulesConfiguration.bdo === 'enabled' &&
      !rowData.cardId &&
      ['SINGLE', 'DOUBLE', 'MANUAL'].includes(rowData.weighingMode);

    const areAllMeasurementsFromScale = rowData.measurements.reduce(
      (acc: boolean, curr: any) => acc && curr.type === 'MEASURED',
      true,
    );

    // there is no possibility to restore splitted ghost weighing,
    // because this will cause duplication of measurements
    if (
      rowData.weighingMode === 'DOUBLE' &&
      rowData.measurements.length === 2 &&
      !rowData.deleted &&
      areAllMeasurementsFromScale
    ) {
      (componentData.configuration?.weighingConfirmation
        ? componentData.configuration.enableConfirmedWeighingsEdit
          ? true
          : !rowData.isConfirmed
        : true) &&
        items.push({ label: t('actionsSplit'), command: () => handleClickItem('splitWeighing'), icon: 'pi pi-replay' });
    }
    if (!rowData.deleted && !rowData.isConfirmed && rowData.measurements.length === 1) {
      hasPermissions(['WEIGHINGS_EDIT'], loggedUserContext.permissions) &&
        (componentData.configuration?.weighingConfirmation
          ? componentData.configuration.enableConfirmedWeighingsEdit
            ? true
            : !rowData.isConfirmed
          : true) &&
        items.push({ label: t('actionsOpen'), command: () => handleClickItem('openWeighing'), icon: 'pi pi-unlock' });
    }
    if (!rowData.deleted && !rowData.isConfirmed && componentData.configuration?.weighingConfirmation) {
      hasPermissions(['WEIGHINGS_CONFIRMATION'], loggedUserContext.permissions) &&
        items.push({
          label: t('actionsConfirm'),
          command: () => handleConfirmWeighingsListClickRow(rowIndex),
          icon: 'pi pi-check',
        });
    }
    if (!rowData.deleted && rowData.isConfirmed && componentData.configuration?.weighingConfirmation) {
      hasPermissions(['WEIGHINGS_CONFIRMATION'], loggedUserContext.permissions) &&
        items.push({
          label: t('actionsUndoConfirm'),
          command: () => handleUndoConfirmWeighingsListClickRow(rowIndex),
          icon: 'pi pi-times',
        });
    }
    if (!rowData.deleted) {
      hasPermissions(['WEIGHING'], loggedUserContext.permissions) &&
        items.push({
          label: t('historyLabel'),
          command: () => handleClickItem('weighingHistory'),
          icon: 'pi pi-info-circle',
        });
    }

    if (!!rowData.deleted && !rowData.isSplitted) {
      hasPermissions(['WEIGHINGS_RESTORE'], loggedUserContext.permissions) &&
        items.push({
          label: t('actionsRestore'),
          command: () => handleClickItem('restoreWeighing'),
          icon: 'pi pi-replay',
        });
    } else {
      if (hasOneOfPermissions(['WEIGHING', 'WEIGHINGS_REPORTS'], loggedUserContext.permissions)) {
        itemsReceipt.push({
          label: t('actionsReceiptDownload'),
          command: () => handleClickItem('generateReceiptDownload'),
          icon: 'pi pi-download',
        });
        itemsReceipt.push({
          label: t('actionsReceiptSend'),
          command: () => handleClickItem('showSendEmailDialog'),
          icon: 'pi pi-reply',
        });
        if (navigator.userAgent.indexOf('Firefox') !== -1) {
          itemsReceipt.push({
            label: t('actionsReceiptViewPrint'),
            command: () => handleClickItem('generateReceipt'),
            icon: 'pi pi-eye',
          });
        } else {
          itemsReceipt.push({
            label: t('actionsReceiptView'),
            command: () => handleClickItem('generateReceipt'),
            icon: 'pi pi-eye',
          });
          itemsReceipt.push({
            label: t('actionsReceiptPrint'),
            command: () => handleClickItem('printReceipt'),
            icon: 'pi pi-print',
          });
        }
      }
      hasPermissions(['WEIGHINGS_EDIT'], loggedUserContext.permissions) &&
        (componentData.configuration?.weighingConfirmation
          ? componentData.configuration.enableConfirmedWeighingsEdit
            ? true
            : !rowData.isConfirmed
          : true) &&
        items.push({ label: t('actionsEdit'), command: () => handleClickItem('editWeighing'), icon: 'pi pi-pencil' });
      hasPermissions(['WEIGHINGS_DELETE'], loggedUserContext.permissions) &&
        items.push({
          label: t('actionsDelete'),
          command: () => handleClickItem('deleteWeighing'),
          icon: 'pi pi-trash',
        });
      posPrinterNode() &&
        items.push({
          label: t('actionsPrintPosReceipt'),
          command: () => handleClickItem('printPosReceipt'),
          icon: 'pi pi-print',
        });

      if (bdoOptionsEnabled && hasPermissions(['WASTE_REGISTER'], loggedUserContext.permissions)) {
        itemsBdo.push({
          label: t('actionsKpo'),
          command: () => handleClickItem('kpo'),
          disabled: !componentData.isCorrectBdoModuleConfig,
        });
        itemsBdo.push({
          label: t('actionsKpokReceive'),
          command: () => handleClickItem('kpok-receive'),
          disabled: !componentData.isCorrectBdoModuleConfig,
        });
        itemsBdo.push({
          label: t('actionsKpokTransfer'),
          command: () => handleClickItem('kpok-transfer'),
          disabled: !componentData.isCorrectBdoModuleConfig,
        });
      }
    }

    if (itemsBdo.length > 0) {
      items.unshift({ label: t('actionsBdo'), items: itemsBdo, icon: 'pi pi-bars' });
    }
    if (itemsReceipt.length > 0) {
      items.unshift({ label: t('actionsReceipt'), items: itemsReceipt, icon: 'pi pi-bars' });
    }
  };
  prepareItems();

  const prepareData = async (withWasteMass: boolean) => {
    let wasteMass = null;

    if (withWasteMass) {
      wasteMass = +parseFloat((await weighingsService.getWeighingNetto(rowData.id!, 'Mg')).data).toFixed(4);
    }

    return {
      form: {
        vehicleRegNumber: rowData?.vehicle?.registrationNumber,
        wasteMass,
        wasteCodeId: rowData.productsSummary[0]?.product?.isBdoWasteCode
          ? rowData.productsSummary[0].product.wasteCodeId
          : null,
      },
      weighingId: rowData?.id,
      weighingContractor: rowData.contractor,
    };
  };

  const handleOpenSnapshots = () => {
    setClickedRowId(rowData.id!);
    setIsOpenSnapshotsDialog(true);
  };

  return (
    <div className="actions flex justify-content-end">
      {isModulesEnabled(['CAMERAS'], loggedUserContext.currentCustomer?.subscriptionModules) && (
        <button type="button" className="p-link mr-7" onClick={handleOpenSnapshots}>
          <i className="pi pi-fw pi-images"></i>
        </button>
      )}
      {!!items.length && (
        <>
          <TieredMenu model={items} popup ref={menu} />
          <Button label={t('actionsLabel')} onClick={(event) => menu.current?.toggle(event)} />
        </>
      )}
    </div>
  );
};
