import { Toast } from 'primereact/toast';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { weighingActions } from '../../../../store/weighing-slice';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../types/reducer-state';
import { hasPermissions } from '../../../../utils/permissionsUtils';
import weighingConfigurationService from '../../../../services/ScaleoApiServices/WeighingConfigurationService';

const ToastController = () => {
  const { t } = useTranslation('weighingHandlingWeighings');
  const dispatch = useDispatch();
  const {
    weighingSummaryDialogProps,
    finishedWeighingMeasurement,
    shouldDisplayWeighingErrorToast,
    shouldDisplayWeighingSuccessToast,
    tareUpdatedToastData,
    automaticWeighingSuccessToastData,
    scaleZero,
    allNotConfirmedWeighings,
  } = useSelector((state: ReducerState) => state.weighing);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const toast = useRef<any>();
  const [configSummaryScreenAfterMeasurementVisible, setConfigSummaryScreenAfterMeasurementVisible] = useState(false);

  const fetchWeighingConfiguration = useCallback(async () => {
    weighingConfigurationService.getConfiguration().then((weighingConfiguration) => {
      setConfigSummaryScreenAfterMeasurementVisible(weighingConfiguration.data.summaryScreenAfterMeasurementVisible);
    });
  }, []);

  useEffect(() => {
    fetchWeighingConfiguration();
  }, [fetchWeighingConfiguration]);

  useEffect(() => {
    if (shouldDisplayWeighingErrorToast) {
      toast.current.show({
        severity: 'error',
        summary: t('weighingError'),
        detail: t('weighingErrorDetail', { error: finishedWeighingMeasurement?.error }),
        life: 4000,
      });
      dispatch(weighingActions.weighingErrorToastDisplayed());
    } else if (tareUpdatedToastData) {
      const detailedMessage = tareUpdatedToastData.registrationNumber
        ? t('savedTareDetail', { registrationNumber: tareUpdatedToastData.registrationNumber })
        : t('savedTare');

      toast.current.show({
        severity: 'success',
        summary: t('tareProcessing'),
        detail: detailedMessage,
        life: 4000,
      });
      dispatch(weighingActions.tareUpdatedToastDisplayed());
    } else if (shouldDisplayWeighingSuccessToast) {
      toast.current.show({
        severity: 'success',
        summary: t('closedWeighing'),
        detail: t('closedWeighingDetail', { weighingNumber: finishedWeighingMeasurement?.weighingNumber }),
        life: 4000,
      });
      dispatch(weighingActions.weighingSuccessToastDisplayed());
    } else if (automaticWeighingSuccessToastData) {
      const detailedMessage = automaticWeighingSuccessToastData.registrationNumber
        ? t('automaticWeighingFinishedDetail', {
            registrationNumber: automaticWeighingSuccessToastData.registrationNumber,
          })
        : t('automaticWeighingFinished');

      if (!weighingSummaryDialogProps.visible && !configSummaryScreenAfterMeasurementVisible) {
        toast.current.show({
          severity: 'success',
          summary: t('automaticWeighing'),
          detail: detailedMessage,
          life: 4000,
        });
      }
      dispatch(weighingActions.automaticWeighingSuccessToastDisplayed());
    }
  }, [
    finishedWeighingMeasurement,
    shouldDisplayWeighingErrorToast,
    shouldDisplayWeighingSuccessToast,
    tareUpdatedToastData,
    automaticWeighingSuccessToastData,
    dispatch,
  ]);

  useEffect(() => {
    if (scaleZero?.error) {
      toast.current.show({
        severity: 'error',
        summary: t('scaleZeroError'),
        detail: t('scaleZeroErrorDetail', { error: scaleZero?.error }),
        life: 4000,
      });
    } else if (scaleZero?.scaleId) {
      toast.current.show({
        severity: 'success',
        summary: t('scaleZeroProcessing'),
        detail: t('scaleZeroFinished'),
        life: 4000,
      });
    }
  }, [scaleZero]);

  useEffect(() => {
    if (
      allNotConfirmedWeighings.length > 0 &&
      hasPermissions(['WEIGHINGS_CONFIRMATION'], loggedUserContext.permissions)
    ) {
      // const message = t('weighingToConfirmInfo');
      // toast.current.show({
      //   severity: 'info',
      //   summary: t('weighingToConfirmWeighingTitle'),
      //   detail: message,
      //   life: 6000,
      // });
    }
  }, [allNotConfirmedWeighings.length, dispatch]);

  return <Toast ref={toast} />;
};

export default ToastController;
