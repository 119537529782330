import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import { useSelector } from 'react-redux';
import { ReducerState } from '../../../../types/reducer-state';
import { useTranslation } from 'react-i18next';
import containersService from '../../../../services/ScaleoApiServices/ContainersService';
import { Container } from '../../../../types/container';
import { useFormik } from 'formik';

const emptyContainer = {
  id: null,
  name: '',
  code: '',
  tareValue: null,
} as Container;

interface NewOrEditContainerDialogProps {
  visible: boolean;
  setContainerDialogVisible: (b: boolean) => void;
  onContainerAdded?: (container: Container) => void;
  container: Container | null;
  displayTare: boolean;
}

export const NewOrEditContainerDialog = ({
  visible,
  setContainerDialogVisible,
  onContainerAdded,
  container,
  displayTare,
}: NewOrEditContainerDialogProps) => {
  const { t } = useTranslation('datasetsContainers');

  const unit = useSelector((state: ReducerState) => state.appConfig.unit);

  const tareValueHandler = (e: any) => {
    formik.setFieldValue('tareValue', e.target.value ? Number(e.target.value) : null);
  };

  const hideDialog = () => {
    setContainerDialogVisible(false);
    formik.resetForm({ values: emptyContainer });
  };

  const formik = useFormik({
    initialValues: container ?? emptyContainer,
    validate: (data) => {
      const errors = {} as any;

      if (!data.name) {
        errors.name = t('noNameError');
      }
      if (!data.code) {
        errors.code = t('noCodeError');
      }
      return errors;
    },
    onSubmit: async (formData, helpers) => {
      if (!formData.id) {
        await containersService
          .createContainer({ ...formData })
          .then((res) => {
            hideDialog();
            onContainerAdded && onContainerAdded({ ...res.data });
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('containerExistsError'));
            }
          });
      } else {
        await containersService
          .editContainer({ ...formData }, formData.id)
          .then((res) => {
            hideDialog();
            onContainerAdded && onContainerAdded({ ...res.data });
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('containerExistError'));
            }
          });
      }
    },
    enableReinitialize: true,
  });

  const containerDialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={hideDialog} />
      <Button type="submit" label={t('saveButton')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );
  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={visible}
        header={t('containerDetails')}
        modal
        className="p-fluid w-40vw"
        footer={containerDialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      >
        <form>
          <div className="field">
            <label htmlFor="name">{t('name')}</label>
            <InputText id="name" value={formik.values.name} onChange={formik.handleChange} autoFocus />
            <FormErrorMessage fieldName="name" formikInstance={formik} />
          </div>
          <div className="field">
            <label htmlFor="code">{t('code')}</label>
            <InputText id="code" value={formik.values.code} onChange={formik.handleChange} />
            <FormErrorMessage fieldName="code" formikInstance={formik} />
          </div>
          {displayTare && (
            <div className="field">
              <label htmlFor="tareValue">{t('tareValue', { unit })}</label>
              <InputText
                id="tareValue"
                type="number"
                value={formik.values.tareValue?.toString()}
                onChange={tareValueHandler}
              />
            </div>
          )}
          <br />
          <br />
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};
