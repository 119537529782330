import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import usersService from '../../services/ScaleoApiServices/UsersService';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import { User } from '../../types/user';
import { ReducerState } from '../../types/reducer-state';
import { Message } from 'primereact/message';
import { userActions } from '../../store/user-slice';

const UserProfile = () => {
  const { t } = useTranslation('userProfile');

  const currentUser = useSelector((state: ReducerState) => state.user.loggedUser);
  const toast = useRef<any>();
  const dispatch = useDispatch();

  const [initFormValues] = useState({
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    newPassword: false,
    previousPassword: '',
    password: '',
    confPassword: '',
    role: '',
  });

  const validateMethod = (data: User) => {
    const errors = {} as Record<string, string>;
    if (!data.email) {
      errors.email = t('emailError');
    } else if (!validator.isEmail(data.email)) {
      errors.email = t('emailError');
    }
    if ((!data.id && !data.password) || (data.newPassword && !data.password)) {
      errors.password = t('passwordError');
    }
    if (data.password !== data.confPassword) {
      errors.confPassword = t('confirmPasswordError');
    }
    if (!data.firstName) {
      errors.firstName = t('firstNameError');
    }
    if (!data.lastName) {
      errors.lastName = t('lastNameError');
    }
    return errors;
  };

  const fetchUser = useCallback(async (userId) => {
    usersService.getById(userId).then((response) => {
      const user = response.data;
      formik.resetForm({
        values: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email ? user.email : '',
          phone: user.phone ? user.phone : '',
          newPassword: false,
          password: '',
          previousPassword: '',
          confPassword: '',
        },
      });
    });
  }, []);

  useEffect(() => {
    fetchUser(currentUser?.id);
  }, [currentUser?.id, fetchUser]);

  const handleSubmit = async (data: User, helpers: any) => {
    usersService
      .editUserProfile({ ...data, accessToken: currentUser?.accessToken }, formik.values.id!)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: t('updateSuccess'),
          life: 4000,
        });
        window.location.reload();
      })
      .catch((error) => {
        const errorCode = error.response.data.error;
        errorCode === 'INVALID_PASSWORD' && helpers.setFieldError('password', t('updateError'));
        errorCode === 'INVALID_PREVIOUS_PASSWORD' &&
          helpers.setFieldError('previousPassword', t('previousPasswordError'));
        errorCode === 'LIMIT_EXCEEDED' && helpers.setFieldError('password', t('limitExceededError'));
      });
    dispatch(userActions.shouldRefreshLoggedUserContext(true));
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="card pb-70px">
      <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
        <form onSubmit={formik.handleSubmit}>
          <Fieldset legend={t('header')}>
            <div className="p-fluid formgrid grid">
              <div className="col-12 md:col-6 lg:col-4">
                {(!formik.values.firstName || !formik.values.lastName) && (
                  <Message className="h-4vh w-20vw mb-2vh" text={'Musisz dodać imię i nazwisko'} />
                )}
                <div className="field">
                  <label htmlFor="email">{t('email')}</label>
                  <InputText id="email" value={formik.values.email} readOnly disabled />
                </div>
                <div className="field">
                  <div className="field-checkbox">
                    <Checkbox id="newPassword" checked={formik.values.newPassword} onChange={formik.handleChange} />
                    <label htmlFor="newPassword">{t('newPassword')}</label>
                  </div>
                </div>
                {formik.values.newPassword && (
                  <>
                    <div className="field">
                      <label htmlFor="previousPassword">{t('previousPassword')}</label>
                      <Password
                        id="previousPassword"
                        name="previousPassword"
                        value={formik.values.previousPassword}
                        onChange={formik.handleChange}
                        feedback={false}
                        toggleMask
                      />
                      <FormErrorMessage fieldName="previousPassword" formikInstance={formik} />
                    </div>
                    <div className="field">
                      <label htmlFor="password">{t('password')}</label>
                      <Password
                        id="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        feedback={false}
                        toggleMask
                      />
                      <FormErrorMessage fieldName="password" formikInstance={formik} />
                    </div>
                    <div className="field">
                      <label htmlFor="confPassword">{t('confirmPassword')}</label>
                      <Password
                        id="confPassword"
                        name="confPassword"
                        value={formik.values.confPassword}
                        onChange={formik.handleChange}
                        feedback={false}
                      />
                      <FormErrorMessage fieldName="confPassword" formikInstance={formik} />
                    </div>
                    <hr />
                  </>
                )}
                <div className="field">
                  <label htmlFor="firstName">{t('firstName')}</label>
                  <InputText id="firstName" value={formik.values.firstName} onChange={formik.handleChange} />
                  <FormErrorMessage fieldName="firstName" formikInstance={formik} />
                </div>
                <div className="field">
                  <label htmlFor="lastName">{t('lastName')}</label>
                  <InputText id="lastName" value={formik.values.lastName} onChange={formik.handleChange} />
                  <FormErrorMessage fieldName="lastName" formikInstance={formik} />
                </div>
                <div className="field">
                  <label htmlFor="phone">{t('phone')}</label>
                  <InputText id="phone" value={formik.values.phone} onChange={formik.handleChange} />
                  <FormErrorMessage fieldName="phone" formikInstance={formik} />
                </div>
              </div>
            </div>
            <div className="action-buttons">
              <div className="flex flex-column md:flex-row justify-content-end p-ai-end">
                <Button type="submit" label={t('saveButton')} icon="pi pi-check" text />
              </div>
            </div>
          </Fieldset>
        </form>
      </FormErrorMessageScroller>
      <Toast ref={toast} />
    </div>
  );
};

export default UserProfile;
