import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Chips } from 'primereact/chips';
import { Panel } from 'primereact/panel';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import validator from 'validator';
import weighingsService from '../../../../../../services/ScaleoApiServices/WeighingsService';
import { getFileFromBuffer } from '../../../../../../utils/getFile';
import FormErrorMessage from '../../../../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../../../../_shared/FormErrorMessageScroller';
import ControlWeighingSummaryData from './ControlWeighingSummaryData';
import RegularWeighingSummaryData from './MultipleWeighingSummaryData';
import { useTranslation } from 'react-i18next';
import WeighingSeriesSummaryData from './WeighingSeriesSummaryData';
import DoubleWeighingSeriesSummaryData from './DoubleWeighingSeriesSummaryData';
import DefaultWeighingSummaryData from './DefaultWeighingSummaryData';
import { InputTextarea } from 'primereact/inputtextarea';
import { Weighing } from '../../../../../../types/weighing';

interface WeighingSummaryProps {
  weighing: Weighing;
  withRouterButtons: any;
  autoPrintReceiptWeight: boolean;
  printWeighingReceipt: () => void;
  confirmationComment: boolean;
  setComment: (s: string) => void;
  doNotPrint: boolean | null;
}

const WeighingSummary = ({
  weighing,
  withRouterButtons,
  autoPrintReceiptWeight,
  printWeighingReceipt,
  confirmationComment,
  setComment,
  doNotPrint,
}: WeighingSummaryProps) => {
  const { t } = useTranslation('weighingHandlingWeighings');

  const history = useHistory();

  const gotoWeighingsList = () => {
    if (withRouterButtons && typeof withRouterButtons === 'function') {
      withRouterButtons();
    }
    history.push('/weighings');
  };

  useEffect(() => {
    if (autoPrintReceiptWeight && weighing && !doNotPrint) {
      printWeighingReceipt();
    }
  }, []);

  const getWeighingSummaryDataTemplate = () => {
    switch (weighing.weighingMode) {
      case 'SINGLE':
        return <RegularWeighingSummaryData weighing={weighing} />;
      case 'DOUBLE':
        return <RegularWeighingSummaryData weighing={weighing} />;
      case 'MULTIPLE':
        return <RegularWeighingSummaryData weighing={weighing} />;
      case 'WEIGHING_SERIES':
        return <WeighingSeriesSummaryData weighing={weighing} />;
      case 'DOUBLE_WEIGHING_SERIES':
        return <DoubleWeighingSeriesSummaryData weighing={weighing} />;
      case 'CONTROL':
        return <ControlWeighingSummaryData weighing={weighing} />;
      default:
        // to show automatic weighing measurements etc.
        return <DefaultWeighingSummaryData weighing={weighing} />;
    }
  };

  const downloadWeighingReceipt = async () => {
    const weighingReceipt = await weighingsService.getWeighingReceipt(weighing.id as string);
    getFileFromBuffer(weighingReceipt.data, 'application/pdf', 'kwit_wagowy.pdf');
  };

  const openWeighingReceiptInNewTab = async () => {
    const weighingReceipt = await weighingsService.getWeighingReceipt(weighing.id as string);
    getFileFromBuffer(weighingReceipt.data, 'application/pdf');
  };

  const sendWeighingReceipt = async () => {
    weighingsService.sendWeighingReceipts([weighing.id as string], formik.values.emails);
  };

  const formik = useFormik({
    initialValues: {
      emails: [],
    },
    validate: (data) => {
      const errors: any = {};
      if (data.emails.length === 0) {
        errors.emails = t('openWeighingsListSummaryValidationNoEmails');
      }
      data.emails.forEach((email) => {
        if (!validator.isEmail(email)) {
          errors.emails = t('openWeighingsListSummaryValidationIncorrectEmails');
          errors[email] = true;
        }
      });

      return errors;
    },
    onSubmit: () => {
      const METHODS = {
        SEND: () => sendWeighingReceipt(),
      };

      const action = (document?.activeElement as any)?.dataset?.flag; // custom data-flag attribute as a workaround to have multiple submit buttons in formik
      METHODS[action as keyof typeof METHODS] && METHODS[action as keyof typeof METHODS]();
    },
  });

  const onEmailAdded = (event: any) => {
    const newEmail = event.value;
    const alreadyAddedEmails = formik.values.emails;
    formik.setFieldValue('emails', [...alreadyAddedEmails, newEmail]);
  };

  const onEmailRemoved = (event: any) => {
    const emailToBeRemoved = event.value[0];
    const alreadyAddedEmails = formik.values.emails;
    formik.setFieldValue(
      'emails',
      alreadyAddedEmails.filter(function (email) {
        return email !== emailToBeRemoved;
      }),
    );
  };

  const handleBlurChips = (e: any) => {
    if (e.target.value) {
      formik.setFieldValue('emails', [...formik.values.emails, e.target.value]);
      e.target.value = '';
    }
  };

  const emailChipTemplate = (email: keyof typeof formik.errors) => {
    return (
      <div>
        {formik.errors[email] && <span style={{ backgroundColor: '#FFCDD2', color: '#73000c' }}>{email}</span>}
        {!formik.errors[email] && <span>{email}</span>}
      </div>
    );
  };

  const renderPrintButton = () => {
    //temporary fix for Firefox
    if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return (
        <Button
          type="reset"
          label={t('openWeighingsListSummaryReceiptViewPrint')}
          icon="pi pi-eye"
          text
          onClick={() => openWeighingReceiptInNewTab()}
        />
      );
    } else {
      return (
        <>
          <Button
            type="reset"
            label={t('openWeighingsListSummaryReceiptPrint')}
            icon="pi pi-print "
            text
            onClick={() => printWeighingReceipt()}
          />
          <Button
            type="reset"
            label={t('openWeighingsListSummaryReceiptView')}
            icon="pi pi-eye"
            text
            onClick={() => openWeighingReceiptInNewTab()}
          />
        </>
      );
    }
  };

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid nested-grid">
          <div className="grid lg:col-12 md:col-12 sm:col-12">
            <div className="col-12">
              <Panel header={t('openWeighingsListSummaryWeighingDataTitle')}>
                <div className="formgrid grid">
                  {getWeighingSummaryDataTemplate()}
                  {confirmationComment && (
                    <div className="col-6">
                      <strong>{`${t('openWeighingsListSummaryWeighingDataComment')}:`}</strong>
                      <InputTextarea className="h-7vh w-50vw" onChange={(e) => setComment(e.target.value)} />
                    </div>
                  )}
                  {weighing?.weighingMode !== 'CONTROL' && (
                    <div className="button-container mt--2vw">
                      <Button
                        type="button"
                        label={t('openWeighingsListSummaryWeighingDataList')}
                        icon="pi pi-list"
                        className="p-button-text"
                        onClick={gotoWeighingsList}
                      />
                    </div>
                  )}
                </div>
              </Panel>
            </div>
            {weighing.closed && (
              <div className="col-12">
                <Panel header={t('openWeighingsListSummaryReceiptTitle')}>
                  <div className="grid formgrid ">
                    <div className="field lg:col-4 md:col-12 sm:col-12">
                      <strong>{t('openWeighingsListSummaryReceiptEmails')}</strong>
                    </div>
                    <div className="field lg:col-8 md:col-12 sm:col-12">
                      <Chips
                        value={formik.values.emails}
                        onAdd={onEmailAdded}
                        onRemove={onEmailRemoved}
                        itemTemplate={emailChipTemplate}
                        onBlur={handleBlurChips}
                      />
                      <FormErrorMessage fieldName="emails" formikInstance={formik} />
                    </div>
                    <div className="button-container">
                      {renderPrintButton()}
                      <Button
                        type="reset"
                        label={t('openWeighingsListSummaryReceiptDownload')}
                        icon="pi pi-download"
                        text
                        onClick={() => downloadWeighingReceipt()}
                      />
                      <Button
                        data-flag="SEND"
                        type="submit"
                        label={t('openWeighingsListSummaryReceiptSend')}
                        icon="pi pi-reply"
                        text
                      />
                      {/* <Button type="submit" label={t('openWeighingsListSummaryReceiptPrint')}  icon="pi pi-print" text /> */}
                      {/* TODO: probably we can add this button in offline version */}
                    </div>
                  </div>
                </Panel>
              </div>
            )}
          </div>
          {/* TODO: placeholder for photos
          <div className="grid lg:col-4 md:col-12 sm:col-12" name="weighing-summary-photos">
            <div className="col-12" name="camera-preview">
              <Panel legend={t('openWeighingsListSummaryCameraLegend')} header={t('openWeighingsListSummaryCameraTitle')} toggleable>
                <img
                  alt="Waga-samochodowa-Miary-i-Wagi"
                  className="xl:mb-4 md:mr-4 sm:mb-4 max-width-145px max-height-145px"
                  src="assets/layout/images/Waga-samochodowa-Miary-i-Wagi-98-1.jpg"
                />
              </Panel>
            </div>
          </div> */}
        </div>
      </form>
    </FormErrorMessageScroller>
  );
};

export default WeighingSummary;
