import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { useSelector } from 'react-redux';
import { formatDecimal } from '../../../utils/formatUtils';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../types/reducer-state';

const MassTemplate = (measurement: any) => {
  return <>{`${formatDecimal(measurement.indication)} ${measurement.unit}`}</>;
};

const alibiTemplate = (measurement: any) => {
  return (
    <>
      {measurement.alibi && `${measurement.alibi}`}
      {!measurement.alibi && `--`}
    </>
  );
};

const renderAdditionalField = (field: any) => {
  if (['DICTIONARY', 'TEXT', 'AUTO_ID'].includes(field.type)) {
    return (
      <div key={field.name}>
        {field.name}: {field.value}
      </div>
    );
  } else {
    let _value = parseFloat(`${field.value}`.replace(/,/g, '.').replace(/\s/g, ''));
    let _unit = field.unit;

    if (isNaN(_value)) {
      _value = field.value;
      _unit = '';
    } else {
      _value = formatDecimal(_value) as any;
    }

    return (
      <div key={field.name}>
        {field.name}: {`${_value} ${_unit}`}
      </div>
    );
  }
};

interface MeasurementsListProps {
  measurements: any[];
  showAdditionalFields: boolean;
  columnEmptyValue: string | undefined;
}

const MeasurementsList = ({ measurements, showAdditionalFields, columnEmptyValue }: MeasurementsListProps) => {
  const { t } = useTranslation('weighingComponents');

  const unit = useSelector((state: ReducerState) => state.appConfig.unit);
  const measurementsWithUnit = measurements.map((measurement) => ({ ...measurement, unit: unit }));

  const productBody = (rowData: any) => <>{rowData.product?.name}</>;

  const additionalFieldsBodyTemplate = (measurement: any) => {
    if (measurement.additionalFields?.length && !measurement.isTare) {
      return measurement.additionalFields?.map(renderAdditionalField);
    }

    return columnEmptyValue || null;
  };

  const measurementNameTemplate = (rowData: any, data: { rowIndex: number }) => {
    return (
      <span>
        {rowData.isTare && t('measurementTare', { index: data.rowIndex + 1 })}
        {!rowData.isTare && t('measurementNoTare', { index: data.rowIndex + 1 })}
      </span>
    );
  };

  const WEIGHING_MEASUREMENT_EXECUTION_TYPE_TRANSLATIONS = {
    DECLARED: t('declared'),
    MEASURED: t('measured'),
  };

  return (
    <>
      <DataTable responsiveLayout="scroll" value={measurementsWithUnit} className="mb-6">
        <Column field="measurementName" header={t('labelMeasurement')} body={measurementNameTemplate} />
        <Column field="indication" header={t('labelIndication')} body={MassTemplate} />
        <Column field="alibi" header={t('labelAlibi')} body={alibiTemplate} />
        <Column field="product.name" header={t('labelProduct')} body={productBody} />
        <Column field="scale.name" header={t('labelScale')} />
        <Column field="timestamp" header={t('labelDate')} body={(m) => new Date(m.timestamp).toLocaleString()} />
        <Column
          field="type"
          header={t('labelType')}
          body={(m) =>
            WEIGHING_MEASUREMENT_EXECUTION_TYPE_TRANSLATIONS[
              m.type as keyof typeof WEIGHING_MEASUREMENT_EXECUTION_TYPE_TRANSLATIONS
            ]
          }
        />
        {measurements.find((m) => m.accessCodeName) && (
          <Column field="accessCodeName" header={t('productsSummaryAccessCodeName')} />
        )}
        {measurements.find((m) => m.arrivalNotificationNumber) && (
          <Column field="arrivalNotificationNumber" header={t('productsSummaryarrivalNotificationNumber')} />
        )}
        {!!showAdditionalFields && (
          <Column field="additionalFields" header={t('additionalField')} body={additionalFieldsBodyTemplate} />
        )}
      </DataTable>
    </>
  );
};

export default MeasurementsList;
