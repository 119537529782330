import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import '../../../DataTable.css';
import { userActions } from '../../../../store/user-slice';
import { useDispatch, useSelector } from 'react-redux';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import accessCodesService from '../../../../services/ScaleoApiServices/AccessCodesService';
import { CustomAutoComplete } from '../../../_shared/CustomComponents';
import { Checkbox } from 'primereact/checkbox';
import { Password } from 'primereact/password';
import { ReducerState } from '../../../../types/reducer-state';
import { Dictionary } from '../../../../types/dictionary';
import AdditionalFields from './AdditionalFields';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { isModulesEnabled } from '../../../../utils/modulesUtils';
import { Contractor, Driver, Product, Vehicle } from '../../../../types/weighing';
import { Trailer } from '../../../../types/trailer';

const INIT_FORM_STATE = {
  name: '',
  contractor: null as Contractor | null,
  driver: null as Driver | null,
  vehicle: null as Vehicle | null,
  trailer: null as Trailer | null,
  product: null as Product | null,
  isActive: true,
  additionalFields: [],
  transactionType: null,
  cardNumber: null,
  pin: null,
  qrCode: false,
  usageCounter: 0,
  numberOfUses: null,
};

interface NewOrEditAccessCodeDialogProps {
  visible: boolean;
  closeDialog: any;
  item: any;
  contractors: Contractor[];
  drivers: Driver[];
  vehicles: Vehicle[];
  trailers: Trailer[];
  products: Product[];
  dictionaries: Dictionary[] | null;
  transactionTypes: any[];
}

const NewOrEditAccessCodeDialog = ({
  visible,
  closeDialog,
  item,
  contractors,
  drivers,
  vehicles,
  trailers,
  products,
  dictionaries,
  transactionTypes,
}: NewOrEditAccessCodeDialogProps) => {
  const { t } = useTranslation('datasetsAccessCodes');
  const dispatch = useDispatch();
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const { weighingConfiguration } = useSelector((state: ReducerState) => state.weighing);
  const { additionalFields = [] } = weighingConfiguration || {};
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  useEffect(() => {
    if (visible && item?.id) {
      const {
        name,
        pin,
        cardNumber,
        qrCode,
        isActive,
        additionalFields: itemAdditionalFields,
        usageCounter,
        numberOfUses,
      } = item;
      const contractor = contractors.find((c) => c.id === item?.contractorId)!;
      const driver = drivers.find((d) => d.id === item?.driverId)!;
      const vehicle = vehicles.find((v) => v.id === item?.vehicleId)!;
      const product = products.find((v) => v.id === item?.productId)!;
      const trailer = trailers.find((t) => t.id === item?.trailerId)!;
      const transactionType = transactionTypes.find((t) => t.id === item?.transactionType);

      setInitFormValues({
        name,
        contractor,
        pin,
        cardNumber,
        qrCode: !!qrCode,
        driver,
        vehicle,
        trailer,
        isActive,
        usageCounter,
        numberOfUses,
        product,
        additionalFields: additionalFields.map((a: any) => ({ ...a, value: itemAdditionalFields[a.id] })),
        transactionType,
      });
    } else {
      setInitFormValues({ ...INIT_FORM_STATE, additionalFields });
    }
  }, [item, visible, JSON.stringify(additionalFields)]);

  const validateMethod = (data: any) => {
    const errors: any = {};

    if (!data.name) {
      errors.name = t('emptyNameError');
    }

    if (!data.cardNumber && !data.pin && !data.qrCode) {
      errors.name = t('emptyError');
      return errors;
    }

    if (data.cardNumber && !data.cardNumber.match(/^[0-9a-fA-F]+$/i)) {
      errors.cardNumber = t('numericalCardNumberError');
    }

    if (!data.numberOfUses) {
      errors.numberOfUses = t('numberOfUses');
    }

    if (isNaN(data.pin)) {
      errors.pin = t('numericalPinError');
    }

    return errors;
  };

  const handleCloseDialog = (changes: any) => {
    formik.resetForm({
      values: initFormValues,
    });
    closeDialog(changes === true);
  };

  const handleSubmit = (data: any, helpers: any) => {
    const body = {
      ...data,
      contractorId: data.contractor?.id,
      driverId: data.driver?.id,
      vehicleId: data.vehicle?.id,
      trailerId: data.trailer?.id,
      productId: data.product?.id,
      additionalFields: data.additionalFields
        .filter((a: any) => a.value)
        .reduce((acc: any, curr: any) => ({ ...acc, [curr.id]: curr.value }), {}),
      transactionType: data.transactionType?.id,
    };

    if (item?.id) {
      accessCodesService
        .editAccessCode(body, item.id)
        .then(() => {
          dispatch(userActions.shouldRefreshLoggedUserContext(true));
          handleCloseDialog(true);
        })
        .catch((error) => {
          if (error.response.status === 409) {
            switch (error?.response?.data?.error) {
              case 'Access code with this pin exists!':
                helpers.setFieldError('pin', t('pinExistsError'));
                break;
              case 'Access code with this card number exists!':
                helpers.setFieldError('cardNumber', t('cardNumberExistsError'));
                break;
              case 'Access code with this name exists!':
                helpers.setFieldError('name', t('nameExistsError'));
                break;
            }
          }
        });
    } else {
      accessCodesService
        .postAccessCode(body)
        .then(() => {
          dispatch(userActions.shouldRefreshLoggedUserContext(true));
          handleCloseDialog(true);
        })
        .catch((error) => {
          if (error.response.status === 409) {
            switch (error?.response?.data?.error) {
              case 'Access code with this pin exists!':
                helpers.setFieldError('pin', t('pinExistsError'));
                break;
              case 'Access code with this card number exists!':
                helpers.setFieldError('cardNumber', t('cardNumberExistsError'));
                break;
              case 'Access code with this name exists!':
                helpers.setFieldError('name', t('nameExistsError'));
                break;
            }
          }
        });
    }
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const dialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={handleCloseDialog} />
      <Button type="submit" label={t('saveButton')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );

  const handleChangeField = (e: any, type: string) => {
    switch (type) {
      case 'text':
        formik.setFieldValue(e.target.id, e.target.value, true);
        break;

      case 'dropdown':
        formik.setFieldValue(e.target.id, e.value, false);
        break;

      case 'checkbox':
        formik.setFieldValue(e.target.id, e.checked, false);
        break;

      default:
        formik.handleChange(e);
        break;
    }
  };

  return (
    <div className="access-code-edit-dialog">
      <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
        <Dialog
          visible={visible}
          header={t('locationDetails')}
          modal
          className="p-fluid w-40vw"
          breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
          footer={dialogFooter}
          onHide={handleCloseDialog as any}
        >
          <form>
            <div className="col-12">
              <div className="field">
                <label htmlFor="name">{t('name')}</label>
                <InputText id="name" value={formik.values.name} onChange={(e) => handleChangeField(e, 'text')} />
                <FormErrorMessage fieldName="name" formikInstance={formik} />
              </div>
              <div className="field-checkbox">
                <Checkbox
                  id="cardNumber"
                  checked={formik.values.cardNumber !== null}
                  onChange={() => {
                    const newValue = formik.values.cardNumber === null ? '' : null;
                    void formik.setFieldValue('cardNumber', newValue);
                  }}
                />
                <label htmlFor="cardNumber" className="checkbox-label">
                  {t('cardNumber')}
                </label>
              </div>
              {formik.values.cardNumber !== null && (
                <div className="field">
                  <label htmlFor="cardNumber">{t('cardNumber')}</label>
                  <Password
                    id="cardNumber"
                    value={formik.values.cardNumber!}
                    onChange={(e) => formik.setFieldValue('cardNumber', e.target.value.toLocaleUpperCase())}
                    feedback={false}
                    toggleMask
                  />
                  <FormErrorMessage fieldName="cardNumber" formikInstance={formik} />
                </div>
              )}
              <div className="field-checkbox">
                <Checkbox
                  id="pin"
                  checked={formik.values.pin !== null}
                  onChange={() => {
                    const newValue = formik.values.pin === null ? '' : null;
                    void formik.setFieldValue('pin', newValue);
                  }}
                />
                <label htmlFor="pin" className="checkbox-label">
                  {t('pin')}
                </label>
              </div>
              {formik.values.pin !== null && (
                <div className="field">
                  <label htmlFor="pin">{t('pin')}</label>
                  <Password
                    id="pin"
                    value={formik.values.pin!}
                    onChange={(e) => formik.setFieldValue('pin', e.target.value)}
                    feedback={false}
                    toggleMask
                  />
                  <FormErrorMessage fieldName="pin" formikInstance={formik} />
                </div>
              )}
              {isModulesEnabled(['QR_ACCESS_CONTROL'], loggedUserContext.currentCustomer?.subscriptionModules) && (
                <div className="field-checkbox">
                  <Checkbox
                    id="qrCode"
                    checked={formik.values.qrCode}
                    onChange={() => {
                      void formik.setFieldValue('qrCode', !formik.values.qrCode);
                    }}
                  />
                  <label htmlFor="qrCode" className="checkbox-label">
                    {t('qrCode')}
                  </label>
                </div>
              )}
              <label htmlFor="accessType">{t('accessType')}</label>
              <div className="mt-2vh">
                <div className="field-radiobutton mr-3">
                  <RadioButton
                    inputId="numberOfUses"
                    name="single"
                    value="numberOfUses"
                    onChange={() => {
                      void formik.setFieldValue('numberOfUses', 1);
                    }}
                    checked={formik.values.numberOfUses === 1}
                  />
                  <label htmlFor="cardType1">{t('single')}</label>
                </div>
                <div className="field-radiobutton mr-3">
                  <RadioButton
                    inputId="numberOfUses"
                    name="infinite"
                    value="numberOfUses"
                    onChange={() => {
                      void formik.setFieldValue('numberOfUses', -1);
                    }}
                    checked={formik.values.numberOfUses === -1}
                  />
                  <label htmlFor="cardType1">{t('unlimited')}</label>
                </div>
                <div className="field-radiobutton mr-3">
                  <RadioButton
                    inputId="numberOfUses"
                    name="finite"
                    value="numberOfUses"
                    onChange={() => {
                      void formik.setFieldValue('numberOfUses', 0);
                    }}
                    checked={formik.values.numberOfUses !== 1 && formik.values.numberOfUses !== -1}
                  />
                  <label htmlFor="cardType1">{t('limited')}</label>
                </div>
                {formik.values.numberOfUses !== 1 && formik.values.numberOfUses !== -1 && (
                  <div className="field">
                    <label htmlFor="limitNumberOfUses">{t('limitNumberOfUses')}</label>
                    <InputNumber
                      id="index"
                      value={formik.values.numberOfUses!}
                      mode="decimal"
                      onChange={(e: any) => {
                        void formik.setFieldValue('numberOfUses', e.value);
                      }}
                      className="w-10vw ml-1vw"
                    />
                    <FormErrorMessage fieldName="numberOfUses" formikInstance={formik} />
                  </div>
                )}
              </div>
              <div className="field">
                <label htmlFor="usageCounter">{t('usageCounter')}</label>
                <InputNumber
                  id="usageCounter"
                  value={formik.values.usageCounter}
                  disabled
                  mode="decimal"
                  className="w-12rem ml-1vw"
                />
              </div>
              <div className="field">
                <label htmlFor="contractor">{t('contractor')}</label>
                <CustomAutoComplete
                  id="contractor"
                  value={formik.values.contractor}
                  handler={formik.handleChange}
                  source={contractors}
                />
              </div>
              <div className="field">
                <label htmlFor="driver">{t('driver')}</label>
                <CustomAutoComplete
                  id="driver"
                  value={formik.values.driver}
                  handler={formik.handleChange}
                  source={drivers}
                />
              </div>
              <div className="field">
                <label htmlFor="vehicle">{t('vehicle')}</label>
                <CustomAutoComplete
                  id="vehicle"
                  value={formik.values.vehicle}
                  handler={formik.handleChange}
                  source={vehicles}
                />
              </div>
              {weighingConfiguration?.addTrailerRegistrationNumbersSupport && (
                <div className="field">
                  <label htmlFor="trailer">{t('trailer')}</label>
                  <CustomAutoComplete
                    id="trailer"
                    value={formik.values.trailer}
                    handler={formik.handleChange}
                    source={trailers}
                  />
                </div>
              )}
              <div className="field">
                <label htmlFor="product">{t('product')}</label>
                <CustomAutoComplete
                  id="product"
                  value={formik.values.product}
                  handler={formik.handleChange}
                  source={products}
                />
              </div>
              <AdditionalFields formikInstance={formik} dictionaries={dictionaries} />
              <div className="field">
                <label htmlFor="transactionType">{t('transactionType')}</label>
                <CustomAutoComplete
                  id="transactionType"
                  value={formik.values.transactionType}
                  handler={formik.handleChange}
                  source={transactionTypes}
                />
              </div>
              &nbsp;
              <div className="field-checkbox">
                <Checkbox
                  id="isActive"
                  checked={formik.values.isActive}
                  onChange={(e) => handleChangeField(e, 'checkbox')}
                />
                <label htmlFor="isActive" className="checkbox-label">
                  {t('isActive')}
                </label>
              </div>
            </div>
          </form>
        </Dialog>
      </FormErrorMessageScroller>
    </div>
  );
};

export default NewOrEditAccessCodeDialog;
