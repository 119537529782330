import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomButton } from '../../_shared/CustomComponents';
import DataTableFilterElements from '../../_shared/DataTableFilterElements';
import DeleteAccessCodeDialog from './components/DeleteAccessCodeDialog';
import NewOrEditAccessCodeDialog from './components/NewOrEditAccessCodeDialog';
import getPaginatorTemplate from '../../../utils/getPaginatorTemplate';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../types/reducer-state';
import accessCodesService from '../../../services/ScaleoApiServices/AccessCodesService';
import contractorsService from '../../../services/ScaleoApiServices/ContractorsService';
import driversService from '../../../services/ScaleoApiServices/DriversService';
import vehicleService from '../../../services/ScaleoApiServices/VehiclesService';
import productsService from '../../../services/ScaleoApiServices/ProductsService';
import weighingConfigurationService from '../../../services/ScaleoApiServices/WeighingConfigurationService';
import { weighingActions } from '../../../store/weighing-slice';
import dictionariesService from '../../../services/ScaleoApiServices/DictionariesService';
import { Dictionary } from '../../../types/dictionary';
import useTransactionType from '../../../utils/useTransactionType';
import { TFunction } from 'i18next';
import { TieredMenu } from 'primereact/tieredmenu';
import { getFileFromBuffer, printFileFromBuffer } from '../../../utils/getFile';
import { isModulesEnabled } from '../../../utils/modulesUtils';
import { Trailer } from '../../../types/trailer';
import { Contractor, Driver, Product, Vehicle } from '../../../types/weighing';
import trailersService from '../../../services/ScaleoApiServices/TrailersService';

const INIT_FILTERS = {
  code: null,
};

const INIT_PAGINATION_PARAMETERS = {
  order: {
    isAscending: true,
    orderColumn: 'name',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const INIT_PAGINATOR_STATE = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

const verifyFilters = (filters: any, t: TFunction) => {
  const newFilters = { ...filters };
  if (filters.isActive) {
    newFilters.isActive = filters.isActive === t('yes') ? true : false;
  }

  return newFilters;
};

const AccessCodes = () => {
  const { t } = useTranslation('datasetsAccessCodes');
  const dispatch = useDispatch();
  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const [filters, setFilters] = useState<{} | typeof INIT_FILTERS>(INIT_FILTERS);
  const [paginationParameters, setPaginationParameters] = useState(INIT_PAGINATION_PARAMETERS);
  const [paginatorState, setPaginatorState] = useState(INIT_PAGINATOR_STATE);
  const [isOpenNewOrEditDialog, setIsOpenNewOrEditDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [listData, setListData] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowsToDelete, setSelectedRowsToDelete] = useState<any | null>(null);
  const [selectedRowToEdit, setSelectedRowToEdit] = useState<any | null>(null);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const [contractors, setContractors] = useState<Contractor[] | null>(null);
  const [drivers, setDrivers] = useState<Driver[] | null>(null);
  const [vehicles, setVehicles] = useState<Vehicle[] | null>(null);
  const [trailers, setTrailers] = useState<Trailer[] | null>(null);
  const [products, setProducts] = useState<Product[] | null>(null);
  const [dictionaries, setDictionaries] = useState<Dictionary[] | null>(null);
  const [additionalFields, setAdditionalFields] = useState<any | null>(null);
  const { ALL_TRANSACTION_TYPES } = useTransactionType();

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const refreshListData = useCallback(() => {
    Promise.all([
      accessCodesService.getAccessCodesSearch(verifyFilters(filters, t), paginationParameters, globalFilter),
      contractorsService.getContractors(),
      driversService.getDrivers(),
      vehicleService.getVehicles(),
      productsService.getProducts(),
      weighingConfigurationService.getConfiguration(),
      dictionariesService.getDictionaries(),
      trailersService.getTrailers(),
    ])
      .then((response) => {
        setListData(
          response[0].data.items.map((item: any) => ({ ...item, isPinHidden: true, isCardNumberHidden: true })),
        );
        setContractors(response[1].data);
        setDrivers(response[2].data);
        setVehicles(response[3].data);
        setProducts(response[4].data);
        setPaginatorState({
          ...paginatorState,
          totalPages: response[0].data.totalPagesNumber ?? 1,
          currentPage:
            paginatorState.currentPage > (response[0].data.totalPagesNumber ?? 1) ? 1 : paginatorState.currentPage,
        });
        setAdditionalFields(response[5].data?.additionalFields);
        setDictionaries(response[6].data);
        setTrailers(response[7].data);
        dispatch(weighingActions.weighingConfigurationFetched(response[5].data));
      })
      .catch(() => {
        setListData([]);
      });
  }, [filters, paginationParameters, globalFilter, dispatch, isOpenNewOrEditDialog]);

  useEffect(() => {
    refreshListData();
  }, [loggedUserContext.currentCustomer?.id, refreshListData]);

  const handleClickClearFilters = () => {
    setFilters({});
    setGlobalFilter('');
  };

  const header = (
    <div className="table-header text-2xl p-2">
      {t('header')} &#160;&#160;
      <div className="flex">
        <Button
          label={t('clearFilters')}
          icon="pi pi-filter-slash"
          outlined
          className="mr-2"
          onClick={handleClickClearFilters}
        />
        <span>
          <DataTableFilterElements.Text
            initialValue={globalFilter}
            onChangeFilter={setGlobalFilter}
            name={'global'}
            placeholder={t('searchPlaceholder')}
            isSearchIcon
          />
        </span>
      </div>
    </div>
  );

  const leftToolbarTemplate = () => {
    const handleClickNew = () => {
      setSelectedRowToEdit(null);
      setIsOpenNewOrEditDialog(true);
    };

    const handleClickDeleteAccessCode = () => {
      setSelectedRowsToDelete(selectedRows);
      setIsOpenDeleteDialog(true);
    };

    return (
      <>
        <Button label={t('addButton')} icon="pi pi-plus" severity="success" className="mr-2" onClick={handleClickNew} />
        <CustomButton
          label={t('deleteButton')}
          icon="pi pi-trash"
          severity="danger"
          onClick={handleClickDeleteAccessCode}
          disabled={!selectedRows || !selectedRows.length || selectedRows.map((row) => row?.isUsed).includes(true)}
          tooltip={t('tooltip')}
          name={undefined}
          dataFlag={undefined}
          type={undefined}
        />
      </>
    );
  };

  const accessTypeBodyTemplate = (rowData: any) => {
    switch (rowData.numberOfUses) {
      case -1:
        return <>{t('unlimited')}</>;
      case 1:
        return <>{t('single')}</>;
      default:
        return <>{t('limited')}</>;
    }
  };

  const accessStatusBodyTemplate = (rowData: any) => {
    if (rowData.numberOfUses === -1) {
      return (
        <>
          <i className="pi pi-check" />
        </>
      );
    } else {
      return (
        <>
          <i className={rowData.numberOfUses > rowData.usageCounter ? 'pi pi-times' : 'pi pi-check'} />
        </>
      );
    }
  };

  const constraitBodyTemplate = (rowData: any) => {
    const containsAdditionaFields = Object.keys(rowData.additionalFields).length > 0;

    if (
      !rowData.contractorId &&
      !rowData.driverId &&
      !rowData.vehicleId &&
      !rowData.trailerId &&
      !rowData.productId &&
      !containsAdditionaFields &&
      !rowData.transactionType
    ) {
      return (
        <>
          <span className="column-title">{t('name')}</span>
          {t('noConstrait')}
        </>
      );
    }
    const contractor = contractors?.find((c) => c.id === rowData.contractorId)?.name;
    const driver = drivers?.find((c) => c.id === rowData.driverId)?.name;
    const vehicle = vehicles?.find((v) => v.id === rowData.vehicleId)?.registrationNumber;
    const trailer = trailers?.find((t) => t.id === rowData.trailerId)?.registrationNumber;
    const product = products?.find((p) => p.id === rowData.productId)?.name;
    const transactionType = ALL_TRANSACTION_TYPES?.find((t) => t.id === rowData.transactionType)?.name;

    const additionalFieldsConstrait = Object.entries(rowData.additionalFields).map((e) => (
      <>
        {additionalFields?.find((a: any) => a.id === e[0])?.name}:{e[1]}
        <br />
      </>
    ));

    return (
      <>
        <span className="column-title">{t('name')}</span>
        {contractor && `${t('contractor')}: ${contractor}`}
        {contractor && <br />}
        {driver && `${t('driver')}: ${driver}`}
        {driver && <br />}
        {vehicle && `${t('vehicle')}: ${vehicle}`}
        {vehicle && <br />}
        {trailer && `${t('trailer')}: ${trailer}`}
        {trailer && <br />}
        {product && `${t('product')}: ${product}`}
        {product && <br />}
        {containsAdditionaFields && additionalFieldsConstrait}
        {transactionType && `${t('transactionType')}: ${transactionType}`}
      </>
    );
  };

  const generateQrCode = async (id: string) => {
    const qrCodeResponse = await accessCodesService.getQrCode(id);

    return qrCodeResponse.data;
  };

  const viewQrCode = async (id: string) => {
    const qrCodeBuffer = await generateQrCode(id);

    getFileFromBuffer(qrCodeBuffer, 'application/pdf');
  };

  const downloadQrCode = async (id: string) => {
    const qrCodeBuffer = await generateQrCode(id);

    getFileFromBuffer(qrCodeBuffer, 'application/pdf', 'qr.pdf');
  };

  const printQrCode = async (id: string) => {
    const qrCodeBuffer = await generateQrCode(id);

    printFileFromBuffer(qrCodeBuffer, 'application/pdf');
  };

  const actionBodyTemplate = (rowData: any, { rowIndex }: any) => (
    <ActionBodyTemplateComponent rowData={rowData} rowIndex={rowIndex} />
  );

  const ActionBodyTemplateComponent = ({ rowData }: any) => {
    const items = [] as any[];
    const menu = useRef<any>(null);

    const handleClickEditAccessCodeRow = () => {
      setSelectedRowToEdit(rowData);
      setSelectedRows([rowData]);
      setIsOpenNewOrEditDialog(true);
    };

    const handleClickDeleteAccessCodeRow = () => {
      setSelectedRowsToDelete(rowData);
      setSelectedRows([rowData]);
      setIsOpenDeleteDialog(true);
    };

    const handleClickItem = (value: string) => {
      switch (value) {
        case 'edit':
          handleClickEditAccessCodeRow();
          break;
        case 'update':
          handleClickDeleteAccessCodeRow();
          break;
        case 'view':
          viewQrCode(rowData.id);
          break;
        case 'download':
          downloadQrCode(rowData.id);
          break;
        case 'print':
          printQrCode(rowData.id);
          break;
        default:
          break;
      }
    };

    const prepareItems = () => {
      items.push({ label: t('actionsEdit'), command: () => handleClickItem('edit'), icon: 'pi pi-pencil' });
      items.push({ label: t('actionsDelete'), command: () => handleClickItem('delete'), icon: 'pi pi-trash' });

      if (rowData.qrCode) {
        const qrItems = [] as any[];
        qrItems.push({ label: t('actionsView'), command: () => handleClickItem('view'), icon: 'pi pi-eye' });
        qrItems.push({
          label: t('actionsDownload'),
          command: () => handleClickItem('download'),
          icon: 'pi pi-download',
        });
        qrItems.push({ label: t('actionsPrint'), command: () => handleClickItem('print'), icon: 'pi pi-print' });

        items.unshift({ label: t('actionsQrCode'), items: qrItems, icon: 'pi pi-bars' });
      }
    };
    prepareItems();

    return (
      <div className="actions flex justify-center-end">
        {!!items.length && (
          <>
            <TieredMenu model={items} popup ref={menu} />
            <Button label={t('options')} onClick={(event) => menu.current?.toggle(event)} />
          </>
        )}
      </div>
    );
  };

  const handleCloseNewOrEditDialog = (changes: any) => {
    setIsOpenNewOrEditDialog(false);
    if (changes) refreshListData();
  };

  const handleCloseDeleteDialog = (changes: any) => {
    setIsOpenDeleteDialog(false);
    if (changes) refreshListData();
  };

  const handleChangeFilter = (value: any, name: any) => {
    const newFilters = { ...filters };

    newFilters[name as keyof typeof newFilters] = value;

    setFilters(newFilters);
  };

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const isActiveFieldTemplate = (rowData: any) => {
    return <>{<i className={rowData.isActive ? 'pi pi-check' : 'pi pi-times'} />}</>;
  };

  const qrCodeFieldTemplate = (rowData: any) => {
    return <>{<i className={rowData.qrCode ? 'pi pi-check' : 'pi pi-times'} />}</>;
  };

  const showHideCardNumberTemplate = (rowData: any) => {
    if (!rowData.cardNumber) {
      return <></>;
    }

    const showOrHide = () => {
      const newData = listData.map((val: any) =>
        val.id === rowData.id ? { ...val, isCardNumberHidden: !val.isCardNumberHidden } : val,
      );
      setListData(newData);
    };

    return (
      <>
        {`${rowData.isCardNumberHidden ? '*'.repeat(rowData.cardNumber.length) : rowData.cardNumber} `}
        <i
          onClick={showOrHide}
          className={`${rowData?.isCardNumberHidden ? 'pi pi-eye' : 'pi pi-eye-slash'} cursor-pointer`}
        />
      </>
    );
  };

  const showHidePinTemplate = (rowData: any) => {
    if (!rowData.pin) {
      return <></>;
    }

    const showOrHide = () => {
      const newData = listData.map((val: any) =>
        val.id === rowData.id ? { ...val, isPinHidden: !val.isPinHidden } : val,
      );
      setListData(newData);
    };

    return (
      <>
        {`${rowData.isPinHidden ? '*'.repeat(rowData.pin.length) : rowData.pin} `}
        <i
          onClick={showOrHide}
          className={`${rowData?.isPinHidden ? 'pi pi-eye' : 'pi pi-eye-slash'} cursor-pointer`}
        />
      </>
    );
  };

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          {isOpenNewOrEditDialog && (
            <NewOrEditAccessCodeDialog
              visible={isOpenNewOrEditDialog}
              closeDialog={handleCloseNewOrEditDialog}
              item={selectedRowToEdit}
              contractors={contractors ?? []}
              drivers={drivers ?? []}
              vehicles={vehicles?.map((v) => ({ ...v, name: v.registrationNumber })) ?? []}
              trailers={trailers?.map((v) => ({ ...v, name: v.registrationNumber })) ?? []}
              products={products ?? []}
              dictionaries={dictionaries}
              transactionTypes={ALL_TRANSACTION_TYPES}
            />
          )}
          {isOpenDeleteDialog && (
            <DeleteAccessCodeDialog
              visible={isOpenDeleteDialog}
              closeDialog={handleCloseDeleteDialog}
              selectedRows={selectedRowsToDelete}
            />
          )}
          <Toolbar start={leftToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                responsiveLayout="scroll"
                ref={dt}
                value={listData}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                removableSort
                rowHover
                rows={paginatorState.rows}
                paginator
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState) as any}
                emptyMessage={t('noData')}
                onSort={handleSort}
                selectionMode="checkbox"
                selection={selectedRows}
                onSelectionChange={(e: any) => setSelectedRows(e.value)}
                stripedRows
                filterDisplay="row"
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column
                  field="name"
                  header={t('name')}
                  sortable
                  filter
                  showFilterMenu={false}
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={(filters as any).code}
                      onChangeFilter={handleChangeFilter}
                      name="name"
                      placeholder={t('filters')}
                    />
                  }
                />
                <Column
                  field="cardNumber"
                  header={t('cardNumber')}
                  sortable
                  filter
                  showFilterMenu={false}
                  body={showHideCardNumberTemplate}
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={(filters as any).code}
                      onChangeFilter={handleChangeFilter}
                      name="cardNumber"
                      placeholder={t('filters')}
                    />
                  }
                />
                <Column
                  field="pin"
                  header="Pin"
                  sortable
                  filter
                  showFilterMenu={false}
                  body={showHidePinTemplate}
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={(filters as any).code}
                      onChangeFilter={handleChangeFilter}
                      name="pin"
                      placeholder={t('filters')}
                    />
                  }
                />
                {isModulesEnabled(['QR_ACCESS_CONTROL'], loggedUserContext.currentCustomer?.subscriptionModules) && (
                  <Column field="qrCode" header={t('qrCode')} body={qrCodeFieldTemplate} />
                )}
                <Column
                  field="isActive"
                  header={t('isActive')}
                  sortable
                  filter
                  showFilterMenu={false}
                  body={isActiveFieldTemplate}
                  filterElement={
                    <DataTableFilterElements.Dropdown
                      initialValue={(filters as any).isActive}
                      options={[t('yes'), t('no')]}
                      onChangeFilter={handleChangeFilter}
                      name="isActive"
                      placeholder={t('filters')}
                    />
                  }
                />
                <Column field="accessType" header={t('accessType')} body={accessTypeBodyTemplate} />
                <Column field="accessType" header={t('accessStatus')} body={accessStatusBodyTemplate} />
                <Column field="constrait" header={t('constrait')} body={constraitBodyTemplate} />
                <Column body={actionBodyTemplate} className="w-100px text-center"></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessCodes;
