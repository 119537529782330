import { Fieldset } from 'primereact/fieldset';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation('contactComponent');

  const dealer = {
    name: 'MIW GROUP Tomasz Kogut SP. z o.o.',
    adress: {
      street: 'ul. Smaków 9',
      code: '49-318',
      city: 'Skarbimierz-Osiedle',
      county: 'pow. Brzeg',
      province: 'woj. opolskie',
    },
    phone: '77 411 39 28',
    email: 'biuro@miary-wagi.pl',
    page: 'http://www.miary-wagi.pl',
    logo: 'assets/layout/images/miw-logo.png',
  };

  return (
    <div className="card pb-70px">
      <Fieldset legend={t('header')}>
        <div className="p-fluid formgrid grid">
          <div className="col-12 sm:col-6 lg:col-5 lg:col-offset-1">
            <div className="m-2 mb:col-4">
              <h4>{dealer.name}</h4>
              {dealer.adress && (
                <div>
                  <i className="pi pi-fw pi-map-marker float-left"></i>
                  <div className="float-left">
                    {dealer.adress.street}
                    {(dealer.adress.code || dealer.adress.city) && <br />} {dealer.adress.code} {dealer.adress.city}
                    {dealer.adress.county && <br />} {dealer.adress.county}
                    {dealer.adress.province && <br />} {dealer.adress.province}
                  </div>
                </div>
              )}
            </div>
            <div className="pt-6px clear-both">
              {dealer.phone && (
                <div className="p-1">
                  <i className="pi pi-fw pi-phone"></i> {dealer.phone}
                </div>
              )}
              {dealer.email && (
                <div className="p-1">
                  <i className="pi pi-fw pi-envelope"></i> {dealer.email}
                </div>
              )}
              {dealer.page && (
                <div className="p-1">
                  <i className="pi pi-fw pi-globe"></i> {dealer.page}
                </div>
              )}
              {dealer.logo && (
                <div className="p-4">
                  <img className="w-100" src={dealer.logo} alt="logo" />
                </div>
              )}
            </div>
          </div>
          <div className="col-12 sm:col-6 lg:col-4 lg:col-offset-1">
            <div className="m-2 mb-4">
              <h4>{t('support')}</h4>
              <div className="p-1">
                <i className="pi pi-fw pi-envelope"></i> support@scaleo.eu
              </div>
              <div className="p-1">
                <i className="pi pi-fw pi-envelope"></i> rodo@scaleo.eu
              </div>
            </div>
            <div className="mb-4 mt-5vh">
              <h4>{t('sale')}</h4>
              <div className="p-1">
                <i className="pi pi-fw pi-envelope"></i> scaleo@scaleo.eu
              </div>
              <i className="font-size-10px">{t('saleProposals')}</i>
            </div>
          </div>
        </div>
      </Fieldset>
    </div>
  );
};
export default Contact;
