import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { useTranslation } from 'react-i18next';

interface MeasurementButtonsProps {
  formik: any;
  type: string;
  index: number;
}

export const MeasurementButtons = ({ formik, type, index }: MeasurementButtonsProps) => {
  const { t } = useTranslation('weighingWeighingList');

  return (
    <div className="inline-flex">
      <div className={`minusWrapper-${index}`}>
        <div className={`field col-4 md:col-3 minus-${index}`}>
          <Placeholder />
          <Button
            type="button"
            icon="pi pi-minus"
            severity="danger"
            onClick={() => {
              const measurements = formik.values.measurements;
              measurements[index].toBeDeleted = true;
              if (!measurements[index].id) {
                measurements.splice(index, 1);
              }
              formik.setFieldValue('measurements', measurements);
            }}
            disabled={type === 'MEASURED'}
          />
          <Tooltip target={`.minusWrapper-${index}`} position="left">
            {type === 'MEASURED' ? t('Można usuwać tylko zadeklarowane pomiary') : t('Usuń pomiar')}
          </Tooltip>
        </div>
      </div>
      <div className={`upWrapper-${index}`}>
        <div className={`field col-4 md:col-3 up-${index}`}>
          <Placeholder />
          <Button
            type="button"
            icon="pi pi-angle-up"
            severity="info"
            onClick={() => {
              const measurements = formik.values.measurements;
              swapElements(measurements, index, index - 1);
              formik.setFieldValue('measurements', measurements);
            }}
            className="ml--1vw"
            disabled={index === 0}
          />
          <Tooltip target={`.upWrapper-${index}`} position="left">
            {index === 0 ? t('Nie można przesunąć w gorę pierwszego pomiaru') : t('Przesuń w górę')}
          </Tooltip>
        </div>
      </div>
      <div className={`downWrapper-${index}`}>
        <div className={`field col-4 md:col-3 down-${index}`}>
          <Placeholder />
          <Button
            type="button"
            icon="pi pi-angle-down"
            severity="info"
            onClick={() => {
              const measurements = formik.values.measurements;
              swapElements(measurements, index, index + 1);
              formik.setFieldValue('measurements', measurements);
            }}
            className="ml--1vw"
            disabled={index === formik.values.measurements.length - 1}
          />
          <Tooltip target={`.downWrapper-${index}`} position="left">
            {index === formik.values.measurements.length - 1
              ? t('Nie można przesunąć w dół ostatniego pomiaru')
              : t('Przesuń w dół')}
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const swapElements = (array: any[], index1: number, index2: number) => {
  [array[index1], array[index2]] = [array[index2], array[index1]];
};

const Placeholder = () => (
  <label htmlFor="scale" className="text-white">
    1
  </label>
);
