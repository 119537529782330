import { InputSwitch } from 'primereact/inputswitch';
import { useSelector } from 'react-redux';
import { isModulesEnabled } from '../../../../utils/modulesUtils';
import { CustomAutoComplete } from '../../../_shared/CustomComponents';
import { ReducerState } from '../../../../types/reducer-state';
import { useTranslation } from 'react-i18next';

interface AccessControlPanelProps {
  formik: any;
}

export const AccessControlPanel = ({ formik }: AccessControlPanelProps) => {
  const { t } = useTranslation('datasetsVehicles');

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const defaultAccessControlType = {
    name: t('default'),
    id: 'Default',
  };

  const arrivalNotificationAccessControlType = {
    name: t('arrivalNotification'),
    id: 'ArrivalNotification',
  };

  return isModulesEnabled(['ACCESS_CONTROL'], loggedUserContext.currentCustomer?.subscriptionModules) ? (
    <>
      <>
        {isModulesEnabled(
          ['ACCESS_CONTROL_ARRIVAL_NOTIFICATION'],
          loggedUserContext.currentCustomer?.subscriptionModules,
        ) && (
          <>
            <div className="font-bold my-4">{t('acessControlMode')}</div>
            <div className="field">
              <CustomAutoComplete
                id="requireArrivalNotification"
                value={formik.values.requireArrivalNotification ? t('arrivalNotification') : t('default')}
                handler={(e: any) =>
                  formik.setFieldValue('requireArrivalNotification', e.value?.id === 'ArrivalNotification')
                }
                source={[defaultAccessControlType, arrivalNotificationAccessControlType]}
                lookupField={undefined}
                inputStyle={undefined}
                customSearchBranch={undefined}
                customItemTemplate={undefined}
                placeholder={undefined}
                scrollHeight={undefined}
                tooltip={undefined}
                disabled={undefined}
                forceSelection={undefined}
                onBlur={undefined}
              />
            </div>
          </>
        )}
        {!formik.values.requireArrivalNotification && (
          <>
            <div className="font-bold my-4">{t('accessControl')}</div>
            <div className="field">
              <label htmlFor="allowEntry">{t('allowEntry')}</label>
              <InputSwitch
                className="float-right"
                id="allowEntry"
                checked={formik.values.allowEntry}
                onChange={(e) => {
                  void formik.handleChange(e);
                  if (!e.target.value) {
                    void formik.setFieldValue('entryWeighingRequired', false);
                    void formik.setFieldValue('entryConfirmationRequired', false);
                  }
                }}
              />
            </div>
            <div className="field">
              <label htmlFor="allowExit">{t('allowExit')}</label>
              <InputSwitch
                className="float-right"
                id="allowExit"
                checked={formik.values.allowExit}
                onChange={(e) => {
                  void formik.handleChange(e);
                  if (!e.target.value) {
                    void formik.setFieldValue('exitWeighingRequired', false);
                    void formik.setFieldValue('exitConfirmationRequired', false);
                  }
                }}
              />
            </div>
          </>
        )}
      </>
      {(formik.values.allowEntry || formik.values.requireArrivalNotification) && (
        <>
          <div className="font-bold my-4">{t('entry')}</div>
          <div className="field">
            <label htmlFor="entryWeighingRequired">{t('weighingRequired')}</label>
            <InputSwitch
              className="float-right"
              id="entryWeighingRequired"
              checked={formik.values.entryWeighingRequired}
              onChange={formik.handleChange}
            />
          </div>
          <div className="field">
            <label htmlFor="entryConfirmationRequired">{t('confirmationRequired')}</label>
            <InputSwitch
              className="float-right"
              id="entryConfirmationRequired"
              checked={formik.values.entryConfirmationRequired}
              onChange={formik.handleChange}
            />
          </div>
        </>
      )}
      {(formik.values.allowExit || formik.values.requireArrivalNotification) && (
        <>
          <div className="font-bold my-4">{t('exit')}</div>
          <div className="field">
            <label htmlFor="exitWeighingRequired">{t('weighingRequired')}</label>
            <InputSwitch
              className="float-right"
              id="exitWeighingRequired"
              checked={formik.values.exitWeighingRequired}
              onChange={formik.handleChange}
            />
          </div>
          <div className="field">
            <label htmlFor="exitConfirmationRequired">{t('confirmationRequired')}</label>
            <InputSwitch
              className="float-right"
              id="exitConfirmationRequired"
              checked={formik.values.exitConfirmationRequired}
              onChange={formik.handleChange}
            />
          </div>
        </>
      )}
    </>
  ) : (
    <></>
  );
};
