import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import locationsService from '../../../../services/ScaleoApiServices/LocationsService';
import '../../../DataTable.css';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../../store/user-slice';
import { useTranslation, Trans } from 'react-i18next';

interface DeleteLocationDialogProps {
  visible: boolean;
  closeDialog: () => void;
  selectedRow: any;
}

const DeleteLocationDialog = ({ visible, closeDialog, selectedRow }: DeleteLocationDialogProps) => {
  const { t } = useTranslation('managementLocations');

  const dispatch = useDispatch();

  const handleCloseDialog = () => {
    closeDialog();
  };

  const handleConfirm = async () => {
    await locationsService.removeLocation(selectedRow.id);

    dispatch(userActions.shouldRefreshLoggedUserContext(true));
    handleCloseDialog();
  };

  const dialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={handleCloseDialog} />
      <Button type="button" label={t('deleteButton')} icon="pi pi-check" text onClick={handleConfirm} />
    </>
  );

  return (
    <Dialog
      visible={visible}
      breakpoints={{ '896px': '90vw' }}
      header={
        <span className="flex align-items-center">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
          {t('confirm')}
        </span>
      }
      modal
      className="p-fluid min-width-450px"
      footer={dialogFooter}
      onHide={handleCloseDialog}
    >
      {selectedRow ? (
        <div className="confirmation-content">
          <span>
            <Trans t={t} i18nKey="contentDetail" values={{ location: selectedRow?.name }} />
          </span>
        </div>
      ) : (
        ''
      )}
    </Dialog>
  );
};

export default DeleteLocationDialog;
