import { useCallback, useState } from 'react';
import weighingConfigurationService from '../../services/ScaleoApiServices/WeighingConfigurationService';

const initialValues = {
  shouldIncludePosReceiptCustomFooter: false,
  posReceiptCustomFooter: '',
  posReceiptNumberOfCopies: 1,
  posReceiptFieldsOrder: [],
  additionalFields: [],
};

const usePosReceiptConfigurationData = () => {
  const [data, setData] = useState(initialValues);
  const [stringifiedData, setStringifiedData] = useState('[]');

  const fetchData = useCallback(async () => {
    const response = await weighingConfigurationService.getConfigurationReceipts();
    if (response.data) {
      const isReceiptNotEmpty =
        response.data.posReceiptCustomFooter && response.data.posReceiptCustomFooter.trim() !== '';

      const responseData = {
        shouldIncludePosReceiptCustomFooter: isReceiptNotEmpty,
        posReceiptCustomFooter: response.data.posReceiptCustomFooter,
        posReceiptNumberOfCopies: response.data.posReceiptNumberOfCopies,
        additionalFields: response.data.additionalFields,
        posReceiptFieldsOrder: response.data.posReceiptFieldsOrder,
      };

      setData(responseData);
      setStringifiedData(JSON.stringify(responseData));
    }
  }, []);

  return { data, stringifiedData, fetchData };
};

export default usePosReceiptConfigurationData;
