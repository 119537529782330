import classNames from 'classnames';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import { addLocale, locale } from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import './App.scss';
import AppMenu from './AppMenu';
import AppTopbar from './AppTopbar';
import { AxiosConfigContainer } from './components/AxiosConfig';
import Bdo from './components/Bdo';
import BillingData from './components/BillingData/BillingData';
import { Contractors } from './components/DataSets/Contractors/Contractors';
import { Drivers } from './components/DataSets/Drivers/Drivers';
import { Products } from './components/DataSets/Products/Products';
import { Vehicles } from './components/DataSets/Vehicles/Vehicles';
import Management from './components/Management';
import UserProfile from './components/UserProfile/UserProfile';
import Contact from './components/Contact/Contact';
import HandlingWeighings from './components/Weighing/HandlingWeighings/HandlingWeighings';
import WeighingsList from './components/Weighing/WeighingsList/WeighingsList';
import ChangesConfirmationDialog from './components/_shared/ChangesConfirmationDialog';
import useModulesConfiguration from './utils/useModulesConfiguration';
import usersService from './services/ScaleoApiServices/UsersService';
import { scaleoModeContext } from './services/ScaleoModeContext';
import { appConfigActions } from './store/appConfig-slice';
import { userActions } from './store/user-slice';
import { useTranslation } from 'react-i18next';
import { hasOneOfPermissions, hasPermissions } from './utils/permissionsUtils';
import { isModulesEnabled, isOneOfModulesEnabled } from './utils/modulesUtils';
import { uiActions } from './store/ui-slice';
import { blockBodyScroll, isDesktop, isMobile, unblockBodyScroll } from './utils/uiUtils';
import { ReducerState } from './types/reducer-state';
import AccessCodes from './components/DataSets/AccessCodes/AccessCodes';
import WeighingSummaryDialog from './components/Weighing/HandlingWeighings/components/WeighingSummaryDialog/WeighingSummaryDialog';
import ToastController from './components/Weighing/HandlingWeighings/components/ToastController';
import { SoundContainer } from './components/SoundContainer';
import AccessControlEventLogPage from './menu-pages/access-control/AccessControlEventLogPage';
import { ConfirmationsPanelDialog } from './components/AccessControl/ConfirmationsPanelDialog/ConfirmationsPanelDialog';
import { UpdateLicenseKey } from './pages/UpdateLicenseKey';
import { VerifyLicenseKey } from './pages/VerifyLicenseKey';
import installationService from './services/ScaleoApiServices/InstallationService';
import AccessControlArrivalNotificationPage from './menu-pages/access-control/AccessControlArrivalNotificationPage';
import { Containers } from './components/DataSets/Containers/Containers';
import { Trailers } from './components/DataSets/Trailers/Trailers';
import DowntimeInfoModule from './components/_shared/DowntimeInfoModule/DowntimeInfoModule';
import Version from './components/Version/Version';
import Dictionaries from './components/DataSets/Dictionaries/Dictionaries';

scaleoModeContext().appConfigurer();

interface AppProps {
  publishWeighCommandHandler: (topic: string, data: any) => void;
  publishScaleCommandHandler: (topic: string, data: any) => void;
}

const App = (props: AppProps) => {
  const { t } = useTranslation('app');

  addLocale('pl', t('localeObject', { returnObjects: true }));
  locale('pl');

  const modulesConfiguration = useModulesConfiguration();
  const [layoutMode, setLayoutMode] = useState('static');
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
  const [inlineUser, setInlineUser] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState<any>(null);
  const [inlineUserMenuActive, setInlineUserMenuActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [configActive, setConfigActive] = useState(false);
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const [expired, setExpired] = useState(false);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const shouldRefreshLoggedUserContext = useSelector(
    (state: ReducerState) => state.user.shouldRefreshLoggedUserContext,
  );
  const isMenuOpen = useSelector((state: ReducerState) => state.ui.isMenuOpen);
  const { publishScaleCommandHandler, publishWeighCommandHandler } = props;

  const fetchUserContext = useCallback(() => {
    usersService.getLoggedUserContext().then((response) => {
      const _loggedUserContext = response.data;

      if (!_loggedUserContext && process.env.REACT_APP_SCALEO_MODE === 'OFFLINE') {
        setExpired(true);
        return;
      }

      const hasAnyCustomers = _loggedUserContext.customers && _loggedUserContext.customers.length > 0;
      dispatch(userActions.setLoggedUserCustomers(hasAnyCustomers));

      localStorage.setItem('hasAnyCustomers', hasAnyCustomers?.toString());

      dispatch(userActions.shouldRefreshLoggedUserContext(false));
      dispatch(userActions.setLoggedUserContext(_loggedUserContext));
      dispatch(appConfigActions.setUnit(_loggedUserContext?.weighingConfiguration?.unit));
      setInitialized(true);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!expired) {
      fetchUserContext();
    }
  }, [fetchUserContext, loggedUserContext?.currentLocationId, expired]);

  useEffect(() => {
    if (shouldRefreshLoggedUserContext) {
      fetchUserContext();
    }
  }, [fetchUserContext, shouldRefreshLoggedUserContext]);

  const locationSelected = () => {
    return loggedUserContext.currentLocationId !== null;
  };

  const bdoEnabled = () => {
    return modulesConfiguration.bdo === 'enabled';
  };

  const menu = [
    {
      label: t('weighing'),
      icon: 'pi pi-fw pi-home',
      visible:
        isModulesEnabled(['WEIGHING'], loggedUserContext.currentCustomer?.subscriptionModules) &&
        locationSelected() &&
        hasOneOfPermissions(['WEIGHING', 'WEIGHINGS_REPORTS'], loggedUserContext.permissions),
      items: [
        {
          label: t('handlingWeighings'),
          icon: 'pi pi-fw pi-home',
          visible: hasPermissions(['WEIGHING'], loggedUserContext.permissions),
          to: '/',
        },
        {
          label: t('weighings'),
          icon: 'pi pi-fw pi-list',
          visible: hasOneOfPermissions(['WEIGHING', 'WEIGHINGS_REPORTS'], loggedUserContext.permissions),
          to: '/weighings',
        },
      ],
    },
    {
      label: t('accessControl'),
      icon: 'pi pi-fw pi-home',
      visible:
        isModulesEnabled(['ACCESS_CONTROL'], loggedUserContext.currentCustomer?.subscriptionModules) &&
        locationSelected() &&
        hasOneOfPermissions(['ACCESS_CONTROL_EVENT_LOG'], loggedUserContext.permissions),
      items: [
        {
          label: t('eventLog'),
          icon: 'pi pi-fw pi-book',
          visible: hasPermissions(['ACCESS_CONTROL_EVENT_LOG'], loggedUserContext.permissions),
          to: '/event-log',
        },
        {
          label: t('arrivalNotifications'),
          icon: 'pi pi-fw pi-book',
          visible:
            hasPermissions(['ACCESS_CONTROL_ARRIVAL_NOTIFICATION'], loggedUserContext.permissions) &&
            isModulesEnabled(
              ['ACCESS_CONTROL_ARRIVAL_NOTIFICATION'],
              loggedUserContext.currentCustomer?.subscriptionModules,
            ),
          to: '/arrival-notifications',
        },
      ],
    },
    {
      label: t('bdo'),
      icon: 'pi pi-fw pi-trash',
      visible:
        isModulesEnabled(['BDO'], loggedUserContext.currentCustomer?.subscriptionModules) &&
        locationSelected() &&
        bdoEnabled() &&
        hasPermissions(['WASTE_REGISTER'], loggedUserContext.permissions),
      items: [
        {
          label: t('bdoKpo'),
          icon: 'pi po-fw pi-filter-slash',
          items: [
            {
              label: t('bdoKpoSender'),
              icon: 'pi po-fw pi-list',
              to: '/bdo-kpo-sender',
            },
            {
              label: t('bdoKpoCarrier'),
              icon: 'pi po-fw pi-list',
              to: '/bdo-kpo-carrier',
            },
            {
              label: t('bdoKpoReceiver'),
              icon: 'pi po-fw pi-list',
              to: '/bdo-kpo-receiver',
            },
          ],
        },
        {
          label: t('bdoKpok'),
          icon: 'pi po-fw pi-filter',
          items: [
            {
              label: t('bdoKpokSender'),
              icon: 'pi po-fw pi-list',
              to: '/bdo-kpok-sender',
            },
            {
              label: t('bdoKpokCarrier'),
              icon: 'pi po-fw pi-list',
              to: '/bdo-kpok-carrier',
            },
            {
              label: t('bdoKpokReceiver'),
              icon: 'pi po-fw pi-list',
              to: '/bdo-kpok-receiver',
            },
          ],
        },
      ],
    },
    {
      label: t('dataSets'),
      icon: 'pi pi-fw pi-th-large',
      visible:
        isModulesEnabled(['WEIGHING'], loggedUserContext.currentCustomer?.subscriptionModules) &&
        locationSelected() &&
        hasPermissions(['DATASETS_EDIT'], loggedUserContext.permissions),
      items: [
        {
          label: t('vehicles'),
          icon: 'pi po-fw pi-directions',
          to: '/vehicles',
        },
        {
          label: t('contractors'),
          icon: 'pi po-fw pi-users',
          to: '/contractors',
        },
        {
          label: t('products'),
          icon: 'pi po-fw pi-shopping-cart',
          to: '/products',
        },
        {
          label: t('drivers'),
          icon: 'pi po-fw pi-id-card',
          to: '/drivers',
        },
        {
          label: t('accessCodes'),
          icon: 'pi po-fw pi-credit-card',
          visible:
            isModulesEnabled(['RFID_SERVICE'], loggedUserContext.currentCustomer?.subscriptionModules) &&
            hasPermissions(['ACCESS_CODES_MANAGEMENT'], loggedUserContext.permissions),
          to: '/access-codes',
        },
        {
          label: t('containers'),
          icon: 'pi po-fw pi-ticket',
          visible: isModulesEnabled(['CONTAINERS'], loggedUserContext.currentCustomer?.subscriptionModules),
          to: '/containers',
        },
        loggedUserContext.weighingConfiguration?.addTrailerRegistrationNumbersSupport && {
          label: t('trailers'),
          icon: 'pi po-fw pi-shopping-cart',
          visible: true,
          to: '/trailers',
        },
        {
          label: t('dictionaries'),
          icon: 'pi po-fw pi-book',
          visible:
            isModulesEnabled(['WEIGHING'], loggedUserContext.currentCustomer?.subscriptionModules) &&
            hasPermissions(['DICTIONARIES_MANAGEMENT'], loggedUserContext.permissions),
          to: '/dictionaries',
        },
      ].filter((b) => b),
    },
    {
      label: t('management'),
      icon: 'pi pi-fw pi-map',
      visible: hasOneOfPermissions(
        [
          'LOCATIONS_MANAGEMENT',
          'USERS_MANAGEMENT',
          'ROLES_MANAGEMENT',
          'NODE_MODELS',
          'NODES_MANAGEMENT',
          'EDGE_DEVICES_MANAGEMENT',
          'SYSTEM_CONFIGURATION',
          'DATA_IMPORT_EXPORT',
        ],
        loggedUserContext.permissions,
      ),
      items: [
        {
          label: t('locations'),
          icon: 'pi po-fw pi-briefcase',
          visible: hasPermissions(['LOCATIONS_MANAGEMENT'], loggedUserContext.permissions),
          to: '/locations',
        },
        {
          label: t('users'),
          icon: 'pi po-fw pi-user',
          visible: locationSelected() && hasPermissions(['USERS_MANAGEMENT'], loggedUserContext.permissions),
          to: '/users',
        },
        {
          label: t('roles'),
          icon: 'pi po-fw pi-key',
          visible: hasPermissions(['ROLES_MANAGEMENT'], loggedUserContext.permissions),
          to: '/roles',
        },
        {
          label: t('stations'),
          icon: 'pi po-fw pi-ticket',
          to: '/stations',
        },
        {
          label: t('scales'),
          icon: 'pi po-fw pi-desktop',
          visible:
            isModulesEnabled(['WEIGHING'], loggedUserContext.currentCustomer?.subscriptionModules) &&
            locationSelected() &&
            hasPermissions(['NODES_MANAGEMENT'], loggedUserContext.permissions),
          to: '/nodes',
        },
        {
          label: t('edgeDevices'),
          icon: 'pi po-fw pi-sitemap',
          visible:
            isModulesEnabled(['WEIGHING'], loggedUserContext.currentCustomer?.subscriptionModules) &&
            locationSelected() &&
            hasPermissions(['EDGE_DEVICES_MANAGEMENT'], loggedUserContext.permissions),
          to: '/edge-devices',
        },
        {
          label: t('systemConfiguration'),
          icon: 'pi pi-fw pi-cog',
          visible:
            isOneOfModulesEnabled(['WEIGHING', 'BDO'], loggedUserContext.currentCustomer?.subscriptionModules) &&
            hasOneOfPermissions(['SYSTEM_CONFIGURATION'], loggedUserContext.permissions),
          items: [
            {
              label: t('systemConfigurationWeighing'),
              icon: 'pi po-fw pi-cog',
              visible: isModulesEnabled(['WEIGHING'], loggedUserContext.currentCustomer?.subscriptionModules),
              to: '/config-system-weighing-configuration',
            },
            {
              label: t('systemConfigurationBdo'),
              icon: 'pi po-fw pi-cog',
              visible: isModulesEnabled(['BDO'], loggedUserContext.currentCustomer?.subscriptionModules),
              to: '/config-system-bdo',
            },
            {
              label: t('systemConfigurationReceipt'),
              icon: 'pi po-fw pi-cog',
              to: '/config-prints',
            },
            {
              label: t('systemConfigurationReceiptPOS'),
              icon: 'pi po-fw pi-cog',
              to: '/config-pos-receipts',
            },
            {
              label: t('confirmationsConfigurationMenuTitle'),
              icon: 'pi po-fw pi-cog',
              to: '/config-confirmations',
            },
            {
              label: t('systemConfigurationMails'),
              icon: 'pi po-fw pi-cog',
              to: '/config-emails',
            },
            {
              label: t('systemConfigurationReports'),
              icon: 'pi po-fw pi-cog',
              to: '/config-reports',
            },
          ],
        },
        {
          label: t('apiKey'),
          icon: 'pi po-fw pi-key',
          to: '/api-key',
          visible: loggedUserContext.limits?.allowApiAccess !== false,
        },
        {
          label: t('webhooks'),
          icon: 'pi po-fw pi-bell',
          to: '/web-hooks',
        },
        {
          label: t('updateLicenseKey'),
          icon: 'pi po-fw pi-key',
          to: '/update-license-key',
          visible: process.env.REACT_APP_SCALEO_MODE === 'OFFLINE',
        },
        {
          label: t('importExport'),
          icon: 'pi po-fw pi-file',
          visible:
            isModulesEnabled(['WEIGHING'], loggedUserContext.currentCustomer?.subscriptionModules) &&
            hasPermissions(['DATA_IMPORT_EXPORT'], loggedUserContext.permissions),
          to: '/import-export',
        },
        // {  label: t('backups'), icon: 'pi po-fw pi-copy', to: '/backups' },
      ],
    },
  ];

  const fallbackRoute = () => {
    if (
      hasOneOfPermissions(
        ['WEIGHINGS_EDIT', 'WEIGHINGS_DELETE', 'WEIGHINGS_RESTORE', 'WEIGHINGS_REPORTS'],
        loggedUserContext.permissions,
      )
    ) {
      return '/weighings';
    } else if (hasOneOfPermissions(['DATASETS_EDIT'], loggedUserContext.permissions)) {
      return '/drivers';
    } else if (hasOneOfPermissions(['WASTE_REGISTER'], loggedUserContext.permissions)) {
      return '/bdo-kpo-sender';
    }
    return '';
  };

  const routers = [
    {
      path: '/',
      component: loggedUserContext.currentCustomer?.id ? (
        hasPermissions(['WEIGHING'], loggedUserContext.permissions) ? (
          <HandlingWeighings
            {...props}
            publishWeighCommandHandler={publishWeighCommandHandler}
            publishScaleCommandHandler={publishScaleCommandHandler}
          />
        ) : (
          <Redirect to={fallbackRoute()} />
        )
      ) : (
        <p>{t('noCustomers')}</p>
      ),
      exact: true,
    },
    {
      path: '/weighings',
      component: <WeighingsList />,
    },
    {
      path: '/drivers',
      component: <Drivers />,
    },
    {
      path: '/products',
      component: <Products />,
    },
    {
      path: '/contractors',
      component: <Contractors />,
    },
    {
      path: '/vehicles',
      component: <Vehicles />,
    },
    {
      path: '/access-codes',
      component: <AccessCodes />,
    },
    {
      path: '/containers',
      component: <Containers />,
    },
    {
      path: '/trailers',
      component: <Trailers />,
    },
    {
      path: '/bdo-kpo-sender',
      component: <Bdo.Kpo.Sender />,
    },
    {
      path: '/bdo-kpo-carrier',
      component: <Bdo.Kpo.Carrier />,
    },
    {
      path: '/bdo-kpo-receiver',
      component: <Bdo.Kpo.Receiver />,
    },
    {
      path: '/bdo-kpok-sender',
      component: <Bdo.Kpok.Sender />,
    },
    {
      path: '/bdo-kpok-carrier',
      component: <Bdo.Kpok.Carrier />,
    },
    {
      path: '/bdo-kpok-receiver',
      component: <Bdo.Kpok.Receiver />,
    },
    {
      path: '/locations',
      component: <Management.Locations />,
    },
    {
      path: '/dictionaries',
      component: <Dictionaries />,
    },
    {
      path: '/config-system-weighing-configuration',
      component: <Management.SystemConfiguration.WeighingConfiguration />,
    },
    {
      path: '/config-system-bdo',
      component: <Management.SystemConfiguration.BdoConfiguration />,
    },
    {
      path: '/indicator-types',
      component: <Management.IndicatorTypes />,
    },
    {
      path: '/stations',
      component: <Management.Stations />,
    },
    {
      path: '/nodes',
      component: <Management.Nodes />,
    },
    {
      path: '/edge-devices',
      component: <Management.EdgeDevices />,
    },
    {
      path: '/users',
      component: <Management.Users />,
    },
    {
      path: '/roles',
      component: <Management.Roles />,
    },
    {
      path: '/api-key',
      component: <Management.ApiKeys />,
    },
    {
      path: '/web-hooks',
      component: <Management.WebHooks />,
    },
    {
      path: '/update-license-key',
      component: <UpdateLicenseKey />,
    },
    {
      path: '/import-export',
      component: <Management.ImportExport />,
    },
    {
      path: '/user-profile',
      component: <UserProfile />,
    },
    {
      path: '/billing-data',
      component: <BillingData />,
    },
    {
      path: '/contact',
      component: <Contact />,
    },
    {
      path: '/config-prints',
      component: <Management.SystemConfiguration.PrintsConfiguration />,
    },
    {
      path: '/config-confirmations',
      component: <Management.SystemConfiguration.ConfirmationsConfiguration />,
    },
    {
      path: '/config-emails',
      component: <Management.SystemConfiguration.EmailsConfiguration />,
    },
    {
      path: '/config-reports',
      component: <Management.SystemConfiguration.ReportsConfiguration />,
    },
    {
      path: '/event-log',
      component: <AccessControlEventLogPage />,
    },
    {
      path: '/config-pos-receipts',
      component: <Management.SystemConfiguration.PosReceiptsConfiguration />,
    },
    {
      path: '/arrival-notifications',
      component: <AccessControlArrivalNotificationPage />,
    },
    {
      path: '/version',
      component: <Version />,
    },
  ];

  let topbarItemClick: boolean;
  let menuClick: boolean;
  let userMenuClick: boolean;
  let configClick = false;

  useEffect(() => {
    if (staticMenuMobileActive) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }, [staticMenuMobileActive]);

  const onDocumentClick = () => {
    if (!topbarItemClick) {
      setActiveTopbarItem(null);
    }

    if (!userMenuClick && isSlim() && !isMobile()) {
      setInlineUserMenuActive(false);
    }

    if (!menuClick) {
      if (isHorizontal() || isSlim()) {
        setMenuActive(false);
      }

      if (overlayMenuActive || staticMenuMobileActive) {
        hideOverlayMenu();
      }

      unblockBodyScroll();
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    topbarItemClick = false;
    menuClick = false;
    userMenuClick = false;
    configClick = false;
  };

  const onMenuButtonClick = (event: SyntheticEvent | null) => {
    menuClick = true;

    if (layoutMode === 'overlay') {
      setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
    }

    if (isDesktop()) {
      setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive);
      onLayoutModeChange(layoutMode === 'static' ? 'slim' : 'static');
    } else {
      setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
      if (staticMenuMobileActive) {
        blockBodyScroll();
      } else {
        unblockBodyScroll();
      }
    }

    if (event) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (isMobile()) {
      dispatch(uiActions.setIsMenuOpen(staticMenuMobileActive));
    }
  }, [staticMenuMobileActive, dispatch]);

  useEffect(() => {
    if (isDesktop()) {
      if (layoutMode === 'static' && !isMenuOpen) {
        dispatch(uiActions.setIsMenuOpen(true));
      }
      if (layoutMode === 'slim' && isMenuOpen) {
        dispatch(uiActions.setIsMenuOpen(false));
      }
    }
  }, [layoutMode, dispatch]);

  useEffect(() => {
    if (layoutMode === 'static' && !isMenuOpen) {
      onMenuButtonClick(null);
      setStaticMenuMobileActive(false);
    }
  }, [isMenuOpen]);

  const onTopbarItemClick = (event: any) => {
    topbarItemClick = true;

    if (activeTopbarItem === event.item) setActiveTopbarItem(null);
    else setActiveTopbarItem(event.item);

    event.originalEvent.preventDefault();
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onInlineUserClick = () => {
    userMenuClick = true;
    setInlineUserMenuActive((prevInlineUserMenuActive) => !prevInlineUserMenuActive);
    setMenuActive(false);
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
  };

  const onMenuItemClick = (event: any) => {
    if (!event.item.items) {
      hideOverlayMenu();
    }
    if (!event.item.items && (isHorizontal() || isSlim())) {
      setMenuActive(false);
    }
  };

  const onRootMenuItemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
    setInlineUserMenuActive(false);
  };

  const isHorizontal = () => {
    return layoutMode === 'horizontal';
  };

  const isSlim = () => {
    return layoutMode === 'slim';
  };

  const onLayoutModeChange = (_layoutMode: string) => {
    setLayoutMode(_layoutMode);
    setStaticMenuDesktopInactive(false);
    setOverlayMenuActive(false);

    if (_layoutMode === 'horizontal' && inlineUser) {
      setInlineUser(false);
    }
  };

  const layoutClassName = classNames(
    'layout-wrapper',
    {
      'layout-horizontal': layoutMode === 'horizontal',
      'layout-overlay': layoutMode === 'overlay',
      'layout-static': layoutMode === 'static',
      'layout-slim': layoutMode === 'slim',
      'layout-menu-light': true,
      'layout-menu-dark': false,
      'layout-overlay-active': overlayMenuActive,
      'layout-mobile-active': staticMenuMobileActive,
      'layout-static-inactive': staticMenuDesktopInactive,
      'layout-rtl': false,
      'p-input-filled': false,
      'p-ripple-disabled': true,
    },
    'layout-topbar-blue',
  );

  const inlineUserTimeout = layoutMode === 'slim' ? 0 : { enter: 1000, exit: 450 };

  if (expired) {
    return (
      <Route
        path={'/'}
        render={() => <VerifyLicenseKey verifyOrUpdateKey={installationService.updateLicenseKey} setIsSuccess={null} />}
      />
    );
  }

  return (
    <div className={layoutClassName} onClick={onDocumentClick}>
      <AxiosConfigContainer />
      {initialized && (
        <>
          <SoundContainer />
          <AppTopbar
            activeTopbarItem={activeTopbarItem}
            inlineUser={inlineUser}
            onMenuButtonClick={onMenuButtonClick}
            onTopbarItemClick={onTopbarItemClick}
          />
          {loggedUserContext.currentCustomer?.id && (
            <div className="layout-menu-container" onClick={onMenuClick}>
              {inlineUser && (
                <div className="layout-profile">
                  <button type="button" className="p-link layout-profile-button" onClick={onInlineUserClick}>
                    <img src="assets/layout/images/avatar.png" alt="roma-layout" />
                    <div className="layout-profile-userinfo">
                      <span className="layout-profile-name">Arlene Welch</span>
                      <span className="layout-profile-role">Design Ops</span>
                    </div>
                  </button>
                  <CSSTransition
                    classNames="p-toggleable-content"
                    timeout={inlineUserTimeout}
                    in={inlineUserMenuActive}
                    unmountOnExit
                  >
                    <ul
                      className={classNames('layout-profile-menu', {
                        'profile-menu-active': inlineUserMenuActive,
                      })}
                    >
                      <li>
                        <button type="button" className="p-link">
                          <i className="pi pi-fw pi-user"></i>
                          <span>Profile</span>
                        </button>
                      </li>
                      <li>
                        <button type="button" className="p-link">
                          <i className="pi pi-fw pi-cog"></i>
                          <span>Settings</span>
                        </button>
                      </li>
                      <li>
                        <button type="button" className="p-link">
                          <i className="pi pi-fw pi-envelope"></i>
                          <span>Messages</span>
                        </button>
                      </li>
                      <li>
                        <button type="button" className="p-link">
                          <i className="pi pi-fw pi-bell"></i>
                          <span>Notifications</span>
                        </button>
                      </li>
                    </ul>
                  </CSSTransition>
                </div>
              )}
              <AppMenu
                model={menu}
                onMenuItemClick={onMenuItemClick}
                onRootMenuItemClick={onRootMenuItemClick}
                layoutMode={layoutMode}
                active={menuActive}
                mobileMenuActive={staticMenuMobileActive}
              />
            </div>
          )}
          <div className="layout-main">
            <div className="layout-content">
              <ConfirmationsPanelDialog />
              <WeighingSummaryDialog />
              <ToastController />
              {routers.map((router, index) => {
                if (router.exact) {
                  return <Route key={`router${index}`} path={router.path} exact render={() => router.component} />;
                }

                return <Route key={`router${index}`} path={router.path} render={() => router.component} />;
              })}
            </div>
          </div>
          <div className="layout-content-mask"></div>
          <ChangesConfirmationDialog />
          <DowntimeInfoModule />
        </>
      )}
    </div>
  );
};

export default App;
