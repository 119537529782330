import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import FormErrorMessage from '../components/_shared/FormErrorMessage';
import LanguageSelectorUnauthorized from '../components/_shared/LanguageSelectorUnauthorized';
import usersService from '../services/ScaleoApiServices/UsersService';
import { uiActions } from '../store/ui-slice';
import { useTranslation } from 'react-i18next';

interface Errors {
  password?: string;
  repeatPassword?: string;
  otp?: string;
}

export const ConfirmForgotPassword = () => {
  const { t } = useTranslation('confirmForgotPasswordPage');

  const history = useHistory();
  const email = getEmailFromQuery();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatPassword: '',
      otp: '',
    },
    validate: (data) => {
      const errors: Errors = {};

      if (!data.password) {
        errors.password = t('noPasswordError');
      }
      if (!data.repeatPassword) {
        errors.repeatPassword = t('noRepeatPasswordError');
      } else if (data.password !== data.repeatPassword) {
        errors.repeatPassword = t('incorrectRepeatPasswordError');
      }

      if (!data.otp) {
        errors.otp = t('noOtpError');
      }

      return errors;
    },
    onSubmit: (formData, helpers) => {
      dispatch(uiActions.toggleIsLoading({ isLoading: true }));

      usersService
        .confirmForgotPassword({
          email,
          password: formData.password,
          otp: formData.otp,
        })
        .then(() => {
          history.push(`/login`);
          scheduleHideLoader();
        })
        .catch((error) => {
          scheduleHideLoader();

          const errorCode = error.response.data.error;

          errorCode === 'OTP_ERROR' && helpers.setFieldError('otp', t('otpError'));
          errorCode === 'LIMIT_EXCEEDED' && helpers.setFieldError('otp', t('limitExceededError'));
          errorCode === 'INVALID_PASSWORD' && helpers.setFieldError('password', t('invalidPasswordError'));
        });
    },
  });

  const scheduleHideLoader = () => {
    setTimeout(() => {
      dispatch(uiActions.toggleIsLoading({ isLoading: false }));
    }, 400);
  };

  function getEmailFromQuery() {
    const { search } = useLocation();

    return React.useMemo(() => {
      const queryString = search.startsWith('?') ? search.substring(1) : search;
      const emailMatch = queryString.match(/email=([^&]+)/);
      return emailMatch ? emailMatch[1] : null;
    }, [search]);
  }

  return (
    <div className="login-body">
      <div className="card login-panel p-fluid">
        <LanguageSelectorUnauthorized visible={false} />
        <div className="login-panel-content">
          <form>
            <div className="grid">
              <div className="col-12 logo-container">
                <img src="assets/layout/images/logo_scaleo.png" alt={t('logoAlt')} className="w-full" />
              </div>
              <div className="col-12 sm:col-6 md:col-6">
                <span className="guest-sign-in">{t('header')}</span>
              </div>
              <div className="col-12">
                <label htmlFor="otp">{t('otp')}</label>
                <InputText id="otp" value={formik.values.otp} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="otp" formikInstance={formik} />
              </div>
              <div className="col-12 password-container">
                <label>{t('newPassword')}</label>
                <Password
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  feedback={false}
                  toggleMask
                />
                <FormErrorMessage fieldName="password" formikInstance={formik} />
              </div>
              <div className="col-12 password-container">
                <label>{t('repeatPassword')}</label>
                <Password
                  id="repeatPassword"
                  name="repeatPassword"
                  value={formik.values.repeatPassword}
                  onChange={formik.handleChange}
                  feedback={false}
                  toggleMask
                />
                <FormErrorMessage fieldName="repeatPassword" formikInstance={formik} />
              </div>
            </div>
          </form>
          <div className="grid">
            <div className="col-12 sm:col-6 md:col-6">
              <Button type="submit" label={t('submitButton')} icon="pi pi-check" onClick={formik.submitForm} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
