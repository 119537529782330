import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { useFormik } from 'formik';
import { Chips } from 'primereact/chips';
import dictionariesService from '../../../../services/ScaleoApiServices/DictionariesService';
import '../../../DataTable.css';
import formChangeFieldMethod from '../../../../utils/formChangeFieldMethod';
import { useTranslation } from 'react-i18next';
import { Dictionary } from '../../../../types/dictionary';

interface NewOrEditDictionaryDialogProps {
  visible: boolean;
  closeDialog: () => void;
  item: Dictionary | null;
}

const INIT_FORM_STATE: { name: string; values: any[] } = {
  name: '',
  values: [],
};

const NewOrEditDictionaryDialog = ({ visible, closeDialog, item }: NewOrEditDictionaryDialogProps) => {
  const { t } = useTranslation('managementDictionaries');

  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  useEffect(() => {
    if (visible && item?.id) {
      const { name, values } = item;
      setInitFormValues({ name, values });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
  }, [item, visible]);

  const validateMethod = (data: any) => {
    const errors: any = {};

    if (!data.name) {
      errors.name = t('noNameError');
    } else {
      if (!data.name.match(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ 0-9-]+$/)) {
        errors.name = t('nameInfoError');
      }
    }
    return errors;
  };

  const handleCloseDialog = () => {
    formik.resetForm({
      values: initFormValues,
    });

    closeDialog();
  };

  const handleSubmit = (data: any, helpers: any) => {
    const body = data;

    if (item?.id) {
      dictionariesService
        .editDictionary(body, item.id)
        .then(() => {
          handleCloseDialog();
        })
        .catch((error) => {
          if (error.response.status === 409) {
            helpers.setFieldError('name', t('dictionaryExistError'));
          }
        });
    } else {
      dictionariesService
        .createDictionary(body)
        .then(() => {
          handleCloseDialog();
        })
        .catch((error) => {
          if (error.response.status === 409) {
            helpers.setFieldError('name', t('dictionaryExistError'));
          }
        });
    }
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const dialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={handleCloseDialog} />
      <Button type="submit" label={t('saveButton')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );

  const isFormFieldInvalid = () => formik.touched?.name && formik.errors?.name;

  const getFormErrorMessage = () => isFormFieldInvalid() && <Message severity="error" text={formik.errors?.name} />;

  const handleChange = (e: any) => {
    formChangeFieldMethod(e, 'chips', formik);
  };

  const handleBlurChips = (e: any) => {
    if (e.target.value) {
      formik.setFieldValue('values', [...formik.values.values, e.target.value], true);
      e.target.value = '';
    }
  };

  const chipsTemplate = (value: any) => (
    <span className={initFormValues.values.includes(value) && item?.isUsed ? 'disabled-chips' : ''}>{value}</span>
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="location-edit-dialog">
        <Dialog
          visible={visible}
          header={t('dictionaryDetails')}
          modal
          className="p-fluid"
          breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
          style={{ width: '40vw' }}
          footer={dialogFooter}
          onHide={handleCloseDialog}
        >
          <div className="field col-12">
            <label htmlFor="name">{t('name')}</label>
            <InputText
              id="name"
              value={formik.values.name}
              onChange={(e) => formChangeFieldMethod(e, 'text', formik)}
              disabled={item?.isUsed}
            />
            {getFormErrorMessage()}
            <br />
            <br />
            <label htmlFor="values">{t('values')}</label>
            <Chips
              id="values"
              value={formik.values.values}
              onChange={handleChange}
              itemTemplate={chipsTemplate}
              onBlur={handleBlurChips}
            />
            {getFormErrorMessage()}
            <br />
            <br />
            <br />
          </div>
        </Dialog>
      </div>
    </form>
  );
};

export default NewOrEditDictionaryDialog;
