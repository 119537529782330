import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosConfigContainer } from '../components/AxiosConfig';
import LanguageSelectorUnauthorized from '../components/_shared/LanguageSelectorUnauthorized';
import AuthContext from '../store/auth-context';
import { uiActions } from '../store/ui-slice';
import { useTranslation } from 'react-i18next';
import systemConfigurationService from '../services/ScaleoApiServices/SystemConfigurationService';
import LoginDialog from '../components/LoginDialog/LoginDialog';
// import usersService from '../services/ScaleoApiServices/UsersService';
import UserLimitDialog from '../components/LoginDialog/UserLimitDialog';
import ExpiredLicenseDialog from '../components/LoginDialog/ExpiredLicenseDialog';
import { VerifyLicenseKey } from './VerifyLicenseKey';
import installationService from '../services/ScaleoApiServices/InstallationService';
// import { getLoggedDeviceInfo } from '../utils/getLoggedDeviceInfo';

interface LoginProps {
  onLoginAttempt: (
    mail: string,
    password: string,
    callback: (error: any, idToken: string, accessToken: string, id: any, email: string, credentials: any) => void,
  ) => void;
}

export const Login = ({ onLoginAttempt }: LoginProps) => {
  const { t } = useTranslation('loginPage');
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();

  const [emailOrPasswordInvalid, setEmailOrPasswordInvalid] = useState(false);
  const [emailEmpty, setEmailEmpty] = useState(false);
  const [passwordEmpty, setPasswordEmpty] = useState(false);
  const [smtpExists, setSmtpExists] = useState(null);
  const emailInputRef = useRef<HTMLInputElement>();
  const passwordInputRef = useRef<HTMLInputElement>();

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isUserLimitDialogVisible, setIsUserLimitDialogVisible] = useState(false);
  const [isExpiredLicenseDialogVisible, setIsExpiredLicenseDialogVisible] = useState(false);
  const [isUpdateLicenseDialogVisible, setIsUpdateLicenseDialogVisible] = useState(false);
  const [isUserAlreadyLogged, setIsUserAlreadyLogged] = useState(false);
  // const [loggedDeviceInfo, setLoggedDeviceInfo] = useState('');

  const handleDialogVisible = () => {
    setIsDialogVisible(false);
  };

  const handleLogoutUser = async () => {
    // await usersService.dropSession(emailInputRef.current?.value);

    setIsUserAlreadyLogged(false);
    setIsDialogVisible(false);
  };

  const scheduleHideLoader = () => {
    setTimeout(() => {
      dispatch(uiActions.toggleIsLoading({ isLoading: false }));
    }, 400);
  };

  useEffect(() => {
    process.env.REACT_APP_SCALEO_MODE === 'OFFLINE' &&
      systemConfigurationService.existsAnySmtpConfig().then((response) => {
        setSmtpExists(response.data);
      });
  }, []);

  useEffect(() => {
    if (isExpiredLicenseDialogVisible) {
      setTimeout(() => setIsUpdateLicenseDialogVisible(true), 3000);
    }
  }, [isExpiredLicenseDialogVisible]);

  const submitHandler = async (event: any) => {
    setEmailOrPasswordInvalid(false);
    event.preventDefault();

    const enteredEmail = emailInputRef.current?.value;
    const enteredPassword = passwordInputRef.current?.value;

    const valid = validateEmailAndPassword(enteredEmail, setEmailEmpty, enteredPassword, setPasswordEmpty);

    dispatch(uiActions.toggleIsLoading({ isLoading: true }));

    const isAlreadyLogged = isUserAlreadyLogged;

    if (valid) {
      try {
        // if (process.env.REACT_APP_SCALEO_MODE !== 'ONLINE') {
        //   const userSession = await usersService.getUserSessionByEmail(enteredEmail!, enteredPassword!, getLoggedDeviceInfo());

        //   if (userSession.sessionId) {
        //     setEmailOrPasswordInvalid(false);
        //     setLoggedDeviceInfo(userSession.sessionLoggedDeviceInfo);
        //     setIsUserAlreadyLogged(true);
        //     isAlreadyLogged = true;
        //     setIsDialogVisible(true);
        //   }
        // }

        if (!isAlreadyLogged) {
          onLoginAttempt(enteredEmail!, enteredPassword!, (error, idToken, accessToken, id, email, credentials) => {
            if (error && (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException')) {
              setEmailOrPasswordInvalid(true);
              scheduleHideLoader();
              return;
            }
            authCtx.login(idToken, accessToken, id, email, null, credentials);
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          setEmailOrPasswordInvalid(true);
          return;
        }

        if (error.response.data.error === 'Provided key is not valid for you computer!') {
          setIsExpiredLicenseDialogVisible(true);
          return;
        }

        setIsUserLimitDialogVisible(true);
      }
    } else {
      scheduleHideLoader();
    }
  };

  const showForgotPassword = process.env.REACT_APP_SCALEO_MODE === 'OFFLINE' ? smtpExists : true;

  if (isUpdateLicenseDialogVisible) {
    return <VerifyLicenseKey verifyOrUpdateKey={installationService.updateLicenseKey} setIsSuccess={null} />;
  }

  return (
    <>
      <AxiosConfigContainer />
      <form onSubmit={submitHandler}>
        <div className="login-body">
          <div className="card login-panel p-fluid">
            <LanguageSelectorUnauthorized visible={false} />
            <div className="login-panel-content">
              <div className="grid">
                <div className="col-12 logo-container">
                  <img src="assets/layout/images/logo_scaleo.png" alt={t('logoAlt')} className="w-full" />
                </div>
                <div className="col-12 sm:col-6 md:col-6">
                  <span className="guest-sign-in">{t('header')}</span>
                </div>
                <div className="col-12">
                  <label>{t('fieldsEmail')}</label>
                  <div className="login-input mb-2">
                    <InputText
                      id="input"
                      type="text"
                      ref={emailInputRef as any}
                      className={emailEmpty && ('p-invalid' as any)}
                    />
                  </div>
                  {emailEmpty && <Message severity="error" text={t('fieldsEmailError')} />}
                </div>
                <div className="col-12 password-container">
                  <label>{t('fieldsPassword')}</label>
                  <div className="login-input mb-2">
                    <InputText
                      type="password"
                      ref={passwordInputRef as any}
                      className={passwordEmpty && ('p-invalid' as any)}
                    />
                  </div>
                  {passwordEmpty && <Message severity="error" text={t('fieldsPasswordError')} />}
                </div>
                {emailOrPasswordInvalid === true && (
                  <div className="col-12 invalid-login-or-password-container">
                    <Message severity="error" text={t('fieldsInvalidPasswordError')} />
                  </div>
                )}

                <div className="col-12 sm:col-6 md:col-6 rememberme-container"></div>
                {showForgotPassword && (
                  <div className="col-6 forgetpassword-container">
                    <a href="#/forgot-password" className="forget-password">
                      {t('fieldsForgotPassword')}
                    </a>
                  </div>
                )}
                <div className="col-6">
                  <Button label={t('fieldsSubmitButton')} icon="pi pi-check" />
                </div>

                {process.env.REACT_APP_SCALEO_MODE === 'ONLINE' && (
                  <div className="col-6 forgetpassword-container">
                    {t('question')}&nbsp;
                    <a href="https://scaleo.eu/free/" className="sign-up">
                      {t('register')}
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <LoginDialog
        // loggedDeviceInfo={loggedDeviceInfo}
        isDialogVisible={isDialogVisible}
        handleDialogVisible={handleDialogVisible}
        handleLogoutUser={handleLogoutUser}
      />
      <UserLimitDialog
        isDialogVisible={isUserLimitDialogVisible}
        handleDialogVisible={() => setIsUserLimitDialogVisible(false)}
      />
      <ExpiredLicenseDialog
        isDialogVisible={isExpiredLicenseDialogVisible}
        handleDialogVisible={() => setIsExpiredLicenseDialogVisible(false)}
      />
    </>
  );
};

function validateEmailAndPassword(
  enteredEmail: string | undefined,
  setEmailEmpty: (b: boolean) => void,
  enteredPassword: string | undefined,
  setPasswordEmpty: (b: boolean) => void,
) {
  if (enteredEmail === '') {
    setEmailEmpty(true);
  } else {
    setEmailEmpty(false);
  }
  if (enteredPassword === '') {
    setPasswordEmpty(true);
  } else {
    setPasswordEmpty(false);
  }
  return !(enteredEmail === '' || enteredPassword === '');
}
