import React, { useEffect, useState, useCallback, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import NewOrEditDictionaryDialog from './components/NewOrEditDictionaryDialog';
import DeleteDictionaryDialog from './components/DeleteDictionaryDialog';
import DataTableFilterElements from '../../_shared/DataTableFilterElements';
import dictionariesService from '../../../services/ScaleoApiServices/DictionariesService';
import { useSelector } from 'react-redux';
import { trimString } from '../../../utils/stringUtils';
import getPaginatorTemplate from '../../../utils/getPaginatorTemplate';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../types/reducer-state';
import { Dictionary } from '../../../types/dictionary';
import DeleteDictionariesDialog from './components/DeleteDictionariesDialog';

const INIT_FILTERS = {
  name: null,
  values: null,
};

const INIT_PAGINATION_PARAMETERS = {
  order: {
    isAscending: true,
    orderColumn: 'name',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const INIT_PAGINATOR_STATE = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

const Dictionaries = () => {
  const { t } = useTranslation('managementDictionaries');

  const [globalFilter, setGlobalFilter] = useState('');
  const dt = useRef(null);
  const [filters, setFilters] = useState(INIT_FILTERS);
  const [paginationParameters, setPaginationParameters] = useState(INIT_PAGINATION_PARAMETERS);
  const [paginatorState, setPaginatorState] = useState(INIT_PAGINATOR_STATE);
  const [isOpenNewOrEditDialog, setIsOpenNewOrEditDialog] = useState(false);
  const [isOpenDeleteDictionaryDialog, setIsOpenDeleteDictionaryDialog] = useState(false);
  const [isOpenDeleteDictionariesDialog, setIsOpenDeleteDictionariesDialog] = useState(false);
  const [listData, setListData] = useState([]);
  const [selectedRow, setSelectedRow] = useState<Dictionary | null>(null);
  const [selectedRows, setSelectedRows] = useState<Dictionary[]>([]);

  // const [selectedRowToEdit, setSelectedRowToEdit] = useState(null);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const refreshListData = useCallback(async () => {
    dictionariesService
      .getDictionariesSearch(filters, paginationParameters, globalFilter)
      .then((response) => {
        setListData(response.data.items);
        setPaginatorState({
          ...paginatorState,
          totalPages: response.data.totalPagesNumber,
          currentPage: paginatorState.currentPage > response.data.totalPagesNumber ? 1 : paginatorState.currentPage,
        });
      })
      .catch(() => {
        setListData([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, paginationParameters, globalFilter]);

  useEffect(() => {
    refreshListData();
  }, [loggedUserContext?.currentCustomer?.id, refreshListData]);

  const handleClickClearFilters = () => {
    setFilters(INIT_FILTERS);
    setGlobalFilter('');
  };

  const header = (
    <div className="table-header">
      {t('header')} &#160;&#160;
      <div className="flex">
        <Button
          type="button"
          label={t('clearFilters')}
          icon="pi pi-filter-slash"
          outlined
          className="mr-2"
          onClick={handleClickClearFilters}
        />
        <span>
          <DataTableFilterElements.Text
            initialValue={globalFilter}
            onChangeFilter={setGlobalFilter}
            name={'global'}
            placeholder={t('searchPlaceholder')}
          />
        </span>
      </div>
    </div>
  );

  const leftToolbarTemplate = () => {
    const handleClickNew = () => {
      setSelectedRow(null);

      setIsOpenNewOrEditDialog(true);
    };

    const handleClickDeleteDictionaries = () => {
      setSelectedRows(selectedRows);

      setIsOpenDeleteDictionariesDialog(true);
    };

    return (
      <>
        <Button
          type="button"
          label={t('addButton')}
          icon="pi pi-plus"
          severity="success"
          className="mr-2"
          onClick={handleClickNew}
        />
        <Button
          type="button"
          label={t('deleteButton')}
          icon="pi pi-trash"
          severity="danger"
          onClick={handleClickDeleteDictionaries}
          disabled={!selectedRows || !selectedRows.length || selectedRows.map((el: any) => el?.isUsed).includes(true)}
          tooltip={!selectedRows.length ? t('selectDictionary') : t('cannotDelete')}
        />
      </>
    );
  };

  const rightToolbarTemplate = () => <></>;

  const nameBodyTemplate = (rowData: any) => (
    <>
      <span className="p-column-title">{t('name')}</span>
      {rowData.name}
    </>
  );

  const valuesBodyTemplate = (rowData: any) => (
    <>
      <span className="p-column-title">{t('values')}</span>
      {trimString(rowData.values.join(', '), 80, true)}
    </>
  );

  const actionBodyTemplate = (rowData: any) => {
    const handleClickEditDictionaryRow = () => {
      setSelectedRow(rowData);

      setIsOpenNewOrEditDialog(true);
    };

    const handleClickDeleteDictionaryRow = () => {
      setSelectedRow(rowData);

      setIsOpenDeleteDictionaryDialog(true);
    };

    return (
      <div className="actions">
        <Button
          type="button"
          icon="pi pi-pencil"
          raised
          severity="success"
          className="mr-2"
          onClick={handleClickEditDictionaryRow}
        />
        {rowData.isUsed === false ? (
          <Button type="button" icon="pi pi-trash" severity="warning" raised onClick={handleClickDeleteDictionaryRow} />
        ) : (
          <Button
            type="button"
            icon="pi pi-trash"
            raised
            severity="secondary"
            tooltip={t('tooltip')}
            tooltipOptions={{ position: 'bottom' }}
          />
        )}
      </div>
    );
  };

  const handleCloseNewOrEditDialog = () => {
    setSelectedRow(null);
    setIsOpenNewOrEditDialog(false);

    refreshListData();
  };

  const handleCloseDeleteDictionaryDialog = () => {
    setSelectedRow(null);
    setIsOpenDeleteDictionaryDialog(false);

    refreshListData();
  };

  const handleCloseDeleteDictionariesDialog = () => {
    setSelectedRows([]);
    setIsOpenDeleteDictionariesDialog(false);

    refreshListData();
  };

  const handleChangeFilter = (value: any) => {
    const newFilters = { ...filters };
    newFilters.name = value;
    setFilters(newFilters);
  };

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card">
          <NewOrEditDictionaryDialog
            visible={isOpenNewOrEditDialog}
            closeDialog={handleCloseNewOrEditDialog}
            item={selectedRow}
          />
          <DeleteDictionaryDialog
            visible={isOpenDeleteDictionaryDialog}
            closeDialog={handleCloseDeleteDictionaryDialog}
            selectedRow={selectedRow!}
          />
          <DeleteDictionariesDialog
            visible={isOpenDeleteDictionariesDialog}
            closeDialog={handleCloseDeleteDictionariesDialog}
            selectedRows={selectedRows}
          />
          <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                responsiveLayout="scroll"
                ref={dt}
                value={listData}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                removableSort
                rowHover
                rows={paginatorState.rows}
                paginator
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState)}
                emptyMessage={t('noData')}
                onSort={handleSort}
                selectionMode="checkbox"
                selection={selectedRows}
                onSelectionChange={(e: any) => setSelectedRows(e.value)}
                stripedRows
                filterDisplay="row"
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column
                  field="name"
                  header={t('name')}
                  sortable
                  body={nameBodyTemplate}
                  filter
                  showFilterMenu={false}
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.name}
                      onChangeFilter={handleChangeFilter}
                      name="name"
                      placeholder={t('filters')}
                    />
                  }
                ></Column>
                <Column
                  field="values"
                  header={t('values')}
                  sortable
                  body={valuesBodyTemplate}
                  filter
                  showFilterMenu={false}
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.values}
                      onChangeFilter={handleChangeFilter}
                      name="values"
                      placeholder={t('filters')}
                    />
                  }
                ></Column>
                <Column body={actionBodyTemplate} className="w-100px text-center"></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dictionaries;
