import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';

interface WeighingModeSectionProps {
  formikInstance: any;
}

const WeighingModeSection = ({ formikInstance }: WeighingModeSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationWeighing');

  const MODE_OPTIONS = [
    { label: t('modeOptionsSingle'), value: 'SINGLE' },
    { label: t('modeOptionsDouble'), value: 'DOUBLE' },
    { label: t('modeOptionsMultiple'), value: 'MULTIPLE' },
    { label: t('modeOptionsWeighingSeries'), value: 'WEIGHING_SERIES' },
    { label: t('modeOptionsDoubleWeighingSeries'), value: 'DOUBLE_WEIGHING_SERIES' },
    { label: t('modeOptionsTare'), value: 'TARE' },
    { label: t('modeOptionsControl'), value: 'CONTROL' },
  ];

  const handleChangeWeighingMode = (e: any, value: any) => {
    let newValue = [];
    if (e.checked && !formikInstance.values.weighingMode?.includes(value)) {
      newValue = [...formikInstance.values.weighingMode, value];
    }
    if (!e.checked && formikInstance.values.weighingMode?.includes(value)) {
      newValue = formikInstance.values.weighingMode.filter((el: any) => el !== value);
    }
    formikInstance.setFieldValue('weighingMode', newValue.sort(), false);
  };

  return (
    <Fieldset legend={t('weighingModeSectionHeader')} className="mt-1vh">
      <div className="p-fluid formgrid grid">
        {MODE_OPTIONS.map((option, i) => (
          <div className="field-checkbox col-12" key={option.label}>
            <Checkbox
              id={`weighingMode-${i}`}
              checked={formikInstance.values?.weighingMode?.includes(option.value)}
              onChange={(e) => handleChangeWeighingMode(e, option.value)}
            ></Checkbox>
            <label htmlFor={`weighingMode-${i}`} className="checkbox-label">
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </Fieldset>
  );
};

export default WeighingModeSection;
