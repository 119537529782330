import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';

interface UpdateVehicleTareSectionProps {
  formikInstance: any;
  handleChangeField: any;
}

const UpdateVehicleTareSection = ({ formikInstance, handleChangeField }: UpdateVehicleTareSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationWeighing');

  return (
    <Fieldset legend={t('updateVehicleTareSectionHeader')} className="mt-1vh">
      <div className="p-fluid formgrid grid">
        <div className="field-checkbox col-12">
          <Checkbox
            id="updateVehicleTareShowCheckbox"
            checked={formikInstance.values.updateVehicleTareShowCheckbox}
            onChange={(e) => handleChangeField(e, 'checkbox')}
          ></Checkbox>
          <label htmlFor="updateVehicleTareShowCheckbox" className="checkbox-label">
            {t('updateVehicleTareShowCheckbox')}
          </label>
        </div>
        <div className="field-checkbox col-12">
          <Checkbox
            id="updateVehicleTareDefaultValue"
            checked={formikInstance.values.updateVehicleTareDefaultValue}
            onChange={(e) => handleChangeField(e, 'checkbox')}
          ></Checkbox>
          <label htmlFor="updateVehicleTareDefaultValue" className="checkbox-label">
            {t('updateVehicleTareDefaultValue')}
          </label>
        </div>
      </div>
    </Fieldset>
  );
};

export default UpdateVehicleTareSection;
