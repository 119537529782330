import { useFormik } from 'formik';
import { Button } from 'primereact/button';
// import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import FormErrorMessage from '../components/_shared/FormErrorMessage';
import LanguageSelectorUnauthorized from '../components/_shared/LanguageSelectorUnauthorized';
import usersService from '../services/ScaleoApiServices/UsersService';
import { uiActions } from '../store/ui-slice';
import { useTranslation } from 'react-i18next';

export const ConfirmSignUp = () => {
  const { t } = useTranslation('confirmSignUpPage');

  const history = useHistory();
  const dispatch = useDispatch();

  const email = useQuery();

  const formik = useFormik({
    initialValues: {
      otp: '',
    },
    validate: (data) => {
      const errors = {};

      if (!data.otp) {
        errors.otp = t('noOtpError');
      }
      return errors;
    },
    onSubmit: (formData, helpers) => {
      dispatch(uiActions.toggleIsLoading({ isLoading: true }));

      usersService
        .confirmSignUp({
          email: email,
          otp: formData.otp,
        })
        .then(() => {
          history.push('/login');
          scheduleHideLoader();
        })
        .catch((error) => {
          scheduleHideLoader();
          const errorCode = error.response.data.error;
          // TODO: i18n
          errorCode === 'OTP_ERROR' && helpers.setFieldError('otp', t('otpError'));
        });
    },
  });

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => {
      const queryString = search.startsWith('?') ? search.substring(1) : search;
      const emailMatch = queryString.match(/email=([^&]+)/);
      return emailMatch ? emailMatch[1] : null;
    }, [search]);
  }

  const scheduleHideLoader = () => {
    setTimeout(() => {
      dispatch(uiActions.toggleIsLoading({ isLoading: false }));
    }, 400);
  };

  return (
    <div className="login-body">
      <div className="card login-panel p-fluid">
        <LanguageSelectorUnauthorized visible={false} />
        <div className="login-panel-content">
          <form>
            <div className="grid">
              <div className="col-12 logo-container">
                <img src="assets/layout/images/logo_scaleo.png" alt={t('logoAlt')} className="w-full" />
              </div>
              <div className="col-12 sm:col-6 md:col-6">
                <span className="guest-sign-in">{t('header')}</span>
              </div>
              <div className="col-12">
                <label htmlFor="otp">{t('otp')}</label>
                <InputText id="otp" value={formik.values.otp} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="otp" formikInstance={formik} />
              </div>
            </div>
          </form>

          <div className="grid">
            <div className="col-12 sm:col-6 md:col-6">
              <Button type="submit" label={t('submitButton')} icon="pi pi-check" onClick={formik.submitForm} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
