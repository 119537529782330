import { useFormik } from 'formik';
import { userActions } from '../../store/user-slice';
import bdoConfigurationService from '../../services/ScaleoApiServices/BdoConfigurationService';
import { TFunction } from 'i18next';

const isUuidV4 = (id: string) => {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(id);
};

const useBdoConfigurationForm = (
  bdoConfigurationData: any,
  fetchData: () => void,
  dispatch: any,
  setIsCorrectConfig: any,
  t: TFunction,
) => {
  const formik = useFormik({
    initialValues: bdoConfigurationData,
    validate: (data: any) => {
      const errors = {} as any;

      if (data.moduleEnabled && (data.isChanged || !data.isLogged)) {
        if (!data.clientId) {
          errors.clientId = t('clientIdError');
        } else if (!isUuidV4(data.clientId)) {
          errors.clientId = t('clientIdInfoError');
        }
        if (!data.clientSecret) {
          errors.clientSecret = t('clientSecretError');
        } else if (!/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9]*$/.test(data.clientSecret)) {
          errors.clientSecret = t('clientSecretInfoError');
        }
      }

      return errors;
    },
    onSubmit: async (formData) => {
      const { ...newFormData } = formData;

      newFormData.isChanged = newFormData.isChanged || !newFormData.isLogged;
      newFormData.apiType = formik.values.apiType?.value ? formik.values.apiType?.value : formik.values.apiType;

      const response = await bdoConfigurationService.updateConfiguration(newFormData.id, newFormData);

      setIsCorrectConfig(!!response.data.isCorrectConfig);

      if (response?.data?.isCorrectConfig === true) {
        await fetchData();
      }

      dispatch(userActions.shouldRefreshSystemConfiguration(true));
    },
    enableReinitialize: true,
  });

  const resetForm = () => {
    formik.resetForm();
  };

  return { formik, resetForm };
};

export default useBdoConfigurationForm;
