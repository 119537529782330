import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import locationsService from '../../../../services/ScaleoApiServices/LocationsService';
import '../../../DataTable.css';
import BdoApiService from '../../../../services/BdoApiService';
import { Dropdown } from 'primereact/dropdown';
import { userActions } from '../../../../store/user-slice';
import { useDispatch } from 'react-redux';
import validator from 'validator';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import useModulesConfiguration from '../../../../utils/useModulesConfiguration';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';
import { Location } from '../../../../types/location';

interface NewOrEditLocationDialogProps {
  visible: boolean;
  closeDialog: (e: any) => void;
  item: Location | null;
}

const INIT_FORM_STATE = {
  name: '',
  shortName: '',
  bdoEupId: '',
  street: '',
  postalCode: '',
  city: '',
  isTestWeighing: true,
};

const NewOrEditLocationDialog = ({ visible, closeDialog, item }: NewOrEditLocationDialogProps) => {
  const { t } = useTranslation('managementLocations');

  const modulesConfiguration = useModulesConfiguration();
  const dispatch = useDispatch();
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const [eupOptions, setEupOptions] = useState([]);

  const fetchAPI = useCallback(async (bdoEnabled) => {
    if (bdoEnabled) {
      const eupIdResponse = await BdoApiService.getEups();
      const optionArray =
        eupIdResponse?.data?.items?.map((opt: any) => {
          return { label: opt.name, value: opt.eupId };
        }) || [];
      setEupOptions(optionArray);
    }
  }, []);

  useEffect(() => {
    fetchAPI(modulesConfiguration.bdo === 'enabled');
  }, [modulesConfiguration]);

  useEffect(() => {
    if (visible && item?.id) {
      const { name, shortName, bdoEupId, street, postalCode, city, isTestWeighing } = item;
      setInitFormValues({ name, shortName, bdoEupId, street, postalCode, city, isTestWeighing });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
  }, [item, visible]);

  const validateMethod = (data: any) => {
    const errors: any = {};

    if (!data.name) {
      errors.name = t('noNameError');
    } else {
      if (!data.name.match(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ 0-9-&]+$/)) {
        errors.name = t('nameInfoError');
      }
    }

    if (!data.shortName) {
      errors.shortName = t('noShortNameError');
    } else {
      if (!data.shortName.match(/^[A-ZŻŹĆĄŚĘŁÓŃ 0-9-&]+$/)) {
        errors.shortName = t('shortNameInfoError');
      }
    }

    if (!data.street) {
      errors.street = t('noStreetError');
    } else {
      if (!data.street.match(/^[\p{L}0-9-./\s]+$/u)) {
        errors.street = t('streetInfoError');
      }
    }

    if (!data.postalCode) {
      errors.postalCode = t('noPostalCodeError');
    } else {
      if (data.postalCode && !validator.isPostalCode(data.postalCode, 'any')) {
        errors.postalCode = t('postalCodeInfoError');
      }
    }

    if (!data.city) {
      errors.city = t('noCityError');
    } else {
      if (!data.city.match(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ -]+$/)) {
        errors.city = t('cityInfoError');
      }
    }

    return errors;
  };

  const handleCloseDialog = () => {
    formik.resetForm({
      values: initFormValues,
    });
    closeDialog(true);
  };

  const handleSubmit = (data: any, helpers: any) => {
    const body = data;

    if (item?.id) {
      locationsService
        .editLocation(body, item.id)
        .then(() => {
          dispatch(userActions.shouldRefreshLoggedUserContext(true));
          handleCloseDialog();
        })
        .catch((error) => {
          if (error.response.status === 409) {
            helpers.setFieldError('name', t('locationExistError'));
          }
        });
    } else {
      locationsService
        .postLocation(body)
        .then(() => {
          dispatch(userActions.shouldRefreshLoggedUserContext(true));
          handleCloseDialog();
        })
        .catch((error) => {
          if (error.response.status === 409) {
            helpers.setFieldError('name', t('locationExistError'));
          }
        });
    }
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const dialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={handleCloseDialog} />
      <Button type="submit" label={t('saveButton')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );

  const handleChangeField = (e: any, type: string) => {
    switch (type) {
      case 'text':
        formik.setFieldValue(e.target.id, e.target.value, true);
        break;

      case 'shortName':
        formik.setFieldValue(e.target.id, e.target.value?.toUpperCase(), true);
        break;

      case 'dropdown':
        formik.setFieldValue(e.target.id, e.value, false);
        break;

      case 'checkbox':
        formik.setFieldValue(e.target.id, e.checked, false);
        break;

      default:
        formik.handleChange(e);
        break;
    }
  };

  return (
    <div className="location-edit-dialog">
      <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
        <Dialog
          visible={visible}
          header={t('locationDetails')}
          modal
          className="p-fluid w-40vw"
          breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
          footer={dialogFooter}
          onHide={handleCloseDialog}
        >
          <form>
            <div className="col-12">
              <div className="field">
                <label htmlFor="name">{t('name')}</label>
                <InputText id="name" value={formik.values.name} onChange={(e: any) => handleChangeField(e, 'text')} />
                <FormErrorMessage fieldName="name" formikInstance={formik} />
              </div>
              <div className="field">
                <label htmlFor="name">{t('shortName')}</label>
                <InputText
                  id="shortName"
                  value={formik.values.shortName}
                  onChange={(e) => handleChangeField(e, 'shortName')}
                />
                <FormErrorMessage fieldName="shortName" formikInstance={formik} />
              </div>
              <div className="field">
                <label htmlFor="street">{t('street')}</label>
                <InputText
                  id="street"
                  value={formik.values.street}
                  onChange={(e: any) => handleChangeField(e, 'text')}
                />
                <FormErrorMessage fieldName="street" formikInstance={formik} />
              </div>
              <div className="field">
                <label htmlFor="postalCode">{t('postalCode')}</label>
                <InputText
                  id="postalCode"
                  value={formik.values.postalCode}
                  onChange={(e) => handleChangeField(e, 'text')}
                />
                <FormErrorMessage fieldName="postalCode" formikInstance={formik} />
              </div>
              <div className="field">
                <label htmlFor="city">{t('city')}</label>
                <InputText id="city" value={formik.values.city} onChange={(e: any) => handleChangeField(e, 'text')} />
                <FormErrorMessage fieldName="city" formikInstance={formik} />
              </div>
              <div className="field">
                {modulesConfiguration.bdo === 'enabled' ? (
                  <>
                    <label htmlFor={`bdoEupId`}>{t('bdoEupId')}</label>
                    <Dropdown
                      id={`bdoEupId`}
                      options={eupOptions}
                      value={formik.values.bdoEupId}
                      onChange={(e: any) => handleChangeField(e, 'dropdown')}
                      placeholder={t('bdoEupIdPlaceholder')}
                    />
                    <FormErrorMessage fieldName="bdoEupId" formikInstance={formik} />
                  </>
                ) : (
                  ''
                )}
              </div>
              <div className="field-checkbox">
                <Checkbox
                  id="isTestWeighing"
                  checked={formik.values.isTestWeighing}
                  onChange={(e: any) => handleChangeField(e, 'checkbox')}
                />
                <label htmlFor="isTestWeighing" className="checkbox-label">
                  {t('isTestWeighing')}
                </label>
              </div>
            </div>
          </form>
        </Dialog>
      </FormErrorMessageScroller>
    </div>
  );
};

export default NewOrEditLocationDialog;
