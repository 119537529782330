import axios from 'axios';

class BdoApiServiceComponent {
  preparePlannedTransportTimeFilter(value) {
    const prepareDateFilterString = (date) => {
      const dateValue = new Date(date);
      return `${dateValue.getFullYear()}-${('0' + (dateValue.getMonth() + 1)).slice(-2)}-${(
        '0' + dateValue.getDate()
      ).slice(-2)}`;
    };

    const result = ['', ''];
    if (value) {
      if (value[0]) {
        result[0] = prepareDateFilterString(value[0]);
      }
      if (value[1]) {
        result[1] = prepareDateFilterString(value[1]);
      }
      return result;
    }
    return value;
  }

  // #region CONFIG

  async checkLocationEupConfig(requestConfig) {
    return axios.post('/bdo/config', null, { ...requestConfig });
  }
  // #endregion

  async getBdoReport(fileFormat, filters, paginationParameters, params) {
    return axios.post(
      `/bdo/report`,
      {
        fileFormat,
        filters: {
          ...filters,
          plannedTransportTime: this.preparePlannedTransportTimeFilter(filters.plannedTransportTime),
        },
        paginationParameters,
        params,
      },
      {
        responseType: 'arraybuffer',
        loader_timeout: false,
      },
    );
  }

  // #region DATASETS
  async getEups(requestConfig) {
    return axios.get('/bdo/datasets/eups', requestConfig);
  }
  async getEup(id, requestConfig) {
    return axios.get('/bdo/datasets/eup', { ...requestConfig, params: { id } });
  }
  async getStatuses(requestConfig) {
    return axios.get('/bdo/datasets/statuses', requestConfig);
  }
  async getCompanies(search, requestConfig) {
    return axios.get('/bdo/datasets/companies', { ...requestConfig, params: { search } });
  }
  async getCompany(id, requestConfig) {
    return axios.get('/bdo/datasets/company', { ...requestConfig, params: { id } });
  }
  async getCompanyByTaxId(taxId, requestConfig) {
    return axios.get('/bdo/datasets/companybytaxid', { ...requestConfig, params: { taxId } });
  }
  async getCompanyEups(companyId, requestConfig) {
    return axios.get('/bdo/datasets/companyeups', { ...requestConfig, params: { companyId } });
  }
  async refreshWasteCodes(requestConfig) {
    return axios.post('/bdo/datasets/wastecodes', null, requestConfig);
  }
  async getWasteCodeByWasteCodeId(id, requestConfig) {
    if (id) {
      return axios.get('/bdo/datasets/wastecodes/byid', { ...requestConfig, params: { id } });
    } else {
      return { data: null };
    }
  }
  async getWasteCodes(search, requestConfig) {
    return axios.get('/bdo/datasets/wastecodes', { ...requestConfig, params: { search } });
  }
  async getWasteProcesses(requestConfig) {
    return axios.get('/bdo/datasets/wasteprocesses', requestConfig);
  }
  async getCommunes(search, requestConfig) {
    return axios.get('/bdo/datasets/communes', { ...requestConfig, params: { search } });
  }
  async getCommune(id, requestConfig) {
    return axios.get('/bdo/datasets/commune', { ...requestConfig, params: { id } });
  }
  async getCurrentEupInfo(requestConfig) {
    return axios.get('/bdo/datasets/currentEupInfo', requestConfig);
  }
  // #endregion

  // #region KPO
  // #region KPO/card
  async KPO_getPrintingPageData(KpoId, requestConfig) {
    return axios.get('/bdo/kpo/card/printingpage', { ...requestConfig, params: { KpoId } });
  }
  async KPO_getSenderSearch(filters, paginationParameters, requestConfig) {
    return axios.post(
      '/bdo/kpo/card/sender/search',
      {
        filters: {
          ...filters,
          plannedTransportTime: this.preparePlannedTransportTimeFilter(filters.plannedTransportTime),
        },
        paginationParameters,
      },
      requestConfig,
    );
  }
  async KPO_getCarrierSearch(filters, paginationParameters, requestConfig) {
    return axios.post(
      '/bdo/kpo/card/carrier/search',
      {
        filters: {
          ...filters,
          plannedTransportTime: this.preparePlannedTransportTimeFilter(filters.plannedTransportTime),
        },
        paginationParameters,
      },
      requestConfig,
    );
  }
  async KPO_getReceiverSearch(filters, paginationParameters, requestConfig) {
    return axios.post(
      '/bdo/kpo/card/receiver/search',
      {
        filters: {
          ...filters,
          plannedTransportTime: this.preparePlannedTransportTimeFilter(filters.plannedTransportTime),
        },
        paginationParameters,
      },
      requestConfig,
    );
  }
  async KPO_deleteCard(kpoId, requestConfig) {
    return axios.delete('/bdo/kpo/card', { ...requestConfig, params: { kpoId } });
  }
  async KPO_createCard(data, requestConfig) {
    return axios.post('/bdo/kpo/card', data, requestConfig);
  }
  async KPO_getPlannedCard(KpoId, CompanyType, requestConfig) {
    return axios.get('/bdo/kpo/card/planned', { ...requestConfig, params: { KpoId, CompanyType } });
  }
  async KPO_getApprovedCard(KpoId, CompanyType, requestConfig) {
    return axios.get('/bdo/kpo/card/approved', { ...requestConfig, params: { KpoId, CompanyType } });
  }
  async KPO_getGeneratedConfirmationCard(KpoId, CompanyType, requestConfig) {
    return axios.get('/bdo/kpo/card/generatedconfirmation', { ...requestConfig, params: { KpoId, CompanyType } });
  }
  async KPO_getWithDrawnCard(KpoId, CompanyType, requestConfig) {
    return axios.get('/bdo/kpo/card/withdrawn', { ...requestConfig, params: { KpoId, CompanyType } });
  }
  async KPO_getRejectedCard(KpoId, CompanyType, requestConfig) {
    return axios.get('/bdo/kpo/card/rejected', { ...requestConfig, params: { KpoId, CompanyType } });
  }
  async KPO_getReceiveConfirmationCard(KpoId, CompanyType, requestConfig) {
    return axios.get('/bdo/kpo/card/receiveconfirmation', { ...requestConfig, params: { KpoId, CompanyType } });
  }
  async KPO_getTransportConfirmationCard(KpoId, CompanyType, requestConfig) {
    return axios.get('/bdo/kpo/card/transportconfirmation', { ...requestConfig, params: { KpoId, CompanyType } });
  }
  async KPO_updatePlannedCard(data, requestConfig) {
    return axios.put('/bdo/kpo/card/planned', data, requestConfig);
  }
  async KPO_updateApprovedCard(data, requestConfig) {
    return axios.put('/bdo/kpo/card/approved', data, requestConfig);
  }
  // #endregion

  // #region KPO/status
  async KPO_setStatusApproved(data, requestConfig) {
    return axios.put('/bdo/kpo/status/approved', data, requestConfig); //PLANOWANA -> ZATWIERDZONA
  }
  async KPO_setStatusGeneratedConfirmationSender(data, requestConfig) {
    return axios.put('/bdo/kpo/status/generatedconfirmation/sender', data, requestConfig); //ZATWIERDZONA -> POTWIERDZENIE WYGENEROWANE
  }
  async KPO_setStatusGeneratedConfirmationCarrier(data, requestConfig) {
    return axios.put('/bdo/kpo/status/generatedconfirmation/carrier', data, requestConfig); //ZATWIERDZONA -> POTWIERDZENIE WYGENEROWANE
  }
  async KPO_setStatusWithDrawn(data, requestConfig) {
    return axios.put('/bdo/kpo/status/withdrawn', data, requestConfig); //ZATWIERDZONA lub POTWIERDZENIE WYGENEROWANE -> WYCOFANA
  }
  async KPO_setStatusRejected(data, requestConfig) {
    return axios.put('/bdo/kpo/status/rejected', data, requestConfig); //POTWIERDZENIE WYGENEROWANE -> ODRZUCONA
  }
  async KPO_setStatusReceiveConfirmation(data, requestConfig) {
    return axios.put('/bdo/kpo/status/receiveconfirmation', data, requestConfig); //POTWIERDZENIE WYGENEROWANE -> POTWIERDZENIE PRZYJĘCIA
  }
  async KPO_setStatusTransportConfirmation(data, requestConfig) {
    return axios.put('/bdo/kpo/status/transportconfirmation', data, requestConfig); //POTWIERDZENIE PRZYJĘCIA -> POTWIERDZENIE TRANSPORTU
  }
  async KPO_reviseCard(data, requestConfig) {
    return axios.put('/bdo/kpo/status/revise', data, requestConfig); //Korekta odrzuconej karty
  }
  // #endregion

  // #region KPO/document
  async KPO_downloadConfirmation(KpoId, requestConfig) {
    return axios.get('/bdo/kpo/document/confirmation', { ...requestConfig, params: { KpoId } }); //Pobranie potwierdzenia dokumentu
  }
  async KPO_downloadDocument(KpoId, requestConfig) {
    return axios.get('/bdo/kpo/document/card', { ...requestConfig, params: { KpoId } }); //Pobranie dokumentu
  }
  // #endregion
  // #endregion

  // #region KPOK
  // #region KPOK/card
  async KPOK_getPrintingPageDataReceive(KpokId, requestConfig) {
    return axios.get('/bdo/kpok/card/printingpage/receive', { ...requestConfig, params: { KpokId } });
  }
  async KPOK_getPrintingPageDataTransfer(KpokId, requestConfig) {
    return axios.get('/bdo/kpok/card/printingpage/transfer', { ...requestConfig, params: { KpokId } });
  }
  async KPOK_getSenderSearch(filters, paginationParameters, requestConfig) {
    return axios.post(
      '/bdo/kpok/card/sender/search',
      {
        filters: {
          ...filters,
          plannedTransportTime: this.preparePlannedTransportTimeFilter(filters.plannedTransportTime),
        },
        paginationParameters,
      },
      requestConfig,
    );
  }
  async KPOK_getCarrierSearch(filters, paginationParameters, requestConfig) {
    return axios.post(
      '/bdo/kpok/card/carrier/search',
      {
        filters: {
          ...filters,
          plannedTransportTime: this.preparePlannedTransportTimeFilter(filters.plannedTransportTime),
        },
        paginationParameters,
      },
      requestConfig,
    );
  }
  async KPOK_getReceiverSearch(filters, paginationParameters, requestConfig) {
    return axios.post(
      '/bdo/kpok/card/receiver/search',
      {
        filters: {
          ...filters,
          plannedTransportTime: this.preparePlannedTransportTimeFilter(filters.plannedTransportTime),
        },
        paginationParameters,
      },
      requestConfig,
    );
  }
  async KPOK_deleteCard(kpokId, requestConfig) {
    return axios.delete('/bdo/kpok/card', { ...requestConfig, params: { kpokId } });
  }
  async KPOK_createTransferCard(data, requestConfig) {
    return axios.post('/bdo/kpok/card/transfer', data, requestConfig);
  }
  async KPOK_createReceiveCard(data, requestConfig) {
    return axios.post('/bdo/kpok/card/receive', data, requestConfig);
  }
  async KPOK_getPlannedCard(KpokId, CompanyType, requestConfig) {
    return axios.get('/bdo/kpok/card/planned', { ...requestConfig, params: { KpokId, CompanyType } });
  }
  async KPOK_getApprovedCard(KpokId, CompanyType, requestConfig) {
    return axios.get('/bdo/kpok/card/approved', { ...requestConfig, params: { KpokId, CompanyType } });
  }
  async KPOK_getGeneratedConfirmationCard(KpokId, CompanyType, requestConfig) {
    return axios.get('/bdo/kpok/card/generatedconfirmation', { ...requestConfig, params: { KpokId, CompanyType } });
  }
  async KPOK_getWithDrawnCard(KpokId, CompanyType, requestConfig) {
    return axios.get('/bdo/kpok/card/withdrawn', { ...requestConfig, params: { KpokId, CompanyType } });
  }
  async KPOK_getRejectedCard(KpokId, CompanyType, requestConfig) {
    return axios.get('/bdo/kpok/card/rejected', { ...requestConfig, params: { KpokId, CompanyType } });
  }
  async KPOK_getReceiveConfirmationCard(KpokId, CompanyType, requestConfig) {
    return axios.get('/bdo/kpok/card/receiveconfirmation', { ...requestConfig, params: { KpokId, CompanyType } });
  }
  async KPOK_getTransportConfirmationCard(KpokId, CompanyType, requestConfig) {
    return axios.get('/bdo/kpok/card/transportconfirmation', { ...requestConfig, params: { KpokId, CompanyType } });
  }
  async KPOK_updatePlannedTransferCard(data, requestConfig) {
    return axios.put('/bdo/kpok/card/planned/transfer', data, requestConfig);
  }
  async KPOK_updatePlannedReceiveCard(data, requestConfig) {
    return axios.put('/bdo/kpok/card/planned/receive', data, requestConfig);
  }
  async KPOK_updateApprovedTransferCard(data, requestConfig) {
    return axios.put('/bdo/kpok/card/approved/transfer', data, requestConfig);
  }
  async KPOK_updateApprovedReceiveCard(data, requestConfig) {
    return axios.put('/bdo/kpok/card/approved/receive', data, requestConfig);
  }
  // #endregion

  // #region KPOK/status
  async KPOK_setStatusApproved(data, requestConfig) {
    return axios.put('/bdo/kpok/status/approved', data, requestConfig); //PLANOWANA -> ZATWIERDZONA
  }
  async KPOK_setStatusGeneratedConfirmation(data, requestConfig) {
    return axios.put('/bdo/kpok/status/generatedconfirmation', data, requestConfig); //ZATWIERDZONA -> POTWIERDZENIE WYGENEROWANE
  }
  async KPOK_setStatusGeneratedConfirmationCarrier(data, requestConfig) {
    return axios.put('/bdo/kpok/status/generatedconfirmation/carrier', data, requestConfig); //ZATWIERDZONA -> POTWIERDZENIE WYGENEROWANE
  }
  async KPOK_setStatusWithDrawn(data, requestConfig) {
    return axios.put('/bdo/kpok/status/withdrawn', data, requestConfig); //ZATWIERDZONA lub POTWIERDZENIE WYGENEROWANE -> WYCOFANA
  }
  async KPOK_setStatusRejected(data, requestConfig) {
    return axios.put('/bdo/kpok/status/rejected', data, requestConfig); //POTWIERDZENIE WYGENEROWANE -> ODRZUCONA
  }
  async KPOK_setStatusReceiveConfirmation(data, requestConfig) {
    return axios.put('/bdo/kpok/status/receiveconfirmation', data, requestConfig); //POTWIERDZENIE WYGENEROWANE -> POTWIERDZENIE PRZYJĘCIA
  }
  async KPOK_setStatusTransportConfirmation(data, requestConfig) {
    return axios.put('/bdo/kpok/status/transportconfirmation', data, requestConfig); //POTWIERDZENIE PRZYJĘCIA -> POTWIERDZENIE TRANSPORTU
  }
  async KPOK_reviseCard(data, requestConfig) {
    return axios.put('/bdo/kpok/status/revise', data, requestConfig); //Korekta odrzuconej karty
  }
  // #endregion

  // #region KPOK/document
  async KPOK_downloadConfirmation(KpokId, requestConfig) {
    return axios.get('/bdo/kpok/document/confirmation', { ...requestConfig, params: { KpokId } }); //Pobranie potwierdzenia dokumentu
  }
  async KPOK_downloadTransferDocument(KpokId, requestConfig) {
    return axios.get('/bdo/kpok/document/card/transfer', { ...requestConfig, params: { KpokId } }); //Pobranie dokumentu
  }
  async KPOK_downloadReceiveDocument(KpokId, requestConfig) {
    return axios.get('/bdo/kpok/document/card/receive', { ...requestConfig, params: { KpokId } }); //Pobranie dokumentu
  }
  // #endregion
  // #endregion
}

const BdoApiService = new BdoApiServiceComponent();
export default BdoApiService;
