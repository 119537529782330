import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import stationsService from '../../services/ScaleoApiServices/StationsService';
import { Station, StationType } from '../../types/station';

export const useStationForm = (
  station: Station | null,
  onStationAdded: ((s: Station) => void) | undefined,
  setStationDialogVisible: (b: boolean) => void,
) => {
  const { t } = useTranslation('managementStations');

  const formik = useFormik({
    initialValues: station ?? emptyStation,
    validate: (data) => {
      const errors = {} as any;

      if (!data.name) {
        errors.name = t('noStationNameError');
      }
      if (!data.locationId) {
        errors.location = t('noStationLocationError');
      }

      return errors;
    },
    onSubmit: async (formData, helpers) => {
      if (!formData.id) {
        await stationsService
          .createStation(formData)
          .then((res) => {
            hideDialog();
            onStationAdded && onStationAdded(res.data);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('stationExistsError'));
            }
          });
      } else {
        await stationsService
          .editStation(formData)
          .then((res) => {
            hideDialog();
            onStationAdded && onStationAdded(res.data);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', t('stationExistError'));
            }
          });
      }
    },
    enableReinitialize: true,
  });

  const hideDialog = () => {
    setStationDialogVisible(false);
    formik.resetForm({ values: emptyStation });
  };

  return {
    formik,
    hideDialog,
  };
};

const emptyStation = {
  id: null,
  name: '',
  installationId: '',
  locationId: '',
  type: StationType.WEIGHING_STATION,
} as Station;
