import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerState } from '../../../types/reducer-state';
import { Dialog } from 'primereact/dialog';
import { ConfirmationsPanel } from './components/ConfirmationsPanel';
import { confirmationsActions } from '../../../store/confirmations-slice';
import { useTranslation } from 'react-i18next';

export const ConfirmationsPanelDialog = () => {
  const { confirmationsToHandle } = useSelector((state: ReducerState) => state.confirmations);
  const dispatch = useDispatch();

  const { t } = useTranslation('accessControl');

  return confirmationsToHandle.length > 0 ? (
    <Dialog
      visible
      breakpoints={{ '960px': '90vw', '640px': '100vw' }}
      header={t('title')}
      modal
      className="p-fluid w-33vw"
      onHide={() => dispatch(confirmationsActions.deleteAllConfirmations())}
    >
      <ConfirmationsPanel confirmations={confirmationsToHandle} />
    </Dialog>
  ) : (
    <></>
  );
};
