import BdoApiService from '../../../../services/BdoApiService';
import { getFileFromText } from '../../../../utils/getFile';

const KPO_downloadConfirmation = async (kpoId) => {
  return BdoApiService.KPO_downloadConfirmation(kpoId).then((response) => {
    getFileFromText(response.data, 'application/pdf');
  });
};

const KPO_downloadDocument = async (kpoId) => {
  return BdoApiService.KPO_downloadDocument(kpoId).then((response) => {
    getFileFromText(response.data, 'application/pdf');
  });
};

const documentUtils = {
  KPO_downloadConfirmation,
  KPO_downloadDocument,
};

export default documentUtils;
