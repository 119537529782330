import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';

interface DeleteContainersDialogProps {
  deleteContainersDialogVisible: boolean;
  hideDeleteContainersDialog: () => void;
  deleteSelectedContainers: () => void;
}

export const DeleteContainersDialog = ({
  deleteContainersDialogVisible,
  hideDeleteContainersDialog,
  deleteSelectedContainers,
}: DeleteContainersDialogProps) => {
  const { t } = useTranslation('datasetsContainers');

  const deleteContainersDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteContainersDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteSelectedContainers} />
    </>
  );

  return (
    <Dialog
      visible={deleteContainersDialogVisible}
      header={
        <span className="align-items-center flex">
          <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
          {t('confirm')}
        </span>
      }
      modal
      className="p-fluid min-width-450px"
      footer={deleteContainersDialogFooter}
      onHide={hideDeleteContainersDialog}
      breakpoints={{ '896px': '90vw' }}
    >
      <div className="confirmation-content">{<span>{t('deleteContainersContent')}</span>}</div>
    </Dialog>
  );
};
