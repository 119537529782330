import { Message } from 'primereact/message';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomDivider from '../../../_shared/CustomDivider';

const DisabledWeighing = () => {
  const { t } = useTranslation('weighingHandlingWeighings');

  return (
    <div className="grid">
      <div className="col-12">
        <div className="card docs no-margin">
          <Message severity="info" text={t('dwInfo')} />
          <CustomDivider />
          <span dangerouslySetInnerHTML={{ __html: t('dwContent') }} />
        </div>
      </div>
    </div>
  );
};

export default DisabledWeighing;
