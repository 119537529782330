import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';
import { Trailer } from '../../../../types/trailer';
import { useTrailerForm } from '../../../../hooks/Trailers/useTrailerForm';

interface TrailerDialogProps {
  visible: boolean;
  setTrailerDialogVisible: (b: boolean) => void;
  onTrailerAdded?: (trailer: Trailer) => void;
  trailer: Trailer | null;
}

export const TrailerDialog = ({ visible, setTrailerDialogVisible, onTrailerAdded, trailer }: TrailerDialogProps) => {
  const { t } = useTranslation('datasetsTrailers');

  const { formik, hideDialog } = useTrailerForm(trailer, onTrailerAdded, setTrailerDialogVisible);

  const trailerDialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={hideDialog} />
      <Button type="submit" label={t('saveButton')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );
  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={visible}
        header={t('trailerDetails')}
        modal
        className="p-fluid w-40vw"
        footer={trailerDialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      >
        <form>
          <div className="field">
            <label htmlFor="registrationNumber">{t('registrationNumber')}</label>
            <InputText
              id="registrationNumber"
              value={formik.values.registrationNumber}
              onChange={formik.handleChange}
              autoFocus
            />
            <FormErrorMessage fieldName="registrationNumber" formikInstance={formik} />
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};
