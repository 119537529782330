import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';

const PrevPageLinkComponent = ({ onClick, className, disabled }) => {
  const { t } = useTranslation('utils');
  return (
    <button type="button" className={className} onClick={onClick} disabled={disabled}>
      <span className="p-3">{t('previous')}</span>
    </button>
  );
};

const NextPageLinkComponent = ({ onClick, className, disabled }) => {
  const { t } = useTranslation('utils');
  return (
    <button type="button" className={className} onClick={onClick} disabled={disabled}>
      <span className="p-3">{t('next')}</span>
    </button>
  );
};

const getPaginatorTemplate = (paginatorState, setPaginatorState) => {
  const handleChangePage = (pageNumber) => {
    setPaginatorState({ ...paginatorState, currentPage: pageNumber });
  };

  const handleChangeRowsPerPage = (e) => {
    setPaginatorState({ ...paginatorState, rows: e.value, currentPage: 1 });
  };

  return {
    layout: 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown',
    RowsPerPageDropdown: () => {
      const dropdownOptions = [5, 10, 25, 50];

      return (
        <Dropdown
          value={paginatorState.rows}
          options={dropdownOptions}
          onChange={handleChangeRowsPerPage}
          appendTo={document.body}
        />
      );
    },
    FirstPageLink: () => (
      <button
        type="button"
        className={`p-paginator-first p-paginator-element p-link ${
          paginatorState.currentPage === 1 ? 'p-disabled' : ''
        }`}
        onClick={() => handleChangePage(1)}
        disabled={paginatorState.currentPage === 1}
      >
        <span className="p-paginator-icon pi pi-angle-double-left" />
      </button>
    ),
    PrevPageLink: () => (
      <PrevPageLinkComponent
        onClick={() => handleChangePage(paginatorState.currentPage - 1)}
        className={`p-paginator-prev p-paginator-element p-link ${
          paginatorState.currentPage === 1 ? 'p-disabled' : ''
        }`}
        disabled={paginatorState.currentPage === 1}
      />
    ),
    NextPageLink: () => (
      <NextPageLinkComponent
        className={`p-paginator-next p-paginator-element p-link ${
          paginatorState.currentPage === paginatorState.totalPages || paginatorState.totalPages === 0
            ? 'p-disabled'
            : ''
        }`}
        onClick={() => handleChangePage(paginatorState.currentPage + 1)}
        disabled={paginatorState.currentPage === paginatorState.totalPages || paginatorState.totalPages === 0}
      />
    ),
    LastPageLink: () => (
      <button
        type="button"
        className={`p-paginator-last p-paginator-element p-link ${
          paginatorState.currentPage === paginatorState.totalPages ? 'p-disabled' : ''
        }`}
        onClick={() => handleChangePage(paginatorState.totalPages)}
        disabled={paginatorState.currentPage === paginatorState.totalPages}
      >
        <span className="p-paginator-icon pi pi-angle-double-right" />
      </button>
    ),
    CurrentPageReport: () => {
      return (
        <span className="p-paginator-pages">
          {[...Array(paginatorState.totalPages)].map((_, index) => {
            if (index + 1 > paginatorState.currentPage + 2 && index + 1 < paginatorState.totalPages - 1) {
              return null;
            }
            if (index - 1 < paginatorState.currentPage - 4 && index - 1 > 0) {
              return null;
            }

            if (
              (index + 1 === paginatorState.currentPage + 2 && index + 1 < paginatorState.totalPages - 1) ||
              (index - 1 === paginatorState.currentPage - 4 && index - 1 > 0)
            ) {
              return (
                <span key={index} className="p-paginator-page p-paginator-element p-link p-disabled select-none">
                  ...
                </span>
              );
            }

            return (
              <button
                key={index}
                type="button"
                className={`p-paginator-page p-paginator-element p-link ${
                  index + 1 === paginatorState.currentPage ? 'p-highlight' : ''
                }`}
                onClick={() => handleChangePage(index + 1)}
              >
                {index + 1}
              </button>
            );
          })}
        </span>
      );
    },
  };
};

export default getPaginatorTemplate;
