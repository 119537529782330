import UAParser from 'ua-parser-js';

export const getLoggedDeviceInfo = () => {
  const parser = new UAParser();
  const result = parser.getResult();

  const browser = result.browser.name ? result.browser.name + ',' : '';
  const model = result.device.model ? result.device.model + ',' : '';
  const OS = result.os.name ? result.os.name : '';

  return `${browser} ${model} ${OS}`;
};
