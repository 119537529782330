import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Password } from 'primereact/password';
import { InputNumber } from 'primereact/inputnumber';
import FormErrorMessage from '../../../../_shared/FormErrorMessage';
import { useTranslation } from 'react-i18next';

interface SmptConfigProps {
  formikInstance: any;
  handleChangeField: Function;
  hasNoData: boolean | null;
}

const SmtpConfig = ({ formikInstance, handleChangeField, hasNoData }: SmptConfigProps) => {
  const { t } = useTranslation('managementSystemConfigurationEmails');

  return (
    <Fieldset legend={t('domainConfiguration')} className="">
      <div className="grid">
        <div className="col-12">
          <span className="field-checkbox md:col-12">
            <Checkbox
              id="isClientSMTP"
              checked={formikInstance.values.isClientSMTP}
              onChange={(e) => handleChangeField(e, 'checkbox')}
            ></Checkbox>
            <label htmlFor="isClientSMTP" className="checkbox-label">
              {t('ownSmtpServer')}
            </label>
          </span>
          {formikInstance.values.isClientSMTP && (
            <Fieldset legend={t('smtpServerConfiguration')} className="mt-1vh">
              <div className="p-fluid formgrid grid">
                <label htmlFor="smtpEmail" className="col-12 sm:col-6 lg:col-3">
                  {t('mail')}
                </label>
                <div className="field col-12 sm:col-6 lg:col-4 max-width-20rem">
                  <InputText
                    id="smtpEmail"
                    value={formikInstance.values.smtpEmail}
                    onChange={formikInstance.handleChange}
                  />
                  <FormErrorMessage fieldName="smtpEmail" formikInstance={formikInstance} />
                </div>
              </div>
              <div className="p-fluid formgrid grid">
                <label htmlFor="smtpName" className="col-12 sm:col-6 lg:col-3">
                  {t('serverName')}
                </label>
                <div className="field col-12 sm:col-6 lg:col-4 max-width-20rem">
                  <InputText
                    id="smtpName"
                    value={formikInstance.values.smtpName}
                    onChange={formikInstance.handleChange}
                  />
                  <FormErrorMessage fieldName="smtpName" formikInstance={formikInstance} />
                </div>
              </div>
              <div className="p-fluid formgrid grid">
                <label htmlFor="smtpPort" className="col-12 sm:col-6 lg:col-3">
                  {t('port')}
                </label>
                <div className="field col-12 sm:col-6 lg:col-4 max-width-20rem">
                  <InputNumber
                    id="smtpPort"
                    value={formikInstance.values.smtpPort}
                    onValueChange={formikInstance.handleChange}
                  />
                  <FormErrorMessage fieldName="smtpPort" formikInstance={formikInstance} />
                </div>
              </div>
              {!hasNoData && (
                <span className="field-checkbox md:col-12">
                  <Checkbox
                    id="isChanged"
                    checked={formikInstance.values.isChanged}
                    onChange={(e) => handleChangeField(e, 'checkbox')}
                  ></Checkbox>
                  <label htmlFor="isChanged" className="checkbox-label">
                    {t('bdoIsChanged')}
                  </label>
                  <FormErrorMessage fieldName="isChanged" formikInstance={formikInstance} />
                </span>
              )}
              {(hasNoData || formikInstance.values.isChanged) && (
                <>
                  <div className="p-fluid formgrid grid">
                    <label htmlFor="smtpUser" className="col-12 sm:col-6 lg:col-3">
                      {t('user')}
                    </label>
                    <div className="field col-12 sm:col-6 lg:col-4 max-width-20rem">
                      <InputText
                        id="smtpUser"
                        value={formikInstance.values.smtpUser}
                        onChange={formikInstance.handleChange}
                      />
                      <FormErrorMessage fieldName="smtpUser" formikInstance={formikInstance} />
                    </div>
                  </div>
                  <div className="p-fluid formgrid grid">
                    <label htmlFor="smtpUserPassword" className="col-12 sm:col-6 lg:col-3">
                      {t('password')}
                    </label>
                    <div className="field col-12 sm:col-6 lg:col-4 max-width-20rem">
                      <Password
                        id="smtpUserPassword"
                        name="smtpUserPassword"
                        value={formikInstance.values.smtpUserPassword}
                        onChange={formikInstance.handleChange}
                        feedback={false}
                      />
                      <FormErrorMessage fieldName="smtpUserPassword" formikInstance={formikInstance} />
                    </div>
                  </div>
                </>
              )}
            </Fieldset>
          )}
        </div>
      </div>
    </Fieldset>
  );
};

export default SmtpConfig;
