import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import driversService from '../../../services/ScaleoApiServices/DriversService';
import '../../DataTable.css';
import { CustomTableField, CustomButton } from '../../_shared/CustomComponents';
import DataTableFilterElements from '../../_shared/DataTableFilterElements';
import ImportInformationDialog from '../../_shared/ImportInformationDialog';
import { NewOrEditDriverDialog } from './components/NewOrEditDriverDialog';
import { userActions } from '../../../store/user-slice';
import { getFileFromText } from '../../../utils/getFile';
import getPaginatorTemplate from '../../../utils/getPaginatorTemplate';
import { useTranslation, Trans } from 'react-i18next';
import { ReducerState } from '../../../types/reducer-state';
import { Driver } from '../../../types/weighing';

export const Drivers = () => {
  const { t } = useTranslation('datasetsDrivers');

  const emptyDriver = {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };

  const emptyPaginationParameters = {
    order: {
      isAscending: true,
      orderColumn: 'id',
    },
    page: {
      index: 1,
      size: 10,
    },
  };

  const emptyPaginatorState = {
    currentPage: 1,
    totalPages: 1,
    rows: 10,
  };

  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState(emptyDriver);
  const [selectedDrivers, setSelectedDrivers] = useState<Driver[]>([]);
  const [driverDialogVisible, setDriverDialogVisible] = useState(false);
  const [deleteDriversDialogVisible, setDeleteDriversDialogVisible] = useState(false);
  const [deleteDriverDialogVisible, setDeleteDriverDialogVisible] = useState(false);
  const [selectedRowToEdit, setSelectedRowToEdit] = useState(null);

  const dispatch = useDispatch();

  const filters = useSelector((state: ReducerState) => state.user.filtersState.drivers);
  const [paginationParameters, setPaginationParameters] = useState(emptyPaginationParameters);
  const [paginatorState, setPaginatorState] = useState(emptyPaginatorState);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const dt = useRef(null);
  const fileUploader = useRef<FileUpload>(null);
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
  const [importInformationDialogData, setImportInformationDialogData] = useState(null);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const fetchDrivers = useCallback(
    async (_filters, _paginatorParam) => {
      driversService.searchDrivers(_filters, _paginatorParam).then((response) => {
        setDrivers(response.data);
        response.totalPages
          ? setPaginatorState({
              ...paginatorState,
              totalPages: response.totalPages,
            })
          : setPaginatorState({
              ...paginatorState,
              totalPages: 1,
              currentPage: 1,
            });
      });
    },
    [paginatorState],
  );

  useEffect(() => {
    fetchDrivers(filters, paginationParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, paginationParameters, loggedUserContext?.currentCustomer?.id, driverDialogVisible]);

  const openNew = () => {
    setDriver(emptyDriver);
    setSelectedRowToEdit(null);
    setDriverDialogVisible(true);
  };
  const deleteSelectedDrivers = () => {
    for (const _driver of selectedDrivers) {
      driversService.removeDriver(_driver?.id).then(() => {
        fetchDrivers(filters, paginationParameters);
      });
    }
    setDeleteDriversDialogVisible(false);
    setSelectedDrivers([]);
  };
  const confirmDeleteSelected = () => {
    setDeleteDriversDialogVisible(true);
  };
  const hideDeleteDriversDialog = () => {
    setDeleteDriversDialogVisible(false);
  };
  const confirmDeleteDriver = (_driver: any) => {
    setDriver(_driver);
    setDeleteDriverDialogVisible(true);
  };
  const hideDeleteDriverDialog = () => {
    setDeleteDriverDialogVisible(false);
  };

  const editDriver = (_driver: any) => {
    setDriver(_driver);
    setSelectedRowToEdit(_driver);
    setDriverDialogVisible(true);
  };

  const deleteDriver = () => {
    driversService.removeDriver(driver.id).then(() => {
      fetchDrivers(filters, paginationParameters);
    });

    setDeleteDriverDialogVisible(false);
    setDriver(emptyDriver);
  };

  const changeFilter = (value: any, name: any) => {
    const newFilters = { ...filters };
    newFilters[name] = value;
    dispatch(userActions.setDriversFilters(newFilters));
  };

  const clearFilters = () => {
    dispatch(userActions.setDriversFilters({}));
  };

  useEffect(() => {
    setPaginationParameters((prevPaginationParameters) => ({
      ...prevPaginationParameters,
      page: {
        ...prevPaginationParameters.page,
        index: 1,
      },
    }));
  }, [filters]);

  useEffect(() => {
    if (paginatorState.currentPage > paginatorState.totalPages)
      setPaginatorState((prevPaginatorState) => ({
        ...prevPaginatorState,
        currentPage: 1,
      }));
  }, [paginatorState]);

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const header = (
    <div className="table-header text-2xl p-2">
      {t('header')} &#160;&#160;
      <div className="flex">
        <Button label={t('clearFilters')} icon="pi pi-filter-slash" outlined className="mr-2" onClick={clearFilters} />
        <span>
          <DataTableFilterElements.Text
            initialValue={filters['global']}
            onChangeFilter={changeFilter}
            name={'global'}
            placeholder={t('searchPlaceholder')}
            isSearchIcon
          />
        </span>
      </div>
    </div>
  );

  const deleteDriversDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteDriversDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteSelectedDrivers} />
    </>
  );
  const deleteDriverDialogFooter = (
    <>
      <Button label={t('no')} icon="pi pi-times" text onClick={hideDeleteDriverDialog} />
      <Button label={t('yes')} icon="pi pi-check" text onClick={deleteDriver} />
    </>
  );

  const leftToolbarTemplate = () => {
    return (
      <>
        <Button label={t('addButton')} icon="pi pi-plus" severity="success" className="mr-2" onClick={openNew} />
        <CustomButton
          label={t('deleteButton')}
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={
            !selectedDrivers ||
            !selectedDrivers.length ||
            selectedDrivers.map((driver: any) => driver?.isUsed).includes(true)
          }
          tooltip={!selectedDrivers.length ? t('selectDriver') : t('cannotDelete')}
        />
      </>
    );
  };

  const exportData = async () => {
    const csvResponse = await driversService.exportData();
    const csv = csvResponse.data;
    getFileFromText(csv, 'text/csv', `drivers-${Date.now().toString()}.csv`);
  };

  const importData = (event: any) => {
    let csvData = '';
    const [f] = event.files;
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      csvData = Buffer.from(e.target.result).toString('base64');
      const insertResponse = await driversService.importData(csvData);

      fileUploader.current?.clear();
      await fetchDrivers(filters, paginationParameters);
      setImportInformationDialogData(insertResponse.data);
      setIsOpenImportDialog(true);
    };
    reader.readAsText(f);
  };

  const rightToolbarTemplate = () => {
    return (
      <>
        <FileUpload
          ref={fileUploader}
          mode="basic"
          accept=".csv"
          maxFileSize={1000000}
          name={t('import')}
          chooseLabel={t('import')}
          className="mr-2"
          customUpload
          auto
          uploadHandler={importData}
        />
        <Button label={t('export')} icon="pi pi-upload" className="p-button-help" onClick={exportData} />
      </>
    );
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <Button icon="pi pi-pencil" raised severity="success" className="mr-2" onClick={() => editDriver(rowData)} />
        {rowData.isUsed === false ? (
          <Button icon="pi pi-trash" raised severity="warning" onClick={() => confirmDeleteDriver(rowData)} />
        ) : (
          <Button
            icon="pi pi-trash"
            raised
            severity="secondary"
            tooltip={t('tooltip')}
            tooltipOptions={{ position: 'bottom' }}
          />
        )}
      </div>
    );
  };

  const columnBodyTemplate = (rowData: any, column: any) => {
    return <CustomTableField row={rowData} col={column} />;
  };

  const columns = [
    { field: 'firstName', header: t('firstName'), type: 'text' },
    { field: 'lastName', header: t('lastName'), type: 'text' },
    { field: 'email', header: t('email'), type: 'text' },
    { field: 'phone', header: t('phone'), type: 'text' },
  ];

  const dynamicColumns = columns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={columnBodyTemplate}
        fieldsType={col.type}
        sortable
        filter
        showFilterMenu={false}
        filterElement={
          <DataTableFilterElements.Text
            initialValue={filters[col.field]}
            onChangeFilter={changeFilter}
            name={col.field}
            placeholder={t('filters')}
          />
        }
      ></Column>
    );
  });

  return (
    <div className="grid">
      <ImportInformationDialog
        data={importInformationDialogData}
        visible={isOpenImportDialog}
        closeDialog={() => setIsOpenImportDialog(false)}
      />
      <div className="col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                responsiveLayout="scroll"
                ref={dt}
                value={drivers}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                rowHover
                removableSort
                selectionMode="checkbox"
                selection={selectedDrivers}
                onSelectionChange={(e: any) => setSelectedDrivers(e.value)}
                paginator
                rows={paginatorState.rows}
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState)}
                emptyMessage={t('noData')}
                stripedRows
                filterDisplay="row"
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                {dynamicColumns}
                <Column body={actionBodyTemplate} className="actionBody"></Column>
              </DataTable>
            </div>
          </div>
          <NewOrEditDriverDialog
            driverDialogVisible={driverDialogVisible}
            setDriverDialogVisible={setDriverDialogVisible}
            items={selectedRowToEdit}
          />
          <Dialog
            visible={deleteDriversDialogVisible}
            header={
              <span className="flex align-items-center">
                <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
                {t('confirm')}
              </span>
            }
            modal
            className="p-fluid min-w-min"
            footer={deleteDriversDialogFooter}
            onHide={hideDeleteDriversDialog}
            breakpoints={{ '896px': '90vw' }}
          >
            <div className="confirmation-content">{driver && <span>{t('multipleContent')}</span>}</div>
          </Dialog>
          <Dialog
            visible={deleteDriverDialogVisible}
            header={
              <span className="flex align-items-center">
                <i className="pi pi-exclamation-triangle mr-3 text-4xl" />
                {t('confirm')}
              </span>
            }
            modal
            className="p-fluid min-w-min"
            footer={deleteDriverDialogFooter}
            onHide={hideDeleteDriverDialog}
            breakpoints={{ '896px': '90vw' }}
          >
            <div className="confirmation-content">
              {driver && (
                <span>
                  <Trans t={t} i18nKey="singleContent" values={{ driver: `${driver.firstName} ${driver.lastName}` }} />
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
