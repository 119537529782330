import { Dropdown } from 'primereact/dropdown';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import usersService from '../../services/ScaleoApiServices/UsersService';
import { useDispatch, useSelector } from 'react-redux';
import { countryOptionTemplate, selectedCountryTemplate } from './LanguageSelectorsTemplates';
import { getAvailableLanguages } from '../../i18next/resources/languages-config';
import { LanguageValue } from '../../enums/language-value.enum';
import { userActions } from '../../store/user-slice';

const LanguageSelector = ({ visible }) => {
  const { i18n } = useTranslation();
  const [value, setValue] = useState(i18n.language);
  const loggedUserContext = useSelector((state) => state.user.context);
  const loggedUser = useSelector((state) => state.user.loggedUser);

  const languages = getAvailableLanguages(loggedUserContext?.limits?.usePolishBDOLanguage);

  const dispatch = useDispatch();
  const previousAppLanguage = useRef(loggedUserContext.appLanguage);

  const updateLanguage = async (language) => {
    await usersService.setAppLanguage(loggedUserContext?.currentLocationId, loggedUser?.id, language);

    setValue(language);
    await i18n.changeLanguage(language);

    dispatch(userActions.setLoggedUserContext({ ...loggedUserContext, appLanguage: language }));
  };

  useEffect(() => {
    let languageToSet = loggedUserContext?.appLanguage;

    if (loggedUserContext.appLanguage === LanguageValue.POLISH_BDO) {
      languageToSet = loggedUserContext.limits?.usePolishBDOLanguage ? LanguageValue.POLISH_BDO : LanguageValue.POLISH;
    }
    if (loggedUserContext.appLanguage === LanguageValue.POLISH) {
      languageToSet = loggedUserContext.limits?.usePolishBDOLanguage
        ? LanguageValue.POLISH_BDO
        : loggedUserContext.appLanguage;
    }

    if (languageToSet !== value && languageToSet !== previousAppLanguage.current) {
      updateLanguage(languageToSet);
      previousAppLanguage.current = languageToSet;
    }
  }, [loggedUserContext.appLanguage, loggedUserContext.limits?.usePolishBDOLanguage]);

  const handleChange = (e) => {
    const newValue = e?.value ?? null;

    if (newValue !== value) {
      updateLanguage(newValue);
    }
  };

  if (visible) {
    return (
      <Dropdown
        value={value}
        options={languages}
        onChange={handleChange}
        required
        itemTemplate={countryOptionTemplate}
        valueTemplate={selectedCountryTemplate}
        className="language-selector"
      />
    );
  }

  return <></>;
};

export default LanguageSelector;
