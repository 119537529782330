import React from 'react';
import { useSelector } from 'react-redux';
import { getTranslatedLabel, weighingModeLabels } from '../../../../../../utils/labels';
import useTransactionType from '../../../../../../utils/useTransactionType';
import MeasurementsList from '../../../../components/MeasurementsList';
import {
  WeighingSummaryTextFieldTemplate,
  WeighingSummaryDecimalFieldTemplate,
} from '../../../../components/WeighingSummaryFieldTemplates';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../../../types/reducer-state';
import { Weighing } from '../../../../../../types/weighing';
import CustomDivider from '../../../../../_shared/CustomDivider';

interface WeighingSeriesSummaryDataProps {
  weighing: Weighing;
}

const WeighingSeriesSummaryData = ({ weighing }: WeighingSeriesSummaryDataProps) => {
  const { t } = useTranslation('weighingHandlingWeighings');
  const { t: t1 } = useTranslation('utils');
  const unit = useSelector((state: ReducerState) => state.appConfig.unit);
  const { getTransactionTypeLabel } = useTransactionType();

  const renderAdditionalField = (field: any) =>
    ['DICTIONARY', 'TEXT', 'AUTO_ID'].includes(field.type) ? (
      <WeighingSummaryTextFieldTemplate label={field.name} value={field.value} />
    ) : (
      <WeighingSummaryDecimalFieldTemplate label={field.name} value={field.value} unit={field.unit} />
    );

  const sum = weighing.measurements?.reduce((acc: number, curr: any) => acc + curr.indication, 0);

  return (
    <>
      <div className="lg:col-6 md:col-12">
        <div className="formgrid grid">
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleWeighingNumber')}
            value={weighing?.number as string}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleWeighingMode')}
            value={getTranslatedLabel(weighingModeLabels, weighing?.weighingMode, t1)}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleWeighingType')}
            value={getTransactionTypeLabel(weighing?.transactionType)}
          />
          <WeighingSummaryDecimalFieldTemplate label={t('reportColumnsSum')} value={sum} unit={unit} />
        </div>
      </div>
      <div className="lg:col-6 md:col-12">
        <div className="formgrid grid">
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsList-summary-multiple-registration_number')}
            value={weighing?.vehicle.registrationNumber}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleProduct')}
            value={weighing?.product?.name}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleDriver')}
            value={weighing?.driver?.name}
          />
          <WeighingSummaryTextFieldTemplate
            label={t('openWeighingsListSummaryMultipleContractor')}
            value={weighing?.contractor?.name}
          />
          {weighing?.additionalFields?.map(renderAdditionalField)}
        </div>
      </div>
      <div className="lg:col-12 md:col-12">
        <CustomDivider>
          <span className="tag">{t('openWeighingsListSummaryMultipleMeasurementsList')}</span>
        </CustomDivider>
        <MeasurementsList measurements={weighing.measurements} showAdditionalFields columnEmptyValue={undefined} />
      </div>
    </>
  );
};

export default WeighingSeriesSummaryData;
