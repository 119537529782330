import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appConfigActions } from '../../../../store/appConfig-slice';
import { userActions } from '../../../../store/user-slice';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import systemConfigurationService from '../../../../services/ScaleoApiServices/SystemConfigurationService';
import SmtpConfig from './components/SmptConfig';
import formChangeFieldMethod from '../../../../utils/formChangeFieldMethod';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../types/reducer-state';

const INIT_FORM_STATE = {
  isChanged: false,
  isClientSMTP: false,
  smtpEmail: '',
  smtpName: '',
  smtpPort: null,
  smtpUser: '',
  smtpUserPassword: '',
};

const EmailsConfiguration = () => {
  const { t } = useTranslation('managementSystemConfigurationEmails');

  const dispatch = useDispatch();
  const [stringifiedInitFormValues, setStringifiedInitFormValues] = useState(JSON.stringify(INIT_FORM_STATE));
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const confirmationOfWindowChange = useSelector((state: ReducerState) => state.appConfig.confirmationOfWindowChange);
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const [hasNoData, setHasNoData] = useState<boolean | null>(null);

  const fetchAPI = useCallback(() => {
    systemConfigurationService.getSMTPConfig().then((response) => {
      if (response.data) {
        setInitFormValues(response.data);
        setHasNoData(!!!response.data.isClientSMTP);
        setStringifiedInitFormValues(JSON.stringify(response.data));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initFormValues]);

  useEffect(() => {
    fetchAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUserContext.currentCustomer?.id]);

  const handleSubmit = (data: any) => {
    systemConfigurationService.saveSMTPConfig({
      ...data,
      isChanged: undefined,
    });
    formik.setFieldValue('isChanged', false, false);
    formik.setFieldValue('smtpUser', '', false);
    formik.setFieldValue('smtpUserPassword', '', false);
    dispatch(userActions.shouldRefreshSystemConfiguration(true));
    setHasNoData(false);
  };

  const validateMethod = (data: any) => {
    const errors: any = {};

    if (data.isClientSMTP) {
      if (!data.smtpEmail) {
        errors.smtpEmail = t('mailValidation');
      }
      if (!data.smtpName) {
        errors.smtpName = t('nameValidation');
      }
      if (!data.smtpPort) {
        errors.smtpPort = t('portValidation');
      }
      if (!data.isChanged && !hasNoData) {
        errors.isChanged = t('secretsValidation');
        return errors;
      }
      if (!data.smtpUser) {
        errors.smtpUser = t('userValidation');
      }
      if (!data.smtpUserPassword) {
        errors.smtpUserPassword = t('passwordValidation');
      }
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const changes = JSON.stringify(formik.values) !== stringifiedInitFormValues;
    if (confirmationOfWindowChange.required !== changes && formik.values.smtpUser && formik.values.smtpUserPassword) {
      dispatch(
        appConfigActions.setConfirmationOfWindowChange({
          ...confirmationOfWindowChange,
          required: changes,
          saveMethod: formik.submitForm,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formik.values)]);

  const handleClickCancel = () => {
    formik.resetForm();
  };

  const handleClickSubmit = () => {
    formik.submitForm().then(() => {
      dispatch(appConfigActions.setConfirmationOfWindowChange({ ...confirmationOfWindowChange, required: false }));
    });
  };

  const handleChangeField = (e: any, type: string) => {
    formChangeFieldMethod(e, type, formik);
  };

  return (
    <div className="card">
      <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
        <form onSubmit={formik.handleSubmit}>
          <SmtpConfig formikInstance={formik} handleChangeField={handleChangeField} hasNoData={hasNoData} />
          <div>
            <div
              className="flex justify-content-end w-full p-ai-end bg-white fixed bottom-0px right-0px pt-10px pr-40px pb-10px pl-0px"
              style={{
                boxShadow: '0px 10px 15px black',
              }}
            >
              <Button type="reset" label={t('reset')} icon="pi pi-times" text onClick={handleClickCancel} />
              <Button type="button" label={t('save')} icon="pi pi-check" text onClick={handleClickSubmit} />
            </div>
          </div>
        </form>
      </FormErrorMessageScroller>
    </div>
  );
};

export default EmailsConfiguration;
