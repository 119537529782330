import { Button } from 'primereact/button';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import webhooksService from '../../../../services/ScaleoApiServices/WebhooksService';
import { Webhook, WebhookEvent } from '../../../../types/webhook';

interface WebhookRowProps {
  field: any;
  index: number;
  fetchAPI: () => Promise<void>;
  setWebhook: (w: Webhook) => void;
  setWebhookDialogOpen: (b: boolean) => void;
}

export const WebhookRow = ({ field, index, fetchAPI, setWebhook, setWebhookDialogOpen }: WebhookRowProps) => {
  const { t } = useTranslation('managementWebhooks');

  const handleClickDelete = useCallback(
    async (id: string) => {
      await webhooksService.deleteWebhook(id);
      await fetchAPI();
    },
    [fetchAPI, webhooksService.deleteWebhook],
  );

  const webhookEventsTranslations: Record<WebhookEvent, string> = {
    [WebhookEvent.WEIGHING_CLOSED]: t('weighingClosed'),
  };

  return (
    <div key={`webhook--${index}`}>
      <div
        className="p-fluid formgrid grid align-items-center pt-2px pb-2px pl-0 pr-0"
        style={{ backgroundColor: index % 2 === 0 ? 'whitesmoke' : '' }}
      >
        <div className="col-2">
          <span>{field.name}</span>
        </div>
        <div className="col-5">
          <span>{field.destinationUrl}</span>
        </div>
        <div className="col-3">
          <span>{webhookEventsTranslations[field.event as WebhookEvent]}</span>
        </div>
        <div className="col-1 text-center flex-nowrap flex">
          <Button
            icon="pi pi-pencil"
            type="button"
            className="p-button-sm"
            text
            onClick={() => {
              setWebhook(field);
              setWebhookDialogOpen(true);
            }}
            tooltip={t('update')}
          />
          <Button
            icon="pi pi-trash"
            type="button"
            text
            severity="danger"
            className="p-button-sm ml-2"
            onClick={async () => handleClickDelete(field.id)}
            tooltip={t('delete')}
          />
        </div>
      </div>
    </div>
  );
};
