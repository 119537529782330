import kpoReceiverEditGeneratedConfirmationCardDialog from './kpoReceiverEditGeneratedConfirmationCardDialog.json';
import kpoReceiver from './kpoReceiver.json';
import kpoCarrier from './kpoCarrier.json';
import kpoCarrierEditApprovedCardDialog from './kpoCarrierEditApprovedCardDialog.json';
import kpoCarrierEditReceiveConfirmationCardDialog from './kpoCarrierEditReceiveConfirmationCardDialog.json';
import kpoCarrierViewCardDialog from './kpoCarrierViewCardDialog.json';
import kpoReceiverViewCardDialog from './kpoReceiverViewCardDialog.json';
import kpoSender from './kpoSender.json';
import kpoSenderComponents from './kpoSenderComponents.json';
import kpoUtils from './kpoUtils.json';
import kpoChangeStatusDialogs from './kpoChangeStatusDialogs.json';
import bdoUtils from './bdoUtils.json';
import accessControl from './accessControl.json';
import accessControlEventLog from './accessControlEventLog.json';
import sharedComponents from './sharedComponents.json';
import billingData from './billingData.json';
import snapshotDialog from './snapshotDialog.json';
import axiosConfig from './axiosConfig.json';
import userProfile from './userProfile.json';
import app from './app.json';
import appTopbar from './appTopbar.json';
import confirmForgotPasswordPage from './confirmForgotPasswordPage.json';
import confirmSignUpPage from './confirmSignUpPage.json';
import createAccountPage from './createAccountPage.json';
import createSuccessfulPage from './createSuccessfulPage.json';
import errorPage from './errorPage.json';
import loginPage from './loginPage.json';
import notFoundPage from './notFoundPage.json';
import signUpPage from './signUpPage.json';
import contactComponent from './contactComponent.json';
import versionComponent from './versionComponent.json';
import datasetsAccessCodes from './datasetsAccessCodes.json';
import datasetsContractors from './datasetsContractors.json';
import datasetsDrivers from './datasetsDrivers.json';
import datasetsProducts from './datasetsProducts.json';
import datasetsVehicles from './datasetsVehicles.json';
import datasetsTares from './datasetsTares.json';
import managementApiKey from './managementApiKey.json';
import managementWebhooks from './managementWebHooks.json';
import managementDictionaries from './managementDictionaries.json';
import managementDynamicSettings from './managementDynamicSettings.json';
import managementEdgeDevices from './managementEdgeDevices.json';
import managementImportExport from './managementImportExport.json';
import managementIndicatorTypes from './managementIndicatorTypes.json';
import managementLocations from './managementLocations.json';
import managementNodes from './managementNodes.json';
import managementStations from './managementStations.json';
import managementRoles from './managementRoles.json';
import managementUsers from './managementUsers.json';
import managementSystemConfigurationBdo from './managementSystemConfigurationBdo.json';
import managementSystemConfigurationConfirmations from './managementSystemConfigurationConfirmations.json';
import managementSystemConfigurationEmails from './managementSystemConfigurationEmails.json';
import managementSystemConfigurationGraphicElements from './managementSystemConfigurationGraphicElements.json';
import managementSystemConfigurationPrints from './managementSystemConfigurationPrints.json';
import managementSystemConfigurationReports from './managementSystemConfigurationReports.json';
import managementSystemConfigurationWeighing from './managementSystemConfigurationWeighing.json';
import utils from './utils.json';
import kpokCarrier from './kpokCarrier.json';
import kpokComponents from './kpokComponents.json';
import kpokReceiver from './kpokReceiver.json';
import kpokSender from './kpokSender.json';
import weighingComponents from './weighingComponents.json';
import weighingHandlingWeighings from './weighingHandlingWeighings.json';
import weighingWeighingList from './weighingWeighingList.json';
import forgotPasswordPage from './forgotPasswordPage.json';
import noCustomersErrorPage from './noCustomersErrorPage.json';
import updateLicenseKeyPage from './updateLicenseKeyPage.json';
import verifyLicenseKeyPage from './verifyLicenseKeyPage.json';
import managementSystemConfigurationReceipts from './managementSystemConfigurationReceipts.json';
import containersForm from './containersForm.json';
import datasetsContainers from './datasetsContainers.json';
import datasetsTrailers from './datasetsTrailers.json';
import dateTableFilterElements from './dateTableFilterElements.json';
import accessControlArrivalNotification from './accessControlArrivalNotification.json';

const deDEtranslations = {
  kpoReceiverEditGeneratedConfirmationCardDialog,
  kpoReceiver,
  loginPage,
  kpoCarrier,
  kpoCarrierEditApprovedCardDialog,
  kpoCarrierEditReceiveConfirmationCardDialog,
  kpoCarrierViewCardDialog,
  kpoReceiverViewCardDialog,
  kpoSender,
  kpoSenderComponents,
  kpoUtils,
  kpoChangeStatusDialogs,
  bdoUtils,
  accessControl,
  accessControlEventLog,
  sharedComponents,
  billingData,
  snapshotDialog,
  axiosConfig,
  userProfile,
  app,
  appTopbar,
  confirmForgotPasswordPage,
  confirmSignUpPage,
  createAccountPage,
  createSuccessfulPage,
  errorPage,
  notFoundPage,
  signUpPage,
  contactComponent,
  versionComponent,
  datasetsAccessCodes,
  datasetsContractors,
  datasetsDrivers,
  datasetsProducts,
  datasetsTares,
  datasetsVehicles,
  managementApiKey,
  managementWebhooks,
  managementDictionaries,
  managementDynamicSettings,
  managementEdgeDevices,
  managementImportExport,
  managementIndicatorTypes,
  managementLocations,
  managementNodes,
  managementStations,
  managementRoles,
  managementUsers,
  managementSystemConfigurationBdo,
  managementSystemConfigurationConfirmations,
  managementSystemConfigurationEmails,
  managementSystemConfigurationGraphicElements,
  managementSystemConfigurationPrints,
  managementSystemConfigurationReports,
  managementSystemConfigurationWeighing,
  utils,
  kpokCarrier,
  kpokComponents,
  kpokReceiver,
  kpokSender,
  weighingComponents,
  weighingHandlingWeighings,
  weighingWeighingList,
  forgotPasswordPage,
  noCustomersErrorPage,
  updateLicenseKeyPage,
  verifyLicenseKeyPage,
  containersForm,
  datasetsContainers,
  managementSystemConfigurationReceipts,
  dateTableFilterElements,
  accessControlArrivalNotification,
  datasetsTrailers,
};

export default deDEtranslations;
