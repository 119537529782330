import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';

const ImportInformationDialog = ({ data, visible, closeDialog }) => {
  const { t } = useTranslation('sharedComponents');

  const handleClickOK = () => {
    closeDialog();
  };

  const dialogFooter = (
    <div className="grid justify-content-center">
      <Button label={t('okButton')} icon="pi pi-check" className="p-button-text" onClick={handleClickOK} />
    </div>
  );

  return (
    <Dialog
      visible={visible}
      header={t('importInformationHeader')}
      modal
      breakpoints={{ '1400px': '75vw', '896px': '90vw' }}
      className="p-fluid w-60vw"
      footer={dialogFooter}
      onHide={handleClickOK}
    >
      <div className="field col-12">
        <div>
          <h5>{t('totalCount', { totalCount: data?.totalCount })}</h5>
        </div>
        <div>
          <h5>{t('importedCount', { importedCount: data?.importedCount })}</h5>
        </div>
        <div>
          <h5>{t('errorsCount', { errorsCount: data?.errorsCount })}</h5>
        </div>
        {data?.errorsCount > 0 ? (
          <div>
            <br />
            <h6>{t('details')}</h6>
            <hr />
            <p className="ml-3 mr-3 word-break-all">
              {data?.errors.map((el, i) => (
                <span key={i}>
                  <b>{t('object')}</b>
                  <br />
                  <pre>{JSON.stringify(el.row, undefined, 1)}</pre>
                  <b>{t('message')}</b>
                  <br />
                  {el.error}
                  <br />
                  {i < data?.errors?.length - 1 ? <hr /> : ''}
                </span>
              ))}
            </p>
            <hr />
          </div>
        ) : (
          ''
        )}
      </div>
    </Dialog>
  );
};

export default ImportInformationDialog;
