import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import weighingsService from '../../../../../services/ScaleoApiServices/WeighingsService';
import weighingConfigurationService from '../../../../../services/ScaleoApiServices/WeighingConfigurationService';
import WeighingSummary from './components/WeighingSummary';
import { weighingActions } from '../../../../../store/weighing-slice';
import { printFileFromBuffer } from '../../../../../utils/getFile';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../../types/reducer-state';

const WeighingSummaryDialog = () => {
  const { t } = useTranslation('weighingHandlingWeighings');

  const dispatch = useDispatch();
  const [weighing, setWeighing] = useState<any>();
  const [configSummaryScreenAfterClosingWeighingVisible, setConfigSummaryScreenAfterClosingWeighingVisible] =
    useState(false);
  const [configSummaryScreenAfterMeasurementVisible, setConfigSummaryScreenAfterMeasurementVisible] = useState(false);
  const [weighingConfirmation, setWeighingConfirmation] = useState(false);
  const [confirmationComment, setConfirmationComment] = useState(false);
  const [requireConfirmationComment, setRequireConfirmationComment] = useState(false);
  const [confirmationRequiredCondition, setConfirmationRequiredCondition] = useState(null);
  const [autoPrintReceiptWeight, setAutoPrintReceiptWeight] = useState(false);
  const [comment, setComment] = useState<string | null>(null);
  const { weighingId, visible, lastShowedWeighingMeasurementId, isConfirmedClick, isPrinted } = useSelector(
    (state: ReducerState) => state.weighing.weighingSummaryDialogProps,
  );
  const finishedWeighingMeasurement = useSelector((state: ReducerState) => state.weighing.finishedWeighingMeasurement);

  const onSummaryClose = () => {
    dispatch(weighingActions.clearFinishedWeighingMeasurementProps());
    dispatch(
      weighingActions.setWeighingSummaryDialogProps({
        visible: false,
        weighingId: null,
        lastShowedWeighingMeasurementId:
          weighing?.measurements && weighing.measurements[weighing.measurements.length - 1].id,
        isConfirmedClick: null,
        isPrinted,
      }),
    );
    setWeighing(undefined);
  };

  const fetchWeighing = useCallback(async (id) => {
    const _weighing = await weighingsService.getWeighing(id);
    _weighing.data && setWeighing(_weighing.data);
  }, []);

  const fetchWeighingConfiguration = useCallback(async () => {
    weighingConfigurationService.getConfiguration().then((weighingConfiguration) => {
      setConfigSummaryScreenAfterClosingWeighingVisible(
        weighingConfiguration.data.summaryScreenAfterClosingWeighingVisible,
      );
      setConfigSummaryScreenAfterMeasurementVisible(weighingConfiguration.data.summaryScreenAfterMeasurementVisible);
      setAutoPrintReceiptWeight(weighingConfiguration.data.autoPrintReceiptWeight);
      setWeighingConfirmation(weighingConfiguration.data.weighingConfirmation);
      setConfirmationComment(weighingConfiguration.data.confirmationComment);
      setRequireConfirmationComment(weighingConfiguration.data.requireConfirmationComment);
      setConfirmationRequiredCondition(weighingConfiguration.data.confirmationRequiredCondition);
    });
  }, []);

  useEffect(() => {
    weighingId && fetchWeighing(weighingId);
    fetchWeighingConfiguration();
  }, [fetchWeighing, weighingId, fetchWeighingConfiguration]);

  useEffect(() => {
    if (configSummaryScreenAfterMeasurementVisible && finishedWeighingMeasurement?.weighingId && !weighingId) {
      lastShowedWeighingMeasurementId !== finishedWeighingMeasurement.sessionId &&
        dispatch(
          weighingActions.setWeighingSummaryDialogProps({
            visible: true,
            weighingId: finishedWeighingMeasurement.weighingId,
            isPrinted,
          }),
        );
    }
  }, [
    fetchWeighing,
    JSON.stringify(finishedWeighingMeasurement),
    fetchWeighingConfiguration,
    configSummaryScreenAfterMeasurementVisible,
    lastShowedWeighingMeasurementId,
  ]);

  const printWeighingReceipt = async () => {
    //temporary fix for Firefox
    if (navigator.userAgent.indexOf('Firefox') === -1) {
      const weighingReceipt = await weighingsService.getWeighingReceipt(weighingId!);
      printFileFromBuffer(weighingReceipt.data, 'application/pdf');
    }
    dispatch(weighingActions.setWeighingSummaryIsPrinted());
  };

  useEffect(() => {
    if (visible && !configSummaryScreenAfterClosingWeighingVisible && !configSummaryScreenAfterMeasurementVisible) {
      if (autoPrintReceiptWeight && weighingId && !isConfirmedClick && !isPrinted) {
        printWeighingReceipt();
      }
      if (
        !finishedWeighingMeasurement?.isCombined &&
        !configSummaryScreenAfterMeasurementVisible &&
        !isConfirmedClick
      ) {
        onSummaryClose();
        dispatch(weighingActions.displayWeighingSuccessToast());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, configSummaryScreenAfterMeasurementVisible, dispatch, weighingId, autoPrintReceiptWeight]);

  const confirmWeighing = useCallback(async () => {
    await weighingsService.confirmWeighing(weighing.id, comment);
    onSummaryClose();
    dispatch(weighingActions.reloadHandlingWeighingsData());
    dispatch(weighingActions.reloadWeighingsListData());
  }, [JSON.stringify(weighing), onSummaryClose]);

  const isEmptyRequiredComment = !comment && requireConfirmationComment;

  const isConfirmationAvailable = () => {
    if (confirmationRequiredCondition === 'AFTER_CLOSING_WEIGHING' || confirmationRequiredCondition === 'NEVER') {
      return finishedWeighingMeasurement?.weighingClosed || weighing?.closed;
    }
    return true;
  };

  const dialogFooter = (
    <>
      {weighingConfirmation && isConfirmationAvailable() && (
        <Button
          type="submit"
          label={
            isEmptyRequiredComment
              ? t('openWeighingsListSummaryCommentRequired')
              : t('openWeighingsListSummaryDialogConfirmButton')
          }
          onClick={() => {
            void confirmWeighing();
            setComment(null);
          }}
          disabled={isEmptyRequiredComment}
        />
      )}
      <Button
        type="reset"
        label={t('openWeighingsListSummaryDialogCloseButton')}
        icon="pi pi-times"
        text
        onClick={() => {
          onSummaryClose();
          setComment(null);
        }}
      />
    </>
  );

  const isCombinedViewEnable = () =>
    finishedWeighingMeasurement?.isCombined !== undefined
      ? finishedWeighingMeasurement?.automationMergeWeighingsSummary
      : configSummaryScreenAfterClosingWeighingVisible || configSummaryScreenAfterMeasurementVisible;

  return (
    <>
      {weighing && (
        <Dialog
          visible={(visible && isCombinedViewEnable()) || !!isConfirmedClick}
          breakpoints={{ '960px': '90vw', '640px': '100vw' }}
          header={t('openWeighingsListSummaryDialogTitle')}
          modal
          className="p-fluid w-80vw"
          footer={dialogFooter}
          onHide={onSummaryClose}
        >
          <WeighingSummary
            weighing={weighing}
            withRouterButtons={onSummaryClose}
            autoPrintReceiptWeight={autoPrintReceiptWeight}
            printWeighingReceipt={printWeighingReceipt}
            confirmationComment={confirmationComment}
            setComment={setComment}
            doNotPrint={
              isPrinted ||
              !autoPrintReceiptWeight ||
              isConfirmedClick ||
              (finishedWeighingMeasurement &&
                !finishedWeighingMeasurement?.isCombined &&
                !finishedWeighingMeasurement?.weighingClosed)
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default WeighingSummaryDialog;
