import { transformUrl } from '../components/Mqtt/offline/ScaleoOfflineWsUrlTransformer';
import { getAwsCurrentCredentials } from '../components/Mqtt/online/AwsCredentialsHandler';
import { addAwsSignature } from '../components/Mqtt/online/AwsSignatureWsUrlTransformer';
import {
  authenticateUser as authenticateUserWithAWSCognito,
  signOut as logoutUserWithAWSCognito,
} from './AwsCognitoAuthService';
import {
  authenticateUser as authenticateUserWithScaleoBackend,
  signOut as logoutUserWithScaleoBackend,
} from './ScaleoBackendAuthService';
import { configureApp } from './ScaleoOfflineAppConfigurer';
import { configureApp as configureAppWithAwsAMplify } from './ScaleoOnlineAppConfigurer';

export const scaleoModeContext = () => {
  return process.env.REACT_APP_SCALEO_MODE === 'ONLINE'
    ? {
        // SCALEO ONLINE
        appConfigurer: configureAppWithAwsAMplify,
        authenticateUserHandler: authenticateUserWithAWSCognito,
        logoutHandler: logoutUserWithAWSCognito,
        sessionCredentialsHandler: getAwsCurrentCredentials,
        wsUrlTransformer: addAwsSignature,
      }
    : {
        // SCALEO OFFLINE
        appConfigurer: configureApp,
        authenticateUserHandler: authenticateUserWithScaleoBackend,
        logoutHandler: logoutUserWithScaleoBackend,
        sessionCredentialsHandler: async () => undefined,
        wsUrlTransformer: transformUrl,
      };
};
