import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import contractorsService from '../../../services/ScaleoApiServices/ContractorsService';
import dictionariesService from '../../../services/ScaleoApiServices/DictionariesService';
import driversService from '../../../services/ScaleoApiServices/DriversService';
import vehiclesService from '../../../services/ScaleoApiServices/VehiclesService';
import usersService from '../../../services/ScaleoApiServices/UsersService';
import weighingConfigurationService from '../../../services/ScaleoApiServices/WeighingConfigurationService';
import { weighingActions } from '../../../store/weighing-slice';
import CloseWeighingDialog from './components/CloseWeighingDialog';
import NewWeighingForm from './components/NewWeighingForm/NewWeighingForm';
import DisabledWeighing from './components/DisabledWeighing';
import WeighingSidebar from './components/WeighingSidebar/WeighingSidebar';
import { ReducerState } from '../../../types/reducer-state';
import OpenAndNotConfirmedWeighingsListWrapper from './components/OpenWeighingsList/OpenAndNotConfirmedWeighingsListWrapper';
import WeighingDialog from '../WeighingsList/components/WeighingDialog/WeighingDialog';
import { Weighing } from '../../../types/weighing';
import BdoApiService from '../../../services/BdoApiService';
import useModulesConfiguration from '../../../utils/useModulesConfiguration';
import { userActions } from '../../../store/user-slice';
import containersService from '../../../services/ScaleoApiServices/ContainersService';
import trailersService from '../../../services/ScaleoApiServices/TrailersService';
import edgeDevicesService from '../../../services/ScaleoApiServices/EdgeDevicesService';
import useControls from '../../../utils/useControls';

const INIT_OPTIONS_DATA_STATE = {
  contractors: null,
  drivers: null,
  vehicles: null,
  dictionaries: null,
  scales: null,
  configuration: null,
};

interface HandlingWeighingsProps {
  publishWeighCommandHandler: (topic: string, data: any) => void;
  publishScaleCommandHandler: (topic: string, data: any) => void;
}

const HandlingWeighings = ({ publishWeighCommandHandler, publishScaleCommandHandler }: HandlingWeighingsProps) => {
  const [closeWeighingDialogProps, setCloseWeighingDialogProps] = useState<{
    visible: boolean;
    weighingId: string | null;
  }>({ visible: false, weighingId: null });
  const [closeWeighing, setCloseWeighing] = useState<Weighing | null>(null);
  const [weighingDialogVisible, setWeighingDialogVisible] = useState(false);
  const [optionsData, setOptionsData] = useState<any>(INIT_OPTIONS_DATA_STATE);
  const [isCorrectBdoModuleConfig, setIsCorrectBdoModuleConfig] = useState(false);
  const modulesConfiguration = useModulesConfiguration();

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const weighingConfiguration = useSelector((state: ReducerState) => state.weighing.weighingConfiguration);
  const dispatch = useDispatch();
  const controls = useControls();

  const [firstMobileLayoutBreakpoint, setFirstMobileLayoutBreakpoint] = useState(window.innerWidth < 1200);
  const [secondMobileLayoutBreakpoint, setSecondMobileLayoutBreakpoint] = useState(
    window.innerWidth >= 1200 && window.innerWidth < 1350,
  );
  const isMenuOpen = useSelector((state: ReducerState) => state.ui.isMenuOpen);

  const handleResize = () => {
    setFirstMobileLayoutBreakpoint(window.innerWidth < 1200);
    setSecondMobileLayoutBreakpoint(window.innerWidth >= 1200 && window.innerWidth < 1350);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const locationSelected = useCallback(() => {
    return loggedUserContext.locations && loggedUserContext.locations.length > 0;
  }, [loggedUserContext.locations]);

  const fetchComponentData = useCallback(async () => {
    await Promise.all([
      vehiclesService.getVehicles(),
      contractorsService.getContractors(),
      driversService.getDrivers(),
      dictionariesService.getDictionaries(),
      usersService.getCurrentUserScales(),
      weighingConfigurationService.getConfiguration(),
      BdoApiService.checkLocationEupConfig({ show_loader: false }),
      containersService.getContainers(),
      trailersService.getTrailers(),
      edgeDevicesService.searchEdgeDevices({
        filters: { name: '' },
        paginationParameters: { isAscending: true, orderColumn: '' },
      }),
    ]).then((values) => {
      setOptionsData({
        vehicles: values[0].data,
        contractors: values[1].data,
        drivers: values[2].data,
        dictionaries: values[3].data,
        scales: values[4],
        configuration: values[5].data,
        containers: values[7].data,
        trailers: values[8].data,
        edgeDevices: values[9],
      });
      setIsCorrectBdoModuleConfig(values[6].data?.correctConfig);
      dispatch(weighingActions.weighingConfigurationFetched(values[5].data));
    });
  }, []);

  const [isInactive, setIsInactive] = useState(false);
  const timeoutRef = useRef<any>(null);

  const resetTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsInactive(false);
    timeoutRef.current = setTimeout(() => {
      setIsInactive(true);
    }, 15000);
  };

  useEffect(() => {
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('touchstart', resetTimer);

    resetTimer();

    return () => {
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('touchstart', resetTimer);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    fetchComponentData();
  }, []);

  const onWeighingCloseRequested = (weighingId: string) => {
    setCloseWeighingDialogProps({ visible: true, weighingId });
  };

  const onWeighingClosed = () => {
    setCloseWeighingDialogProps({ visible: false, weighingId: null });
    dispatch(
      weighingActions.setWeighingSummaryDialogProps({ visible: true, weighingId: closeWeighingDialogProps.weighingId }),
    );
    dispatch(weighingActions.weighingClosed());
    dispatch(userActions.shouldRefreshLoggedUserContext(true));
  };

  const onWeighingCloseCancelled = () => {
    setCloseWeighingDialogProps({ visible: false, weighingId: null });
  };

  const MobileWeighingSidebar = () => {
    if (firstMobileLayoutBreakpoint || (isMenuOpen && secondMobileLayoutBreakpoint)) {
      return (
        <div
          className="absolute top-86px right-9px"
          style={{
            zIndex: '1000',
          }}
        >
          <WeighingSidebar
            edgeDevices={optionsData.edgeDevices}
            dictionaries={optionsData.dictionaries}
            controls={controls}
          />
        </div>
      );
    }

    return (
      <WeighingSidebar
        edgeDevices={optionsData.edgeDevices}
        dictionaries={optionsData.dictionaries}
        controls={controls}
      />
    );
  };

  const containerStyles = () => {
    if (!controls.length) return '';

    if (firstMobileLayoutBreakpoint) return '';

    if (secondMobileLayoutBreakpoint && isMenuOpen) return '';

    return 'xl:flex';
  };

  if (weighingConfiguration && locationSelected()) {
    return (
      <>
        <div className={containerStyles()}>
          <div className="grid nested-grid">
            <div className="col-12 p-fluid">
              <div className="card">
                <NewWeighingForm
                  publishWeighCommandHandler={publishWeighCommandHandler}
                  publishScaleCommandHandler={publishScaleCommandHandler}
                  onWeighingCloseRequested={onWeighingCloseRequested}
                  setCloseWeighing={setCloseWeighing}
                  optionsData={optionsData}
                  fetchComponentData={fetchComponentData}
                />
              </div>
            </div>

            <div className="col-12 p-fluid">
              <div className="card">
                <OpenAndNotConfirmedWeighingsListWrapper
                  onWeighingCloseRequested={onWeighingCloseRequested}
                  optionsData={optionsData}
                  weighingConfiguration={weighingConfiguration}
                  isInactive={isInactive}
                  setCloseWeighing={setCloseWeighing}
                  isCorrectBdoModuleConfig={isCorrectBdoModuleConfig}
                />
              </div>
            </div>

            <CloseWeighingDialog
              {...closeWeighingDialogProps}
              onCancelled={onWeighingCloseCancelled}
              onWeighingClosed={onWeighingClosed}
              setWeighingDialogVisible={setWeighingDialogVisible}
            />
            {weighingDialogVisible && (
              <WeighingDialog
                visible={weighingDialogVisible}
                onClose={() => setWeighingDialogVisible(false)}
                weighing={{ ...closeWeighing, closed: true, closedAt: new Date() }}
                setWeighing={() => undefined}
                inputVehicles={optionsData.vehicles}
                inputContractors={optionsData.contractors}
                inputDrivers={optionsData.drivers}
                inputScales={optionsData.scales}
                inputTrailers={optionsData.inputTrailers}
                afterSubmitAction={fetchComponentData}
                canEnableBdoPlugin={isCorrectBdoModuleConfig && modulesConfiguration.bdo === 'enabled'}
                isCombine={false}
                kpoWeighings={[]}
                weighingConfiguration={weighingConfiguration}
                containers={optionsData.containers}
              />
            )}
          </div>
          <MobileWeighingSidebar />
        </div>
      </>
    );
  }

  return <DisabledWeighing />;
};

export default HandlingWeighings;
