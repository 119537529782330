import { createSlice } from '@reduxjs/toolkit';
import { IndicationSlice } from './types/indication-slice';

const initialState: IndicationSlice = {
  currentIndication: null,
  scaleZeroingStatus: 'NOT_STARTED',
  zeroingScaleId: null,
  displaySound: false,
};

const indicationSlice = createSlice({
  name: 'indication',
  initialState: { ...initialState },
  reducers: {
    resetState(_state: IndicationSlice) {
      _state = { ...initialState };
    },
    scaleIndicationReceived(state: IndicationSlice, value) {
      const playSoundSpecifiedWeight = value.payload?.playSoundSpecifiedWeight;
      const currentIndication = value.payload?.message;
      if (
        playSoundSpecifiedWeight &&
        state.currentIndication &&
        Number(state.currentIndication) < Number(playSoundSpecifiedWeight) &&
        Number(playSoundSpecifiedWeight) <= Number(currentIndication)
      ) {
        state.displaySound = true;
      }
      state.currentIndication = currentIndication;
    },
    scaleZeoingRequested(state: IndicationSlice, value) {
      state.scaleZeroingStatus = 'REQUESTED';
      state.zeroingScaleId = value.payload.scaleId;
    },
    scaleZeroReceived(state: IndicationSlice, value) {
      if (value.payload.scaleId === state.zeroingScaleId) {
        state.scaleZeroingStatus = 'SUCCEEDED';
        state.zeroingScaleId = null;
      }
    },
    specifiedWeightSoundPlayed(state: IndicationSlice) {
      state.displaySound = false;
    },
  },
});

export const indicationActions = indicationSlice.actions;

export default indicationSlice;
