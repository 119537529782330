import { TooltipInfo } from '../../../_shared/TooltipInfo';

const bodyTemplates = {
  cardNumberBodyTemplate: (rowData, t) => {
    const cardNumber = rowData?.cardNumber?.substr(0, rowData.cardNumber.indexOf('/'));
    const revisionNumber = rowData?.revisionNumber ? ` ${t('adjustment')} ${rowData.revisionNumber}` : '';

    return (
      <>
        <span className="p-column-title">{t('cardNumber')}</span>
        {cardNumber}
        <br />
        {revisionNumber}
      </>
    );
  },
  cardStatusBodyTemplate: (rowData, t) => (
    <>
      <span className="p-column-title">{t('cardStatus')}</span>
      {rowData?.cardStatus}
    </>
  ),
  plannedTransportTimeBodyTemplate: (rowData, header) => (
    <>
      <span className="p-column-title">{header || 'Data i godzina rozpoczęcia transportu'}</span>
      {rowData?.realTransportTime
        ? new Date(rowData.realTransportTime).toLocaleString()
        : new Date(rowData.plannedTransportTime).toLocaleString()}
    </>
  ),
  wasteCodeBodyTemplate: (rowData, t) => (
    <>
      <span className="p-column-title">{t('wasteCode')}</span>
      {rowData?.wasteCode}
    </>
  ),
  wasteCodeExtendedBodyTemplate: (rowData, t) => (
    <>
      <span className="p-column-title">{t('wasteCodeExtended')}</span>
      {rowData?.wasteCodeExtended ? (
        <>
          Tak{' '}
          {rowData?.WasteCodeExtendedDescription ? (
            <TooltipInfo _key="wasteCodeExtended" text={rowData?.WasteCodeExtendedDescription} />
          ) : (
            ''
          )}
        </>
      ) : (
        'Nie'
      )}
    </>
  ),
  hazardousWasteReclassificationBodyTemplate: (rowData, t) => (
    <>
      <span className="p-column-title">{t('hazardousWasteReclassification')}</span>
      {rowData?.hazardousWasteReclassification ? t('yes') : t('no')}
    </>
  ),
  vehicleRegNumberBodyTemplate: (rowData, t) => (
    <>
      <span className="p-column-title">{t('vehicleRegistrationNumber')}</span>
      {rowData?.vehicleRegNumber}
    </>
  ),
  senderFirstNameAndLastNameBodyTemplate: (rowData, t) => (
    <>
      <span className="p-column-title">{t('fullSenderName')}</span>
      {rowData?.senderName}
    </>
  ),
  senderNameBodyTemplate: (rowData, t) => (
    <>
      <span className="p-column-title">{t('senderName')}</span>
      {rowData?.senderName}
    </>
  ),
  receiverNameBodyTemplate: (rowData, t) => (
    <>
      <span className="p-column-title">{t('receiverName')}</span>
      {rowData?.receiverName}
    </>
  ),
};

const translateFieldNameToColumnName = (fieldName) => {
  switch (fieldName) {
    case 'cardNumber':
      return 'Kpok_CardNumber';
    case 'plannedTransportTime':
      return 'Kpok_RealTransportTime';
    case 'wasteCode':
      return 'WasteCode_Code';
    case 'senderFirstNameAndLastName':
      return 'Sender_FirstName_And_LastName';
    case 'senderName':
      return 'SenderCompany_Name';
    case 'receiverName':
      return 'ReceiverCompany_Name';
    case 'vehicleRegNumber':
      return 'Kpok_VehicleRegNumber';
    default:
      return 'Kpok_LastModifiedAt';
  }
};

const translateColumnNameToFieldName = (fieldName) => {
  switch (fieldName) {
    case 'Kpok_CardNumber':
      return 'cardNumber';
    case 'Kpok_RealTransportTime':
      return 'plannedTransportTime';
    case 'WasteCode_Code':
      return 'wasteCode';
    case 'Sender_FirstName_And_LastName':
      return 'senderFirstNameAndLastName';
    case 'SenderCompany_Name':
      return 'senderName';
    case 'ReceiverCompany_Name':
      return 'receiverName';
    case 'Kpok_VehicleRegNumber':
      return 'vehicleRegNumber';
    default:
      return '';
  }
};

const tableUtils = {
  bodyTemplates,
  translateFieldNameToColumnName,
  translateColumnNameToFieldName,
};

export default tableUtils;
