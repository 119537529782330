import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import FormErrorMessage from '../../../_shared/FormErrorMessage';
import { BdoApiType } from '../../../../enums/bdo-api-type.enum';
import { TFunction } from 'i18next';
import BdoConfigurationAuthorizationSection from './BdoConfigurationAuthorizationSection';

interface BdoConfigurationGeneralSectionProps {
  formik: any;
  handleChangeField: (e: any, type: string) => void;
  isCorrectConfig: boolean | null;
  t: TFunction;
}

const BdoConfigurationGeneralSection = ({
  formik,
  handleChangeField,
  isCorrectConfig,
  t,
}: BdoConfigurationGeneralSectionProps) => {
  const apiTypeOptions = [
    {
      label: t('production'),
      value: BdoApiType.PRODUCTION,
    },
    { label: t('test'), value: BdoApiType.TEST },
  ];

  return (
    <>
      <Fieldset legend={t('generalSettingsSectionHeader')}>
        <div className="grid pt-1vh">
          <span className="field-checkbox md:col-12">
            <Checkbox
              id="moduleEnabled"
              checked={formik.values.moduleEnabled}
              onChange={(e) => handleChangeField(e, 'checkbox')}
            ></Checkbox>
            <label htmlFor="moduleEnabled" className="checkbox-label">
              {t('isEnabled')}
            </label>
            <FormErrorMessage fieldName="moduleEnabled" formikInstance={formik} />
          </span>

          {formik.values.moduleEnabled ? (
            <>
              <span className="field-checkbox md:col-12">
                <label htmlFor="apiType" className="mr-6 mt-2">
                  {t('bdoApiType')}
                </label>
                <Dropdown
                  id="apiType"
                  options={apiTypeOptions}
                  value={formik.values.apiType}
                  onChange={(e) => handleChangeField(e, 'dropdown')}
                ></Dropdown>

                <FormErrorMessage fieldName="apiType" formikInstance={formik} />
              </span>

              <span className="field-checkbox md:col-12">
                <Checkbox
                  id="isChanged"
                  checked={formik.values.isChanged}
                  onChange={(e) => handleChangeField(e, 'checkbox')}
                ></Checkbox>
                <label htmlFor="isChanged" className="checkbox-label">
                  {t('isChanged')}
                </label>
                <FormErrorMessage fieldName="isChanged" formikInstance={formik} />
              </span>

              {formik.values.isChanged && (
                <BdoConfigurationAuthorizationSection
                  formik={formik}
                  handleChangeField={handleChangeField}
                  isCorrectConfig={isCorrectConfig}
                  t={t}
                />
              )}
            </>
          ) : (
            ''
          )}
        </div>
      </Fieldset>
    </>
  );
};

export default BdoConfigurationGeneralSection;
