import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import trailersService from '../../services/ScaleoApiServices/TrailersService';
import { Trailer } from '../../types/trailer';
import { formatStringToDate } from '../../utils/formatUtils';
import { ReducerState } from '../../types/reducer-state';
import { userActions } from '../../store/user-slice';

const formatSearchFilters = (internalFilters: any) => {
  return {
    ...internalFilters,
    tareTimestamp: internalFilters.tareTimestamp?.map((stringDate: string) => formatStringToDate(stringDate)),
  };
};

export const useTrailersData = () => {
  const dispatch = useDispatch();

  const [trailers, setTrailers] = useState<Trailer[] | null>(null);

  const filters = useSelector((state: ReducerState) => state.user.filtersState.trailers);
  const [internalFilters, setInternalFilters] = useState(filters);
  const [paginationParameters, setPaginationParameters] = useState(emptyPaginationParameters);
  const [paginatorState, setPaginatorState] = useState(emptyPaginatorState);

  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);

  const fetchTrailers = useCallback(
    async (_filters, _paginationParameters) => {
      void Promise.all([trailersService.searchTrailers(formatSearchFilters(_filters), _paginationParameters)]).then(
        (response) => {
          setTrailers(response[0].data);

          response[0].totalPages
            ? setPaginatorState({
                ...paginatorState,
                totalPages: response[0].totalPages,
              })
            : setPaginatorState({
                ...paginatorState,
                totalPages: 1,
                currentPage: 1,
              });
        },
      );
    },
    [trailersService.searchTrailers, formatSearchFilters, setTrailers, setPaginatorState],
  );

  useEffect(() => {
    void fetchTrailers(internalFilters, paginationParameters);
  }, [internalFilters, paginationParameters, loggedUserContext.currentCustomer?.id]);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  useEffect(() => {
    setPaginationParameters((prevPaginationParameters) => ({
      ...prevPaginationParameters,
      page: {
        ...prevPaginationParameters.page,
        index: 1,
      },
    }));
  }, [internalFilters]);

  useEffect(() => {
    if (paginatorState.currentPage > paginatorState.totalPages)
      setPaginatorState((prevPaginatorState) => ({
        ...prevPaginatorState,
        currentPage: 1,
      }));
  }, [paginatorState]);

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const changeFilter = (value: any, name: string) => {
    const newFilters = { ...internalFilters };
    newFilters[name] = value;

    dispatch(userActions.setTrailersFilters(newFilters));
    setInternalFilters(newFilters);
  };

  const clearFilters = () => {
    dispatch(userActions.setTrailersFilters({}));
    setInternalFilters({});
  };

  const fetchTrailersData = useCallback(
    async () => fetchTrailers(filters, paginationParameters),
    [filters, paginationParameters],
  );

  return {
    fetchTrailers: fetchTrailersData,
    trailers,
    handleSort,
    paginationParameters,
    paginatorState,
    setPaginatorState,
    internalFilters,
    changeFilter,
    clearFilters,
  };
};

const emptyPaginationParameters = {
  order: {
    isAscending: true,
    orderColumn: 'id',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const emptyPaginatorState = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};
