import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import productsService from '../../../../services/ScaleoApiServices/ProductsService';
import weighingConfigurationService from '../../../../services/ScaleoApiServices/WeighingConfigurationService';
import { appConfigActions } from '../../../../store/appConfig-slice';
import FormErrorMessageScroller from '../../../_shared/FormErrorMessageScroller';
import AdditionalFieldsSection from './components/AdditionalFieldsSection/AdditionalFieldsSection';
import BdoCardsSection from './components/BdoCardsSection';
import NewVehicleSavingSection from './components/NewVehicleSavingSection';
import UpdateVehicleDefaultDataSection from './components/UpdateVehicleDataSection';
import UpdateVehicleTareSection from './components/UpdateVehicleTareSection';
import WeighingModeSection from './components/WeighingModeSection';
import WeighingNumerationSection from './components/WeighingNumerationSection';
import FieldsOrderSection from './components/FieldsOrderSection/FieldsOrderSection';
import TransactionTypeSection from './components/TransactionTypeSection/TransactionTypeSection';
import formChangeFieldMethod from '../../../../utils/formChangeFieldMethod';
import GeneralSection from './components/GeneralSection';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../types/reducer-state';
import { weighingActions } from '../../../../store/weighing-slice';
import { userActions } from '../../../../store/user-slice';
import { WeighingNumerationSeparatorOption } from '../../../../enums/weighing-numeration.enum';

const INIT_FORM_STATE = {
  customTransactionTypes: [],
  transactionTypeDefault: null,
  transactionTypeRequired: false,
  transactionTypeAutomatic: false,
  multipleOpenWeighingsForTheSameVehicle: false,
  weighingMode: [],
  weighingData: [],
  additionalFields: [],
  isTestWeighing: false,
  unit: null,
  numberLocation: null,
  numberPeriod: null,
  summaryScreenAfterMeasurementVisible: false,
  summaryScreenAfterClosingWeighingVisible: false,
  saveVehicleShowCheckbox: false,
  saveVehicleDefaultValue: false,
  weighingCarrierAutocomplete: false,
  weighingReceiverAutocomplete: false,
  updateVehicleDataShowCheckbox: false,
  updateVehicleDataDefaultValue: false,
  updateVehicleTareShowCheckbox: false,
  updateVehicleTareDefaultValue: false,
  autoPrintReceiptWeight: false,
  printPosReceiptDefaultValue: false,
  autoEmailReceiptWeight: false,
  fieldsOrder: [],
  numberSeparator: WeighingNumerationSeparatorOption.SLASH,
  numberAdditionalField: null,
  numberGeneratedByFields: [],
};

const WeighingConfiguration = () => {
  const { t } = useTranslation('managementSystemConfigurationWeighing');

  const dispatch = useDispatch();
  const [stringifiedInitFormValues, setStringifiedInitFormValues] = useState('[]');
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const confirmationOfWindowChange = useSelector((state: ReducerState) => state.appConfig.confirmationOfWindowChange);

  const fetchAPI = useCallback(async () => {
    const response = await weighingConfigurationService.getConfiguration();
    let additionalFieldsProductIds = [] as any[];
    response.data?.additionalFields.forEach(
      (field: any) => (additionalFieldsProductIds = additionalFieldsProductIds.concat(field.productIds)),
    );
    additionalFieldsProductIds = Array.from(new Set(additionalFieldsProductIds));

    const responseProducts = await productsService.searchProductByIds(additionalFieldsProductIds);
    const additionalFields = response.data?.additionalFields.map((field: any) => {
      return { ...field, productIds: responseProducts.data.filter((el: any) => field.productIds.includes(el.id)) };
    });

    if (response.data) {
      const responseDataValues = {
        customTransactionTypes: response.data?.customTransactionTypes || [],
        transactionTypeDefault: response.data?.transactionTypeDefaultId,
        transactionTypeRequired: response.data?.transactionTypeRequired,
        transactionTypeAutomatic: response.data?.transactionTypeAutomatic,
        multipleOpenWeighingsForTheSameVehicle: response.data?.multipleOpenWeighingsForTheSameVehicle,
        weighingMode: response.data?.weighingMode.sort() || [],
        weighingData: response.data?.weighingData.sort() || [],
        additionalFields: additionalFields.sort(),
        isTestWeighing: response.data?.isTestWeighing,
        unit: response.data?.unit,
        numberLocation: response.data?.numberLocation,
        numberPeriod: response.data?.numberPeriod,
        summaryScreenAfterMeasurementVisible: response.data?.summaryScreenAfterMeasurementVisible,
        summaryScreenAfterClosingWeighingVisible: response.data?.summaryScreenAfterClosingWeighingVisible,
        saveVehicleShowCheckbox: response.data?.saveVehicleShowCheckbox,
        saveVehicleDefaultValue: response.data?.saveVehicleDefaultValue,
        weighingCarrierAutocomplete: response.data?.weighingCarrierAutocomplete,
        weighingReceiverAutocomplete: response.data?.weighingReceiverAutocomplete,
        updateVehicleDataShowCheckbox: response.data?.updateVehicleDataShowCheckbox,
        updateVehicleDataDefaultValue: response.data?.updateVehicleDataDefaultValue,
        updateVehicleTareShowCheckbox: response.data?.updateVehicleTareShowCheckbox,
        updateVehicleTareDefaultValue: response.data?.updateVehicleTareDefaultValue,
        autoPrintReceiptWeight: response.data?.autoPrintReceiptWeight,
        printPosReceiptDefaultValue: response.data?.printPosReceiptDefaultValue,
        autoEmailReceiptWeight: response.data?.autoEmailReceiptWeight,
        mergeWeighingsSummary: response.data?.mergeWeighingsSummary,
        playSoundWhileAutomaticSummary: response.data?.playSoundWhileAutomaticSummary,
        addTrailerRegistrationNumbersSupport: response.data?.addTrailerRegistrationNumbersSupport,
        fieldsOrder: response.data?.fieldsOrder || [],
        numberSeparator: response.data?.numberSeparator || WeighingNumerationSeparatorOption.SLASH,
        numberAdditionalField: response.data?.numberAdditionalField,
        numberGeneratedByFields: response.data?.numberGeneratedByFields || [],
      };
      setInitFormValues({ ...initFormValues, ...responseDataValues });
      setStringifiedInitFormValues(JSON.stringify({ ...initFormValues, ...responseDataValues }));
      dispatch(weighingActions.weighingConfigurationFetched(response.data));
      dispatch(userActions.shouldRefreshSystemConfiguration(true));
      dispatch(userActions.shouldRefreshLoggedUserContext(true));
    }
  }, [initFormValues]);

  useEffect(() => {
    fetchAPI();
  }, [loggedUserContext.currentCustomer?.id]);

  const validateMethod = () => {
    const errors = {};

    return errors;
  };

  const saveChanges = async (data: any) => {
    const additionalFields = data.additionalFields.map((field: any) => {
      return { ...field, productIds: field.productIds.map((product: any) => product.id) };
    });
    const resultBody = {
      customTransactionTypes: [
        ...data.customTransactionTypes.map((customTransactionType: any) => ({
          id: customTransactionType.id,
          isUsed: customTransactionType.isUsed,
          name: customTransactionType.name,
        })),
      ],
      transactionTypeDefaultId: data.transactionTypeDefault,
      transactionTypeRequired: data.transactionTypeRequired,
      transactionTypeAutomatic: data.transactionTypeAutomatic,
      multipleOpenWeighingsForTheSameVehicle: data.multipleOpenWeighingsForTheSameVehicle,
      weighingMode: data.weighingMode,
      weighingData: data.weighingData,
      additionalFields,
      isTestWeighing: data.isTestWeighing,
      unit: data.unit,
      numberLocation: data.numberLocation,
      numberPeriod: data.numberPeriod,
      summaryScreenAfterMeasurementVisible: data.summaryScreenAfterMeasurementVisible,
      summaryScreenAfterClosingWeighingVisible: data.summaryScreenAfterClosingWeighingVisible,
      saveVehicleShowCheckbox: data.saveVehicleShowCheckbox,
      saveVehicleDefaultValue: data.saveVehicleDefaultValue,
      weighingCarrierAutocomplete: data.weighingCarrierAutocomplete,
      weighingReceiverAutocomplete: data.weighingReceiverAutocomplete,
      updateVehicleDataShowCheckbox: data.updateVehicleDataShowCheckbox,
      updateVehicleDataDefaultValue: data.updateVehicleDataDefaultValue,
      updateVehicleTareShowCheckbox: data.updateVehicleTareShowCheckbox,
      updateVehicleTareDefaultValue: data.updateVehicleTareDefaultValue,
      autoPrintReceiptWeight: data.autoPrintReceiptWeight,
      autoEmailReceiptWeight: data.autoEmailReceiptWeight,
      printPosReceiptDefaultValue: data.printPosReceiptDefaultValue,
      mergeWeighingsSummary: data.mergeWeighingsSummary,
      playSoundWhileAutomaticSummary: data.playSoundWhileAutomaticSummary,
      addTrailerRegistrationNumbersSupport: data.addTrailerRegistrationNumbersSupport,
      fieldsOrder: data.fieldsOrder,
      numberSeparator: data.numberSeparator,
      numberAdditionalField: data.numberAdditionalField,
      numberGeneratedByFields: data.numberGeneratedByFields,
    };
    await weighingConfigurationService.updateConfiguration(resultBody);
    dispatch(appConfigActions.setUnit(data.unit));
    void fetchAPI();
  };

  const handleSubmit = async (data: any) => {
    await saveChanges(data);
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const changes = JSON.stringify(formik.values) !== stringifiedInitFormValues;
    if (confirmationOfWindowChange.required !== changes) {
      dispatch(
        appConfigActions.setConfirmationOfWindowChange({
          ...confirmationOfWindowChange,
          required: changes,
          saveMethod: formik.submitForm,
        }),
      );
    }
  }, [JSON.stringify(formik.values)]);

  const handleChangeField = (e: any, type: string) => {
    formChangeFieldMethod(e, type, formik);
  };

  const handleClickCancel = () => {
    formik.resetForm();
  };

  const handleClickSubmit = () => {
    formik.submitForm().then(() => {
      dispatch(appConfigActions.setConfirmationOfWindowChange({ ...confirmationOfWindowChange, required: false }));
    });
  };

  return (
    <div className="card pb-70px">
      <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
        <form onSubmit={formik.handleSubmit}>
          <GeneralSection formikInstance={formik} handleChangeField={handleChangeField} />
          <FieldsOrderSection formikInstance={formik} />
          <WeighingModeSection formikInstance={formik} />
          <TransactionTypeSection formikInstance={formik} handleChangeField={handleChangeField} />
          <BdoCardsSection formikInstance={formik} handleChangeField={handleChangeField} />
          <AdditionalFieldsSection formikInstance={formik} />
          <WeighingNumerationSection formikInstance={formik} handleChangeField={handleChangeField} />
          <NewVehicleSavingSection formikInstance={formik} handleChangeField={handleChangeField} />
          <UpdateVehicleDefaultDataSection formikInstance={formik} handleChangeField={handleChangeField} />
          <UpdateVehicleTareSection formikInstance={formik} handleChangeField={handleChangeField} />
          <div>
            <div
              className="flex justify-content-end w-full p-ai-end bg-white fixed bottom-0px right-0px pt-10px pr-40px pb-10px pl-0px"
              style={{
                boxShadow: '0px 10px 15px black',
              }}
            >
              <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={handleClickCancel} />
              <Button type="button" label={t('saveButton')} icon="pi pi-check" text onClick={handleClickSubmit} />
            </div>
          </div>
        </form>
      </FormErrorMessageScroller>
    </div>
  );
};

export default WeighingConfiguration;
