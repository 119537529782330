import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect } from 'react';
import validator from 'validator';
import nodesService from '../../../services/ScaleoApiServices/NodesService';
import usersService from '../../../services/ScaleoApiServices/UsersService';
import { CustomAutoComplete } from '../../_shared/CustomComponents';
import FormErrorMessage from '../../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../../_shared/FormErrorMessageScroller';
import multiSelectPanelHeader from '../../_shared/multiSelectPanelHeader';
import { useTranslation } from 'react-i18next';
import { Node } from '../../../types/node';
import { userActions } from '../../../store/user-slice';
import { useDispatch } from 'react-redux';

const INIT_FORM_STATE = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  newPassword: false,
  role: '',
  nodeIds: [],
  locationIds: [],
  scaleIds: null,
};

interface InviteNewUserDialogProps {
  closeDialogHandler: () => void;
  smtpConfig: any;
  nodes: Node[] | null;
  userRoles: any[] | null;
  locations: any[] | null;
  setNodes: React.Dispatch<React.SetStateAction<Node[] | null>>;
}

function InviteNewUserDialog({
  closeDialogHandler,
  smtpConfig,
  nodes,
  setNodes,
  userRoles,
  locations,
}: InviteNewUserDialogProps) {
  const { t } = useTranslation('managementUsers');
  const dispatch = useDispatch();

  const hideDialog = () => {
    formik.resetForm(INIT_FORM_STATE as any);
    closeDialogHandler();
  };

  const formik = useFormik({
    initialValues: INIT_FORM_STATE,
    validate: (data) => {
      const errors = {} as any;

      const nameRegex = /^[A-Za-z\u00C0-\u024F\s]+$/;

      if (!data.firstName) {
        errors.firstName = t('noFirstNameError');
      }
      if (!data.lastName) {
        errors.lastName = t('noLastNameError');
      }

      if (data.firstName && !nameRegex.test(data.firstName)) {
        errors.firstName = t('incorrectFirstNameError');
      }

      if (data.lastName && !nameRegex.test(data.lastName)) {
        errors.lastName = t('incorrectLastNameError');
      }

      if (!data.role) {
        errors.role = t('noRoleError');
      }
      if (!data.email) {
        errors.email = t('noEmailError');
      } else if (!validator.isEmail(data.email)) {
        errors.email = t('incorrectEmailError');
      }
      if (!!data.scaleIds && (data.locationIds === null || data.locationIds.length === 0)) {
        errors.locationIds = t('noLocationError');
      }

      return errors;
    },
    onSubmit: async (formData, helpers) => {
      usersService
        .inviteUser(formData)
        .then(() => {
          dispatch(userActions.shouldRefreshLoggedUserContext(true));
          hideDialog();
        })
        .catch((error) => {
          if (error.response.status === 409) {
            helpers.setFieldError('email', t('mngmt-users-invite_dialog-user_exist'));
          }
        })
        .finally(() => helpers.setSubmitting(false));
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    nodesService.searchNodes({ filters: { locationIds: formik.values.locationIds } }).then((_nodes) => {
      setNodes(_nodes);
      !formik.values.id &&
        formik.setFieldValue(
          'nodeIds',
          _nodes.map((node: Node) => node.id),
        );
      formik.values.id &&
        formik.setFieldValue(
          'nodeIds',
          formik.values.nodeIds.filter((nodeId) => _nodes.map((node: Node) => node.id).includes(nodeId)),
        );
    });
  }, [formik.values.locationIds]);

  const onLocationChanged = (event: any) => {
    const newLocationIds = event.value;
    formik.setFieldValue('locationIds', newLocationIds);
  };

  const onNodeChanged = (event: any) => {
    const newNodeIds = event.value;
    formik.setFieldValue('nodeIds', newNodeIds);
  };

  const userDialogFooter = (
    <>
      <Button type="reset" label={t('cancelButton')} icon="pi pi-times" text onClick={hideDialog} />
      <Button type="submit" label={t('saveButton')} icon="pi pi-check" text onClick={formik.submitForm} />
    </>
  );

  const showInviteMessage = () => (process.env.REACT_APP_SCALEO_MODE === 'ONLINE' ? true : smtpConfig?.isClientSMTP);

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={true}
        header={t('userDetails')}
        modal
        className="p-fluid w-40vw"
        footer={userDialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      >
        <form>
          {showInviteMessage() && (
            <div className="col-12">
              <Message
                className="mt-4"
                severity="info"
                text="Na adres e-mail użytkownika zostanie wysłany jednorazowy link do aktywacji konta."
              />
            </div>
          )}
          <div className="col-12">
            <div className="field">
              <label htmlFor="email">{t('email')}</label>
              <InputText id="email" value={formik.values.email} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="email" formikInstance={formik} />
            </div>

            <div className="field">
              <label htmlFor="firstName">{t('firstName')}</label>
              <InputText id="firstName" value={formik.values.firstName} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="firstName" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="lastName">{t('lastName')}</label>
              <InputText id="lastName" value={formik.values.lastName} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="lastName" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="role">{t('role')}</label>
              <CustomAutoComplete
                id="role"
                value={formik.values.role}
                handler={formik.handleChange}
                source={userRoles!}
              />
              <FormErrorMessage fieldName="role" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="locationIds">{t('locationIds')}</label>
              <MultiSelect
                value={formik.values.locationIds}
                onChange={onLocationChanged}
                options={locations!}
                optionLabel="name"
                optionValue="id"
                placeholder={t('locationIdsPlaceholder')}
                className="multiselect-custom"
                maxSelectedLabels={0}
                selectedItemsLabel={t('locationIdsSelectedLabel')}
                showClear={true}
                panelHeaderTemplate={multiSelectPanelHeader}
              />
              <FormErrorMessage fieldName="locationIds" formikInstance={formik} />
            </div>
            <div className="field">
              <label htmlFor="nodeIds">{t('nodeIds')}</label>
              <MultiSelect
                id="nodeIds"
                value={formik.values.nodeIds}
                onChange={onNodeChanged}
                options={nodes!}
                optionLabel="name"
                optionValue="id"
                placeholder={t('nodeIdsPlaceholder')}
                className="multiselect-custom"
                maxSelectedLabels={0}
                selectedItemsLabel={t('nodeIdsSelectedLabel')}
                showClear={true}
                panelHeaderTemplate={multiSelectPanelHeader}
              />
              <FormErrorMessage fieldName="nodeIds" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
}

export default InviteNewUserDialog;
