import React, { useState } from 'react';
import ImportInformationDialog from '../../_shared/ImportInformationDialog';
// import SectionConfiguration from './components/SectionConfiguration';
import SectionDatabase from './components/SectionDatabase';

const ImportExport = () => {
  const [isOpenImportDialog, setIsOpenImportDialog] = useState(false);
  const [importInformationDialogData, setImportInformationDialogData] = useState(null);

  return (
    <div className="card">
      <ImportInformationDialog
        data={importInformationDialogData}
        visible={isOpenImportDialog}
        closeDialog={() => setIsOpenImportDialog(false)}
      />
      <SectionDatabase
        onImport={(informationData: any) => {
          setImportInformationDialogData(informationData);
          setIsOpenImportDialog(true);
        }}
      />
      {/* <SectionConfiguration
        style={{ marginTop: '1rem' }}
        onImport={(informationData) => {
          setImportInformationDialogData(informationData);
          setIsOpenImportDialog(true);
        }}
      /> */}
    </div>
  );
};

export default ImportExport;
