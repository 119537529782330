import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Checkbox } from 'primereact/checkbox';
import { useTranslation } from 'react-i18next';

interface UpdateVehicleDefaultDataSectionProps {
  formikInstance: any;
  handleChangeField: any;
}

const UpdateVehicleDefaultDataSection = ({
  formikInstance,
  handleChangeField,
}: UpdateVehicleDefaultDataSectionProps) => {
  const { t } = useTranslation('managementSystemConfigurationWeighing');

  return (
    <Fieldset legend={t('updateVehicleDataSectionHeader')} className="mt-1vh">
      <div className="p-fluid formgrid grid">
        <div className="field-checkbox col-12">
          <Checkbox
            id="updateVehicleDataShowCheckbox"
            checked={formikInstance.values.updateVehicleDataShowCheckbox}
            onChange={(e) => handleChangeField(e, 'checkbox')}
          ></Checkbox>
          <label htmlFor="updateVehicleDataShowCheckbox" className="checkbox-label">
            {t('updateVehicleDataShowCheckbox')}
          </label>
        </div>
        <div className="field-checkbox col-12">
          <Checkbox
            id="updateVehicleDataDefaultValue"
            checked={formikInstance.values.updateVehicleDataDefaultValue}
            onChange={(e) => handleChangeField(e, 'checkbox')}
          ></Checkbox>
          <label htmlFor="updateVehicleDataDefaultValue" className="checkbox-label">
            {t('updateVehicleDataDefaultValue')}
          </label>
        </div>
      </div>
    </Fieldset>
  );
};

export default UpdateVehicleDefaultDataSection;
