import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useLocation, withRouter } from 'react-router-dom';
import App from './App';
import { ConfirmForgotPassword } from './pages/ConfirmForgotPassword';
import { ConfirmSignUp } from './pages/ConfirmSignUp';
import { CreateAccount } from './pages/CreateAccount';
import { Error } from './pages/Error';
import { ForgotPassword } from './pages/ForgotPassword';
import { Login } from './pages/Login';
import { NotFound } from './pages/NotFound';
import { SignUp } from './pages/SignUp';
import { scaleoModeContext } from './services/ScaleoModeContext';
import { CreateSuccessful } from './pages/CreateSuccessful';
import { ReducerState } from './types/reducer-state';
import { NoCustomersError } from './pages/NoCustomersError';
import usersService from './services/ScaleoApiServices/UsersService';
import { userActions } from './store/user-slice';
import installationStatusService from './services/ScaleoApiServices/InstallationService';
import { VerifyLicenseKey } from './pages/VerifyLicenseKey';
import installationService from './services/ScaleoApiServices/InstallationService';

interface AppWrapperProps {
  publishWeighCommandHandler: (topic: string, data: any) => void;
  publishScaleCommandHandler: (topic: string, data: any) => void;
  location: {
    pathname: string;
  };
}

const AppWrapper = ({
  publishScaleCommandHandler,
  publishWeighCommandHandler,
  location: { pathname },
}: AppWrapperProps) => {
  const location = useLocation();
  const loggedUser = useSelector((state: ReducerState) => state.user.loggedUser);
  const loggedUserCustomers = useSelector((state: ReducerState) => state.user.loggedUserCustomers);

  const dispatch = useDispatch();
  const [isAppVerified, setIsAppVerified] = useState(true);

  const fetchOfflineInstallationStatus = useCallback(() => {
    process.env.REACT_APP_SCALEO_MODE === 'OFFLINE' &&
      installationStatusService.isAppVerified().then((response) => {
        setIsAppVerified(response.data);
      });
  }, [location]);

  useEffect(() => {
    fetchOfflineInstallationStatus();
  }, [fetchOfflineInstallationStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      const x = e.clientX;
      const y = e.clientY;

      document.documentElement.style.setProperty('--mouse-x', `${x}px`);
      document.documentElement.style.setProperty('--mouse-y', `${y}px`);
    };
    window.addEventListener('mousemove', onMouseMove);

    return () => window.removeEventListener('mousemove', onMouseMove);
  }, []);

  useEffect(() => {
    if (loggedUser && !loggedUserCustomers) {
      usersService.getLoggedUserCustomers().then((response) => {
        const hasAnyCustomers = response.data.length > 0;

        dispatch(userActions.setLoggedUserCustomers(hasAnyCustomers));

        localStorage.setItem('hasAnyCustomers', hasAnyCustomers.toString());
      });
    }
  }, [loggedUser, loggedUserCustomers]);

  if (!isAppVerified) {
    return (
      <Route
        path={pathname}
        render={() => <VerifyLicenseKey verifyOrUpdateKey={installationService.verifyLicenseKey} setIsSuccess={null} />}
      />
    );
  }

  switch (pathname) {
    case '/':
      return (
        <Route path="/">
          {loggedUser && !loggedUserCustomers && <NoCustomersError />}
          {loggedUser && loggedUserCustomers === true && (
            <App
              publishWeighCommandHandler={publishWeighCommandHandler}
              publishScaleCommandHandler={publishScaleCommandHandler}
            />
          )}
          {!loggedUser && <Redirect to="/login" />}
        </Route>
      );
    case '/login':
      return (
        <Route
          path="/login"
          render={(props) => <Login {...props} onLoginAttempt={scaleoModeContext().authenticateUserHandler} />}
        />
      );
    case '/sign-up':
      return <Route path="/sign-up" render={() => <SignUp />} />;
    case '/confirm-sign-up':
      return <Route path="/confirm-sign-up" render={() => <ConfirmSignUp />} />;
    case '/create-account':
      return <Route path="/create-account" render={() => <CreateAccount />} />;
    case '/forgot-password':
      return <Route path="/forgot-password" render={() => <ForgotPassword />} />;
    case '/confirm-forgot-password':
      return <Route path="/confirm-forgot-password" render={() => <ConfirmForgotPassword />} />;
    case '/error':
      return <Route path="/error">{loggedUser && !loggedUserCustomers ? <NoCustomersError /> : <Error />}</Route>;
    case '/create-successfull':
      return <Route path="/create-successfull" component={CreateSuccessful} />;
    case '/notfound':
      return <Route path="/notfound" component={NotFound} />;
    default:
      return (
        <App
          publishWeighCommandHandler={publishWeighCommandHandler}
          publishScaleCommandHandler={publishScaleCommandHandler}
        />
      );
  }
};

export default withRouter(AppWrapper as any);
