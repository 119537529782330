import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appConfigActions } from '../../../../store/appConfig-slice';
import { userActions } from '../../../../store/user-slice';
import weighingConfigurationService from '../../../../services/ScaleoApiServices/WeighingConfigurationService';
import formChangeFieldMethod from '../../../../utils/formChangeFieldMethod';
import { useTranslation } from 'react-i18next';
import { ReducerState } from '../../../../types/reducer-state';
import ReportHideSection from './components/ReportHideSection';
import CyclicMailReportSection from './components/CyclicMailReportSection';

const INIT_FORM_STATE = {
  hideCreationDate: false,
  enableCyclicMailReport: false,
  cyclicMailSendingReportMinutesAfterMidnight: null,
  cyclicMailSendingReportFormat: null,
  cyclicMailSendingReportMails: [],
};

const ReportsConfiguration = () => {
  const { t } = useTranslation('managementSystemConfigurationReports');

  const dispatch = useDispatch();
  const [stringifiedInitFormValues, setStringifiedInitFormValues] = useState('[]');
  const loggedUserContext = useSelector((state: ReducerState) => state.user.context);
  const confirmationOfWindowChange = useSelector((state: ReducerState) => state.appConfig.confirmationOfWindowChange);
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  const fetchAPI = useCallback(async () => {
    const response = await weighingConfigurationService.getConfigurationReports();
    if (response.data) {
      const responseDataValues = {
        hideCreationDate: response.data.hideCreationDate,
        enableCyclicMailReport: response.data.enableCyclicMailReport,
        cyclicMailSendingReportMinutesAfterMidnight: response.data.cyclicMailSendingReportMinutesAfterMidnight,
        cyclicMailSendingReportFormat: response.data.cyclicMailSendingReportFormat,
        cyclicMailSendingReportMails: response.data.cyclicMailSendingReportMails,
      };

      setInitFormValues({ ...initFormValues, ...responseDataValues });
      setStringifiedInitFormValues(JSON.stringify({ ...initFormValues, ...responseDataValues }));
    }
  }, [initFormValues]);

  useEffect(() => {
    fetchAPI();
  }, [loggedUserContext.currentCustomer?.id]);

  const handleSubmit = async (data: any) => {
    const postData = {
      hideCreationDate: data.hideCreationDate,
      enableCyclicMailReport: data.enableCyclicMailReport,
      cyclicMailSendingReportMinutesAfterMidnight: data.enableCyclicMailReport
        ? data.cyclicMailSendingReportMinutesAfterMidnight
        : null,
      cyclicMailSendingReportFormat: data.enableCyclicMailReport ? data.cyclicMailSendingReportFormat : null,
      cyclicMailSendingReportMails: data.enableCyclicMailReport ? data.cyclicMailSendingReportMails : [],
    };
    await weighingConfigurationService.updateConfigurationReports(postData);
    dispatch(userActions.shouldRefreshSystemConfiguration(true));
    fetchAPI();
  };

  const validateMethod = (data: any) => {
    const errors: any = {};
    if (data.enableCyclicMailReport && !data.cyclicMailSendingReportMinutesAfterMidnight) {
      errors.cyclicMailSendingReportMinutesAfterMidnight = t('emptyTimeValidationError');
    }

    if (data.enableCyclicMailReport && data.cyclicMailSendingReportMails.length < 1) {
      errors.cyclicMailSendingReportMails = t('emptyMailValidationError');
    }

    if (data.cyclicMailSendingReportMails) {
      const re = /\S+@\S+\.\S+/;
      data.cyclicMailSendingReportMails.map((email: string) => {
        if (!re.test(email)) return (errors.cyclicMailSendingReportMails = t('characterMailValidationError'));
        else return null;
      });
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const changes = JSON.stringify(formik.values) !== stringifiedInitFormValues;
    if (confirmationOfWindowChange.required !== changes) {
      dispatch(
        appConfigActions.setConfirmationOfWindowChange({
          ...confirmationOfWindowChange,
          required: changes,
          saveMethod: formik.submitForm,
        }),
      );
    }
  }, [JSON.stringify(formik.values)]);

  const handleClickCancel = () => {
    formik.resetForm();
  };

  const handleClickSubmit = () => {
    formik.submitForm().then(() => {
      dispatch(appConfigActions.setConfirmationOfWindowChange({ ...confirmationOfWindowChange, required: false }));
    });
  };

  const handleChangeField = (e: any, type: string) => {
    formChangeFieldMethod(e, type, formik);
  };

  return (
    <div className="card pb-70px">
      <form onSubmit={formik.handleSubmit}>
        <ReportHideSection formikInstance={formik} handleChangeField={handleChangeField} />
        <CyclicMailReportSection formikInstance={formik} handleChangeField={handleChangeField} />
        <div>
          <div
            className="flex justify-content-end p-ai-end bg-white fixed bottom-0px right-0px w-full pt-10px pr-40px pb-10px pl-0px"
            style={{
              boxShadow: '0px 10px 15px black',
            }}
          >
            <Button type="reset" label={t('reset')} icon="pi pi-times" text onClick={handleClickCancel} />
            <Button type="button" label={t('save')} icon="pi pi-check" text onClick={handleClickSubmit} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ReportsConfiguration;
