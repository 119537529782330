import WithDrawnSender from './WithDrawnSender';
import CorrectSender from './CorrectSender';
import ConfirmationGeneratedCarrier from './ConfirmationGeneratedCarrier';
import RejectedReceiver from './RejectedReceiver';
import ReceiveConfirmationReceiver from './ReceiveConfirmationReceiver';

const ChangeStatusDialogs = {
  Sender: {
    WITHDRAWN: WithDrawnSender,
    CORRECT: CorrectSender,
  },
  Carrier: {
    CONFIRMATION_GENERATED: ConfirmationGeneratedCarrier,
  },
  Receiver: {
    REJECTED: RejectedReceiver,
    RECEIVE_CONFIRMATION: ReceiveConfirmationReceiver,
  },
};

export default ChangeStatusDialogs;
