import React from 'react';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { useFormik } from 'formik';
import statusUtils from '../statusUtils';
import FormErrorMessage from '../../../../_shared/FormErrorMessage';
import formChangeFieldMethod from '../../../../../utils/formChangeFieldMethod';
import { DEFAULT_DIALOG_BREAKPOINTS, DEFAULT_DIALOG_STYLE, CancelButton, SubmitButton } from '../../../bdoUtils';
import FormErrorMessageScroller from '../../../../_shared/FormErrorMessageScroller';
import { useTranslation } from 'react-i18next';

const INIT_FORM_STATE = { remarks: '' };

const WithDrawnSender = ({ visible, closeDialog, item }) => {
  const { t } = useTranslation('kpokComponents');

  const validateMethod = (data) => {
    const errors = {};

    if (!data.remarks) {
      errors.remarks = t('withdrawnSenderRemarksNotProvided');
    }

    return errors;
  };

  const handleCloseDialog = (changes, statusFilter) => {
    formik.resetForm({
      values: INIT_FORM_STATE,
    });
    closeDialog(changes === true, statusFilter);
  };

  const handleSubmit = async (data) => {
    await statusUtils.changeStatusMethods.sender.WITHDRAWN({ kpokId: item.kpokId, ...data });

    handleCloseDialog(true, 'WITHDRAWN');
  };

  const formik = useFormik({
    initialValues: INIT_FORM_STATE,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const dialogFooter = (
    <div className="grid justify-content-center">
      <CancelButton onClick={handleCloseDialog} />
      <SubmitButton onClick={formik.submitForm} />
    </div>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={visible}
        header={t('withdrawnSenderHeader')}
        modal
        className="p-fluid"
        maskClassName="higher-rank"
        breakpoints={DEFAULT_DIALOG_BREAKPOINTS}
        style={DEFAULT_DIALOG_STYLE}
        footer={dialogFooter}
        onHide={handleCloseDialog}
      >
        <form>
          <div className="field col-12">
            <label htmlFor="remarks">
              {t('withdrawnSenderRemarks')}
              <span className="text-red-500">&nbsp;*</span>
            </label>
            <InputTextarea
              id="remarks"
              value={formik.values.remarks}
              onChange={(e) => formChangeFieldMethod(e, 'text', formik)}
              rows={5}
              autoResize
            />
            <FormErrorMessage fieldName="remarks" formikInstance={formik} />
            <br />
            <br />
            <br />
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};

export default WithDrawnSender;
