import { Weighing } from '../../types/weighing';
import { getFileFromBuffer, printFileFromBuffer } from '../../utils/getFile';
import weighingsService from '../../services/ScaleoApiServices/WeighingsService';

export const useWeighingsListReceipts = (selectedWeighings: Weighing[] | null) => {
  const generateReceipts = (options: any, weighingsArray?: Weighing[]) => {
    void weighingsService
      .getWeighingReceipts(
        (weighingsArray || selectedWeighings!).map((el: any) => {
          return el.id;
        }),
      )
      .then((receipts) => {
        !options.download && getFileFromBuffer(receipts.data, 'application/pdf');
        options.download && getFileFromBuffer(receipts.data, 'application/pdf', 'kwit_wagowy.pdf');
      });
  };

  const printReceipts = (weighingsArray?: Weighing[]) => {
    void weighingsService
      .getWeighingReceipts(
        ((weighingsArray || selectedWeighings) as any[])?.map((el) => {
          return el.id;
        }),
      )
      .then((receipts) => {
        printFileFromBuffer(receipts.data, 'application/pdf');
      });
  };

  return {
    printReceipts,
    generateReceipts,
  };
};
